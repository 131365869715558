import React, { useState } from "react";
import { Form, Table } from "react-bootstrap";
import { Button, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { accessSettings, servicePath } from "../../../../../../utility/data";
import { useEffect } from "react";
import PricingHeader from "../../../../../../components/Pricing/PricingHeader";
import CustomizedButton from "../../../../../../components/CustomizedButton";
import {
  handleEmptyValues,
  handleFocus,
  newHandleInput,
} from "../../../../../../utility/helper";
import { useRightContext } from "../../../../../../context/rightMenuContext";
import PricingTableComp from "../../../../../../components/PricingTableComp";

const ProjectAncillariesLeft = ({
  token,
  setMessage,
  setMessageType,
  loading,
  setLoading,
}) => {
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState("Lump sum");

  const [selectedPricing, setSelectedPricing] = useState();
  const [selectedSupplyOnly, setSelectedSupplyOnly] = useState("");
  const { userDataContext, setUserDataContext } = useRightContext("");
  const [data, setData] = useState([]);

  useEffect(() => {
    setLoading(true);
    if (selectedPricing && selectedSupplyOnly) {
      getAncillaryData();
    }
  }, [selectedPricing, selectedSupplyOnly]);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const getAncillaryData = () => {
    const FormData = require("form-data");

    let data = new FormData();

    data.append("PriceMasterId", selectedPricing);
    data.append("MarkupMasterId", selectedSupplyOnly);

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${servicePath}/General/GetProjectAncillariesPricing`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };
    axios
      .request(config)
      .then((res) => {
        if (res?.data?.statusCode === 200) {
          setLoading(false);
          setData(res?.data?.entity);
          console.log(data, "data");
        } else if (res?.data?.statusCode === 404) {
          setLoading(false);
          navigate("/error/404");
        } else {
          setLoading(false);
          setMessage(
            res?.data?.message ? res.data.message : "Something went wrong"
          );
          setMessageType("error");
        }
      })
      .catch((error) => {
        console.log(error);

        setLoading(false);
        setMessage(error.message ? error.message : "Something went wrong");
        setMessageType("error");

        if (error?.message === "Network Error") {
          navigate("/error/network");
        }
      });
  };

  const handleInput = (id, key, value) => {
    if (validate(value)) {
      setData((prevData) =>
        prevData.map((item, index) =>
          index === id ? { ...item, [key]: parseFloat(value) } : item
        )
      );
    }
  };

  const validate = (value) => {
    if (value < 0) return false;
    return true;
  };

  const handleUpdate = () => {
    setLoading(true);
    updateTableData();
  };

  const updateTableData = () => {
    console.log("Updated data", data);
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${servicePath}/General/AddUpdateProjectAncillariesPricing`,
      headers: {
        accept: "text/plain",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: handleEmptyValues(data),
    };

    axios
      .request(config)
      .then((res) => {
        console.log(res.data, "UpdatedProjectAncillaries");

        if (res?.data?.statusCode === 200) {
          setMessage(res.data.message);
          setMessageType("success");

          getAncillaryData();
        }
      })
      .catch((error) => {
        console.log(error);

        setLoading(false);
        setMessage(error.message ? error.message : "Something went wrong");
        setMessageType("error");

        if (error?.message === "Network Error") {
          navigate("/error/network");
        }
      });
  };

  const handleFormReset = () => {
    setLoading(true);
    getAncillaryData();
  };
  const data1 = { url: "/settings/quotations/ancillaries" };
  return (
    <div className="d-flex flex-column h-100">
      <PricingHeader
        setSelectedSupplyOnly={setSelectedSupplyOnly}
        setSelectedPricing={setSelectedPricing}
        selectedPricing={selectedPricing}
        selectedSupplyOnly={selectedSupplyOnly}
      />

      <Nav tabs className="details_header border-0 nav-tabs-custom">
        <NavItem>
          <NavLink
            className={`${activeTab === "Lump sum" && "active"}`}
            onClick={() => handleTabChange("Lump sum")}
          >
            Lump sum
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={`${activeTab === "Percentage" && "active"}`}
            onClick={() => handleTabChange("Percentage")}
          >
            Percentage
          </NavLink>
        </NavItem>

        {userDataContext && accessSettings && accessSettings.includes(userDataContext?.role) && (
          <CustomizedButton data={data1} />
        )}
      </Nav>

      <TabContent activeTab={activeTab} className="flex-grow-1 d-flex">
        <TabPane tabId="Lump sum" className="flex-grow-1 w-100">
          <Form className="h-100 d-flex flex-column flex-grow-1 justify-content-between">
            <div className="inside_scroll has_tab has_btns">
              <PricingTableComp
                data={data}
                handleInput={handleInput}
                newHandleInput={newHandleInput}
                setData={setData}
                userDataContext={userDataContext}
              ></PricingTableComp>
            </div>
            <div className="d-flex gap-2 gap-xl-3 main_buttons">
              <Button
                color="primary"
                size="md"
                className="px-4"
                onClick={handleUpdate}
              >
                Save Changes
              </Button>

              <Button
                color="primary"
                size="md"
                outline
                className="px-4"
                onClick={handleFormReset}
              >
                Reset
              </Button>
            </div>
          </Form>
        </TabPane>

        <TabPane tabId="Percentage" className="w-100">
          <Form className="h-100 d-flex flex-column flex-grow-1 justify-content-between">
            <div className="inside_scroll has_tab has_btns">
              <Table responsive className="w-100 discount_table">
                <thead className="border-bottom">
                  <tr>
                    <th></th>
                    <th>Percentage</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((item, index) => (
                    <tr key={index}>
                      <td>{item.description}</td>
                      <td>
                        <Form.Control
                          type="number"
                          onFocus={handleFocus}
                          value={item.percentage || 0}
                          onChange={(e) =>
                            handleInput(index, "percentage", e.target.value)
                          }
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
            <div className="d-flex gap-2 gap-xl-3 main_buttons">
              <Button
                color="primary"
                size="md"
                className="px-4"
                onClick={handleUpdate}
              >
                Save Changes
              </Button>

              <Button
                color="primary"
                size="md"
                outline
                className="px-4"
                onClick={handleFormReset}
              >
                Reset
              </Button>
            </div>
          </Form>
        </TabPane>
      </TabContent>
    </div>
  );
};

export default ProjectAncillariesLeft;
