import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Image } from "react-bootstrap";

import SidebarMenu from "./sidebarMenu";
import LogOutIcon from '../assets/img/icons/logout.svg'
import logo from "../assets/img/logo/logo.png"
import smallLogo from "../assets/img/logo/small-logo.png"
import { BiChevronRight } from "react-icons/bi";
import { srcIconCondition, srcLogoCondition } from "../services/commonServices";
import { useRightContext } from '../context/rightMenuContext'
import { GetSashMenuListForPricing, getAllFrameSidebarList, getAllPanelSidebarList, getColourCollectionNavigaton, getPanelMenuListForPricing } from "../services/SidebarServices";

const Sidebar = ({ accessToken, setSidebarActive, sidebarActive, fetchSidebar, setFetchSidebar, toggleSiderbar, userData }) => {

    var network_error = localStorage.getItem("network_error")

    var initial_fetch = localStorage.getItem("initial_fetch")

    const navigate = useNavigate()

    const { setPanelMenuList, setPricingColoursMenuList, setPricingPanelMenuList, setPricingSashProductsMenuList } = useRightContext()

    const [initialOpen, setInitialOpen] = useState(true);

    useEffect(() => {
        if (sidebarActive === false) {
            setInitialOpen(true)
        }

        initial_fetch = localStorage.getItem("initial_fetch")
    }, [sidebarActive])

    useEffect(() => {

        if (fetchSidebar && accessToken !== null && initial_fetch == 'true') {

            getAllPanelSidebarList(accessToken, setPanelMenuList, navigate);
            getPanelMenuListForPricing(accessToken, setPricingPanelMenuList, navigate);
            getColourCollectionNavigaton(accessToken, setPricingColoursMenuList, navigate);
            GetSashMenuListForPricing(accessToken, setPricingSashProductsMenuList, navigate);

            // getAllFrameSidebarList(accessToken, setFrameMenuList, navigate);
            // getFrameProductsSidebarList(accessToken, setFrameProductsMenuList, navigate);
            // GetFramestylesMenuListForPricing(accessToken, setPricingFrameStylesMenuList, navigate);
            // GetInstallFramestylesMenuListForPricing(accessToken, setPricingInstallFrameStylesMenuList, navigate);
            // GetFrameProductsMenuListForPricing(accessToken, setPricingFrameProductsMenuList, navigate);

            setFetchSidebar(false)
            localStorage.setItem("initial_fetch", false)
        }
    }, [fetchSidebar, accessToken, initial_fetch])

    const handleExitOrbit = () => {
        // var company_data = JSON.parse(localStorage.getItem("user_data"))

        if (userData && userData?.role === "Super Admin") {
            window.location.href = `https://${userData?.website_url}/admin/dashboard`
        } else {
            window.location.href = `https://${userData?.website_url}`
        }
    }

    function toggleSidebarFun(value) {
        let isTouch = 'ontouchstart' in window || navigator.maxTouchPoints

        if (!isTouch) {
            setSidebarActive(value)
        }
    }

    return (
        <>
            <div className={`vertical-menu full_sidebar ${sidebarActive ? 'sidebar-lg' : 'sidebar-sm'}`}>
                    <div className="position-relative">
                        <Link to="#" className="sidebar_toggle text-white" onClick={() => toggleSiderbar(!sidebarActive)}>
                            <svg width="30" height="68" viewBox="0 0 30 68" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="15" cy="34" r="15" fill="#44C9F6" />
                                <path d="M10 1.5C10.5 16.5 15.5 17.5 21.5 20.5L7 27.4882C7.16667 19.9882 9.81344 -4.09689 10 1.5Z" fill="#44C9F6" />
                                <path d="M10 66.9882C10.5 51.9882 15.5 50.5 21.5 47.5L7 41C7.16667 48.5 9.81344 72.585 10 66.9882Z" fill="#44C9F6" />
                            </svg>
                            <BiChevronRight size={30} />
                        </Link>

                        <div className="h-100" onMouseEnter={() => toggleSidebarFun(true)} onMouseLeave={() => toggleSidebarFun(false)}>

                            {!sidebarActive ? (
                                <Link to="/" className="logo dash_small_logo">
                                    <span className="logo-lg">
                                        {userData && userData?.role ? (
                                            <Image src={userData && srcIconCondition(userData, smallLogo)} alt="" height="55" />
                                        ) : (
                                            <Image src={smallLogo} alt="" height="55" />
                                        )}
                                    </span>
                                </Link>
                            ) : (
                                <Link to="/" className="logo_large mt-0">
                                    {userData && userData?.role ? (
                                        <Image src={userData && srcLogoCondition(userData, logo)} alt="logo" />
                                    ) : (
                                        <Image src={logo} alt="logo" />
                                    )}
                                </Link>
                            )}

                            <div id="sidebar-menu" className={initialOpen ? 'initial_open' : ''}>
                                <ul className="list-unstyled d-flex flex-column justify-content-between multi_level_sidebar h-100" id="side-menu">
                                    <div className="sidebar_scroll flex-grow-1">
                                        <SidebarMenu accessToken={accessToken} sidebarActive={sidebarActive} setSidebarActive={setSidebarActive} initialOpen={initialOpen} setInitialOpen={setInitialOpen} role={userData && userData?.role ? userData?.role : 'Admin'} userData={userData} />
                                    </div>

                                    {userData && (userData?.role === "Admin" || userData?.role === "Super Admin" || userData?.role === "Supplier") && (
                                        <div onClick={handleExitOrbit} className="MuiButtonBase-root MuiListItem-root page_links main_menu false MuiListItem-gutters MuiListItem-button mb-0" role="button" aria-disabled="false">
                                            <div className="MuiListItemIcon-root">
                                                <span className="menu_icon">
                                                    <Image src={LogOutIcon} alt="exit" />
                                                </span>
                                            </div>
                                            <div className="MuiListItemText-root">
                                                <span className="MuiTypography-root MuiListItemText-primary MuiTypography-body1">
                                                    Exit Orbit
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                </ul>
                            </div>
                        </div>
                    </div>
            </div>
        </>
    );
}

export default Sidebar;