import React, { useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import {
	Row,
	Col,
	Button,
	FormGroup,
	Label,
	Input,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	UncontrolledPopover,
	PopoverBody,
	Badge,
} from "reactstrap";
import NumberInputDecimal from "../../../../../../components/NumberInputDecimal";
import { useRightContext } from "../../../../../../context/rightMenuContext";
import { Link, useNavigate } from "react-router-dom";

import { MdOutlineModeEdit } from "react-icons/md";
import { BiSort, BiTrashAlt } from "react-icons/bi";
import DataTable from "react-data-table-component";
import {
	addOversizeGlazingService,
	deleteOverSizeGlazingService,
	getAllGlazingOversizePrice,
	getOverSizeGlazingIndividualService,
} from "../../../../../../services/glazingOversizeService";

// import Choices from 'choices.js';
// import 'choices.js/public/assets/styles/choices.min.css';

import { allGlazingPalette } from "../../../../../../services/glazingServices";
import { handleQuantity } from "../../../../../../utility/helper";
import SearchWithCheckboxes from "../../../../../../components/Pricing/SearchWithCheckboxes";

const OversizeLeft = ({ accessToken, setLoading, setMessage, setMessageType, selectItemDetails, updateItemDetailsServices, handleFormReset }) => {

	const navigate = useNavigate();
	const selectRef = useRef(null);

	// let specChoices = ''

	const { timestamp, selectedRightItem, selected, setSelected, handleEdit } = useRightContext()

	const [name, setName] = useState('')
	const [itemId, setItemId] = useState('')
	const [nameError, setNameError] = useState(false)

	const [minimumArea, setMinimumArea] = useState(0);
	const [maximumArea, setMaximumArea] = useState(100);
	const [percentage, setPercentage] = useState(0);
	const [selectedSpec, setSelectedSpec] = useState([]);

	const [allSlabList, setAllSlabList] = useState([]);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [deletedId, setDeletedId] = useState("");
	const [isDeleting, setIsDeleting] = useState(false);
	const [editSlabId, setEditSlabId] = useState("");

	const [allSpecsList, setAllSpecsList] = useState([]);

	useEffect(() => {
		getAllPaletteServices();
	}, [])

	useEffect(() => {
		setLoading(false)

		setSelected(selectItemDetails?.name || '')
		setName(selectItemDetails?.name || '')
		setItemId(selectItemDetails?.id || '')

		setNameError(false)


		if (selectItemDetails?.id) {
			getAllOverSizingGlazingData(selectItemDetails?.id);

			setMinimumArea(0);
			setMaximumArea(100);
			setPercentage(0);

			const itemData = {
				id: allSpecsList[0]?.id,
				name: allSpecsList[0]?.name,
			}

			setSelectedSpec([itemData])

			setEditSlabId("");
		}
	}, [selectItemDetails])

	const getAllPaletteServices = async () => {
		setLoading(true)
		const res = await allGlazingPalette(1, accessToken)
		if (res?.data?.statusCode === 200) {
			let data = res?.data?.entity
			setAllSpecsList(data)

			const itemData = {
				id: data[0]?.id,
				name: data[0]?.name,
			}

			setSelectedSpec([itemData])

			setLoading(false)
			setMessageType('success')
			setMessage(res.message)
		} else {
			setLoading(false)
			setMessageType('error')
			setMessage(res.message)
		}
	}

	// useEffect(() => {
	// 	if (selectRef.current) {
	// 		// Initialize Choices.js when accordion opens
	// 		specChoices = new Choices(selectRef.current, {
	// 			removeItemButton: false,
	// 			shouldSort: false,
	// 			searchEnabled: true,
	// 			searchResultLimit: 6,
	// 		});

	// 		// Focus the select element when accordion opens
	// 		selectRef.current.focus();

	// 		return () => {
	// 			specChoices.destroy();
	// 		};
	// 	}
	// }, [allSpecsList]);

	useEffect(() => {
		if (editSlabId !== "") {
			getOverSizeGlazingIndividual(editSlabId);
		}
	}, [editSlabId]);

	const getAllOverSizingGlazingData = async (fetch_id) => {
		setLoading(true);

		const res = await getAllGlazingOversizePrice(accessToken, fetch_id);

		if (res?.data?.statusCode === 200) {
			setAllSlabList(res?.data?.entity);
			setLoading(false);
		} else if (res?.data?.statusCode === 404) {
			setLoading(false);
			setAllSlabList([]);
			navigate("/error/404");
		} else {
			setLoading(false);
			setAllSlabList([]);
			setMessage(
				res?.data?.message ? res.data.message : "Something went wrong"
			);
			setMessageType("error");
		}
	};

	const columns = [
		{
			name: <span>Specification name</span>,
			selector: (row) => <div className="d-flex gap-3 align-items-center justify-content-between">
				<span>
					{row && row?.specifications && row?.specifications?.slice(0, 2).map(spec => spec?.name).join(", ")} 
					{row?.specifications && row?.specifications?.length > 2 && <strong className="ms-2">+{row?.specifications?.length - 2}</strong>}
				</span>

				{/* {row?.specifications && row?.specifications?.length > 2 && (
					<>
						<span className="d-flex gap-2 fw-bold fs-6">
							{`+ ${row?.specifications?.length - 2}`}
						</span>
						
						<Badge
							color="light"
							pill
							className="fs-6"
						>
							{row?.specifications?.length - 2}
						</Badge>

						<Button
							id="PopoverLegacy"
							type="button"
						>
							{row?.specifications?.length - 2}
						</Button>

						<UncontrolledPopover
							placement="bottom"
							target="PopoverLegacy"
							trigger="legacy"
						>
							<PopoverBody>
								Legacy is a reactstrap special trigger value (outside of bootstrap‘s spec/standard). Before reactstrap correctly supported click and focus, it had a hybrid which was very useful and has been brought back as trigger=“legacy“. One advantage of the legacy trigger is that it allows the popover text to be selected while also closing when clicking outside the triggering element and popover itself.
							</PopoverBody>
						</UncontrolledPopover>
					</>
				)} */}
			</div>,
		},
		{
			name: <span>Price from (m<sup>2</sup>)</span>,
			minWidth: '120px',
			selector: (row) => row.minimumArea,
		},
		{
			name: <span>Percentage</span>,
			selector: (row) => row.percentage,
		},
		{
			name: <span>Action</span>,

			cell: (row) => (
				<div className="d-flex gap-2">
					<Link onClick={() => setEditSlabId(row?.id)}>
						<MdOutlineModeEdit size={20} />
					</Link>

					<Link>
						<BiTrashAlt
							size={20}
							onClick={() => {
								setShowDeleteModal(true);
								setDeletedId(row);
							}}
						/>
					</Link>
				</div>
			),
		},
	];

	const getDataMapping = allSlabList?.map((item) => {
		return {
			minimumArea: item?.minValue,
			maximumArea: item?.maxValue,
			percentage: item?.percentage,
			specifications: item?.specifications,
			specificationName: item?.specificationName,
			id: item?.id,
		};
	});

	const addUpdateOversizeGlazing = async () => {
		setLoading(true);

		let id = null;

		if (editSlabId !== "") {
			id = editSlabId;
		}

		const data = {
			id: id,
			minValue: minimumArea,
			maxValue: maximumArea,
			percentage: parseFloat(percentage),
			specifications: selectedSpec,
			collectionId: selectedRightItem?.id,
			specificationName: '',
		};

		console.log(data, selectedSpec, "selectedSpec")

		const res = await addOversizeGlazingService(accessToken, data);

		if (res?.data?.statusCode === 200) {
			getAllOverSizingGlazingData(selectItemDetails?.id);

			setLoading(false);
			setMessageType("success");
			setMessage(res?.data?.message);

			setMinimumArea(0);
			setMaximumArea(100);
			setPercentage(0);
			const itemData = {
				id: allSpecsList[0]?.id,
				name: allSpecsList[0]?.name,
			}

			setSelectedSpec([itemData])

			setEditSlabId("");

		} else if (res?.data?.statusCode === 404) {
			setLoading(false);
			navigate("/error/404");
		} else {
			setLoading(false);
			setMessage(
				res?.data?.message ? res.data.message : "Something went wrong"
			);
			setMessageType("error");
		}
	};

	const getOverSizeGlazingIndividual = async (id) => {
		setLoading(true);
		const res = await getOverSizeGlazingIndividualService(accessToken, id);

		if (res?.data?.statusCode === 200) {

			const data = res?.data?.entity

			setMaximumArea(data?.maxValue);
			setMinimumArea(data?.minValue);
			setPercentage(data?.percentage);

			// const itemData = {
			// 	id: data.specificationId,
			// 	name: data.specificationName,
			// }

			setSelectedSpec(data.specifications)

			// if(specChoices) {
			// 	specChoices.setValue(data?.specificationId);
			// }

			setLoading(false);

		} else if (res?.data?.statusCode === 404) {
			setLoading(false);
			navigate("/error/404");
		} else {
			setLoading(false);
			setMessage(
				res?.data?.message ? res.data.message : "Something went wrong"
			);
			setMessageType("error");
		}
	};

	const deleteOverSizeGlazing = async (id) => {
		setLoading(true);
		setIsDeleting(true);
		const res = await deleteOverSizeGlazingService(id, accessToken);
		if (res?.data?.statusCode === 200) {
			setLoading(false);
			setMessageType("success");
			setMessage(res?.data?.message);
			getAllOverSizingGlazingData(selectItemDetails?.id);
			setShowDeleteModal(false);
			setIsDeleting(false);
			setDeletedId("");

		} else if (res?.data?.statusCode === 404) {
			setLoading(false);
			navigate("/error/404");
			setIsDeleting(false);
		} else {
			setLoading(false);
			setMessage(
				res?.data?.message ? res.data.message : "Something went wrong"
			);
			setIsDeleting(false);
			setMessageType("error");
		}
	};

	const handleNameChange = (e) => {
		const value = e.target.value

		if (e.target.value?.length <= 50) {
			setName(value)
			handleEdit(value)
			setSelected(value)
		}

		if (value.length > 50) {
			setNameError(true)
		} else {
			setNameError(false)
		}
	}

	const handleFormSubmit = () => {

		const formDataValues = {
			'id': selectItemDetails?.id,
			"name": name,
		}

		updateItemDetailsServices(formDataValues)
	}

	const onSelectItem = (item) => {
		const itemData = {
			id: item?.id,
			name: item?.name,
		}

		selectedSpec.push(itemData)

		setSelectedSpec([...selectedSpec])

		console.log(selectedSpec, item, "value test")
	}

	const onRemoveItem = (item) => {
		// Remove the item by filtering it out from the array
		const updatedSpec = selectedSpec.filter(spec => spec?.id !== item?.id);

		// Update the state with the filtered array
		setSelectedSpec(updatedSpec);

		console.log(updatedSpec, item, "value test");
	};


	return (
		<div className="d-flex flex-column h-100">
			<div className="d-flex align-items-center mb-3 flex-wrap gap-2 left_header">
				<h5 className="mb-0 text-wrap text-break me-4 main_title">{selected ? selected : 'No items added'}</h5>
			</div>

			<Form className={`h-100 d-flex flex-column flex-grow-1 justify-content-between ${selectedRightItem?.isSelf ? "" : "not_allowed"}`}>
				<div className="inside_scroll has_btns has_head">
					<Row>
						<Col xl="5" xxl="6" className="mb-sm-2">
							<FormGroup className="form-group">
								<Label for="name">
									Name
								</Label>
								<Input className='mb-1' name='name' value={name} onChange={(e) => handleNameChange(e)} />

								{nameError && timestamp && itemId && (
									<div className='mt-1 text-muted text-small'>Max 50 characters are allowed</div>
								)}

								{(!name && timestamp && itemId) && (
									<div className='mt-1 text-danger text-small'>Name is required</div>
								)}
							</FormGroup>
						</Col>
					</Row>

					<div className="inside_scroll has_btns has_head border-top pt-4">

						<h5 className="text-wrap text-break me-4 main_title">

							{editSlabId === "" ? "Add new" : "Edit"} oversize
						</h5>

						<Row>
							<Col lg="6">
								<FormGroup className="form-group">
									<Label for="minimum">Price from (m²)</Label>

									<NumberInputDecimal
										name="minimum"
										value={minimumArea}
										setValue={setMinimumArea}
									/>

									{minimumArea === "" && (
										<div className="mt-1 text-danger text-small">
											This field is required.
										</div>
									)}
								</FormGroup>
							</Col>

							{/* <Col lg="6">
								<FormGroup className="form-group">
									<Label for="maximum">Maximum Area (mm²)</Label>

									<NumberInputDecimal
										name="maximum"
										value={maximumArea}
										setValue={setMaximumArea}
									/>
									{error && (
										<div className="mt-1 text-danger text-small">
											Max should be greater than Min value.
										</div>
									)}
								</FormGroup>
							</Col> */}

							<Col lg="6">
								<FormGroup className="form-group">
									<Label for="percentage">Percentage</Label>

									{/* <NumberInput
										name="percentage"
										value={percentage}
										setValue={setPercentage}
									/> */}

									<Input
										type='number'
										name="percentage"
										min="1" step="1" max="999"
										value={percentage}
										onInput={(e) => handleQuantity(e)}

										onChange={(e) => { setPercentage(e.target.value) }}
									/>
								</FormGroup>
							</Col>

							<Col lg="6">
								<FormGroup className="form-group">
									<Label for="specification">Specifications {`(${selectedSpec?.length})`}</Label>

									<SearchWithCheckboxes
										list={allSpecsList}
										title={"name"}
										subtitle={"productCode"}
										activeValues={selectedSpec}
										onSelect={onSelectItem}
										onRemove={onRemoveItem}
										searchKeys={["name", "productCode"]}
										isCheckbox={true}
										isSingleSelect={false}
									/>

									{/* <div className="d-flex gap-2 flex-wrap">
										{selectedSpec && selectedSpec.length > 0 && selectedSpec.map((o, index) => (
											<span
												key={index}
												className="text-muted"
											>
												{o?.name}
											</span>
										))}

										{selectedSpec && selectedSpec.length > 0 && selectedSpec.map(spec => spec.name).join(", ")}
									</div> */}

									{/* <div className="d-flex choices_select">
										<select
											ref={selectRef}
											onChange={(e) => { setSelectedSpec(e.target.value) }}
										>
											{allSpecsList && allSpecsList.map((o, index) => (
												<option
													key={index}
													value={o?.id}
												>
													{o?.name}
												</option>
											))}
										</select>

										<div className="select_icon">
											<IoChevronDown size={16} color="#404449" />
										</div>
									</div> */}
								</FormGroup>
							</Col>
						</Row>

						<div className="d-flex gap-2 gap-xl-3 main_buttons mb-4">
							<Button
								color="primary"
								size="md"
								className="px-4"
								onClick={() => {
									addUpdateOversizeGlazing();
								}}
								disabled={!selectedSpec?.length > 0 || !maximumArea || !minimumArea || !percentage || !selectedSpec}
							>
								{editSlabId === "" ? "Add" : "Update"}
							</Button>

							{editSlabId && (

								<Button
									outline
									color="primary"
									size="md"
									className="px-4"
									onClick={() => handleFormReset()}
									disabled={!maximumArea || !minimumArea || !percentage || !selectedSpec}
								>
									Cancel
								</Button>
							)}
						</div>

						<div className="d-flex flex-column flex-grow-1 justify-content-between">

							<h5 className="mt-4 mb-3">
								Added oversize slabs
							</h5>

							<div className="">
								<DataTable
									columns={columns}
									data={getDataMapping}
									className="custom_datatable clickable oversize_table"
									sortIcon={<BiSort size={18} className="text-muted" />}
									highlightOnHover
									pagination
									paginationPerPage={10}
									paginationRowsPerPageOptions={[5, 10, 15, 20, 30]}
								/>
							</div>
						</div>
					</div>
				</div>

				{/* Start: space for buttons at the bottom */}
				{selectedRightItem?.isSelf && (
					<div className="d-flex gap-2 gap-xl-3 main_buttons">
						<Button color="primary" size="md" className="px-4"
							onClick={() => handleFormSubmit()}
							disabled={!timestamp || !name.trim()}
						>
							Save Changes
						</Button>

						<Button color="primary" size="md"
							outline
							className="px-4"
							disabled={!timestamp}
							onClick={() => handleFormReset()} >
							Reset
						</Button>
					</div>
				)}
				{/* End: space for buttons at the bottom */}
			</Form>

			<Modal isOpen={showDeleteModal} toggle={() => setShowDeleteModal(false)}>
				<ModalHeader>Delete Selected Item</ModalHeader>
				<ModalBody>
					Are you sure you want delete this{" "}
					<strong>
						{deletedId?.minimumArea} - {deletedId?.maximumArea}
					</strong>{" "} ?
				</ModalBody>
				<ModalFooter>
					<Button
						color="outline-danger"
						size="md"
						disabled={isDeleting}
						onClick={() => deleteOverSizeGlazing(deletedId?.id)}
					>
						Delete
					</Button>
					<Button
						onClick={() => setShowDeleteModal(false)}
						color="primary"
						size="md"
					>
						Cancel
					</Button>
				</ModalFooter>
			</Modal>
		</div>
	);
};

export default OversizeLeft;