import React, { useEffect, useState, useRef } from 'react';
import { Card } from 'react-bootstrap';
import { BsReverseLayoutSidebarInsetReverse } from 'react-icons/bs';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import { useMessage } from '../context/messageContext';

const InternalLayout = ({ leftContent, rightContent, pageTitle, layoutType, filterButton, headerButton, rightMaster }) => {
    const [rightMenu, setRightMenu] = useState(false);
    const { message, messageType, setMessage } = useMessage();

    useEffect(() => {
        if (message) {
            toast(message, {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                type: messageType === 'success' ? 'success' : 'error',
            });
        }
        setMessage(''); // Clear message after displaying
    }, [message, messageType, setMessage]);

    return (
        <>
            <ToastContainer />
            <div className='page_title d-flex justify-content-between align-items-center'>
                <div className='d-flex gap-3 align-items-center'>
                    {pageTitle && <h3 className='mb-0 text-nowrap'>{pageTitle}</h3>}
                    {!rightMaster && filterButton}
                </div>

                {rightMaster ? (
                    <div className='right_master d-flex gap-3 align-items-center'>
                        {filterButton}
                        {layoutType !== 'full' && (
                            <Button
                                color=""
                                size="md"
                                className='border-0 text-muted p-0 d-md-none right_menu_btn'
                                onClick={() => setRightMenu(!rightMenu)}
                            >
                                <BsReverseLayoutSidebarInsetReverse size={26} />
                            </Button>
                        )}
                    </div>
                ) : (
                    <div className='d-flex gap-2'>
                        {headerButton}
                        {layoutType !== 'full' && (
                            <Button
                                color=""
                                size="md"
                                className='border-0 text-muted p-0 d-md-none right_menu_btn'
                                onClick={() => setRightMenu(!rightMenu)}
                            >
                                <BsReverseLayoutSidebarInsetReverse size={26} />
                            </Button>
                        )}
                    </div>
                )}
            </div>

            <div className={`main_layout page_scroll d-flex gap-3 gap-xl-4`}>
                <div className={`left_content flex-grow-1 ${layoutType}`}>
                    <Card className='mb-0 h-100'>
                        <Card.Body className='p-3 p-sm-4 main-card'>
                            <div className='d-flex flex-column justify-content-between h-100'>
                                {leftContent}
                            </div>
                        </Card.Body>
                    </Card>
                </div>

                {layoutType !== 'full' && (
                    <div className={`right_content right_menu ${rightMenu ? 'open_menu' : ''}`}>
                        <Card className='mb-0 h-100'>
                            <Card.Body className='d-flex flex-column justify-content-between px-0 py-3 py-sm-4 main-card'>
                                {rightContent}
                            </Card.Body>
                        </Card>
                    </div>
                )}
            </div>
        </>
    );
};

InternalLayout.propTypes = {
    leftContent: PropTypes.node.isRequired,
    rightContent: PropTypes.node,
    pageTitle: PropTypes.string,
    layoutType: PropTypes.string,
    filterButton: PropTypes.node,
    headerButton: PropTypes.node,
    rightMaster: PropTypes.bool,
};

export default InternalLayout;
