import React from "react";

const ItemQuantity = ({ name, quantity }) => {
    return (
        <div className="d-flex align-items-center gap-3">
            {name}
            {quantity > 1 &&
                <div className="d-flex">
                    <span className="fw-500 text-dark" style={{'marginRight': '1px'}}>X</span>
                    <b>{quantity}</b>
                </div>
            }
        </div>
    );
}

export default ItemQuantity;