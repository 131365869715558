import axios from "axios";
import { servicePath } from "../utility/data";

// get all right menu item list based on page/type
export const getAllPanelProductMaster = (accessToken) => {

    let config = {
        method: 'GET',
        maxBodyLength: Infinity,
        headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'multipart/form-data'
        }
    };

    return axios
        .get(`${servicePath}/Panel/GetAllPanelProductMaster`, config)
        .then((res) => {
            return res;
        })
        .catch((error) => {
            return error;
        });
}

// get details of selected item in right menu
export const getPanelProductMaster = (accessToken, id) => {
    const formData = new FormData();
    formData.append("Id", id);

    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'multipart/form-data'
        }
    };

    return axios
        .post(`${servicePath}/Panel/GetPanelProductMaster`, formData, config)
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
};

// delete selected item
export const deleteRightItem = (accessToken, id) => {
    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    const formData = new FormData()
    formData.append("Id", id)
    return axios
        .post(`${servicePath}/Panel/DeletePanelProductMaster`, formData, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
}

// update order of selected item
export const updateOrder = (accessToken, id, direction) => {

    var requestBody = {
        "id": id,
        "sequenceNo": 0,
        "type": direction
    };

    const headers = {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
    }

    return axios
        .post(`${servicePath}/Panel/UpdatePanelMasterOrder`, requestBody, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
}

// update details of selected item
export const updateItemDetails = (accessToken, id, data) => {

    console.log(id, "ss")

    console.log("DATTTTT",data.usedInProductsList)

    const headers = {
        'accept': 'text/plain',
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'multipart/form-data'
    }

    const formData = new FormData();

    formData.append("Id", id);
    formData.append('Name', data.name);
    formData.append('Description', data.description || '');
    formData.append('Colours', data.colours || 1);
    formData.append('thickness', data.thickness || 28);
    formData.append('MaximumWidth', data.maxWidth || 1400);
    formData.append('MaximumHeight', data.maxHeight || 2500);
    formData.append('MaximumMSquar', data.maximumPower || 1.6);
    formData.append('HardwareCollectionId', data.collectionSelect || 1);
    formData.append('ProfilePath', data.filePath);
    formData.append('ProfilePathUrl', '');

    formData.append('UsedInProducts', '');

    return axios
        .post(`${servicePath}/Panel/AddUpdatePanelProductMaster`, formData, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
};

// add new right menu item based on page/type
export const addNewRightItem = (accessToken, data) => {
    const formData = new FormData();

    formData.append("Id", '');
    formData.append('Name', data);
    formData.append('Description', '');
    formData.append('Colours', 1);
    formData.append('Thickness', 28);
    formData.append('MaximumWidth', 1400);
    formData.append('MaximumHeight', 2500);
    formData.append('MaximumMSquar', 1.6);
    formData.append('HardwareCollectionId', 1);

    formData.append('UsedInProducts', '');

    const headers = {
        'accept': 'text/plain',
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'multipart/form-data'
    }

    return axios
        .post(`${servicePath}/Panel/AddUpdatePanelProductMaster`, formData, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
};