import React, { useEffect, useState } from 'react'
import CollectionsLeft from './collectionsleft'
import CollectionRight from './collectionright'
import InternalLayout from '../../../../../layouts/internalLayout'
import { getAllAccounts, getDiscountProducts, getSubAccountSettings } from '../../../../../services/accountservices'
import { Oval } from 'react-loader-spinner'
import { useMessage } from '../../../../../context/messageContext'
import { useNavigate } from 'react-router-dom'
import { useAncillary } from '../../../../../context/ancillarycontext'

const Collections = () => {
    const accessToken = localStorage.getItem('access_token')

    const [subAccounts, setSubAccounts] = useState([]);
    const [subAccountSettings, setSubAccountSettings] = useState()
    const [subAccountId, setSubAccountsId] = useState('')
    const [loading, setLoading] = useState(false)
    const [discountableProductList, setDiscountableProductList] = useState([])
    const [getSubAccountName, setGetSubAccountName] = useState('')

    let subAccountFirstElId = subAccounts && subAccounts[0];

    const navigate = useNavigate()

    const { setActiveIndex } = useAncillary();

    useEffect(() => {
        subAccountsServices()
    }, [])

    const { setMessage, setMessageType } = useMessage()

    useEffect(() => {
        if (subAccountId) {
            console.log("35");
            getDiscountProductsServices(subAccountId)
        }
    }, [subAccountId])

    useEffect(() => {
        if (subAccounts?.length > 0) {
            subAccountSettingServices(subAccountFirstElId?.id)

            setGetSubAccountName(subAccountFirstElId?.userName)

            setSubAccountsId(subAccountFirstElId?.id)
        }
    }, [subAccounts])

    useEffect(() => {
        if (subAccountSettings) {
            getDiscountProductsServices(subAccountSettings?.id)
        }
    }, [subAccountSettings])

    useEffect(() => {
        if (subAccountId) {
            subAccountSettingServices(subAccountId)
        }
    }, [subAccountId])

    const getDiscountProductsServices = async (id) => {
        const res = await getDiscountProducts(accessToken, id)

        if (res.statusCode === 200) {
            setDiscountableProductList(res.entity)
        } else if (res.statusCode === 404) {
            navigate('/error/404')
        } else {
            setMessage(res.message);
            setMessageType('error');
        }
    }


    const subAccountsServices = async () => {
        setLoading(true)
        const res = await getAllAccounts(accessToken)

        console.log(subAccountId, "after reset")

        if (res?.statusCode === 200) {
            setSubAccounts(res.entity)
            setTimeout(() => {
                setLoading(false)
            }, 1200);
        } else if (res?.statusCode === 404) {
            setTimeout(() => {
                setLoading(false)
            }, 1200);
            navigate('/error/404')
        } else {
            setTimeout(() => {
                setLoading(false)
            }, 1000)
            setMessage(res.message);
            setMessageType('error');
        }
    }

    const subAccountSettingServices = async (id) => {
        setLoading(true)
        const res = await getSubAccountSettings(accessToken, id)
        if (res?.statusCode === 200) {
            setSubAccountSettings(res.entity)
            setLoading(false)
        } else if (res?.statusCode === 404) {
            setLoading(false)
            navigate('/error/404')
        } else {
            setLoading(false)
            setMessage(res.message)
            setMessageType('error')
        }
    }

    const handleFormReset = () => {
        if (subAccountId) {
            subAccountsServices()
            setActiveIndex(0)
            subAccountSettingServices(subAccountId)
        }
    }

    const handleFormResetDiscount = () => {
        if (subAccountId) {
            getDiscountProductsServices(subAccountId)
        }
    }

    return (
        <>
            {loading &&
                <div className='loader_main full_loader'>
                    <Oval
                        height={50}
                        width={50}
                        color="#44C8F5"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                        ariaLabel='oval-loading'
                        secondaryColor="#44C8F5"
                        strokeWidth={2}
                        strokeWidthSecondary={2}
                    />
                </div>
            }
            <InternalLayout
                pageTitle="Sub Accounts"
                layoutType=""
                leftContent={<CollectionsLeft handleFormReset={handleFormReset} handleFormResetDiscount={handleFormResetDiscount} subAccountId={subAccountId} accessToken={accessToken} getSubAccountName={getSubAccountName} subAccountSettings={subAccountSettings} setLoading={setLoading} setDiscountableProductList={setDiscountableProductList} discountableProductList={discountableProductList} />}
                rightContent={<CollectionRight subAccounts={subAccounts} setSubAccountsId={setSubAccountsId} setGetSubAccountName={setGetSubAccountName} />} />
        </>
    )
}

export default Collections