import axios from "axios";
import { servicePath } from "../utility/data";

// Start: Sidebar APIs call
export const getAllPanelSidebarList = async (accessToken, setPanelMenuList, navigate) => {

    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    try {
        const res = await axios
            .get(`${servicePath}/Master/GetPanelNavigationMaster`, { headers });
        var panelMenus = res?.data?.entity?.result?.items;

        setPanelMenuList(panelMenus);

        localStorage.removeItem("network_error")
    } catch (error) {

        if (error?.response?.status === 401) {
            navigate('/unauthorized')

            localStorage.removeItem("access_token")
            // localStorage.removeItem("user_data")
        }

        if (error?.response?.status == 404) {
            navigate('/error/503')
        }

        if (error?.message === "Network Error") {
            navigate('/error/network')
        }

        return error;
    }
}

export const getPanelMenuListForPricing = async (accessToken, setPricingPanelMenuList, navigate) => {

    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    try {
        const res = await axios
            .get(`${servicePath}/Master/GetPanelMenuListForPricing`, { headers });

        var getNewList = res?.data?.entity?.result?.items;

        setPricingPanelMenuList(getNewList);
    } catch (error) {
        return error;
    }
}

export const getColourCollectionNavigaton = async (accessToken, setFrameProductsMenuList, navigate) => {

    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    try {
        const res = await axios
            .get(`${servicePath}/Master/GetColourCollectionNavigaton`, { headers });

        var getNewList = res?.data?.entity?.result?.items;

        setFrameProductsMenuList(getNewList);
    } catch (error) {
        return error;
    }
}

export const GetSashMenuListForPricing = async (accessToken, setPricingSashProductsMenuList, navigate) => {

    const formData = new FormData();

    formData.append("TypeId", 2);

    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    try {
        const res = await axios
            .post(`${servicePath}/Master/GetSashMenuListForPricing`, formData, { headers });

        var getNewList = res?.data?.entity?.result?.items;

        // console.log(getNewList, "getNewList")

        setPricingSashProductsMenuList(getNewList);
    } catch (error) {
        return error;
    }
}

// not in used
export const getAllFrameSidebarList = async (accessToken, setFrameMenuList, navigate) => {

    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    try {
        const res = await axios
            .get(`${servicePath}/Master/GetFrameNavigation`, { headers });
        // return res;
        var frameMenus = res?.data?.entity?.result?.items;

        setFrameMenuList(frameMenus);
    } catch (error) {


        return error;
    }
}

// not in used
export const GetFramestylesMenuListForPricing = async (accessToken, setPricingFrameStylesMenuList, navigate) => {

    const formData = new FormData();

    formData.append("TypeId", 1);

    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    try {
        const res = await axios
            .post(`${servicePath}/Master/GetFramestylesMenuListForPricing`, formData, { headers });

        var getNewList = res?.data?.entity?.result?.items;

        // console.log(getNewList, "getNewList")

        setPricingFrameStylesMenuList(getNewList);
    } catch (error) {
        return error;
    }
}

// not in used
export const getFrameProductsSidebarList = async (accessToken, setFrameProductsMenuList, navigate) => {

    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    try {
        const res = await axios
            .get(`${servicePath}/Master/GetSystemStyleNavigaton`, { headers });

        var frameProductMenus = res?.data?.entity?.result?.items;

        setFrameProductsMenuList(frameProductMenus);
    } catch (error) {
        return error;
    }
}

// not in used
export const GetFrameProductsMenuListForPricing = async (accessToken, setPricingFrameProductsMenuList, navigate) => {

    const formData = new FormData();

    formData.append("TypeId", 1);

    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    try {
        const res = await axios
            .post(`${servicePath}/Master/GetSashMenuListForPricing`, formData, { headers });

        var getNewList = res?.data?.entity?.result?.items;

        // console.log(getNewList, "getNewList")

        setPricingFrameProductsMenuList(getNewList);
    } catch (error) {
        return error;
    }
}

// not in used
export const GetInstallFramestylesMenuListForPricing = async (accessToken, setPricingFrameStylesMenuList, navigate) => {

    const formData = new FormData();

    formData.append("TypeId", 2);

    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    try {
        const res = await axios
            .post(`${servicePath}/Master/GetFramestylesMenuListForPricing`, formData, { headers });

        var getNewList = res?.data?.entity?.result?.items;

        // console.log(getNewList, "getNewList")

        setPricingFrameStylesMenuList(getNewList);
    } catch (error) {
        return error;
    }
}

// End: Sidebar APIs call