import React, { useState, useEffect } from 'react'
import { Form } from 'react-bootstrap';
import { MdOutlineCreate, MdOutlineKeyboardArrowDown } from 'react-icons/md';
import { MenuItem, OutlinedInput, Select } from 'material-ui-core';
import {
    Row,
    Col,
    Button,
    FormGroup,
    Label,
    Input,
} from 'reactstrap';
import { HornOptions, MenuProps, noSelectOptions } from '../utility/data';
import { useSashHangingContext } from '../context/sashHangingContext';
import NumberInput from './NumberInput';
import NumberInputDecimal from './NumberInputDecimal';
import { GetHangingMaster } from '../services/commonServices';

const SystemSashHangingLeft = ({ isEditable, setLoading, allRightListItems, setShowSashHangingModal, activeTab, accessToken, selectItemDetails, updateItemDetailsServices, handleFormReset, getRightMenuListData, sashId }) => {

    const { timestamp, fetchSelected, handleEdit, setSelected } = useSashHangingContext()

    const [sashHangingDropOptions, setSashHangingDropOptions] = useState([]);

    const [itemId, setItemId] = useState('')
    const [sashHanging, setSashHanging] = useState("")

    const [name, setName] = useState('')
    const [hanging, setHanging] = useState("")
    const [horns, setHorns] = useState("")

    const [minWidth, setMinWidth] = useState("0")
    const [minHeight, setMinHeight] = useState("0")
    const [maxWidth, setMaxWidth] = useState("0")
    const [maxHeight, setMaxHeight] = useState("0")
    const [maximumPower, setMaximumPower] = useState("0")
    const [hornLength, setHornLength] = useState("0")
    const [mirrorLines, setMirrorLines] = useState(false)
    const [frameColour, setFrameColour] = useState(false)
    const [defaultHanging, setDefaultHanging] = useState(false)
    const [hangingOptions, setHangingOptions] = useState([])

    const [nameError, setNameError] = useState(false)

    useEffect(() => {
        if (activeTab === "Sash hanging" && allRightListItems && allRightListItems.length > 0) {
            setSashHangingDropOptions(allRightListItems)
        } else {
            setSashHangingDropOptions(noSelectOptions)
        }
    }, [activeTab, allRightListItems])

    useEffect(() => {
        if (activeTab === "Sash hanging") {
            getHangingMaster()
        }
    }, [activeTab])

    useEffect(() => {
        if (sashHangingDropOptions && sashHangingDropOptions.length > 0) {
            setSashHanging(selectItemDetails?.id || sashHangingDropOptions[0]?.id)
        } else {
            setSashHanging('')
        }
    }, [sashHangingDropOptions, selectItemDetails])

    // setting all the value for prefilled inputs
    useEffect(() => {
        console.log(selectItemDetails, "data left")

        setItemId(selectItemDetails?.id || '')
        setName(selectItemDetails?.name || '')

        setMinWidth(selectItemDetails?.minimumWidth || 0)
        setMinHeight(selectItemDetails?.minimumHeight || 0)

        setMaxWidth(selectItemDetails?.maximumWidth || 0)
        setMaxHeight(selectItemDetails?.maximumHeight || 0)
        setMaximumPower(selectItemDetails?.maximumMSquar || 0)
        setHornLength(selectItemDetails?.hornLength || 0)


        setMirrorLines(selectItemDetails?.mirrorLines || false)
        setFrameColour(selectItemDetails?.applyFrameColourPercentage || false)
        setDefaultHanging(selectItemDetails?.defaultHanging || false)

        setNameError(false)
    }, [selectItemDetails])

    useEffect(() => {
        if (hangingOptions && hangingOptions.length > 0) {
            setHanging(selectItemDetails?.hanging || hangingOptions[0]?.id)
        }
    }, [hangingOptions, selectItemDetails])

    useEffect(() => {
        if (HornOptions && HornOptions.length > 0) {
            setHorns(selectItemDetails?.horns || HornOptions[0]?.id)
        }
    }, [HornOptions, selectItemDetails])

    const getHangingMaster = async () => {
        const res = await GetHangingMaster(accessToken, sashId);
        if (res && res.status === 200) {
            if (res?.data?.statusCode === 200) {
                setHangingOptions(res?.data?.entity);
            }
        }
    };

    const handleNameChange = (e) => {
        const value = e.target.value

        if (e.target.value?.length <= 50) {
            setName(value)
            handleEdit(value)
            setSelected(value)
        }

        if (value.length > 50) {
            setNameError(true)
        } else {
            setNameError(false)
        }
    }

    const handleMirrorLineChange = (e) => {
        const value = e.target.checked

        setMirrorLines(value)
    }

    const handleFrameColorChange = (e) => {
        const value = e.target.checked

        setFrameColour(value)
    }

    const handleDefaultChange = (e) => {
        const value = e.target.checked

        setDefaultHanging(value)
    }

    const handleItemChange = (e) => {
        const value = e.target.value

        setSashHanging(e.target.value)

        var currentIndex = sashHangingDropOptions.findIndex(obj => obj.id === e.target.value);

        fetchSelected(currentIndex, value);
    }

    const handleFormSubmit = () => {

        var formDataValues = {
            "id": selectItemDetails.id,
            "sashProductMasterId": selectItemDetails.sashProductMasterId,
            "sashProductGeneralId": selectItemDetails.sashProductGeneralId,
            "name": name,
            "minimumWidth": minWidth,
            "minimumHeight": minHeight,
            "maximumWidth": maxWidth,
            "maximumHeight": maxHeight,
            "maximumMSquar": maximumPower,
            "mirrorLines": mirrorLines,
            "applyFrameColourPercentage": frameColour,
            "defaultHanging": defaultHanging,
            "hanging": hanging,
            "horns": horns,
            "hornLength": hornLength ? hornLength : "",
        }

        updateItemDetailsServices(formDataValues)
    }

    return (

        <>
            <Form className='h-100 d-flex flex-column flex-grow-1 justify-content-between'>
                <div className='inside_scroll has_tab has_btns has_head'>
                    <Row>
                        <Col lg="6" className='mb-3'>
                            <FormGroup className="mb-0">
                                <Select
                                    labelId="sashHanging"
                                    id="sashHanging"
                                    value={sashHanging || ''}
                                    label="sashHanging"
                                    onChange={(e) => { handleItemChange(e) }}
                                    input={<OutlinedInput className='w-100' name='sashHanging' />}

                                    MenuProps={MenuProps}

                                    IconComponent={() => (
                                        <MdOutlineKeyboardArrowDown size={20} className='me-1 MuiSvgIcon-root MuiSelect-icon' />
                                    )}
                                >
                                    {sashHangingDropOptions && sashHangingDropOptions.map((o, index) => (
                                        <MenuItem key={index} value={o?.id}>
                                            {o?.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormGroup>
                        </Col>

                         {isEditable && (
                            <Col lg="6" className='mb-3'>
                                <FormGroup className="mb-0">
                                    <Button
                                        color="primary"
                                        outline
                                        size="md"
                                        className='rounded'
                                        onClick={() => { setShowSashHangingModal(true) }}
                                    >
                                        <MdOutlineCreate size={20} className="me-2" />
                                        Manage sash hanging
                                    </Button>
                                </FormGroup>
                            </Col>
                         )}           
                    </Row>

                    <Row className={`${!isEditable ? "not_allowed" : ''}`}>
                        <Col lg="6">
                            <FormGroup className="form-group">
                                <Label for="name">
                                    Name
                                </Label>
                                <Input className='mb-1' name='name' value={name} onChange={(e) => handleNameChange(e)} />

                                {nameError && timestamp && itemId && (
                                    <div className='mt-1 text-muted text-small'>Max 50 characters are allowed</div>
                                )}

                                {(!name && timestamp && itemId) && (
                                    <div className='mt-1 text-danger text-small'>Name is required</div>
                                )}
                            </FormGroup>
                        </Col>

                        <Col lg="6">
                            <FormGroup className="form-group">
                                <Label for="hangingSelect">
                                    Hanging
                                </Label>

                                <Select
                                    labelId="hangingSelect"
                                    id="hangingSelect"
                                    value={hanging}
                                    label="hangingSelect"
                                    onChange={(e) => { setHanging(e.target.value) }}
                                    input={<OutlinedInput className='w-100' name='hangingSelect' />}

                                    MenuProps={MenuProps}

                                    IconComponent={() => (
                                        <MdOutlineKeyboardArrowDown size={20} className='me-1 MuiSvgIcon-root MuiSelect-icon' />
                                    )}
                                >
                                    {hangingOptions && hangingOptions.map((o, index) => (
                                        <MenuItem key={index} value={o?.id}>
                                            {o?.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormGroup>
                        </Col>

                        <Col lg="6">
                            <FormGroup className="form-group">
                                <Label for="minWidth">Minimum width</Label>

                                <NumberInputDecimal
                                    name="minWidth"
                                    value={minWidth}
                                    setValue={setMinWidth}
                                />
                            </FormGroup>
                        </Col>

                        <Col lg="6">
                            <FormGroup className="form-group">
                                <Label for="minHeight">Minimum height</Label>

                                <NumberInputDecimal
                                    name="minHeight"
                                    value={minHeight}
                                    setValue={setMinHeight}
                                />
                            </FormGroup>
                        </Col>

                        <Col lg="6">
                            <FormGroup className="form-group">
                                <Label for="maxWidth">Maximum width</Label>

                                <NumberInputDecimal
                                    name="maxWidth"
                                    value={maxWidth}
                                    setValue={setMaxWidth}
                                />
                            </FormGroup>
                        </Col>

                        <Col lg="6">
                            <FormGroup className="form-group">
                                <Label for="maxHeight">Maximum height</Label>

                                <NumberInputDecimal
                                    name="maxHeight"
                                    value={maxHeight}
                                    setValue={setMaxHeight}
                                />
                            </FormGroup>
                        </Col>

                        <Col lg="6">
                            <FormGroup className="form-group">
                                <Label for="maximumPower">Maximum m<sup>2</sup> </Label>

                                <NumberInput
                                    name="maximumPower"
                                    value={maximumPower}
                                    setValue={setMaximumPower}
                                />
                            </FormGroup>
                        </Col>

                        <Col lg="6">
                            <FormGroup className="form-group">
                                <Label for="hornsSelect">
                                    Horns
                                </Label>

                                <Select
                                    labelId="hornsSelect"
                                    id="hornsSelect"
                                    value={horns}
                                    label="hornsSelect"
                                    onChange={(e) => { setHorns(e.target.value) }}
                                    input={<OutlinedInput className='w-100' name='hornsSelect' />}

                                    MenuProps={MenuProps}

                                    IconComponent={() => (
                                        <MdOutlineKeyboardArrowDown size={20} className='me-1 MuiSvgIcon-root MuiSelect-icon' />
                                    )}
                                >
                                    {HornOptions && HornOptions.map((o, index) => (
                                        <MenuItem key={index} value={o?.id}>
                                            {o?.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormGroup>
                        </Col>
                        {horns === 1 ? "" :
                            <Col lg="6">
                                <FormGroup className="form-group">
                                    <Label for="hornLength">Horn Length </Label>

                                    <NumberInput
                                        name="hornLength"
                                        value={hornLength}
                                        setValue={setHornLength}
                                    />
                                </FormGroup>
                            </Col>}
                    </Row>

                    <div className={`d-flex flex-wrap gap-4 ${!isEditable ? "not_allowed" : ''}`}>
                        <div className='min-w-120'>
                            <FormGroup className="form-group">
                                <Label for="mirrorLines">
                                    Mirror lines
                                </Label>
                                <Form.Check // prettier-ignore
                                    type="switch"
                                    id="mirrorLines"
                                    className='form-switch-lg default_switch'
                                    onChange={(e) => handleMirrorLineChange(e)}
                                    label={mirrorLines ? 'On' : 'Off'}
                                    checked={mirrorLines}
                                />
                            </FormGroup>
                        </div>

                        <div className='min-w-180'>
                            <FormGroup className="form-group">
                                <Label for="frameColour">
                                    Apply frame colour %
                                </Label>
                                <Form.Check // prettier-ignore
                                    type="switch"
                                    id="frameColour"
                                    className='form-switch-lg default_switch'
                                    onChange={(e) => handleFrameColorChange(e)}
                                    label={frameColour ? 'On' : 'Off'}
                                    checked={frameColour}
                                />
                            </FormGroup>
                        </div>

                        <div className='min-w-120'>
                            <FormGroup className="form-group">
                                <Label for="defaultSwitch">
                                    Default hanging
                                </Label>
                                <Form.Check // prettier-ignore
                                    type="switch"
                                    id="defaultSwitch"
                                    className='form-switch-lg default_switch'
                                    onChange={(e) => handleDefaultChange(e)}
                                    label={defaultHanging ? 'On' : 'Off'}
                                    checked={defaultHanging}
                                />
                            </FormGroup>
                        </div>
                    </div>
                </div>

                {isEditable && (
                    <div className='d-flex gap-2 gap-xl-3 main_buttons'>
                        <Button
                            color="primary"
                            size="md"
                            className='px-4'
                            onClick={() => handleFormSubmit()}
                            disabled={!timestamp || !name.trim()}
                        >
                            Save Changes
                        </Button>

                        <Button
                            color="primary"
                            size="md"
                            outline
                            className='px-4'
                            onClick={() => handleFormReset()}
                            disabled={!timestamp}
                        >
                            Reset
                        </Button>
                    </div>
                )}
            </Form>
        </>
    )
}

export default SystemSashHangingLeft