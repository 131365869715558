import * as THREE from 'three';
import * as BufferGeometryUtils from 'three/addons/utils/BufferGeometryUtils.js';
import { createSashPath } from './lineShapes';


export let hangingRefs = []

const storeHangingRefs = (obj ,center  , callBack) => { 
    
    obj.position.set(center.x , center.y , center.z)
    hangingRefs.push(obj) 
    if(typeof callBack === 'function'){
        callBack(hangingRefs)
    }
}


export const getSashHangingRefs = () =>{
    return hangingRefs
}


export const removeHangingRefs = () => {
    hangingRefs = []
}



export function createSash(sashes, group, frameSides, frameWidth, frameHeight, sashHangingValue, sashGroup) {
    removeHangingRefs()
    const offsetL = frameSides.left.dimensions.height, 
    // offsetT = frameSides.top.dimensions.height,
    offsetT = 0.05,
    offsetR = frameSides.right.dimensions.height, 
    offsetB = frameSides.bottom.dimensions.height;
    const setSashHangingNo = sashHangingValue;
    // const sashMaterial = new THREE.MeshPhongMaterial({ color: 0x0a0a0d });
    // const sashMaterial = new THREE.MeshPhongMaterial({ color: 0x44c8f5 });
    const sashMaterial = new THREE.MeshPhongMaterial({
        // color: 0xffffff,
        color: 0x0a0a0d,
        specular: 0xffffff,
        shininess: 27.5,
        flatShading: false
     });
     sashGroup.current = [];
    const extrudeSettings = (path) => ({
        steps: 100,
        extrudePath: path,
        bevelEnabled: true,
        bevelThickness: 0.01,
        bevelSize: 0.01,
        bevelOffset: 0,
        bevelSegments: 5,
        curveSegments: 12 
    });
    const sashPaths = ["SashTop", "SashRight", "SashBottom", "SashLeft"];
    // const sashPaths = ["SashRight"];
    // frameSides.top.dimensions.height
    const sashWidth = (frameWidth - (offsetL + offsetR)) / setSashHangingNo;

    let offset = -frameWidth / 2 + sashWidth / 2 + offsetL;
    const sashShape = createShapeFromWidthHeight(0.08, 0.05);

    for (let index = 0; index < setSashHangingNo; index++) {

        let paths = [];
        //     const element = array[index];
        // }
        // sashes.splits.forEach((sash, index)=>{

        const sashHeight = frameHeight - (offsetT + offsetB);
        sashPaths.forEach((sashPosition) => {
            const sashPath = createSashPath(sashWidth, sashHeight, sashPosition, offset);
            paths.push(sashPath);
            // const sashGeometry = new THREE.ExtrudeGeometry(sashShape, extrudeSettings(sashPath));
            const sashGeometry1 = new THREE.ExtrudeGeometry(sashShape, extrudeSettings(sashPath));
            const sashGeometry = BufferGeometryUtils.mergeVertices(sashGeometry1);
            sashGeometry.computeVertexNormals();
            const sashMesh = new THREE.Mesh(sashGeometry, sashMaterial);
            sashMesh.position.y -= (offsetT - offsetB) / 2;
            
            // sashMesh.position.y += 0.01;
            sashMesh.castShadow = true;
            sashMesh.receiveShadow = true;
            sashMesh.name = sashPosition;
            group.add(sashMesh);
            sashGroup.current.push(sashMesh);

        })
    
        const glassThickness = 0.02;
        const glassMaterial = new THREE.MeshPhysicalMaterial({
            color: 0xADD8E6,
            roughness: 0.05,
            metalness: 0.9,
            reflectivity: 0.6,
            clearcoat: 1,
            clearcoatRoughness: 0.05,
            envMapIntensity: 1,
            opacity: 0.5,
            transparent: true
        });
        const glassShape = getGlassShape(paths);
        const glassExtrudeSettings = {
            depth: glassThickness,
            bevelEnabled: false,
        };

        const glassGeometry = new THREE.ExtrudeGeometry(glassShape, glassExtrudeSettings);
        const glassMesh = new THREE.Mesh(glassGeometry, glassMaterial);
        glassMesh.name = "GlassPanel";
        glassMesh.scale.set(0.999, 0.999, 1);
        glassMesh.position.z = -glassThickness * 0.5;
        group.add(glassMesh);
        console.log(glassMesh , "109");

        const boundingBox = new THREE.Box3().setFromObject(glassMesh);
        const center = new THREE.Vector3()
        boundingBox.getCenter(center)                  
        const newFrameMesh = glassMesh.clone()

        storeHangingRefs(newFrameMesh , center , getSashHangingRefs)
        offset += sashWidth;
    };

}



function getGlassShape(paths){
    
    const shape = new THREE.Shape();
    if (paths[0])shape.moveTo(paths[0].v1.x, paths[0].v1.y);
    paths.forEach(path => {
        if (path)shape.lineTo(path.v2.x, path.v2.y);
    });
    shape.lineTo(paths[0].v1.x, paths[0].v1.y);
    return shape;
}
function createShapeFromWidthHeight(width, height){
    // const arcRatio = 0.001;
    const arcRatio = 0;
    // const blockShape = new THREE.Shape();
    // blockShape.moveTo(-width / 2, 0);//top left
    // blockShape.lineTo(width / 2, 0);//top right
    // blockShape.lineTo(width / 2, -height);//bottom right
    // blockShape.lineTo(-width / 2, -height);//bottom left
    // blockShape.lineTo(-width / 2, 0);//top left
    // return blockShape;

    // const blockShape = new THREE.Shape();
    // blockShape.moveTo(-width / 2, 0);//top left
    // blockShape.lineTo(width / 2, 0);//top right
    // blockShape.quadraticCurveTo(
    //     (width / 2) + arcRatio, -height / 2, 
    //     width / 2, -height); // Smooth arc to the left-center
    // // blockShape.lineTo(width / 2, -height);//bottom right
    // blockShape.lineTo(-width / 2, -height);//bottom left
    // blockShape.lineTo(-width / 2, 0);//top left
    // return blockShape;

    // const blockShape = new THREE.Shape();
    // blockShape.moveTo(-width / 2, 0);                             // Start at top-left

    // // Create a smooth arc to the left-center with a slight width increase
    // const leftControlX = -(width / 2) - arcRatio;                     // Control point x for left side
    // const leftControlY = -height / 4;                             // Control point y (midway along the y-axis)
    // blockShape.quadraticCurveTo(leftControlX, 0, leftControlX, -height / 2); // Smooth arc to the left-center

    // blockShape.lineTo(-width / 2, -height);                       // Move to bottom-left
    // blockShape.lineTo(width / 2, -height);                        // Move to bottom-right

    // // Create a smooth arc to the right-center with a slight width increase
    // const rightControlX = (width / 2) + arcRatio;                     // Control point x for right side
    // const rightControlY = -height / 4;                            // Control point y (midway along the y-axis)
    // blockShape.quadraticCurveTo(rightControlX, -height, rightControlX, -height / 2); // Smooth arc to the right-center

    // blockShape.lineTo(width / 2, 0);                              // Move to top-right
    // blockShape.lineTo(-width / 2, 0);                             // Close the shape back to top-left

    // return blockShape;

    const frameShape = new THREE.Shape();
    const radius = 0.0001;  // Radius for rounded corners

    // Start from the top left, adjust the coordinates to include radius.
    frameShape.moveTo(-width / 2 + radius, 0);

    // Top edge (left to right)
    frameShape.lineTo(width / 2 - radius, 0);
    frameShape.absarc(width / 2 - radius, -radius, radius, Math.PI / 2, 0, true);

    // Right edge (top to bottom)
    frameShape.lineTo(width / 2, -height + radius);
    frameShape.absarc(width / 2 - radius, -height + radius, radius, 0, -Math.PI / 2, true);

    // Bottom edge (right to left)
    frameShape.lineTo(-width / 2 + radius, -height);
    frameShape.absarc(-width / 2 + radius, -height + radius, radius, -Math.PI / 2, -Math.PI, true);

    // Left edge (bottom to top)
    frameShape.lineTo(-width / 2, -radius);
    frameShape.absarc(-width / 2 + radius, -radius, radius, Math.PI, Math.PI / 2, true);

    return frameShape;
}