import React, { useEffect, useRef, useState, useCallback } from "react";
import { BiChevronDown } from "react-icons/bi";
import { Label, Input } from "reactstrap";

const SearchWithCheckboxes = ({
  list,
  title,
  subtitle,
  placeholderText,
  activeValues,
  onSelect,
  onRemove,
  searchKeys = [],
  isCheckbox = false,
  isSingleSelect = false,
  disabled,
}) => {
  const [query, setQuery] = useState("");
  const [filteredSuggestions, setFilteredSuggestions] = useState(list);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [selectedItems, setSelectedItems] = useState(isSingleSelect ? {} : []);

  const suggestionRef = useRef(null);

  useEffect(() => {
    setFilteredSuggestions(list || []);
  }, [list]);

  useEffect(() => {
    if (activeValues) {
      setSelectedItems(activeValues || (isSingleSelect ? {} : []));
    }
  }, [activeValues]);

  const handleClickOutside = useCallback(
    (event) => {
      if (suggestionRef.current && !suggestionRef.current.contains(event.target)) {
        setShowSuggestions(false);
      }
    },
    [suggestionRef]
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside]);

  const handleChange = (e) => {
    const inputValue = e.target.value;
    setQuery(inputValue);

    if (inputValue.length > 0) {
      const filtered = list.filter((item) =>
        searchKeys.some((key) =>
          item[key]?.toString().toLowerCase().includes(inputValue.toLowerCase())
        )
      );
      setFilteredSuggestions(filtered);
    } else {
      setFilteredSuggestions(list);
    }
  };

  const handleCheckbox = (suggestion) => {
    setSelectedItems((prevSelectedItems) => {
      const isSelected = isSingleSelect
        ? prevSelectedItems.id === suggestion.id
        : prevSelectedItems?.some((item) => item.id === suggestion.id);

      if (isSelected) {
        onRemove(suggestion);
        return isSingleSelect
          ? {}
          : prevSelectedItems.filter((item) => item.id !== suggestion.id);
      } else {
        onSelect(suggestion);
        
        return isSingleSelect ? suggestion : [...prevSelectedItems, suggestion];
      }
    });
  };

  const ListWithChecks = () => (
    <ul className="suggestions pt-2">
      {filteredSuggestions.map((suggestion, index) => (
        <li
          key={index}
          className={`select-opt ${isSingleSelect
            ? selectedItems.id === suggestion.id
            : selectedItems.some((item) => item.id === suggestion.id)
              ? "active"
              : ""
            }`}
        >
          <div className="form-check mb-0 w-100 p-0">
            <Label className="mb-0 d-flex select-item">
              <Input
                type="checkbox"
                className="form-check-input"
                checked={
                  isSingleSelect
                    ? selectedItems.id === suggestion.id
                    : selectedItems.some((item) => item.id === suggestion.id)
                }
                onChange={() => handleCheckbox(suggestion)}
              />
              <div className="ms-2">
                <h6 className="mb-0">{suggestion[title]}</h6>
                <span className="text-muted">{suggestion[subtitle]}</span>
              </div>
            </Label>
          </div>
        </li>
      ))}
    </ul>
  );

  const ListWithoutChecks = () => (
    <ul className="suggestions pt-2">
      {filteredSuggestions.map((suggestion, index) => (
        <li
          key={index}
          className={`select-opt ${(selectedItems.id == suggestion.id || activeValues?.id === suggestion.id) ? 'active' : ''}`}
          onClick={() => handleCheckbox(suggestion)}
        >
          <div className="d-flex flex-column w-100 select-item">
            <h6 className="mb-0">{suggestion[title]}</h6>
            <div className="text-muted">{suggestion[subtitle]}</div>
          </div>
        </li>
      ))}
    </ul>
  );

  const renderSuggestions = () => {
    if (showSuggestions) {
      if (filteredSuggestions.length > 0) {
        return (
          <div className={`suggestions_wrap ${isSingleSelect ? 'is_single' : ''}`} ref={suggestionRef}>
            {isCheckbox ? (
              <ListWithChecks />
            ) : (
              <>
                <div className="p-2">
                  <input
                    className="form-control search_box"
                    type="text"
                    onChange={handleChange}
                    value={query}
                    placeholder={placeholderText || "Search..."}
                  />
                </div>
                <ListWithoutChecks />
              </>
            )}
          </div>
        );
      } else {
        return (
          <div className="suggestions_wrap">
            {!isCheckbox && (
              <div className="p-2">
                <input
                  className="form-control"
                  type="text"
                  onChange={handleChange}
                  value={query}
                  placeholder={placeholderText || "Search..."}
                />
              </div>
            )}
            <div className="suggestions no-suggestions">No suggestions available</div>
          </div>
        );
      }
    }
    return null;
  };

  const handleFocus = () => {
    if (!disabled) {
      console.log(showSuggestions, 'showSuggestions')
      setShowSuggestions(!showSuggestions);
    }
  };

  return (
    <div className="search_suggestion">
      <div className="position-relative d-flex align-items-center" onClick={handleFocus}>
        {isCheckbox ? (
          <input
            className="form-control"
            type="text"
            value={query}
            placeholder={placeholderText || "Search..."}
            onChange={handleChange}
            disabled={disabled}
          />
        ) : (

          <>
            {/* <input
              className="form-control"
              type="text"
              value={activeValues?.name}
              placeholder="Select"
              onFocus={handleFocus}
            /> */}

            <div className={`form-control input_select text-truncate ${disabled ? "disabled" : ''}`}>
              {activeValues?.name}
            </div>
          </>
        )}

        <BiChevronDown className={`toggle_icon ${showSuggestions ? "active" : ""}`} size={20} />
      </div>
      {renderSuggestions()}
    </div>
  );
};

export default SearchWithCheckboxes;