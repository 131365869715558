import React from 'react'
import ErrorLayout from './error-layout'

const Error419 = () => {
    return (
        <>
            <ErrorLayout 
                code={<h1 className="display-1 fw-medium">419</h1>} 
                text="Page expired"
                subText="Sorry your session has expired. Please refresh and try again"
            />
        </>
    )
}

export default Error419