import React from 'react';
import { Image } from 'react-bootstrap';
import { BsTools } from 'react-icons/bs';

const HardwareListItem = ({ data, handleHardwareOption, getLength }) => {

    return (
        <div
            className="position-relative d-flex align-items-center cursor-pointer"
            onClick={() => {
                handleHardwareOption(data[0]);
            }}
        >
            <div className={`right_item w-100`}>
                <span className="me-2">
                    {data[0]?.imagePath ? (
                        <Image src={data[0]?.imagePath} width={50} height={50} />
                    ) : (
                        <BsTools color='#989898' size={20} />
                    )}
                </span>
                <div className="right_title">
                    <span className="text-truncate text-capitalize">
                        {data[0]?.type}{" "}
                        {getLength(data)}
                    </span>
                    <span className="text-truncate d-block text-small">
                        {data[0]?.name}
                    </span>
                    <span className="text-truncate d-block text-small">
                        {data[0]?.color}
                    </span>
                </div>
            </div>
        </div>
    );
}

export default HardwareListItem