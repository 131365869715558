import React from "react";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import { Input } from "reactstrap";

const NumberInputNegative = ({ name, value, setValue, disabled, className }) => {

    const handleValueUpdate = (e, type) => {
        e.preventDefault();

        if (!disabled) {
            let newVal = parseInt(value) || 0; // Default to 0 if value is empty or NaN

            if (type === 'minus') {
                newVal = newVal - 1;
            } else {
                newVal = newVal + 1;
            }

            setValue(newVal);
        }
    };

    const handleOnBlur = (e) => {
        // Allow only positive and negative integers (block non-numeric characters)
        e.target.value = e.target.value.replace(/[^-0-9]/g, ''); // Allows digits and a minus sign

        // If the input is not a valid integer, reset to the current value
        if (e.target.value === "" || isNaN(parseInt(e.target.value, 10))) {
            e.target.value = value; // Revert to the current value if invalid
        } else {
            setValue(parseInt(e.target.value, 10)); // Update value with valid integer
        }
    };

    return (
        <>
            <div className='input_with_buttons'>
                <span className='up_down_btns'>
                    <span onClick={(e) => { handleValueUpdate(e, 'plus') }}>
                        <MdKeyboardArrowUp />
                    </span>
                    <span onClick={(e) => { handleValueUpdate(e, 'minus') }}>
                        <MdKeyboardArrowDown />
                    </span>
                </span>
                <Input 
                    type="number" 
                    className={`mb-1 ${className}`} 
                    id={name} 
                    name={name} 
                    value={value} 
                    disabled={disabled} 
                    onChange={(e) => setValue(e.target.value)} 
                    onInput={(e) => { handleOnBlur(e) }} 
                />
            </div>
        </>
    );
}

export default NumberInputNegative;
