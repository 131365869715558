import * as THREE from 'three';


export const getHeightFromAngle = (startAngle, fheight) => {
    // Convert the angle from degrees to radians because JavaScript trigonometric functions use radians
    const angleRadians = (startAngle * Math.PI) / 180;

    // Calculate the Y offset using cosine or sine
    // Here we use cosine to get the height component
    const yOffset = fheight * Math.cos(angleRadians);

    return yOffset;
}


export const getAngleFromLine = (lineCurve) => {
    const v1 = lineCurve.v1 || lineCurve.points[0];
    const v2 = lineCurve.v2 || lineCurve.points[lineCurve.points.length - 1];
    
    // Calculate the vector between the points
    const directionVector = new THREE.Vector3().subVectors(v2, v1);
    
    // Calculate the angle with the positive X-axis
    const angleRadians = directionVector.angleTo(new THREE.Vector3(1, 0, 0));
    
    // Convert radians to degrees
    const angleDegrees = angleRadians * (180 / Math.PI);
    const nearest_multiple = Math.round(angleDegrees / 90) * 90;
    const difference = Math.abs(angleDegrees - nearest_multiple)
    return difference
    // return angleDegrees;
  }


export const updateFrameJoint = (jointType, pathPos, mesh, fheight, wheight, wwidth, startOffset, endOffset, startAngle, endAngle, framePath) => {

    if(startOffset == false && endOffset == false)return;
    let startAngleWidth = 0, endAngleWidth = 0;
    if(startAngle){
        startAngleWidth = getHeightFromAngle(startAngle, fheight);
    }
    if(endAngle){
        endAngleWidth = getHeightFromAngle(endAngle, fheight);
    }
    const geometry = mesh.geometry || mesh;
    geometry.computeBoundingBox();
    const boundingBox = geometry.boundingBox;
    // const boundingBox = new THREE.Box3().setFromObject(geometry);
    const boundHeight = boundingBox.max.y - boundingBox.min.y;
    const boundWidth = boundingBox.max.x - boundingBox.min.x;

    const ftop = wheight / 2;
    const fright = wwidth/2;
    const fbot = -wheight/2;
    const fleft = -wwidth/2;    
    if(jointType == "mitre"){
        const scaleOffset = 0.000;
        if(pathPos == "top"){
            let rightSlant = endAngleWidth || (1 - (fright - boundingBox.max.x)) * fheight;
            let leftSlant = startAngleWidth || (1 - (fleft - boundingBox.min.x)) * fheight;
            if(!startOffset)leftSlant = 0;
            if(!endOffset)rightSlant = 0;
            for (let id = 0; id < geometry.attributes.position.array.length; id+=(3)) {
                if(geometry.attributes.position.array[id+1] < (ftop - (fheight/2))){
                    if(geometry.attributes.position.array[id] > boundingBox.max.x - leftSlant){
                        geometry.attributes.position.array[id] = boundingBox.max.x - leftSlant
                    }
                    if(geometry.attributes.position.array[id] < boundingBox.min.x + rightSlant){
                        geometry.attributes.position.array[id] = boundingBox.min.x + rightSlant
                    }
                }
            }
            // const scaleFactor = (1 - ((wwidth - (fheight * 2)) / wwidth)) - scaleOffset;
            // for (let id = 0; id < geometry.attributes.position.array.length; id+=(3)) {
            //     if(geometry.attributes.position.array[id+1] < (ftop - (fheight/2))){
            //         geometry.attributes.position.array[id] -= geometry.attributes.position.array[id] * scaleFactor;
            //     }
            // }
        }
        if(pathPos == "bottom"){
            let rightSlant = endAngleWidth || (1 - (fright - boundingBox.max.x)) * fheight;
            let leftSlant = startAngleWidth || (1 - (fleft - boundingBox.min.x)) * fheight;
            if(!startOffset)leftSlant = 0;
            if(!endOffset)rightSlant = 0;
            for (let id = 0; id < geometry.attributes.position.array.length; id+=(3)) {
                if(geometry.attributes.position.array[id+1] > (fbot + (fheight/2))){
                    if(geometry.attributes.position.array[id] > boundingBox.max.x - leftSlant){
                        geometry.attributes.position.array[id] = boundingBox.max.x - leftSlant
                    }
                    if(geometry.attributes.position.array[id] < boundingBox.min.x + rightSlant){
                        geometry.attributes.position.array[id] = boundingBox.min.x + rightSlant
                    }
                }
            }
            // let scaleFactor = (1 - ((wwidth - (fheight * 2)) / wwidth)) - scaleOffset;
            // for (let id = 0; id < geometry.attributes.position.array.length; id+=(3)) {
            //     if(geometry.attributes.position.array[id+1] > (fbot + (fheight/2))){
            //         geometry.attributes.position.array[id] -= geometry.attributes.position.array[id] * scaleFactor;
            //     }
            // }
        }
        if(pathPos == "right"){
            let topSlant = endAngleWidth || (1 - (ftop - boundingBox.max.y)) * fheight;
            let botSlant = startAngleWidth || (1 - (fbot - boundingBox.min.y)) * fheight;
            if(!startOffset)topSlant = 0;
            if(!endOffset)botSlant = 0;
            for (let id = 1; id < geometry.attributes.position.array.length; id+=(3)) {
                if(geometry.attributes.position.array[id-1] < (fright - (fheight/2))){
                    if(geometry.attributes.position.array[id] > boundingBox.max.y - topSlant){
                        geometry.attributes.position.array[id] = boundingBox.max.y - topSlant
                    }
                    if(geometry.attributes.position.array[id] < boundingBox.min.y + botSlant){
                        geometry.attributes.position.array[id] = boundingBox.min.y + botSlant
                    }
                }
            }


            // const scaleFactor = (1 - ((wheight - (fheight * 2)) / wheight)) - scaleOffset;
            // for (let id = 1; id < geometry.attributes.position.array.length; id+=(3)) {
            //     if(geometry.attributes.position.array[id-1] < (fright - (fheight/2))){
            //         geometry.attributes.position.array[id] -= geometry.attributes.position.array[id] * scaleFactor;
            //     }
            // }

            // let scaleFactor = (1 - ((wheight - (fheight * 2)) / wheight)) - scaleOffset;
            // // if(startOffset != endOffset)scaleFactor = (1 - ((boundHeight - (boundWidth)) / boundHeight)) - scaleOffset;
            // // console.log("scaleFactor :::: ", scaleFactor);
            
            // for (let id = 1; id < geometry.attributes.position.array.length; id+=(3)) {
            //     if(geometry.attributes.position.array[id-1] < (fright - (fheight/2))){
            //         if(startOffset == endOffset){
            //             geometry.attributes.position.array[id] -= geometry.attributes.position.array[id] * scaleFactor;
            //         }else if(startOffset == true){
            //             geometry.attributes.position.array[id] -= (geometry.attributes.position.array[id]) * scaleFactor;
            //         }else{
            //             geometry.attributes.position.array[id] -= (geometry.attributes.position.array[id]) * scaleFactor;
            //         }

            //     }
            // }
        }
        if(pathPos == "left"){
            let topSlant = endAngleWidth || (1 - (ftop - boundingBox.max.y)) * fheight;
            let botSlant = startAngleWidth || (1 - (fbot - boundingBox.min.y)) * fheight;
            if(!startOffset)topSlant = 0;
            if(!endOffset)botSlant = 0;
            for (let id = 1; id < geometry.attributes.position.array.length; id+=(3)) {
                if(geometry.attributes.position.array[id-1] > (fleft + (fheight/2))){
                    if(geometry.attributes.position.array[id] > boundingBox.max.y - topSlant){
                        geometry.attributes.position.array[id] = boundingBox.max.y - topSlant
                    }
                    if(geometry.attributes.position.array[id] < boundingBox.min.y + botSlant){
                        geometry.attributes.position.array[id] = boundingBox.min.y + botSlant
                    }
                }
            }
        }
        if(pathPos == "left__"){
            let scaleFactor = (1 - ((wheight - (fheight * 2)) / wheight)) - scaleOffset;
            if(startOffset != endOffset)scaleFactor = (1 - ((boundHeight - (boundWidth)) / boundHeight)) - scaleOffset;
            // scaleFactor += 0.03
            let endAngleOffset = 0.6;
            
            for (let id = 1; id < geometry.attributes.position.array.length; id+=(3)) {
                if(geometry.attributes.position.array[id-1] > (fleft + (fheight/2))){
                    if(startOffset == endOffset){
                        geometry.attributes.position.array[id] -= (geometry.attributes.position.array[id]) * scaleFactor;
                    }else if(startOffset == true){
                        // geometry.attributes.position.array[id] -= (geometry.attributes.position.array[id]) * scaleFactor;
                        geometry.attributes.position.array[id] -= ((geometry.attributes.position.array[id] - boundingBox.min.y + endAngleOffset) * scaleFactor) - 0.08;
                    }else{

                        geometry.attributes.position.array[id] -= (geometry.attributes.position.array[id]) * scaleFactor;
                    }

                }
            }
        }
    }
    if(mesh.geometry){
        mesh.geometry = geometry;
        mesh.geometry.verticesNeedUpdate = true;
    }else{
        geometry.verticesNeedUpdate = true;
    }
    // setTimeout(()=>removeExceedCorners(frames), 1500)
}