import React, { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap';
import { MdGridOn, MdOutlineKeyboardArrowDown } from 'react-icons/md';
import { TbListDetails } from 'react-icons/tb';
import { Checkbox, FormControl, ListItemText, MenuItem, OutlinedInput, Select, Radio, RadioGroup, FormControlLabel } from 'material-ui-core';
import {
    Row,
    Col,
    Button,
    FormGroup,
    Label,
    Input,
} from 'reactstrap';
import { MenuProps, noSelectOptions } from '../utility/data';
import NumberInput from './NumberInput';
import { useRightContext } from '../context/rightMenuContext';
import { GetSizeMasterService, GetProjectInfoMaster, GetPropertiesMaster, getFontSizeMaster } from '../services/commonServices';

const DocsFrame = ({ activeTab, fontOptions, accessToken, selectItemDetails, updateItemDetailsServices, handleFormReset }) => {
    const { timestamp } = useRightContext()

    useEffect(() => {
        if (activeTab === "Frame") {
            getProjectDropOptions()
            getPropertiesDropOptions()
            getSizeOptions("icon")
            getSizeOptions("image")
            getSizeOptions("padding")
            getSizeOptions("width")
            getHeadFontSizeOptions(2)

        }
    }, [activeTab])

    const [headFontOptions, setHeadFontOptions] = useState([])
    const [projectDropDown, setProjectDropDown] = useState([])
    const [propertiesOptions, setPropertiesOptions] = useState([])

    const [imageSizeOptions, setImageSizeOptions] = useState([noSelectOptions])
    const [iconSizeOptions, setIconSizeOptions] = useState([noSelectOptions])
    const [minWidthOptions, setMinWidthOptions] = useState([noSelectOptions])
    const [paddingOptions, setPaddingOptions] = useState([noSelectOptions])

    const [nameFont, setNameFont] = useState("")
    const [infoSelect, setInfoSelect] = useState([])
    const [infoFont, setInfoFont] = useState("")

    const [propertiesSelect, setPropertiesSelect] = useState([])
    const [panelFont, setPanelFont] = useState("")
    const [propertiesIcon, setPropertiesIcon] = useState("")
    const [propertyFont, setPropertyFont] = useState("")
    const [leftPadding, setLeftPadding] = useState("")
    const [topPadding, setTopPadding] = useState("")
    const [minWidth, setMinWidth] = useState()

    const [viewType, setViewType] = useState('Icons')

    const [newPageFrame, setNewPageFrame] = useState(false)
    const [newRowFrame, setNewRowFrame] = useState(false)

    const [gridHorizontal, setGridHorizontal] = useState(false)
    const [gridVertical, setGridVertical] = useState(false)
    const [hideInstall, setHideInstall] = useState(false)
    const [priceItemised, setPriceItemised] = useState(false)
    const [priceAncillaries, setPriceAncillaries] = useState(false)
    const [externalView, setExternalView] = useState(false)
    const [internalView, setInternalView] = useState(false)
    const [includeSill, setIncludeSill] = useState(false)
    const [priceTotal, setPriceTotal] = useState(false)
    const [openSash, setOpenSash] = useState(false)
    const [frameDimensions, setFrameDimensions] = useState(false)
    const [transomDimention, setTransomDimention] = useState(false)
    const [imageSize, setImageSize] = useState("")
    const [rotationX, setRotationX] = useState('0')
    const [rotationY, setRotationY] = useState('0')

    useEffect(() => {
        if (selectItemDetails && (selectItemDetails.id || selectItemDetails.initialValue)) {

            console.log(selectItemDetails, "selectItemDetails")

            setNewPageFrame(selectItemDetails?.startNewPageForEachFrame ? selectItemDetails?.startNewPageForEachFrame : false)
            setNewRowFrame(selectItemDetails?.startNewRowForEachFrame ? selectItemDetails?.startNewRowForEachFrame : false)
            setInfoSelect(selectItemDetails?.printFrameInfo ? selectItemDetails?.printFrameInfo : [])
            setInfoFont(selectItemDetails?.fontSize ? selectItemDetails?.fontSize : fontOptions[3]?.id)
            setPanelFont(selectItemDetails?.panelTitleFontSize ? selectItemDetails?.panelTitleFontSize : fontOptions[3]?.id)

            setPropertyFont(selectItemDetails?.propertyFontSize ? selectItemDetails?.propertyFontSize : fontOptions[3]?.id)

            setGridHorizontal(selectItemDetails?.gridLineHorizontal ? selectItemDetails?.gridLineHorizontal : false)
            setGridVertical(selectItemDetails?.gridLineVertical ? selectItemDetails?.gridLineVertical : false)

            setViewType(selectItemDetails?.view ? selectItemDetails?.view : "Icons")
            setHideInstall(selectItemDetails?.hideInstallation ? selectItemDetails?.hideInstallation : false)
            setPriceItemised(selectItemDetails?.itemised ? selectItemDetails?.itemised : false)
            setPriceAncillaries(selectItemDetails?.ancillaries ? selectItemDetails?.ancillaries : false)
            setExternalView(selectItemDetails?.externalView ? selectItemDetails?.externalView : false)
            setInternalView(selectItemDetails?.internalView ? selectItemDetails?.internalView : false)
            setIncludeSill(selectItemDetails?.includeSill ? selectItemDetails?.includeSill : false)
            setPriceTotal(selectItemDetails?.total ? selectItemDetails?.total : false)
            setOpenSash(selectItemDetails?.openSash ? selectItemDetails?.openSash : false)
            setFrameDimensions(selectItemDetails?.frameDimensions ? selectItemDetails?.frameDimensions : false)
            setTransomDimention(selectItemDetails?.transomDimensions ? selectItemDetails?.transomDimensions : false)
            setRotationX(selectItemDetails?.rotationX ? selectItemDetails?.rotationX : "0")
            setRotationY(selectItemDetails?.rotationY ? selectItemDetails?.rotationY : "0")
        }
    }, [selectItemDetails])

    useEffect(() => {
        if (minWidthOptions && minWidthOptions.length > 0) {
            setMinWidth(selectItemDetails?.minimumWidth ? selectItemDetails?.minimumWidth : minWidthOptions[3]?.id)
        }
    }, [selectItemDetails, minWidthOptions])

    useEffect(() => {
        if (imageSizeOptions && imageSizeOptions.length > 0) {
            setImageSize(selectItemDetails?.size ? selectItemDetails?.size : imageSizeOptions[3]?.id)
        }
    }, [selectItemDetails, imageSizeOptions])

    useEffect(() => {
        if (headFontOptions && headFontOptions.length > 0) {
            setNameFont(selectItemDetails?.nameFontSize ? selectItemDetails?.nameFontSize : headFontOptions[2]?.id)
        }
    }, [selectItemDetails, headFontOptions])

    useEffect(() => {
        if (iconSizeOptions && iconSizeOptions.length > 0) {
            setPropertiesIcon(selectItemDetails?.propertyIconSize ? selectItemDetails?.propertyIconSize : iconSizeOptions[3]?.id)
        }
    }, [selectItemDetails, iconSizeOptions])

    useEffect(() => {
        if (paddingOptions && paddingOptions.length > 0) {
            setLeftPadding(selectItemDetails?.leftRightPadding ? selectItemDetails?.leftRightPadding : paddingOptions[3]?.id)
            setTopPadding(selectItemDetails?.topBottomPadding ? selectItemDetails?.topBottomPadding : paddingOptions[3]?.id)
        }
    }, [selectItemDetails, paddingOptions])

    // Filtering and setting here the project items in dropdown
    useEffect(() => {
        if (selectItemDetails && selectItemDetails?.printFrameInfo?.length > 0) {

            const infoSelectNames = selectItemDetails?.printFrameInfo?.map((item) => {
                const proj = projectDropDown?.find((p) => p.id == item)
                return proj ? proj.name : null
            })?.filter(id => id !== null);

            setInfoSelect(infoSelectNames);
        } else {
            setInfoSelect([]);
        }

        if (selectItemDetails && selectItemDetails?.printFrameProperty?.length > 0) {
            const propertiesSelectNames = selectItemDetails?.printFrameProperty?.map((item) => {
                const proj = propertiesOptions?.find((p) => p.id == item)
                return proj ? proj.name : null
            })?.filter(id => id !== null);

            setPropertiesSelect(propertiesSelectNames);
        } else {
            setPropertiesSelect([]);
        }
    }, [selectItemDetails])

    // get title font size dropdown from master
    const getHeadFontSizeOptions = async (typeID) => {
        const res = await getFontSizeMaster(accessToken, typeID);

        if (res && res && res.status === 200) {
            if (res?.data?.statusCode === 200) {
                if (res?.data?.entity !== null) {
                    if (res?.data?.entity?.length > 0) {
                        setHeadFontOptions(res?.data?.entity)
                    }
                }
            }
        }
    }

    // get min width options for dropdown from master
    const getSizeOptions = async (TypeName) => {
        const res = await GetSizeMasterService(accessToken, TypeName);

        if (res && res && res.status === 200) {
            if (res?.data?.statusCode === 200) {
                if (res?.data?.entity !== null) {
                    if (res?.data?.entity?.length > 0) {

                        if (TypeName === "icon") {
                            setIconSizeOptions(res?.data?.entity)
                        }

                        if (TypeName === "image") {
                            setImageSizeOptions(res?.data?.entity)
                        }

                        if (TypeName === "padding") {
                            setPaddingOptions(res?.data?.entity)
                        }

                        if (TypeName === "width") {
                            setMinWidthOptions(res?.data?.entity)
                        }
                    }
                }
            }
        }
    }

    const getProjectDropOptions = async () => {
        const res = await GetProjectInfoMaster(accessToken)

        if (res && res && res.status === 200) {
            if (res?.data?.statusCode === 200) {
                setProjectDropDown(res?.data?.entity)
            } else {
                setProjectDropDown(noSelectOptions)
            }
        }
    }

    const getPropertiesDropOptions = async () => {
        const res = await GetPropertiesMaster(accessToken)

        if (res && res && res.status === 200) {
            if (res?.data?.statusCode === 200) {
                setPropertiesOptions(res?.data?.entity)
            } else {
                setPropertiesOptions(noSelectOptions)
            }
        }
    }

    const handleTextChange = (e) => {
        const value = e.target.value

        setInfoSelect(typeof value === 'string' ? value.split(',') : value)
    }

    const handlePropertiesChange = (e) => {
        const value = e.target.value

        setPropertiesSelect(typeof value === 'string' ? value.split(',') : value)
    }

    const handleNewPageFrameChange = (e) => {

        setNewPageFrame(!newPageFrame)

        if (e.target.checked) {
            setNewRowFrame(false)
        } else {
            setNewRowFrame(true)
        }
    }


    const handleFormSubmit = () => {

        const getSelectedProjectId = infoSelect?.map((name) => {
            const project = projectDropDown.find((item) => item?.name === name)
            return project ? project?.id : null;
        })

        const propertiesSelectIds = propertiesSelect?.map((name) => {
            const project = propertiesOptions.find((item) => item?.name === name)
            return project ? project?.id : null;
        })

        const formDataValues = {
            'id': selectItemDetails?.id,
            'nameFontSize': nameFont || headFontOptions[2]?.id,
            'StartNewPageForEachFrame': newPageFrame,
            'StartNewRowForEachFrame': newRowFrame,
            'printFrameInfo': getSelectedProjectId,
            'FontSize': infoFont || fontOptions[3]?.id,
            'View': viewType,
            'printFrameProperty': propertiesSelectIds,
            'MinimumWidth': minWidth || minWidthOptions[3]?.id,
            'PanelTitleFontSize': panelFont || fontOptions[3]?.id,
            'PropertyIconSize': propertiesIcon || iconSizeOptions[3]?.id,
            'PropertyFontSize': propertyFont || fontOptions[3]?.id,
            'HideInstallation': hideInstall,
            'Ancillaries': priceAncillaries,
            'Total': priceTotal,
            'ExternalView': externalView,
            'InternalView': internalView,
            'FrameDimensions': frameDimensions,
            'IncludeSill': includeSill,
            'TransomDimensions': transomDimention,
            "openSash": openSash,
            'Size': imageSize || imageSizeOptions[3]?.id,
            'RotationX': rotationX,
            'RotationY': rotationY,

            'gridLineHorizontal': gridHorizontal,
            'gridLineVertical': gridVertical,
            'leftRightPadding': leftPadding || paddingOptions[3]?.id,
            'topBottomPadding': topPadding || paddingOptions[3]?.id,
            'itemised': priceItemised,
        }

        updateItemDetailsServices(formDataValues, "Frame")
    }

    return (
        <Form className='h-100 d-flex flex-column flex-grow-1 justify-content-between'>
            <div className='inside_scroll has_tab has_btns has_head'>
                <Row className='mb-4'>
                    {headFontOptions && headFontOptions.length > 0 && (
                        <Col lg="6">
                            <FormGroup className="form-group">
                                <Label for="nameFont">
                                    Name font size
                                </Label>

                                <Select
                                    labelId="nameFont"
                                    id="nameFont"
                                    value={nameFont || ''}
                                    label="nameFont"
                                    onChange={(e) => { setNameFont(e.target.value) }}
                                    input={<OutlinedInput className='w-100' name='nameFont' />}

                                    MenuProps={MenuProps}

                                    IconComponent={() => (
                                        <MdOutlineKeyboardArrowDown size={20} className='me-1 MuiSvgIcon-root MuiSelect-icon' />
                                    )}
                                >
                                    {headFontOptions && headFontOptions.map((o, index) => (
                                        <MenuItem key={index} value={o?.id} style={{ fontSize: `${o.value}px` }}>
                                            {o.title}
                                        </MenuItem>
                                    ))}
                                </Select>

                            </FormGroup>
                        </Col>
                    )}

                    <Col lg="11" xl="9" xxl="7">
                        <Row>
                            <Col sm="6">
                                <FormGroup className="form-group">
                                    <Label for="pageFrame">
                                        Start a new page for each frame
                                    </Label>
                                    <Form.Check // prettier-ignore
                                        type="switch"
                                        id="pageFrame"
                                        className='form-switch-lg'
                                        name='pageFrame'
                                        onChange={(e) => handleNewPageFrameChange(e)}
                                        value={newPageFrame}
                                        checked={newPageFrame}
                                        label={newPageFrame ? 'On' : 'Off'}
                                    />
                                </FormGroup>
                            </Col>

                            <Col sm="6">
                                <FormGroup className={`form-group ${newPageFrame ? "opacity-75" : ''}`}>
                                    <Label for="nowFrame">
                                        Start a new row for each frame
                                    </Label>
                                    <Form.Check // prettier-ignore
                                        type="switch"
                                        id="nowFrame"
                                        className='form-switch-lg'
                                        name='nowFrame'
                                        onChange={(e) => setNewRowFrame(!newRowFrame)}
                                        value={newRowFrame}
                                        disabled={newPageFrame}

                                        checked={newRowFrame}
                                        label={newRowFrame ? 'On' : 'Off'}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <h5 className='main_title sub_title'>
                    Info
                </h5>

                <Row className='mb-4'>
                    <Col lg="6">
                        <FormGroup className="form-group">
                            <FormControl fullWidth>
                                <Label for="infoSelect">
                                    Info
                                </Label>

                                <Select
                                    labelId="infoSelect-label"
                                    id="infoSelect"
                                    multiple
                                    value={infoSelect || ''}
                                    onChange={(e) => handleTextChange(e)}
                                    input={<OutlinedInput name='infoSelect' />}
                                    renderValue={(selected) => selected.join(', ')}
                                    MenuProps={MenuProps}

                                    IconComponent={() => (
                                        <MdOutlineKeyboardArrowDown size={20} className='me-1 MuiSvgIcon-root MuiSelect-icon' />
                                    )}
                                >
                                    {projectDropDown?.map((name, index) => (
                                        <MenuItem key={index} value={name.name} className='check_list'>
                                            <Checkbox checked={infoSelect?.indexOf(name.name) > -1} />
                                            <ListItemText primary={name.name} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </FormGroup>
                    </Col>

                    {fontOptions && fontOptions.length > 0 && (
                        <Col lg="6">
                            <FormGroup className="form-group">
                                <Label for="infoFont">
                                    Font size
                                </Label>

                                <Select
                                    labelId="infoFont"
                                    id="infoFont"
                                    value={infoFont || ''}
                                    label="infoFont"
                                    onChange={(e) => { setInfoFont(e.target.value) }}
                                    input={<OutlinedInput className='w-100' name='infoFont' />}

                                    MenuProps={MenuProps}

                                    IconComponent={() => (
                                        <MdOutlineKeyboardArrowDown size={20} className='me-1 MuiSvgIcon-root MuiSelect-icon' />
                                    )}
                                >
                                    {fontOptions && fontOptions.map((o, index) => (
                                        <MenuItem key={index} value={o?.id} style={{ fontSize: `${o.value}px` }}>
                                            {o.title}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormGroup>
                        </Col>
                    )}
                </Row>

                <h5 className='main_title sub_title'>
                    Properties
                </h5>

                <Row className='mb-4'>
                    <Col lg="6">
                        <FormControl className="form-group">
                            <Label for="viewType">
                                View
                            </Label>
                            <RadioGroup
                                aria-labelledby="viewType-label"
                                name="viewType"
                                className='flex-row gap-2'
                                value={viewType}
                                onChange={(e) => setViewType(e.target.value)}
                            >
                                <FormControlLabel className='page_type_radio_2' value="Icons" control={<Radio />} label={<div>
                                    <span><TbListDetails className='no_svg' /></span>
                                    <span>Icons</span>
                                </div>} />

                                <FormControlLabel className='page_type_radio_2' value="Grid" control={<Radio />} label={<div>
                                    <span><MdGridOn /></span>
                                    <span>Grid</span>
                                </div>} />
                            </RadioGroup>
                        </FormControl>
                    </Col>

                    <Col lg="6">
                        <FormGroup className="form-group">
                            <FormControl fullWidth>
                                <Label for="propertiesSelect">
                                    Properties
                                </Label>

                                <Select
                                    labelId="propertiesSelect-label"
                                    id="propertiesSelect"
                                    multiple
                                    value={propertiesSelect || ''}
                                    onChange={(e) => handlePropertiesChange(e)}
                                    input={<OutlinedInput name='propertiesSelect' />}
                                    renderValue={(selected) => selected.join(', ')}
                                    MenuProps={MenuProps}

                                    IconComponent={() => (
                                        <MdOutlineKeyboardArrowDown size={20} className='me-1 MuiSvgIcon-root MuiSelect-icon' />
                                    )}
                                >
                                    {propertiesOptions.map((name, index) => (
                                        <MenuItem key={index} value={name.name} className='check_list'>
                                            <Checkbox checked={propertiesSelect?.indexOf(name.name) > -1} />
                                            <ListItemText primary={name.name} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </FormGroup>
                    </Col>

                    {viewType === 'Icons' ? (
                        <>
                            <Col lg="6">
                                {minWidthOptions && minWidthOptions.length > 0 && minWidth && (
                                    <FormGroup className="form-group">
                                        <Label for="minWidth">
                                            Minimum width
                                        </Label>

                                        <Select
                                            labelId="minWidth"
                                            id="minWidth"
                                            value={minWidth || minWidthOptions[3]?.id}
                                            label="minWidth"
                                            onChange={(e) => { setMinWidth(e.target.value) }}
                                            input={<OutlinedInput className='w-100' name='minWidth' />}

                                            MenuProps={MenuProps}

                                            IconComponent={() => (
                                                <MdOutlineKeyboardArrowDown size={20} className='me-1 MuiSvgIcon-root MuiSelect-icon' />
                                            )}
                                        >
                                            {minWidthOptions && minWidthOptions.map((o, index) => (
                                                <MenuItem key={index} value={o?.id}>
                                                    {o?.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormGroup>
                                )}
                            </Col>

                            {fontOptions && fontOptions.length > 0 && (
                                <Col lg="6">
                                    <FormGroup className="form-group">
                                        <Label for="panelFont">
                                            Panel title font size
                                        </Label>

                                        <Select
                                            labelId="panelFont"
                                            id="panelFont"
                                            value={panelFont || ''}
                                            label="panelFont"
                                            onChange={(e) => { setPanelFont(e.target.value) }}
                                            input={<OutlinedInput className='w-100' name='panelFont' />}

                                            MenuProps={MenuProps}

                                            IconComponent={() => (
                                                <MdOutlineKeyboardArrowDown size={20} className='me-1 MuiSvgIcon-root MuiSelect-icon' />
                                            )}
                                        >
                                            {fontOptions && fontOptions.map((o, index) => (
                                                <MenuItem key={index} value={o?.id} style={{ fontSize: `${o.value}px` }}>
                                                    {o.title}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormGroup>
                                </Col>
                            )}

                            <Col lg="6">
                                {iconSizeOptions && iconSizeOptions.length > 0 && propertiesIcon && (
                                    <FormGroup className="form-group">
                                        <Label for="propertiesIcon">
                                            Property icon size
                                        </Label>

                                        <Select
                                            labelId="propertiesIcon"
                                            id="propertiesIcon"
                                            value={propertiesIcon || iconSizeOptions[3]?.id}
                                            label="propertiesIcon"
                                            onChange={(e) => { setPropertiesIcon(e.target.value) }}
                                            input={<OutlinedInput className='w-100' name='propertiesIcon' />}

                                            MenuProps={MenuProps}

                                            IconComponent={() => (
                                                <MdOutlineKeyboardArrowDown size={20} className='me-1 MuiSvgIcon-root MuiSelect-icon' />
                                            )}
                                        >
                                            {iconSizeOptions && iconSizeOptions.map((o, index) => (
                                                <MenuItem key={index} value={o?.id}>
                                                    {o?.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormGroup>
                                )}
                            </Col>

                            {fontOptions && fontOptions.length > 0 && (
                                <Col lg="6">
                                    <FormGroup className="form-group">
                                        <Label for="propertyFont">
                                            Property font size
                                        </Label>

                                        <Select
                                            labelId="propertyFont"
                                            id="propertyFont"
                                            value={propertyFont || ''}
                                            label="propertyFont"
                                            onChange={(e) => { setPropertyFont(e.target.value) }}
                                            input={<OutlinedInput className='w-100' name='propertyFont' />}

                                            MenuProps={MenuProps}

                                            IconComponent={() => (
                                                <MdOutlineKeyboardArrowDown size={20} className='me-1 MuiSvgIcon-root MuiSelect-icon' />
                                            )}
                                        >
                                            {fontOptions && fontOptions.map((o, index) => (
                                                <MenuItem key={index} value={o?.id} style={{ fontSize: `${o.value}px` }}>
                                                    {o.title}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormGroup>
                                </Col>
                            )}
                        </>
                    ) : (
                        <>
                            <Col lg="11" xl="9" xxl="7">
                                <Row>
                                    <Col sm="6">
                                        <FormGroup className="form-group">
                                            <Label for="gridHorizontal">
                                                Grid lines horizontal
                                            </Label>
                                            <Form.Check // prettier-ignore
                                                type="switch"
                                                id="gridHorizontal"
                                                className='form-switch-lg'
                                                name='gridHorizontal'
                                                onChange={(e) => setGridHorizontal(!gridHorizontal)}
                                                value={gridHorizontal}
                                                checked={gridHorizontal}
                                                label={gridHorizontal ? 'On' : 'Off'}
                                            />
                                        </FormGroup>
                                    </Col>

                                    <Col sm="6">
                                        <FormGroup className="form-group">
                                            <Label for="gridVertical">
                                                Grid lines vertical
                                            </Label>
                                            <Form.Check // prettier-ignore
                                                type="switch"
                                                id="gridVertical"
                                                className='form-switch-lg'
                                                name='gridVertical'
                                                onChange={(e) => setGridVertical(!gridVertical)}
                                                value={gridVertical}

                                                checked={gridVertical}
                                                label={gridVertical ? 'On' : 'Off'}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Col>

                            <Col lg="6">
                                {paddingOptions && paddingOptions.length > 0 && (

                                    <FormGroup className="form-group">
                                        <Label for="leftPadding">
                                            Left/right padding
                                        </Label>

                                        <Select
                                            labelId="leftPadding"
                                            id="leftPadding"
                                            value={leftPadding || paddingOptions[3]?.id}
                                            label="leftPadding"
                                            onChange={(e) => { setLeftPadding(e.target.value) }}
                                            input={<OutlinedInput className='w-100' name='leftPadding' />}

                                            MenuProps={MenuProps}

                                            IconComponent={() => (
                                                <MdOutlineKeyboardArrowDown size={20} className='me-1 MuiSvgIcon-root MuiSelect-icon' />
                                            )}
                                        >
                                            {paddingOptions && paddingOptions.map((o, index) => (
                                                <MenuItem key={index} value={o?.id}>
                                                    {o?.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormGroup>
                                )}
                            </Col>

                            <Col lg="6">
                                {paddingOptions && paddingOptions.length > 0 && (

                                    <FormGroup className="form-group">
                                        <Label for="topPadding">
                                            Top/bottom padding
                                        </Label>

                                        <Select
                                            labelId="topPadding"
                                            id="topPadding"
                                            value={topPadding || paddingOptions[3]?.id}
                                            label="topPadding"
                                            onChange={(e) => { setTopPadding(e.target.value) }}
                                            input={<OutlinedInput className='w-100' name='topPadding' />}

                                            MenuProps={MenuProps}

                                            IconComponent={() => (
                                                <MdOutlineKeyboardArrowDown size={20} className='me-1 MuiSvgIcon-root MuiSelect-icon' />
                                            )}
                                        >
                                            {paddingOptions && paddingOptions.map((o, index) => (
                                                <MenuItem key={index} value={o?.id}>
                                                    {o?.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormGroup>
                                )}
                            </Col>
                        </>
                    )}
                </Row>

                <h5 className='main_title sub_title'>
                    Prices
                </h5>
                <Row className='mb-4'>
                    {viewType === 'Grid' && (
                        <Col xs="6" lg="4" xl="3" xxl="2">
                            <FormGroup className="form-group">
                                <Label for="priceItemised">
                                    Itemised
                                </Label>
                                <Form.Check // prettier-ignore
                                    type="switch"
                                    id="priceItemised"
                                    className='form-switch-lg'
                                    name='priceItemised'
                                    onChange={(e) => setPriceItemised(!priceItemised)}
                                    value={priceItemised}
                                    checked={priceItemised}
                                    label={priceItemised ? 'On' : 'Off'}
                                />
                            </FormGroup>
                        </Col>
                    )}

                    <Col xs="6" lg="4" xl="3" xxl="2">
                        <FormGroup className="form-group">
                            <Label for="hideInstall">
                                Hide installation
                            </Label>
                            <Form.Check // prettier-ignore
                                type="switch"
                                id="hideInstall"
                                className='form-switch-lg'
                                name='hideInstall'
                                onChange={(e) => setHideInstall(!hideInstall)}
                                value={hideInstall}
                                checked={hideInstall}
                                label={hideInstall ? 'On' : 'Off'}
                            />
                        </FormGroup>
                    </Col>
                    <Col xs="6" lg="4" xl="3" xxl="2">
                        <FormGroup className="form-group">
                            <Label for="priceAncillaries">
                                Ancillaries
                            </Label>
                            <Form.Check // prettier-ignore
                                type="switch"
                                id="priceAncillaries"
                                className='form-switch-lg'
                                name='priceAncillaries'
                                onChange={(e) => setPriceAncillaries(!priceAncillaries)}
                                value={priceAncillaries}
                                checked={priceAncillaries}
                                label={priceAncillaries ? 'On' : 'Off'}
                            />
                        </FormGroup>
                    </Col>
                    <Col xs="6" lg="4" xl="3" xxl="2">
                        <FormGroup className="form-group">
                            <Label for="priceTotal">
                                Total
                            </Label>
                            <Form.Check // prettier-ignore
                                type="switch"
                                id="priceTotal"
                                className='form-switch-lg'
                                name='priceTotal'
                                onChange={(e) => setPriceTotal(!priceTotal)}
                                value={priceTotal}
                                checked={priceTotal}
                                label={priceTotal ? 'On' : 'Off'}
                            />
                        </FormGroup>
                    </Col>
                </Row>

                <h5 className='main_title sub_title'>
                    Images
                </h5>

                <Row className='mb-4'>
                    <Col lg="6" xxl="4">
                        <Row>
                            <Col xs="6" sm="6" lg="6">
                                <FormGroup className="form-group">
                                    <Label for="externalView">
                                        External view
                                    </Label>
                                    <Form.Check // prettier-ignore
                                        type="switch"
                                        id="externalView"
                                        className='form-switch-lg'
                                        name='externalView'
                                        onChange={(e) => setExternalView(!externalView)}
                                        value={externalView}
                                        checked={externalView}
                                        label={externalView ? 'On' : 'Off'}
                                    />
                                </FormGroup>
                            </Col>

                            <Col xs="6" sm="6" lg="6">
                                <FormGroup className="form-group">
                                    <Label for="internalView">
                                        Internal view
                                    </Label>
                                    <Form.Check // prettier-ignore
                                        type="switch"
                                        id="internalView"
                                        className='form-switch-lg'
                                        name='internalView'
                                        onChange={(e) => setInternalView(!internalView)}
                                        value={internalView}
                                        checked={internalView}
                                        label={internalView ? 'On' : 'Off'}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    </Col>

                    {(externalView || internalView) && (
                        <>
                            <Col lg="6" xxl="4">
                                <Row className='align-items-center'>
                                    <Col xs="7" sm="7" lg="7" className='mb-4'>
                                        <div className="mb-0">
                                            <Label for="frameDimensions">
                                                Frame dimensions
                                            </Label>
                                            <Form.Check // prettier-ignore
                                                type="switch"
                                                id="frameDimensions"
                                                className='form-switch-lg'
                                                name='frameDimensions'
                                                onChange={(e) => setFrameDimensions(!frameDimensions)}
                                                value={frameDimensions}
                                                checked={frameDimensions}
                                                label={frameDimensions ? 'On' : 'Off'}
                                            />
                                        </div>
                                    </Col>

                                    <Col xs="5" lg="5" className={`ps-0 ${!frameDimensions ? 'opacity-75' : ''}`}>
                                        <FormGroup className="mb-0" check>
                                            <Input
                                                type="checkbox"
                                                name="radioComments"
                                                id="IncludeSill"
                                                disabled={!frameDimensions}
                                                checked={includeSill}
                                                onChange={() => setIncludeSill(!includeSill)}
                                            />
                                            <Label for="IncludeSill" className='mb-0'>Include sill</Label>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Col>

                            <Col lg="6" xxl="4">
                                <Row>
                                    <Col sm="6" lg="6" xxl="7">
                                        <FormGroup className="form-group">
                                            <Label for="transomDimention">
                                                Transom dimensions
                                            </Label>
                                            <Form.Check // prettier-ignore
                                                type="switch"
                                                id="transomDimention"
                                                className='form-switch-lg'
                                                name='transomDimention'
                                                onChange={(e) => setTransomDimention(!transomDimention)}
                                                value={transomDimention}
                                                checked={transomDimention}
                                                label={transomDimention ? 'On' : 'Off'}
                                            />
                                        </FormGroup>
                                    </Col>

                                    <Col sm="6" lg="6" xxl="5">
                                        <FormGroup className="form-group">
                                            <Label for="openSash">
                                                Open sash
                                            </Label>
                                            <Form.Check // prettier-ignore
                                                type="switch"
                                                id="openSash"
                                                className='form-switch-lg'
                                                name='openSash'
                                                onChange={(e) => setOpenSash(!openSash)}
                                                value={openSash}
                                                checked={openSash}
                                                label={openSash ? 'On' : 'Off'}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Col>

                            <Col sm="12">
                                <Row className='mb-sm-5 pb-sm-4'>
                                    <Col lg="6">
                                        <FormGroup className="form-group">
                                            <Label for="imageSize">
                                                Size
                                            </Label>

                                            <Select
                                                labelId="imageSize"
                                                id="imageSize"
                                                value={imageSize || ''}
                                                label="imageSize"
                                                onChange={(e) => { setImageSize(e.target.value) }}
                                                input={<OutlinedInput className='w-100' name='imageSize' />}

                                                MenuProps={MenuProps}

                                                IconComponent={() => (
                                                    <MdOutlineKeyboardArrowDown size={20} className='me-1 MuiSvgIcon-root MuiSelect-icon' />
                                                )}
                                            >
                                                {imageSizeOptions && imageSizeOptions.map((o, index) => (
                                                    <MenuItem key={index} value={o?.id}>
                                                        {o?.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormGroup>
                                    </Col>

                                    <Col lg="6">
                                        <Row>
                                            <Col sm="6">
                                                <FormGroup className="form-group">
                                                    <Label for="rotationX">
                                                        Rotation X
                                                    </Label>

                                                    <NumberInput for="rotationX" value={rotationX} setValue={setRotationX} />
                                                </FormGroup>
                                            </Col>

                                            <Col sm="6">
                                                <FormGroup className="form-group">
                                                    <Label name="rotationY">
                                                        Rotation Y
                                                    </Label>

                                                    <NumberInput name="rotationY" value={rotationY} setValue={setRotationY} />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </>
                    )}
                </Row>
            </div>
            <div className='d-flex gap-2 gap-xl-3 main_buttons'>
                <Button
                    color="primary"
                    size="md"
                    className='px-4'

                    onClick={() => handleFormSubmit()}
                    disabled={!timestamp}
                >
                    Save Changes
                </Button>

                <Button
                    color="primary"
                    size="md"
                    outline
                    className='px-4'
                    onClick={() => handleFormReset()}
                    disabled={!timestamp || selectItemDetails?.initialValue}
                >
                    Reset
                </Button>
            </div>
        </Form>
    )
}

export default DocsFrame