import React from 'react'
import ErrorLayout from './error-layout'
import ProcessingImage from '../../../../assets/img/process.gif'
import { Oval } from 'react-loader-spinner'

const Processing = () => {
    return (
        <>
            {/* <ErrorLayout 
                code={ProcessingImage} 
                text="Processing"
                subText="Please wait while we are processing your request"
                layout="layout-2"
                pageType="full_cover"
            /> */}

            <div className="loader_main full_loader">
                <Oval
                    height={50}
                    width={50}
                    color="#44C8F5"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    ariaLabel="oval-loading"
                    secondaryColor="#44C8F5"
                    strokeWidth={2}
                    strokeWidthSecondary={2}
                />
            </div>
        </>
    )
}

export default Processing