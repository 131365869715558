import React, { useState } from 'react'
import {
    Nav,
    NavLink,
    NavItem,
    TabContent,
    TabPane
} from 'reactstrap';
import SpacebarPalette from '../../../../../../components/GlazingSpacebarComp/SpacebarPalette';
import SpacerbarColour from '../../../../../../components/GlazingSpacebarComp/SpacerbarColour';
import SpacerbarCollection from '../../../../../../components/GlazingSpacebarComp/SpacerbarCollection';


const SpecificationsLeft = ({ setLoading, getAllColoursServices, getAllPaletteServices, allColours, commonHeading, setCommonHeading, setTabType, tabType, allPalette, allCollection, getAllCollectionServices }) => {

    const [activeTab, setActiveTab] = useState("colours")

    const noRefCheck = (tab) => {
        setActiveTab(tab)
        setTabType(tab)
    }

    return (
        <div className='d-flex flex-column h-100'>
            <div className="d-flex align-items-center mb-3 flex-wrap gap-2 left_header">
                {commonHeading && (
                    <h5 className="mb-0 text-wrap text-break main_title">{commonHeading}</h5>
                )}
            </div>

            <Nav tabs className="details_header border-0 nav-tabs-custom">
                <NavItem>
                    <NavLink
                        className={`${activeTab === "colours" && 'active'}`}
                        onClick={() => noRefCheck("colours")}
                    >
                        Colours
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        className={`${activeTab === "palette" && 'active'}`}
                        onClick={() => noRefCheck("palette")}
                    >
                        Palette
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        className={`${activeTab === "collections" && 'active'}`}
                        onClick={() => noRefCheck("collections")}
                    >
                        Collections
                    </NavLink>
                </NavItem>
            </Nav>
            <TabContent activeTab={activeTab} className='flex-grow-1 d-flex'>
                <TabPane tabId="colours" className='flex-grow-1 w-100'>
                    {activeTab === "colours" && (
                        <SpacerbarColour tabType={tabType} allColours={allColours} setLoading={setLoading} setCommonHeading={setCommonHeading} getAllColoursServices={getAllColoursServices} />
                    )}
                </TabPane>
                <TabPane tabId="palette" className='w-100'>
                    {activeTab === "palette" && (
                        <SpacebarPalette allPalette={allPalette} allColours={allColours} tabType={tabType} setCommonHeading={setCommonHeading} setLoading={setLoading} getAllPaletteServices={getAllPaletteServices} />
                    )}
                </TabPane>
                <TabPane tabId="collections" className='w-100'>
                    {activeTab === "collections" && (
                        <SpacerbarCollection allCollection={allCollection} getAllCollectionServices={getAllCollectionServices} setLoading={setLoading} setCommonHeading={setCommonHeading} tabType={tabType} />
                    )}
                </TabPane>
            </TabContent>
        </div>
    )
}

export default SpecificationsLeft