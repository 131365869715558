import React from 'react'
import ErrorLayout from './error-layout'
import { BiSolidBuoy } from 'react-icons/bi'

const Error403 = () => {
    return (
        <>
            <ErrorLayout 
                code={<h1 className="display-1 fw-medium">4<BiSolidBuoy className='bx-spin text-primary display-3' />3</h1>} 
                text="Forbidden"
                subText="Your request has been denied"
                layout=""
            />
        </>
    )
}

export default Error403