import React from 'react'
import ErrorLayout from './error-layout'
import wipImage from '../../../../assets/img/wip-1.svg'

const ComingSoon = ({title, subtitle}) => {
    return (
        <>  
            <ErrorLayout 
                code={wipImage} 
                text={title}
                subText={subtitle ? subtitle : "We have not started working on this page."}
                subText2="It will be available soon."
                layout="layout-2"
                pageType="inside_content"
            />
        </>
    )
}

export default ComingSoon