import React from 'react'
import { useState } from 'react';
import Slider from 'react-rangeslider';
import { useProductPaletteContext } from '../../context/productPaletteContext';
import {
    Row,
    Col,
    Button,
    Label,
    FormGroup,
    Input,
} from 'reactstrap';
import { Form } from 'react-bootstrap';
import { useMessage } from '../../context/messageContext';
import { useEffect } from 'react';
import { addUpdateGlazingColours, uniqueGlazingColours } from '../../services/glazingServices';
import { useNavigate } from 'react-router-dom';
import { PhotoshopPicker } from 'react-color';
import { convertCanvasToImageFile, denormalizeValue, normalizeValue, rgbToHex } from '../../utility/helper';
import { useRightContext } from '../../context/rightMenuContext';

const SpecificationColours = ({ allColours, setLoading, getAllColoursServices, setCommonHeading, tabType }) => {

    const accessToken = localStorage.getItem('access_token');

    let firstElId = allColours[0]?.id

    let minSliderVal = 0;
    let maxSliderVal = 100;

    const { handleEdit, handleModificationChange } = useProductPaletteContext();
    const [name, setName] = useState('')
    const [desc, setDesc] = useState('')
    const [roughness, setRoughness] = useState(0)
    const [metalness, setMetalness] = useState(0)
    const [defaultColor, setDefaultColor] = useState({
        r: '',
        g: '',
        b: '',
        hex: ''
    },)
    const [id, setId] = useState()
    const { timestamp, setTimestamp, activeIndex, newItemList, handleDescEdit } = useProductPaletteContext();
    const { setMessage, setMessageType } = useMessage()

    const { setSelectedRightItem, selectedRightItem } = useRightContext()

    const navigate = useNavigate()

    useEffect(() => {
        if (firstElId && tabType === 'colours') {
            if (allColours?.length && activeIndex !== 0 && tabType === 'colours') {
                setTimestamp(timestamp)
            } else {
                setTimestamp(firstElId)
            }
        }
    }, [allColours, timestamp, tabType])


    useEffect(() => {
        if (timestamp && tabType === 'colours') {
            getUniqueColourServices(timestamp)
        }
    }, [timestamp, tabType])

    // useEffect(() => {
    //     if (allColours?.length === 0) {
    //         setTimestamp('')
    //     }
    // }, [allColours])

    const handleColorPicker = (value) => {
        let rgb = value?.rgb
        setDefaultColor({ r: rgb?.r, g: rgb?.g, b: rgb?.b, hex: value?.hex })
        handleModificationChange('hex', value?.hex)
    }


    const reset = () => {
        getAllColoursServices()
        getUniqueColourServices(timestamp)
    }

    useEffect(() => {
        if (newItemList?.length === 0) {
            setTimestamp('')
            setName(' ')
            setDesc(' ')
            setCommonHeading('')
        }
    }, [newItemList])

    // const handelMetalness = (value) => {
    //     setMetalness(value)
    // }

    const handleRoughnessChange = (value) => {
        setRoughness(value)
        handleModificationChange('roughness', normalizeValue(value, minSliderVal, maxSliderVal));
    }

    const handelMetalness = (value) => {
        setMetalness(value)
        handleModificationChange('metalness', normalizeValue(value, minSliderVal, maxSliderVal))
    }

    const updateGlazingColoursServices = async () => {

        setLoading(true)

        const capturedFile = convertCanvasToImageFile();

        const data = {
            id: timestamp,
            name: name,
            desc: desc,
            hex: defaultColor?.hex,
            red: defaultColor?.r,
            green: defaultColor?.g,
            blue: defaultColor?.b,
            roughness: normalizeValue(roughness, minSliderVal, maxSliderVal),
            metalness: normalizeValue(metalness, minSliderVal, maxSliderVal),
            transparency: 0,
            typeId: 1,
            CapturedFile: capturedFile || '',
            CapturedImage: ""
        }

        const res = await addUpdateGlazingColours(data, accessToken)
        if (res && res.status === 200) {
            if (res?.data?.statusCode === 200) {
                setMessageType('success')
                setMessage(res?.data?.message ? res.data.message : 'Added new colour')
                getAllColoursServices()
                getUniqueColourServices(timestamp)
                setLoading(false)
            } else {
                setMessage(res?.data?.message ? res.data.message : 'Something went wrong')
                setMessageType('error')
                getAllColoursServices()
                getUniqueColourServices(timestamp)
                setLoading(false)
            }
        } else {
            setLoading(false)
            setMessage(res?.message ? res.message : 'Something went wrong');
            setMessageType('error')
            getAllColoursServices()
            if (res?.message === "Network Error") {
                navigate('/error/network')
            }
        }
    }


    const getUniqueColourServices = async (timestamp) => {

        const res = await uniqueGlazingColours(timestamp, accessToken)
        if (res && res.status === 200) {
            if (res?.data?.statusCode === 200) {
                let r = res?.data?.entity?.red;
                let g = res?.data?.entity?.green;
                let b = res?.data?.entity?.blue;
                const selectedRight = allColours?.find((p) => p.id == timestamp)
                setSelectedRightItem(selectedRight)
                setName(res?.data.entity?.name)
                setCommonHeading(res?.data.entity?.name)
                setDesc(res?.data.entity?.description ? res?.data.entity?.description : "")
                setRoughness(denormalizeValue(res?.data.entity?.roughness, minSliderVal, maxSliderVal))
                // setRoughness((res?.data.entity?.roughness))
                // setMetalness((res?.data.entity?.metalness))
                setMetalness(denormalizeValue(res?.data.entity?.metalness, minSliderVal, maxSliderVal))
                setDefaultColor({ r: r, g: g, b: b, hex: rgbToHex(r, g, b) })
                setId(res?.data.entity?.id)
            } else {
                setMessage(res?.data?.message ? res.data.message : 'Something went wrong');
                setMessageType('error')
            }

            setLoading(false)
        } else {
            setLoading(false)
            setMessage(res?.message ? res.message : 'Something went wrong');
            setMessageType('error')
            if (res?.message === "Network Error") {
                navigate('/error/network')
                setLoading(false)
            }
        }
    }


    const handleNameChange = (e) => {
        const value = e.target.value;
        if (e.target.value.length <= 50) {
            setName(value)
            handleEdit(value)
            setCommonHeading(value)
        }
    }

    const handleDescChange = (e) => {
        const value = e.target.value;
        if (e.target.value.length <= 80) {
            setDesc(value)
            handleDescEdit(value)
        }
    }

    return (
        <Form className='h-100 d-flex flex-column flex-grow-1 justify-content-between'>
            <div className='inside_scroll has_btns has_head'>
                <Row>
                    <Col lg="6">
                        <FormGroup className="form-group">
                            <Label for="name">
                                Name
                            </Label>
                            <Input disabled={!selectedRightItem?.isSelf} className='mb-1' name='name' value={name} onChange={(e) => handleNameChange(e)} />

                            {name && name.length > 49 && (
                                <div className='mt-1 text-muted text-small'>Max 50 characters are allowed</div>
                            )}

                            {(!name && id) && (
                                <div className='mt-1 text-danger text-small'>Name is required</div>
                            )}
                        </FormGroup>
                    </Col>
                    <Col lg="6" >
                        <FormGroup className="form-group">
                            <Label for="description">
                                Description
                            </Label>
                            <Input disabled={!selectedRightItem?.isSelf} className='mb-1' name='description' value={desc} onChange={(e) => handleDescChange(e)} />
                            {desc && desc.length > 79 && (
                                <div className='mt-1 text-muted text-small'>Max 80 characters are allowed</div>
                            )}
                        </FormGroup>
                    </Col>

                    <Col lg="12">
                        <FormGroup className={`${!selectedRightItem?.isSelf ? "not_allowed" : ""}`}>
                            <div className='palette-color-picker'>
                                <PhotoshopPicker onChange={handleColorPicker} disableAlpha={true} color={defaultColor} />
                            </div>
                        </FormGroup>
                    </Col>

                    <Col lg="6">
                        <FormGroup className={`${(!selectedRightItem?.isSelf) && "opacity-75 pointer_none"}`}>
                            <Label>Roughness</Label>
                            <div className='d-flex align-items-center'>
                                <Slider
                                    min={0}
                                    max={100}
                                    value={roughness}
                                    className='w-75'
                                    onChange={handleRoughnessChange}
                                />
                                <span className='ms-3'>
                                    {roughness}
                                    {/* {normalizeValue(roughness, minSliderVal, maxSliderVal)} */}
                                </span>
                            </div>
                        </FormGroup>
                    </Col>

                    <Col lg="6">
                        <FormGroup className={`${(!selectedRightItem?.isSelf) && "opacity-75 pointer_none"}`}>
                            <Label>Metalness</Label>
                            <div className='d-flex align-items-center'>
                                <Slider
                                    min={0}
                                    max={100}
                                    value={metalness}
                                    className='w-75'
                                    onChange={handelMetalness}
                                />
                                <span className='ms-3'>
                                    {metalness}
                                    {/* {normalizeValue(metalness, minSliderVal, maxSliderVal)} */}
                                </span>
                            </div>
                        </FormGroup>
                    </Col>
                </Row>
            </div>
            {selectedRightItem?.isSelf && <div className='d-flex gap-2 gap-xl-3 main_buttons'>
                <Button
                    color="primary"
                    size="md"
                    className='px-4'
                    onClick={() => updateGlazingColoursServices()}
                    disabled={allColours?.length === 0 || desc.length > 79 || !name || name.length > 49}
                >
                    Save Changes
                </Button>

                <Button
                    color="primary"
                    size="md"
                    outline
                    className='px-4'
                    onClick={() => reset()}
                    disabled={allColours?.length === 0 || desc.length > 79 || !name || name.length > 49}
                >
                    Reset
                </Button>
            </div>}
        </Form>
    )
}

export default SpecificationColours