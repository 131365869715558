import { toBeRequired } from '@testing-library/jest-dom/matchers';
import * as THREE from 'three';
import { SVGLoader } from 'three/examples/jsm/loaders/SVGLoader';
import { GLTFLoader } from 'three/addons/loaders/GLTFLoader.js';
import cornerJointModel from "../../assets/models/JointMitre.glb";
import { useDesigner } from '../../context/designerContext';

const useShapedCorner = () => {
   const { setModelWidth, setModelHeight } = useDesigner();

    let framesData = {
        top: {},
        right: {},
        bottom: {},
        left: {}
    };
    let svgShape, jointMaterial, profileJointArray = [];;
    let meshScale = 1;
    let tryScale = .012;
    // let tryScale = 2.995044744983315 / 1097;
    // let meshScale = 2.995044744983315 / 1097;
    // let meshScale = 0.012;
    const material = new THREE.MeshPhongMaterial({ 
        color: 0x000000,
        side: THREE.DoubleSide,
        specular: 0x333333,
        shininess: 30
        // transparent: true,
        // needsUpdate: true,
        // opacity: 0.1
     });//********** */

    const chamferedCorner = async (shapedHeight, shapedWidth, modelHeight, modelWidth, shapeFrame, checkClickedProfile, sceneRef, topFrame, rightFrame, bottomFrame, leftFrame, SpaceBar, ProfileJoints) => {
  

        if (!checkClickedProfile) return;

        let topLeftJoint = [], topRightJoint = [], bottomLeftJoint = [], bottomRightJoint = [];
        sceneRef.current.traverse((child) => {
            if (child.name.includes("clonedJoints"))child.visible = false;
            // if (child.name.includes("ProfileJoints")) {
            //     if(!jointMaterial){
            //         jointMaterial = child.material.copy();
            //     }
            // }
            if (child.name.includes("ProfileJoints FrameTop FrameRight")) {
                // jointMaterial = child.material.copy();

                topLeftJoint.push(child);
            }
            if (child.name.includes("ProfileJoints FrameTop FrameLeft")) {
                topRightJoint.push(child);
            }
            if (child.name.includes("ProfileJoints FrameBottom FrameRight")) {
                bottomLeftJoint.push(child);
            }
            if (child.name.includes("ProfileJoints FrameBottom FrameLeft")) {
                bottomRightJoint.push(child);
            }
            if (child.name.includes("ProfileJoints")) {
                if (!child.userData.oldPosition) {
                    const topSpaceBoundingBox = new THREE.Box3().setFromObject(child);
                    child.userData.oldBounding = topSpaceBoundingBox;
                    child.userData.oldPosition = child.position;
                }

                child.userData.oldScale = child?.userData.oldScale ? child.userData.oldScale : child.scale;

            }

        });
        if (sceneRef.current.userData.framesData) {
            framesData = sceneRef.current.userData.framesData;
        } else {
            sceneRef.current.userData.framesData = framesData;
        }
        let topSpaceBar, bottomSpaceBar;
        SpaceBar.forEach((frame, index) => {
            if (frame.name.includes("Top")) {
                topSpaceBar = frame;
            }
            if (frame.name.includes("Bottom")) {
                bottomSpaceBar = frame;
            }
            if (frame.name.includes("Top") || frame.name.includes("Bottom")) {
                if (!frame.userData.oldPosition) {
                    const topSpaceBoundingBox = new THREE.Box3().setFromObject(frame);
                    frame.userData.oldBounding = topSpaceBoundingBox;
                    frame.userData.oldPosition = frame.position;
                }

                frame.userData.oldScale = frame?.userData.oldScale ? frame.userData.oldScale : frame.scale;
            }
        })
        const spaceBarArray = [topSpaceBar, bottomSpaceBar]

        // let topLeftJoint, topRightJoint, bottomLeftJoint, bottomRightJoint;
        // console.log("ProfileJoints :::: ", ProfileJoints);
        // ProfileJoints.forEach((frame, index) => {
        //     if (frame.name.includes("ProfileJoints FrameTop FrameRight")) {
        //         topLeftJoint = frame;
        //     }
        //     if (frame.name.includes("ProfileJoints FrameTop FrameLeft")) {
        //         topRightJoint = frame;
        //     }
        //     if (frame.name.includes("ProfileJoints FrameBottom FrameRight")) {
        //         bottomLeftJoint = frame;
        //     }
        //     if (frame.name.includes("ProfileJoints FrameBottom FrameLeft")) {
        //         bottomRightJoint = frame;
        //     }
        //     if (frame.name.includes("ProfileJoints")) {
        //         if (!frame.userData.oldPosition) {
        //             const topSpaceBoundingBox = new THREE.Box3().setFromObject(frame);
        //             frame.userData.oldBounding = topSpaceBoundingBox;
        //             frame.userData.oldPosition = frame.position;
        //         }

        //         frame.userData.oldScale = frame?.userData.oldScale ? frame.userData.oldScale : frame.scale;
        //         console.log("frame.userData :::: ", frame.userData);
        //     }
        // })
        if (topLeftJoint.length > 2)topLeftJoint.splice(0, 2);
        if (topRightJoint.length > 2)topRightJoint.splice(0, 2);
        if (bottomLeftJoint.length > 2)bottomLeftJoint.splice(0, 2);
        if (bottomRightJoint.length > 2)bottomRightJoint.splice(0, 2);
        // if (topLeftJoint.length > 4)topLeftJoint.splice(0, 4);
        // if (topRightJoint.length > 4)topRightJoint.splice(0, 4);
        // if (bottomLeftJoint.length > 3)bottomLeftJoint.splice(0, 3);
        // if (bottomRightJoint.length > 4)bottomRightJoint.splice(0, 4);
        const profileJoints = [topLeftJoint, topRightJoint, bottomLeftJoint, bottomRightJoint]
        sceneRef.current.getObjectByProperty('name', checkClickedProfile.name).userData.shapeFrame = shapeFrame;
        removeOldCorners(sceneRef.current, checkClickedProfile.name);
        const isOnlyCorner = await checkCornerType(sceneRef.current);

        const FrameLeft = sceneRef.current.getObjectByProperty('name', "FrameLeft");
        const frameBottom = sceneRef.current.getObjectByProperty('name', "FrameBottom");
        let meshScale1 = 1;
        // let meshScale1 = 0.001;
        // let meshScale1 = 0.00100342134;
        if (FrameLeft && modelHeight > modelWidth) {
            const boundingBox = new THREE.Box3().setFromObject(FrameLeft);
            const modelWidthT = boundingBox.max.x - boundingBox.min.x;
            const modelHeightT = boundingBox.max.y - boundingBox.min.y;
            const worldPosition = new THREE.Vector3();
            FrameLeft.getWorldPosition(worldPosition);
            meshScale1 = 1;
            // meshScale1 = (FrameLeft.scale.y * modelHeightT) / modelHeight;
            // meshScale1 = modelHeight / (FrameLeft.scale.y * modelHeightT);
        } else {
            // console.error("FrameLeft not found");
        }
        const frameBottomBound = new THREE.Box3().setFromObject(frameBottom);

        const FrameLeftBound = new THREE.Box3().setFromObject(FrameLeft);

        if (frameBottom && modelHeight <= modelWidth) {
            const boundingBox = new THREE.Box3().setFromObject(frameBottom);
            const modelWidthT = boundingBox.max.x - boundingBox.min.x;
            meshScale1 = 1;
            // meshScale1 = (frameBottom.scale.x * modelWidthT) / modelWidth;
        } else {
            console.error("FrameBottom not found");
        }

        await parseSVGPath(shapedHeight, shapedWidth, modelHeight, modelWidth, shapeFrame, sceneRef, checkClickedProfile.name, meshScale1, FrameLeft, frameBottom);
        // if (shapeFrame != "Corner") await parseSVGPath(shapedHeight, shapedWidth, modelHeight, modelWidth, shapeFrame, sceneRef, checkClickedProfile.name, meshScale1, FrameLeft, frameBottom);
        if (!isOnlyCorner) {
            createBaseFrames(sceneRef.current, topFrame, rightFrame, bottomFrame, leftFrame, spaceBarArray, profileJoints);
        } else {
            await removeSVGFrames(sceneRef.current);
            const glass = sceneRef.current.getObjectByProperty('name', "GlassPanel034");
            changeBaseFramesOpacity(topFrame, rightFrame, bottomFrame, leftFrame, glass, spaceBarArray, 1, profileJoints);
        }

    }

    function removeOldCorners(scene, cornerName) {
        if (cornerName == "ProfileJoints FrameTop FrameLeft") scene.remove(scene.getObjectByProperty('name', "topRightCorner"));
        if (cornerName == "ProfileJoints FrameTop FrameRight") scene.remove(scene.getObjectByProperty('name', "topLeftCorner"));
        if (cornerName == "ProfileJoints FrameBottom FrameLeft") scene.remove(scene.getObjectByProperty('name', "bottomRightCorner"));
        if (cornerName == "ProfileJoints FrameBottom FrameRight") scene.remove(scene.getObjectByProperty('name', "bottomLeftCorner"));
    }

    function parseSVGPath(shapedHeight, shapedWidth, modelHeight, modelWidth, shapeFrame, sceneRef, cornerName, meshScale11, FrameLeft, frameBottom) {
        // Parse SVG path data
        const loader = new SVGLoader();
        // const svgPath = `<svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 446.67 254.14"><polygon points="390.16 47.51 390.16 127.07 56.52 127.07 56.52 47.51 25.29 47.51 25.29 206.63 421.38 206.63 421.38 47.51 390.16 47.51" style="fill:#474747"/></svg>`;
        const svgPath = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 5 3.5"><path d="M10 0V6H8.8V3L1.3 3.1V6H0V0H10Z"/></svg>';
        const svgData = loader.parse(svgPath);

        svgData.paths.forEach(path => {
            const shapes = path.toShapes(true);
            shapes.forEach(async shape => {
                let meshScale1 = 1;
                // let meshScale1 = meshScale11 / meshScale;
                // let meshScale1 = 0.001;
                const { cornerPath, topPath1, leftPath1, rightPath1 } = await createPaths(modelHeight * meshScale1, modelWidth * meshScale1, shapedWidth * meshScale1, shapedHeight * meshScale1, shapeFrame, cornerName, FrameLeft, meshScale1);
                sceneRef.current.userData.framesData = framesData;

                const extrudeSettings = {
                    steps: 100,
                    extrudePath: cornerPath,
                    bevelEnabled: true,
                    bevelSegments: 3,
                    bevelSize: 0.2,
                    bevelThickness: 0.1
                };
                // const exmaterial = new THREE.MeshPhongMaterial({
                //     color: 0x000000
                // });
                svgShape = shape;
                if (shapeFrame === "Corner") return false;
                const exGeometry = new THREE.ExtrudeGeometry(shape, extrudeSettings);
                const exmesh = new THREE.Mesh(exGeometry, material);
                const exname = cornerName == "ProfileJoints FrameTop FrameLeft" ? "topRightCorner" : cornerName == "ProfileJoints FrameTop FrameRight" ? "topLeftCorner" : cornerName == "ProfileJoints FrameBottom FrameRight" ? "bottomLeftCorner" : "bottomRightCorner";

                exmesh.name = exname;
                exmesh.userData.extrudePath = cornerPath;
                
                exmesh.scale.set(tryScale, tryScale, tryScale);
                exmesh.position.z = 4.5 * tryScale;
                // exmesh.scale.set(meshScale, meshScale, meshScale);
                // exmesh.position.z = 4.5 * meshScale;
                sceneRef.current.add(exmesh);
            });
        });
    }

    function createPaths(frameHeight, frameWidth, curveWidth1, curveHeight1, shapeFrame, cornerName, FrameLeft, meshScale1) {
        const { topLeft, topRight, bottomRight, bottomLeft } = calculateCornerPoints(frameWidth, frameHeight, FrameLeft, meshScale1);
        if (!framesData.top.start) framesData.top.start = new THREE.Vector3(topRight.x, topRight.y, 0);
        if (!framesData.top.end) framesData.top.end = new THREE.Vector3(topLeft.x, topLeft.y, 0);
        if (!framesData.left.start) framesData.left.start = new THREE.Vector3(topLeft.x, topLeft.y, 0);
        if (!framesData.left.end) framesData.left.end = new THREE.Vector3(bottomLeft.x, bottomLeft.y, 0);
        if (!framesData.bottom.start) framesData.bottom.start = new THREE.Vector3(bottomLeft.x, bottomLeft.y, 0);
        if (!framesData.bottom.end) framesData.bottom.end = new THREE.Vector3(bottomRight.x, bottomRight.y, 0);
        if (!framesData.right.start) framesData.right.start = new THREE.Vector3(bottomRight.x, bottomRight.y, 0);
        if (!framesData.right.end) framesData.right.end = new THREE.Vector3(topRight.x, topRight.y, 0);
        const curveWidth = (curveWidth1 / tryScale) / 1000;
        const curveHeight = (curveHeight1 / tryScale) / 1000;
        const topPath1 = {
            x1: topLeft.x + curveWidth,
            y1: topLeft.y,
            z1: 0,
            x2: topRight.x - curveWidth,
            y2: topRight.y,
            z2: 0
        };


        const bottomPath1 = {
            x1: bottomLeft.x + curveWidth,
            y1: bottomLeft.y,
            z1: 0,
            x2: bottomRight.x - curveWidth,
            y2: bottomRight.y,
            z2: 0
        };

        const leftPath1 = {
            x1: bottomLeft.x,
            y1: bottomLeft.y + curveHeight,
            z1: 0,
            x2: topLeft.x,
            y2: topLeft.y - curveHeight,
            z2: 0
        };

        const rightPath1 = {
            x1: topRight.x,
            y1: topRight.y - curveHeight,
            z1: 0,
            x2: bottomRight.x,
            y2: bottomRight.y + curveHeight,
            z2: 0
        };

        let cornerPath;
        if (shapeFrame == "Radius" || shapeFrame == "Ellipse") {

            if (cornerName == "ProfileJoints FrameTop FrameLeft") { //topRignt corner
                cornerPath = new THREE.QuadraticBezierCurve3(
                    new THREE.Vector3(rightPath1.x1, rightPath1.y1, rightPath1.z1),
                    new THREE.Vector3(topRight.x, topRight.y, 0),
                    new THREE.Vector3(topPath1.x2, topPath1.y2, topPath1.z2)
                );
                framesData.top.start = cornerPath.v2;
                framesData.right.end = cornerPath.v0;
            }
            if (cornerName == "ProfileJoints FrameTop FrameRight") { //topLeft corner
                cornerPath = new THREE.QuadraticBezierCurve3(
                    new THREE.Vector3(topPath1.x1, topPath1.y1, topPath1.z1),
                    new THREE.Vector3(topLeft.x, topLeft.y, 0),
                    new THREE.Vector3(leftPath1.x2, leftPath1.y2, leftPath1.z2)
                );
                framesData.top.end = cornerPath.v0;
                framesData.left.start = cornerPath.v2;
            }
            if (cornerName == "ProfileJoints FrameBottom FrameLeft") { //bottomRignt corner
                cornerPath = new THREE.QuadraticBezierCurve3(
                    new THREE.Vector3(bottomPath1.x2, bottomPath1.y2, bottomPath1.z2),
                    new THREE.Vector3(bottomRight.x, bottomRight.y, 0),
                    new THREE.Vector3(rightPath1.x2, rightPath1.y2, rightPath1.z2)
                );
                framesData.right.start = cornerPath.v2;
                framesData.bottom.end = cornerPath.v0;
            }
            if (cornerName == "ProfileJoints FrameBottom FrameRight") {//bottomLeft corner
                cornerPath = new THREE.QuadraticBezierCurve3(
                    new THREE.Vector3(leftPath1.x1, leftPath1.y1, leftPath1.z1),
                    new THREE.Vector3(bottomLeft.x, bottomLeft.y, 0),
                    new THREE.Vector3(bottomPath1.x1, bottomPath1.y1, bottomPath1.z1)
                );
                framesData.bottom.start = cornerPath.v2;
                framesData.left.end = cornerPath.v0;
            }
        } else if (shapeFrame == "Chamfer") {
            if (cornerName == "ProfileJoints FrameTop FrameLeft") {//topRignt corner
                cornerPath = new THREE.LineCurve3(
                    new THREE.Vector3(rightPath1.x1, rightPath1.y1, rightPath1.z1),
                    new THREE.Vector3(topPath1.x2, topPath1.y2, topPath1.z2)
                );
                framesData.top.start = cornerPath.v2;
                framesData.right.end = cornerPath.v1;
            }
            if (cornerName == "ProfileJoints FrameTop FrameRight") {//topLeft corner
                cornerPath = new THREE.LineCurve3(
                    new THREE.Vector3(topPath1.x1, topPath1.y1, topPath1.z1),
                    new THREE.Vector3(leftPath1.x2, leftPath1.y2, leftPath1.z2)
                );
                framesData.top.end = cornerPath.v1;
                framesData.left.start = cornerPath.v2;
            }
            if (cornerName == "ProfileJoints FrameBottom FrameLeft") {//bottomRignt corner
                cornerPath = new THREE.LineCurve3(
                    new THREE.Vector3(bottomPath1.x2, bottomPath1.y2, bottomPath1.z2),
                    new THREE.Vector3(rightPath1.x2, rightPath1.y2, rightPath1.z2)
                );
                framesData.right.start = cornerPath.v2;
                framesData.bottom.end = cornerPath.v1;
            }
            if (cornerName == "ProfileJoints FrameBottom FrameRight") {//bottomLeft corner
                cornerPath = new THREE.LineCurve3(
                    new THREE.Vector3(leftPath1.x1, leftPath1.y1, leftPath1.z1),
                    new THREE.Vector3(bottomPath1.x1, bottomPath1.y1, bottomPath1.z1)
                );
                framesData.bottom.start = cornerPath.v2;
                framesData.left.end = cornerPath.v1;
            }
        } else {
            if (cornerName == "ProfileJoints FrameTop FrameLeft") {//topRignt corner
                framesData.top.start = new THREE.Vector3(topRight.x, topRight.y, 0);
                framesData.right.end = new THREE.Vector3(topRight.x, topRight.y, 0);
            }
            if (cornerName == "ProfileJoints FrameTop FrameRight") {//topLeft corner
                framesData.top.end = new THREE.Vector3(topLeft.x, topLeft.y, 0);
                framesData.left.start = new THREE.Vector3(topLeft.x, topLeft.y, 0);
            }
            if (cornerName == "ProfileJoints FrameBottom FrameLeft") {//bottomRignt corner
                framesData.right.start = new THREE.Vector3(bottomRight.x, bottomRight.y, 0);
                framesData.bottom.end = new THREE.Vector3(bottomRight.x, bottomRight.y, 0);
            }
            if (cornerName == "ProfileJoints FrameBottom FrameRight") {//bottomLeft corner
                framesData.bottom.start = new THREE.Vector3(bottomLeft.x, bottomLeft.y, 0);
                framesData.left.end = new THREE.Vector3(bottomLeft.x, bottomLeft.y, 0);
            }
        }

        return { cornerPath, topPath1, leftPath1, rightPath1 };
    }

    function calculateCornerPoints(frameWidth, frameHeight, FrameLeft, meshScale1) {
        // setModelWidth
        //******* */
        const halfWidth = (frameWidth / tryScale) / 2000;
        const halfHeight = (frameHeight / tryScale) / 2000;
        const topLeft = new THREE.Vector2(-halfWidth, halfHeight);
        const topRight = new THREE.Vector2(halfWidth, halfHeight);
        const bottomRight = new THREE.Vector2(halfWidth, -halfHeight);
        const bottomLeft = new THREE.Vector2(-halfWidth, -halfHeight);
        return { topLeft, topRight, bottomRight, bottomLeft };
    }
    async function checkCornerType(scene) {
        let hasCorner = true;

        await scene.traverse((object) => {
            if (object.name === "ProfileJoints FrameTop FrameLeft" || object.name === "ProfileJoints FrameTop FrameRight" || object.name === "ProfileJoints FrameBottom FrameLeft" || object.name === "ProfileJoints FrameBottom FrameRight") {
                if (object.userData && object.userData.shapeFrame && object.userData.shapeFrame !== "Corner") {
                    hasCorner = false;
                    return false;
                }
            }
        });

        return hasCorner;
    }
    async function createBaseFrames(scene, topFrame, rightFrame, bottomFrame, leftFrame, spaceBarArray, profileJoints) {
        const glass = scene.getObjectByProperty('name', "GlassPanel034");
        // const svgTopFrame = scene.getObjectByProperty('name', "topFrame");

        // const topFrameBoundingBox = new THREE.Box3().setFromObject(svgTopFrame);
        // const topFrameWidth = topFrameBoundingBox.max.x - topFrameBoundingBox.min.x;

        changeBaseFramesOpacity(topFrame, rightFrame, bottomFrame, leftFrame, glass, spaceBarArray, 0, profileJoints);
        await createSvgBaseFrames(scene, profileJoints);
        updateSpaceBar(scene, spaceBarArray);
        // setTimeout(() => updateProfileJointsData(scene, profileJoints), 1500)
        // updateProfileJointsData(scene, profileJoints);
    }
    async function updateProfileJointsData(scene, profileJoints) {
        let tlc, blc, trc, brc, tFrame, bFrame, lFrame, rFrame, 
        topRightJoint, topRightJointBack, topLeftJoint, topLeftJointBack,
        leftTopJoint, leftTopJointBack, leftBottomJoint, leftBottomJointBack,
        rightTopJoint, rightTopJointBack, rightBottomJoint, rightBottomJointBack,
        bottomRightJoint, bottomRightJointBack, bottomLeftJoint, bottomLeftJointBack;
        profileJointArray = [];
        await scene.traverse((object) => {
            if (object.name === "topFrame") tFrame = object;
            if (object.name === "leftFrame") lFrame = object;
            if (object.name === "bottomFrame") bFrame = object;
            if (object.name === "rightFrame") rFrame = object;
            
            if (object.name === "clonedJoints trjf" || object.name === "clonedJoints trjb" ||
                object.name === "clonedJoints tljf" || object.name === "clonedJoints tljb" ||
                object.name === "clonedJoints brjf" || object.name === "clonedJoints brjb" ||
                object.name === "clonedJoints bljf" || object.name === "clonedJoints bljb" || 
                object.name === "clonedJoints ltjf" || object.name === "clonedJoints ltjb" ||
                object.name === "clonedJoints lbjf" || object.name === "clonedJoints lbjb" ||
                object.name === "clonedJoints rtjf" || object.name === "clonedJoints rtjb" ||
                object.name === "clonedJoints rbjf" || object.name === "clonedJoints rbjb"
            ){
                object.visible = true;
                profileJointArray.push(object);
            }
            if (object.name === "clonedJoints trjf")topRightJoint = object
            if (object.name === "clonedJoints trjb")topRightJointBack = object
            if (object.name === "clonedJoints tljf")topLeftJoint = object;
            if (object.name === "clonedJoints tljb")topLeftJointBack = object;

            if (object.name === "clonedJoints brjf")bottomRightJoint = object
            if (object.name === "clonedJoints brjb")bottomRightJointBack = object
            if (object.name === "clonedJoints bljf")bottomLeftJoint = object;
            if (object.name === "clonedJoints bljb")bottomLeftJointBack = object;

            if (object.name === "clonedJoints ltjf")leftTopJoint = object
            if (object.name === "clonedJoints ltjb")leftTopJointBack = object
            if (object.name === "clonedJoints lbjf")leftBottomJoint = object;
            if (object.name === "clonedJoints lbjb")leftBottomJointBack = object;

            if (object.name === "clonedJoints rtjf")rightTopJoint = object
            if (object.name === "clonedJoints rtjb")rightTopJointBack = object
            if (object.name === "clonedJoints rbjf")rightBottomJoint = object;
            if (object.name === "clonedJoints rbjb")rightBottomJointBack = object;

        });
        const tFrameBounding = new THREE.Box3().setFromObject(tFrame);
        const bFrameBounding = new THREE.Box3().setFromObject(bFrame);
        const lFrameBounding = new THREE.Box3().setFromObject(lFrame);
        const rFrameBounding = new THREE.Box3().setFromObject(rFrame);
        const jointZoffset = 0.0545;
        
        const frameWidth = lFrameBounding.max.x - lFrameBounding.min.x;
        const frameHeight = tFrameBounding.max.y - tFrameBounding.min.y;

        const loader = new GLTFLoader();
        var baseModelSize;
        loader.load(cornerJointModel, function (gltf) {
            const cornerJointModel = gltf.scene;
            cornerJointModel.traverse((child) => {

                if (child.isMesh && child.material) {
                    child.material = new THREE.MeshStandardMaterial({
                        color: 0x232323,
                        metalness: 0,
                        roughness: 1,
                        transparent: true,
                        opacity: 0.5,
                        blending: THREE.NormalBlending,
                        // blending: THREE.CustomBlending,
                        // blendEquation: THREE.AddEquation, // default is THREE.AddEquation
                        // blendSrc: THREE.SrcAlphaFactor,   // default is THREE.SrcAlphaFactor
                        // blendDst: THREE.OneMinusSrcAlphaFactor, // default is THREE.OneMinusSrcAlphaFactor
                        depthWrite: false,
                    })
                }
            });
            

            const cornerJointScale = 3.6;
            cornerJointModel.scale.set(cornerJointScale, cornerJointScale, 1);
            const cornerJointBounding = new THREE.Box3().setFromObject(cornerJointModel);
            const cornerJointWidth = cornerJointBounding.max.y - cornerJointBounding.min.y;

            const topOffsetY = tFrameBounding.max.y - (cornerJointWidth / 2);
            const bottomOffsetY = bFrameBounding.min.y + (cornerJointWidth / 2);
            const leftOffsetX = lFrameBounding.min.x + (cornerJointWidth / 2);
            const rightOffsetX = rFrameBounding.max.x - (cornerJointWidth / 2);
            
            // right top joint
            if(!rightTopJoint){
                rightTopJoint = cornerJointModel.clone();
                rightTopJoint.name = "clonedJoints rtjf";
                scene.add(rightTopJoint);
            }
            const rightTopOffsetY = rFrameBounding.max.y - (cornerJointWidth / 2);
            // rightTopJoint.rotation.z = THREE.MathUtils.degToRad( 90 );
            rightTopJoint.position.set( rightOffsetX, rightTopOffsetY, jointZoffset);

            if(!rightTopJointBack){
                rightTopJointBack = cornerJointModel.clone();
                rightTopJointBack.name = "clonedJoints rtjb";
                scene.add(rightTopJointBack);
            }
            rightTopJointBack.position.set( rightOffsetX, rightTopOffsetY, -jointZoffset);

            // right bottom joint
            if(!rightBottomJoint){
                rightBottomJoint = cornerJointModel.clone();
                rightBottomJoint.name = "clonedJoints rbjf";
                scene.add(rightBottomJoint);
            }
            const rightBottomOffsetY = rFrameBounding.min.y + (cornerJointWidth / 2);
            rightBottomJoint.rotation.z = THREE.MathUtils.degToRad( 90 );
            rightBottomJoint.position.set(rightOffsetX, rightBottomOffsetY, jointZoffset);

            if(!rightBottomJointBack){
                rightBottomJointBack = cornerJointModel.clone();
                rightBottomJointBack.name = "clonedJoints rbjb";
                scene.add(rightBottomJointBack);
            }
            rightBottomJointBack.rotation.z = THREE.MathUtils.degToRad( 90 );
            rightBottomJointBack.position.set(rightOffsetX, rightBottomOffsetY, -jointZoffset);

            // left top joint
            if(!leftTopJoint){
                leftTopJoint = cornerJointModel.clone();
                leftTopJoint.name = "clonedJoints ltjf";
                scene.add(leftTopJoint);
            }
            const leftTopOffsetY = lFrameBounding.max.y - (cornerJointWidth / 2);
            // leftTopJoint.rotation.z = THREE.MathUtils.degToRad( 90 );
            leftTopJoint.position.set( leftOffsetX, leftTopOffsetY, jointZoffset);

            if(!leftTopJointBack){
                leftTopJointBack = cornerJointModel.clone();
                leftTopJointBack.name = "clonedJoints ltjb";
                scene.add(leftTopJointBack);
            }
            leftTopJointBack.position.set( leftOffsetX, leftTopOffsetY, -jointZoffset);

            // left bottom joint
            if(!leftBottomJoint){
                leftBottomJoint = cornerJointModel.clone();
                leftBottomJoint.name = "clonedJoints lbjf";
                scene.add(leftBottomJoint);
            }
            const leftBottomOffsetY = lFrameBounding.min.y + (cornerJointWidth / 2);
            leftBottomJoint.rotation.z = THREE.MathUtils.degToRad( 90 );
            leftBottomJoint.position.set(leftOffsetX, leftBottomOffsetY, jointZoffset);

            if(!leftBottomJointBack){
                leftBottomJointBack = cornerJointModel.clone();
                leftBottomJointBack.name = "clonedJoints lbjb";
                scene.add(leftBottomJointBack);
            }
            leftBottomJointBack.rotation.z = THREE.MathUtils.degToRad( 90 );
            leftBottomJointBack.position.set(leftOffsetX, leftBottomOffsetY, -jointZoffset);




            // top right joint
            if(!topRightJoint){
                topRightJoint = cornerJointModel.clone();
                topRightJoint.name = "clonedJoints trjf";
                scene.add(topRightJoint);
            }
            const topRightOffsetX = tFrameBounding.max.x - (cornerJointWidth / 2);
            topRightJoint.rotation.z = THREE.MathUtils.degToRad( 90 );
            topRightJoint.position.set(topRightOffsetX, topOffsetY, jointZoffset);

            if(!topRightJointBack){
                topRightJointBack = cornerJointModel.clone();
                topRightJointBack.name = "clonedJoints trjb";
                scene.add(topRightJointBack);
            }
            topRightJointBack.rotation.z = THREE.MathUtils.degToRad( 90 );
            topRightJointBack.position.set(topRightOffsetX, topOffsetY, -jointZoffset);

            // top left joint
            if(!topLeftJoint){
                topLeftJoint = cornerJointModel.clone();
                topLeftJoint.name = "clonedJoints tljf";
                scene.add(topLeftJoint);
            }
            const topLeftOffsetX = tFrameBounding.min.x + (cornerJointWidth / 2);
            topLeftJoint.position.set(topLeftOffsetX, topOffsetY, jointZoffset);

            if(!topLeftJointBack){
                topLeftJointBack = cornerJointModel.clone();
                topLeftJointBack.name = "clonedJoints tljb";
                scene.add(topLeftJointBack);
            }
            topLeftJointBack.position.set(topLeftOffsetX, topOffsetY, -jointZoffset);




            // bottom right joint
            if(!bottomRightJoint){
                bottomRightJoint = cornerJointModel.clone();
                bottomRightJoint.name = "clonedJoints brjf";
                scene.add(bottomRightJoint);
            }
            const bottomRightOffsetX = bFrameBounding.max.x - (cornerJointWidth / 2);
            // bottomRightJoint.rotation.z = THREE.MathUtils.degToRad( 90 );
            bottomRightJoint.position.set(bottomRightOffsetX, bottomOffsetY, jointZoffset);

            if(!bottomRightJointBack){
                bottomRightJointBack = cornerJointModel.clone();
                bottomRightJointBack.name = "clonedJoints brjb";
                scene.add(bottomRightJointBack);
            }
            bottomRightJointBack.position.set(bottomRightOffsetX, bottomOffsetY, -jointZoffset);

            // bottom left joint
            if(!bottomLeftJoint){
                bottomLeftJoint = cornerJointModel.clone();
                bottomLeftJoint.name = "clonedJoints bljf";
                scene.add(bottomLeftJoint);
            }
            const bottomLeftOffsetX = bFrameBounding.min.x + (cornerJointWidth / 2);
            bottomLeftJoint.rotation.z = THREE.MathUtils.degToRad( 90 );
            bottomLeftJoint.position.set(bottomLeftOffsetX, bottomOffsetY, jointZoffset);

            if(!bottomLeftJointBack){
                bottomLeftJointBack = cornerJointModel.clone();
                bottomLeftJointBack.name = "clonedJoints bljb";
                scene.add(bottomLeftJointBack);
            }
            bottomLeftJointBack.rotation.z = THREE.MathUtils.degToRad( 90 );
            bottomLeftJointBack.position.set(bottomLeftOffsetX, bottomOffsetY, -jointZoffset);

        })

        
    }
    function updateJointsNameOpacity(clonedJoints, opacity){

        clonedJoints.forEach(function (group, index) {
            group.children[0].material.opacity = opacity;
            group.children[0].name += " clonedJoints";
        })
    }
    function updateSpaceBar(scene, spaceBarArray) {
        // top scale change
        const topSpaceBoundingBox1 = new THREE.Box3().setFromObject(spaceBarArray[0]);
        const topSpaceBarWidth = topSpaceBoundingBox1.max.x - topSpaceBoundingBox1.min.x;
        const svgTopFrame1 = scene.getObjectByProperty('name', "topFrame");
        const topFrameBoundingBox = new THREE.Box3().setFromObject(svgTopFrame1);
        const topFrameWidth = topFrameBoundingBox.max.x - topFrameBoundingBox.min.x;
        const topSpaceScale = spaceBarArray[0].userData.oldScale.x * (topFrameWidth / topSpaceBarWidth) * 0.98;
        spaceBarArray[0].scale.set(topSpaceScale, 1, 1);

        //top position change
        const svgTopFrame = scene.getObjectByProperty('name', "topFrame");
        const topFrameBoundingBox2 = new THREE.Box3().setFromObject(svgTopFrame);
        const topSpaceBoundingBox = new THREE.Box3().setFromObject(spaceBarArray[0]);
        let topOffset = topFrameBoundingBox2.min.x - topSpaceBoundingBox.min.x;
        spaceBarArray[0].position.x += topOffset;


        // bottom scale change
        const bottomSpaceBoundingBox1 = new THREE.Box3().setFromObject(spaceBarArray[1]);
        const bottomSpaceBarWidth = bottomSpaceBoundingBox1.max.x - bottomSpaceBoundingBox1.min.x;
        const svgBottomFrame1 = scene.getObjectByProperty('name', "bottomFrame");
        const bottomFrameBoundingBox = new THREE.Box3().setFromObject(svgBottomFrame1);
        const bottomFrameWidth = bottomFrameBoundingBox.max.x - bottomFrameBoundingBox.min.x;
        const bottomSpaceScale = spaceBarArray[1].userData.oldScale.x * (bottomFrameWidth / bottomSpaceBarWidth) * 0.98;
        spaceBarArray[1].scale.set(bottomSpaceScale, 1, 1);

        //bottom position change
        const svgBottomFrame = scene.getObjectByProperty('name', "bottomFrame");
        const bottomFrameBoundingBox2 = new THREE.Box3().setFromObject(svgBottomFrame);
        const bottomSpaceBoundingBox = new THREE.Box3().setFromObject(spaceBarArray[1]);
        let bottomOffset = bottomFrameBoundingBox2.min.x - bottomSpaceBoundingBox.min.x;
        spaceBarArray[1].position.x += bottomOffset;
    }
    function changeBaseFramesOpacity(topFrame, rightFrame, bottomFrame, leftFrame, glass, spaceBarArray, opacityVal, profileJoints) {
        // **********



        // const topFrameBoundingBox = new THREE.Box3().setFromObject(svgTopFrame);
        // const topFrameWidth = topFrameBoundingBox.max.x - topFrameBoundingBox.min.x;


        // const joints = [profileJoints[0][2], profileJoints[0][3], profileJoints[1][2], profileJoints[1][3], profileJoints[2][1], profileJoints[2][2], profileJoints[3][2], profileJoints[3][3]];
        const joints = [profileJoints[0][1], profileJoints[0][1], profileJoints[1][0], profileJoints[1][1], profileJoints[2][0], profileJoints[2][1], profileJoints[3][0], profileJoints[3][1]];

        joints.forEach(function (child, index) {

            if (child && child.material) {
                // if (child.material.opacity > 0) child.userData.oldOpacity = child.material.opacity;
                child.material.opacity = opacityVal > 0 ? 1 : opacityVal;//its working *************
                // child.material.opacity = 0;
                child.material.transparent = true;
                child.material.needsUpdate = true;
                // child.position.z += 0.5;

            }
        })
        // const joints = [profileJoints[0], profileJoints[1], profileJoints[2], profileJoints[3]];
        const frames = [topFrame, rightFrame, bottomFrame, leftFrame, glass];
        frames.forEach(function (frame, index) {
            frame.traverse((child) => {
                if (child.isMesh && child.material) {//***** need to revert */
                    if (child.material.opacity > 0) child.userData.oldOpacity = child.material.opacity;
                    child.material.opacity = opacityVal > 0 ? child.userData.oldOpacity : opacityVal;
                    child.material.transparent = true;
                    child.material.needsUpdate = true;
                }
            });
        })
    }
    async function removeSVGFrames(scene) {
        scene.remove(scene.getObjectByProperty('name', "topFrame"))
        scene.remove(scene.getObjectByProperty('name', "leftFrame"))
        scene.remove(scene.getObjectByProperty('name', "bottomFrame"))
        scene.remove(scene.getObjectByProperty('name', "rightFrame"))
        scene.remove(scene.getObjectByProperty('name', "svgGlass"))
        scene.remove(scene.getObjectByProperty("name", "clonedJoints"));
        
    }
    async function createSvgBaseFrames(scene, profileJoints) {
        await removeSVGFrames(scene);
        // Top frame
        const topPath = new THREE.LineCurve3(
            framesData.top.start,
            framesData.top.end
        );

        const extrudeSettings1 = {
            steps: 2,
            extrudePath: topPath,
            bevelEnabled: true,
            bevelSegments: 3,
            bevelSize: 0.2,
            bevelThickness: 0.1
        };

        const geometry1 = new THREE.ExtrudeGeometry(svgShape, extrudeSettings1);
        
        const mesh1 = new THREE.Mesh(geometry1, material);
        mesh1.name = "topFrame";
        
        mesh1.scale.set(tryScale, tryScale, tryScale);
        mesh1.position.z = 4.5 * tryScale;
        // mesh1.scale.set(meshScale, meshScale, meshScale);
        // mesh1.position.z = 4.5 * meshScale;
        scene.add(mesh1);

        const leftPath = new THREE.LineCurve3(
            framesData.left.start,
            framesData.left.end
        );
        const leftExtrudeSettings = {
            steps: 2,
            bevelEnabled: true,
            bevelSegments: 3,
            bevelSize: 0.2,
            bevelThickness: 0.1,
            extrudePath: leftPath,
        };
        const leftGeometry = new THREE.ExtrudeGeometry(svgShape, leftExtrudeSettings);
        const leftMesh = new THREE.Mesh(leftGeometry, material);
        leftMesh.name = "leftFrame";
        
        leftMesh.scale.set(tryScale, tryScale, tryScale);
        leftMesh.position.z = 4.5 * tryScale;
        // leftMesh.scale.set(meshScale, meshScale, meshScale);
        // leftMesh.position.z = 4.5 * meshScale;
        scene.add(leftMesh);


        const bottomPath = new THREE.LineCurve3(
            framesData.bottom.start,
            framesData.bottom.end
        );
        const bottomExtrudeSettings = {
            steps: 2,
            bevelEnabled: true,
            bevelSegments: 3,
            bevelSize: 0.2,
            bevelThickness: 0.1,
            extrudePath: bottomPath,
        };
        const bottomGeometry = new THREE.ExtrudeGeometry(svgShape, bottomExtrudeSettings);
        const bottomMesh = new THREE.Mesh(bottomGeometry, material);
        bottomMesh.name = "bottomFrame";
        
        bottomMesh.scale.set(tryScale, tryScale, tryScale);
        bottomMesh.position.z = 4.5 * tryScale;
        // bottomMesh.scale.set(meshScale, meshScale, meshScale);
        // bottomMesh.position.z = 4.5 * meshScale;
        scene.add(bottomMesh);


        const frameBottomBound = new THREE.Box3().setFromObject(bottomMesh);




        const rightPath = new THREE.LineCurve3(
            framesData.right.start,
            framesData.right.end
        );
        const rightExtrudeSettings = {
            steps: 2,
            bevelEnabled: true,
            bevelSegments: 3,
            bevelSize: 0.2,
            bevelThickness: 0.1,
            extrudePath: rightPath,
        };
        const rightGeometry = new THREE.ExtrudeGeometry(svgShape, rightExtrudeSettings);
        const rightMesh = new THREE.Mesh(rightGeometry, material);
        rightMesh.name = "rightFrame";
        
        rightMesh.scale.set(tryScale, tryScale, tryScale);
        rightMesh.position.z = 4.5 * tryScale;
        // rightMesh.scale.set(meshScale, meshScale, meshScale);
        // rightMesh.position.z = 4.5 * meshScale;
        scene.add(rightMesh);
        const plane = new THREE.Plane(new THREE.Vector3(0, .001, 0), 0); // Normal and constant for the plane

        // const planeScale = 0.5;
        // plane.scale.set(planeScale, planeScale, planeScale);
        rightMesh.material.clippingPlanes = [plane];
        bottomMesh.material.clippingPlanes = [plane];
        leftMesh.material.clippingPlanes = [plane];
        mesh1.material.clippingPlanes = [plane];

        const frames = {
            topFrame: topPath,
            leftFrame: leftPath,
            bottomFrame: bottomPath,
            rightFrame: rightPath
        };
        let svgObjects = [mesh1, leftMesh, bottomMesh, rightMesh];
        let tlc = "", blc = "", trc = "", brc = "";
        await scene.traverse((object) => {
            if (object.name === "topLeftCorner") tlc = object;
            if (object.name === "bottomLeftCorner") blc = object;
            if (object.name === "topRightCorner") trc = object;
            if (object.name === "bottomRightCorner") brc = object;
            if (object.name === "topLeftCorner" || object.name === "bottomLeftCorner" || object.name === "topRightCorner" || object.name === "bottomRightCorner")svgObjects.push(object);
        });
        const corners = {
            topLeftCorner: tlc ? tlc.userData.extrudePath : null,
            bottomLeftCorner: blc ? blc.userData.extrudePath : null,
            topRightCorner: trc ? trc.userData.extrudePath : null,
            bottomRightCorner: brc ? brc.userData.extrudePath : null,
        };
        const [glassOutline, glassOutlinePath] = createGlassOutline(frames, corners);
        // const scaleMatrix = new THREE.Matrix4().makeScale(0.012, 0.012, 1);
        // glassOutline.applyMatrix4(scaleMatrix);
        await updateProfileJointsData(scene, profileJoints);
        applyPathMaskToObjects(scene, glassOutline, svgObjects, profileJointArray);
        const extrudeSettings = {
            steps: 100,
            bevelEnabled: true,
            bevelSegments: 3,
            bevelSize: 0.2,
            bevelThickness: 0.1
        };
        const glassGeometry = new THREE.ExtrudeGeometry(glassOutline, extrudeSettings);

        // Create a material and mesh
        const glassMaterialOld = scene.getObjectByProperty('name', "GlassPanel034").material;
        const glassMaterial = new THREE.MeshPhongMaterial({
            color: glassMaterialOld.color,
            transparent: true,
            // needsUpdate: true
        });

        glassMaterial.opacity = 0.3;
        const glassMesh = new THREE.Mesh(glassGeometry, glassMaterial);
        glassMesh.name = "svgGlass";
        
        glassMesh.scale.set(tryScale * 0.99, tryScale * 0.99, tryScale * 0.99);
        // glassMesh.scale.set(meshScale * 0.99, meshScale * 0.99, meshScale * 0.99);
        // Add to the scene
        scene.add(glassMesh);


    }
    function applyPathMaskToObjects(scene, path, objectss, joints) {
        // Create an array to store the clipping planes
        const clippingPlanes = [];
        let objects = objectss.concat(joints);

    
        // Get points along the path to create clipping planes
        const points = path.getPoints();
    
        // Iterate through the path points to create clipping planes
        for (let i = 0; i < points.length; i++) {
            const start = points[i];
            const end = points[(i + 1) % points.length]; // Loop back to the start for the last segment
    
            // Calculate the direction and normal for the clipping plane
            const direction = new THREE.Vector3(end.x - start.x, end.y - start.y, 0).normalize();
            const normal = new THREE.Vector3(-direction.y, direction.x, 0); // Perpendicular to the direction
    
            // Create the clipping plane using the normal and a point on the plane
            const plane = new THREE.Plane().setFromNormalAndCoplanarPoint(normal, new THREE.Vector3(start.x * meshScale * 1.01, start.y * meshScale * 1.01, 0));
            clippingPlanes.push(plane);
        }
    
        // Apply the clipping planes to each object's material
        objects.forEach(object => {
            if (object.material) {
                // If material is an array (like for multi-material meshes), apply to each material
                if (Array.isArray(object.material)) {
                    object.material.forEach(material => {
                        material.clippingPlanes = clippingPlanes;
                        material.needsUpdate = true;
                    });
                } else {
                    object.material.clippingPlanes = clippingPlanes;
                    object.material.needsUpdate = true;
                }
            } else if(object.type == "Group"){
                objects.forEach(object => {
                    if (object.material) {

                        // If material is an array (like for multi-material meshes), apply to each material
                        if (Array.isArray(object.material)) {
                            object.material.forEach(material => {
                                material.clippingPlanes = clippingPlanes;
                                material.needsUpdate = true;
                            });
                        } else {
                            object.material.clippingPlanes = clippingPlanes;
                            object.material.needsUpdate = true;
                        }
                    }
                })
            }
        });
    
        // Enable local clipping globally on the renderer

        scene.localClippingEnabled = true; // Assuming you have access to the scene and renderer
    }


    function createGlassOutline(frames, corners) {
        // Create a Shape for the outline
        const shape = new THREE.Shape();
        // Add top frame
        shape.moveTo(frames.topFrame.v1.x, frames.topFrame.v1.y); // Start point of topFrame
        shape.lineTo(frames.topFrame.v2.x, frames.topFrame.v2.y); // End point of topFrame
    
        // Add top-left corner
        if (corners.topLeftCorner instanceof THREE.LineCurve3) {
            shape.lineTo(corners.topLeftCorner.v2.x, corners.topLeftCorner.v2.y);
        } else if (corners.topLeftCorner instanceof THREE.QuadraticBezierCurve3) {
            shape.quadraticCurveTo(
                corners.topLeftCorner.v1.x, corners.topLeftCorner.v1.y,
                corners.topLeftCorner.v2.x, corners.topLeftCorner.v2.y
            );
        }
    

        // Add left frame
        shape.lineTo(frames.leftFrame.v2.x, frames.leftFrame.v2.y);
    
        // Add bottom-left corner
        if (corners.bottomLeftCorner instanceof THREE.LineCurve3) {
            shape.lineTo(corners.bottomLeftCorner.v2.x, corners.bottomLeftCorner.v2.y);
        } else if (corners.bottomLeftCorner instanceof THREE.QuadraticBezierCurve3) {
            shape.quadraticCurveTo(
                corners.bottomLeftCorner.v1.x, corners.bottomLeftCorner.v1.y,
                corners.bottomLeftCorner.v2.x, corners.bottomLeftCorner.v2.y
            );
        }

        // Add bottom frame
        shape.lineTo(frames.bottomFrame.v2.x, frames.bottomFrame.v2.y);
    
        // Add bottom-right corner
        if (corners.bottomRightCorner instanceof THREE.LineCurve3) {

            shape.lineTo(corners.bottomRightCorner.v2.x, corners.bottomRightCorner.v2.y);

        } else if (corners.bottomRightCorner instanceof THREE.QuadraticBezierCurve3) {
            shape.quadraticCurveTo(
                corners.bottomRightCorner.v1.x, corners.bottomRightCorner.v1.y,
                corners.bottomRightCorner.v2.x, corners.bottomRightCorner.v2.y
            );
        }
    
        // Add right frame
        shape.lineTo(frames.rightFrame.v2.x, frames.rightFrame.v2.y);

        // Add top-right corner
        if (corners.topRightCorner instanceof THREE.LineCurve3) {
            shape.lineTo(corners.topRightCorner.v2.x, corners.topRightCorner.v2.y);
        } else if (corners.topRightCorner instanceof THREE.QuadraticBezierCurve3) {
            shape.quadraticCurveTo(
                corners.topRightCorner.v1.x, corners.topRightCorner.v1.y,
                corners.topRightCorner.v2.x, corners.topRightCorner.v2.y
            );
        }
    
        shape.lineTo(frames.topFrame.v1.x, frames.topFrame.v1.y); // Close the path by linking back to the start
    
        const path = new THREE.CurvePath();

        // Add top frame
        path.add(new THREE.LineCurve3(
            new THREE.Vector3(frames.topFrame.v1.x, frames.topFrame.v1.y, 0),
            new THREE.Vector3(frames.topFrame.v2.x, frames.topFrame.v2.y, 0)
        ));

        // Add top-left corner
        if (corners.topLeftCorner instanceof THREE.LineCurve3) {
            path.add(new THREE.LineCurve3(
                new THREE.Vector3(corners.topLeftCorner.v1.x, corners.topLeftCorner.v1.y, 0),
                new THREE.Vector3(corners.topLeftCorner.v2.x, corners.topLeftCorner.v2.y, 0)
            ));
        } else if (corners.topLeftCorner instanceof THREE.QuadraticBezierCurve3) {
            path.add(new THREE.QuadraticBezierCurve3(
                new THREE.Vector3(corners.topLeftCorner.v1.x, corners.topLeftCorner.v1.y, 0),
                new THREE.Vector3(corners.topLeftCorner.v2.x, corners.topLeftCorner.v2.y, 0),
                new THREE.Vector3(corners.topLeftCorner.v2.x, corners.topLeftCorner.v2.y, 0)
            ));
        }

        // Add left frame
        path.add(new THREE.LineCurve3(
            new THREE.Vector3(frames.leftFrame.v1.x, frames.leftFrame.v1.y, 0),
            new THREE.Vector3(frames.leftFrame.v2.x, frames.leftFrame.v2.y, 0)
        ));

        // Add bottom-left corner
        if (corners.bottomLeftCorner instanceof THREE.LineCurve3) {
            path.add(new THREE.LineCurve3(
                new THREE.Vector3(corners.bottomLeftCorner.v1.x, corners.bottomLeftCorner.v1.y, 0),
                new THREE.Vector3(corners.bottomLeftCorner.v2.x, corners.bottomLeftCorner.v2.y, 0)
            ));

        } else if (corners.bottomLeftCorner instanceof THREE.QuadraticBezierCurve3) {
            path.add(new THREE.QuadraticBezierCurve3(
                new THREE.Vector3(corners.bottomLeftCorner.v1.x, corners.bottomLeftCorner.v1.y, 0),
                new THREE.Vector3(corners.bottomLeftCorner.v2.x, corners.bottomLeftCorner.v2.y, 0),
                new THREE.Vector3(corners.bottomLeftCorner.v2.x, corners.bottomLeftCorner.v2.y, 0)
            ));
        }

        // Add bottom frame
        path.add(new THREE.LineCurve3(
            new THREE.Vector3(frames.bottomFrame.v1.x, frames.bottomFrame.v1.y, 0),
            new THREE.Vector3(frames.bottomFrame.v2.x, frames.bottomFrame.v2.y, 0)
        ));


        // Add bottom-right corner
        if (corners.bottomRightCorner instanceof THREE.LineCurve3) {
            path.add(new THREE.LineCurve3(
                new THREE.Vector3(corners.bottomRightCorner.v1.x, corners.bottomRightCorner.v1.y, 0),
                new THREE.Vector3(corners.bottomRightCorner.v2.x, corners.bottomRightCorner.v2.y, 0)
            ));
        } else if (corners.bottomRightCorner instanceof THREE.QuadraticBezierCurve3) {
            path.add(new THREE.QuadraticBezierCurve3(
                new THREE.Vector3(corners.bottomRightCorner.v1.x, corners.bottomRightCorner.v1.y, 0),
                new THREE.Vector3(corners.bottomRightCorner.v2.x, corners.bottomRightCorner.v2.y, 0),
                new THREE.Vector3(corners.bottomRightCorner.v2.x, corners.bottomRightCorner.v2.y, 0)
            ));
        }

        // Add right frame
        path.add(new THREE.LineCurve3(
            new THREE.Vector3(frames.rightFrame.v1.x, frames.rightFrame.v1.y, 0),
            new THREE.Vector3(frames.rightFrame.v2.x, frames.rightFrame.v2.y, 0)
        ));

        // Add top-right corner
        if (corners.topRightCorner instanceof THREE.LineCurve3) {
            path.add(new THREE.LineCurve3(
                new THREE.Vector3(corners.topRightCorner.v1.x, corners.topRightCorner.v1.y, 0),
                new THREE.Vector3(corners.topRightCorner.v2.x, corners.topRightCorner.v2.y, 0)
            ));
        } else if (corners.topRightCorner instanceof THREE.QuadraticBezierCurve3) {
            path.add(new THREE.QuadraticBezierCurve3(
                new THREE.Vector3(corners.topRightCorner.v1.x, corners.topRightCorner.v1.y, 0),
                new THREE.Vector3(corners.topRightCorner.v2.x, corners.topRightCorner.v2.y, 0),
                new THREE.Vector3(corners.topRightCorner.v2.x, corners.topRightCorner.v2.y, 0)
            ));
        }
        // Close the path by linking back to the start

        path.add(new THREE.LineCurve3(
            new THREE.Vector3(frames.topFrame.v1.x, frames.topFrame.v1.y, 0),
            new THREE.Vector3(frames.topFrame.v1.x, frames.topFrame.v1.y, 0)
        ));

    
        return [shape, path]; // Return both shape and path
    }
    return { chamferedCorner }
}


export default useShapedCorner;