import axios from "axios";
import { servicePath } from "../utility/data";

// get all panel collections for selected master panel based on ID
export const getPanelCollectionList = (accessToken, id) => {

    console.log(id, "fetchId")
    const formData = new FormData();
    formData.append("ProductMasterId", id);

    let config = {
        method: 'POST',
        maxBodyLength: Infinity,
        headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'multipart/form-data'
        }
    };

    return axios
        .post(`${servicePath}/Panel/GetAllPanelProductCollection`, formData, config)
        .then((res) => {
            return res;
        })
        .catch((error) => {
            return error;
        });
}

export const getPanelStyleList = (accessToken, id) => {

    const formData = new FormData();
    formData.append("ProductCollectionId", id);

    let config = {
        method: 'POST',
        maxBodyLength: Infinity,
        headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'multipart/form-data'
        }
    };

    return axios
        .post(`${servicePath}/Panel/GetAllPanelProduct`, formData, config)
        .then((res) => {
            return res;
        })
        .catch((error) => {
            return error;
        });
}

// get all right menu item list based on page/type
export const getRightMenuList = (accessToken, id) => {

    const formData = new FormData();
    formData.append("ProductCollectionId", id);

    let config = {
        method: 'POST',
        maxBodyLength: Infinity,
        headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'multipart/form-data'
        }
    };

    return axios
        .post(`${servicePath}/Panel/GetAllPanelProduct`, formData, config)
        .then((res) => {
            return res;
        })
        .catch((error) => {
            return error;
        });
}

// get details of selected item in right menu
export const getItemDetails = (accessToken, id) => {
    const formData = new FormData();
    formData.append("Id", id);

    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'multipart/form-data'
        }
    };

    return axios
        .post(`${servicePath}/Panel/GetPanelProduct`, formData, config)
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
};

// delete selected item
export const deleteRightItem = (accessToken, id) => {

    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    const formData = new FormData()
    formData.append("Id", id)

    return axios
        .post(`${servicePath}/Panel/DeletePanelProduct`, formData, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
}

// update order of selected item
export const updateOrder = (accessToken, id, collectionId, direction) => {

    var requestBody = {
        "id": id,
        "sequenceNo": 0,
        "typeText": collectionId,
        "type": direction
    };

    const headers = {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
    }

    return axios
        .post(`${servicePath}/Panel/UpdatePanelProductOrder`, requestBody, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
}

// update details of selected item
export const updateItemDetails = (accessToken, data) => {

    const headers = {
        'accept': 'text/plain',
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'multipart/form-data'
    }

    const formData = new FormData();

    formData.append("Id", data.id);
    formData.append("Name", data.name);
    formData.append("DefaultPanelStyle", data.defaultStyle);
    formData.append("PanelProductCollectionId", data.collectionId);
    formData.append('ProfilePath', data.filePath);
    formData.append('ProfilePathUrl', '');

    return axios
        .post(`${servicePath}/Panel/AddUpdatePanelProduct`, formData, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
};

// add new right menu item based on page/type
export const addNewRightItem = (accessToken, data, collectionId) => {

    const formData = new FormData();

    formData.append("Id", '');
    formData.append("Name", data);
    formData.append("DefaultPanelStyle", false);
    formData.append("PanelProductCollectionId", collectionId);

    const headers = {
        'accept': 'text/plain',
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'multipart/form-data'
    }

    return axios
        .post(`${servicePath}/Panel/AddUpdatePanelProduct`, formData, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
};