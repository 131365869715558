// cornerShapes.js
import * as THREE from 'three';

// Create Chamfered Corner
export function createChamferedCorner(start, end, color) {
    const points = [start, end];
    const geometry = new THREE.BufferGeometry().setFromPoints(points);
    const material = new THREE.LineBasicMaterial({ color });
    return new THREE.Line(geometry, material);
}

// Function to create a line path between two points
export function createLinePath(start, end) {
    const curve = new THREE.LineCurve3(start, end);
    return curve;
}
export function createEllipsePath(start, middle, end){
    const curve = new THREE.QuadraticBezierCurve3(start, middle, end);
    return curve;
}

// Function to create a corner path based on Frame Data
export function createCornerPath(frameData, cornerPosition, cornerStyle) {
    const { dimensions, frame } = frameData;
    const { width, height } = dimensions;
    const corner = frame.corners[cornerPosition];
    if (!corner || corner.type === 'none') return null; // Skip if no corner or type is none

    let start, end, middle;
    const { size } = corner;

    if(cornerStyle == "chamfer" || cornerStyle == "Chamfer"){
        switch (cornerPosition) {
            case 'topLeft':
                // Starting from the top-left corner of the frame, considering size offset
                start = new THREE.Vector3(-width / 2, height / 2 - size.height, 0);
                end = new THREE.Vector3(-width / 2 + size.width, height / 2, 0);
                break;
            case 'topRight':
                // Starting from the top-right corner of the frame, considering size offset
                start = new THREE.Vector3(width / 2 - size.width, height / 2, 0);
                end = new THREE.Vector3(width / 2, height / 2 - size.height, 0);
                break;
            case 'bottomRight':
                // Starting from the bottom-right corner of the frame, considering size offset
                start = new THREE.Vector3(width / 2, -height / 2 + size.height, 0);
                end = new THREE.Vector3(width / 2 - size.width, -height / 2, 0);
                break;
            case 'bottomLeft':
                // Starting from the bottom-left corner of the frame, considering size offset
                start = new THREE.Vector3(-width / 2 + size.width, -height / 2, 0);
                end = new THREE.Vector3(-width / 2, -height / 2 + size.height, 0);
                break;
        }
        return createLinePath(start, end);
    }else if(cornerStyle == "Radius" || cornerStyle == "radius"){
        switch (cornerPosition) {
            case 'topLeft':
                start = new THREE.Vector3(-width / 2, height / 2 - size.height, 0);
                end = new THREE.Vector3(-width / 2 + size.width, height / 2, 0);
                // const circleInfotl = getCircleInfo([end.x, end.y], [start.x, start.y], cornerPosition, size, [width, height]);
                const circleInfotl = getCircleInfo([start.x, start.y], [end.x, end.y], cornerPosition, size, [width, height]);
                return circleInfotl;
                break;
            case 'topRight':
                start = new THREE.Vector3(width / 2 - size.width, height / 2, 0);
                end = new THREE.Vector3(width / 2, height / 2 - size.height, 0);
                
                const circleInfo = getCircleInfo([start.x, start.y], [end.x, end.y], cornerPosition, size, [width, height]);
                return circleInfo;
                
                // Starting from the top-right corner of the frame, considering size offset
                
                break;
            case 'bottomRight':
                // Starting from the bottom-right corner of the frame, considering size offset
                start = new THREE.Vector3(width / 2, -height / 2 + size.height, 0);
                end = new THREE.Vector3(width / 2 - size.width, -height / 2, 0);
                const circleInfoBr = getCircleInfo([start.x, start.y], [end.x, end.y], cornerPosition, size, [width, height]);
                // const circleInfoBr = getCircleInfo([start.x, start.y], [end.x, end.y], cornerPosition, size);
                return circleInfoBr;
                break;
            case 'bottomLeft':
                // Starting from the bottom-left corner of the frame, considering size offset
                start = new THREE.Vector3(-width / 2 + size.width, -height / 2, 0);
                end = new THREE.Vector3(-width / 2, -height / 2 + size.height, 0);
                const circleInfoBl = getCircleInfo([start.x, start.y], [end.x, end.y], cornerPosition, size, [width, height]);
                // const circleInfoBl = getCircleInfo([start.x, start.y], [end.x, end.y], cornerPosition, size);
                return circleInfoBl;
                break;
        }
    }else if(cornerStyle == "Ellipse" || cornerStyle == "ellipse"){
        switch (cornerPosition) {
            case 'topLeft':
                // Starting from the top-left corner of the frame, considering size offset
                start = new THREE.Vector3(-width / 2, height / 2 - size.height, 0);
                middle = new THREE.Vector3(-width / 2, height / 2, 0);
                end = new THREE.Vector3(-width / 2 + size.width, height / 2, 0);
                break;
            case 'topRight':
                // Starting from the top-right corner of the frame, considering size offset
                start = new THREE.Vector3(width / 2 - size.width, height / 2, 0);
                middle = new THREE.Vector3(width / 2, height / 2, 0);
                end = new THREE.Vector3(width / 2, height / 2 - size.height, 0);
                break;
            case 'bottomRight':
                // Starting from the bottom-right corner of the frame, considering size offset
                start = new THREE.Vector3(width / 2, -height / 2 + size.height, 0);
                middle = new THREE.Vector3(width / 2, -height / 2, 0);
                end = new THREE.Vector3(width / 2 - size.width, -height / 2, 0);
                break;
            case 'bottomLeft':
                // Starting from the bottom-left corner of the frame, considering size offset
                start = new THREE.Vector3(-width / 2 + size.width, -height / 2, 0);
                middle = new THREE.Vector3(-width / 2, -height / 2, 0);
                end = new THREE.Vector3(-width / 2, -height / 2 + size.height, 0);
                break;
        }
        return createEllipsePath(start, middle, end);
    }else{
        switch (cornerPosition) {
            case 'topLeft':
                // Starting from the top-left corner of the frame, considering size offset
                start = new THREE.Vector3(-width / 2, height / 2 - size.height, 0);
                end = new THREE.Vector3(-width / 2 + size.width, height / 2, 0);
                break;
            case 'topRight':
                // Starting from the top-right corner of the frame, considering size offset
                start = new THREE.Vector3(width / 2 - size.width, height / 2, 0);
                end = new THREE.Vector3(width / 2, height / 2 - size.height, 0);
                break;
            case 'bottomRight':
                // Starting from the bottom-right corner of the frame, considering size offset
                start = new THREE.Vector3(width / 2, -height / 2 + size.height, 0);
                end = new THREE.Vector3(width / 2 - size.width, -height / 2, 0);
                break;
            case 'bottomLeft':
                // Starting from the bottom-left corner of the frame, considering size offset
                start = new THREE.Vector3(-width / 2 + size.width, -height / 2, 0);
                end = new THREE.Vector3(-width / 2, -height / 2 + size.height, 0);
                break;
        }
    }

    // Debugging: Check if the start or end points have NaN values
    if (isNaN(start.x) || isNaN(start.y) || isNaN(end.x) || isNaN(end.y)) {
        console.error(`Invalid corner path points for corner position "${cornerPosition}": Start - ${start.toArray()}, End - ${end.toArray()}`);
        return null;
    }

    return createLinePath(start, end);
}


function getCircleInfo(point1, point2, cornerPosition, dimention, wdim) {
    const yMulti = cornerPosition == "topRight" ? -1 : 1;
    const mMulti = cornerPosition == "topRight" ? -1 : 1;
    const xMulti = cornerPosition == "topRight" ? 1 : -1; // New X-axis multiplier for topLeft adjustment

    const width = dimention.width;
    const height = dimention.height;
    if (width > height) {
        // Calculate the circle origin and radius when width > height
        // Origin will be on the Y-axis (Ox = 0, need to find Oy)
        const A = height - 0; // y1 - y2
        const B = (height ** 2 - width ** 2) / 2;

        // Solve for Oy (the y-coordinate of the circle's center on the Y-axis)
        const Oy = B / A;
        
        // let yOffset = Oy;
        const reverse = cornerPosition == "topLeft1" ? true : false;
        let yOffset = Oy + point2[1];

        // Solve for the radius (r)
        const radius = Math.sqrt(width ** 2 + Oy ** 2);
        
        let xOffset = point1[0];
        if(cornerPosition == "topLeft"){
            // yOffset = point1[1] - radius;
            yOffset = Oy + point1[1];
            xOffset = point2[0];
        }
        if(cornerPosition == "bottomRight"){
            yOffset = point2[1] + radius;
            xOffset = point2[0];
        }
        if(cornerPosition == "bottomLeft"){
            yOffset = point1[1] + radius;
            xOffset = point1[0];
        }

        const arcCurve = getArcCurve(xOffset, yOffset, radius, {x: point1[0], y: point1[1]}, {x: point2[0], y: point2[1]}, reverse);
        return arcCurve;
        return {
            origin: { x: point1[0], y: yOffset },
            radius: radius
        };
    } else {
        
        const p1 = [0, height];
        const p2 = [width, 0];

        const A = p1[0] - p2[0]; // x1 - x2
        const B = (p1[0] ** 2 + p1[1] ** 2 - p2[0] ** 2 - p2[1] ** 2) / 2;

        // Solve for Ox (the x-coordinate of the circle's center on the X-axis)
        const Ox = B / A;
        // const xOff = cornerPosition == "topLeft" ? wdim/2 : 0;
        // const xOffset = Ox + point1[0] + xOff;
        let xOffset = Ox + point1[0];
        // Solve for the radius (r)
        const radius = Math.sqrt((p1[0] - Ox) ** 2 + p1[1] ** 2);

        const reverse = cornerPosition == "bottomLeft1" ? true : false;

        let yOffset = point2[1];
        if(cornerPosition == "topLeft"){
            yOffset = point1[1];
            xOffset = point1[0] + radius;
        }
        if(cornerPosition == "bottomRight"){
            yOffset = point1[1];
            xOffset = point1[0] - radius;
        }
        if(cornerPosition == "bottomLeft"){
            // console.clear();            
        
            yOffset = point2[1] + 0.001;
            xOffset = point2[0] + radius;
            
        }
        const arcCurve = getArcCurve(xOffset, yOffset, radius, {x: point1[0], y: point1[1]}, {x: point2[0], y: point2[1]}, reverse);
        return arcCurve;
        return {
            origin: { x: xOffset, y: point2[1] },
            radius: radius
        };
    }
}
function getArcCurve(originX, originY, radius, startPoint, endPoint, reverse) {
    // Calculate the start and end angles in radians relative to the origin
    let startAngle = Math.atan2(startPoint.y - originY, startPoint.x - originX);
    let endAngle = Math.atan2(endPoint.y - originY, endPoint.x - originX);
    if(reverse){
        endAngle = Math.atan2(startPoint.y - originY, startPoint.x - originX);
        startAngle = Math.atan2(endPoint.y - originY, endPoint.x - originX);
    }

    // Calculate the number of points to generate along the arc
    const numPoints = 50; // More points for a smoother arc
    const arcPoints = [];

    // Generate points along the arc
    for (let i = 0; i <= numPoints; i++) {
        const angle = startAngle + (i / numPoints) * (endAngle - startAngle);
        const x = originX + radius * Math.cos(angle);
        const y = originY + radius * Math.sin(angle);
        
        // Push 3D Vector points (x, y, z = 0) to the array
        arcPoints.push(new THREE.Vector3(x, y, 0));
    }

    // Create a CatmullRomCurve3 (3D curve) from the arc points
    return new THREE.CatmullRomCurve3(arcPoints);
}
function getArcCurvexxx(originX, originY, radius, startingPoint, endingPoint) {
    // Calculate the startAngle (in radians)
    const startAngle = Math.atan2(startingPoint.y - originY, startingPoint.x - originX);

    // Calculate the endAngle (in radians)
    const endAngle = Math.atan2(endingPoint.y - originY, endingPoint.x - originX);

    // Create the ArcCurve using the calculated angles
    const arcCurve = new THREE.ArcCurve(
        originX, originY,  // Origin (center) of the arc
        radius,            // Radius of the arc
        startAngle,        // Calculated start angle (in radians)
        endAngle,          // Calculated end angle (in radians)
        true              // Set to true for counterclockwise
    );

    return arcCurve;
}