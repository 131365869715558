import axios from "axios";
import { servicePath } from "../utility/data";

// get all right menu item list based on page/type
export const getRightMenuList = (accessToken, id) => {

    const formData = new FormData();
    formData.append("ProductMasterId", id);

    let config = {
        method: 'POST',
        maxBodyLength: Infinity,
        headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'multipart/form-data'
        }
    };

    return axios
        .post(`${servicePath}/Panel/GetAllPanelProductCollection`, formData, config)
        .then((res) => {
            return res;
        })
        .catch((error) => {
            return error;
        });
}

// get details of selected item in right menu
export const getPanelProductCollection = (accessToken, id) => {
    const formData = new FormData();
    formData.append("Id", id);

    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'multipart/form-data'
        }
    };

    return axios
        .post(`${servicePath}/Panel/GetPanelProductCollection`, formData, config)
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
};

// delete selected item
export const deleteRightItem = (accessToken, id) => {

    console.log(id, "delete id")

    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    const formData = new FormData()
    formData.append("Id", id)

    return axios
        .post(`${servicePath}/Panel/DeletePanelProductCollection`, formData, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
}

// update order of selected item
export const updateOrder = (accessToken, id, panelMasterId, direction) => {

    var requestBody = {
        "id": id,
        "sequenceNo": 0,
        "typeText": panelMasterId,
        "type": direction
    };

    const headers = {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
    }

    return axios
        .post(`${servicePath}/Panel/UpdatePanelProductCollectionOrder`, requestBody, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
}

// update details of selected item
export const updateItemDetails = (accessToken, data) => {

    const headers = {
        'accept': 'text/plain',
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'multipart/form-data'
    }

    const formData = new FormData();

    formData.append("Id", data.id);
    formData.append("Name", data.name);
    formData.append("DefaultCollection", data.defaultCollection);
    formData.append("PanelProductMasterId", data.masterId);

    return axios
        .post(`${servicePath}/Panel/AddUpdatePanelProductCollection`, formData, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
};

// add new right menu item based on page/type
export const addNewRightItem = (accessToken, data, masterId) => {

    const formData = new FormData();

    formData.append("Id", '');
    formData.append("Name", data);
    formData.append("DefaultCollection", false);
    formData.append("PanelProductMasterId", masterId);

    const headers = {
        'accept': 'text/plain',
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'multipart/form-data'
    }

    return axios
        .post(`${servicePath}/Panel/AddUpdatePanelProductCollection`, formData, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
};