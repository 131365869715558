import { useState } from "react";
import { useContext } from "react";
import { createContext } from "react";

// context for product palette
const productPaletteContext = createContext();

const useProductPaletteContext = () => {
    const value = useContext(productPaletteContext);
    return value;
}

const CustomProductPaletteContext = ({ children }) => {
    const [newItemList, setNewItemList] = useState([]);
    const [selected, setSelected] = useState();
    const [timestamp, setTimestamp] = useState('');
    const [activeIndex, setActiveIndex] = useState(0);


    // Compute the last index of the item data
    const lastIndex = newItemList?.length - 1;

    // Function to add a new item item
    const addNewItem = (currentData) => {
        setNewItemList((prevItem) => [...prevItem, currentData]);
    };

    // Function to fetch and select a specific item
    const fetchSelected = (index, uniqueId) => {
        const data = newItemList?.find((item) => item.id === uniqueId);
        setSelected(data?.name);
        setTimestamp(uniqueId);
        setActiveIndex(index);
    };

    // Function to edit and update an item item
    const handleEdit = (value) => {
        const updateIndex = newItemList?.findIndex((item) => item.id === timestamp);
        if (updateIndex !== -1) {
            let updatedItem = [...newItemList];
            updatedItem[updateIndex] = {
                ...updatedItem[updateIndex],
                name: value,
            };
            setNewItemList(updatedItem);
        }
    };

    // handles the modification for 3d elements
    const handleModificationChange = (modType , value) =>{
        const updateIndex = newItemList?.findIndex((item) => item.id === timestamp);  
        // console.log(updateIndex , "51");
        if (updateIndex !== -1) {
            let updatedItem = [...newItemList];
            updatedItem[updateIndex] = {
                ...updatedItem[updateIndex],
                [modType] : value,
            };
            setNewItemList(updatedItem);
        }
    }

    const newHandleModificationChange = (modType , value) =>{
        const updateIndex = newItemList?.findIndex((item) => item.colourPaletteId === timestamp);  
        // console.log(updateIndex , "51");
        if (updateIndex !== -1) {
            let updatedItem = [...newItemList];
            updatedItem[updateIndex] = {
                ...updatedItem[updateIndex],
                [modType] : value,
            };
            setNewItemList(updatedItem);
        }
    }

  // Function to edit and update description in list
    const handleDescEdit = (value) =>{
        const updateIndex = newItemList?.findIndex((item) => item.id === timestamp);
        if (updateIndex !== -1) {
            let updatedItem = [...newItemList];
            updatedItem[updateIndex] = {
                ...updatedItem[updateIndex],
                description: value,
            };
            setNewItemList(updatedItem);
        }
    }

    // Function to handle navigating up the active index
    const handleActiveIndexUp = () => {
        if (activeIndex === 0) {
            setActiveIndex(lastIndex);
        } else {
            setActiveIndex(activeIndex - 1);
        }
    };

    // Function to handle navigating down the active index
    const handleActiveIndexDown = () => {
        if (activeIndex === lastIndex) {
            setActiveIndex(0);
        } else {
            setActiveIndex(activeIndex + 1);
        }
    };

    return (
        <productPaletteContext.Provider value={{
            newItemList,
            selected,
            timestamp,
            activeIndex,
            addNewItem,
            fetchSelected,
            handleEdit,
            handleActiveIndexUp,
            handleActiveIndexDown,
            setSelected,
            setActiveIndex,
            setNewItemList,
            setTimestamp,
            handleDescEdit,
            handleModificationChange,
            newHandleModificationChange
        }}>
            {children}
        </productPaletteContext.Provider>
    )
}

export { useProductPaletteContext }
export default CustomProductPaletteContext;

