import React, { useState, useEffect } from 'react'
import { Form } from 'react-bootstrap';
import { MdOutlineKeyboardArrowDown } from 'react-icons/md';
import { MenuItem, OutlinedInput, Select } from 'material-ui-core';
import {
    Row,
    Col,
    Button,
    FormGroup,
    Label,
    Input,
} from 'reactstrap';
import { MenuProps, noSelectOptions } from '../utility/data';
import NumberInputDecimal from './NumberInputDecimal';
import { useRightContext } from '../context/rightMenuContext';
import { getAllColoursCollection } from '../services/productServices';
import { getAllFrameStyleColletion } from '../services/frameCollectionsService';
import { getProfileJointsList } from '../services/sysProfileJointsService';
import DropZone from './DropZone';

const SystemGeneral = ({ isEditable, activeTab, accessToken, selectItemDetails, updateItemDetailsServices, handleFormReset }) => {

    const { timestamp, setSelected, handleEdit, handleDescEdit, setDescUpdate, handleFileUpdate } = useRightContext()

    var acceptFileType = {
        'image/png': ['.jpg', '.jpeg', '.png', '.svg', '.gif'],
    }

    const [colourOptions, setColourOptions] = useState([]);
    const [frameStyleOptions, setFrameStyleOptions] = useState([]);
    const [jointsOptions, setJointsOptions] = useState([]);

    const [name, setName] = useState('')
    const [itemId, setItemId] = useState('')
    const [description, setDescription] = useState('')
    const [colours, setColours] = useState("")
    const [frameStyles, setFrameStyles] = useState("")
    const [joints, setJoints] = useState("")

    const [transomDrop, setTransomDrop] = useState("0")
    const [hungWidth, setHungWidth] = useState("0")

    const [hornLength, setHornLength] = useState("0")
    const [windowLength, setWindowLength] = useState("0")

    const [nameError, setNameError] = useState(false)
    const [descriptionError, setDescriptionError] = useState(false)

    const [uploadedFile, setUploadedFile] = useState('')
    const [filePreview, setFilePreview] = useState('')

    useEffect(() => {
        if (activeTab === "General") {
            getColourOptions()
            getFrameStylesOptions()
            getProfileJointsOptions()
        }
    }, [activeTab])

    useEffect(() => {
        if (filePreview) {
            handleFileUpdate(filePreview)
        }
    }, [filePreview])

    // setting all the value for prefilled inputs
    useEffect(() => {
        // console.log(selectItemDetails, "data left")

        setName(selectItemDetails?.name || '')
        setSelected(selectItemDetails?.name || '')

        setItemId(selectItemDetails?.id || '')
        setDescription(selectItemDetails?.description || '')

        setTransomDrop(selectItemDetails?.defaultTransomDrop || 0)
        setHungWidth(selectItemDetails?.defaultSideHungWidth || 0)

        setHornLength(selectItemDetails?.defaultSill || 0)
        setWindowLength(selectItemDetails?.defaultWindow || 0)

        setUploadedFile('')
        setFilePreview(selectItemDetails?.imagePath || '')

        setNameError(false)
        setDescriptionError(false)
    }, [selectItemDetails])

    useEffect(() => {
        if (colourOptions && colourOptions.length > 0) {
            setColours(selectItemDetails?.colourId || colourOptions[0]?.id)
        }
    }, [selectItemDetails, colourOptions])

    useEffect(() => {
        if (frameStyleOptions && frameStyleOptions.length > 0) {
            setFrameStyles(selectItemDetails?.frameStyleId || frameStyleOptions[0]?.id)
        }
    }, [selectItemDetails, frameStyleOptions])

    useEffect(() => {
        if (jointsOptions && jointsOptions.length > 0) {
            setJoints(selectItemDetails?.jointsId || jointsOptions[0]?.id)
        }
    }, [selectItemDetails, jointsOptions])

    // get product color collection
    const getColourOptions = async () => {
        const res = await getAllColoursCollection("1", accessToken)

        if (res && res && res.status === 200) {
            if (res?.data?.statusCode === 200) {
                if (res?.data?.entity !== null) {
                    if (res?.data?.entity?.length > 0) {
                        setColourOptions(res?.data?.entity)
                    } else {
                        setColourOptions(noSelectOptions)
                    }
                }
            }
        }
    }

    // get frame styles collections list
    const getFrameStylesOptions = async () => {
        const res = await getAllFrameStyleColletion(accessToken)

        if (res && res && res.status === 200) {
            if (res?.data?.statusCode === 200) {
                if (res?.data?.entity !== null) {
                    if (res?.data?.entity?.length > 0) {
                        setFrameStyleOptions(res?.data?.entity)
                    } else {
                        setFrameStyleOptions(noSelectOptions)
                    }
                }
            }
        }
    }

    // get profile joints list
    const getProfileJointsOptions = async () => {
        const res = await getProfileJointsList(accessToken)

        if (res && res && res.status === 200) {
            if (res?.data?.statusCode === 200) {
                if (res?.data?.entity !== null) {
                    if (res?.data?.entity?.length > 0) {

                        setJointsOptions(res?.data?.entity)
                    } else {
                        setJointsOptions(noSelectOptions)
                    }
                }
            }
        }
    }

    const handleNameChange = (e) => {
        const value = e.target.value

        if (e.target.value?.length <= 50) {
            setName(value)
            handleEdit(value)
            setSelected(value)
        }

        if (value.length > 50) {
            setNameError(true)
        } else {
            setNameError(false)
        }
    }

    const handleDescriptionChange = (e) => {
        const value = e.target.value

        if (e.target.value?.length <= 80) {
            setDescription(value)
            handleDescEdit(value)
            setDescUpdate(value)
        }

        if (value.length > 80) {
            setDescriptionError(true)
        } else {
            setDescriptionError(false)
        }
    }

    const handleColoursChange = (e) => {
        const value = e.target.value
        setColours(value)
    }

    const handleFormSubmit = () => {

        var formDataValues = {
            'id': selectItemDetails?.id,
            'collectionId': selectItemDetails?.collectionId,
            'name': name,
            'description': description,
            'colourId': colours,
            'frameStyleId': frameStyles,
            'jointsId': joints,
            'defaultTransomDrop': transomDrop,
            'defaultSideHungWidth': hungWidth,
            'defaultSill': hornLength,
            'defaultWindow': windowLength,
            "filePath": uploadedFile || '',
            "imagePath": '',
            'specifications': selectItemDetails?.specifications,
            'defaultSpecifications': selectItemDetails?.defaultSpecificationId,
            'frameClearance': selectItemDetails?.frameClearance,
            'gasketClearance': selectItemDetails?.gasketClearance,
            'beadRadio': selectItemDetails?.bead,

            'profileId': selectItemDetails?.profileId,
            'subProductId': selectItemDetails?.subProductId,
            'hardwareId': selectItemDetails?.hardwareId,
        }

        updateItemDetailsServices(formDataValues, "General")
    }

    return (
        <Form className='h-100 d-flex flex-column flex-grow-1 justify-content-between'>
            <div className='inside_scroll has_tab has_btns has_head'>
                <Row>
                    <Col xxl="12">
                        <Row>
                            <Col lg="6">
                                <FormGroup className="form-group">
                                    <Label for="name">
                                        Name
                                    </Label>
                                    <Input className='mb-1' name='name' value={name} onChange={(e) => handleNameChange(e)} />

                                    {nameError && timestamp && itemId && (
                                        <div className='mt-1 text-muted text-small'>Max 50 characters are allowed</div>
                                    )}

                                    {!name && itemId && (
                                        <div className='mt-1 text-danger text-small'>Name is required</div>
                                    )}
                                </FormGroup>
                            </Col>

                            <Col lg="6">
                                <FormGroup className="form-group">
                                    <Label for="description">
                                        Description
                                    </Label>
                                    <Input className='mb-1' name='description' value={description} onChange={(e) => handleDescriptionChange(e)} />

                                    {(descriptionError && timestamp && itemId) && (
                                        <div className='mt-1 text-muted text-small'>Max 80 characters are allowed</div>
                                    )}
                                </FormGroup>
                            </Col>

                            <Col lg="6">
                                <FormGroup className="form-group">
                                    <Label for="coloursSelect">
                                        Colours
                                    </Label>

                                    <Select
                                        labelId="coloursSelect"
                                        id="coloursSelect"
                                        value={colours}
                                        label="coloursSelect"
                                        onChange={(e) => { handleColoursChange(e) }}
                                        input={<OutlinedInput className='w-100' name='coloursSelect' />}

                                        MenuProps={MenuProps}

                                        IconComponent={() => (
                                            <MdOutlineKeyboardArrowDown size={20} className='me-1 MuiSvgIcon-root MuiSelect-icon' />
                                        )}
                                    >
                                        {colourOptions && colourOptions.map((o, index) => (
                                            <MenuItem key={index} value={o?.id}>
                                                {o?.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormGroup>
                            </Col>

                            <Col lg="6">
                                <FormGroup className="form-group">
                                    <Label for="FrameSelect">
                                        Frame styles
                                    </Label>

                                    <Select
                                        labelId="FrameSelect"
                                        id="FrameSelect"
                                        value={frameStyles}
                                        label="FrameSelect"
                                        onChange={(e) => { setFrameStyles(e.target.value) }}
                                        input={<OutlinedInput className='w-100' name='FrameSelect' />}

                                        MenuProps={MenuProps}

                                        IconComponent={() => (
                                            <MdOutlineKeyboardArrowDown size={20} className='me-1 MuiSvgIcon-root MuiSelect-icon' />
                                        )}
                                    >
                                        {frameStyleOptions && frameStyleOptions.map((o, index) => (
                                            <MenuItem key={index} value={o?.id}>
                                                {o?.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormGroup>
                            </Col>

                            <Col lg="6">
                                <FormGroup className="form-group">
                                    <Label for="jointsSelect">
                                        Joints
                                    </Label>

                                    <Select
                                        labelId="jointsSelect"
                                        id="jointsSelect"
                                        value={joints}
                                        label="jointsSelect"
                                        onChange={(e) => { setJoints(e.target.value) }}
                                        input={<OutlinedInput className='w-100' name='jointsSelect' />}

                                        MenuProps={MenuProps}

                                        IconComponent={() => (
                                            <MdOutlineKeyboardArrowDown size={20} className='me-1 MuiSvgIcon-root MuiSelect-icon' />
                                        )}
                                    >
                                        {jointsOptions && jointsOptions.map((o, index) => (
                                            <MenuItem key={index} value={o?.id}>
                                                {o?.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormGroup>
                            </Col>

                            <Col lg="6">
                                <FormGroup className="form-group">
                                    <Label for="transomDrop">Default transom drop</Label>

                                    <NumberInputDecimal
                                        name="transomDrop"
                                        value={transomDrop}
                                        setValue={setTransomDrop}
                                    />
                                </FormGroup>
                            </Col>

                            <Col lg="6">
                                <FormGroup className="form-group">
                                    <Label for="hungWidth">Default side hung width</Label>

                                    <NumberInputDecimal
                                        name="hungWidth"
                                        value={hungWidth}
                                        setValue={setHungWidth}
                                    />
                                </FormGroup>
                            </Col>

                            <Col lg="6">
                                <FormGroup className="form-group">
                                    <Label for="hornLength">Default sill horn length</Label>

                                    <NumberInputDecimal
                                        name="hornLength"
                                        value={hornLength}
                                        setValue={setHornLength}
                                    />
                                </FormGroup>
                            </Col>

                            <Col lg="6">
                                <FormGroup className="form-group">
                                    <Label for="windowLength">Default window board horn length</Label>

                                    <NumberInputDecimal
                                        name="windowLength"
                                        value={windowLength}
                                        setValue={setWindowLength}
                                    />
                                </FormGroup>
                            </Col>

                            <Col lg="6">
                                <FormGroup className="form-group">
                                    <Label for="uploadFile">Upload image</Label>

                                    <DropZone
                                        acceptFileType={acceptFileType}
                                        multiple={false}
                                        uploadedFile={uploadedFile}
                                        setUploadedFile={setUploadedFile}
                                        filePreview={filePreview}
                                        setFilePreview={setFilePreview}
                                    />
                                </FormGroup>
                            </Col>

                            {/* <Col lg="12" className='mb-5'>
                                <h4 className='main_title sub_title mb-3'>Cross section</h4>
                                <div className="d-flex gap-2 align-items-center flex-nowrap">
                                    <span className="css-502">Inside</span>
                                    <svg width="200" height="140"><g strokeWidth="1" transform="scale(2,2)"><g fill="rgba(73,72,71,0.2)" stroke="rgba(73,72,71,0.6)"><path d="M 0,70 0,30 7.5,30 7.5,50 92.5,50 92.5,30 100,30 100,70 Z "></path></g><path d="M 55,0 59,0 59,50 55,50 Z M 81,0 85,0 85,50 81,50 Z" fill="rgba(0,120,212,0.2)" stroke="rgba(0,120,212,0.6)"></path><path d="M 59,40 81,40 81,50 59,50 Z" fill="rgba(73,72,71,0.4)" stroke="rgba(73,72,71,0.8)"></path></g></svg>
                                    <span className="css-502">Outside</span>
                                </div>
                            </Col> */}
                        </Row>
                    </Col>
                </Row>
            </div>
            
            {isEditable && (
                <div className='d-flex gap-2 gap-xl-3 main_buttons'>
                    <Button
                        color="primary"
                        size="md"
                        className='px-4'
                        onClick={() => handleFormSubmit()}
                        disabled={!timestamp || !name.trim()}
                    >
                        Save Changes
                    </Button>

                    <Button
                        color="primary"
                        size="md"
                        outline
                        className='px-4'
                        onClick={() => handleFormReset()}
                        disabled={!timestamp}
                    >
                        Reset
                    </Button>
                </div>
            )}
        </Form>
    )
}

export default SystemGeneral