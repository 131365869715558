import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Layout from "../layouts/layout";
import Home from "../views/app/pages/quotations/list";
// import Quotations from "../views/app/pages/quotes";
import Quotations from "../views/app/pages/quotations/quote-details";
// import QuotationsProducts from "../views/app/pages/quotations/quote/list";
import CreateQuotations from "../views/app/pages/quotations/create/create";

import Palette from "../views/app/pages/products/colours/palette/palette";

// other pages
import Pricing from "../views/app/pages/pricing";

// error and extra pages pages
import DocumentLayout from "../views/app/pages/pdf/document";
import BlankPage from "../views/app/pages/dummy/dummy";
import LogoutSuccess from "../views/app/pages/errors/logout";
import Error from "../views/app/pages/errors/error";
import Error401 from "../views/app/pages/errors/401";
import Error402 from "../views/app/pages/errors/402";
import Error403 from "../views/app/pages/errors/403";
import Error404 from "../views/app/pages/errors/404";
import Error419 from "../views/app/pages/errors/419";
import Error429 from "../views/app/pages/errors/429";
import Error500 from "../views/app/pages/errors/500";
import Error503 from "../views/app/pages/errors/503";
import ComingSoon from "../views/app/pages/errors/coming-soon";

// settings pages
import Collections from "../views/app/pages/settings/accounts/collections";
import CRM from "../views/app/pages/settings/accounts/crm";
import QuoteAncillaries from "../views/app/pages/settings/quotations/ancillaries/ancillaries";
import PageSetup from "../views/app/pages/settings/printing/page-setup/page-setup";
import Forewords from "../views/app/pages/settings/printing/forewords/forewords";
import Terms from "../views/app/pages/settings/printing/terms/terms";
import Documents from "../views/app/pages/settings/printing/documents/documents";
import FrameAncillaries from "../views/app/pages/settings/frames/anciliaries/ancillaries";
import RetailDesigners from "../views/app/pages/settings/websitedesigners/retailsdesigner/retaildesigner";
import Tradedesigner from "../views/app/pages/settings/websitedesigners/tradedesigner/tradedesigner";

// Products pages
// import Products from "../views/app/pages/products/systems/products";
import BuildingColors from "../views/app/pages/products/building/colours";
import ProductsProfiles from "../views/app/pages/products/profile/profiles";
import HardwareStyles from "../views/app/pages/products/hardware/styles/styles";
import PanelProducts from "../views/app/pages/products/panels/panel-products/panel-products";
import PanelProductsPanels from "../views/app/pages/products/panels/collections/panel-collections";
import PanelProductsStyles from "../views/app/pages/products/panels/styles/panel-styles";

import ProductCollections from "../views/app/pages/products/colours/collections/collections";
import PaletteHardware from "../views/app/pages/products/hardware/colors/palette/palette";
import CollectionsHardware from "../views/app/pages/products/hardware/colors/collections/collections";
import Specifications from "../views/app/pages/products/glazing/specifications/specifications";
import Spacebar from "../views/app/pages/products/glazing/spacebars/spacebar";
import HardWareCollections2 from "../views/app/pages/products/hardware/collections/collections";
import Accessories from "../views/app/pages/products/hardware/accessories/accessories";

import GlazingTextures from "../views/app/pages/products/glazing/textures/textures";

import FrameCollections from "../views/app/pages/products/frame-styles/collections/collections";
import FrameStyles from "../views/app/pages/products/frame-styles/styles/frame-styles";
import { Designs } from "../views/app/pages/products/glazing/design/designs";
import PanelDesigner from "../views/app/pages/designer/panel/designer";

import ProfileJoints from "../views/app/pages/products/systems/profile-joints/profile-joints";
import FrameProducts from "../views/app/pages/products/systems/frame-collections/collections";
import FrameProductsList from "../views/app/pages/products/systems/frame-products/frame-products";

import SashCollections from "../views/app/pages/products/systems/sash-collections/collections";
import SashProductsList from "../views/app/pages/products/systems/sash-products/sash-products";

// Pricing routes
import ProjectDefaults from "../views/app/pages/pricing/general/project-defaults/project-defaults";
import PricingTaxes from "../views/app/pages/pricing/general/taxes/taxes";
import ProjectAncillaries from "../views/app/pages/pricing/general/project-ancillaries/project-ancillaries";
import PricingPromotions from "../views/app/pages/pricing/general/promotions/promotions";
import PricingMarkUp from "../views/app/pages/pricing/general/global-mark-up/markup";
import FrameAncillariesPricing from "../views/app/pages/pricing/general/frame-ancillaries/frame-ancillaries";
import PricingColoursPriceList from "../views/app/pages/pricing/colors/price-list/price-list";
import PricingColoursAddOn from "../views/app/pages/pricing/colors/profile/add-on/add-on";
import PricingColorFrameProducts from "../views/app/pages/pricing/colors/frame-products/frame-products";
import GlazingDesigns from "../views/app/pages/pricing/colors/glazing-designs/glazing-designs";
import PricingPanels from "../views/app/pages/pricing/colors/panel-products/panel-products";

import PricingProfileAddon from "../views/app/pages/pricing/profile/profile";
import PricingHardwareBarHandleInline from "../views/app/pages/pricing/hardware/hardware";
// import PricingSpecifications from "../views/app/pages/pricing/glazing/specifications/specifications";

import PricingFrameStyles from "../views/app/pages/pricing/frame-styles/frame-styles";
import PricingFrameProducts from "../views/app/pages/pricing/frame-products/frame-products";
import PricingInstallFrameStyle from "../views/app/pages/pricing/installation/frame-styles/frame-styles";
import PricingInstallFrameProducts from "../views/app/pages/pricing/installation/frame-products/frame-products";
import PricingPanelProduct from "../views/app/pages/pricing/panel/panel-products/panel-products";
import Designer2 from "../views/app/pages/designer/panel2/designer2";
import GlazingPricing from "../views/app/pages/pricing/glazing/glazing";
import PricingSashProducts from "../views/app/pages/pricing/sash-products/sash-products";
import CostPriceLists from "../views/app/pages/pricing/cost-price-lists/cost-price-list";
import MarkUpPriceLists from "../views/app/pages/pricing/mark-up-price-lists/mark-up-price-list";
// import DemoFiberFrame from "../components/3DComponents/DemoFiberFrame";
// import SetupModel from "../views/app/pages/designer/setup-model/setup-model";
// import ProfileLinking from "../views/app/pages/profileLinking/profileLinking";
import AdditionalHardware from "../views/app/pages/products/hardware/additionalHardware/AdditionalHardware";
import AdditionalProfiles from "../views/app/pages/products/profile/additional-profile/additional-profile";
import AdditionalHardwarePricing from "../views/app/pages/pricing/hardware/AdditionalHardware/AdditionalHardwarePricing";
import AdditionalProfilePricing from "../views/app/pages/pricing/profile/AdditionalProfile/AdditionalProfilePricing";
import OverSizeGlazingPage from "../views/app/pages/pricing/glazing/oversize-glazing/oversize";
import CuttingList from "../views/app/pages/cuttingList/cuttingList";
import { useRightContext } from "../context/rightMenuContext";
import RoleProtectedRoutes from "./roleProtectedRoutes";
import { accessPricing, accessProduct, accessQuote, accessSettings } from "../utility/data";
import PanelDesignerBlock from "../views/app/pages/designer/blockApproach/designer";
import ColorPricing from "../views/app/pages/pricing/hardware/ColorPricing/ColorPricing";

function Router() {

    const { userDataContext } = useRightContext()

    const browserRouter = createBrowserRouter([
        {
            path: "/",
            element: <Layout />,
            children: [
                { index: true, element: <Home /> },

                // user restrictions added in page only
                { path: '/quotation/:quoteId', element: <Quotations /> },
                { path: 'user/quotation/:quoteId/print-view/:printId', element: <DocumentLayout /> },

                // quotations routes (default)
                {
                    path: "/",
                    element: (
                        <RoleProtectedRoutes allowedRoles={accessQuote} userRole={userDataContext?.role} />
                    ),
                    children: [
                        // { path: '/product-list/:quoteId', element: <QuotationsProducts /> },

                        { path: '/create-quotation', element: <CreateQuotations /> },
                        { path: 'cutting-list', element: <CuttingList /> },
                        { path: 'quotation/:quoteId/print-view/:printId', element: <DocumentLayout /> },
                    ]
                },

                // settings routes
                {
                    path: "settings/",
                    element: (
                        <RoleProtectedRoutes allowedRoles={accessSettings} userRole={userDataContext?.role} />
                    ),
                    children: [
                        { path: 'account/crm', element: <CRM /> },
                        { path: 'account/sub-account', element: <Collections /> },

                        { path: "frames/ancillary", element: <FrameAncillaries /> },
                        { path: "quotations/ancillaries", element: <QuoteAncillaries /> },

                        { path: 'printing/page-setup', element: <PageSetup /> },
                        { path: 'printing/forewords', element: <Forewords /> },
                        { path: 'printing/terms', element: <Terms /> },
                        { path: 'printing/documents', element: <Documents /> },

                        { path: "website/retail-designer", element: <RetailDesigners /> },
                        { path: "website/trade-designer", element: <Tradedesigner /> },
                    ]
                },

                {
                    path: "product/",
                    element: (
                        <RoleProtectedRoutes allowedRoles={accessProduct} userRole={userDataContext?.role} />
                    ),
                    children: [
                        // products routes
                        { path: 'colours/palette', element: <Palette /> },
                        { path: 'colours/collections', element: <ProductCollections /> },

                        { path: 'building/colours', element: <BuildingColors /> },
                        { path: 'hardware/collections', element: <HardWareCollections2 /> },

                        // products -> profile routes
                        { path: "profiles/list", element: <ProductsProfiles /> },
                        // { path: "profiles/additional-profile", element: <AdditionalProfiles /> },

                        // products -> glazing routes
                        { path: 'glazing/specifications', element: <Specifications /> },
                        { path: "glazing/spacer-bars", element: <Spacebar /> },
                        { path: "glazing/textures", element: <GlazingTextures /> },
                        { path: "glazing/designs", element: <Designs /> },

                        // products -> hardware routes
                        { path: "hardware/colours/palette", element: <PaletteHardware /> },
                        { path: "hardware/colours/collections", element: <CollectionsHardware /> },
                        { path: "hardware/list", element: <HardwareStyles /> },

                        // { path: "hardware/accessories", element: <Accessories /> },
                        // { path: "hardware/additional-hardware", element: <AdditionalHardware /> },


                        // products -> panels routes
                        { path: "panels/panel-products", element: <PanelProducts /> },
                        { path: "panels/panel-products/:panelMasterId/collections", element: <PanelProductsPanels /> },
                        { path: "panels/panel-products/:panelMasterId/collection/:collectionId", element: <PanelProductsStyles /> },

                        // 3d designer
                        { path: "frame/designer/:modelId/:styleId", element: <PanelDesigner /> },
                        { path: "panels/designer/:panelMasterId/:panelCollectionId/:panelProductId", element: <Designer2 /> },
                        // Block approach routes
                        { path: "frame/block-designer/:modelId/:styleId", element: <PanelDesignerBlock /> },

                        // products -> frame styles routes
                        { path: "systems/styles/collections", element: <FrameCollections /> },
                        { path: "systems/styles/list", element: <FrameStyles /> },

                        // products -> systems routes
                        { path: "systems/profile-joints", element: <ProfileJoints /> },

                        { path: "systems/collections", element: <FrameProducts /> },
                        { path: "systems/list", element: <FrameProductsList /> },

                        { path: "systems/sash/collections", element: <SashCollections /> },
                        { path: "systems/sash/list", element: <SashProductsList /> },
                    ]
                },

                // pricing routes

                {
                    path: 'pricing/',
                    element: (<RoleProtectedRoutes allowedRoles={accessPricing} userRole={userDataContext?.role} />),
                    children: [

                        // general 
                        { path: 'general/project-default', element: <ProjectDefaults /> },
                        { path: 'general/taxes', element: <PricingTaxes /> },
                        { path: 'general/project-ancillaries', element: <ProjectAncillaries /> },
                        { path: 'general/frame-ancillaries', element: <FrameAncillariesPricing /> },
                        { path: 'general/promotion', element: <PricingPromotions /> },
                        { path: 'general/mark-up', element: <PricingMarkUp /> },
                        { path: 'cost-price-list', element: <CostPriceLists /> },
                        { path: 'mark-up-price-list', element: <MarkUpPriceLists /> },

                        // systems
                        { path: 'systems/styles', element: <PricingFrameStyles /> },
                        { path: 'systems/list', element: <PricingFrameProducts /> },
                        { path: 'systems/sash', element: <PricingSashProducts /> },

                        // colours
                        { path: 'colours/price-list/:collectionId', element: <PricingColoursPriceList /> },
                        { path: 'colours/profile/:profileId', element: <PricingColoursAddOn /> },
                        { path: 'colours/frame-products', element: <PricingColorFrameProducts /> },
                        { path: 'colours/glazing-designs', element: <GlazingDesigns /> },
                        { path: 'colours/panel-products', element: <PricingPanels /> },

                        { path: 'profiles', element: <PricingProfileAddon /> },
                        { path: 'hardware', element: <PricingHardwareBarHandleInline /> },
                        { path: 'hardware-color', element: <ColorPricing /> },


                        // glazing
                        { path: 'glazing/:palleteId', element: <GlazingPricing /> },
                        { path: 'glazing/oversize-glazing', element: <OverSizeGlazingPage /> },

                        // panels
                        { path: 'panels/:collectionId', element: <PricingPanelProduct /> },

                        // installation
                        { path: 'installation/styles', element: <PricingInstallFrameStyle /> },
                        // { path: 'installation/frame-products/:collectionId', element: <PricingInstallFrameProducts /> },

                        // { path: '/additional-articles-pricing', element: <AdditionalArticlesPricing /> },
                        // { path: 'additional-hardware', element: <AdditionalHardwarePricing /> },
                        // { path: 'additional-profile', element: <AdditionalProfilePricing /> },
                    ]
                },

                // {
                //     path: '/',
                //     element: (<RoleProtectedRoutes allowedRoles={['Admin', 'Super Admin']} userRole={userDataContext?.role} />),
                //     children: [

                //         { path: '/profile-linking', element: <ProfileLinking /> },

                //         // Additional articles route
                //         { path: '/additional-articles', element: <AdditionalArticles /> },

                //         // extra routes
                //         { path: 'pricing', element: <Pricing /> },
                //         { path: '/demo-fiber', element: <DemoFiberFrame /> },
                //         { path: '/setup-model', element: <SetupModel /> },
                //     ]
                // },

                // error and other routes
                { path: '*', element: <Error404 /> },
                // { path: 'dummy', element: <BlankPage /> },
                { path: 'logout', element: <LogoutSuccess /> },
                { path: 'error/network', element: <Error /> },
                { path: 'unauthorized', element: <Error401 /> },
                { path: 'error/402', element: <Error402 /> },
                { path: 'error/403', element: <Error403 /> },
                { path: 'error/404', element: <Error404 /> },
                { path: 'error/419', element: <Error419 /> },
                { path: 'error/429', element: <Error429 /> },
                { path: 'error/500', element: <Error500 /> },
                { path: 'error/503', element: <Error503 /> },
                { path: 'coming-soon', element: <ComingSoon /> },
            ]
        }
    ])

    return (
        <RouterProvider router={browserRouter} />
    );
}

export default Router;
