import React, { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'

const DropZone = ({ uploadedFile, setUploadedFile, filePreview, setFilePreview, acceptFileType, multiple }) => {

    const onDrop = useCallback(acceptedFiles => {
        // Do something with the files

        // right now preview will work only for single file upload
        if (multiple) {
            setUploadedFile(acceptedFiles || [])
        } else {
            setUploadedFile(acceptedFiles[0] || '')
            setFilePreview(URL.createObjectURL(acceptedFiles[0]));
        }

        // console.log(acceptedFiles, "acceptedFiles")
    }, [])

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        accept: acceptFileType,
        multiple: true,
        onDrop
    })

    return (
        <>
            <div className='custom_dropzone' {...getRootProps()}>
                <input {...getInputProps()} />
                {
                    isDragActive ?
                        <p className='text-muted opacity-75'>Drop files here to upload</p> :
                        <p className='text-muted opacity-75'>Drag 'n' drop files here, or click to select</p>
                }
            </div>
        </>
    );
}

export default DropZone;