import axios from "axios";
import { servicePath } from "../utility/data";


export const getAllFrameStyleColletion = (accessToken) => {

    let config = {
        method: 'GET',
        maxBodyLength: Infinity,
        headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'multipart/form-data'
        }
    };

    return axios
        .get(`${servicePath}/FrameStyle/GetAllFrameStyleColletion`, config)
        .then((res) => {
            return res;
        })
        .catch((error) => {
            return error;
        });
}



// get all right menu item list based on page/type
export const getRightMenuList = (accessToken) => {

    let config = {
        method: 'GET',
        maxBodyLength: Infinity,
        headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'multipart/form-data'
        }
    };

    return axios
        .get(`${servicePath}/FrameStyle/GetAllFrameStyleColletion`, config)
        .then((res) => {
            return res;
        })
        .catch((error) => {
            return error;
        });
}

// get details of selected item in right menu
export const getItemDetails = (accessToken, id) => {
    const formData = new FormData();
    formData.append("Id", id);

    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'multipart/form-data'
        }
    };

    return axios
        .post(`${servicePath}/FrameStyle/GetFrameStyleColletion`, formData, config)
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
};

// delete selected item
export const deleteRightItem = (accessToken, id) => {
    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    const formData = new FormData()
    formData.append("Id", id)
    return axios
        .post(`${servicePath}/FrameStyle/DeleteFrameStyleColletion`, formData, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
}

// update order of selected item
export const updateOrder = (accessToken, id, direction) => {

    var requestBody = {
        "id": id,
        "sequenceNo": 0,
        "typeText": 0,
        "type": direction
    };

    const headers = {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
    }

    return axios
        .post(`${servicePath}/FrameStyle/UpdateFrameCollectionOrder`, requestBody, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
}

// update details of selected item
export const updateItemDetails = (accessToken, id, data) => {

    console.log(id, "ss")

    const headers = {
        'accept': 'text/plain',
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'multipart/form-data'
    }

    const formData = new FormData();

    formData.append("Id", id);
    formData.append('Name', data.name);
    formData.append('EditAs', data.editAs);
    formData.append('TypeId', data.typeId);

    formData.append('UsedInProducts', '');

    return axios
        .post(`${servicePath}/FrameStyle/AddUpdateFrameStyleColletion`, formData, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
};


export const getEditAsLists = (accessToken) => {

    let config = {
        method: 'GET',
        maxBodyLength: Infinity,
        headers: {
            'Authorization': `Bearer ${accessToken}`,
        }
    };

    return axios
        .get(`${servicePath}/Product/GetAllSysFrameProductAsEditList`, config)
        .then((res) => {
            return res;
        })
        .catch((error) => {
            return error;
        });
}

// add new right menu item based on page/type
export const addNewRightItem = (accessToken, data) => {
    const formData = new FormData();

    formData.append("Id", '');
    formData.append('Name', data);
    formData.append('EditAs', 0);
    formData.append('TypeId', 1);
    formData.append('SequenceNo', 0);

    const headers = {
        'accept': 'text/plain',
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'multipart/form-data'
    }

    return axios
        .post(`${servicePath}/FrameStyle/AddUpdateFrameStyleColletion`, formData, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
};


export const setUpModelService = (accessToken, data) => {
    const formData = new FormData();

    formData.append("FrameStyleId", data.frameStyleId);
    formData.append('MinValue', data.referenceMinValue);
    formData.append('MaxValue', data.referenceMaxValue);
    formData.append('ModelFile', data.uploadedFile);

    const headers = {
        'accept': 'text/plain',
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'multipart/form-data'
    }

    return axios
        .post(`${servicePath}/ThreeJSModel/UpdateLinkedModel`, formData, { headers })
        .then((res) => {
            return res?.data;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
};