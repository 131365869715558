import axios from "axios";
import { servicePath } from "../utility/data";

var version = 1

// get all right menu item list based on page/type
export const getAllProfileList = (accessToken, type, sysProductId) => {
    const formData = new FormData()

    formData.append('Type', type);
    formData.append('SysProductId', sysProductId);

    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'multipart/form-data'
        }
    };

    return axios
        .post(`${servicePath}/v${version}/ProfileV/GetAllProductProfile`, formData, config)
        .then((res) => {
            return res;
        })
        .catch((error) => {
            return error;
        });
}

// get details of selected item in right menu
export const getItemDetails = (accessToken, id) => {
    const formData = new FormData();
    formData.append("Id", id);

    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'multipart/form-data'
        }
    };

    return axios
        .post(`${servicePath}/v${version}/ProfileV/GetProductProfileDetail`, formData, config)
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
};

// delete selected item
export const deleteRightItem = (accessToken, id) => {
    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    const formData = new FormData()
    formData.append("Id", id)
    return axios
        .post(`${servicePath}/v${version}/ProfileV/DeleteProductProfile`, formData, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
}

// update order of selected item
export const updateOrder = (accessToken, id, pageType, direction) => {

    var requestBody = {
        "id": id,
        "sequenceNo": 0,
        "typeText": pageType,
        "type": direction
    };

    const headers = {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
    }

    return axios
        .post(`${servicePath}/v${version}/ProfileV/UpdateProductProfileOrder`, requestBody, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
}

// update details of selected item
export const updateItemDetails = (accessToken, id, data, type) => {

    const headers = {
        'accept': 'text/plain',
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'multipart/form-data'
    }

    const formData = new FormData();

    formData.append('Type', type);

    formData.append("Id", id);
    formData.append('Name', data.name);
    formData.append('Description', data.description);
    formData.append('Special', data.special);
    formData.append('Width', data.width);
    formData.append('Height', data.height);
    formData.append('CustomePath', data.profileModelName);
    formData.append('Feature', data.feature);
    formData.append('Minimum', data.minimum);
    formData.append('Maximum', data.maximum);
    formData.append('Shape', data.shape);
    formData.append('StepWidth', data.stepWidth);
    formData.append('StepHeight', data.stepHeight);
    formData.append('XAxis', data.xAxis);
    formData.append('YAxis', data.yAxis);
    formData.append('RadiusWidth', data.radiusWidth);
    formData.append('RadiusHeight', data.radiusHeight);
    formData.append('Angle', data.angle);
    formData.append('Side', 'Inner');
    formData.append('Radius', data.radius);

    formData.append('FilePath', data.filePath);
    formData.append('ImagePath', data.imagePath);

    formData.append('ProductCode', data.productCode);
    formData.append('ProfileType', data.profileType);
    // formData.append('PaintSurfaceArea', data.surfaceArea || 0);
    formData.append('Weight', data.weight || 0);
    formData.append('MaxWeight', data.maxWeight || 0);
    formData.append('ExternalVisibleSurface', data.externalVS);
    formData.append('InternalVisibleSurface', data.internalVS);
    formData.append('ReferenceDimensions', data.refDimension);
    formData.append('ExternalPaintSurfaceArea', data.externalPaintSurfaceArea)
    formData.append('InternalPaintSurfaceArea', data.internalPaintSurfaceArea)
    formData.append('Stock', data.isStock);

    // new fields which are needed to be added
    formData.append("BarLength", data.barLength);
    formData.append("UsableLength", data.usableLength);
    formData.append("AvailableProfileColour", data.availableProfileColour)

    return axios
        .post(`${servicePath}/v${version}/ProfileV/AddUpdateProfile`, formData, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
};

// add new right menu item based on page/type
export const addNewRightItem = (accessToken, data, type) => {
    const formData = new FormData();

    formData.append('Type', type);

    formData.append("Id", '');
    formData.append('Name', data);
    formData.append('Description', '');
    formData.append('Special', '');
    formData.append('Width', '');
    formData.append('Height', '');
    formData.append('CustomePath', '');
    formData.append('Feature', '');
    formData.append('Minimum', '');
    formData.append('Maximum', '');
    formData.append('Shape', '');
    formData.append('StepWidth', '');
    formData.append('StepHeight', '');
    formData.append('XAxis', '');
    formData.append('YAxis', '');
    formData.append('RadiusWidth', '');
    formData.append('RadiusHeight', '');
    formData.append('Angle', '');
    formData.append('Side', 'Inner');
    formData.append('Radius', '');

    formData.append('FilePath', '');
    formData.append('ImagePath', '');

    formData.append('ProductCode', '');
    formData.append('PaintSurfaceArea', 0);
    formData.append('Weight', 0);
    formData.append('ProfileType', '');
    formData.append('ExternalVisibleSurface', '');
    formData.append('InternalVisibleSurface', '');
    formData.append('ReferenceDimensions', '');
    formData.append('Stock', false);

    const headers = {
        'accept': 'text/plain',
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'multipart/form-data'
    }

    return axios
        .post(`${servicePath}/v${version}/ProfileV/AddUpdateProfile`, formData, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
};

export const getAllAdditionalArticlesMappingService = (accessToken, productId) => {
    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    const formData = new FormData()
    formData.append("ProductId", productId)
    return axios
        .post(`${servicePath}/v${version}/ProfileV/GetAllAdditionalArticlesMapping`, formData, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
}

export const getProfileModelMasters = (accessToken, masterId) => {
    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    const formData = new FormData()
    formData.append("MasterId", masterId)

    return axios
        .post(`${servicePath}/Master/GetProfileModelMasters`, formData, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
}

export const getIndividualArticleService = (accessToken, id) => {
    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    const formData = new FormData()
    formData.append("Id", id)
    return axios
        .post(`${servicePath}/v${version}/ProfileV/GetAdditionalArticlesMapping`, formData, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
}

export const addUpdateAdditionalArticlesMappingService = (accessToken, data) => {
    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    const formData = new FormData()
    formData.append("Id", data?.Id)
    formData.append("ArticleId", data?.ArticleId)
    formData.append("ArticleName", data?.ArticleName)
    formData.append("ProductId", data?.ProductId)
    formData.append("Qty", data?.Qty)
    formData.append("TypeId", data?.TypeId)

    return axios
        .post(`${servicePath}/v${version}/ProfileV/AddUpdateAdditionalArticlesMapping`, formData, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
}

export const deleteAdditionalArticlesMapping = (accessToken, id) => {
    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    const formData = new FormData()
    formData.append("Id", id)
    return axios
        .post(`${servicePath}/v${version}/ProfileV/DeleteAdditionalArticlesMapping`, formData, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
}

export const getAllSystemProductsListService = (accessToken) => {
    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }
    return axios
        .get(`${servicePath}/v${version}/ProfileV/GetAllSystemProducts`, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
}