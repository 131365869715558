import React, { useEffect, useState } from 'react'
import InnerLayout from "../../../../../../layouts/internalLayout"
import ComingSoon from '../../../errors/coming-soon'
import { useNavigate, useParams } from 'react-router-dom'
import PanelCollectionsLeft from './left'
import PanelCollectionsRight from './right'
import { useMessage } from '../../../../../../context/messageContext'
import { useRightContext } from '../../../../../../context/rightMenuContext'
import { addNewRightItem, getRightMenuList, getPanelProductCollection, updateItemDetails } from '../../../../../../services/panelCollectionsService'
import { Oval } from 'react-loader-spinner'
import { getAllPanelProductMaster } from '../../../../../../services/panelProductsService'
import { getAllPanelSidebarList, getPanelMenuListForPricing } from '../../../../../../services/SidebarServices'

const PanelCollections = () => {

    const accessToken = localStorage.getItem('access_token')

    const [wip, setWIP] = useState(false)
    const [loading, setLoading] = useState(false)

    const [panelMasterData, setPanelMasterData] = useState('')

    const [selectItemDetails, setSelectItemDetails] = useState([])
    const [allRightListItems, setAllRightListItems] = useState([])
    const [allRightListItemsOrder, setAllRightListItemsOrder] = useState(true)

    const { setMessage, setMessageType } = useMessage()

    const { timestamp, setSelectedRightItem, fetchSelected, setTimestamp, activeIndex, setActiveIndex, setPanelMenuList, setPricingPanelMenuList } = useRightContext()

    const navigate = useNavigate();

    var getFirstElId = allRightListItems[0]?.id || ''

    var itemAddedTrue = ''

    let { panelMasterId } = useParams();

    useEffect(() => {
        setLoading(true)
        setTimestamp('')
        if (panelMasterId) {
            getRightMenuListData(panelMasterId)

            getPanelProductsMaster(panelMasterId)
        }
    }, [panelMasterId])

    useEffect(() => {
        if (allRightListItems?.length > 0 && timestamp) {
            getRightItemDetails(timestamp)
        }
    }, [timestamp])

    useEffect(() => {
        if (allRightListItemsOrder) {
            getFirstElId = allRightListItems[0]?.id

            setActiveIndex(0)

            if (getFirstElId && allRightListItems?.length) {
                getRightItemDetails(getFirstElId)
            }
        } else {
            if (getFirstElId && allRightListItems?.length) {
                if (timestamp && allRightListItems?.length && activeIndex !== 0) {
                    getRightItemDetails(timestamp)
                } else {
                    getRightItemDetails(getFirstElId)
                }
            }
        }
    }, [allRightListItems])

    const getPanelProductsMaster = async (fetchId) => {
        const res = await getAllPanelProductMaster(accessToken, fetchId);
        if (res && res && res.status === 200) {
            if (res?.data?.statusCode === 200) {
                if (res?.data?.entity?.length > 0) {
                    const allMasterItems = res.data.entity

                    const getPageName = allMasterItems.filter((item) => {
                        return item.id == fetchId;
                    });

                    setPanelMasterData(getPageName[0])
                }
            }
        }
    }

    // get all right menu item list based on page/type (type = 1 for forewords)
    const getRightMenuListData = async (fetchId) => {

        getAllPanelSidebarList(accessToken, setPanelMenuList, navigate);
        getPanelMenuListForPricing(accessToken, setPricingPanelMenuList, navigate);

        const res = await getRightMenuList(accessToken, fetchId);

        if (res && res && res.status === 200) {

            if (res?.data?.statusCode === 200) {
                if (res?.data?.entity !== null) {

                    if (res?.data?.entity?.length > 0) {

                        setAllRightListItems(res?.data?.entity)

                        if (itemAddedTrue == 'added') {
                            const addedItemIndex = res.data.entity.length - 1

                            if (addedItemIndex) {
                                fetchSelected(addedItemIndex, res.data.entity[addedItemIndex]?.id)
                                itemAddedTrue = ''
                            }
                        }
                    } else {
                        setAllRightListItems([])
                        setTimestamp('')

                        fetchSelected(0, '');

                        setSelectItemDetails([])
                    }
                }

                setTimeout(() => {
                    setLoading(false)
                }, 1200);
            } else {
                setLoading(false)
                setMessage(res?.data.message ? res.data.message : 'Something went wrong');
                setMessageType('error')
            }

        } else {
            setLoading(false)
            setMessage(res?.message ? res.message : 'Something went wrong');
            setMessageType('error')

            if (res?.message === "Network Error") {
                navigate('/error/network')
            }
        }
    }

    // get details of selected item in right menu by passing item id
    const getRightItemDetails = async (id) => {
        setLoading(true)

        const res = await getPanelProductCollection(accessToken, id);

        setAllRightListItemsOrder(false)

        if (res && res && res.status === 200) {
            if (res?.data?.statusCode === 200) {
                if (res?.data?.entity !== null) {

                    const selectedRight = allRightListItems?.find((p) => p.id == id)
                    setSelectedRightItem(selectedRight)

                    // set state to get details of current selected item which will display data in left side component
                    setSelectItemDetails(res.data.entity)

                    // set selected item id which will update context
                    setTimestamp(id)
                }

                setTimeout(() => {
                    setLoading(false)
                }, 1200);
            } else {
                setLoading(false)
                setMessage(res?.data.message ? res.data.message : 'Something went wrong');
                setMessageType('error')
            }

        } else {
            setLoading(false)
            setMessage(res?.message ? res.message : 'Something went wrong');
            setMessageType('error')

            if (res?.message === "Network Error") {
                navigate('/error/network')
            }
        }
    }

    // api call to update all selected item details
    const updateItemDetailsServices = async (data) => {
        setLoading(true)

        const res = await updateItemDetails(accessToken, data)

        if (res && res && res.status === 200) {
            // console.log(res, 'selected item info')

            if (res?.data?.statusCode === 200) {
                setLoading(false)
                setMessage(res?.data?.message ? res.data.message : 'Updated details');
                setMessageType('success')

                getRightMenuListData(panelMasterId)
                setLoading(false)
            } else {
                setLoading(false)
                setMessage(res?.data?.message ? res.data.message : 'Something went wrong');
                setMessageType('error')
            }
        } else {
            setLoading(false)
            setMessage(res?.message ? res.message : 'Something went wrong');
            setMessageType('error')

            if (res?.message === "Network Error") {
                navigate('/error/network')
            }
        }
    }

    // api call to added new right item
    const addNewRightItemServices = async (title) => {
        setLoading(true)
        itemAddedTrue = "added"

        const res = await addNewRightItem(accessToken, title, panelMasterId)

        if (res && res && res.status === 200) {
            if (res?.data?.statusCode === 200) {
                setMessage(res?.data?.message ? res.data.message : 'Record created successfully!');
                setMessageType('success')

                getRightMenuListData(panelMasterId)
            } else {
                setLoading(false)
                setMessage(res?.data?.message ? res.data.message : 'Something went wrong');
                setMessageType('error')
            }
        } else {
            setLoading(false)
            setMessage(res?.message ? res.message : 'Something went wrong');
            setMessageType('error')

            if (res?.message === "Network Error") {
                navigate('/error/network')
            }
        }
    }

    const handleFormReset = () => {
        if (timestamp) {

            getRightMenuListData(panelMasterId)

            getRightItemDetails(timestamp)
        }
    }

    return (
        <>
            {wip ? (
                <ComingSoon title={`Collections Coming Soon`} />
            ) : (
                <>
                    {loading && (
                        <div className='loader_main full_loader'>
                            <Oval
                                height={50}
                                width={50}
                                color="#44C8F5"
                                wrapperStyle={{}}
                                wrapperClass=""
                                visible={true}
                                ariaLabel='oval-loading'
                                secondaryColor="#44C8F5"
                                strokeWidth={2}
                                strokeWidthSecondary={2}
                            />
                        </div>
                    )}

                    <InnerLayout pageTitle="Collections" layoutType=''
                        leftContent={<PanelCollectionsLeft panelMasterId={panelMasterId} selectItemDetails={selectItemDetails} updateItemDetailsServices={updateItemDetailsServices} handleFormReset={handleFormReset} />}
                        rightContent={<PanelCollectionsRight panelMasterData={panelMasterData} panelMasterId={panelMasterId} accessToken={accessToken} selectItemDetails={selectItemDetails} allRightListItems={allRightListItems} setLoading={setLoading} getRightMenuListData={getRightMenuListData} addNewRightItemServices={addNewRightItemServices} />} />
                </>
            )}
        </>
    )
}

export default PanelCollections