import * as THREE from 'three';
import { GLTFLoader } from 'three/addons/loaders/GLTFLoader.js';
import { calculateDiagonalLength, setInitialColors } from '../helper';
import PVCsquare from '../../assets/models/PVCsquare.glb'
import CladdingAdd from '../../assets/models/CladdingAdd.glb'
import CladdingSub from '../../assets/models/CladdingSubtract.glb'
import PVCgroove from '../../assets/models/PVCgroove.glb'
import PVCgroovesubtract from '../../assets/models/PVCgroovesubtract.glb'
import CloudTexturePng from "../../assets/img/cloud.png"

export function setGlazing(hex, glassIndex = null, shapeFrame, setClipGlaze, glazingRef, clipGlaze, clipData) {

    // console.log(hex, shapeFrame, glazingRef, "glaze test")

    // let redTransparentMaterial
    // var clipPlanes = clipData ? clipData : clipGlaze

    // console.log(clipPlanes , "18");


    // if (clipPlanes) {
    //     let quaternion2 = new THREE.Quaternion();
    //     let angleInRadians = THREE.MathUtils.degToRad(-5)
    //     quaternion2.setFromAxisAngle(new THREE.Vector3(1, 0, 1), angleInRadians);
    //     let normalVector2 = new THREE.Vector3(clipPlanes.x, clipPlanes.y, 0);
    //     normalVector2.applyQuaternion(quaternion2);
    //     var vec2 = normalVector2.normalize();

    //     let quaternion = new THREE.Quaternion();
    //     let angleInRadians2 = THREE.MathUtils.degToRad(-2.5)
    //     quaternion.setFromAxisAngle(new THREE.Vector3(1, 1, 1), angleInRadians2);
    //     let normalVector = new THREE.Vector3(clipPlanes.x, clipPlanes.y, 0);
    //     normalVector.applyQuaternion(quaternion);
    //     var vec = normalVector.normalize();

    //     let quaternion3 = new THREE.Quaternion();
    //     let angleInRadians3 = THREE.MathUtils.degToRad(-7.5)
    //     quaternion3.setFromAxisAngle(new THREE.Vector3(1, 0, 1), angleInRadians3);
    //     let normalVector3 = new THREE.Vector3(clipPlanes.x, clipPlanes.y, 0);
    //     normalVector3.applyQuaternion(quaternion3);
    //     var vec3 = normalVector3.normalize();

    //     let quaternion4 = new THREE.Quaternion();
    //     let angleInRadians4 = THREE.MathUtils.degToRad(-20)
    //     quaternion4.setFromAxisAngle(new THREE.Vector3(1, 0, 1), angleInRadians4);
    //     let normalVector4 = new THREE.Vector3(clipPlanes.x, clipPlanes.y, 0);
    //     normalVector4.applyQuaternion(quaternion4);
    //     var vec4 = normalVector4.normalize();

    //     // let plane = new THREE.Plane(normalVector2, clipPlanes.d)
    //     var vector = new THREE.Vector3(clipPlanes.x, clipPlanes.y, 0);
    //     vector.normalize();
    //     var plane = new THREE.Plane(vector, clipPlanes.distC1)
    //     var plane2;
    //     var clippedPlanes = clipGlaze
    //     if (shapeFrame === 'Radius') {
    //         var plane2 = new THREE.Plane(vec2, clipPlanes.distC1)
    //         clippedPlanes?.push(plane2);
    //         var plane3 = new THREE.Plane(vec, clipPlanes.distC1 * 0.9)
    //         clippedPlanes?.push(plane3);
    //         var plane4 = new THREE.Plane(vec3, clipPlanes.distC1)
    //         clippedPlanes?.push(plane4);
    //         var plane5 = new THREE.Plane(vec4, clipPlanes.distC1)
    //         clippedPlanes?.push(plane5);
    //     }
    //     clippedPlanes?.push(plane);
    //     setClipGlaze(prevData => [...prevData, plane])
    //     redTransparentMaterial = new THREE.MeshPhongMaterial({
    //         color: hex,
    //         transparent: true,
    //         opacity: 0.3,
    //         reflectivity: 0,
    //         clippingPlanes: clippedPlanes,
    //     });
    //     console.log(clippedPlanes)
    //     let planeHelper = new THREE.PlaneHelper(clippedPlanes, 1, 0xffff00);
    //     sceneRef.current.add(planeHelper);
    //     clippedPlanes.forEach(plane => {
    //     	let planeHelper = new THREE.PlaneHelper(plane, 1, 0xffff00); // Adjust size and color as needed
    //     	sceneRef.current.add(planeHelper);
    //       });
    // } 
    // else {
    const redTransparentMaterial = new THREE.MeshPhongMaterial({
        color: hex,
        transparent: true,
        opacity: 0.3,
        reflectivity: 0,
    });
    // }


    if (glazingRef && glazingRef.current && glazingRef.current.length > 0) {
        if (glassIndex === null) {
            glazingRef.current.forEach((glassMesh, index) => {
                if (glassMesh?.name?.includes("GlassPanel")) {
                    glassMesh.material = redTransparentMaterial;
                }
            });
        } else {
            glazingRef.current.forEach((glassMesh, index) => {
                if (glassIndex === index && glassMesh?.name?.includes("GlassPanel")) {
                    glassMesh.material = redTransparentMaterial;
                }
            });
        }
    }
}

export function setGlassTexture(texturePath, glassIndex = null, color, glazingRef, clipGlaze) {

    const textureLoader = new THREE.TextureLoader();
    if (!texturePath) {
        return
    }
    const texture = textureLoader.load(texturePath);

    texture.wrapS = texture.wrapT = THREE.MirroredRepeatWrapping;
    texture.repeat.set(30, 60);

    var clipPlane = clipGlaze

    const translucentMaterial = new THREE.MeshPhongMaterial({
        color: color,
        transparent: true,
        opacity: 0.5,
        reflectivity: 0,
        clippingPlanes: clipPlane,
        map: texture,
    });

    if (glassIndex == null) {
        glazingRef.current.forEach((glassMesh, index) => {
            glassMesh.material = translucentMaterial;
        });
    } else {
        glazingRef.current.forEach((glassMesh, index) => {
            if (glassIndex == index) {
                glassMesh.material = translucentMaterial;
            }
        });
    }
    // render();
}

export const addGlazingPanel = (glazing, index, isAlreadyAdded, glazeData, sceneRef, glazeModelRef, setPanelObject, setApplyPanel, internalFrameRef, externalFrameRef, rgbStringToHex, internalRAL, internalColor, externalRAL, externalColor, customModelData, setInternal, setExternal) => {
    if (glazing) {
        while (glazing?.children?.length > 0) {
            glazing.remove(glazing?.children[0]);
        }

        // if (sceneRef.current) {
        //     sceneRef.current.children.forEach((child) => {
        //         if (child.name.includes(`panelGlazing_ext ${index}`) || child.name.includes(`panelGlazing_int panelGlazing_int_${index}`)) {
        //             sceneRef.current.remove(child);
        //         }
        //     });
        // }

        // externalFrameRef.current = externalFrameRef.current.filter((child) => !child.name.includes(`panelGlazing_ext ${index}`));
        // internalFrameRef.current = internalFrameRef.current.filter((child) => !child.name.includes(`panelGlazing_int panelGlazing_int_${index}`));

        setInitialGlassMaterial(glazing)

        if (externalFrameRef && externalFrameRef.current && externalFrameRef.current.length > 0) {
            var newExtRef = externalFrameRef.current.filter((item) => {
                if ((item.name === `panelGlazing_ext ${index} External`) || (item.name === "GlassPanel")) {
                    return false
                } else {
                    return true
                }
            })

            externalFrameRef.current = newExtRef;
        }

        if (internalFrameRef && internalFrameRef.current && internalFrameRef.current.length > 0) {
            var newExtRef = internalFrameRef.current.filter((item) => {
                if ((item.name === `panelGlazing_int panelGlazing_int_${index} Internal`) || (item.name === "GlassPanel")) {
                    return false
                } else {
                    return true
                }
            })

            internalFrameRef.current = newExtRef;
        }

        if (sceneRef.current) {
            var panelGlazingItems
            var panelGlazingLines
            var panelGlazingHardware

            sceneRef.current.traverse(function (object) {
                if (object.name.includes(`panelGlazing_int_${index}`)) {
                    object.visible = false;
                    panelGlazingItems = object
                }
                if (object.name.includes(`linesPanel ${index} Internal`)) {
                    object.visible = false;
                    panelGlazingLines = object
                }
                if (object.name.includes(`linesPanel ${index} External`)) {
                    object.visible = false;
                    panelGlazingLines = object
                }
            });

            if (panelGlazingItems) {
                sceneRef.current.remove(panelGlazingItems);
                sceneRef.current.remove(panelGlazingLines);
                sceneRef.current.remove(panelGlazingHardware);
            }
        }

        setTimeout(() => {

            let panelPropsGlaze = glazeData?.items;
            let styleSelect = panelPropsGlaze?.features?.type;
            let methodSelect = panelPropsGlaze?.features?.method;
            let angle = panelPropsGlaze?.features?.angle;
            let shape = panelPropsGlaze?.features?.shape;
            let repeat = panelPropsGlaze?.features?.repeat;
            let isBackSide = true

            if (glazeModelRef.current && glazeModelRef.current instanceof THREE.Object3D) {
                glazing.add(glazeModelRef.current)
            }

            let glazingCloned = glazing.clone();

            glazing.name = `panelGlazing_ext ${index} External`
            glazing.position.z += 0.001

            glazingCloned.name = `panelGlazing_int panelGlazing_int_${index} Internal`

            const boundingBoxGlazing = new THREE.Box3().setFromObject(glazing);
            const glassCenter = new THREE.Vector3();
            boundingBoxGlazing.getCenter(glassCenter)

            const glazingWidth = boundingBoxGlazing.max.x - boundingBoxGlazing.min.x
            const glazingHeight = boundingBoxGlazing.max.y - boundingBoxGlazing.min.y

            const boundingBoxCloned = new THREE.Box3().setFromObject(glazingCloned);
            const glazingClonedWidth = boundingBoxCloned.max.x - boundingBoxCloned.min.x
            const glazingClonedHeight = boundingBoxCloned.max.y - boundingBoxCloned.min.y

            glazingCloned.position.z = (boundingBoxGlazing.max.z - boundingBoxGlazing.max.z) - 0.001

            glazingCloned.scale.x *= glazingWidth / glazingClonedWidth

            const newWorldPosition = new THREE.Vector3();

            glazing.getWorldPosition(newWorldPosition);

            glazingCloned.position.x = newWorldPosition.x

            // const glazingBoundingBox = new THREE.Box3().setFromObject(glazing);
            // const glazingBackBoundingBox = new THREE.Box3().setFromObject(glazingCloned);

            let clippingPlanes = []
            clippingPlanes = [
                new THREE.Plane(new THREE.Vector3(0, (glazingHeight / glazingClonedHeight), 0), - (glassCenter.y - (glazingHeight / 2))),
                new THREE.Plane(new THREE.Vector3(-1, 0, 0), (glassCenter.x + (glazingWidth / 2))),
                new THREE.Plane(new THREE.Vector3(1, 0, 0), -(glassCenter.x - (glazingWidth / 2))),
                new THREE.Plane(new THREE.Vector3(0, -(glazingHeight / glazingClonedHeight), 0), (glassCenter.y + (glazingHeight / 2)))
            ];

            // const planeHelpers = clippingPlanes.map(plane => new THREE.PlaneHelper(plane, 1, 0xffff00));

            // planeHelpers.forEach(helper => sceneRef.current.add(helper));

            const model = shape === 2 ? (methodSelect === 'Add' ? CladdingAdd : CladdingSub) : shape === 3 ? PVCsquare : (methodSelect === 'Add' ? PVCgroove : PVCgroovesubtract);

            const rotationAngle = angle > 0 ? (angle) : 0;
            const rotationAngleInRadians = rotationAngle * Math.PI / 180;

            if ((styleSelect === 'Lines' && shape !== 3) || (styleSelect === 'Lines' && shape === 3 && methodSelect === 'Add')) {

                const loader = new GLTFLoader();

                loader.load(
                    model,
                    (gltf) => {
                        let lModel = gltf.scene
                        // lModel.traverse((child) => {
                        // 	if (child.material) {
                        // 		child.material.clipping = true;
                        // 		child.material.clippingPlanes = clippingPlanes;
                        // 	}
                        // });

                        const barModelBoundingBox = new THREE.Box3().setFromObject(gltf.scene);
                        const modelHeight = (barModelBoundingBox.max.y - barModelBoundingBox.min.y)

                        // var BarLengthScale = calculateDiagonalLength(angle, (glazingWidth * 1000), (glazingHeight * 1000))
                        var BarLengthScale = calculateDiagonalLength(angle, (((glazingWidth + (Math.floor(glazingWidth) * .0152)) * 1000) + 200), (((glazingHeight + (Math.floor(glazingHeight) * .016)) * 1000) + 200))
                        var panelLength = calculateDiagonalLength(angle, (((glazingHeight + (Math.floor(glazingHeight) * .016)) * 1000) + 200), (((glazingWidth + (Math.floor(glazingWidth) * .0152)) * 1000) + 200))
                        let repeatValue = (parseFloat(modelHeight) * 1000) + (repeat > 30 ? parseFloat(repeat) : 100)

                        let repeatRatio = Math.ceil(panelLength / repeatValue)

                        var oldPlace = 0

                        for (let i = 0; i < repeatRatio; i++) {
                            const clone = lModel.clone();

                            clone.traverse((child) => {
                                if (child) {
                                    const newMaterial = new THREE.MeshStandardMaterial({
                                        color: externalRAL !== undefined ? rgbStringToHex(externalRAL?.rgbValue) : externalColor?.colour,
                                        metalness: 1,
                                        roughness: 0.5,
                                        clipping: true,
                                        clippingPlanes: clippingPlanes,
                                    });
                                    child.material = newMaterial

                                    externalFrameRef.current.push(child);
                                }
                            });

                            setInitialColors(rgbStringToHex, internalRAL, internalColor, externalRAL, externalColor, customModelData, setInternal, setExternal)

                            clone.rotation.set(0, -Math.PI / 2, 0);

                            const barModelBoundingBox = new THREE.Box3().setFromObject(clone);
                            const barDepth = barModelBoundingBox.max.z - barModelBoundingBox.min.z
                            const modelHeight = barModelBoundingBox.max.y - barModelBoundingBox.min.y

                            if (angle > 0) {
                                clone.scale.set(1, 1, ((panelLength > BarLengthScale ? panelLength : BarLengthScale) / 1000))
                            } else {
                                clone.scale.set(1, 1, shape === 2 ? 1.36 : 1.32)
                            }

                            clone.name = `linesPanel ${index} External`

                            var adjustedPosition = 0;

                            if (repeatRatio > 1) {
                                if (repeatRatio % 2 === 0) {
                                    if (i % 2 === 0) {
                                        adjustedPosition = ((((1.20 * (i + 1)) / repeatRatio) + (modelHeight / 2)) / 1)
                                        oldPlace = adjustedPosition
                                    } else {
                                        adjustedPosition = - oldPlace
                                    }
                                } else {
                                    if (i % 2 === 0) {
                                        adjustedPosition = - oldPlace
                                    } else {
                                        adjustedPosition = ((((1.20 * (i + 1)) / repeatRatio) + (modelHeight / 2)) / 1)
                                        oldPlace = adjustedPosition
                                    }
                                }
                            } else {
                                adjustedPosition = 0
                            }

                            clone.position.set(0, adjustedPosition, 0)

                            var mesh = new THREE.Mesh();

                            mesh.add(clone);

                            mesh.rotation.set(0, 0, rotationAngleInRadians)
                            // mesh.rotation.set(0, 0, 0)
                            mesh.position.set(0, 0, .1);

                            const newMesh = mesh.clone()

                            newMesh.name = `linesPanel ${index} Internal`

                            if (methodSelect === "Add") {
                                newMesh.position.z = - (0.1 - barDepth);
                            } else {
                                newMesh.position.z = - (0.1 - barDepth);
                            }

                            newMesh.traverse((child) => {
                                if (child instanceof THREE.Object3D) {
                                    const newMaterial = new THREE.MeshStandardMaterial({
                                        color: internalRAL !== undefined ? rgbStringToHex(internalRAL?.rgbValue) : internalColor?.colour,
                                        metalness: 1,
                                        roughness: 0.5,
                                        clipping: true,
                                        clippingPlanes: clippingPlanes,
                                    });
                                    child.material = newMaterial
                                    internalFrameRef.current.push(child);
                                }
                            });

                            setInitialColors(rgbStringToHex, internalRAL, internalColor, externalRAL, externalColor, customModelData, setInternal, setExternal)

                            if (newMesh.children[0]) {
                                newMesh.children[0].rotation.y = Math.PI / 2
                            }
                            glazingCloned.add(newMesh);
                            glazingCloned.add(mesh);
                        }
                    },
                    undefined,
                    (error) => {
                        console.error('An error occurred while loading the model:', error);
                    }
                );
            } else if (styleSelect === 'Lines' && shape === 3 && methodSelect === 'Subtract') {

                const modelHeight = 0.02

                var BarLengthScale = calculateDiagonalLength(angle, ((glazingWidth * 1000)), ((glazingHeight * 1000)))
                var panelLength = calculateDiagonalLength(angle, (((glazingHeight + (Math.floor(glazingHeight) * .016)) * 1000) + 200), (((glazingWidth + (Math.floor(glazingWidth) * .0152)) * 1000) + 200))
                let repeatValue = (parseFloat(modelHeight) * 1000) + (repeat > 30 ? parseFloat(repeat) : 100)

                let repeatRatio = Math.ceil(panelLength / repeatValue)

                var oldPlace = 0

                for (let i = 0; i < repeatRatio; i++) {

                    var material = new THREE.MeshStandardMaterial({
                        side: THREE.DoubleSide,
                        color: 'white',
                        clippingPlanes: clippingPlanes
                    });

                    var geometry = new THREE.BoxGeometry(0.02, 1.2, 0.01);

                    var planeMesh = new THREE.Mesh(geometry, material);

                    planeMesh.rotation.set(0, 0, (Math.PI / 2));

                    if (angle > 0) {
                        planeMesh.scale.set(1, ((BarLengthScale + 200) / 1000), 1)
                    } else {
                        planeMesh.scale.set(1, 1.32, 1)
                    }

                    var adjustedPosition = 0;

                    if (repeatRatio > 1) {
                        if (repeatRatio % 2 === 0) {
                            if (i % 2 === 0) {
                                adjustedPosition = ((((1.20 * (i + 1)) / repeatRatio) + (modelHeight / 2)) / 1)
                                oldPlace = adjustedPosition

                            } else {
                                adjustedPosition = - oldPlace

                            }
                        } else {
                            if (i % 2 === 0) {
                                adjustedPosition = - oldPlace

                            } else {
                                adjustedPosition = ((((1.20 * (i + 1)) / repeatRatio) + (modelHeight / 2)) / 1)
                                oldPlace = adjustedPosition

                            }
                        }
                    } else {
                        adjustedPosition = 0
                    }

                    planeMesh.position.set(0, adjustedPosition, 0)

                    var parentMesh = new THREE.Mesh();

                    parentMesh.rotation.set(0, 0, rotationAngleInRadians);
                    parentMesh.name = `linesPanel ${index}`

                    parentMesh.add(planeMesh);

                    parentMesh.position.set(0, 0, .15)

                    glazing.add(parentMesh);

                    const newMesh = parentMesh.clone()
                    newMesh.position.z = -0.15
                    glazingCloned.add(newMesh)
                }
            }

            const hardwareItems = Object.values(glazeData?.items || {}).filter(item => item.type === 'hardware');

            // Promise.all(hardwareItems.map(item => addHardwareElement(item)))
            // 	.then(meshes => {
            // 		meshes.forEach(mesh => {
            // 			glazing.add(mesh);
            // 		});
            // 	})
            // 	.catch(error => {
            // 		console.error('Failed to load element:', error);
            // 	});

            sceneRef.current.add(glazingCloned);

            externalFrameRef.current.push(glazing);
            internalFrameRef.current.push(glazingCloned);

            setInitialColors(rgbStringToHex, internalRAL, internalColor, externalRAL, externalColor, customModelData, setInternal, setExternal)
        }, 100);


        setPanelObject([])
    }

    setTimeout(() => {
        setApplyPanel(false)
        // getGlazingRef()
        // setCheckClickedProfile()
        // setCheckSingleSide()
    }, 1000);
}

export const setInitialGlassMaterial = (glazing) => {
    const cloudTexture = new THREE.TextureLoader().load(CloudTexturePng);
    cloudTexture.wrapS = THREE.RepeatWrapping;
    cloudTexture.wrapT = THREE.RepeatWrapping;
    cloudTexture.repeat.set(0, 0);

    const glassMaterial = new THREE.MeshPhongMaterial({
        transparent: true,
        opacity: 0.3,
        reflectivity: 0,
        map: cloudTexture,
    });

    glazing.material = glassMaterial;
    glazing.name = "GlassPanel"
    glazing.position.z = 0
}

export const glazingPanelDelete = (glazing, index, sceneRef, panelDataSave, setPanelDataSave, externalFrameRef, internalFrameRef, setDeletePanel) => {
    
    if (glazing) {
        var filterPanelJson = panelDataSave.filter((item) => {
            if (item.glassIndex == index) {
                return false
            } else {
                return true
            }
        })

        setPanelDataSave(filterPanelJson)

        setInitialGlassMaterial(glazing)

        if (externalFrameRef && externalFrameRef.current && externalFrameRef.current.length > 0) {
            var newExtRef = externalFrameRef.current.filter((item) => {
                if ((item.name === `panelGlazing_ext ${index} External`) || (item.name === "GlassPanel")) {
                    return false
                } else {
                    return true
                }
            })

            externalFrameRef.current = newExtRef;
        }

        if (internalFrameRef && internalFrameRef.current && internalFrameRef.current.length > 0) {
            var newExtRef = internalFrameRef.current.filter((item) => {
                if ((item.name === `panelGlazing_int panelGlazing_int_${index} Internal`) || (item.name === "GlassPanel")) {
                    return false
                } else {
                    return true
                }
            })

            internalFrameRef.current = newExtRef;
        }


        if (sceneRef.current) {
            var panelGlazingItems
            var panelGlazingLines
            var panelGlazingHardware

            sceneRef.current.traverse(function (object) {
                if (object.name.includes(`panelGlazing_int_${index}`)) {
                    object.visible = false;
                    panelGlazingItems = object
                }
                if (object.name.includes(`linesPanel ${index} Internal`)) {
                    object.visible = false;
                    panelGlazingLines = object
                }
                if (object.name.includes(`linesPanel ${index} External`)) {
                    object.visible = false;
                    panelGlazingLines = object
                }
                if (object.name.includes(`hardware`)) {
                    object.visible = false;
                    panelGlazingHardware = object
                }
            });

            if (panelGlazingItems) {
                sceneRef.current.remove(panelGlazingItems);
                sceneRef.current.remove(panelGlazingLines);
                sceneRef.current.remove(panelGlazingHardware);
            }
        }

        // sceneRef.current.remove(glazing.material)
    }
    setTimeout(() => {
        setDeletePanel(false)
    }, 1000);
}

export const removeAllPanels = (sceneRef, externalFrameRef, setPanelDataSave, setCustomModelData) => {
    // console.log("remove all")
    // if(newSashCount != customModelData?.numberOfSash?.number) {
    // }

    // removing all panels from sceen
    if (sceneRef.current) {
        var panelGlazingItems

        sceneRef.current.traverse(function (child) {
            // console.log(child, "child panel")
            if (child.name.includes("panelGlazing_ext")) {
                child.name = "GlassPanel"
                child.position.z = 0
            }

            if (child.name.includes("panelGlazing_int")) {
                child.visible = false;
                panelGlazingItems = child
            }
        });

        if (panelGlazingItems) {
            sceneRef.current.remove(panelGlazingItems);
        }
    }

    // Start: removing all panels from color array
    if (externalFrameRef && externalFrameRef.current && externalFrameRef.current.length > 0) {
        var newExtRef = externalFrameRef.current.filter((item) => {
            if (item.name.includes("panelGlazing_ext") || (item.name === "GlassPanel")) {
                return false
            } else {
                return true
            }
        })

        externalFrameRef.current = newExtRef;
    }

    setPanelDataSave([])

    setCustomModelData((prevModelData) => ({
        ...prevModelData,
        glazing: {
            ...prevModelData.glazing,
            panel: [],
        },
    }));
    // End: removing all panels from color array
}