import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import {
	Row,
	Col,
	Button,
	FormGroup,
	Label,
	Input,
} from "reactstrap";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { MenuItem, OutlinedInput, Select } from "material-ui-core";
import { MenuProps, modelTypeOptions } from "../../../../../../utility/data";
import { useRightContext } from "../../../../../../context/rightMenuContext";

const FrameCollectionsLeft = ({ selectItemDetails, updateItemDetailsServices, handleFormReset, editAsList }) => {

	const { timestamp, selectedRightItem, selected, setSelected, handleEdit } = useRightContext()

	const [name, setName] = useState('')
	const [itemId, setItemId] = useState('')
	const [editAs, setEditAs] = useState("0")
	const [typeId, setTypeId] = useState("1")
	const [nameError, setNameError] = useState(false)

	useEffect(() => {
		setSelected(selectItemDetails?.name || '')
		setName(selectItemDetails?.name || '')
		setItemId(selectItemDetails?.id || '')
		// setEditAs(selectItemDetails?.editAs || "1")

		setNameError(false)
	}, [selectItemDetails])

	useEffect(() => {
		if (selectItemDetails && (editAsList && editAsList.length > 0)) {
			setEditAs(selectItemDetails?.editAs || editAsList[0].id)
		}
	}, [selectItemDetails, editAsList])

	useEffect(() => {
		if (selectItemDetails && (modelTypeOptions && modelTypeOptions.length > 0)) {
			setTypeId(selectItemDetails?.typeId || modelTypeOptions[0].id)
		}
	}, [selectItemDetails, modelTypeOptions])

	const handleNameChange = (e) => {
		const value = e.target.value

		if (e.target.value?.length <= 50) {
			setName(value)
			handleEdit(value)
			setSelected(value)
		}

		if (value.length > 50) {
			setNameError(true)
		} else {
			setNameError(false)
		}
	}

	const handleEditAsChange = (e) => {
		const value = e.target.value
		setEditAs(value)
	}

	const handleTypeChange = (e) => {
		const value = e.target.value
		setTypeId(value)
	}

	const handleFormSubmit = () => {

		const formDataValues = {
			'id': selectItemDetails?.id,
			"name": name,
			'editAs': editAs,
			'typeId': typeId,
		}

		updateItemDetailsServices(formDataValues)
	}

	return (
		<div className="d-flex flex-column h-100">
			<div className="d-flex align-items-center mb-3 flex-wrap gap-2 left_header">
				{selected && (
					<h5 className="mb-0 text-wrap text-break main_title">{selected}</h5>
				)}
			</div>

			<Form onSubmit={(e) => { e.preventDefault()}} className={`h-100 d-flex flex-column flex-grow-1 justify-content-between ${selectedRightItem?.isSelf ? "" : "not_allowed"}`}>
				{/* has_tab has_btns has_head -> add respective class of included compoenent in div of class inside_scroll for proper scroll */}

				<div className="inside_scroll has_btns has_head">
					<Row className="mb-5">
						<Col lg="6">
							<FormGroup className="form-group">
								<Label for="name">
									Name
								</Label>
								<Input disabled={!selectedRightItem?.isSelf} className='mb-1' name='name' value={name} onChange={(e) => handleNameChange(e)} />

								{nameError && timestamp && itemId && (
									<div className='mt-1 text-muted text-small'>Max 50 characters are allowed</div>
								)}

								{(!name && timestamp && itemId) && (
									<div className='mt-1 text-danger text-small'>Name is required</div>
								)}
							</FormGroup>
						</Col>

						<Col lg="6">
							<FormGroup className="form-group">
								<Label for="editAsSelect">
									Edit as
								</Label>

								<Select
									disabled={!selectedRightItem?.isSelf}
									labelId="editAsSelect"
									id="editAsSelect"
									value={editAs}
									label="editAsSelect"
									onChange={(e) => { handleEditAsChange(e) }}
									input={<OutlinedInput className='w-100' name='editAsSelect' />}

									MenuProps={MenuProps}

									IconComponent={() => (
										<MdOutlineKeyboardArrowDown size={20} className='me-1 MuiSvgIcon-root MuiSelect-icon' />
									)}
								>
									{editAsList?.map((o, index) => (
										<MenuItem key={index} value={o?.id}>
											{o?.name}
										</MenuItem>
									))}
								</Select>
							</FormGroup>
						</Col>

						<Col lg="6">
							<FormGroup className="form-group">
								<Label for="typeIdSelect">
									Type
								</Label>

								<Select
									disabled={!selectedRightItem?.isSelf}
									labelId="typeIdSelect"
									id="typeIdSelect"
									value={typeId}
									label="typeIdSelect"
									onChange={(e) => { handleTypeChange(e) }}
									input={<OutlinedInput className='w-100' name='typeIdSelect' />}

									MenuProps={MenuProps}

									IconComponent={() => (
										<MdOutlineKeyboardArrowDown size={20} className='me-1 MuiSvgIcon-root MuiSelect-icon' />
									)}
								>
									{modelTypeOptions?.map((o, index) => (
										<MenuItem key={index} value={o?.id}>
											{o?.name}
										</MenuItem>
									))}
								</Select>
							</FormGroup>
						</Col>
					</Row>
				</div>

				{/* Start: space for buttons at the bottom */}
				{selectedRightItem?.isSelf && (
					<div className="d-flex gap-2 gap-xl-3 main_buttons">
						<Button color="primary" size="md" className="px-4"
							onClick={() => handleFormSubmit()}
							disabled={!timestamp || !name.trim()}
						>
							Save Changes
						</Button>

						<Button color="primary" size="md" outline className="px-4" disabled={!timestamp} onClick={() => handleFormReset()} >
							Reset
						</Button>
					</div>
				)}
				{/* End: space for buttons at the bottom */}
			</Form>
		</div>
	);
};

export default FrameCollectionsLeft;
