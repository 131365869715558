import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import {
	Row,
	Col,
	Button,
	FormGroup,
	Label,
	Input,
	Alert,
} from "reactstrap";
import NumberInput from "../../../../../../components/NumberInput";
import { Link } from "react-router-dom";
import { MdInfoOutline, MdOutlineKeyboardArrowDown, MdOutlineSell } from "react-icons/md";
import { MenuItem, OutlinedInput, Select } from "material-ui-core";
import { MenuProps } from "../../../../../../utility/data";
import { useRightContext } from "../../../../../../context/rightMenuContext";
import UsedInProducts from "../../../../../../components/UsedInProducts";
import NumberInputDecimal from "../../../../../../components/NumberInputDecimal";
import DropZone from "../../../../../../components/DropZone";

const PanelProductsLeft = ({ pageType, selectItemDetails, updateItemDetailsServices, handleFormReset, hardwareCollectionOptions, productCollectionOptions, usedInProductsList, setUsedInProductsList , deletePanelProdInUsedService,removePanelProdInUsedService}) => {

	var acceptFileType = {
		'image/png': ['.jpg', '.jpeg', '.png', '.svg', '.gif'],
	}
	const { timestamp, selectedRightItem, selected, setSelected, handleEdit, handleDescEdit, setDescUpdate, handleFileUpdate  } = useRightContext()

	const [name, setName] = useState('')
	const [itemId, setItemId] = useState('')
	const [description, setDescription] = useState('')
	// const [usedInProductsList, setUsedInProductsList] = useState([])
	const [colours, setColours] = useState("1")
	const [thickness, setThickness] = useState("0")
	const [maxWidth, setMaxWidth] = useState("40")
	const [maxHeight, setMaxHeight] = useState("40")
	const [maximumPower, setMaximumPower] = useState("0")
	const [collectionSelect, setCollectionSelect] = useState('1')
	const [uploadedFile, setUploadedFile] = useState('')
	const [filePreview, setFilePreview] = useState('')
	const [nameError, setNameError] = useState(false)
	const [descriptionError, setDescriptionError] = useState(false)

	console.log(usedInProductsList, "44");
	console.log(timestamp, "45");

	useEffect(() => {

		// console.log(productCollectionOptions, "productCollectionOptions")

		setSelected(selectItemDetails?.name || '')
		setName(selectItemDetails?.name || '')
		setItemId(selectItemDetails?.id || '')
		setDescription(selectItemDetails?.description || '')
		setUsedInProductsList(selectItemDetails?.usedInProductsList || [])

		setColours(selectItemDetails?.colours || productCollectionOptions[0]?.id)
		
		// here hardwareCollectionOptions[0] was there but in order to get none in first index this is changed 
		setCollectionSelect(selectItemDetails?.hardwareCollectionId || hardwareCollectionOptions[1]?.id)
		setUploadedFile('')
		setFilePreview(selectItemDetails?.imagePath || '')
		setThickness(selectItemDetails?.thickness || 28)
		setMaxWidth(selectItemDetails?.maximumWidth || 1400)
		setMaxHeight(selectItemDetails?.maximumHeight || 2500)
		setMaximumPower(selectItemDetails?.maximumMSquar || 1.6)

		setNameError(false)
        setDescriptionError(false)
	}, [selectItemDetails])


	useEffect(() => {
		if (filePreview) {
			handleFileUpdate(filePreview)
		}
	}, [filePreview])

	const handleNameChange = (e) => {
		const value = e.target.value

		if (e.target.value?.length <= 50) {
			setName(value)
			handleEdit(value)
			setSelected(value)
		}

		if(value.length > 50) {
			setNameError(true)
		} else {
			setNameError(false)
		}
	}

	const handleDescriptionChange = (e) => {
		const value = e.target.value

		if (e.target.value?.length <= 80) {
			setDescription(value)
			handleDescEdit(value)
			setDescUpdate(value)
		}

		if(value.length > 80) {
			setDescriptionError(true)
		} else {
			setDescriptionError(false)
		}
	}

	const handleCollectionChange = (e) => {
		const value = e.target.value
		setCollectionSelect(value)
	}

	const handleColoursChange = (e) => {
		const value = e.target.value
		setColours(value)
	}

	const handleFormSubmit = () => {

		const formDataValues = {
			'id': selectItemDetails?.id,
			"type": pageType,

			"name": name,
			"description": description,
			'colours': colours,
			"thickness": thickness,
			"maxWidth": maxWidth,
			"maxHeight": maxHeight,
			'maximumPower': maximumPower,
			"collectionSelect": collectionSelect,
			"usedInProductsList": usedInProductsList,
			"filePath": uploadedFile || '',
			"imagePath": '',
		}

		updateItemDetailsServices(formDataValues)
	}

	console.log(usedInProductsList, "139");

	return (
		<div className="d-flex flex-column h-100">
			<div className="d-flex align-items-center mb-3 flex-wrap gap-2 left_header">
				{selected && (
					<h5 className="mb-0 text-wrap text-break me-4 main_title">{selected}</h5>
				)}

				{/* <div>
					<MdOutlineSell className="text-primary me-2" size={22} />
					<span className="text-primary">
						<Link to={{}}>Edit prices</Link>
					</span>
				</div> */}
			</div>

			<Form className={`h-100 d-flex flex-column flex-grow-1 justify-content-between ${selectedRightItem?.isSelf ? "" : "not_allowed"}`}>
				{/* has_tab has_btns has_head -> add respective class of included compoenent in div of class inside_scroll for proper scroll */}

				<div className="inside_scroll has_btns has_head">

					<h4 className="main_title sub_title">General</h4>

					<Row className="border-bottom mb-4">
						<Col lg="6">
							<FormGroup className="form-group">
								<Label for="name">
									Name
								</Label>
								<Input className='mb-1' name='name' value={name} onChange={(e) => handleNameChange(e)} />

								{nameError && timestamp && itemId && (
									<div className='mt-1 text-muted text-small'>Max 50 characters are allowed</div>
								)}

								{(!name && timestamp && itemId) && (
									<div className='mt-1 text-danger text-small'>Name is required</div>
								)}
							</FormGroup>
						</Col>

						<Col lg="6">
							<FormGroup className="form-group">
								<Label for="description">
									Description
								</Label>
								<Input className='mb-1' name='description' value={description} onChange={(e) => handleDescriptionChange(e)} />

								{(descriptionError && timestamp && itemId) && (
									<div className='mt-1 text-muted text-small'>Max 80 characters are allowed</div>
								)}
							</FormGroup>
						</Col>

						<Col lg="6">
							<FormGroup className="form-group">
								<Label for="coloursSelect">
									Colours
								</Label>

								<Select
									labelId="coloursSelect"
									id="coloursSelect"
									value={colours}
									label="coloursSelect"
									onChange={(e) => { handleColoursChange(e) }}
									input={<OutlinedInput className='w-100' name='coloursSelect' />}

									MenuProps={MenuProps}

									IconComponent={() => (
										<MdOutlineKeyboardArrowDown size={20} className='me-1 MuiSvgIcon-root MuiSelect-icon' />
									)}
								>
									{productCollectionOptions && productCollectionOptions.length > 0 && productCollectionOptions.map((o, index) => (
										<MenuItem key={index} value={o?.id}>
											{o?.name}
										</MenuItem>
									))}
								</Select>
							</FormGroup>
						</Col>

						<Col lg="6">
							<FormGroup className="form-group">
								<Label for="thickness">Thickness</Label>

								<NumberInput
									name="thickness"
									value={thickness}
									setValue={setThickness}
								/>
							</FormGroup>
						</Col>

						<Col lg="6">
							<FormGroup className="form-group">
								<Label for="maxWidth">Maximum width</Label>

								<NumberInput
									name="maxWidth"
									value={maxWidth}
									setValue={setMaxWidth}
								/>
							</FormGroup>
						</Col>

						<Col lg="6">
							<FormGroup className="form-group">
								<Label for="maxHeight">Maximum height</Label>

								<NumberInput
									name="maxHeight"
									value={maxHeight}
									setValue={setMaxHeight}
								/>
							</FormGroup>
						</Col>
						
						<Col lg="6">
							<FormGroup className="form-group">
								<Label for="maximumPower">Maximum m<sup>2</sup> </Label>

								<NumberInputDecimal
									name="maximumPower"
									value={maximumPower}
									setValue={setMaximumPower}
								/>
							</FormGroup>
						</Col>

						<Col lg="6">
							<FormGroup className="form-group">
								<Label for="uploadFile">Upload image</Label>

								<DropZone
									acceptFileType={acceptFileType}
									multiple={false}
									uploadedFile={uploadedFile}
									setUploadedFile={setUploadedFile}
									filePreview={filePreview}
									setFilePreview={setFilePreview}
								/>
							</FormGroup>
						</Col>

						<Col sm="12">
							<h4 className="main_title sub_title">Used in products</h4>

							<UsedInProducts data={usedInProductsList} handleRemove={removePanelProdInUsedService}/>
						</Col>
					</Row>

					<Row className="mb-5">
						<h4 className="main_title sub_title">Hardware</h4>

						<Col lg="6">
							<FormGroup className="form-group">
								<Label for="collectionSelect">
									Collection
								</Label>

								<Select
									labelId="collectionSelect"
									id="collectionSelect"
									value={collectionSelect}
									label="collectionSelect"
									onChange={(e) => { handleCollectionChange(e) }}
									input={<OutlinedInput className='w-100' name='collectionSelect' />}

									MenuProps={MenuProps}

									IconComponent={() => (
										<MdOutlineKeyboardArrowDown size={20} className='me-1 MuiSvgIcon-root MuiSelect-icon' />
									)}
								>
									{hardwareCollectionOptions && hardwareCollectionOptions.length > 0 && hardwareCollectionOptions.map((o, index) => (
										<MenuItem key={index} value={o?.id}>
											{o?.name}
										</MenuItem>
									))}
								</Select>
							</FormGroup>
						</Col>
					</Row>
				</div>

				{/* Start: space for buttons at the bottom */}
				{selectedRightItem?.isSelf && (
					<div className="d-flex gap-2 gap-xl-3 main_buttons">
						<Button color="primary" size="md" className="px-4"
							onClick={() => handleFormSubmit()}
							disabled={!timestamp || !name.trim()}
						>
							Save Changes
						</Button>

						<Button color="primary" size="md" outline className="px-4" disabled={!timestamp} onClick={() => handleFormReset()} >
							Reset
						</Button>
					</div>
				)}
				{/* End: space for buttons at the bottom */}
			</Form>
		</div>
	);
};

export default PanelProductsLeft;
