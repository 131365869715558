import React, { useEffect, useState } from "react";
import { BiPlus, BiTrashAlt } from "react-icons/bi";
import { MdFolderOpen } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import { useRightContext } from "../../../../../../context/rightMenuContext";

const Right = ({ allRightListItems, setPageName }) => {
  const navigate = useNavigate();

  const {
    timestamp,
    fetchSelected,
    newItemList,
    setNewItemList,
    handleActiveIndexUp,
    handleActiveIndexDown,
    activeIndex,
  } = useRightContext();

  useEffect(() => {
    const assignItemValue = allRightListItems?.map((item) => {
      return {
        name: item.name,
        id: item.id,
      };
    });

    setNewItemList(assignItemValue);
  }, [allRightListItems, setNewItemList]);

  useEffect(() => {
    if (timestamp) {
      const data = allRightListItems?.find((item) => item?.id === timestamp);
      setPageName(data?.name);
    }
  }, [timestamp]);

  const handleItemClick = (currentIndex, id) => {
    fetchSelected(currentIndex, id);
  };

  // api to delete right side items
  //   const deleteRightItemServices = async () => {
  //     setIsDeleting(true);
  //     setLoading(true);

  //     const res = await deleteRightItem(accessToken, selectItemDetails?.id);

  //     if (res && res && res.status === 200) {
  //       setShowDeleteModal(false);
  //       fetchSelected(0, allRightListItems[0]?.id);

  //       if (res?.data?.statusCode === 200) {
  //         setMessage(
  //           res?.data?.message
  //             ? res.data.message
  //             : "Record removed successfully !"
  //         );
  //         setMessageType("success");

  //         getRightMenuListData(collectionId);
  //         setIsDeleting(false);
  //       } else {
  //         setIsDeleting(false);
  //         setLoading(false);
  //         setMessage(
  //           res?.data?.message ? res.data.message : "Something went wrong"
  //         );
  //         setMessageType("error");
  //       }
  //     } else {
  //       setIsDeleting(false);
  //       setLoading(false);
  //       setMessage(res?.message ? res.message : "Something went wrong");
  //       setMessageType("error");

  //       setShowDeleteModal(false);
  //       getRightMenuListData(collectionId);

  //       if (res?.message === "Network Error") {
  //         navigate("/error/network");
  //       }
  //     }
  //   };

  // api to update right side item
  //   const updateOrderServices = async (direction) => {
  //     setLoading(true);

  //     const res = await updateColoursPriceOrder(
  //       accessToken,
  //       timestamp,
  //       direction,
  //       collectionId
  //     );

  //     if (res && res && res.status === 200) {
  //       if (res?.data?.statusCode === 200) {
  //         getRightMenuListData(collectionId);
  //       } else {
  //         setLoading(false);
  //         setMessage(
  //           res?.data?.message ? res.data.message : "Something went wrong"
  //         );
  //         setMessageType("error");
  //       }
  //     } else {
  //       setLoading(false);
  //       setMessage(res?.message ? res.message : "Something went wrong");
  //       setMessageType("error");

  //       if (res?.message === "Network Error") {
  //         navigate("/error/network");
  //       }
  //     }
  //   };

  const handleItemUp = () => {
    handleActiveIndexUp();
    // updateOrderServices("UP");
  };

  const handleItemDown = () => {
    handleActiveIndexDown();
    // updateOrderServices("DOWN");
  };

  return (
    <>
      <div className="h-100 d-flex flex-column flex-grow-1 justify-content-between">
        <div className="inside_scroll has_btns">
          <div className="right_list">
            {newItemList?.map((item, index) => (
              // add has_delete and has_copy class in below div based on availability of icons in action_btns div
              <div
                key={index}
                className="position-relative d-flex align-items-center right_item_wrap has_delete"
              >
                <Link
                  to="#"
                  className={`right_item w-100 ${
                    activeIndex === index && "active"
                  }`}
                  onClick={() => handleItemClick(index, item.id)}
                >
                  <span className="me-2">
                    <MdFolderOpen size={22} />
                  </span>

                  <div className="right_title">
                    <span className="text-truncate">{item.name}</span>
                    <span className="text-truncate d-block text-small">
                      {item.description}
                    </span>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>

        {/* Start: space for buttons at the bottom */}
        {/* <div className="d-flex gap-2 gap-xl-3 main_buttons">
          <Button
            color="primary"
            size="md"
            className="px-4"
            onClick={() =>
              addNewRightItemServices(
                `Discounts ${
                  newItemList && newItemList.length ? newItemList.length + 1 : 1
                }`
              )
            }
          >
            <BiPlus size={20} className="me-1" />
            New
          </Button>

          <Button
            color=""
            size="md"
            className="border-0 text-primary px-0"
            onClick={() => handleItemUp()}
            // disabled
            disabled={timestamp === "" || activeIndex === 0}
          >
            <MdArrowUpward size={22} className="me-1" />
            Up
          </Button>
          <Button
            color=""
            size="md"
            className="border-0 text-primary px-0"
            onClick={() => handleItemDown()}
            // disabled
            disabled={
              timestamp === "" || allRightListItems?.length - 1 === activeIndex
            }
          >
            <MdArrowDownward size={22} className="me-1" />
            Down
          </Button>
        </div> */}
        {/* End: space for buttons at the bottom */}
      </div>
    </>
  );
};

export default Right;
