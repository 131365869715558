import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import {
	Row,
	Col,
	Button,
	FormGroup,
	Label,
	Input,
	Nav,
	NavItem,
	NavLink,
	TabContent,
	TabPane,
} from "reactstrap";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { MenuItem, OutlinedInput, Select } from "material-ui-core";
import { MenuProps } from "../../../../../../utility/data";
import { useRightContext } from "../../../../../../context/rightMenuContext";
import DropZone from "../../../../../../components/DropZone";
import SizeTagsComp from "../../../../../../components/SizeTagsComp";

const FrameCollectionsLeft = ({ selectItemDetails, updateItemDetailsServices, handleFormReset, productListOptions, setLoading }) => {

	const { timestamp, selectedRightItem, selected, setSelected, handleEdit, handleFileUpdate } = useRightContext()

	var acceptFileType = {
		'image/png': ['.jpg', '.jpeg', '.png', '.svg', '.gif'],
	}

	const [name, setName] = useState('')
	const [itemId, setItemId] = useState('')

	const [systemCode, setSystemCode] = useState('')

	const [productId, setProductId] = useState("")
	const [hideFormProject, setHideFormProject] = useState(false)

	const [nameError, setNameError] = useState(false)

	const [uploadedFile, setUploadedFile] = useState('')
	const [filePreview, setFilePreview] = useState('')
	const [activeTab, setActiveTab] = useState("General")

	useEffect(() => {
		// console.log(selectItemDetails, "selectItemDetails")
		setUploadedFile('')
		setFilePreview(selectItemDetails?.imagePath || '')
		if (selectItemDetails) {
			setSelected(selectItemDetails?.name || '')
			setName(selectItemDetails?.name || '')

			setItemId(selectItemDetails?.id || '')

			if (selectItemDetails?.systemCode == 0) {
				setSystemCode('')
			} else {
				setSystemCode(selectItemDetails?.systemCode || '')
			}

			setProductId(selectItemDetails?.productId || productListOptions[0]?.id)
			setHideFormProject(selectItemDetails?.hideFromProjectList || false)
		}

		setNameError(false)
	}, [selectItemDetails])


	console.log(selectItemDetails , "69");
	

	useEffect(() => {
		if (filePreview) {
			handleFileUpdate(filePreview)
		}
	}, [filePreview])

	const handleNameChange = (e) => {
		const value = e.target.value

		if (e.target.value?.length <= 50) {
			setName(value)
			handleEdit(value)
			setSelected(value)
		}

		if (value.length > 50) {
			setNameError(true)
		} else {
			setNameError(false)
		}
	}

	const handelProductsListChange = (e) => {
		const value = e.target.value
		setProductId(value)
	}

	const handleFormSubmit = () => {

		const formDataValues = {
			'id': selectItemDetails?.id,

			"name": name,
			"systemCode": systemCode,
			'productId': productId,
			"hide": hideFormProject,
			"filePath": uploadedFile || '',
			"imagePath": '',
		}

		updateItemDetailsServices(formDataValues)
	}

	return (
		<div className="d-flex flex-column h-100">
			<div className="d-flex align-items-center mb-3 flex-wrap gap-2 left_header">
				{selected && (
					<h5 className="mb-0 text-wrap text-break me-4 main_title">{selected}</h5>
				)}
			</div>

			{/* <Nav tabs className="details_header border-0 nav-tabs-custom">
				<NavItem>
					<NavLink
						className={`${activeTab === "General" && "active"}`}
						onClick={() => {
							setActiveTab("General");
						}}
					>
						General
					</NavLink>
				</NavItem>
				<NavItem>
					<NavLink
						className={`${activeTab === "Size tags" && "active"}`}
						onClick={() => {
							setActiveTab("Size tags");
						}}
					>
						Size tags
					</NavLink>
				</NavItem>
			</Nav> */}

			<TabContent activeTab={activeTab} className="flex-grow-1 d-flex">

				<TabPane tabId="General" className="flex-grow-1 w-100">
					<Form className={`h-100 d-flex flex-column flex-grow-1 justify-content-between ${selectedRightItem?.isSelf ? "" : "not_allowed"}`}>
						<div className="inside_scroll has_btns has_head">

							<Row>
								<Col lg="6">
									<FormGroup className="form-group">
										<Label for="name">
											Name
										</Label>
										<Input className='mb-1' name='name' value={name} onChange={(e) => handleNameChange(e)} />

										{nameError && timestamp && itemId && (
											<div className='mt-1 text-muted text-small'>Max 50 characters are allowed</div>
										)}

										{(!name && timestamp && itemId) && (
											<div className='mt-1 text-danger text-small'>Name is required</div>
										)}
									</FormGroup>
								</Col>

								<Col lg="6">
									<FormGroup className="form-group">
										<Label for="systemCode">
											System code
										</Label>

										<Input className='mb-1' name='systemCode' value={systemCode} onChange={(e) => setSystemCode(e.target.value)} />

										{(!systemCode && timestamp && itemId) && (
											<div className='mt-1 text-danger text-small'>System code is required</div>
										)}
									</FormGroup>
								</Col>

								<Col lg="6">
									<FormGroup className="form-group">
										<Label for="productsList">
											Product to use for image
										</Label>

										<Select
											labelId="productsList"
											id="productsList"
											value={productId || ''}
											label="productsList"
											onChange={(e) => { handelProductsListChange(e) }}
											input={<OutlinedInput className='w-100' name='productsList' />}

											MenuProps={MenuProps}

											IconComponent={() => (
												<MdOutlineKeyboardArrowDown size={20} className='me-1 MuiSvgIcon-root MuiSelect-icon' />
											)}
										>
											{productListOptions && productListOptions.map((o, index) => (
												<MenuItem key={index} value={o?.id}>
													{o?.name}
												</MenuItem>
											))}
										</Select>
									</FormGroup>
								</Col>

								<Col lg="6">
									<FormGroup className="form-group">
										<Label for="hideFormProject">
											Hide from project list
										</Label>
										<Form.Check // prettier-ignore
											type="switch"
											id="hideFormProject"
											className='form-switch-lg'
											onChange={() => { setHideFormProject(!hideFormProject) }}
											checked={hideFormProject}
											label={hideFormProject ? 'On' : 'Off'}
										/>
									</FormGroup>
								</Col>

								<Col lg="6">
									<FormGroup className="form-group">
										<Label for="uploadFile">Upload image</Label>

										<DropZone
											acceptFileType={acceptFileType}
											multiple={false}
											uploadedFile={uploadedFile}
											setUploadedFile={setUploadedFile}
											filePreview={filePreview}
											setFilePreview={setFilePreview}
										/>
									</FormGroup>
								</Col>
							</Row>
						</div>

						{/* Start: space for buttons at the bottom */}
						{selectedRightItem?.isSelf && (
							<div className="d-flex gap-2 gap-xl-3 main_buttons">
								<Button color="primary" size="md" className="px-4"
									onClick={() => handleFormSubmit()}
									disabled={!timestamp || !name.trim() || !systemCode}
								>
									Save Changes
								</Button>

								<Button color="primary" size="md"
									outline
									className="px-4"
									disabled={!timestamp}
									onClick={() => handleFormReset()} >
									Reset
								</Button>
							</div>
						)}
						{/* End: space for buttons at the bottom */}
					</Form>
				</TabPane>

				{/* <TabPane tabId="Size tags" className="flex-grow-1 w-100">
					<SizeTagsComp
						setLoading={setLoading}
						frameStyleCollectionId={selectItemDetails?.id}
						frameStyleId={0}
						activeTab={activeTab}
						selectedRightItem={selectedRightItem}
					></SizeTagsComp>
				</TabPane> */}

			</TabContent>
		</div>
	);
};

export default FrameCollectionsLeft;
