import React from 'react'
import { MdOutlineCreate } from 'react-icons/md';
import { Link, useNavigate } from "react-router-dom";
const CustomizedButton = ({ data, hasState }) => {

  const navigate = useNavigate();

  const handleNavigate = () => {
    if (hasState) {
      navigate(data.url,
        { state: { data: data.stateValue } }
      )
    } else {
      console.log(hasState, data, "hasState2")
      navigate(data.url)
    }
  }
  return (
    <div className="ms-sm-3 clickable" onClick={handleNavigate}>
      <MdOutlineCreate className="text-primary me-1 mb-1" size={18} />
      <span className="text-primary d-none d-sm-inline-block">
        Customize
      </span>
    </div>
  )
}

export default CustomizedButton