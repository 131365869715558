import React, { useState } from 'react'
import InternalLayout from '../../../../../../layouts/internalLayout'
import ProjectDefaultsLeft from './left'
import { Oval } from 'react-loader-spinner'


const ProjectDefaults = () => {

    var access_token = localStorage.getItem("access_token")

    const [loading, setLoading] = useState(false)


    return (
        <>
            {loading && (
                <div className='loader_main full_loader'>
                    <Oval
                        height={50}
                        width={50}
                        color="#44C8F5"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                        ariaLabel='oval-loading'
                        secondaryColor="#44C8F5"
                        strokeWidth={2}
                        strokeWidthSecondary={2}
                    />
                </div>
            )}

            <InternalLayout pageTitle="General" layoutType="full" 
                leftContent={<ProjectDefaultsLeft token={access_token} loading={loading} setLoading={setLoading} />} 
                rightContent={''} />
        </>
    )
}

export default ProjectDefaults