import React, { useEffect, useState } from "react";
import { Button, Label, FormGroup } from "reactstrap";
import { MenuItem, OutlinedInput, Select } from "material-ui-core";
import { Form } from "react-bootstrap";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import {
  MenuProps,
  horizontalAlignmentPanelOptions,
  panelOriginData,
  verticalAlignmentPanelOptions,
} from "../../utility/data";
import { BiTrash } from "react-icons/bi";
import { usePanelDesigner } from "../../context/designer2Context";
import NumberInputNegative from "../NumberInputNegative";
import { deleteKey, getPosition } from "../../utility/helper";
import { setPanelModelProperty } from "../../services/panelModelServices";

const Hardware = ({ hardwareTypeName, setHardwareType, hardwareType, access_token, setIsHardwareSelected, setOpen, setMessage, loading, setLoading, panelProductId, panelCollectionId, handleCancel }) => {
  const {
    handleVerticalAlignment,
    handleHorizontalAlignment,
    handleHorizontalOrigin,
    handleVerticalOrigin,
    setModelData,
    setIsDeletedHardware,
    modelData,
  } = usePanelDesigner();

  const [verticalPosition, setVerticalPosition] = useState(0);
  const [horizontalPosition, setHorizontalPosition] = useState(0);
  const [horizontalAlign, setHorizontalAlign] = useState("");
  const [verticalAlign, setVerticalAlign] = useState("");
  const [horizontalOri, setHorizontalOri] = useState("");
  const [verticalOri, setVerticalOri] = useState("");
  const [lastChange, setLastChange] = useState("");
  const [isDataSaved, setIsDataSaved] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false)
  const [saveInitData, setSaveInitData] = useState(false)

  useEffect(() => {
    // let pos = "";
    // if (hardwareType && !modelData?.hasOwnProperty(hardwareType?.name)) {
    //   pos = getPosition(hardwareType?.name?.toLocaleLowerCase());
    // } else {
    //   pos = modelData[`${hardwareType?.name}`];
    // }

    if (hardwareTypeName, hardwareType && hardwareType?.name && !modelData?.hasOwnProperty(hardwareType?.name)) {
      let pos = getPosition(hardwareType?.name?.toLocaleLowerCase());
      setVerticalPosition(pos?.verticalPos ? pos?.verticalPos : 0);
      setHorizontalPosition(pos?.horizontalPos ? pos?.horizontalPos : 0);
      setHorizontalAlign(pos?.horizontalAlign ? pos?.horizontalAlign : 0);
      setVerticalAlign(pos?.verticalAlign ? pos?.verticalAlign : 0);
      setHorizontalOri(pos?.horizontalOrigin ? pos?.horizontalOrigin : 0);
      setVerticalOri(pos?.verticalOrigin ? pos?.verticalOrigin : 0);

      setSaveInitData(true)
    } else {
      if(hardwareType && hardwareType.name) {
        let pos = modelData[`${hardwareType?.name}`];
        
        if(pos && pos.id) {
          setVerticalPosition(pos?.verticalPos ? pos?.verticalPos : 0);
          setHorizontalPosition(pos?.horizontalPos ? pos?.horizontalPos : 0);
          setHorizontalAlign(pos?.horizontalAlign ? pos?.horizontalAlign : 0);
          setVerticalAlign(pos?.verticalAlign ? pos?.verticalAlign : 0);
          setHorizontalOri(pos?.horizontalOrigin ? pos?.horizontalOrigin : 0);
          setVerticalOri(pos?.verticalOrigin ? pos?.verticalOrigin : 0);
        }
      }
    }
  }, [hardwareType]);

  useEffect(() => {
    if(saveInitData) {
      saveData()
    }
  }, [saveInitData])

  useEffect(() => {
    if (isDataSaved) {
      savePanelDataService(modelData)
      setIsDataSaved(false)
      setIsDeletedHardware(false)
      setIsDeleted(false)
    }
  }, [isDataSaved])

  const deleteHardware = () => {
    // if (currentModel.current.children.length > 5) {
    //   for (let i = currentModel.current.children.length; i >= 5; i--) {
    //     const secondElement = currentModel.current.children[i];
    //     currentModel.current.remove(secondElement);
    //   }
    // }
    setModelData(deleteKey(modelData, hardwareType?.name));
    setIsDeleted(true)
    setIsDeletedHardware(true)
    setIsDataSaved(true);
  };

  const handleVerticalPosition = (value) => {
    setVerticalPosition(value);
    setLastChange("position");
  };

  const handleHorizontalPosition = (value) => {
    setHorizontalPosition(value);
    setLastChange("position");
  };

  const handleHorizontalAlign = (value) => {
    setHorizontalAlign(value);
    handleHorizontalAlignment(value);
    handleHorizontalOrigin("");
    handleVerticalOrigin("");
    setLastChange("");
  };

  const handleVerticalAlign = (value) => {
    setVerticalAlign(value);
    handleVerticalAlignment(value);
    handleVerticalOrigin("");
    handleHorizontalOrigin("");
    setLastChange("");
  };

  const handleHorizontalOri = (value) => {
    setHorizontalOri(value);
    handleHorizontalOrigin(value);
    handleHorizontalAlignment("");
    handleVerticalAlignment("");
    setLastChange("");
  };

  const handleVerticalOri = (value) => {
    setVerticalOri(value);
    handleVerticalOrigin(value);
    handleVerticalAlignment("");
    handleHorizontalAlignment("");
    setLastChange("");
  };

  const saveData = () => {
    // if (lastChange === "position") {
    //   handleHorizontalPos(horizontalPosition);
    //   handleVerticalPos(verticalPosition);
    //   handleHorizontalAlignment("");
    //   handleVerticalAlignment("");
    //   handleHorizontalOrigin("");
    //   handleVerticalOrigin("");
    // }

    setModelData((prevData) => ({
      ...prevData,
      [`${hardwareType?.name}`]: {
        name: hardwareType?.name,
        modelFilePath: hardwareType?.modelFilePath,
        id: hardwareType?.id,
        horizontalAlign: horizontalAlign,
        verticalAlign: verticalAlign,
        horizontalOrigin: horizontalOri,
        verticalOrigin: verticalOri,
        horizontalPos: horizontalPosition,
        verticalPos: verticalPosition,
        price: hardwareType?.price,
        hardwareType: hardwareTypeName,
        type: 'hardware',
        // isAlreadyAdded: true,
      },
    }));
    setIsDataSaved(true)
  };

  const savePanelDataService = async (jsonData) => {
    setLoading(true)
    const res = await (setPanelModelProperty(access_token, jsonData, panelCollectionId, panelProductId))
    if (res?.status === 200) {
      if (res?.data?.statusCode === 200) {
        setLoading(false)
        setMessage(res?.data?.message);
        if (isDeleted) {
          setIsHardwareSelected(false)
          setHardwareType('')
        }
      }
    } else {
      setLoading(false)
      setMessage(res?.statusText)
    }
  }

  const handleSave = () => {
    setTimeout(() => {
      setIsHardwareSelected(false)
    }, 400);
  }

  return (
    <>
      <Form className="h-100 d-flex flex-column flex-grow-1 justify-content-between">
        <h4 className="mb-3">{hardwareType?.name}</h4>
        <div className="inside_scroll scroll_fix_designer mb-3">
          <FormGroup className="form-group">
            <Label for="image" className="text-dark">
              Horizontal Origin
            </Label>
            <Select
              labelId="printdoc-label"
              id="image"
              value={horizontalOri}
              label="image"
              onChange={(e) => handleHorizontalOri(e.target.value)}
              onMouseDown={(e) => e.stopPropagation()}
              input={<OutlinedInput className="w-100" name="colours" />}
              MenuProps={MenuProps}
              IconComponent={() => (
                <MdOutlineKeyboardArrowDown
                  size={20}
                  className="me-1 MuiSvgIcon-root MuiSelect-icon"
                />
              )}
            >
              {panelOriginData.map((item, index) => (
                <MenuItem value={item.name} key={index}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormGroup>
          <FormGroup className="form-group">
            <Label for="verticalOrigin" className="text-dark">
              Vertical Origin
            </Label>
            <Select
              labelId="printdoc-label"
              id="verticalOrigin"
              value={verticalOri}
              label="verticalOrigin"
              onChange={(e) => handleVerticalOri(e.target.value)}
              onMouseDown={(e) => e.stopPropagation()}
              input={<OutlinedInput className="w-100" name="colours" />}
              MenuProps={MenuProps}
              IconComponent={() => (
                <MdOutlineKeyboardArrowDown
                  size={20}
                  className="me-1 MuiSvgIcon-root MuiSelect-icon"
                />
              )}
            >
              {panelOriginData.map((item, index) => (
                <MenuItem value={item.name} key={index}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormGroup>
          <FormGroup className="form-group">
            <Label for="horizontalAllign" className="text-dark">
              Horizontal alignment
            </Label>
            <Select
              labelId="printdoc-label"
              id="horizontalAllign"
              value={horizontalAlign}
              label="horizontalAllign"
              onChange={(e) => handleHorizontalAlign(e.target.value)}
              onMouseDown={(e) => e.stopPropagation()}
              input={<OutlinedInput className="w-100" name="colours" />}
              MenuProps={MenuProps}
              IconComponent={() => (
                <MdOutlineKeyboardArrowDown
                  size={20}
                  className="me-1 MuiSvgIcon-root MuiSelect-icon"
                />
              )}
            >
              {horizontalAlignmentPanelOptions.map((item, index) => (
                <MenuItem key={index} value={item.name}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormGroup>
          <FormGroup className="form-group">
            <Label for="verticalALign" className="text-dark">
              Vertical alignment
            </Label>
            <Select
              labelId="printdoc-label"
              id="verticalALign"
              value={verticalAlign}
              label="verticalALign"
              onChange={(e) => handleVerticalAlign(e.target.value)}
              onMouseDown={(e) => e.stopPropagation()}
              input={<OutlinedInput className="w-100" name="colours" />}
              MenuProps={MenuProps}
              IconComponent={() => (
                <MdOutlineKeyboardArrowDown
                  size={20}
                  className="me-1 MuiSvgIcon-root MuiSelect-icon"
                />
              )}
            >
              {verticalAlignmentPanelOptions.map((item, index) => (
                <MenuItem key={index} value={item.name}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormGroup>
          <FormGroup className="form-group">
            <Label for="horizontalPosition" className="text-dark">
              Horizontal position
            </Label>
            <NumberInputNegative
              id="horizontalPosition"
              name="horizontalPosition"
              value={horizontalPosition}
              setValue={handleHorizontalPosition}
            />
          </FormGroup>
          <FormGroup className="form-group">
            <Label for="verticalPosition" className="text-dark">
              Vertical position
            </Label>
            <NumberInputNegative
              id="verticalPosition"
              name="verticalPosition"
              value={verticalPosition}
              setValue={handleVerticalPosition}
            />
          </FormGroup>
          <Button
            color="light"
            size="md"
            className="px-4 w-100 d-flex justify-content-between fw-medium mb-4"
            onClick={deleteHardware}
          >
            <BiTrash size={20} />
            Delete
            <span></span>
          </Button>
        </div>
        <div className="d-flex gap-2 w-100 pb-3">
          <Button
            className="w-50"
            color="outline-primary"
            size="md"
            onClick={() => { saveData(); handleSave()}}
            disabled={false}
          >
            Save
          </Button>
          <Button
            className="w-50"
            color="primary"
            outline
            size="md"
            onClick={handleCancel}
          >
            Cancel
          </Button>
        </div>
      </Form>
    </>
  );
};

export default Hardware;
