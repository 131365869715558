import { useState } from "react";
import { useContext } from "react";
import { createContext } from "react";
import { modelData } from "../utility/data";
import blockPricingData from "../../src/assets/base_models/pricingData.json"

const DesignerContext = createContext();

const useDesigner = () => {
    const value = useContext(DesignerContext);
    return value;
}

const CustomDesignerContext = ({ children }) => {

    const [uiLinesLocation, setUiLinesLocation] = useState('')
    const [colorActiveTab, setColorActiveTab] = useState('external')
    const [internalColor, setInternalColor] = useState()
    const [externalColor, setExternalColor] = useState()
    const [activeIndex, setActiveIndex] = useState()
    const [overrideWidth, setOverrideWidth] = useState(2500);
    const [overrideHeight, setOverrideHeight] = useState(2100);
    const [visibleExternalColors, setVisibleExternalColors] = useState([])
    const [visibleInternalColors, setVisibleInternalColors] = useState([])
    const [internalRAL, setInternalRAL] = useState()
    const [externalRAL, setExternalRAL] = useState()
    const [frameObject, setFrameObject] = useState()
    const [frameObjectType, setFrameObjectType] = useState('')
    const [hornLength, setHornLength] = useState(0)
    const [glazingSpec, setGlazingSpec] = useState()
    const [texture, setTexture] = useState()
    const [modelHeight, setModelHeight] = useState()
    const [modelWidth, setModelWidth] = useState()
    const [shapedWidth, setShapedWidth] = useState('')
    const [shapedHeight, setShapedHeight] = useState('')
    const [saveHeightWidth, setSaveHeightWidth] = useState(false)
    const [depth, setDepth] = useState()
    const [customModelData, setCustomModelData] = useState(modelData)
    const [rows, setRows] = useState(4)
    const [cols, setCols] = useState(12)
    const [design, setDesign] = useState()
    const [designHeight, setdesignHeight] = useState()
    const [shapeFrame, setShapeFrame] = useState("")
    const [applyAngle, setApplyAngle] = useState(false)
    const [designWidth, setdesignWidth] = useState()
    const [saveGlazingDesign, setSaveGlazingDesign] = useState(false)
    const [clearGlazingDesign, setClearGlazingDesign] = useState(false)
    const [glazingSpecSelect, setGlazingSpecSelect] = useState(0)
    const [hardwareElPos, setHardwareElPos] = useState(0)
    const [numHardware, setNumHardware] = useState(0)
    const [hardwareColor, setHardwareColor] = useState('')
    const [handleSides, sethandleSides] = useState(3)
    const [horizontalPos, setHorizontalPos] = useState(0);
    const [verticalPos, setVerticalPos] = useState(0)
    const [isHardwareSave, setIsHardwareSave] = useState(false)
    const [frameObjectSaving, setFrameObjectSaving] = useState(false)
    const [deleteFrameObject, setDeleteFrameObject] = useState(false)
    const [deleteHardware, setDeleteHardware] = useState(false)
    const [checkSingleSide, setCheckSingleSide] = useState()
    const [frameProfiles, setFrameProfiles] = useState('')
    const [checkClickedProfile, setCheckClickedProfile] = useState()
    const [sashRadioActive, setSashRadioActive] = useState('')
    const [sashProfileType, setSashProfileType] = useState('')
    const [sashHangingNo, setSashHangingNo] = useState(0)
    const [sashHanging, setSashHanging] = useState({ sashIndex: 0, sashAlignmentIndex: 0 })
    const [saveFrameCustomization, setSaveFrameCustomization] = useState(false)
    const [transomsOrientation, setTransomsOrientation] = useState('')
    const [deletePressed, setDeletePressed] = useState(false)
    const [toggleHelper, setToggleHelper] = useState(false)
    const [deleteItem, setDeleteItem] = useState('')
    const [isSettingPos, setIsSettingPos] = useState(false)
    const [productsData, setProductsData] = useState([])
    const [couplerData, setCouplerData] = useState([])
    // added by saify
    const [internalColorActiveIndex, setInternalActiveIndex] = useState()
    const [textureObject, setTextureObject] = useState();
    const [specObject, setSpecObject] = useState();
    const [hardwareObject, setHardwareObject] = useState()
    const [windowHandleOrientation, setWindowHandleOrientation] = useState('Top')
    const [changeHandleDirection, setChangeHandleDirection] = useState('right')
    const [deleteRefPoints, setDeleteRefPoints] = useState([])
    const [deleteSashHanging, setDeleteSashHanging] = useState(false);
    const [cancelChanges, setCancelChanges] = useState(false)
    const [multiSelectRefPoints, setMultiSelectRefPoints] = useState([])
    const [lockRefPoints, setLockRefPoints] = useState([])
    const [lockRefIndex, setLockRefIndex] = useState([])
    const [newModelId, setNewModelId] = useState("");
    const [newStyleId, setNewStyleId] = useState("")
    const [initialProfile, setInitialProfile] = useState()
    const [modelTypeId, setModelTypeId] = useState(1)
    const [applyPanel, setApplyPanel] = useState(false)
    const [deletePanel, setDeletePanel] = useState(false)
    const [panelObject, setPanelObject] = useState([]);
    const [defaultSpec, setDefaultSpec] = useState([])
    const [transomType, setTransomType] = useState()
    const [threshHolData, setThreshHolData] = useState([])
    const [thresholdType, setThresholdType] = useState()
    const [bayPostList, setBayPostList] = useState([])
    const [appliedModel, setAppliedModel] = useState({
        count: sashHangingNo.toString(),
        collectionId: newModelId,
        styleId: newStyleId,
        typeId: modelTypeId,
    })
    const [frameAngle, setFrameAngle] = useState(0)
    const [appliedPanelData, setAppliedPanelData] = useState()
    const [panelDataSave, setPanelDataSave] = useState([])
    const [transomDistance, setTransomDistance] = useState(0)
    const [beadList, setBeadList] = useState([])
    const [spacerBar, setSpacerBar] = useState()
    const [sandblastPricing, setSanblastPricing] = useState('')
    const [shapedFrameApplied, setShapedFrameApplied] = useState(false)

    const [hardwareReferencePoint, setHardwareReferencePoint] = useState(null)
    const [applyPartialTrigger, setApplyPartialTrigger] = useState(false)
    const [applyPartialObj, setApplyPartialObj] = useState('')
    const [newPartialWidth, setNewPartialWidth] = useState([])
    const [disableUse, setDisableUse] = useState(false)
    const [modelJson, setModelJson] = useState({})
    const [validWidths, setValidWidths] = useState({
        min: null, max: null
    })
    const [hangingPosition, setHangingPosition] = useState('')
    const [validHeights, setValidHeights] = useState({
        min: null, max: null
    })
    const [blockModel, setBlockModel] = useState(blockPricingData)

    const [pricingData, setPricingData] = useState(
        {
            frameProfile: [],
            glazing: [],
            sill: [],
            sash: [],
            texture: [],
            design: [],
            sandblasted: [],
            handles: [],
            panel: [],
            knocker: [],
            escutcheon: [],
            spyhole: [],
            numeral: [],
            color: [],
            letterPlate: [],
            transoms: [],
            floatingMullion: [],
            // Here threhhold is nulll as it will be applied only one time
            threshold: null,
            hinge: [],
            coupler: [],
            bayPost: [],
            barHandleOffset: [],
            bead: [],
            trickleVent: [],
            cylinder: [],
            slideAndTurnHardware: [],
            installationPrices: [],
            addedFrames: [],
            totalPrice: 0.0,
        }
    );

    const [totalQuotationFramePrice, setQuotationFramePrice] = useState(0);
    const [profileJointDetails, setProfileJointDetails] = useState('')
    const [addedFrameData, setAddedFrameData] = useState(null)
    const [addedFrameList, setAddedFrameList] = useState([])
    const [wide, setWide] = useState("");
    const [high, setHigh] = useState("");

    const [modelImages, setModelImages] = useState({
        externalImage: '',
        internalImage: '',
        seqImage: '',
    })

    const handleInternalColor = (color, index) => {
        setInternalColor(color)
        // setActiveIndex(index)
        // added by saify
        setInternalActiveIndex(index)

        setInternalRAL()
    }
    const handleExternalColor = (color, index) => {
        setExternalColor(color)
        setActiveIndex(index)

        setExternalRAL()
    }

    const handleExternalRAL = (color, index) => {
        setExternalRAL(color)
        setActiveIndex(index)
    }

    const handleInternalRAL = (color, index) => {
        setInternalRAL(color)
        // setActiveIndex(index)
        // Added by saify
        setInternalActiveIndex(index)
    }

    const handleFrameObject = (sill, index) => {
        setFrameObject(sill)
        setActiveIndex(index)

        // let obj = {
        //     id: sill?.id,
        //     name: sill?.name,
        //     price: sill?.price,
        //     orientation: "",
        //     frameType: "Sill",
        // };

        // setPricingData((prevData) => ({
        //     ...prevData,
        //     sill: [obj]
        // }))
    }

    const handleGlazingSpec = (spec, index) => {
        setGlazingSpec(spec)
        // setActiveIndex(index)
        setGlazingSpecSelect(index)
        // Added for dynamic getting dynamic pricing
    }

    const handleTexture = (texture, index) => {
        setTexture(texture)
        setActiveIndex(index)
    }

    const handleGlazingDesign = (des, index) => {
        setDesign(des)
        setActiveIndex(index)
    }

    const delete3DItems = (del, name) => {
        setDeleteItem(name)
        setDeletePressed(del)
        // setTransomsOrientation('')
        // setSashHanging()
    }


    return (
        // provider for context
        <DesignerContext.Provider value={{
            internalColor,
            externalColor,
            setActiveIndex,
            activeIndex,
            handleInternalColor,
            handleExternalColor,
            handleInternalRAL,
            handleExternalRAL,
            externalRAL,
            internalRAL,
            handleFrameObject,
            frameObject,
            setFrameObjectType,
            frameObjectType,
            setHornLength,
            hornLength,
            handleGlazingSpec,
            glazingSpec,
            handleTexture,
            texture,
            setModelWidth,
            modelWidth,
            setModelHeight,
            modelHeight,
            setSaveHeightWidth,
            setShapedHeight,
            shapedHeight,
            shapedWidth,
            setShapedWidth,
            saveHeightWidth,
            depth,
            setDepth,
            setCustomModelData,
            customModelData,
            handleGlazingDesign,
            design,
            setDesign,
            rows,
            setRows,
            cols,
            setCols,
            designHeight,
            designWidth,
            setdesignHeight,
            setdesignWidth,
            setSaveGlazingDesign,
            saveGlazingDesign,
            setClearGlazingDesign,
            clearGlazingDesign,
            setGlazingSpecSelect,
            glazingSpecSelect,
            numHardware,
            setNumHardware,
            hardwareElPos,
            setHardwareElPos,
            hardwareColor,
            setHardwareColor,
            verticalPos,
            setVerticalPos,
            horizontalPos,
            setHorizontalPos,
            handleSides,
            sethandleSides,
            isHardwareSave,
            setIsHardwareSave,
            frameObjectSaving,
            setFrameObjectSaving,
            setFrameObject,
            deleteFrameObject,
            setDeleteFrameObject,
            deleteHardware,
            setDeleteHardware,
            checkSingleSide,
            setCheckSingleSide,
            frameProfiles,
            setFrameProfiles,
            checkClickedProfile,
            setCheckClickedProfile,
            sashProfileType,
            setSashProfileType,
            saveFrameCustomization,
            setSaveFrameCustomization,
            sashHangingNo,
            setSashHangingNo,
            sashHanging,
            setSashHanging,
            setTransomsOrientation,
            transomsOrientation,
            deleteItem,
            deletePressed,
            delete3DItems,
            internalColorActiveIndex,
            setInternalActiveIndex,
            setInternalColor,
            setInternalRAL,
            setExternalColor,
            setExternalRAL,
            deleteRefPoints,
            setDeleteRefPoints,
            isSettingPos,
            setIsSettingPos,
            textureObject,
            setTextureObject,
            specObject,
            setSpecObject,
            panelObject,
            setPanelObject,
            hardwareObject,
            setHardwareObject,
            setToggleHelper,
            toggleHelper,
            colorActiveTab,
            setColorActiveTab,
            setSashRadioActive,
            sashRadioActive,
            setWindowHandleOrientation,
            windowHandleOrientation,
            setChangeHandleDirection,
            changeHandleDirection,
            setDeleteSashHanging,
            deleteSashHanging,
            cancelChanges,
            setCancelChanges,
            newModelId,
            setNewModelId,
            newStyleId,
            setNewStyleId,
            multiSelectRefPoints,
            setMultiSelectRefPoints,
            lockRefPoints,
            setLockRefPoints,
            lockRefIndex,
            setLockRefIndex,
            setAppliedModel,
            appliedModel,
            modelTypeId,
            setModelTypeId,
            applyPanel,
            setApplyPanel,
            deletePanel,
            setDeletePanel,
            appliedPanelData,
            setAppliedPanelData,
            panelDataSave,
            setPanelDataSave,
            pricingData,
            setPricingData,
            initialProfile,
            setInitialProfile,
            defaultSpec,
            setDefaultSpec,
            transomDistance,
            setTransomDistance,
            visibleExternalColors,
            setVisibleExternalColors,
            visibleInternalColors,
            setVisibleInternalColors,
            productsData,
            setProductsData,
            transomType,
            setTransomType,
            threshHolData,
            setThreshHolData,
            thresholdType,
            setThresholdType,
            couplerData,
            setCouplerData,
            setDeleteItem,
            setBeadList,
            beadList,
            bayPostList,
            setBayPostList,
            spacerBar,
            setSpacerBar,
            shapeFrame,
            setShapeFrame,
            sandblastPricing,
            setSanblastPricing,
            hardwareReferencePoint,
            setHardwareReferencePoint,
            totalQuotationFramePrice,
            setQuotationFramePrice,
            profileJointDetails,
            setProfileJointDetails,
            setDeletePressed,
            overrideWidth,
            setOverrideWidth,
            overrideHeight,
            setOverrideHeight,
            frameAngle,
            setFrameAngle,
            applyAngle,
            setApplyAngle,
            uiLinesLocation,
            setUiLinesLocation,
            addedFrameData,
            setAddedFrameData,
            addedFrameList,
            setAddedFrameList,
            setModelImages,
            modelImages,
            applyPartialTrigger,
            setApplyPartialTrigger,
            setApplyPartialObj,
            newPartialWidth,
            setNewPartialWidth,
            applyPartialObj,
            disableUse,
            setDisableUse,
            validWidths,
            setValidWidths,
            validHeights,
            setValidHeights,
            blockModel,
            setBlockModel,
            shapedFrameApplied,
            setShapedFrameApplied,
            modelJson,
            setModelJson,
            hangingPosition,
            setHangingPosition,
            high,
            setHigh,
            wide,
            setWide
        }}>
            {children}
        </DesignerContext.Provider>
    )
}

export { useDesigner }
export default CustomDesignerContext;

