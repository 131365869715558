// createWindowFrame.js
import * as THREE from 'three';
import { createFramePath } from './lineShapes.js'; // Import the createFramePath function
import { createCornerPath } from './cornerShapes.js'; // Import the createCornerPath function
import { createCombinedOutlinePath } from './combinedOutline.js';
import { createSash, hangingRefs, removeHangingRefs } from './sashes.js';
import { getAngleFromLine, updateFrameJoint } from './profileJoints.js';
import { cloneDeep } from 'lodash';



// Function to create and extrude a frame shape along the various paths

const shapeCache = new Map();
let zOffsetMap = {}
let frameProfileRefs = []
let jointRefs = []

const storeFrameProfileRef = (obj) => {
    frameProfileRefs.push(obj)
}

const storeJointsRef = (obj, position , name) => {  
    obj.position.set(position.x , position.y , position.z)
    obj.name = name
    jointRefs.push(obj)
}

export const getJointsRef = () => {
    return jointRefs;
}

export const getFrameRefs = () => {
    return frameProfileRefs;
}

export const loadDefaultFrame = async (defaultFrameProfile,profileJoint) => {
    let height = defaultFrameProfile?.height / 1000 || 0.1;
    let width = defaultFrameProfile?.width / 1000 || 0.1;
    const frameJoint = profileJoint?.frameName

    return prevModelJson => {
        const updatedModelJson = { ...prevModelJson };
        updatedModelJson.joint = frameJoint
        Object.keys(updatedModelJson.frame.sides).forEach(side => {
            updatedModelJson.frame.sides[side].dimensions.width = width;
            updatedModelJson.frame.sides[side].dimensions.height = height;
        });
        return updatedModelJson;
    };
};

export const updateFrameProfile = async (frameProfile, orientation) => {
    let height = frameProfile?.height / 1000 || 0.1;
    let width = frameProfile?.width / 1000 || 0.1;
    const finalOrientation = orientation == 'FrameTop' ? "top" : orientation == 'FrameRight' ? "right" : orientation == 'FrameBottom' ? "bottom" : "left";
    return prevModelJson => {
        const updatedModelJson = { ...prevModelJson };
        Object.keys(updatedModelJson.frame.sides).forEach(side => {
            if (finalOrientation === side) {
                updatedModelJson.frame.sides[side].dimensions.width = width;
                updatedModelJson.frame.sides[side].dimensions.height = height;
            }
        });
        return updatedModelJson;
    };
};

const profileHeightWidth = (height, width) => {

    const frameShape = new THREE.Shape();
    const radius = 0.002;
    frameShape.moveTo(-width / 2 + radius, 0);

    // Top edge (left to right)
    frameShape.lineTo(width / 2 - radius, 0);
    frameShape.absarc(width / 2 - radius, -radius, radius, Math.PI / 2, 0, true);

    // Right edge (top to bottom)
    frameShape.lineTo(width / 2, -height + radius);
    frameShape.absarc(width / 2 - radius, -height + radius, radius, 0, -Math.PI / 2, true);

    // Bottom edge (right to left)
    frameShape.lineTo(-width / 2 + radius, -height);
    frameShape.absarc(-width / 2 + radius, -height + radius, radius, -Math.PI / 2, -Math.PI, true);

    frameShape.lineTo(-width / 2, -radius);
    frameShape.absarc(-width / 2 + radius, -radius, radius, Math.PI, Math.PI / 2, true);

    return frameShape;

}


const getShapeCache = (height, width) => {
    const key = `${height} - ${width}`
    if (!shapeCache.has(key)) {
        const shape = profileHeightWidth(height, width)
        shapeCache.set(key, shape)
    }
    return shapeCache.get(key)
}

export async function createWindowFrame(scene, windowDataFull, groupName, sashHangingValue, sashGroup, profileOrientation, profileType , frameProfileDefault, frameStyleBottom) {
       
    const windowData = windowDataFull;
    frameProfileRefs = [];
    removeHangingRefs()
    jointRefs = [];
    await removeGroupByName(scene, groupName);
    const group = new THREE.Group();
    group.name = groupName;
    const { frame, sash, color ,joint } = windowData // Extract frame data

//need to remove below 3 lines ******* this one is static
    // frame.corners.bottomLeft.type = "Chamfer";
    frame.corners.bottomLeft.type = "Radius";
    // frame.corners.bottomLeft.size.width = 1.5;
    // frame.corners.bottomLeft.size.height = 1.5;
    frame.corners.bottomLeft.size.width = 1;
    frame.corners.bottomLeft.size.height = 0.5;

    // frame.corners.bottomRight.type = "Radius";
    // frame.corners.bottomRight.size.width = 0.5;
    // frame.corners.bottomRight.size.height = 0.5;

    frame.corners.topRight.type = "Chamfer";
    frame.corners.topRight.size.width = 0.5;
    frame.corners.topRight.size.height = 0.5;

    // frame.corners.topLeft.type = "Chamfer";
    // frame.corners.topLeft.size.width = 0.5;
    // frame.corners.topLeft.size.height = 0.5;

    const { width: frameWidth, height: frameHeight } = frame.sides.top.dimensions;
    const glassThickness = 0.02; // Adjust as needed for glass thickness

    // Create a material for the frame
    const frameMaterial = new THREE.MeshPhongMaterial({
        // color: 0xffffff,
        color: color,
        specular: 0xffffff,
        shininess: 27.5,
        flatShading: false,
        emissive: 0x222222,
        bumpScale: 1,

     });
    // Extrusion settings
    const extrudeSettings = (path, steps) => ({
        // steps: 100,
        steps: steps,
        extrudePath: path, // Path to extrude along
        bevelEnabled: false,  // No bevel
        // bevelThickness: 1,
        // bevelSize: 1,
        // bevelOffset: 0,
        // bevelSegments: 100,
        // curveSegments: 120,
        morphTargetsRelative: true
    });

    const pathsAndCorners = [
        { pathType: '', cornerType: 'topRight' },
        { pathType: '', cornerType: 'topLeft' },
        { pathType: 'top', cornerType: 'bottomRight' },
        { pathType: 'right', cornerType: 'bottomLeft' },
        { pathType: 'bottom', cornerType: '' },
        { pathType: 'left', cornerType: '' }
        
    ];
    const jointType = joint?.toLowerCase();
  
    
    const frames = {};
    await pathsAndCorners.forEach(({ pathType, cornerType }) => {

        const sideData = windowData.frame.sides[pathType || "top"]
        let { width, height } = sideData.dimensions;

        const newPathName = pathType == 'top' ? "FrameTop" : pathType == 'right' ? "FrameRight" : pathType == 'bottom' ? "FrameBottom" : "FrameLeft";

        let zOffset;
        if (profileOrientation && profileType) {

            const frameProfileHeight = profileType?.height / 1000
            const frameProfileWidth = profileType?.width / 1000

            const defaultWidth = frameProfileDefault?.width / 1000

            if (newPathName == profileOrientation) {
                zOffset = (defaultWidth - frameProfileWidth) / 2;
                width = frameProfileWidth;
                height = frameProfileHeight;
            } else {
                zOffset = zOffsetMap[pathType] ?? 0
            }
        } else {
            zOffset = 0
        }


        zOffsetMap[pathType] = zOffset;

        const frameShape = getShapeCache(height, width)

        
        let framePath = pathType != "" ? createFramePath(windowData, pathType, "Corner") : null;

        let cornerPath = cornerType != "" ? createCornerPath(windowData, cornerType, frame.corners[cornerType].type) : null;

        if (framePath) {

            const frameGeometry1 = new THREE.ExtrudeGeometry(frameShape, extrudeSettings(framePath, 2));
            // frames[pathType+"Path"] = framePath;
            frames[pathType] = [framePath];
            frames[pathType].push(height);
            // updateCornerBevel(pathType, frameGeometry1, windowData.dimensions.height, windowData.dimensions.width);
            let startOffset = true, endOffset = true;
            let startAngle = 0, endAngle = 0;
            if(pathType == "top"){
                if(frame.corners["topLeft"].type == "Radius" || frame.corners["topLeft"].type == "Ellipse"){
                    endOffset = false;
                }
                if(frame.corners["topRight"].type == "Radius" || frame.corners["topRight"].type == "Ellipse"){
                    startOffset = false;
                }
                if(frame.corners["topRight"].type == "Chamfer"){
                    startAngle = getAngleFromLine(frames.topRight[0])
                }
                if(frame.corners["topLeft"].type == "Chamfer"){
                    endAngle = getAngleFromLine(frames.topLeft[0])
                }
            }
            if(pathType == "bottom"){
                if(frame.corners["bottomLeft"].type == "Radius" || frame.corners["bottomLeft"].type == "Ellipse"){
                    endOffset = false;
                }
                if(frame.corners["bottomRight"].type == "Radius" || frame.corners["bottomRight"].type == "Ellipse"){
                    startOffset = false;
                }
                if(frame.corners["bottomRight"].type == "Chamfer"){
                    startAngle = getAngleFromLine(frames.bottomRight[0])
                }
                if(frame.corners["bottomLeft"].type == "Chamfer"){
                    endAngle = getAngleFromLine(frames.bottomLeft[0])
                }
            }
            if(pathType == "left"){
                if(frame.corners["topLeft"].type == "Radius" || frame.corners["topLeft"].type == "Ellipse"){
                    startOffset = false;
                }
                if(frame.corners["bottomLeft"].type == "Radius" || frame.corners["bottomLeft"].type == "Ellipse"){
                    endOffset = false;
                }
                if(frame.corners["bottomLeft"].type == "Chamfer"){
                    startAngle = getAngleFromLine(frames.bottomLeft[0])
                }
                if(frame.corners["topLeft"].type == "Chamfer"){
                    endAngle = getAngleFromLine(frames.topLeft[0])
                }
            }
            if(pathType == "right"){
                if(frame.corners["topRight"].type == "Radius" || frame.corners["topRight"].type == "Ellipse"){
                    startOffset = false;
                }
                if(frame.corners["bottomRight"].type == "Radius" || frame.corners["bottomRight"].type == "Ellipse"){
                    endOffset = false;
                }
                if(frame.corners["bottomRight"].type == "Chamfer"){
                    startAngle = getAngleFromLine(frames.bottomRight[0])
                }
                if(frame.corners["topRight"].type == "Chamfer"){
                    endAngle = getAngleFromLine(frames.topRight[0])
                }
            }
            updateFrameJoint(jointType, pathType, frameGeometry1, height, windowData.dimensions.height, windowData.dimensions.width, startOffset, endOffset, startAngle, endAngle)
            // jointType, pathPos, mesh, fheight, wheight, wwidth, startOffset, endOffset, startAngle, endAngle, framePath)
            const frameMesh = new THREE.Mesh(frameGeometry1, frameMaterial.clone());
    
            frameMesh.castShadow = true;
            frameMesh.receiveShadow = true;
            if(pathType == "right" || pathType == "left")frameMesh.material.shininess += 10;

            frameMesh.name = pathType == 'top' ? "FrameTop" : pathType == 'right' ? "FrameRight" : pathType == 'bottom' ? "FrameBottom" : "FrameLeft";
            frameMesh.position.z -= zOffset;

            if(pathType == "bottom"){
                frameStyleBottom.current = frameMesh;

            }

    
            

            group.add(frameMesh);
            // frames[pathType] = frameMesh;
            frames[pathType].push(frameMesh);
            const boundingBox = new THREE.Box3().setFromObject(frameMesh);
            const boundHeight = boundingBox.max.y - boundingBox.min.y;


            if(jointType != "mitre"){
                const topObj = frames["top"][2];
                const topObjBoundingBox = new THREE.Box3().setFromObject(topObj);
                const topBotCorner = topObjBoundingBox.min.y;
                if(pathType == 'left'){
                    const topLeftHeight = getWidthAndHeightFromLine(frames.topLeft[0]).height || height;
                    const topOffset = frame.corners["topLeft"].type == "Corner" || frame.corners["topLeft"].size.height == 0 ? height : 0;
                    const bottomOffset = frame.corners["bottomLeft"].type == "Corner" || frame.corners["bottomLeft"].size.height == 0 ? height : 0;
                    const heightScale = (boundHeight - topOffset - bottomOffset) / boundHeight;
                    frameMesh.scale.y = heightScale;
                    const boundingBox2 = new THREE.Box3().setFromObject(frameMesh);
                    frameMesh.position.y += topBotCorner - boundingBox2.max.y - topLeftHeight + height;
                }
                if(pathType == 'right'){
                    const topRightHeight = getWidthAndHeightFromLine(frames.topRight[0]).height || height;
                    const topOffset = frame.corners["topRight"].type == "Corner" || frame.corners["topRight"].size.height == 0 ? height : 0;
                    const bottomOffset = frame.corners["bottomRight"].type == "Corner" || frame.corners["bottomRight"].size.height == 0 ? height : 0;
                    const heightScale = (boundHeight - topOffset - bottomOffset) / boundHeight;
                    frameMesh.scale.y = heightScale;
                    const boundingBox2 = new THREE.Box3().setFromObject(frameMesh);
                    frameMesh.position.y += topBotCorner - boundingBox2.max.y - topRightHeight + height;
                }
            }

            // calculate the position of each frameprofile

            const center = new THREE.Vector3()
            boundingBox.getCenter(center)

            const width = boundingBox.max.x - boundingBox.min.x;

            // Calculate the right and left ends
            const rightEnd = new THREE.Vector3(center.x + width / 2, center.y, center.z);
            const leftEnd = new THREE.Vector3(center.x - width / 2, center.y, center.z);
            // create the copy of old mesh and then update it with calculated position
         
            if (pathType === "top" || pathType === "bottom") {
                const rightJointMesh = frameMesh.clone();
                const leftJointMesh = frameMesh.clone();
                rightJointMesh.visible = false
                leftJointMesh.visible = false
                storeJointsRef(rightJointMesh, rightEnd, `${pathType}Right`);
                storeJointsRef(leftJointMesh, leftEnd, `${pathType}Left`);
            }

            const newFrameMesh = frameMesh.clone()
            newFrameMesh.position.set(center.x, center.y, center.z)
            storeFrameProfileRef(newFrameMesh)
        }

        if (cornerType && (frame.corners[cornerType].type === "Corner" || frame.corners[cornerType].type === "corner")) {
            cornerPath = "";
        }

        
        if (cornerPath) {
            const steps = frame.corners[cornerType].type == "Chamfer" ? 2 : 100;
            const pathAngle = frame.corners[cornerType].type == "Chamfer" ? getAngleFromLine(cornerPath) : "";
            let shape = frameShape.clone();
            frames[cornerType] = [cornerPath];
            if(pathAngle > 0){
                let scaleFactor = 1.2;
                if(Math.round(pathAngle) != 45)scaleFactor = 1*(90-pathAngle)/90;
                if(frame.corners[cornerType].type != "Chamfer")scaleFactor=1;
                // const scaleFactor = (1.35 * pathAngle) / 45;
                shape = profileHeightWidth(height * scaleFactor, width);
                frames[cornerType].push(height);
            }
            
            const cornerGeometry = new THREE.ExtrudeGeometry(shape, extrudeSettings(cornerPath, steps));
        
            cornerGeometry.morphTargetsRelative = true;
            cornerGeometry.lookAt = new THREE.Vector3(-1, -1, 0)
            const cornerMesh = new THREE.Mesh(cornerGeometry, frameMaterial.clone());
            cornerMesh.castShadow = true;
            // cornerMesh.scale.set(1.4, 1.4, 1)
            cornerMesh.receiveShadow = true;
            cornerMesh.material.shininess = frameMaterial.shininess - 10;
            group.add(cornerMesh);
            frames[cornerType].push(cornerMesh);
        }
    });

//    console.log(frames , "396");

    // Object.keys(frames).forEach(key => {
    //     if (key === "topRight" || key === "topLeft" || key === "bottomRight" || key === "bottomLeft") {
    //         console.log(frames[key], "362");

    //         const v1 = frames[key][0]?.v1
    //         const v2 = frames[key][0]?.v2
    //         const mesh = frames[key][1]

    //         const startPoint = new THREE.Vector3(...v1);
    //         const endPoint = new THREE.Vector3(...v2);

    //         const centerPoint = new THREE.Vector3(
    //             (startPoint.x + endPoint.x) / 2,
    //             (startPoint.y + endPoint.y) / 2,
    //             (startPoint.z + endPoint.z) / 2
    //         );

    //         storeJointsRef(mesh, centerPoint, key)
    //     }
    // })
   
    // removeExceedCorners(frames);
    // // Example usage:
 

    function removeBaseGlass(){
        scene.traverse((child) => {
            if ((child instanceof THREE.Mesh)) {
                if (child.name.includes("GlassPanel")) {
                    scene.remove(child);
                }
            }
        })
    }
    
    const glassMaterial = new THREE.MeshPhysicalMaterial({
        color: 0xADD8E6,
        roughness: 0.05,
        metalness: 0.9,
        reflectivity: 0.6,
        clearcoat: 1,
        clearcoatRoughness: 0.05,
        envMapIntensity: 1,
        opacity: 0.5,
        transparent: true
    });

    if (sashHangingValue > -1) {
        removeBaseGlass();
        createSash(sash, group, frame.sides, windowData.dimensions.width, windowData.dimensions.height, sashHangingValue, sashGroup);
    }
    if (parseInt(sashHangingValue) <= 0) {
        const glassExtrudeSettings = {
            depth: glassThickness,
            bevelEnabled: false,
        };

        const combinedOutlinePath = createCombinedOutlinePath(windowData);
        const glassGeometry = new THREE.ExtrudeGeometry(combinedOutlinePath, glassExtrudeSettings);
        const glassMesh = new THREE.Mesh(glassGeometry, glassMaterial);
        glassMesh.name = "GlassPanel";
        glassMesh.scale.set(0.999, 0.999, 1);
        glassMesh.position.z = -glassThickness * 0.5;
        group.add(glassMesh);
        hangingRefs.push(glassMesh)
    }


    scene.add(group);

}


function removeExceedCorners(frames){
    // its working fine *****
    // const bottomLeft1 = CSG.subtract(frames.bottomLeft[2], frames.left[2]);
    // const bottomLeftsm = CSG.subtract(bottomLeft1, frames.bottom[2]);
    // frames.bottomLeft[2].geometry = bottomLeftsm.geometry;
}

function removeGroupByName(scene, groupName) {
    const groupToRemove = scene.getObjectByName(groupName);

    if (groupToRemove) {
        scene.remove(groupToRemove);
        groupToRemove.traverse((child) => {
            if (child.geometry) child.geometry.dispose();
            if (child.material) {
                if (Array.isArray(child.material)) {
                    child.material.forEach((material) => material.dispose());
                } else {
                    child.material.dispose();
                }
            }
        });
    } else {
        console.log(`Group with name ${groupName} not found.`);
    }
}


function findIntersectionPoint(line1, line2, offset) {
    // Extract points from lines
    const p1 = line1[0];
    const p2 = line1[1];
    const p3 = line2[0];
    const p4 = line2[1];
  
    // Calculate direction vectors
    const d1 = { x: p2.x - p1.x, y: p2.y - p1.y };
    const d2 = { x: p4.x - p3.x, y: p4.y - p3.y };
  
    const n1 = { x: -d1.y, y: d1.x };
    const n2 = { x: d2.y, y: -d2.x };
  
    const p1_offset = { x: p1.x + n1.x * offset, y: p1.y + n1.y * offset };
    const p2_offset = { x: p2.x + n1.x * offset, y: p2.y + n1.y * offset };
    const p3_offset = { x: p3.x + n2.x * offset, y: p3.y + n2.y * offset };
    const p4_offset = { x: p4.x + n2.x * offset, y: p4.y + n2.y * offset };
  
  
    const dx1 = p2_offset.x - p1_offset.x;
    const dy1 = p2_offset.y - p1_offset.y;
    const dx2 = p4_offset.x - p3_offset.x;
    const dy2 = p4_offset.y - p3_offset.y;
  
    const det = dx1 * dy2 - dy1 * dx2;
  
    if (det === 0) {
      // Lines are parallel or coincident
      return null;
    }
  
    const t = (dy2 * (p3_offset.x - p1_offset.x) - dx2 * (p3_offset.y - p1_offset.y)) / det;
  
    const intersectionPoint = {
      x: p1_offset.x + t * dx1,
      y: (p1_offset.y + t * dy1)
    };
  
    return intersectionPoint;
  }
  
  // Example usage:
  const line1 = [{ x: -1.25, y: 1.05 }, { x: -1.25, y: 0 }];//left
  const line2 = [{ x: -1.25, y: 0 }, { x: 0.25, y: -1.05 }];
  const offset = 0.04;
  
  const intersectionPoint = findIntersectionPoint(line1, line2, offset);
//   console.clear();
  
//   console.log(intersectionPoint);


function getWidthAndHeightFromLine(lineCurve) {
    // Retrieve start and end points of the line
    const v1 = lineCurve.v1 || lineCurve.points[0];
    const v2 = lineCurve.v2 || lineCurve.points[lineCurve.points.length - 1];

    // Calculate the differences in coordinates
    const deltaX = Math.abs(v2.x - v1.x);
    const deltaY = Math.abs(v2.y - v1.y);

    // Return width and height
    return {
        width: deltaX,
        height: deltaY,
    };
}