import React, { useState } from 'react'
import { Form } from 'react-bootstrap';
import {
    Row,
    Col,
    Button,
    Label,
    FormGroup,
    Input,
} from 'reactstrap';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useProductPaletteContext } from '../../../../../../../context/productPaletteContext';
import { useMessage } from '../../../../../../../context/messageContext';
import { addCollectionColours, addExternalColours, deleteExternalColours, getUniqueCollection, hiddenColourListExternal, setDefaultExternalColour, updateExternalVisibleOrder, visibleColourListExternal } from '../../../../../../../services/productServices';
import CollectionColours from "../../../../../../../components/CollectionColours";
import { useRightContext } from '../../../../../../../context/rightMenuContext';
import Editprices from '../../../../../../../components/Editprices';

const CollectionsLeft = ({ setLoading, getAllCollectionServices, allCollection }) => {


    const [name, setName] = useState('')
    const [desc, setDesc] = useState('')
    const [hiddenExternalColours, setHiddenExternalColours] = useState([])
    const [visibleExternalColors, setVisibleExternalColors] = useState([])
    const [selectedHiddenIndex, setSelectedHiddenIndex] = useState(0);
    const [selectedVisibleIndex, setSelectedVisibleIndex] = useState(0);
    const [id, setId] = useState()
    const [visibleData, setVisibleData] = useState('')
    const accessToken = localStorage.getItem('access_token')

    const { timestamp, newItemList, handleEdit, setTimestamp, activeIndex } = useProductPaletteContext();
    const { setMessage, setMessageType } = useMessage()

    const { setSelectedRightItem, selectedRightItem } = useRightContext()


    const navigate = useNavigate()

    const firstElId = allCollection[0]

    useEffect(() => {
        if (firstElId) {
            if (allCollection?.length && activeIndex !== 0) {
                setTimestamp(timestamp)
            } else {
                setTimestamp(firstElId?.id)
            }
        }
    }, [allCollection, timestamp])

    useEffect(() => {
        if (newItemList?.length === 0) {
            setTimestamp('')
            setName(' ')
        }
    }, [newItemList])

    useEffect(() => {
        if (timestamp !== '' && newItemList?.length > 0) {
            getUniqueCollectionServices()
        }
    }, [timestamp])

    useEffect(() => {
        if (timestamp !== '' && newItemList?.length > 0) {
            externalHiddenListService()
            externalVisibleListService()
        }
    }, [timestamp])

    const reset = () => {
        getUniqueCollectionServices()
        getAllCollectionServices()
    }

    const addExternalProductService = async (colorId, colorName) => {
        setLoading(true)
        const res = await addExternalColours(timestamp, colorId, colorName, accessToken)
        if (res && res.status === 200) {
            if (res?.data?.statusCode === 200) {
                setMessage(res?.data?.message ? res.data.message : 'Colors Shown')
                setMessageType('success')
                setLoading(false)
                externalHiddenListService()
                externalVisibleListService()

            } else {
                setLoading(false)
                setMessage(res?.data?.message ? res.data.message : 'Something went wrong');
                setMessageType('error')
                externalHiddenListService()
                externalVisibleListService()
            }
        } else {
            setLoading(false)
            setMessage(res?.message ? res.message : 'Something went wrong');
            setMessageType('error')
            if (res?.message === "Network Error") {
                navigate('/error/network')
                setLoading(false)
            }
        }
    }

    const deleteExternalColourServices = async (externalColorId) => {
        const res = await deleteExternalColours(externalColorId, accessToken)
        if (res && res.status === 200) {
            if (res?.data?.statusCode === 200) {
                setMessage(res?.data?.message)
                setMessageType('success')
                setLoading(false)
                externalHiddenListService()
                externalVisibleListService()
            } else {
                setLoading(false)
                setMessage(res?.data?.message);
                setMessageType('error')
                externalHiddenListService()
                externalVisibleListService()
            }
        } else {
            setLoading(false)
            setMessage(res?.message ? res.message : 'Something went wrong');
            setMessageType('error')
            if (res?.message === "Network Error") {
                navigate('/error/network')
                setLoading(false)
            }
        }
    }


    const addUpdateColorsServices = async () => {
        setLoading(true)
        const data = {
            id: timestamp,
            name: name,
            type: 2,
        }
        const res = await addCollectionColours(data, accessToken)
        if (res && res.status === 200) {
            if (res?.data?.statusCode === 200) {
                setMessageType('success')
                setMessage(res?.data?.message ? res?.data?.message : 'Added new colour')
                // getAllCollectionServices('added')
                setLoading(false)
            } else {
                setMessage(res?.data?.message ? res?.data?.message : 'Something went wrong')
                setMessageType('error')
                // getAllCollectionServices()
                setLoading(false)
            }
        }

    }

    const getUniqueCollectionServices = async () => {
        const res = await getUniqueCollection(timestamp, accessToken)
        if (res && res.status === 200) {
            if (res?.data?.statusCode === 200) {
                const selectedRight = allCollection?.find((p) => p.id == timestamp)
                setSelectedRightItem(selectedRight)
                setName(res?.data.entity?.name)
                setTimestamp(timestamp)
                setLoading(false)
                setId(res?.data.entity?.id)

            } else {
                setLoading(false)
                setMessage(res?.data?.message ? res.data.message : 'Something went wrong');
                setMessageType('error')
            }
        } else {
            setLoading(false)
            setMessage(res?.message ? res.message : 'Something went wrong');
            setMessageType('error')
            if (res?.message === "Network Error") {
                navigate('/error/network')
                setLoading(false)
            }

        }
    }

    const handleNameChange = (e) => {
        const value = e.target.value;
        setName(value)
        handleEdit(value)
    }

    const externalVisibleListService = async () => {
        setLoading(true)
        const res = await visibleColourListExternal(accessToken, timestamp, 2)
        if (res && res.status === 200) {
            if (res?.data?.statusCode === 200) {
                setMessage(res?.data?.message)
                setMessageType('success')
                setLoading(false)
                setVisibleExternalColors(res?.data?.entity)
            }
        }
    }

    const externalHiddenListService = async () => {
        setLoading(true)
        const res = await hiddenColourListExternal(accessToken, timestamp, 2)
        if (res && res.status === 200) {
            if (res?.data?.statusCode === 200) {
                setMessage(res?.data?.message)
                setMessageType('success')
                setLoading(false)
                setHiddenExternalColours(res?.data?.entity)
            }
        }
    }

    const defaultServices = async () => {
        const res = await setDefaultExternalColour(accessToken, visibleData?.primaryId)
        if (res && res.status === 200) {
            if (res?.data?.statusCode === 200) {
                setMessage(res?.data?.message)
                setMessageType('success')
                setLoading(false)
                externalVisibleListService()
            } else {
                setLoading(false)
                setMessage(res?.data?.message);
                setMessageType('error')
                externalVisibleListService()
            }
        } else {
            setLoading(false)
            setMessage(res?.message ? res.message : 'Something went wrong');
            setMessageType('error')
            // getAllColoursServices()
            if (res?.message === "Network Error") {
                navigate('/error/network')
                setLoading(false)
            }
        }
    }

    // const updateOrder = async () => {
    //     console.log('order');
    // }

    const updateInternalVisibleOrderServices = async (direction) => {
        setLoading(true)
        // console.log(visibleData.colourId, "251");
        const res = await updateExternalVisibleOrder(accessToken, visibleData?.primaryId, direction, timestamp)

        if (res && res && res.status === 200) {

            if (res?.data?.statusCode === 200) {
                externalVisibleListService()

            } else {
                setLoading(false)
                setMessage(res?.data?.message ? res.data.message : 'Something went wrong');
                setMessageType('error')
            }
        } else {
            setLoading(false)
            setMessage(res?.message ? res.message : 'Something went wrong');
            setMessageType('error')

            if (res?.message === "Network Error") {
                navigate('/error/network')
            }
        }
    }
    const updateOrder = async (direction, type) => {
        updateInternalVisibleOrderServices(direction, type)

        if (direction === "UP") {
            setSelectedVisibleIndex(selectedVisibleIndex - 1)
        } else {
            setSelectedVisibleIndex(selectedVisibleIndex + 1)
        }
    }

    const editNavData = { url: `/pricing/hardware-color` }

    return (
        <div className='d-flex flex-column h-100'>
            {/* <h5 className='main_title'>Lorem ipsum dolor sit amet</h5> */}
            {/* <Nav tabs className="details_header border-0 nav-tabs-custom">
                <NavItem>
                    <NavLink
                        className={`${activeTab === "General" && 'active'}`}
                        onClick={() => noRefCheck("General")}
                    >
                        General
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        className={`${activeTab === "Discount" && 'active'}`}
                        onClick={() => noRefCheck("Discount")}
                    >
                        Discount
                    </NavLink>
                </NavItem>
            </Nav> */}
            <Form className='h-100 d-flex flex-column flex-grow-1 justify-content-between'>

                <div className='inside_scroll has_btns has_head'>
                    <div className='d-flex align-items-center left_header mb-3'>
                        {name && (
                            <h5 className="mb-0 text-wrap text-break me-4 main_title">{name}</h5>
                        )}
                        <Editprices data={editNavData} hasState={true} />
                    </div>
                    <Row>
                        <Col lg="12">
                            <Row className='align-items-center'>
                                <Col lg="6">
                                    <FormGroup className="form-group">
                                        <Label for="name">
                                            Name
                                        </Label>
                                        <Input disabled={!selectedRightItem?.isSelf} className='mb-1' name='name' value={name} onChange={(e) => handleNameChange(e)} />
                                        {name && name.length > 49 && (
                                            <div className='mt-1 text-muted text-small'>Max 50 characters are allowed</div>
                                        )}
                                        {(!name && id) && (
                                            <div className='mt-1 text-danger text-small'>Name is required</div>
                                        )}
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Col>
                        <Col lg="12" className='mb-3'>
                            <CollectionColours shownColours={visibleExternalColors} hiddenColours={hiddenExternalColours} moveToRight={addExternalProductService} moveToLeft={deleteExternalColourServices} selectedHiddenIndex={selectedHiddenIndex} selectedVisibleIndex={selectedVisibleIndex} setSelectedVisibleIndex={setSelectedVisibleIndex} setSelectedHiddenIndex={setSelectedHiddenIndex} setVisibleData={setVisibleData} visibleData={visibleData} setDefaultColour={defaultServices} updateOrder={updateOrder} isSelf={selectedRightItem?.isSelf} />
                        </Col>
                    </Row>
                </div>
                {selectedRightItem?.isSelf && <div className='d-flex gap-2 gap-xl-3 main_buttons'>
                    <Button
                        color="primary"
                        size="md"
                        className='px-4'
                        onClick={() => addUpdateColorsServices()}
                        disabled={newItemList?.length === 0 || name?.length > 49 || !name}
                    >
                        Save Changes
                    </Button>

                    <Button
                        color="primary"
                        size="md"
                        outline
                        className='px-4'
                        disabled={newItemList?.length === 0 || name?.length > 49 || !name}
                        onClick={() => reset()}
                    >
                        Reset
                    </Button>
                </div>}
            </Form>

        </div>
    )
}

export default CollectionsLeft