import React from 'react';
import { useRightContext } from '../../../../context/rightMenuContext';
import ItemQuantity from '../../../../components/Pricing/ItemQuantity';
// import { formatValue } from '../../../../utility/helper';

const DocSummary = ({ stylesHeader, generalCss, summary, summaryPrice, currency }) => {

    const { projectDefault, generalTax } = useRightContext()

    // console.log(projectDefault, generalTax, "test")

    return (
        <>
            {/* <div style={generalCss.breakInside}>
            {header?.showHeader === "On every page" && (
                <DocHeader
                    stylesHeader={stylesHeader}
                    generalCss={generalCss}
                    pdfData={pdfData}
                    header={header}
                    itemCount={frameSpecificData && frameSpecificData?.length || 0}
                />
            )}
        </div> */}

            <div style={{ ...generalCss.summaryGap, ...generalCss.mb3 }}>
                {/* Frame summary */}
                {summary?.frames && summaryPrice && summaryPrice.framePrice && summaryPrice?.framePrice?.length > 0 && (
                    <div className='unsetPrintHeight' style={{ ...stylesHeader.rightBox, ...generalCss.heightUnset, ...generalCss.breakInside }} id='summary_frame'>
                        <h6 style={stylesHeader.rightBox.text}>Frames</h6>

                        <table style={stylesHeader.table}>
                            <tbody>
                                {summaryPrice.framePrice.map((o, index) => (
                                    <tr key={index}>
                                        <td style={summaryPrice?.framePrice?.length !== (index + 1) ? { ...stylesHeader.table.td } : { ...stylesHeader.table.td, ...stylesHeader.table.borderNone }}>
                                            <ItemQuantity name={o?.name} quantity={o.quantity} />
                                        </td>
                                        <td style={summaryPrice?.framePrice?.length !== (index + 1) ? { ...stylesHeader.table.td, ...stylesHeader.table.textEnd } : { ...stylesHeader.table.td, ...stylesHeader.table.borderNone, ...stylesHeader.table.textEnd }}>
                                            {currency}{o?.price}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}

                {summaryPrice?.wastageModel?.price > 0 && (
                    <div className='unsetPrintHeight' style={{ ...stylesHeader.rightBox, ...generalCss.heightUnset, ...generalCss.breakInside }} id='summary_frame'>
                        <h6 style={stylesHeader.rightBox.text}>Wastage</h6>

                        <table style={stylesHeader.table}>
                            <tbody>
                                <tr>
                                    <td style={summaryPrice?.wastageModel?.price > 0 ? { ...stylesHeader.table.td } : { ...stylesHeader.table.td, ...stylesHeader.table.borderNone }}>
                                        Total amount
                                    </td>
                                    <td style={summaryPrice?.wastageModel?.price > 0 ? { ...stylesHeader.table.td, ...stylesHeader.table.textEnd } : { ...stylesHeader.table.td, ...stylesHeader.table.borderNone, ...stylesHeader.table.textEnd }}>
                                        {currency}{summaryPrice?.wastageModel?.price}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                )}

                {/* Installation summary */}
                {summary?.installation && summaryPrice && summaryPrice.installationPrice && summaryPrice?.installationPrice?.length > 0 && (
                    <div className='unsetPrintHeight' style={{ ...stylesHeader.rightBox, ...generalCss.heightUnset, ...generalCss.breakInside }} id='summary_installation'>
                        <h6 style={stylesHeader.rightBox.text}>Installation</h6>

                        <table style={stylesHeader.table}>
                            <tbody>
                                {summaryPrice.installationPrice.map((o, index) => (
                                    <tr key={index}>
                                        <td style={summaryPrice?.installationPrice?.length !== (index + 1) ? { ...stylesHeader.table.td } : { ...stylesHeader.table.td, ...stylesHeader.table.borderNone }}>
                                            <ItemQuantity name={o?.name} quantity={o.quantity} />
                                        </td>
                                        <td style={summaryPrice?.installationPrice?.length !== (index + 1) ? { ...stylesHeader.table.td, ...stylesHeader.table.textEnd } : { ...stylesHeader.table.td, ...stylesHeader.table.borderNone, ...stylesHeader.table.textEnd }}>
                                            {projectDefault?.installation ? (
                                                `${currency}${o?.price}`
                                            ) : (
                                                `${currency}0`
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}

                {/* Ancillaries Price summary */}
                {summary?.ancillaries && summaryPrice && summaryPrice.ancillariesPrice && summaryPrice?.ancillariesPrice?.length > 0 && (
                    <div className='unsetPrintHeight' style={{ ...stylesHeader.rightBox, ...generalCss.heightUnset, ...generalCss.breakInside }} id='summary_ancillaries'>
                        <h6 style={stylesHeader.rightBox.text}>Ancillaries</h6>

                        <table style={stylesHeader.table}>
                            <tbody>
                                {summaryPrice.ancillariesPrice.map((o, index) => (
                                    <tr key={index}>
                                        <td style={summaryPrice?.ancillariesPrice?.length !== (index + 1) ? { ...stylesHeader.table.td } : { ...stylesHeader.table.td, ...stylesHeader.table.borderNone }}>
                                            <ItemQuantity name={o?.ancillariesName} quantity={o.quantity} />
                                        </td>
                                        <td style={summaryPrice?.ancillariesPrice?.length !== (index + 1) ? { ...stylesHeader.table.td, ...stylesHeader.table.textEnd } : { ...stylesHeader.table.td, ...stylesHeader.table.borderNone, ...stylesHeader.table.textEnd }}>
                                            {currency}{o.ancillariesPrice}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}

                {/* Total summary */}
                {summary?.total && summaryPrice && (
                    <div className='unsetPrintHeight' style={{ ...stylesHeader.rightBox, ...generalCss.heightUnset, ...generalCss.breakInside }} id='summary_total'>
                        <h6 style={stylesHeader.rightBox.text}>Total</h6>

                        <table style={stylesHeader.table}>
                            <tbody>
                                <tr>
                                    <td style={stylesHeader.table.td}>
                                        Frames
                                    </td>
                                    <td style={{ ...stylesHeader.table.td, ...stylesHeader.table.textEnd }}>
                                        {currency}{summaryPrice?.total?.framesPrice?.price}
                                    </td>
                                </tr>

                                {summary?.installation && summaryPrice.installationPrice && summaryPrice?.installationPrice?.length > 0 && (
                                    <tr>
                                        <td style={stylesHeader.table.td}>
                                            Installation
                                        </td>
                                        <td style={{ ...stylesHeader.table.td, ...stylesHeader.table.textEnd }}>
                                            {currency}{summaryPrice?.total?.installationPrice?.price}
                                        </td>
                                    </tr>
                                )}

                                {summary?.ancillaries && summaryPrice.ancillariesPrice && summaryPrice?.ancillariesPrice?.length > 0 && (
                                    <tr>
                                        <td style={stylesHeader.table.td}>
                                            Ancillaries
                                        </td>
                                        <td style={{ ...stylesHeader.table.td, ...stylesHeader.table.textEnd }}>
                                            {currency}{summaryPrice?.total?.ancillariesPrice?.price}
                                        </td>
                                    </tr>
                                )}

                                <tr>
                                    <td style={{ ...stylesHeader.table.td, ...generalCss.fw500 }}>
                                        Subtotal
                                    </td>
                                    <td style={{ ...stylesHeader.table.td, ...stylesHeader.table.textEnd }}>
                                        {currency}{summaryPrice?.total?.subtotal?.price}
                                    </td>
                                </tr>

                                {summaryPrice.discounts > 0 && (
                                    <>
                                        <tr>
                                            <td style={stylesHeader.table.td}>
                                                Discount
                                            </td>
                                            <td style={{ ...stylesHeader.table.td, ...stylesHeader.table.textEnd }}>
                                                - {currency}{summaryPrice.discounts}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td style={{ ...stylesHeader.table.td, ...generalCss.fw500 }}>
                                                Subtotal
                                            </td>
                                            <td style={{ ...stylesHeader.table.td, ...stylesHeader.table.textEnd }}>
                                                {currency}{summaryPrice?.total?.discountedSubtotal?.price}
                                                {/* {currency}{formatValue(summaryPrice?.total?.discountedSubtotal?.price - summaryPrice.discounts)} */}
                                            </td>
                                        </tr>
                                    </>
                                )}

                                {projectDefault?.vat === "total" ? (
                                    <tr>
                                        <td style={stylesHeader.table.td}>
                                            {generalTax?.description}
                                        </td>
                                        <td style={{ ...stylesHeader.table.td, ...stylesHeader.table.textEnd }}>
                                            {currency}{summaryPrice?.total?.vat?.price}
                                        </td>
                                    </tr>
                                ) : (
                                    <tr>
                                        <td style={stylesHeader.table.td}>
                                            Of which {generalTax?.description}
                                        </td>
                                        <td style={{ ...stylesHeader.table.td, ...stylesHeader.table.textEnd }}>
                                            {currency}{summaryPrice?.total?.vat?.price}
                                        </td>
                                    </tr>
                                )}

                                <tr>
                                    <td style={{ ...stylesHeader.table.td, ...stylesHeader.table.borderNone }}>
                                        <strong>Total</strong>
                                    </td>
                                    <td style={{ ...stylesHeader.table.td, ...stylesHeader.table.borderNone, ...stylesHeader.table.textEnd }}>
                                        {currency}{summaryPrice?.total?.total?.price}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                )}
            </div>
        </>
    )
};

export default DocSummary