import { Navigate, Outlet } from 'react-router-dom';
import { useRightContext } from '../context/rightMenuContext';
import { Oval } from 'react-loader-spinner';

const RoleProtectedRoutes = ({ allowedRoles, userRole }) => {

    const { userDataContext } = useRightContext()

    return userDataContext ? (
        allowedRoles.includes(userRole) ? <Outlet /> : <Navigate to="/unauthorized" />
    ) : <div className='loader_main full_loader'>
        <Oval
            height={50}
            width={50}
            color="#44C8F5"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel='oval-loading'
            secondaryColor="#44C8F5"
            strokeWidth={2}
            strokeWidthSecondary={2}
        />
    </div>;
};


export default RoleProtectedRoutes