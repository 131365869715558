import axios from "axios"
import { servicePath } from "../utility/data"

export const setPanelModelProperty = (accessToken, json, panelCollectionId, panelProductId) => {
    const formData = new FormData()
    formData.append('Id', 0)
    formData.append('PanelCollectionId', panelCollectionId)
    formData.append('PanelProductId', panelProductId)
    formData.append('JSONBlob', JSON.stringify(json))


    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }
    return axios
        .post(`${servicePath}/Panel/SetPanelProperty`, formData, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
}

export const getPanelModelProperty = (accessToken, panelCollectionId, panelProductId) => {
    const formData = new FormData();
    formData.append('PanelCollectionId', panelCollectionId)
    formData.append('PanelProductId', panelProductId)

    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }
    return axios
        .post(`${servicePath}/Panel/GetPanelProperty`, formData, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
}