import { MenuItem, OutlinedInput, Select } from "material-ui-core";
import React, { useState } from "react";
import { FormGroup } from "reactstrap";
import { MenuProps } from "../../utility/data";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";

const DynamicDropdown = ({ arr, setMethod }) => {
  
  const numbers = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];

  const handleSelectChange = (index, value) => {
    const newNumbers = [...arr];
    newNumbers[index] = value;

    // Add a new empty dropdown if the selected dropdown is the last one and it's not empty
    if (index === newNumbers.length - 1 && value !== "") {
      newNumbers.push("");
    }

    setMethod((prevModelData) => ({
      ...prevModelData,
      hardware: {
        ...prevModelData.hardware,
        numeral: [
          {
            ...prevModelData.hardware.numeral[0],
            numbers: newNumbers,
          },
          ...prevModelData.hardware?.numeral?.slice(1), // Keep the rest of the elements unchanged
        ],
      },
    }));
  };


  return (
    <div className="d-flex flex-wrap">
      {arr?.length > 0 &&
        arr?.map((item, index) => (
          <FormGroup className="form-group w-25 me-1" key={index}>
            <Select
              labelId="printdoc-label"
              id="numeralDrop"
              value={item}
              label="numeralDrop"
              onChange={(e) => handleSelectChange(index, e.target.value)}
              input={<OutlinedInput className="w-100 bg-white" name="bump" />}
              MenuProps={MenuProps}
              IconComponent={() => (
                <MdOutlineKeyboardArrowDown
                  size={20}
                  className="me-1 MuiSvgIcon-root MuiSelect-icon"
                />
              )}
            >
              {numbers?.map((item, index) => {
                return (
                  <MenuItem value={item} key={index}>
                    {item}
                  </MenuItem>
                );
              })}
            </Select>
          </FormGroup>
        ))}
    </div>
  );
};

export default DynamicDropdown;
