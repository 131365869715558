// function to convert hex to RGB and set dynamic primary color from glazingo
export function setColor(newColor) {
    if (newColor) {
        // hex to rgb values
        const r = parseInt(newColor?.slice(1, 3), 16);
        const g = parseInt(newColor?.slice(3, 5), 16);
        const b = parseInt(newColor?.slice(5, 7), 16);

        var hexRGB = `${r}, ${g}, ${b}`;

        document.documentElement.style.setProperty('--theme-blue', newColor);
        document.documentElement.style.setProperty('--theme-blue-rgb', hexRGB);
    }
}

export const handleError = (error, logoutStatus, navigate) => {

    if (logoutStatus === 'true') {
        navigate('/logout');
        return;
    }

    const { status } = error?.response || {};
    const { message } = error || {};

    if (status === 401) {
        navigate('/unauthorized');
        localStorage.removeItem('access_token');
    } else if (status === 404 || message === 'Network Error') {
        navigate(message === 'Network Error' ? '/error/network' : '/error/503');
        localStorage.setItem('network_error', true);
    }
};

//This for showing the alert in case of sash and frame if all the profiles are disable
export const getCountForStatus = (arr, setMethod) => {
    if (arr && arr?.length > 0) {
        let statusCount = 0;
        arr?.forEach((item) => {
            if (!item?.status) {
                statusCount++;
            }
        });
        if (statusCount === arr?.length) {
            setMethod(true);
        } else {
            setMethod(false);
        }
    }
};