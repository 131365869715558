import axios from "axios";
import { servicePath } from "../utility/data";

export const addUpdateHardwareCollection = (data, accessToken) => {
    const formData = new FormData();
    formData.append("Id", data.id);
    formData.append("Name", data.name);


    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    return axios
        .post(`${servicePath}/Hardware/AddUpdateHardwareCollection`, formData, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error
        });
};

export const allHardwareCollection = (accessToken) => {
    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    return axios
        .get(`${servicePath}/Hardware/GetAllHardwareCollection`, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error
        });
}


export const deleteHardwareCollection = (id, accessToken) => {

    const formData = new FormData();
    formData.append("CollectionId", id);

    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    return axios
        .post(`${servicePath}/Hardware/DeleteHardwareCollection`, formData, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error
        });
};

export const addHardwareStyles = (collectionId, dropDownId, styleId, accessToken) => {

    const formData = new FormData();
    formData.append("HardwareCollectionId", collectionId);
    formData.append("HardwarestyleMasterId", dropDownId);
    formData.append("HardwarestyleId", styleId);
    // formData.append("SequenceNo", '');

    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    return axios
        .post(`${servicePath}/Hardware/AddHardwareCollectionstyles`, formData, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error
        });
};

export const deleteHardwareStyles = (styleId, accessToken) => {

    const formData = new FormData();
    formData.append("Id", styleId);

    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    return axios
        .post(`${servicePath}/Hardware/DeleteHardwareCollectionstyles`, formData, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error
        });
};

export const getUniqueHardwareCollection = (id, accessToken) => {

    const formData = new FormData();
    formData.append("id", id);

    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    return axios
        .post(`${servicePath}/Hardware/GetHardwareCollection`, formData, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error
        });
};

export const allHardwareCollectionStyles = (collectionId, accessToken, dropDownId) => {

    const formData = new FormData();
    formData.append("HardwareCollectionId", collectionId);
    formData.append("StyleMasterId", dropDownId);

    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    return axios
        .post(`${servicePath}/Hardware/GetAllHardwareCollectionStyles`, formData, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error
        });
};


export const updateHardwareStyleOrder = (styleId, typeText, direction, accessToken) => {

    const body = {
        id: styleId,
        sequenceNo: 0,
        typeText: typeText,
        type: direction,
    }
    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    return axios
        .post(`${servicePath}/Hardware/UpdateHardwareStyleOrder`, body, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error
        });
};


export const updateHardwareCollectionOrder = (collectionId, typeText, direction, accessToken) => {

    const body = {
        id: collectionId,
        typeText: typeText,
        type: direction
    }
    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    return axios
        .post(`${servicePath}/Hardware/UpdateHardwareCollectionOrder`, body, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error
        });
};

export const UpdateVisibleHardwareStyleOrder = (styleId, hardwareCollectionId, hardwarestyleMasterId, direction, accessToken) => {

    const body = {
        id: styleId,
        sequenceNo: 0,
        FirstParentId: hardwareCollectionId,
        SecondParentId: hardwarestyleMasterId,
        type: direction,
    }
    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    return axios
        .post(`${servicePath}/Hardware/UpdateVisibleHardwareStyleOrder`, body, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error
        });
};

export const allAccessoriesCollection = (accessToken) => {
    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    return axios
        .get(`${servicePath}/Pricing/GetAllAccessories`, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error
        });
}