import React, { useState } from 'react'
import { BiPlus, BiTrashAlt } from 'react-icons/bi';
import { MdArrowDownward, MdPersonOutline, MdArrowUpward, MdContentCopy } from 'react-icons/md';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { useEffect } from 'react';
import { useProductPaletteContext } from '../../../../../context/productPaletteContext';
import { useMessage } from '../../../../../context/messageContext';
import { addUpdateColoursName, deletePalette, updateOrder } from '../../../../../services/productServices';
import { copyItem } from '../../../../../services/commonServices';
import RotatingCube from '../../../../../components/3DComponents/RotatingCube';



const ColoursRight = ({ setLoading, getAllColoursServices }) => {

  const { newItemList, fetchSelected, activeIndex, handleActiveIndexDown, handleActiveIndexUp, setActiveIndex, timestamp } = useProductPaletteContext();
  const { setMessage, setMessageType } = useMessage()
  const [showDeleteModal, setShowDeleteModal] = useState(false)

  const accessToken = localStorage.getItem('access_token')

  const navigate = useNavigate()


  useEffect(() => {
    setActiveIndex(0)
  }, [])

  const getSingleColor = newItemList?.find((item) => item.colourPaletteId === timestamp)




  const addNameServices = async (name) => {
    setLoading(true)
    const data = {
      id: "",
      name: name,
      description: "",
      type: 'chart',
      chartId: 0,
      chartColorType: true,
      chartColorId: 0,
      textureId: 0,
      red: 0,
      green: 0,
      blue: 0,
      hex: 0,
      roughness: 0,
      metalness: 0,
      bumpId: 0,
      bumpiness: 0,
      paletteType: 7,
    }
    const res = await addUpdateColoursName(data, accessToken)
    if (res && res.status === 200) {
      if (res?.data?.statusCode === 200) {
        setMessageType('success')
        setMessage(res?.data?.message ? res.data.message : 'Added new colour')
        getAllColoursServices('added')
        setLoading(false)
      } else {
        setMessage(res?.data?.message ? res.data.message : 'Something went wrong')
        setMessageType('error')
        getAllColoursServices()
        setLoading(false)

      }
    }
  }

  const deletePaletteServices = async () => {
    setLoading(true)
    const res = await deletePalette(accessToken, timestamp)
    if (res && res.status === 200) {
      if (res?.data?.statusCode === 200) {
        setMessageType('success')
        setMessage(res?.data?.message ? res.data.message : 'Deleted Color Successfully')
        getAllColoursServices()
        setLoading(false)
        fetchSelected(0, newItemList[0]?.id)
        setShowDeleteModal(false)
      } else {
        setMessage(res?.data?.message ? res.data.message : 'Something went wrong')
        setMessageType('error')
        getAllColoursServices()
        setLoading(false)
      }
    } else {
      setLoading(false)
      setMessage(res?.message ? res.message : 'Something went wrong');
      setMessageType('error')
      getAllColoursServices()
      if (res?.message === "Network Error") {
        navigate('/error/network')
      }
    }

  }

  const updateOrderServices = async (direction) => {
    setLoading(true)

    const res = await updateOrder(accessToken, timestamp, direction)

    if (res && res && res.status === 200) {

        if (res?.data?.statusCode === 200) {
          getAllColoursServices()
        } else {
            setLoading(false)
            setMessage(res?.data?.message ? res.data.message : 'Something went wrong');
            setMessageType('error')
        }
    } else {
        setLoading(false)
        setMessage(res?.message ? res.message : 'Something went wrong');
        setMessageType('error')

        if (res?.message === "Network Error") {
            navigate('/error/network')
        }
    }
}

const handleItemUp = () => {
    handleActiveIndexUp()
    updateOrderServices("UP")
}

const handleItemDown = () => {
    handleActiveIndexDown()
    updateOrderServices("DOWN")
}

const copyServices = async (id) => {
  setLoading(true)
  const res = await copyItem(accessToken, id, 4) // pass typeId 4 for building colours
  if (res && res && res.status === 200) {
      if (res?.data?.statusCode === 200) {
          setLoading(false)
          setMessage(res?.data?.message ? res.data.message : 'Record removed successfully !');
          setMessageType('success')

          getAllColoursServices()
      } else {
          setLoading(false)
          setMessage(res?.data?.message ? res.data.message : 'Something went wrong');
          setMessageType('error')
      }
  } else {
      setLoading(false)
      setMessage(res?.message ? res.message : 'Something went wrong');
      setMessageType('error')

      if (res?.message === "Network Error") {
          navigate('/error/network')
      }
  }

}


  return (
    <>
      <div className='h-100 d-flex flex-column flex-grow-1 justify-content-between'>
        <div className='inside_scroll has_btns'>
          <div className='right_list'>
            {newItemList && newItemList?.map((item, index) =>
              <div key={index} className='position-relative d-flex align-items-center right_item_wrap has_delete has_copy threejs_ball'>
                <Link to="#" className={`right_item w-100 ${activeIndex === index && "active"}`} onClick={() => { fetchSelected(index, item?.colourPaletteId) }} >
                  <span className='me-2 ball_shadow'>
                    {/* <MdPersonOutline size={20} /> */}
                    <RotatingCube elements={item?.filePath} roughnessVal={item?.roughness} colorVal={item?.hex} metalNessVal={item?.metalness} bumpiness={item?.bumpiness} textureVal={item?.texture} />
                  </span>
                  <div className="right_title">
                    <span className="text-truncate">{item.paletteName}</span>
                    <span className="text-truncate d-block text-small">{item.description}</span>
                  </div>
                </Link>
                <span className='position-absolute action_btns'>
                  <Link to="#" onClick={() => { fetchSelected(index, item?.colourPaletteId); setShowDeleteModal(true) }} > <BiTrashAlt className='text-muted' size={20} /> </Link>
                  <Link to="#" onClick={() => copyServices(item?.colourPaletteId)}> <MdContentCopy className='text-muted' size={18} /> </Link>
                </span>
              </div>
            )}
          </div>
        </div>

        <Modal isOpen={showDeleteModal} toggle={() => setShowDeleteModal(false)}>
          <ModalHeader >
            Delete Ancillary
          </ModalHeader>
          <ModalBody>
            Are you sure you want delete <strong>{getSingleColor?.paletteName}</strong> ?
          </ModalBody>
          <ModalFooter>
            <Button color="outline-danger" size="md" onClick={() => deletePaletteServices()}>Delete</Button>
            <Button onClick={() => setShowDeleteModal(false)} color="primary" size="md">Cancel</Button>
          </ModalFooter>
        </Modal>

        <div className='d-flex gap-2 gap-xl-3 main_buttons'>
          <Button
            color="primary"
            size="md"
            className='px-4'
            onClick={() => addNameServices(`Color ${newItemList.length + 1}`)}

          >
            <BiPlus size={20} className='me-1' />
            New
          </Button>

          <Button
            color=""
            size="md"
            className='border-0 text-primary px-0'
            onClick={() => handleItemUp()}
            disabled={activeIndex === 0 || newItemList.length === 0}
          >
            <MdArrowUpward size={22} className='me-1' />
            Up
          </Button>
          <Button
            color=""
            size="md"
            className='border-0 text-primary px-0'
            onClick={() => handleItemDown()}
            disabled={activeIndex === newItemList.length - 1 || newItemList.length === 0}
          >
            <MdArrowDownward size={22} className='me-1' />
            Down
          </Button>
        </div>
      </div>
    </>
  )
}

export default ColoursRight