import React from 'react';

function SeqNumberRefs({ profileRef, glassRef }) {

  const getTransformValue = (name) => {
    if (name.includes('FrameLeft')) {
      return 'translate(20px, -20px)';
    } else if (name.includes('FrameRight')) {
      return 'translate(-10px, -20px)';
    } else if (name.includes('FrameTop') || name.includes('FrameBottom')) {
      return 'translateX(-30px)';
    } else if (name.includes('BarLeft')) {
      return 'translate(-5px, 0)';
    } else if (name.includes('BarRight')) {
      return 'translate(20px, 0)';
    } else if (name.includes('BarTop')) {
      return 'translate(0, 20px)';
    } else if (name.includes('BarBottom')) {
      return 'translate(0, -5px)';
    } else if (name.includes('sill')) {
      return 'translate(0, 10px)';
    } return 'translateX(0px)';
  };

  return (
    <>
      {profileRef?.current && profileRef?.current?.length > 0 && profileRef.current.map((item, index) => {
        const backgroundColor = item.name.includes('Frame')
          ? '#db2525'
          : item.name.includes('Bar')
            ? '#0d68db'  // #254cff // #0d68db
            : item.name.includes('sill')
              ? '#0cc300' // 24cd19
              : '#5029e9';

        const transformValue = getTransformValue(item.name);

        return (
          <div id={`overlayLines${index}`} key={index} className="ref_btn_wrap">
            <div
              className="sq_no_ref"
              style={{
                position: 'absolute',
                left: item.overlayX,
                top: item.overlayY,
                background: backgroundColor,
                transform: transformValue,
              }}
            >
              <span>{index + 1}</span>
            </div>
          </div>
        );
      })}

      {glassRef?.current && glassRef?.current?.length > 0 && glassRef.current.map((item, index) => {

        return (
          <div id={`overlayLines${index}`} key={index} className="ref_btn_wrap">
            <div
              className="sq_no_ref type_2"
              style={{
                position: 'absolute',
                left: item.overlayX,
                top: item.overlayY,
              }}
            >
              <span>{index + 1}</span>
            </div>
          </div>
        );
      })}
    </>
  );
}

export default SeqNumberRefs;
