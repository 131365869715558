import axios from "axios";
import { servicePath } from "../utility/data";

// get all right menu item list based on page/type
export const getRightMenuList = (accessToken, masterId, sysProductId) => {

    const formData = new FormData()
    formData.append('StyleMasterId', masterId);
    formData.append('SysProductId', sysProductId);

    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'multipart/form-data'
        }
    };

    return axios
        .post(`${servicePath}/Hardware/GetAllHardwareStyle`, formData, config)
        .then((res) => {
            return res;
        })
        .catch((error) => {
            return error;
        });
}

// get details of selected item in right menu
export const getItemDetails = (accessToken, id) => {

    const formData = new FormData();
    formData.append("Id", id);

    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'multipart/form-data'
        }
    };

    return axios
        .post(`${servicePath}/Hardware/GetHardwareStyle`, formData, config)
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
};

// delete selected item
export const deleteRightItem = (accessToken, id) => {

    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    const formData = new FormData()
    formData.append("Id", id)

    return axios
        .post(`${servicePath}/Hardware/deleteHardwareStyle`, formData, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
}

// update order of selected item
export const updateOrder = (accessToken, id, styleMasterId, direction) => {
    console.log(id, styleMasterId, direction)

    var requestBody = {
        "id": id,
        "sequenceNo": 0,
        "typeText": styleMasterId,
        "type": direction
    };

    const headers = {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
    }

    return axios
        .post(`${servicePath}/Hardware/UpdateHardwareStyleOrder`, requestBody, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
}

// update details of selected item
export const updateItemDetails = (accessToken, id, data, masterId) => {

    const headers = {
        'accept': 'text/plain',
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'multipart/form-data'
    }

    const formData = new FormData();

    formData.append('StyleMasterId', masterId);

    formData.append("Id", id || '');
    formData.append('Model', data.model);
    formData.append('Name', data.name);
    formData.append('Description', data.description);
    formData.append('ColourCollection', data.colourCollection);
    formData.append('DefaultSide', data.defaultSide);
    formData.append('EscutcheonStyle', data.escutcheonStyle);
    formData.append('InternalStyle', data.internalStyle);
    formData.append('Length', data.length);

    formData.append('FilePath', data.filePath);
    formData.append('ProductCode', data.productCode);
    formData.append('ImagePath', '');
    formData.append('PriceType', data.priceType)

    return axios
        .post(`${servicePath}/Hardware/AddUpadteHardwareStyle`, formData, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
};

// add new right menu item based on page/type
export const addNewRightItem = (accessToken, data, masterId) => {
    const formData = new FormData();

    formData.append('StyleMasterId', masterId);

    formData.append("Id", '');
    formData.append('Model', 0);
    formData.append('Name', data);
    formData.append('Description', '');
    formData.append('ColourCollection', 0);
    formData.append('DefaultSide', '');
    formData.append('EscutcheonStyle', '');
    formData.append('InternalStyle', '');
    formData.append('Length', '');

    formData.append('FilePath', '');
    formData.append('ProductCode', '');

    const headers = {
        'accept': 'text/plain',
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'multipart/form-data'
    }

    return axios
        .post(`${servicePath}/Hardware/AddUpadteHardwareStyle`, formData, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
};