import * as THREE from 'three';
import { GLTFLoader } from 'three/addons/loaders/GLTFLoader.js';
import { rgbStringToHex, setInitialColors } from '../../utility/helper';
import { useDesigner } from '../../context/designerContext';
import { servicePath } from '../../utility/data';



const useFrameModifier = () => {
  const { internalRAL, internalColor, externalRAL, externalColor, customModelData } = useDesigner()

  const changeAddedFrameAngle = (bayPost, selectedFrame, angle, isTop, isLeft, gltfModel, frameStyleTop) => {

    const boundingBox = new THREE.Box3().setFromObject(gltfModel);

    const width = boundingBox.max.x - boundingBox.min.x;
    const widthZ = boundingBox.max.z - boundingBox.min.z;
    const height = boundingBox.max.y - boundingBox.min.y;

    if (angle === "" || angle === undefined || angle === null || angle < 90) {
      angle = 90
    }

    selectedFrame.rotation.y = (180 - angle) * (Math.PI / 180);
    const boundingBoxClone = new THREE.Box3().setFromObject(selectedFrame);
    const xFinalIncrement = selectedFrame.position.x - boundingBoxClone.min.x;
    const zFinalIncrement = selectedFrame.position.z - boundingBoxClone.min.z;
    const yHeight = boundingBoxClone.max.y - boundingBoxClone.min.y;

    const m = (0.11 - 0.04) / (180 - 90)
    const b = 0.04
    const offset = m * (angle - 90) + b


    if (isTop && isTop !== "bottom") {
      selectedFrame.rotation.x = Math.PI / 2;
      selectedFrame.rotation.y = 0;
      selectedFrame.rotation.z = Math.PI;
      let boundingBox6;
      if (bayPost && !bayPost.includes(undefined)) {
        boundingBox6 = new THREE.Box3().setFromObject(bayPost);
      } else {
        boundingBox6 = new THREE.Box3().setFromObject(frameStyleTop.current);
      }
      selectedFrame.rotation.x = (angle) * (Math.PI / 180);
      const boundingBoxClone = new THREE.Box3().setFromObject(selectedFrame);
      const xFinalIncrement = selectedFrame.position.x - boundingBoxClone.min.x;
      const yFinalIncrement = selectedFrame.position.y - boundingBoxClone.min.y;

      const zFinalIncrement = selectedFrame.position.z - boundingBoxClone.min.z;
      const yHeight = boundingBoxClone.max.y - boundingBoxClone.min.y;
      const zWidth = boundingBoxClone.max.z - boundingBoxClone.min.z;



      if (bayPost && !bayPost.includes(undefined)) {
        if (angle === 90) {
          selectedFrame.position.set(0, boundingBox.max.y, ((boundingBox6.min.z - height / 2)));
        } else {
          selectedFrame.position.set(0, bayPost.position.y + yFinalIncrement, boundingBox6.max.z - zFinalIncrement);
        }
      } else {
        if (angle === 90) {
          selectedFrame.position.set(0, boundingBox6.min.y + yFinalIncrement, ((boundingBox6.min.z - height / 2) + 0.1));
          const tiltAngle = THREE.MathUtils.degToRad(100)
          selectedFrame.rotation.x = tiltAngle

        } else {
          selectedFrame.position.set(0, frameStyleTop.current.position.y + yFinalIncrement, boundingBox6.max.z - zFinalIncrement);

        }
      }

      selectedFrame.name = " topPerpendicular"

    } else if (isTop === "bottom") {
      selectedFrame.rotation.x = Math.PI / 2;
      selectedFrame.rotation.y = 0;
      selectedFrame.rotation.z = Math.PI;
      const boundingBox6 = new THREE.Box3().setFromObject(bayPost);

      if (angle === 90) {
        selectedFrame.position.set(0, -boundingBox.max.y, ((boundingBox6.min.z - height / 2)));
      } else {
        selectedFrame.position.set(0, -boundingBox.max.y + 0.05, boundingBox6.min.z - yHeight / 2);
      }
      selectedFrame.name = " bottomPerpendicular"
    }
    else {
      if (!isLeft) {

        const boundingBox6 = new THREE.Box3().setFromObject(bayPost);
        if (angle === 90) {
          selectedFrame.position.set(boundingBox6.min.x + widthZ / 2, 0, boundingBox6.min.z - width / 2);
        } else {
          selectedFrame.position.set(boundingBox6.max.x + xFinalIncrement - widthZ / 2 - 0.05, 0, boundingBox6.min.z - zFinalIncrement + offset);
        }



      } else {

        selectedFrame.rotation.y = (angle) * (Math.PI / 180);
        const boundingBoxClone = new THREE.Box3().setFromObject(selectedFrame);
        const xFinalIncrement = selectedFrame.position.x - boundingBoxClone.min.x;
        const zFinalIncrement = selectedFrame.position.z - boundingBoxClone.min.z;
        const boundingBox6 = new THREE.Box3().setFromObject(bayPost);
        if (angle === 90) {
          selectedFrame.position.set(boundingBox6.min.x + widthZ / 2, 0, boundingBox6.min.z - width / 2);
        } else {
          selectedFrame.position.set(boundingBox6.max.x - xFinalIncrement - widthZ / 2 + 0.05, 0, boundingBox6.min.z - zFinalIncrement + offset);
        }


      }
    }
  }

  const updateAddedFrame = (additionalFrame, frameModelLeft, frameModelRight, frameModelTop, frameModelBottom, sceneRef, isSidePerpendicular = false, isTopPerpendicular = false, setInternal, setExternal, externalFrameRef, internalFrameRef) => {

    let glass;
    let frameTop, frameRight, frameLeft, frameBottom, spaceBarTop, spaceBarBottom;

    console.log(frameModelLeft, frameModelRight, frameModelTop, frameModelBottom, isTopPerpendicular, isSidePerpendicular, 103);

    additionalFrame.traverse((child) => {

      if (child.name.includes("Glass")) {
        glass = child;

      }
      if (child.name.includes("Frame")) {
        if (child.name.includes("Top")) {
          frameTop = child;
        }

        if (child.name.includes("Right")) {
          frameRight = child;
          var boxHelper = new THREE.BoxHelper(child, 0xffff00);
          sceneRef.current.add(boxHelper)
        }

        if (child.name.includes("Left")) {
          frameLeft = child;
        }

        if (child.name.includes("Bottom")) {
          frameBottom = child;
        }

      }

      if (child.name.includes("SpaceBarTop")) {
        spaceBarTop = child;
      }

      if (child.name.includes("SpaceBarBottom")) {
        spaceBarBottom = child;
      }

    })


    let base, bB, baseMax, bB2, addedNewFrame, width, height, trackLeft, trackRight;
    const loader = new GLTFLoader();

    let boundingBox, modelWidth, boundingBox2, modelWidth2, scaleFactor, worldPosition, sourceQuaternion

    console.log(frameModelRight, frameModelLeft, frameBottom, "167");


    // Load a GLTF model
    if (frameModelRight) {
      loader.load(frameModelRight?.url, (gltf) => {
        console.log(gltf.scene, "173");

        const frameClone0 = gltf.scene.clone()

        boundingBox = new THREE.Box3().setFromObject(frameRight);
        if (isTopPerpendicular) {
          modelWidth = boundingBox.max.z - boundingBox.min.z;
        } else {
          modelWidth = boundingBox.max.y - boundingBox.min.y;
        }

        boundingBox2 = new THREE.Box3().setFromObject(frameClone0);
        modelWidth2 = boundingBox2.max.x - boundingBox2.min.x;

        scaleFactor = modelWidth / modelWidth2;

        let worldPositionR = new THREE.Vector3();
        frameRight.getWorldPosition(worldPositionR);
        frameClone0.position.copy(worldPositionR);

        sourceQuaternion = new THREE.Quaternion();
        frameRight.getWorldQuaternion(sourceQuaternion);
        frameClone0.quaternion.copy(sourceQuaternion);


        frameClone0.scale.x = scaleFactor;

        sceneRef.current.add(frameClone0);

        frameClone0.traverse((child) => {
          if (child.isMesh && !child.name.includes("External")) {
            internalFrameRef.current.push(child);
          } else if (child.isMesh && child.name.includes("External")) {
            externalFrameRef.current.push(child)
          }
        })

        additionalFrame.remove(frameRight);

        sceneRef.current.remove(frameRight);

        // Save the world position, rotation, and scale
        const worldPosition = new THREE.Vector3();
        const worldQuaternion = new THREE.Quaternion();
        const worldScale = new THREE.Vector3();

        frameClone0.updateMatrixWorld(true);
        frameClone0.getWorldPosition(worldPosition);
        frameClone0.getWorldQuaternion(worldQuaternion);
        frameClone0.getWorldScale(worldScale);

        // Add the frameClone0 to the new parent
        additionalFrame.add(frameClone0);

        // Reapply the saved world position, rotation, and scale
        frameClone0.position.copy(worldPosition);
        frameClone0.quaternion.copy(worldQuaternion);
        frameClone0.scale.copy(worldScale);
        
        // Update the matrix world after changes
        frameClone0.updateMatrixWorld(true);
        console.log(frameClone0 , "233");

      });
    }


    if (frameModelLeft) {
      loader.load(frameModelLeft.url, (gltf) => {
        const frameClone = gltf.scene.clone()

        boundingBox = new THREE.Box3().setFromObject(frameLeft);
        if (isTopPerpendicular) {
          modelWidth = boundingBox.max.z - boundingBox.min.z;
        } else {
          modelWidth = boundingBox.max.y - boundingBox.min.y;
        }

        boundingBox2 = new THREE.Box3().setFromObject(frameClone);
        modelWidth2 = boundingBox2.max.x - boundingBox2.min.x;

        scaleFactor = modelWidth / modelWidth2;

        worldPosition = new THREE.Vector3();
        frameLeft.getWorldPosition(worldPosition);
        frameClone.position.copy(worldPosition);

        sourceQuaternion = new THREE.Quaternion();
        frameLeft.getWorldQuaternion(sourceQuaternion);
        frameClone.quaternion.copy(sourceQuaternion);

        frameClone.scale.x = scaleFactor;


        sceneRef.current.add(frameClone);

        frameClone.traverse((child) => {
          if (child.isMesh && !child.name.includes("External")) {
            console.log(child, "219");
            internalFrameRef.current.push(child);
          } else if (child.isMesh && child.name.includes("External")) {
            console.log(222);
            externalFrameRef.current.push(child)
          }
        })

        additionalFrame.remove(frameLeft);
        sceneRef.current.remove(frameLeft);
        // Save the world position, rotation, and scale
        const worldPositionLeft = new THREE.Vector3();
        const worldQuaternion = new THREE.Quaternion();
        const worldScale = new THREE.Vector3();

        // var boxHelper = new THREE.BoxHelper(frameClone, 0xffff00);
        // sceneRef.current.add(boxHelper)

        frameClone.updateMatrixWorld(true);
        frameClone.getWorldPosition(worldPositionLeft);
        frameClone.getWorldQuaternion(worldQuaternion);
        frameClone.getWorldScale(worldScale);

        // Add the frameClone0 to the new parent
        additionalFrame.add(frameClone);

        // Reapply the saved world position, rotation, and scale
        frameClone.position.copy(worldPositionLeft);
        frameClone.quaternion.copy(worldQuaternion);
        frameClone.scale.copy(worldScale);

        // Update the matrix world after changes
        frameClone.updateMatrixWorld(true);

      });
    }


    if (frameModelTop) {
      loader.load(frameModelTop.url, (gltf) => {

        const frameClone2 = gltf.scene.clone()

        boundingBox = new THREE.Box3().setFromObject(frameTop);
        if (isSidePerpendicular) {
          modelWidth = boundingBox.max.z - boundingBox.min.z;
        } else {
          modelWidth = boundingBox.max.x - boundingBox.min.x;
        }

        boundingBox2 = new THREE.Box3().setFromObject(frameClone2);
        modelWidth2 = boundingBox2.max.x - boundingBox2.min.x;

        scaleFactor = modelWidth / modelWidth2;

        worldPosition = new THREE.Vector3();
        frameTop.getWorldPosition(worldPosition);
        frameClone2.position.copy(worldPosition);

        sourceQuaternion = new THREE.Quaternion();
        frameTop.getWorldQuaternion(sourceQuaternion);
        frameClone2.quaternion.copy(sourceQuaternion);

        frameClone2.scale.x = scaleFactor;



        sceneRef.current.add(frameClone2);

        frameClone2.traverse((child) => {
          if (child.isMesh && !child.name.includes("External")) {
            internalFrameRef.current.push(child);
          } else if (child.isMesh && child.name.includes("External")) {
            externalFrameRef.current.push(child)
          }
        })

        additionalFrame.remove(frameTop);
        sceneRef.current.remove(frameTop);
        // Save the world position, rotation, and scale
        const worldPositionTop = new THREE.Vector3();
        const worldQuaternion = new THREE.Quaternion();
        const worldScale = new THREE.Vector3();

        frameClone2.updateMatrixWorld(true);
        frameClone2.getWorldPosition(worldPositionTop);
        frameClone2.getWorldQuaternion(worldQuaternion);
        frameClone2.getWorldScale(worldScale);

        // Add the frameClone0 to the new parent
        additionalFrame.add(frameClone2);

        // Reapply the saved world position, rotation, and scale
        frameClone2.position.copy(worldPositionTop);
        frameClone2.quaternion.copy(worldQuaternion);
        frameClone2.scale.copy(worldScale);

        // var boxHelper = new THREE.BoxHelper(frameClone2, 0xffff00);
        // sceneRef.current.add(boxHelper)

        // Update the matrix world after changes
        frameClone2.updateMatrixWorld(true);

      });
    }

    if (frameModelBottom) {
      loader.load(frameModelBottom.url, (gltf) => {
        const frameClone3 = gltf.scene.clone()

        boundingBox = new THREE.Box3().setFromObject(frameBottom);
        if (isSidePerpendicular) {
          modelWidth = boundingBox.max.z - boundingBox.min.z;
        } else {
          modelWidth = boundingBox.max.x - boundingBox.min.x;
        }

        boundingBox2 = new THREE.Box3().setFromObject(frameClone3);
        modelWidth2 = boundingBox2.max.x - boundingBox2.min.x;

        scaleFactor = modelWidth / modelWidth2;

        const worldPositionB = new THREE.Vector3();
        frameBottom.getWorldPosition(worldPositionB);
        frameClone3.position.copy(worldPositionB);

        sourceQuaternion = new THREE.Quaternion();
        frameBottom.getWorldQuaternion(sourceQuaternion);
        frameClone3.quaternion.copy(sourceQuaternion);

        frameClone3.scale.x = scaleFactor;

        sceneRef.current.add(frameClone3);

        frameClone3.traverse((child) => {
          if (child.isMesh && !child.name.includes("External")) {
            internalFrameRef.current.push(child);
          } else if (child.isMesh && child.name.includes("External")) {
            externalFrameRef.current.push(child)
          }
        })

        additionalFrame.remove(frameBottom);
        sceneRef.current.remove(frameBottom);

        // Save the world position, rotation, and scale
        const worldPosition = new THREE.Vector3();
        const worldQuaternion = new THREE.Quaternion();
        const worldScale = new THREE.Vector3();

        frameClone3.updateMatrixWorld(true);
        frameClone3.getWorldPosition(worldPosition);
        frameClone3.getWorldQuaternion(worldQuaternion);
        frameClone3.getWorldScale(worldScale);

        // Add the frameClone0 to the new parent
        additionalFrame.add(frameClone3);
        // var boxHelper = new THREE.BoxHelper(frameClone3, 0xffff00);
        // sceneRef.current.add(boxHelper)

        // Reapply the saved world position, rotation, and scale
        frameClone3.position.copy(worldPosition);
        frameClone3.quaternion.copy(worldQuaternion);
        frameClone3.scale.copy(worldScale);

        // Update the matrix world after changes
        frameClone3.updateMatrixWorld(true);

      });
    }
    setTimeout(() => {
      setInitialColors(rgbStringToHex, internalRAL, internalColor, externalRAL, externalColor, customModelData, setInternal, setExternal)
    }, 100);


  }


  const saveAdditionalFrameData = (setCustomModelData, addedFrameData, couplerData, direction, allFrameCollection, allStyleCollection, clonedModel, angle, type, isEvesFrame) => {
    const findCollection = (id) => allFrameCollection?.find(item => item?.id == id) || {};
    const findStyle = (id) => allStyleCollection?.find(item => item?.id === id) || {};

    const commonObj = {
      id: addedFrameData?.frameStyle?.id,
      name: addedFrameData?.frameStyle?.name,
      frameProfile: addedFrameData?.frameProfileData,
      height: addedFrameData?.layoutFrame?.height,
      width: addedFrameData?.layoutFrame?.width,
      glazingData: addedFrameData?.glazingData,
      imagePath: "",
      collectionId: findCollection(addedFrameData?.collectionId)?.id,
      collectionName: findCollection(addedFrameData?.collectionId)?.name,
      position: clonedModel?.position,
      price: findStyle(addedFrameData?.frameStyle?.id)?.price,
      installationPrice: findStyle(addedFrameData?.frameStyle?.id)?.installationPrice,
    };

    if (type === "parallel") {
      const frameData = {
        frameDirection: direction,
        coupler: isEvesFrame ? null : couplerData,
      };
      setCustomModelData((prevModelData) => ({
        ...prevModelData,
        frame: {
          ...prevModelData.frame,
          framesAndCoupler: [
            ...prevModelData.frame.framesAndCoupler || [],
            frameData
          ]
        },
        addedFrames: [
          ...prevModelData?.addedFrames || [],
          { ...commonObj, couplerData: isEvesFrame ? undefined : frameData }
        ]
      }));
    } else {
      const bayPostSave = {
        frameDirection: direction,
        bayPost: isEvesFrame ? null : `${servicePath}/ThreeJSModel/Glb/${couplerData?.customePath}`,
        angle: isEvesFrame ? null : angle,
        frameType: "Baypost",
        profileTypePrice: isEvesFrame ? null : couplerData?.profileTypePrice,
        additionalArticles: isEvesFrame ? null : couplerData?.additionalArticles,
        internalPaintSurfaceArea: isEvesFrame ? null : couplerData?.internalPaintSurfaceArea,
        externalPaintSurfaceArea: isEvesFrame ? null : couplerData?.externalPaintSurfaceArea,
        price: isEvesFrame ? null : couplerData?.price,
        height: isEvesFrame ? null : couplerData?.height,
        width: isEvesFrame ? null : couplerData?.width,
        name: isEvesFrame ? null : couplerData?.name,
        id: couplerData?.id,
      };

      setCustomModelData((prevModelData) => ({
        ...prevModelData,
        frame: {
          ...prevModelData.frame,
          bayPost: isEvesFrame ? prevModelData.frame.bayPost : [
            ...prevModelData.frame.bayPost || [],
            bayPostSave
          ]
        },
        addedFrames: [
          ...prevModelData?.addedFrames || [],
          { ...commonObj, baypostData: isEvesFrame ? undefined : bayPostSave }
        ]
      }));
    }
  };



  return { changeAddedFrameAngle, updateAddedFrame, saveAdditionalFrameData }

}



export default useFrameModifier