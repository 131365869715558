import React, { useState, useEffect } from 'react'
import {
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
} from 'reactstrap';
import { MdMoreHoriz, MdOutlineSell } from 'react-icons/md';
import SystemSashGeneral from '../../../../../../components/SystemSashGeneral';
import SystemSashGlazing from '../../../../../../components/SystemSashGlazing';
import SystemSashProfile from '../../../../../../components/SystemSashProfile';
import SystemSashHanging from '../../../../../../components/SystemSashHanging';
import SystemSashSubProducts from '../../../../../../components/SystemSashSubProducts';
import SystemSashHardware from '../../../../../../components/SystemSashHardware';
import SystemSashAccessories from '../../../../../../components/SystemSashAccessories';

import { useRightContext } from '../../../../../../context/rightMenuContext';
import { Link } from 'react-router-dom';
import Editprices from '../../../../../../components/Editprices';

const SashProductsLeft = ({ navigate, setMessageType, setMessage, allSashProductAccessories, getAllSashProductAccessoriesServices, sashId, setLoading, pageName, accessToken, activeTab, setActiveTab, selectItemDetails, updateItemDetailsServices, handleFormReset, usedInProductsList, deleteSysFrameSashInUsedService, removePanelProdInUsedService }) => {

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [generalTabData, setGeneralTabData] = useState([]);
    const [glazingTabData, setGlazingTabData] = useState([]);
    const [profileTabData, setProfileTabData] = useState([]);
    const [subProductTabData, setSubProductTabData] = useState([]);
    const [hardwareTabData, setHardwareTabData] = useState([]);
    const [accessoriesTabData, setAccessoriesTabData] = useState([]);

    const toggleTabDropdown = () => setDropdownOpen((prevState) => !prevState);

    const handleActiveTab = (tab) => {
        setActiveTab(tab)
    }

    const { timestamp, selectedRightItem, selected, setSelected } = useRightContext()

    // setting all the value for prefilled inputs
    useEffect(() => {

        if (activeTab === "General") {
            setGeneralTabData(selectItemDetails)

            setSelected(selectItemDetails?.name)
        }

        if (activeTab === "Glazing") {
            setGlazingTabData(selectItemDetails)
        }

        if (activeTab === "Profiles") {
            setProfileTabData(selectItemDetails)
        }

        if (activeTab === "Sub products") {
            setSubProductTabData(selectItemDetails)
        }

        if (activeTab === "Hardware") {
            setHardwareTabData(selectItemDetails)
        }

        if (activeTab === "Accessories") {
            setAccessoriesTabData(selectItemDetails)
        }
    }, [selectItemDetails])

    // const editNavData = { url: `/pricing/sash-products/${sashId}` }
    const editNavData = { url: `/pricing/systems/sash`, stateValue: sashId }


    return (
        <div className='d-flex flex-column h-100'>

            <div className='d-flex align-items-center mb-3 flex-wrap gap-2 left_header'>
                {selected && (
                    <h5 className="mb-0 text-wrap text-break main_title me-4">{selected}</h5>
                )}

                <Editprices data={editNavData} hasState={true} />
            </div>

            <Nav tabs className="details_header border-0 nav-tabs-custom">
                <NavItem>
                    <NavLink
                        className={`${activeTab === "General" && 'active'}`}
                        onClick={() => { handleActiveTab("General") }}
                    >
                        General
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        className={`${activeTab === "Glazing" && 'active'}`}
                        onClick={() => { handleActiveTab("Glazing") }}
                    >
                        Glazing
                    </NavLink>
                </NavItem>

                <NavItem>
                    <NavLink
                        className={`${activeTab === "Profiles" && 'active'}`}
                        onClick={() => { handleActiveTab("Profiles") }}
                    >
                        Profiles
                    </NavLink>
                </NavItem>

                <NavItem className='d-none d-sm-block d-lg-block'>
                    <NavLink
                        className={`${activeTab === "Sub products" && 'active'}`}
                        onClick={() => handleActiveTab("Sub products")}
                    >
                        Sub products
                    </NavLink>
                </NavItem>

                <NavItem className='d-none d-sm-block d-md-none d-lg-block'>
                    <NavLink
                        className={`${activeTab === "Hardware" && 'active'}`}
                        onClick={() => { handleActiveTab("Hardware") }}
                    >
                        Hardware
                    </NavLink>
                </NavItem>
                <NavItem className='d-none d-lg-block'>
                    <NavLink
                        className={`${activeTab === "Sash hanging" && 'active'}`}
                        onClick={() => { handleActiveTab("Sash hanging") }}
                    >
                        Sash hanging
                    </NavLink>
                </NavItem>

                {/* <NavItem className='d-none d-xl-block'>
                    <NavLink
                        className={`${activeTab === "Accessories" && 'active'}`}
                        onClick={() => { handleActiveTab("Accessories") }}
                    >
                        Accessories
                    </NavLink>
                </NavItem> */}

                <Dropdown className='d-xl-none' isOpen={dropdownOpen} toggle={toggleTabDropdown}>
                    <DropdownToggle color='none' className='border-0 p-0'>
                        <MdMoreHoriz size={20} />
                    </DropdownToggle>
                    <DropdownMenu>
                        <DropdownItem className='d-sm-none' href="#Profiles" onClick={() => handleActiveTab("Sub products")}>
                            Sub products
                        </DropdownItem>
                        <DropdownItem className='d-sm-none d-md-block d-lg-none' href="#Hardware" onClick={() => handleActiveTab("Hardware")}>
                            Hardware
                        </DropdownItem>
                        <DropdownItem className='d-lg-none' href="#Sash hanging" onClick={() => handleActiveTab("Sash hanging")}>
                            Sash hanging
                        </DropdownItem>
                        {/* <DropdownItem className='d-xl-none' href="#Accessories" onClick={() => handleActiveTab("Accessories")}>
                            Accessories
                        </DropdownItem> */}
                    </DropdownMenu>
                </Dropdown>
            </Nav>

            <TabContent activeTab={activeTab} className={`flex-grow-1 d-flex`}>
                <TabPane tabId="General" className={`flex-grow-1 w-100 ${selectedRightItem?.isSelf ? "" : "not_allowed"}`}>
                    {activeTab === "General" && (
                        <SystemSashGeneral pageName={pageName} activeTab={activeTab} accessToken={accessToken} isEditable={selectedRightItem?.isSelf} handleFormReset={handleFormReset} selectItemDetails={generalTabData} updateItemDetailsServices={updateItemDetailsServices} usedInProductsList={usedInProductsList} deleteSysFrameSashInUsedService={deleteSysFrameSashInUsedService} removePanelProdInUsedService={removePanelProdInUsedService} />
                    )}
                </TabPane>
                <TabPane tabId="Glazing" className={`w-100 ${selectedRightItem?.isSelf ? "" : "not_allowed"}`}>
                    {activeTab === "Glazing" && (
                        <SystemSashGlazing pageName={pageName} handleFormReset={handleFormReset}  activeTab={activeTab} accessToken={accessToken} isEditable={selectedRightItem?.isSelf} selectItemDetails={glazingTabData} updateItemDetailsServices={updateItemDetailsServices} />
                    )}
                </TabPane>
                <TabPane tabId="Profiles" className='w-100'>
                    {activeTab === "Profiles" && (
                        <SystemSashProfile sashId={sashId} setLoading={setLoading} pageName={pageName} handleFormReset={handleFormReset} activeTab={activeTab} accessToken={accessToken} isEditable={selectedRightItem?.isSelf} selectItemDetails={profileTabData} updateItemDetailsServices={updateItemDetailsServices} />
                    )}
                </TabPane>
                <TabPane tabId="Sub products" className='w-100'>
                    {activeTab === "Sub products" && (
                        <SystemSashSubProducts sashId={sashId} setLoading={setLoading} pageName={pageName} handleFormReset={handleFormReset}  activeTab={activeTab} accessToken={accessToken} isEditable={selectedRightItem?.isSelf} selectItemDetails={subProductTabData} updateItemDetailsServices={updateItemDetailsServices} />
                    )}
                </TabPane>
                <TabPane tabId="Hardware" className={`w-100 ${selectedRightItem?.isSelf ? "" : "not_allowed"}`}>
                    {activeTab === "Hardware" && (
                        <SystemSashHardware pageName={pageName} handleFormReset={handleFormReset} activeTab={activeTab} accessToken={accessToken} isEditable={selectedRightItem?.isSelf} selectItemDetails={hardwareTabData} updateItemDetailsServices={updateItemDetailsServices} />
                    )}
                </TabPane>
                <TabPane tabId="Sash hanging" className={`w-100 ${selectedRightItem?.isSelf ? "" : "not_allowe"}`}>
                    {activeTab === "Sash hanging" && (
                        <SystemSashHanging setLoading={setLoading} sashId={sashId} productId={timestamp} activeTab={activeTab} accessToken={accessToken} isEditable={selectedRightItem?.isSelf} />
                    )}
                </TabPane>
                <TabPane tabId="Accessories" className={`w-100 ${selectedRightItem?.isSelf ? "" : "not_allowed"}`}>
                    {activeTab === "Accessories" && (
                        <SystemSashAccessories navigate={navigate} setMessageType={setMessageType} setMessage={setMessage} setLoading={setLoading} allSashProductAccessories={allSashProductAccessories} getAllSashProductAccessoriesServices={getAllSashProductAccessoriesServices} pageName={pageName} handleFormReset={handleFormReset}activeTab={activeTab} accessToken={accessToken} isEditable={selectedRightItem?.isSelf} selectItemDetails={accessoriesTabData} updateItemDetailsServices={updateItemDetailsServices} />
                    )}
                </TabPane>
            </TabContent>
        </div>
    )
}

export default SashProductsLeft