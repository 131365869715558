import React from 'react';
import ItemQuantity from '../../../../components/Pricing/ItemQuantity';

const ItemListTable = ({ type, dataArray, generalCss, stylesHeader, frame, currency }) => {

    return (
        <>
            {frame?.printFrameProperty?.includes(type) && dataArray?.length > 0 && (
                <>
                    {dataArray.map((o, index) => (
                        <tr key={index} className={`${o?.name == '' ? '' : ''}`}>
                            <td style={frame?.printFrameProperty?.length !== (index + 1) ? { ...stylesHeader.table.td, ...stylesHeader.table.gridLineBottom, ...generalCss.mw180 } : { ...stylesHeader.table.td, ...stylesHeader.table.gridLineBottom, ...stylesHeader.table.borderNone }}>
                                {/* {o?.frameType} {o?.count > 1 && `X${o?.count}`} */}
                                <ItemQuantity name={o?.frameType} quantity={o.count} />
                            </td>
                            <td style={frame?.printFrameProperty?.length !== (index + 1) ? { ...stylesHeader.table.td, ...stylesHeader.table.gridLineBottom, ...stylesHeader.table.gridLineLeft } : { ...stylesHeader.table.td, ...stylesHeader.table.gridLineBottom, ...stylesHeader.table.borderNone, ...stylesHeader.table.gridLineLeft }}>
                                {o?.name}
                            </td>
                            {frame.itemised && (
                                <td style={frame?.printFrameProperty?.length !== (index + 1) ? { ...stylesHeader.table.td, ...stylesHeader.table.gridLineBottom, ...generalCss.mw100, ...stylesHeader.table.textEnd } : { ...stylesHeader.table.td, ...stylesHeader.table.gridLineBottom, ...stylesHeader.table.borderNone, ...stylesHeader.table.textEnd }}>
                                    {currency}{o?.totalPrice?.toFixed(2)}
                                </td>
                            )}
                        </tr>
                    ))}
                </>
            )}
        </>
    );
}


export default ItemListTable