import { useState } from "react";
import { useContext } from "react";
import { createContext } from "react";

const PanelDesignerContext = createContext();

const usePanelDesigner = () => {
  const value = useContext(PanelDesignerContext);
  return value;
};

const CustomPanelDesignerContext = ({ children }) => {
  const [horizontalPos, setHorizontalPos] = useState(0);
  const [verticalPos, setVerticalPos] = useState(0);
  const [currentModel, setCurrentModel] = useState(null);
  const [verticalAlignment, setVerticalAlignment] = useState("");
  const [horizontalAlignment, setHorizontalAlignment] = useState("");
  const [horizontalOrigin, setHorizontalOrigin] = useState("");
  const [verticalOrigin, setVerticalOrigin] = useState("");
  const [modelData, setModelData] = useState();
  const [gotData, setGotData] = useState(false);
  const [isDeletedHardware, setIsDeletedHardware] = useState(false);

  const handleHorizontalPos = (value) => {
    setHorizontalPos(value);
  };

  const handleVerticalPos = (value) => {
    setVerticalPos(value);
  };

  const handleCurrentModel = (model) => {
    setCurrentModel(model);
  };

  const handleVerticalAlignment = (alignment) => {
    setVerticalAlignment(alignment);
  };

  const handleHorizontalAlignment = (alignment) => {
    setHorizontalAlignment(alignment);
  };

  const handleHorizontalOrigin = (alignment) => {
    setHorizontalOrigin(alignment);
  };

  const handleVerticalOrigin = (alignment) => {
    setVerticalOrigin(alignment);
  };

  return (
    <PanelDesignerContext.Provider
      value={{
        isDeletedHardware,
        setIsDeletedHardware,
        horizontalPos,
        verticalPos,
        handleHorizontalPos,
        handleVerticalPos,
        currentModel,
        handleCurrentModel,
        verticalAlignment,
        handleVerticalAlignment,
        horizontalAlignment,
        handleHorizontalAlignment,
        horizontalOrigin,
        handleHorizontalOrigin,
        verticalOrigin,
        handleVerticalOrigin,
        modelData,
        setModelData,
        gotData, 
        setGotData
      }}
    >
      {children}
    </PanelDesignerContext.Provider>
  );
};

export { usePanelDesigner };
export default CustomPanelDesignerContext;
