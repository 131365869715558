import axios from "axios";
import { servicePath } from "../utility/data";

// get all right menu item list based on page/type
export const getRightMenuList = (accessToken, productId) => {

    const formData = new FormData();
    formData.append("SashProductGeneralId", productId);

    let config = {
        method: 'GET',
        maxBodyLength: Infinity,
        headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'multipart/form-data'
        }
    };

    return axios
        .post(`${servicePath}/Product/GetAllSashHanging`, formData, config)
        .then((res) => {
            return res;
        })
        .catch((error) => {
            return error;
        });
}

// get details of selected item in right menu
export const getItemDetails = (accessToken, id) => {
    const formData = new FormData();
    formData.append("Id", id);

    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'multipart/form-data'
        }
    };

    return axios
        .post(`${servicePath}/Product/GetSashHanging`, formData, config)
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
};

// delete selected item
export const deleteRightItem = (accessToken, id) => {
    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    const formData = new FormData()
    formData.append("Id", id)
    return axios
        .post(`${servicePath}/Product/DeleteSashHanging`, formData, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
}

// update order of selected item
export const updateOrder = (accessToken, id, productId, direction) => {

    var requestBody = {
        "id": id,
        "sequenceNo": 0,
        "type": direction,
        "typeText": productId,
    };

    const headers = {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
    }

    return axios
        .post(`${servicePath}/Product/UpdateSysSashHangingOrder`, requestBody, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
}

// update details of selected item
export const updateItemDetails = (accessToken, id, data) => {

    console.log(id, "ss")

    const headers = {
        'accept': 'text/plain',
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'multipart/form-data'
    }

    const formData = new FormData();

    formData.append("Id", id);
    formData.append('Name', data.name);
    formData.append("SashProductMasterId", data.sashProductMasterId);
    formData.append('SashProductGeneralId', data.sashProductGeneralId);

    formData.append('MinimumWidth', data.minimumWidth || 0);
    formData.append('MinimumHeight', data.minimumHeight || 0);
    formData.append('MaximumWidth', data.maximumWidth || 0);
    formData.append('MaximumHeight', data.maximumHeight || 0);
    formData.append('MaximumMSquar', data.maximumMSquar || 0);

    formData.append('Hanging', data.hanging);
    formData.append('Horns', data.horns);
    formData.append('HornLength', data.hornLength);
    formData.append('MirrorLines', data.mirrorLines || false);
    formData.append('DefaultHanging', data.defaultHanging || false);
    formData.append('ApplyFrameColourPercentage', data.applyFrameColourPercentage || false);

    return axios
        .post(`${servicePath}/Product/AddUpdateSashHanging`, formData, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
};

// add new right menu item based on page/type
export const addNewRightItem = (accessToken, data, sashId, productId) => {
    console.log(sashId, productId, "sash hanging add")

    const formData = new FormData();

    formData.append("Id", '');
    formData.append('Name', data);
    formData.append("SashProductMasterId", sashId);
    formData.append('SashProductGeneralId', productId);

    const headers = {
        'accept': 'text/plain',
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'multipart/form-data'
    }

    return axios
        .post(`${servicePath}/Product/AddUpdateSashHanging`, formData, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
};