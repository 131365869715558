import React, { useEffect } from "react";
import InternalLayout from "../../../../../layouts/internalLayout";
import Left from "./left";
import { Oval } from "react-loader-spinner";
import { useMessage } from "../../../../../context/messageContext";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { useRightContext } from "../../../../../context/rightMenuContext";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import { MdKeyboardArrowDown } from "react-icons/md";
import CustomizedButton from "../../../../../components/CustomizedButton";
import NoDataCard from "../../../../../components/NoDataCard";
import { getSashProductsCollection } from "../../../../../services/sysSashCollectionService";

const PricingSashProducts = () => {
  var access_token = localStorage.getItem("access_token");
  const location = useLocation();
  const sashId = location.state ? location.state.data : null

  const [headerDropdown, setHeaderDropdown] = useState(false);
  const [mainDropdownList, setMainDropdownList] = useState([])
  const [frameCollection, setFrameCollection] = useState('')

  const { setMainMenuActive, setSubMenuActive, setSubMenu2Active } = useRightContext()

  const [loading, setLoading] = useState(true);

  const { setMessage, setMessageType } = useMessage();
  useEffect(() => {
    getAllSashService()

    setMainMenuActive("Pricing")
    setSubMenuActive("Systems")
    setSubMenu2Active("")
  }, []);

  useEffect(() => {
    if (mainDropdownList && mainDropdownList.length > 0) {
      if (sashId) {
        const collectionItem = mainDropdownList?.find((p) => p.id == sashId)

        if (collectionItem && collectionItem.id) {
          setFrameCollection(collectionItem)
        }
      } else {
        setFrameCollection(mainDropdownList[0])
      }
    }
  }, [mainDropdownList]);

  const getAllSashService = async () => {

    const res = await getSashProductsCollection(access_token);

    if (res && res && res.status === 200) {
      if (res?.data?.statusCode === 200) {
        if (res?.data?.entity !== null) {
          setMainDropdownList(res?.data?.entity)
        } else {
          setLoading(false)
          setMainDropdownList([])
        }
      }
    }
  }

  const toggleTabDropdown = () => setHeaderDropdown((prevState) => !prevState);

  const handleItemSelect = (value) => {
    setFrameCollection(value)
  }

  const data1 = { url: `/product/systems/sash/list`, stateValue: frameCollection?.id }



  return (
    <>
      {loading && (
        <div className="loader_main full_loader">
          <Oval
            height={50}
            width={50}
            color="#44C8F5"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#44C8F5"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      )}

      {mainDropdownList && mainDropdownList.length > 0 ? (
        <InternalLayout
          pageTitle=""
          layoutType="full"
          leftContent={
            <Left
              token={access_token}
              setMessage={setMessage}
              setMessageType={setMessageType}
              loading={loading}
              sashId={frameCollection?.id}
              pageName={frameCollection?.name}
              setLoading={setLoading}
            />
          }
          rightContent={""}
          filterButton={
            <>
              <Dropdown
                isOpen={headerDropdown}
                toggle={toggleTabDropdown}
              >
                <DropdownToggle color="none" className="border-0 p-0">
                  <div className="d-flex align-items-center">
                    <span className="fw-semibold fs-5">
                      {frameCollection && frameCollection?.name}
                      <MdKeyboardArrowDown className="ms-1" size={20} />
                    </span>
                  </div>
                </DropdownToggle>

                <DropdownMenu className='full_height'>
                  {mainDropdownList?.map((item, index) => {
                    return (
                      <DropdownItem
                        onClick={() => handleItemSelect(item)}
                        key={index}
                        disabled={item?.id == frameCollection?.id}
                        className={`${item?.id == frameCollection?.id ? 'text-primary fw-medium' : ''}`}
                      >
                        {item?.name}
                      </DropdownItem>
                    );
                  })}
                </DropdownMenu>
              </Dropdown>
              <CustomizedButton data={data1} hasState={true} />
            </>
          }
        />
      ) : (
        <NoDataCard text="sash" loading={loading} />
      )}

    </>
  );
};

export default PricingSashProducts;
