import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, FormGroup, Input, InputGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import DataTable from 'react-data-table-component'
import moment from 'moment';
import { BiSort } from "react-icons/bi";
import ReactDatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import { MdKeyboardArrowDown } from "react-icons/md";
import { MdOutlineSearch, MdOutlineFilterAlt, MdOutlineCalendarMonth } from "react-icons/md";
import { statusFilterOptions, dateFilterOptions } from "../../../../utility/data";
import { useEffect } from "react";

const Left = ({ userData, setLoading, allowedProducts, setFilterOptions }) => {

    const navigate = useNavigate()

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [dropdownOpen1, setDropdownOpen1] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [customDateModalOpen, setCustomDateModalOpen] = useState(false);
    const [selectedProjectStatus, setSelectedProjectStatus] = useState("All");
    const [customFilter, setCustomFilter] = useState(false);

    // filters
    const [selectedProjectStatusColor, setSelectedProjectStatusColor] = useState(1);
    const [selectedDateRange, setSelectedDateRange] = useState(dateFilterOptions[3]);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");

    useEffect(() => {
        if (selectedProjectStatusColor || selectedDateRange) {

            // console.log("aadi 123")
            setCustomFilter(false)

            let filters = {
                "filter": true,
                'status': selectedProjectStatusColor,
                "date": selectedDateRange.value,
                'start_date': (selectedDateRange.value === 3 ? moment(selectedDateRange.startDate, 'YYYY-MM-DD').format('YYYY-MM-DD') : ''),
                "end_date": (selectedDateRange.value === 3 ? moment(selectedDateRange.endDate, 'YYYY-MM-DD').format('YYYY-MM-DD') : ''),
            }

            setFilterOptions(filters)
        }
    }, [selectedProjectStatusColor, selectedDateRange, customFilter])

    const handleDateRangeSelect = (dateRange) => {

        const selectedRange = dateFilterOptions.find(item => item.name === dateRange);

        if (selectedRange.name === "Custom") {
            setStartDate('')
            setEndDate('')

            setCustomDateModalOpen(true);
        } else {
            setSelectedDateRange(selectedRange);
        }
    };

    const handleStartDateChange = (date) => {
        setStartDate(date);
    };

    const handleEndDateChange = (date) => {
        setEndDate(date);
    };

    const handleCustomDateSelect = () => {
        setLoading(true)
        setCustomFilter(true)

        // const customStartDate = moment(startDate, 'YYYY-MM-DD').format('DD/MM/YYYY');
        // const customEndDate = moment(endDate, 'YYYY-MM-DD').format('DD/MM/YYYY');

        setSelectedDateRange({ name: "Custom", value: 3, startDate: startDate, endDate: endDate });

        setCustomDateModalOpen(false);

        setTimeout(() => {
            setLoading(false)
        }, 500);
    };

    const handleStandardPricingDrop = () => {
        setDropdownOpen((prev) => !prev);
    };

    const handleStandardPricingDrop1 = () => {
        setDropdownOpen1((prev) => !prev);
    };

    // const handleSearch = () => {
    //     setTimeout(() => {
    //         console.log("Search Term:", searchTerm);
    //     }, 500);
    // };

    const handleItemClick = (item) => {
        navigate(`/quotation/${item.id}`)

        // navigate(`/quotation/${item.id}`, {
        //     state: {
        //         hostUrl: item.website_url
        //     }
        // })
    };

    const handleProjectStatusSelect = (name, id) => {
        setSelectedProjectStatus(name)
        setSelectedProjectStatusColor(id);
    };

    // const tableData = allowedProducts?.filter(item => {
    //     if (selectedProjectStatus === "All") {
    //         return true;
    //     } else {

    //         console.log(item.name, selectedProjectStatus, "selectedProjectStatus")
    //         return item.quote_status_name === selectedProjectStatus;
    //     }
    // }).filter(item => {
    //     if (selectedDateRange && selectedDateRange.name !== "Custom") {
    //         const modifiedDate = moment(item?.modified_at, 'DD/MM/YYYY');
    //         return modifiedDate.isSameOrAfter(moment(selectedDateRange.startDate, 'DD/MM/YYYY')) &&
    //             modifiedDate.isSameOrBefore(moment(selectedDateRange.endDate, 'DD/MM/YYYY'));
    //     } else if (selectedDateRange && selectedDateRange.name === "Custom") {
    //         const modifiedDate = moment(item?.modified_at, 'DD/MM/YYYY');
    //         const customStartDate = moment(selectedDateRange.startDate, 'DD/MM/YYYY');
    //         const customEndDate = moment(selectedDateRange.endDate, 'DD/MM/YYYY');
    //         return modifiedDate.isSameOrAfter(customStartDate) && modifiedDate.isSameOrBefore(customEndDate);
    //     }
    //     return true;
    // }).filter(item =>
    //     item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    //     item.reference.toLowerCase().includes(searchTerm.toLowerCase()) ||
    //     item.modified_at.toLowerCase().includes(searchTerm.toLowerCase())
    // ).map(item => {
    //     return {
    //         user_name: item?.user_name,
    //         name: item?.quotation_name,
    //         reference: item?.reference,
    //         job_type: item?.job_type,
    //         status_color: item?.status_color,
    //         quote_status_name: item?.quote_status_name,
    //         id: item?.quotation_id,
    //         modified_at: item?.modified_at,
    //         host_name: item?.host_name,
    //     };
    // });

    const tableData = allowedProducts.filter(item =>
        item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.reference.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.modified_at.toLowerCase().includes(searchTerm.toLowerCase())
    )?.map(item => {
        return {
            user_name: item?.user_name,
            name: item?.quotation_name,
            reference: item?.reference,
            job_type: item?.job_type,
            status_color: item?.status_color,
            quote_status_name: item?.quote_status_name,
            id: item?.quotation_id,
            modified_at: item?.modified_at,
            host_name: item?.host_name,
        };
    });

    const columns = [
        {
            name: <span>User name</span>,
            selector: row => row.user_name,
            sortable: true,
        },
        {
            name: <span>Quote name</span>,
            selector: row => row.name,
            sortable: true,
        },
        {
            name: <span>Reference</span>,
            selector: row => row.reference,
        },
        {
            name: <span>Job type</span>,
            selector: row => (row.job_type),
        },
        {
            name: <span>Status</span>,

            cell: row =>
                <div className="d-flex align-items-start gap-2">
                    <span className={`status_indicator`} style={{ marginTop: '2px', '--bg-indi-color': `${row?.status_color}`, '--bg-indi-color-a': `${row?.status_color}30` }}></span>
                    <span className="text-nowra" style={{ color: `${row?.status_color}` }}>{row?.quote_status_name}</span>
                </div>
        },
        {
            name: <span>Modified</span>,
            selector: row => moment(row.modified_at, 'DD/MM/YYYY HH:mm:ss').format('DD/MM/YYYY'),
            sortable: true,
        },
    ]

    const SuperAdminColumns = [
        {
            name: <span>UserName</span>,
            selector: row => row.user_name,
            sortable: true,
        },
        {
            name: <span>Name</span>,
            selector: row => row.name,
            sortable: true,
        },
        {
            name: <span>Company Name</span>,
            selector: row => row.host_name,
        },
        {
            name: <span>Reference</span>,
            selector: row => row.reference,
        },
        {
            name: <span>Job Type</span>,
            selector: row => (row.job_type),
        },
        {
            name: <span>Status</span>,

            cell: row =>
                <div className="d-flex align-items-start gap-2">
                    <span className={`status_indicator`} style={{ marginTop: '2px', '--bg-indi-color': `${row?.status_color}`, '--bg-indi-color-a': `${row?.status_color}30` }}></span>
                    <span className="text-nowra" style={{ color: `${row?.status_color}` }}>{row?.quote_status_name}</span>
                </div>
        },
        {
            name: <span>Modified</span>,
            selector: row => moment(row.modified_at, 'DD/MM/YYYY HH:mm:ss').format("DD/MM/YYYY"),
            sortable: true,
        },
    ]

    return (
        <>
            <div className="d-flex justify-content-between mb-3 flex-wrap gap-3">
                <div className="d-flex align-items-center gap-3 gap-lg-5">
                    <Dropdown isOpen={dropdownOpen} toggle={handleStandardPricingDrop}>
                        <DropdownToggle color="none" className="border-0 p-0 lh-1 h-auto">
                            <div className="d-flex align-items-center">
                                <span className="fw-semibold">
                                    <MdOutlineFilterAlt size={20} color="primary" />
                                    {selectedProjectStatus}
                                    <MdKeyboardArrowDown className="ms-1" size={20} />
                                </span>
                            </div>
                        </DropdownToggle>

                        <DropdownMenu className="mt-2">
                            <DropdownItem key={100} className="px-3">
                                <div className="d-flex align-items-center">
                                    <span className="text-dark fw-bold" >
                                        Project Status
                                    </span>
                                </div>
                            </DropdownItem>

                            {statusFilterOptions && statusFilterOptions.map((item, index) => {
                                return (
                                    <DropdownItem
                                        key={index}
                                        onClick={() => {
                                            handleProjectStatusSelect(item.name, item.id);
                                        }}
                                    >
                                        <div className="d-flex align-items-center gap-2">
                                            <span className='status_indicator' style={{ '--bg-indi-color': `${item?.color}`, '--bg-indi-color-a': `${item?.color}30` }} />
                                            {item.name}
                                        </div>
                                    </DropdownItem>
                                );
                            })}
                        </DropdownMenu>
                    </Dropdown>

                    <Dropdown isOpen={dropdownOpen1} toggle={handleStandardPricingDrop1}>
                        <DropdownToggle color="none" className="border-0 p-0 lh-1 h-auto">
                            <div className="d-flex align-items-center">
                                <span className="fw-semibold">
                                    <MdOutlineCalendarMonth size={20} /> {selectedDateRange ? selectedDateRange.name : "Select Date Range"}
                                    <MdKeyboardArrowDown className="ms-1" size={20} />
                                </span>
                            </div>
                        </DropdownToggle>

                        <DropdownMenu className="mt-2">
                            <DropdownItem key={100} className="px-3">
                                <div className="d-flex align-items-center">
                                    <span className="text-dark fw-bold" >
                                        Project modified date
                                    </span>
                                </div>
                            </DropdownItem>

                            {dateFilterOptions && dateFilterOptions.map((item, index) => {
                                return (
                                    <DropdownItem
                                        key={index}
                                        onClick={() => {
                                            handleDateRangeSelect(item.name);
                                        }}
                                    >
                                        {item.name}
                                    </DropdownItem>
                                );
                            })}
                        </DropdownMenu>
                    </Dropdown>
                </div>

                {/* <div className="check_box_wrap d-flex py-1">
                    <label className="check_box_item type_2">
                        <input type="radio" name="emp_range" value="1" checked />
                        <span className="label_name mr-0">
                            <h5>All day</h5>

                            <p> Lorem Ipsum with a dictionary</p>
                        </span>
                    </label>

                    <label className="check_box_item type_2">
                        <input type="radio" name="emp_range" value="2" />
                        <span className="label_name mr-0">
                            <h5>All day</h5>

                            <p> Lorem Ipsum with a dictionary</p>
                        </span>
                    </label>

                    <label className="check_box_item type_2">
                        <input type="radio" name="emp_range" value="3" />
                        <span className="label_name mr-0">
                            <h5>All day</h5>

                            <p> Lorem Ipsum with a dictionary</p>
                        </span>
                    </label>
                </div> */}

                <div className="d-flex align-items-center justify-content-end flex-grow-1 min-w-150">
                    <div className="d-flex">
                        <InputGroup className="border rounded">
                            <Input
                                type="text"
                                placeholder="Search..."
                                className="search-input border-0"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                            <Link to={{}}><div className="input-group-append">
                                <span className="input-group-text bg-transparent border-0 px-2">
                                    <MdOutlineSearch size={20} />
                                </span>
                            </div></Link>
                        </InputGroup>
                    </div>
                </div>
            </div>

            <div className='h-100 d-flex flex-column flex-grow-1 justify-content-between'>
                <div className='inside_scroll has_tab has_btns'>
                    <DataTable
                        columns={userData?.role === "Super Admin" ? SuperAdminColumns : columns}
                        data={tableData}
                        className="quote_list custom_datatable clickable"
                        sortIcon={<BiSort size={18} className="text-muted" />}
                        onRowClicked={handleItemClick}
                        highlightOnHover
                        pointerOnHover
                        pagination
                        paginationPerPage={10}
                        paginationRowsPerPageOptions={[5, 10, 15, 20, 30]}
                    />
                </div>
            </div>

            <Modal isOpen={customDateModalOpen} toggle={() => setCustomDateModalOpen(!customDateModalOpen)}>
                <ModalHeader toggle={() => setCustomDateModalOpen(!customDateModalOpen)}>
                    Custom Filter
                </ModalHeader>

                <ModalBody className="d-flex gap-2 py-4">
                    <div className='w-100 w-sm-50 d-flex gap-2'>
                        <div className="w-100">
                            <FormGroup className='d-flex flex-column mb-0'>
                                <Label for={`startDate`}>Start Date</Label>
                                <ReactDatePicker
                                    selected={startDate}
                                    className="form-control"
                                    onChange={date => handleStartDateChange(date)}
                                    dateFormat="dd/MM/yyyy"
                                    id="startDate"
                                    autoComplete="off"
                                    maxDate={new Date()}
                                />
                            </FormGroup>
                        </div>
                    </div>

                    <div className='w-100 w-sm-50 d-flex gap-2'>
                        <div className="w-100">
                            <FormGroup className='d-flex flex-column mb-0'>
                                <Label for={`endDate`}>End Date </Label>
                                <ReactDatePicker
                                    selected={endDate}
                                    className="form-control"
                                    onChange={date => handleEndDateChange(date)}
                                    dateFormat="dd/MM/yyyy"
                                    id="endDate"
                                    autoComplete="off"
                                    minDate={startDate}
                                    maxDate={new Date()}
                                    disabled={!startDate}
                                />
                            </FormGroup>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter className="py-2">
                    <Button
                        type="button"
                        color="primary"
                        size="md"
                        className='px-4'
                        onClick={handleCustomDateSelect}
                    >
                        Apply
                    </Button>
                    <Button
                        type="button"
                        color="outline-secondary"
                        size="md"
                        className='px-4'
                        onClick={() => setCustomDateModalOpen(false)}
                    >
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
};

export default Left;
