import React from 'react'
import InternalLayout from '../../../../../../layouts/internalLayout'
import Left from './left'
import { Oval } from 'react-loader-spinner'
import { useMessage } from '../../../../../../context/messageContext'
import { useState } from 'react'

const FrameAncillaries = () => {
    var access_token = localStorage.getItem("access_token")

    const [loading, setLoading] = useState(false)

    const { setMessage, setMessageType } = useMessage()
    return (
        <>
            {loading && (
                <div className='loader_main full_loader'>
                    <Oval
                        height={50}
                        width={50}
                        color="#44C8F5"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                        ariaLabel='oval-loading'
                        secondaryColor="#44C8F5"
                        strokeWidth={2}
                        strokeWidthSecondary={2}
                    />
                </div>
            )}
            <InternalLayout pageTitle="Frame Ancillaries" layoutType="full" leftContent={<Left token={access_token} setMessage={setMessage} setMessageType={setMessageType} loading={loading} setLoading={setLoading} />} rightContent={''} />
        </>
    )
}

export default FrameAncillaries