import { getOverGlazingPriceSlabService } from "../services/glazingServices";
import { formatValue } from "./helper";

export const getPricingObject = (
    customModelData,
    pricingData,
    setTotalEachPrice,
    setTotalPrice,
    accessToken,
) => {

    let totalGlazingPriceArray = []

    if (!customModelData?.layoutFrame || !pricingData) {
        return;
    }

    let {
        height,
        width,
        frame: { sill: { sillData: { height: sillHeight = "" } = {} } = {} } = {},
    } = customModelData?.layoutFrame;

    // Here checking if threshold is applied in place of sill so now the reduce height will be based on the the threshold if it is applied 
    if (customModelData?.frame?.threshold?.isThresholdAdded) {
        sillHeight = customModelData?.frame?.threshold?.height
    }

    const reducer = sillHeight !== "" ? "height" : "";
    let totalInternalColorArea = 0;
    let totalExternalColorArea = 0;
    let internalStockColor = false;
    let externalStockColor = false;

    const frameProfilePriceArray = pricingData?.frameProfile?.map((item) => {
        // getting price for the additional articles linked to the frame profile
        let additionaArticlePrice = 0
        let newHeight = item?.cuttingHeight ? item?.cuttingHeight : 0;
        let newWidth = item?.cuttingWidth ? item?.cuttingWidth : 0;

        let frameReducer = "";
        if (
            // reducer === "height" &&
            (item?.orientation?.includes("Left") || item?.orientation?.includes("Right"))
        ) {
            frameReducer = "height";
            additionaArticlePrice = getAdditionalArticlePrice(item?.additionalArticles, parseFloat(parseFloat(height - newHeight) / 1000))

        }
        else {
            frameReducer = "width";
            additionaArticlePrice = getAdditionalArticlePrice(item?.additionalArticles, parseFloat(parseFloat(width - newWidth) / 1000))

        }

        let profileTypePrice = item?.profileTypePrice ? item?.profileTypePrice : 0

        const totalFramePrice = parseFloat(
            getPricing(
                "frameProfile",
                sillHeight,
                height,
                width,
                "",
                frameReducer,
                (parseFloat(item?.price)),
                0,
                "",
                item?.orientation,
                "",
                item
            )
        );

        return {
            id: item?.id,
            total: parseFloat(totalFramePrice) + parseFloat(additionaArticlePrice) + parseFloat(profileTypePrice),
        };
    });

    // This is for getting internal and external area for the frame profile
    if (pricingData?.frameProfile?.length > 0) {
        pricingData?.frameProfile?.forEach((item) => {
            let dimension = 0;
            let newHeight = item?.cuttingHeight ? item?.cuttingHeight : 0;
            let newWidth = item?.cuttingWidth ? item?.cuttingWidth : 0;
            // This is for getting the length or breadth on the basis of applied profiles
            item?.orientation?.split(',')?.forEach((item) => {
                if (item.includes("Left")) {
                    dimension += parseFloat(height - newHeight)
                }
                else if (item.includes("Right")) {
                    dimension += parseFloat(height - newHeight)
                }
                else if (item.includes("Top")) {
                    dimension += parseFloat(width - newWidth)
                }
                else if (item.includes("Bottom")) {
                    dimension += parseFloat(width - newWidth)
                }
            })
            let internalArea = item?.internalPaintSurfaceArea ? item?.internalPaintSurfaceArea : 0;
            let externalArea = item?.externalPaintSurfaceArea ? item?.externalPaintSurfaceArea : 0;
            totalInternalColorArea += parseFloat((parseFloat(internalArea) / 1000000) * parseFloat(dimension / 1000)) // <- divide by 1000000 if area is in mm2
            totalExternalColorArea += parseFloat((parseFloat(externalArea) / 1000000) * parseFloat(dimension / 1000))
        })
    }

    // This is for getting internal and external area for the sash profile
    if (pricingData?.sash?.length > 0) {
        pricingData?.sash?.forEach((item) => {
            let dimension = 0;
            // This is for getting the length or breadth on the basis of applied profiles
            item?.orientation?.split(',')?.forEach((item) => {
                if (item.includes("Left")) {
                    dimension += parseFloat(height)
                }
                else if (item.includes("Right")) {
                    dimension += parseFloat(height)
                }
                else if (item.includes("Top")) {
                    dimension += parseFloat(width)
                }
                else if (item.includes("Bottom")) {
                    dimension += parseFloat(width)
                }
            })
            let internalArea = item?.internalPaintSurfaceArea ? item?.internalPaintSurfaceArea : 0;
            let externalArea = item?.externalPaintSurfaceArea ? item?.externalPaintSurfaceArea : 0;
            totalInternalColorArea += parseFloat((parseFloat(internalArea) / 1000000) * parseFloat(dimension / 1000)) // <- divide by 1000000 if area is in mm2
            totalExternalColorArea += parseFloat((parseFloat(externalArea) / 1000000) * parseFloat(dimension / 1000))
        })
    }

    // This is for getting internal external paint surface area for the transom profile
    if (pricingData?.transoms?.length > 0) {
        pricingData?.transoms?.forEach((item) => {
            let internalPaintSurfaceArea = 0;
            let externalPaintSurfaceArea = 0
            if (item?.hasOwnProperty("internalPaintSurfaceArea") && item?.internalPaintSurfaceArea !== null && item?.internalPaintSurfaceArea !== undefined) {
                internalPaintSurfaceArea = item?.internalPaintSurfaceArea
            }
            if (item?.hasOwnProperty("externalPaintSurfaceArea") && item?.externalPaintSurfaceArea !== null && item?.externalPaintSurfaceArea !== undefined) {
                externalPaintSurfaceArea = item?.externalPaintSurfaceArea
            }
            totalInternalColorArea += parseFloat(parseFloat(internalPaintSurfaceArea) / 1000000 * parseFloat(item?.length))
            totalExternalColorArea += parseFloat(parseFloat(externalPaintSurfaceArea) / 1000000 * parseFloat(item?.length))
        })
    }

    // This is for getting internal external paint surface area for the bead profile
    if (pricingData?.bead?.length > 0) {
        pricingData?.bead?.forEach((item) => {
            let internalPaintSurfaceArea = 0;
            let externalPaintSurfaceArea = 0
            if (item?.hasOwnProperty("internalPaintSurfaceArea") && item?.internalPaintSurfaceArea !== null && item?.internalPaintSurfaceArea !== undefined) {
                internalPaintSurfaceArea = item?.internalPaintSurfaceArea
            }
            if (item?.hasOwnProperty("externalPaintSurfaceArea") && item?.externalPaintSurfaceArea !== null && item?.externalPaintSurfaceArea !== undefined) {
                externalPaintSurfaceArea = item?.externalPaintSurfaceArea
            }
            totalInternalColorArea += parseFloat(parseFloat(internalPaintSurfaceArea) / 1000000 * parseFloat(pricingData?.beadLength))
            totalExternalColorArea += parseFloat(parseFloat(externalPaintSurfaceArea) / 1000000 * parseFloat(pricingData?.beadLength))
        })
    }

    // This is for getting internal and external area for the coupler profile
    if (pricingData?.coupler?.length > 0) {
        pricingData?.coupler?.forEach((item) => {
            let internalPaintSurfaceArea = 0;
            let externalPaintSurfaceArea = 0
            if (item?.internalPaintSurfaceArea !== null) {
                internalPaintSurfaceArea = item?.internalPaintSurfaceArea
            }
            if (item?.externalPaintSurfaceArea !== null) {
                externalPaintSurfaceArea = item?.externalPaintSurfaceArea
            }
            totalInternalColorArea += parseFloat(parseFloat(internalPaintSurfaceArea) / 1000000 * (parseFloat(height) / 1000))
            totalExternalColorArea += parseFloat(parseFloat(externalPaintSurfaceArea) / 1000000 * (parseFloat(height) / 1000))
        })
    }

    // This is for getting internal external paint surface area for the bayPost profile
    if (pricingData?.bayPost?.length > 0) {
        pricingData?.bayPost?.forEach((item) => {
            let internalPaintSurfaceArea = 0;
            let externalPaintSurfaceArea = 0
            if (item?.hasOwnProperty("internalPaintSurfaceArea") && item?.internalPaintSurfaceArea !== null && item?.internalPaintSurfaceArea !== undefined) {
                internalPaintSurfaceArea = item?.internalPaintSurfaceArea
            }
            if (item?.hasOwnProperty("externalPaintSurfaceArea") && item?.externalPaintSurfaceArea !== null && item?.externalPaintSurfaceArea !== undefined) {
                externalPaintSurfaceArea = item?.externalPaintSurfaceArea
            }
            totalInternalColorArea += parseFloat(parseFloat(internalPaintSurfaceArea) / 1000000 * parseFloat(pricingData?.beadLength))
            totalExternalColorArea += parseFloat(parseFloat(externalPaintSurfaceArea) / 1000000 * parseFloat(pricingData?.beadLength))
        })
    }

    // This is for getting internal and external area for the sill profile
    if (pricingData?.sill?.length === 1) {
        let internalPaintSurfaceArea = 0;
        let externalPaintSurfaceArea = 0
        if (pricingData?.sill[0]?.internalPaintSurfaceArea !== null) {
            internalPaintSurfaceArea = pricingData?.sill[0]?.internalPaintSurfaceArea
        }
        if (pricingData?.sill[0]?.externalPaintSurfaceArea !== null) {
            externalPaintSurfaceArea = pricingData?.sill[0]?.externalPaintSurfaceArea
        }
        totalInternalColorArea += parseFloat(parseFloat(internalPaintSurfaceArea) / 1000000 * (parseFloat(width) / 1000))
        totalExternalColorArea += parseFloat(parseFloat(externalPaintSurfaceArea) / 1000000 * (parseFloat(width) / 1000))
    }

    // This is for getting internal and external area for the threshold
    if (pricingData?.threshold !== null) {
        totalInternalColorArea += parseFloat(parseFloat(pricingData?.threshold?.internalPaintSurfaceArea) / 1000000 * (parseFloat(width) / 1000))
        totalExternalColorArea += parseFloat(parseFloat(pricingData?.threshold?.externalPaintSurfaceArea) / 1000000 * (parseFloat(width) / 1000))
    }

    if (pricingData?.floatingMullion?.length > 0) {
        pricingData?.floatingMullion?.forEach((item) => {
            let internalPaintSurfaceArea = 0;
            let externalPaintSurfaceArea = 0
            if (item?.internalPaintSurfaceArea !== null) {
                internalPaintSurfaceArea = item?.internalPaintSurfaceArea
            }
            if (item?.externalPaintSurfaceArea !== null) {
                externalPaintSurfaceArea = item?.externalPaintSurfaceArea
            }
            totalInternalColorArea += parseFloat(parseFloat(internalPaintSurfaceArea) / 1000000 * (parseFloat(height) / 1000))
            totalExternalColorArea += parseFloat(parseFloat(externalPaintSurfaceArea) / 1000000 * (parseFloat(height) / 1000))
        })
    }

    // This is for getting the coloring area for the panel
    if (pricingData?.panel?.length > 0) {
        let newWidth = width;
        let area = 0;
        pricingData?.panel?.forEach((item) => {
            if (
                customModelData?.hasOwnProperty("numberOfSash") &&
                customModelData?.numberOfSash?.number !== "0"
            ) {
                newWidth = width / parseFloat(customModelData?.numberOfSash?.number);
            }
            area += newWidth * height
        })
        totalInternalColorArea += parseFloat(area / 1000000)
        totalExternalColorArea += parseFloat(area / 1000000)
    }

    if (pricingData?.frameColorExt?.stockColor === true) {
        externalStockColor = true
    }
    if (pricingData?.frameColorInt?.stockColor === true) {
        internalStockColor = true
    }

    console.log("pricing OBject", pricingData,)
    // console.log("FEAME", frameProfilePriceArray)
    // This is for calculating the percentage price of color on the basis of frame profile price
    let totalFramePriceArrayPrice = 0;
    let pricingExtPercentagerPrice = 0;
    let pricingIntPercentagePrice = 0;
    if (frameProfilePriceArray?.length > 0) {
        frameProfilePriceArray?.forEach((item => {
            totalFramePriceArrayPrice += item?.total
        }))
    }
    if (totalFramePriceArrayPrice !== 0) {
        pricingExtPercentagerPrice = (pricingData?.frameColorExt?.priceInpercentage / 100) * totalFramePriceArrayPrice
        pricingIntPercentagePrice = (pricingData?.frameColorInt?.priceInpercentage / 100) * totalFramePriceArrayPrice
    }

    let colorObj = {
        internalSurfaceArea: totalInternalColorArea,
        internalColorPrice: pricingData?.frameColorInt?.mmSquarePrice,
        externalSurfaceArea: totalExternalColorArea,
        externalColorPrice: pricingData?.frameColorExt?.mmSquarePrice,
        isSameColor: pricingData?.frameColorExt?.hex === pricingData?.frameColorInt?.hex,
        // Adding keys for the external lump sum and internal lump sum price
        extLumSum: pricingData?.frameColorExt?.price !== undefined ? pricingData?.frameColorExt?.price : 0,
        intLumpSum: pricingData?.frameColorInt?.price !== undefined ? pricingData?.frameColorInt?.price : 0,
        extStock: externalStockColor,
        intStock: internalStockColor,
        pricingExtPercentagePrice: pricingExtPercentagerPrice,
        pricingIntPercentagePrice: pricingIntPercentagePrice,
    };

    const frameProfileColorPrice = parseFloat(
        getPricing(
            "color",
            sillHeight,
            height,
            width,
            "",
            "",
            "",
            0,
            "",
            "",
            colorObj,
        )
    );

    const calculateTotalGlazingPrice = async (pricingData, accessToken) => {
        const getSpecTotal = await Promise.all(
            pricingData?.glazing?.map(async (item) => {
                // Await the result from the async function
                let overSizePercentage = await getOverSizeGlazinPercentSlab(accessToken, item?.priceableArea, item.id);

                // Calculate total glazing price
                let totalGlazingPrice = parseFloat(item?.price * item?.priceableArea);

                if (overSizePercentage > 0) {
                    totalGlazingPrice += ((overSizePercentage / 100) * totalGlazingPrice);
                }

                // console.log(item?.priceableArea, overSizePercentage, totalGlazingPrice, "overSizePercentage");

                // Return the result in the expected format
                return {
                    id: item?.id,
                    name: item?.name,
                    total: totalGlazingPrice,
                };
            })
        );

        return getSpecTotal;
    };

    // total spec glazing calc:
    (async () => {
        const result = await calculateTotalGlazingPrice(pricingData, accessToken);

        if (result) {

            // const updatedArrayData = updateArrayWithCountSumTotal(result);

            totalGlazingPriceArray = result
            // console.log(totalGlazingPriceArray, "totalGlazingPriceArray")
        }
    })();

    const totalAddedFramePriceArray = pricingData?.addedFrames?.map((item) => {
        let totalFramePrice = parseFloat(item?.price * ((parseFloat(item?.height) / 1000) * (parseFloat(item?.width)) / 1000))

        return {
            id: item?.id,
            total: totalFramePrice * item?.count,
        };
    });

    const totalTexturePriceArray = pricingData?.texture?.map((item) => {
        let totalTexturePrice = parseFloat(item?.price * item?.priceableArea);

        return {
            id: item?.id,
            total: totalTexturePrice,
        };
    });

    const totalDesignPriceArray = pricingData?.design?.map((item) => {
        let totalDesignPrice = parseFloat(item?.price * item?.priceableArea);

        return {
            id: item?.id,
            total: totalDesignPrice,
        };
    });

    const totalSandblastedPriceArray = pricingData?.sandblasted?.map((item) => {
        let totalSandblastedPrice = parseFloat(item?.price * item?.priceableArea);

        return {
            id: item?.id,
            total: totalSandblastedPrice,
        };
    });

    const totalPanelPriceArray = pricingData?.panel?.map((item) => {
        let totalPanelPrice = parseFloat(item?.price * item?.priceableArea)

        return {
            id: item?.id,
            total: (totalPanelPrice),
        };
    });

    const totalFloatingMullionPriceArray = pricingData?.floatingMullion?.map((item) => {
        let additionaArticlePrice = 0.0;
        let profileTypePrice = item?.profileTypePrice ? item?.profileTypePrice : 0
        const totalFloatingPrice = parseFloat(
            getPricing(
                "floatingMullion",
                sillHeight,
                height,
                width,
                "",
                reducer,
                parseFloat(item?.price),
                0,
                "",
                "",
                ""
            )
        );
        return {
            id: item?.id,
            total: totalFloatingPrice + parseFloat(additionaArticlePrice) + parseFloat(profileTypePrice),
        };
    })

    const handlePriceArray = pricingData?.handles?.map((item) => ({
      id: item?.id,
      total:
        ((!isNaN(item?.price) && item?.price !== ""
          ? parseFloat(item?.price)
          : 0) +
          getHardwareColorPrice(item?.colorPrice)) *
        item?.count,
    }));

    const cylinderPriceArray = pricingData?.cylinder?.map((item) => ({
      id: item?.id,
      total:
        ((!isNaN(item?.price) && item?.price !== ""
          ? parseFloat(item?.price)
          : 0) +
          getHardwareColorPrice(item?.colorPrice)) *
        item?.count,
    }));

    const barHandleOffsetPriceArray = pricingData?.barHandleOffset?.map(
      (item) => ({
        id: item?.id,
        total:
          (parseFloat(item?.price) + getHardwareColorPrice(item?.colorPrice)) *
          item?.count,
      })
    );

    const knockerPriceArray = pricingData?.knocker?.map((item) => ({
      id: item?.id,
      total:
        (parseFloat(item?.price) + getHardwareColorPrice(item?.colorPrice)) *
        item?.count,
    }));

    const slideAndTurnHardwarePriceArray =
      pricingData?.slideAndTurnHardware?.map((item) => ({
        id: item?.id,
        total:
          (parseFloat(item?.price) + getHardwareColorPrice(item?.colorPrice)) *
          item?.count,
      }));

    const spyholePriceArray = pricingData?.spyhole?.map((item) => ({
      id: item?.id,
      total:
        (parseFloat(item?.price) + getHardwareColorPrice(item?.colorPrice)) *
        item?.count,
    }));

    const estucheonPriceArray = pricingData?.escutcheon?.map((item) => ({
      id: item?.id,
      total:
        (parseFloat(item?.price) + getHardwareColorPrice(item?.colorPrice)) *
        item?.count,
    }));

    const trickleVentPriceArray = pricingData?.trickleVent?.map((item) => ({
      id: item?.id,
      total:
        (parseFloat(item?.price) + getHardwareColorPrice(item?.colorPrice)) *
        item?.count,
    }));

    const letterPlatePriceArray = pricingData?.letterPlate?.map((item) => ({
      id: item?.id,
      total:
        (parseFloat(item?.price) + getHardwareColorPrice(item?.colorPrice)) *
        item?.count,
    }));

    const numeralsPriceArray = pricingData?.numeral?.map((item) => ({
      id: item?.id,
      total:
        parseFloat(
          (parseFloat(item?.price) + getHardwareColorPrice(item?.colorPrice)) *
            item?.count
        ) +
          item?.numbers >
          1 &&
        parseFloat(
          (parseFloat(item?.price) + getHardwareColorPrice(item?.colorPrice)) *
            item?.numbers
        ),
    }));

    const hingPriceArray = pricingData?.hinge?.map((item) => ({
      id: item?.id,
      total: parseFloat(
        (parseFloat(item?.price) + getHardwareColorPrice(item?.colorPrice)) *
          item?.count
      ),
    }));

    let totalSillPrice = 0;
    let additionaArticlePriceSill = getAdditionalArticlePrice(pricingData?.sill[0]?.additionalArticles, parseFloat(width / 1000));
    if (pricingData?.sill[0]?.price) {
        totalSillPrice = getPricing(
            "sill",
            sillHeight,
            height,
            width,
            "",
            reducer,
            pricingData?.sill[0]?.price,
            0,
            ""
        ) + parseFloat(additionaArticlePriceSill) + parseFloat(pricingData?.sill[0]?.profileTypePrice);
    }

    // Calculating the price for the added threshold
    let totalThresholdPrice = 0;
    let additionalArticlesPriceThreshold = 0;
    let profileTypePriceThreshold = 0
    if (pricingData?.threshold?.additionalArticles?.length > 0) {
        additionalArticlesPriceThreshold = getAdditionalArticlePrice(pricingData?.threshold?.additionalArticles, parseFloat(width / 1000));
    }
    if (pricingData?.threshold?.profileTypePrice) {
        profileTypePriceThreshold = pricingData?.threshold?.profileTypePrice
    }

    if (pricingData?.threshold?.price) {
        totalThresholdPrice = getPricing(
            "sill",
            sillHeight,
            height,
            width,
            "",
            reducer,
            pricingData?.threshold?.price,
            0,
            ""
        ) + parseFloat(additionalArticlesPriceThreshold) + parseFloat(profileTypePriceThreshold);
    }

    const totalSashPriceArray = pricingData?.sash?.map((item) => {

        let additionaArticlePrice = 0.0;
        let profileTypePrice = item?.profileTypePrice ? item?.profileTypePrice : 0

        let sashReducer = "";
        if (
            // reducer === "height" &&
            (item?.orientation?.includes("Left") || item?.orientation?.includes("Right"))
        ) {
            sashReducer = "height";
            additionaArticlePrice = getAdditionalArticlePrice(item?.additionalArticles, parseFloat(height / 1000))
        }
        else {
            sashReducer = "width";
            additionaArticlePrice = getAdditionalArticlePrice(item?.additionalArticles, parseFloat(width / 1000))
        }

        const totalSashPrice = parseFloat(
            getPricing(
                "lateralSash",
                sillHeight,
                height,
                width,
                "",
                sashReducer,
                parseFloat(item?.price),
                0,
                "",
                item?.orientation,
                ""
            )
        );

        return {
            id: item?.id,
            total: totalSashPrice + parseFloat(additionaArticlePrice) + parseFloat(profileTypePrice),
        };
    });

    const totalTransomsPriceArray = pricingData?.transoms.map((item) => {
        let additionaArticlePrice = 0.0;
        let profileTypePrice = item?.profileTypePrice ? item?.profileTypePrice : 0

        if (
            (!item?.horizontal)
        ) {
            additionaArticlePrice = getAdditionalArticlePrice(item?.additionalArticles, parseFloat(height / 1000))
        }
        else {
            additionaArticlePrice = getAdditionalArticlePrice(item?.additionalArticles, parseFloat(width / 1000))
        }

        const totalTransomPrice = parseFloat(
            getPricing(
                "transoms",
                sillHeight,
                height,
                width,
                "",
                "",
                parseFloat(item?.price),
                0,
                "",
                item?.orientation,
                item
            )
        );
        return {
            id: item?.id,
            total: totalTransomPrice + parseFloat(additionaArticlePrice) + parseFloat(profileTypePrice),
        };
    })

    const totalBeadPriceArray = pricingData?.bead?.map((item) => {
        let additionaArticlePrice = 0.0;
        let profileTypePrice = item?.profileTypePrice ? item?.profileTypePrice : 0
        additionaArticlePrice = getAdditionalArticlePrice(item?.additionalArticles, parseFloat(pricingData?.beadLength))
        // console.log("ARTICLE PRICE", additionaArticlePrice)
        return {
            id: item?.id,
            total:
                (item?.price + parseFloat(profileTypePrice)) *
                pricingData?.beadLength +
                parseFloat(additionaArticlePrice),
        };
    })

    // Calculating price for the coupler
    const totalCouplerPriceArray = pricingData?.coupler.map((item) => {
        let additionaArticlePrice = 0.0
        additionaArticlePrice = getAdditionalArticlePrice(item?.additionalArticles, parseFloat(height / 1000))
        let profileTypePrice = item?.profileTypePrice ? item?.profileTypePrice * item?.count : 0
        const totalCouplerPrice = parseFloat((height / 1000) * item?.price * item?.count);
        return {
            id: item?.id,
            total: (parseFloat(totalCouplerPrice + parseFloat(additionaArticlePrice) + parseFloat(profileTypePrice))),
        };
    })

    // Calculating price for the coupler
    const tempBayPostPriceArray = pricingData?.bayPost.map((item) => {
        let additionaArticlePrice = 0.0
        additionaArticlePrice = getAdditionalArticlePrice(item?.additionalArticles, parseFloat(height / 1000))
        let profileTypePrice = item?.profileTypePrice ? item?.profileTypePrice * item?.count : 0
        const totalBayPostPrice = parseFloat((height / 1000) * item?.price * item?.count);
        return {
            id: item?.id,
            total: (parseFloat(totalBayPostPrice + parseFloat(additionaArticlePrice) + parseFloat(profileTypePrice))),
        };
    })

    setTimeout(() => {
        setTotalEachPrice((prevPricing) => ({
            ...prevPricing,
            frameProfile: frameProfilePriceArray,
            glazing: totalGlazingPriceArray,
            sill: {
                ...prevPricing.sill,
                total: totalSillPrice,
            },
            sash: totalSashPriceArray,
            texture: totalTexturePriceArray,
            panels: totalPanelPriceArray,
            handles: handlePriceArray,
            cylinder: cylinderPriceArray,
            knocker: knockerPriceArray,
            slideAndTurnHardware: slideAndTurnHardwarePriceArray,
            spyhole: spyholePriceArray,
            escutcheon: estucheonPriceArray,
            numeral: numeralsPriceArray,
            letterPlate: letterPlatePriceArray,
            color: frameProfileColorPrice,
            hinge: hingPriceArray,
            threshold: formatValue(parseFloat(totalThresholdPrice)),
            transoms: totalTransomsPriceArray,
            coupler: totalCouplerPriceArray,
            bayPost: tempBayPostPriceArray,
            floatingMullions: totalFloatingMullionPriceArray,
            barHandleOffset: barHandleOffsetPriceArray,
            bead: totalBeadPriceArray,
            trickleVent: trickleVentPriceArray,
            design: totalDesignPriceArray,
            sandblasted: totalSandblastedPriceArray,
            addedFrames: totalAddedFramePriceArray
        }));

        setTotalPrice(
            formatValue(parseFloat(
                parseFloat(getEachTotalPrice(frameProfilePriceArray)) +
                parseFloat(getEachTotalPrice(totalGlazingPriceArray)) +
                parseFloat(getEachTotalPrice(totalTransomsPriceArray)) +
                parseFloat(totalSillPrice) +
                parseFloat(getEachTotalPrice(totalTexturePriceArray)) +
                parseFloat(getEachTotalPrice(totalPanelPriceArray)) +
                parseFloat(getEachTotalPrice(handlePriceArray)) +
                parseFloat(getEachTotalPrice(cylinderPriceArray)) +
                parseFloat(getEachTotalPrice(knockerPriceArray)) +
                parseFloat(getEachTotalPrice(slideAndTurnHardwarePriceArray)) +
                parseFloat(getEachTotalPrice(spyholePriceArray)) +
                parseFloat(getEachTotalPrice(estucheonPriceArray)) +
                parseFloat(getEachTotalPrice(totalSashPriceArray)) +
                parseFloat(getEachTotalPrice(numeralsPriceArray)) +
                parseFloat(getEachTotalPrice(letterPlatePriceArray)) +
                parseFloat(getEachTotalPrice(hingPriceArray)) +
                parseFloat((frameProfileColorPrice)) +
                parseFloat(getEachTotalPrice(totalCouplerPriceArray)) +
                parseFloat(getEachTotalPrice(tempBayPostPriceArray)) +
                parseFloat(totalThresholdPrice) +
                parseFloat(getEachTotalPrice(totalFloatingMullionPriceArray)) +
                parseFloat(getEachTotalPrice(barHandleOffsetPriceArray)) +
                parseFloat(getEachTotalPrice(totalBeadPriceArray)) +
                parseFloat(getEachTotalPrice(trickleVentPriceArray)) +
                parseFloat(getEachTotalPrice(totalDesignPriceArray)) +
                parseFloat(getEachTotalPrice(totalSandblastedPriceArray)) +
                parseFloat(getEachTotalPrice(totalAddedFramePriceArray))

            ))
        );
    }, 1000);
};

export const getEachTotalPrice = (arr) => {
    let total = 0.0;
    arr.forEach((item) => {
        total += parseFloat(item?.total)
    })
    return formatValue(total)
}

export const getPricing = (
    type,
    sillHeight,
    actualHeight,
    actualWidth,
    depth,
    reducer,
    pricePm,
    markupPerc,
    sashHeight,
    orientation,
    color,
    data
) => {
    let totalPrice = 0;
    if (type === "sill") {
        totalPrice = (actualWidth / 1000) * parseFloat(pricePm);
        totalPrice += (markupPerc / 100) * totalPrice;
    } else if (type === "frameProfile") {
        if (reducer === "height") {
            actualHeight -= sillHeight;
        }
        // else if (reducer === "width") {
        //     actualWidth -= dimension?.width
        // }
        // let internalColorPrice = 0;
        // let externalColorPrice = 0;
        // if (color) {
        //     internalColorPrice = parseFloat(
        //         getValueOrDefault(color?.internalSurfaceArea) * getValueOrDefault(color?.internalColorPrice)
        //     );
        //     externalColorPrice = parseFloat(
        //         getValueOrDefault(color?.externalSurfaceArea) * getValueOrDefault(color?.externalColorPrice)
        //     );
        // }
        let orientationArray = orientation?.split(",");
        let cuttingLength = data?.cuttingHeight ? data?.cuttingLength : 0;
        let cuttingHeight = data?.cuttingHeight ? data?.cuttingHeight : 0;
        let cuttingWidth = data?.cuttingWidth ? data?.cuttingWidth : 0;
        if (orientationArray.length === 4) {
            actualHeight *= 2;
            actualWidth *= 2;
            totalPrice =
                ((parseFloat(actualHeight + actualWidth) - cuttingLength) / 1000) *
                pricePm;
            // +
            // (internalColorPrice + externalColorPrice);
            totalPrice += (markupPerc / 100) * totalPrice;
        } else {
            orientationArray.forEach((item) => {
                if (item === "Left" || item === "Right") {
                    totalPrice +=
                        (parseFloat(actualHeight - cuttingHeight) / 1000) * pricePm;
                    // +
                    // (internalColorPrice + externalColorPrice);
                    // ensure that the price is not undefinded while splitting the array
                } else if (item !== " ") {
                    totalPrice +=
                        (parseFloat(actualWidth - cuttingWidth) / 1000) * pricePm;
                    //  +
                    // (internalColorPrice + externalColorPrice);
                }
            });
            totalPrice += (markupPerc / 100) * totalPrice;
        }
    } else if (type === "glazing") {
        let area;
        if (depth === "" && sashHeight === "") {
            area = actualHeight * actualWidth;
        }
        totalPrice = (area / 1000000) * pricePm;
        totalPrice += (markupPerc / 100) * totalPrice;
    } else if (type === "lateralSash") {
        if (reducer === "height") {
            // actualHeight -= dimension?.height;
            actualHeight -= sillHeight;
        }
        // else if (reducer === "width") {
        //     actualWidth -= dimension?.width
        // }
        if (depth !== "") {
            actualHeight -= 2 * depth;
        }
        // let internalColorPrice = 0;
        // let externalColorPrice = 0;
        // if (color) {
        //     internalColorPrice = parseFloat(
        //         getValueOrDefault(color?.internalSurfaceArea) * getValueOrDefault(color?.internalColorPrice)
        //     );
        //     externalColorPrice = parseFloat(
        //         getValueOrDefault(color?.externalSurfaceArea) * getValueOrDefault(color?.externalColorPrice)
        //     );
        // }
        let orientationArray = orientation?.split(",");
        orientationArray.forEach((item) => {
            if (item === "Left" || item === "Right") {
                totalPrice += (actualHeight / 1000) * pricePm;
                // +
                // (internalColorPrice + externalColorPrice);
            } else {
                totalPrice += (actualWidth / 1000) * pricePm;
                // +
                //     (internalColorPrice + externalColorPrice);
            }
        });
        // totalPrice = (actualHeight / 1000) * pricePm;
        totalPrice += (markupPerc / 100) * totalPrice;
    } else if (type === "transoms") {
        // Here color is just name of the object which has length and price
        totalPrice +=
            parseFloat(
                !isNaN(color?.length) &&
                    color?.length !== undefined &&
                    color?.length !== null
                    ? color?.length
                    : 1
            ) * parseFloat(color?.price);
    } else if (type === "coupler" || type === "floatingMullion") {
        if (reducer === "height") {
            actualHeight -= sillHeight;
        }
        totalPrice += (actualHeight / 1000) * pricePm;
    } else if (type === "color") {
        let internalColorPrice = 0;
        let externalColorPrice = 0;
        if (color) {
            // Here checking for the condition if the both the color are same then we can use total area as one and it will work both for stock and non-stock colors
            if (color?.isSameColor && !color?.extStock && !color?.intStock) {
                internalColorPrice = getColorPricing(
                    parseFloat(color?.internalSurfaceArea) +
                    parseFloat(color?.externalSurfaceArea),
                    getValueOrDefault(color?.internalColorPrice),
                    false
                );
                externalColorPrice = 0;
            } else {
                internalColorPrice = getColorPricing(
                    getValueOrDefault(color?.internalSurfaceArea),
                    getValueOrDefault(color?.internalColorPrice),
                    color?.intStock
                );
                externalColorPrice = getColorPricing(
                    getValueOrDefault(color?.externalSurfaceArea),
                    getValueOrDefault(color?.externalColorPrice),
                    color?.extStock
                );
            }
        }
        totalPrice +=
            parseFloat(internalColorPrice) + parseFloat(externalColorPrice) + color?.extLumSum + color?.intLumpSum + color?.pricingExtPercentagePrice + color?.pricingIntPercentagePrice;
    }
    return formatValue(totalPrice);
};

function getValueOrDefault(value) {
    // Check if the value is undefined or null
    if (value === undefined || value === null) {
        return 0;
    }
    // If the value is not undefined or null, return the value itself
    return value;
}

const getTotalDimennsion = (arr, method, setMethod) => {
    let height = 0;
    let width = 0;
    arr.length > 0 && arr.forEach((item) => {
        if (item?.lengthType === 1) {
            height += parseFloat(item?.dimension
            )
        }
        else if (item?.lengthType === 2) {
            width += parseFloat(item?.dimension
            )
        }
    })
    setMethod(() => ({
        height: height,
        width: width
    }))
}

const getAdditionalArticlePrice = (arr, dimension) => {
    let articlePricing = 0.0;
    typeof (arr) === "object" && arr?.length > 0 && arr.forEach(item => {
        if (item?.priceType == 2) {
            articlePricing += parseFloat(item?.price)
        }
        else if (item?.priceType == 1) {
            articlePricing += parseFloat(item?.price) * parseFloat(dimension)
        }
    })
    return formatValue(articlePricing)
}

const getColorPricing = (area, price, isStock) => {
    const barLength = 6.5;
    area = isNaN(area) ? 0 : area;
    var returnPrice = 0

    // if (!isStock) {
    //     let num = Math.ceil((area / barLength))

    //     returnPrice = formatValue(parseFloat(price) * barLength * num)
    // } else {
    //     returnPrice = formatValue(parseFloat(area) * parseFloat(price))
    // }

    returnPrice = formatValue(parseFloat(area) * parseFloat(price))

    return returnPrice
}

export const getTransomsData = (arr) => {
    let transomsMap = new Map();

    arr.forEach((item) => {
        const id = item?.id;
        const price = parseFloat(item?.price) * parseFloat(item?.length);

        if (transomsMap.has(id)) {
            // If the map already has an entry for this id, update the price
            const existingItem = transomsMap.get(id);
            existingItem.price += price;
        } else {
            // If not, create a new entry
            let obj = {
                id: id,
                price: price,
                name: item?.name,
                orientation: item?.orientation,
                frameType: item?.frameType,
            };
            transomsMap.set(id, obj);
        }
    });

    // Convert map values back to an array
    let transomsArray = Array.from(transomsMap.values());

    // Format the prices in the array
    transomsArray.forEach((item) => {
        item.price = formatValue(item.price);
    });

    return transomsArray;
};

export const addTotalPricingKey = (pricingData, totalEachPrice, setSavePriceData) => {
    // For putting totalprice for frame profile
    let map = totalEachPrice.frameProfile.reduce((acc, obj) => {
        acc[obj.id] = obj.total;
        return acc;
    }, {});
    pricingData.frameProfile.forEach(obj => {
        if (map[obj.id] !== undefined) {
            obj.totalPrice = map[obj.id];
        }
    });
    // for putting total price for sash profile
    let sashProfileMap = totalEachPrice.sash.reduce((acc, obj) => {
        acc[obj.id] = obj.total;
        return acc;
    }, {});
    pricingData.sash.forEach(obj => {
        if (sashProfileMap[obj.id] !== undefined) {
            obj.totalPrice = sashProfileMap[obj.id];
        }
    });
    // for putting total price for glazing
    let glazingMap = totalEachPrice.glazing.reduce((acc, obj) => {
        acc[obj.id] = obj.total;
        return acc;
    }, {});
    pricingData.glazing.forEach(obj => {
        if (glazingMap[obj.id] !== undefined) {
            obj.totalPrice = glazingMap[obj.id];
        }
    });

    // for putting total price of added frames
    let addedFramePriceMap = totalEachPrice.addedFrames.reduce((acc, obj) => {
        acc[obj.id] = obj.total;
        return acc;
    }, {});
    pricingData.addedFrames.forEach(obj => {
        if (addedFramePriceMap[obj.id] !== undefined) {
            obj.totalPrice = addedFramePriceMap[obj.id];
        }
    });

    // for putting total price for panel
    let panelMap = totalEachPrice.panels.reduce((acc, obj) => {
        acc[obj.id] = obj.total;
        return acc;
    }, {});
    pricingData.panel.forEach(obj => {
        if (panelMap[obj.id] !== undefined) {
            obj.totalPrice = panelMap[obj.id];
        }
    });

    // for putting total price for texture
    let textureMap = totalEachPrice.texture.reduce((acc, obj) => {
        acc[obj.id] = obj.total;
        return acc;
    }, {});
    pricingData.texture.forEach(obj => {
        if (textureMap[obj.id] !== undefined) {
            obj.totalPrice = textureMap[obj.id];
        }
    });

    // for putting total price for texture
    let designMap = totalEachPrice.design.reduce((acc, obj) => {
        acc[obj.id] = obj.total;
        return acc;
    }, {});

    pricingData.design.forEach(obj => {
        if (designMap[obj.id] !== undefined) {
            obj.totalPrice = designMap[obj.id];
        }
    });

    // console.log(pricingData.design, "pricingData design")

    // Putting total price for transoms
    let transomsMap = totalEachPrice.transoms.reduce((acc, obj) => {
        acc[obj.id] = obj.total;
        return acc;
    }, {});
    pricingData.transoms.forEach(obj => {
        if (transomsMap[obj.id] !== undefined) {
            obj.totalPrice = transomsMap[obj.id];
        }
    });

    // Putting total price for bead
    let beadMap = totalEachPrice.bead.reduce((acc, obj) => {
        acc[obj.id] = obj.total;
        return acc;
    }, {});
    pricingData.bead.forEach(obj => {
        if (beadMap[obj.id] !== undefined) {
            obj.totalPrice = beadMap[obj.id];
        }
    });

    // Putting total price for coupler
    let couplerMap = totalEachPrice.coupler.reduce((acc, obj) => {
        acc[obj.id] = formatValue(parseFloat(obj.total));
        return acc;
    }, {});
    pricingData.coupler.forEach(obj => {
        if (couplerMap[obj.id] !== undefined) {
            obj.totalPrice = couplerMap[obj.id];
        }
    });

    // Putting total price for coupler
    let bayPost = totalEachPrice.bayPost.reduce((acc, obj) => {
        acc[obj.id] = formatValue(parseFloat(obj.total));
        return acc;
    }, {});
    pricingData.bayPost.forEach(obj => {
        if (bayPost[obj.id] !== undefined) {
            obj.totalPrice = bayPost[obj.id];
        }
    });

    // for putting total price for sill
    if (pricingData?.sill.length > 0) {
        pricingData.sill[0].totalPrice = totalEachPrice.sill.total
    }
    // for putting total price for threshold
    if (pricingData?.threshold) {
        pricingData.threshold.totalPrice = parseFloat(totalEachPrice.threshold)
    }

    // for putting totalPrice for the color
    if (pricingData?.color) {
        pricingData.color[`totalPrice`] = totalEachPrice.color
    }

    // For putting totalPrice for the floating mullion
    if (pricingData?.floatingMullion?.length > 0) {
        let floatingMullionMap = totalEachPrice.floatingMullions.reduce((acc, obj) => {
            acc[obj.id] = obj.total;
            return acc;
        }, {});
        pricingData.floatingMullion.forEach(obj => {
            if (floatingMullionMap[obj.id] !== undefined) {
                obj.totalPrice = floatingMullionMap[obj.id];
            }
        });
    }

    setSavePriceData(true)
}

export function addLengthKey(arr1, arr2) {
    for (let i = 0; i < arr1.length; i++) {
        const index = Math.floor(i / 2);
        if (arr2[index] && typeof arr2[index].length !== 'undefined') {
            arr1[i].length = arr2[index].length;
        }
    }
    return arr1
}

export const getPriceableArea = (arr, index, type) => {
    let area = 0;
    arr?.forEach((item) => {
        if (item?.index == index) {
            area = parseFloat(item?.width * item?.height);
        } else if (type === "design" && index === null && item?.index === 0) {
            area = parseFloat(item?.height * item?.width);
        }
    });
    return area;
};

export const getGlassSizeArea = (item, hangingData, allSizeTags, sashSize, frameHeight) => {
    let area = 0;


    const addedHanging = hangingData.find((c) => c.referencePoint === item?.index ? item?.index : item?.glassIndex);

    let length = 0;

    let cuttingWidth = 0;
    let cuttingHeight = 0;

    let appliedWidth = 0;
    let appliedHeight = 0;

    if (addedHanging && addedHanging.length > 0) {
        addedHanging.tagsIds.forEach((tagId) => {
            const matchingObj = allSizeTags.find((obj1) => obj1.id === tagId);
            if (matchingObj) {
                length += matchingObj?.length;

                cuttingWidth += matchingObj?.length
            }
        });

        addedHanging.tagsIdsHeight.forEach((tagId) => {
            const matchingObj = allSizeTags.find((obj1) => obj1.id === tagId);
            if (matchingObj) {
                length += matchingObj?.length;

                cuttingHeight += matchingObj?.length
            }
        });
    }

    if (item && item.frameType === 'Panel') {
        // console.log(item, cuttingWidth, frameHeight, cuttingHeight, hangingData, "pricingData area")

        if (item.glassIndex > 0) {
            appliedWidth = (sashSize[item.glassIndex - 1] - cuttingWidth) / 1000
            appliedHeight = (frameHeight - cuttingHeight) / 1000
        }

        if (appliedWidth > 0 && appliedHeight > 0) {
            area = (appliedWidth * appliedHeight)
        }
    } else {
        if (item.index > 0) {
            appliedWidth = (sashSize[item.index - 1] - cuttingWidth) / 1000
            appliedHeight = (frameHeight - cuttingHeight) / 1000
        }

        if (appliedWidth > 0 && appliedHeight > 0) {
            area = (appliedWidth * appliedHeight)
        }
    }

    return area;
};

const getOverSizeGlazinPercentSlab = async (accessToken, area, spceId) => {
    try {
        const res = await getOverGlazingPriceSlabService(accessToken, area, spceId);

        // Safely check and retrieve the percentage
        let overSizePercent = 0;
        if (res && res.data && res.data.statusCode === 200) {
            overSizePercent = res?.data?.entity?.percentage || 0;
        }

        return overSizePercent;
    } catch (error) {
        console.error("Error fetching oversize percentage:", error);
        return 0;
    }
};

const getHardwareColorPrice = (colorPrice) => {
    if (colorPrice && !isNaN(colorPrice) && colorPrice !== "") {
        return colorPrice
    }
    else {
        return 0
    }
}