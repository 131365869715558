import axios from "axios";
import { servicePath } from "../utility/data";

export const addFrameAncillary = (accessToken, data) => {
    const formData = new FormData();
    formData.append("Id", '');
    formData.append('Description', data.description);
    formData.append('AddToFrame', false);
    formData.append('Discountable', data.discountable);
    formData.append('HideInFrame', data.hideFrame);
    formData.append('CreatedBy', '7c9e6679-7425-40de-944b-e07fc1f90ae7');

    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }
    return axios
        .post(`${servicePath}/Frame/AddFrameAncillary`, formData, { headers })
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            console.log(error);
            return error.response.data;
        });
};

export const addAncillary = (accessToken, data) => {
    const formData = new FormData();
    formData.append("Id", '');
    formData.append('Description', data);
    formData.append('AddToFrame', false);
    formData.append('Discountable', false);
    formData.append('HideInFrame', false);
    formData.append('CreatedBy', '7c9e6679-7425-40de-944b-e07fc1f90ae7');

    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }
    return axios
        .post(`${servicePath}/Frame/AddFrameAncillary`, formData, { headers })
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            console.log(error);
            return error.response.data;
        });
};

export const getFrameAncillary = (accessToken) => {
    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }
    return axios
        .get(`${servicePath}/Frame/GetAllFrameAncillaries`, { headers })
        .then((res) => {
            return res?.data;
        })
        .catch((error) => {
            console.log(error);
            return error.response.data;
        });
};

export const deleteFrameAncillary = (accessToken, id) => {
    const formData = new FormData()
    formData.append("Id", id)
    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }
    return axios
        .post(`${servicePath}/Frame/DeleteFrameAncillary`, formData, { headers })
        .then((res) => {
            return res?.data;
        })
        .catch((error) => {
            console.log(error);
            return error.response.data;
        });
}

// export const updateOrder = (accessToken, currentId, prevIndex, prevId, currIndex) => {

//     const headers = {
//         'Authorization': `Bearer ${accessToken}`
//     }

//     return axios
//         .post(`${servicePath}/Project/UpdateProjectAncillaryOrder`, [
//             {
//                 id: currentId,
//                 sequenceNo: prevIndex,
//             },
//             {
//                 id: prevId,
//                 sequenceNo: currIndex,
//             },
//         ], { headers })
//         .then((res) => {
//             return res.data;
//         })
//         .catch((error) => {
//             console.log(error);
//             return error.response.data;
//         });
// }

// update order of selected item
export const updateOrder = (accessToken, id, pageType, direction) => {

    // console.log(id, pageType, direction, "update order")

    var apiName = "Frame/UpdateFrameAncillaryOrder"

    var requestBody = [
        {
            "id": id,
            "sequenceNo": 0,
            "type": direction
        }
    ]

    if (pageType === 2) {
        apiName = "Project/UpdateProjectAncillaryOrder"

        requestBody = {
            "id": id,
            "sequenceNo": 0,
            "type": direction
        }

    } else {
        apiName = "Frame/UpdateFrameAncillaryOrder"

        requestBody = [
            {
                "id": id,
                "sequenceNo": 0,
                "type": direction
            }
        ]
    }

    const headers = {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
    }

    return axios
        .post(`${servicePath}/${apiName}`, requestBody, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
}

export const getProjectAncillaries = (accessToken) => {
    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    return axios
        .get(`${servicePath}/Project/GetAllProjectAncillaries`, { headers })
        .then((res) => {
            return res?.data;
        })
        .catch((error) => {
            console.log(error);
            return error.response.data;
        });
}

export const getUniqueFrameAncillary = (accessToken, id) => {
    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    const formData = new FormData();
    formData.append("Id", id);

    return axios
        .post(`${servicePath}/Frame/GetFrameAncillary`, formData, { headers })
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            console.log(error);
            return error.response.data;
        });
};

export const updateAncillary = (accessToken, id, data) => {
    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    const formData = new FormData();
    formData.append("Id", id);
    formData.append('Description', data.description);
    formData.append('AddToFrame', !data.addNew ? false : data.addNew);
    formData.append('Discountable', !data.discountable ? false : data.discountable);
    formData.append('HideInFrame', !data.hide ? false : data.hide);

    return axios
        .post(`${servicePath}/Frame/UpdateFrameAncillary`, formData, { headers })
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            console.log(error);
            return error.response.data;
        });
};

export const addAncillaryProject = (accessToken, data) => {
    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    const formData = new FormData();
    formData.append("Id", '');
    formData.append('Description', data);
    formData.append('AddToFrame', false);
    formData.append('Discountable', false);

    return axios
        .post(`${servicePath}/Project/AddProjectAncillary`, formData, { headers })
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            console.log(error);
            return error.response.data;
        });
};


export const updateAncillaryProject = (accessToken, id, data) => {
    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    const formData = new FormData();
    formData.append("Id", id);
    formData.append('Description', data?.description);
    formData.append('AddToFrame', !data?.newprojects ? false : data.newprojects);
    formData.append('Discountable', !data?.discountable ? false : data.discountable);

    return axios
        .post(`${servicePath}/Project/UpdateProjectAncillary`, formData, { headers })
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            console.log(error);
            return error.response.data;
        });
};


export const getUniqueProjectAncillary = (accessToken, id) => {
    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    const formData = new FormData();
    formData.append("Id", id);

    return axios
        .post(`${servicePath}/Project/GetProjectAncillary`, formData, { headers })
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            console.log(error);
            return error.response.data;
        });
}


export const updateProjectAncillaryOrder = (accessToken, currentId, prevIndex, prevId, currIndex) => {
    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    return axios
        .post(`${servicePath}/Project/UpdateProjectAncillaryOrder`, [
            {
                id: currentId,
                sequenceNo: prevIndex,
            },
            {
                id: prevId,
                sequenceNo: currIndex,
            },
        ], { headers })
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            console.log(error);
            return error.response.data;
        });
}

export const deleteProjectAncillary = (accessToken, id) => {
    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    const formData = new FormData()
    formData.append("Id", id)

    return axios
        .post(`${servicePath}/Project/DeleteProjectAncillary`, formData, { headers })
        .then((res) => {
            return res?.data;
        })
        .catch((error) => {
            console.log(error);
            return error.response.data;
        });
}