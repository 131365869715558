import React from 'react'
import InnerLayout from "../../../../../../layouts/internalLayout"
import SpacebarLeft from './spacebars-left'
import SpacebarRight from './spacebars-right'
import { allGlazingCollection, allGlazingColours, allGlazingPalette } from '../../../../../../services/glazingServices'
import { useState } from 'react'
import { useMessage } from '../../../../../../context/messageContext'
import { useProductPaletteContext } from '../../../../../../context/productPaletteContext'
import { useEffect } from 'react'
import { Oval } from 'react-loader-spinner'
import NoDataCard from '../../../../../../components/NoDataCard'

const Spacebar = () => {


  const [tabType, setTabType] = useState('colours')
  const [allColours, setAllColours] = useState([])
  const [allPalette, setAllPalette] = useState([])
  const [allCollection, setAllCollection] = useState([])
  const [loading, setLoading] = useState(false)
  const { setNewItemList, setTimestamp, setActiveIndex } = useProductPaletteContext();
  const { setMessage, setMessageType } = useMessage();
  const [commonHeading, setCommonHeading] = useState('')

  const accessToken = localStorage.getItem('access_token')


  useEffect(() => {
    if (tabType === "colours") {
      setTimestamp('')
      getAllColoursServices()
    }

    if (tabType === 'palette') {
      setTimestamp('')
      getAllPaletteServices()
    }

    if (tabType === 'collections') {
      setTimestamp('')
      getAllCollectionServices()
    }
  }, [tabType])

  const getAllColoursServices = async (type) => {
    setLoading(true)
    const res = await allGlazingColours(2, accessToken)
    if (res?.data?.statusCode === 200) {
      let data = res?.data?.entity
      setNewItemList(res?.data.entity)
      setAllColours(res?.data.entity)
      if (type === 'added') {
        setTimestamp(data[data.length - 1]?.id)
        setActiveIndex(data?.length - 1)
      }

      if (res?.data?.entity < 1) {
        setLoading(false)
      }
      setMessageType('success')
      setMessage(res.message)
      type = '';
    } else {
      setLoading(false)
      setMessageType('error')
      setMessage(res.message)
    }

  }

  const getAllPaletteServices = async (type) => {
    setLoading(true)
    const res = await allGlazingPalette(2, accessToken)
    if (res?.data?.statusCode === 200) {
      let data = res?.data?.entity
      setNewItemList(res?.data.entity)
      setAllPalette(res?.data.entity)
      if (type === 'added') {
        setTimestamp(data[data.length - 1]?.id)
        setActiveIndex(data?.length - 1)
      }

      if (res?.data?.entity < 1) {
        setLoading(false)
      }
      setMessageType('success')
      setMessage(res.message)
      type = '';
    } else {
      setLoading(false)
      setMessageType('error')
      setMessage(res.message)
    }
  }

  const getAllCollectionServices = async (type) => {
    setLoading(true)
    const res = await allGlazingCollection(2, accessToken)
    if (res?.data?.statusCode === 200) {
      let data = res?.data?.entity
      setNewItemList(res?.data.entity)
      setAllCollection(res?.data.entity)
      if (type === 'added') {
        setTimestamp(data[data.length - 1]?.id)
        setActiveIndex(data?.length - 1)
      }

      if (res?.data?.entity < 1) {
        setLoading(false)
      }

      setMessageType('success')
      setMessage(res.message)
      type = '';
    } else {
      setLoading(false)
      setMessageType('error')
      setMessage(res.message)
    }
  }

  return (
    <div>
      {loading && (
        <div className='loader_main full_loader'>
          <Oval
            height={50}
            width={50}
            color="#44C8F5"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel='oval-loading'
            secondaryColor="#44C8F5"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      )}
      <InnerLayout pageTitle="Spacer bars" layoutType=""
        leftContent={
          <>
            {allColours && allColours.length > 0 ? (
              <SpacebarLeft tabType={tabType} getAllCollectionServices={getAllCollectionServices} setTabType={setTabType} allColours={allColours} setLoading={setLoading} getAllColoursServices={getAllColoursServices} getAllPaletteServices={getAllPaletteServices} commonHeading={commonHeading} setCommonHeading={setCommonHeading} allPalette={allPalette} allCollection={allCollection} />
            ) : (
              <div className='mt-5'>
                <NoDataCard text="items" loading={loading} isText={true} />
              </div>
            )}
          </>
        }
        rightContent={<SpacebarRight allColours={allColours} getAllColoursServices={getAllColoursServices} getAllCollectionServices={getAllCollectionServices} getAllPaletteServices={getAllPaletteServices} tabType={tabType} setLoading={setLoading} allPalette={allPalette} allCollection={allCollection} />} />
    </div>
  )
}

export default Spacebar