import React, { useEffect, useState } from "react";
import { Alert, FormGroup, Label, NavLink } from "reactstrap";
import { capitalizeFirstLetter } from "../utility/helper";
import { MenuItem, OutlinedInput, Select } from "material-ui-core";
import { Image } from "react-bootstrap";
import { MenuProps } from "../utility/data";
import { MdInfoOutline, MdOutlineKeyboardArrowDown, MdOutlineVisibility, MdOutlineVisibilityOff } from "react-icons/md";
import { BsTools } from "react-icons/bs";
import { useDesigner } from "../context/designerContext";
import { setUpdateManufacturingHardwareStatus } from "../services/ManufacturingServices";
import { useMessage } from "../context/messageContext";
import { getHardwareDataService } from "../services/3dModelServices";

const ManufacturingHardware = ({
  activeTab,
  hardwareListing,
  setAllHardware,
  setHardwareType,
  referencePoint,
  manufacturingToggle,
  collectionId,
  frameStyleProdId,
  accessToken,
  additionalAcc
}) => {
  const [showListing, setShowListing] = useState(false);
  const [selectedHardware, setSelectedHardware] = useState("");

  const { setMessage, setMessageType } = useMessage();
  const { multiSelectRefPoints, setMultiSelectRefPoints } = useDesigner();


  useEffect(() => {
    if (activeTab === "Hardware" && additionalAcc === "2") {
      setHardwareType("additionalHardware")
    } else if (activeTab === "Hardware" && selectedHardware) {
      setHardwareType(selectedHardware);
    } else {
      setHardwareType("")
    }
  }, [additionalAcc, selectedHardware, activeTab])

  useEffect(() => {
    if (multiSelectRefPoints.length > 0) {
      setShowListing(true);
    } else {
      setShowListing(false);
    }
  }, [multiSelectRefPoints]);

  useEffect(() => {
    if (!manufacturingToggle && activeTab == "Hardware") {
      setSelectedHardware("");
    }
  }, [manufacturingToggle, activeTab]);

  useEffect(() => {
    if (referencePoint !== null && activeTab === "Hardware") {
      getHardwareListingData(referencePoint?.index);
    }
  }, [referencePoint, activeTab]);

  // Ensure selectedHardware is set properly if hardwareListing changes
  // useEffect(() => {
  //   if (
  //     hardwareListing?.length > 0 &&
  //     selectedHardware === "" &&
  //     manufacturingToggle && activeTab === "Hardware"
  //   ) {
  //     setSelectedHardware(hardwareListing[2]);
  //     setHardwareType(hardwareListing[2]);
  //   }
  // }, [hardwareListing, activeTab]);

  const getHardwareListingData = async (referencePoint) => {
    const data = {
      frameCollectionId: collectionId,
      type: 1,
      referencePoint: referencePoint,
      frameStyleId: frameStyleProdId?.id,
    };

    const res = await getHardwareDataService(accessToken, data);

    if (res?.data?.statusCode === 200) {
      const item = res?.data?.entity?.find(
        (ele) => ele?.id === selectedHardware?.id
      );
      setAllHardware(res?.data?.entity);
      setSelectedHardware(item);
    } else {
      setSelectedHardware(selectedHardware);
      setAllHardware([]);
    }
  };

  const makeHardwareEnableDisable = async (item) => {
    const status = !item?.status;
    let data = {
      id: 0,
      hardwareStyleId: item?.id,
      referencePoint: referencePoint?.index,
      frameStyleId: frameStyleProdId?.id,
      status: status,
    };

    const res = await setUpdateManufacturingHardwareStatus(accessToken, data);

    if (res?.data?.statusCode == 200) {
      setMessageType("success");
      setMessage(res?.data?.message);
      getHardwareListingData(referencePoint?.index);
    } else {
      setMessageType("error");
      setMessage(res?.data?.message);
      getHardwareListingData(referencePoint?.index);
    }
  };

  return (
    <div className="ms-2">
      <FormGroup className="form-group">
        <Label for="hardware" className="text-dark">
          Hardware
        </Label>
        <Select
          fullWidth
          className="w-100"
          labelId="hardware"
          id="hardware"
          value={selectedHardware}
          label="hardware"
          onChange={(e) => {
            setSelectedHardware(e?.target?.value);
            setMultiSelectRefPoints([]);
          }}
          input={<OutlinedInput className="w-100" name="hardware" />}
          MenuProps={MenuProps}
          IconComponent={() => (
            <MdOutlineKeyboardArrowDown
              size={20}
              className="me-1 MuiSvgIcon-root MuiSelect-icon"
            />
          )}
        >
          {hardwareListing &&
            hardwareListing.map((item, index) => (
              <MenuItem key={index} value={item}>
                {capitalizeFirstLetter(item?.name)}
              </MenuItem>
            ))}
        </Select>
      </FormGroup>

      <div className="mt-1 right_list">
        {selectedHardware !== null &&
          selectedHardware?.hardwareStyles &&
          selectedHardware?.hardwareStyles.length > 0 ? (
          selectedHardware?.hardwareStyles?.map((item, index) => (
            <>
              {showListing && (
                <div
                  key={index}
                  className="position-relative d-flex align-items-center right_item_wrap has_delete has_copy"
                >
                  <div className={`right_item w-100`}>
                    <span
                      className={`me-2 image_icon ${item?.status ? "" : "opacity-50"
                        }`}
                    >
                      {item.imagePath ? (
                        <Image src={item.imagePath} width={25} height={25} />
                      ) : (
                        <BsTools size={25} color="#989898" />
                      )}
                    </span>
                    <div className="right_title">
                      <span
                        className={`text-truncate ${item?.status ? "text-dark" : "text-muted"
                          }`}
                      >
                        {item.name}
                      </span>
                    </div>
                    <span className="position-absolute action_btns">
                      <NavLink onClick={() => makeHardwareEnableDisable(item)}>
                        {item?.status ? (
                          <MdOutlineVisibility className="text-muted" size={18} />
                        ) : (
                          <MdOutlineVisibilityOff className="text-muted" size={18} />
                        )}
                      </NavLink>
                    </span>
                  </div>
                </div>
              )}
            </>
          ))
        ) : (
          <>
            {selectedHardware !== null &&
              selectedHardware?.hardwareStyles?.length === 0 && (
                <Alert color="secondary" className="d-flex gap-2 p-2 my-2">
                  <span>
                    <MdInfoOutline size={18} />
                  </span>
                  <span>No hardware to show</span>
                </Alert>
              )}
          </>
        )}
      </div>
    </div>
  );
};

export default ManufacturingHardware;
