import React, { useState, useEffect } from 'react'
import { Form } from 'react-bootstrap';
import { MdOutlineKeyboardArrowDown } from 'react-icons/md';
import { MenuItem, OutlinedInput, Select } from 'material-ui-core';
import {
    Row,
    Col,
    Button,
    FormGroup,
    Label,
} from 'reactstrap';
import { MenuProps, noSelectOptions } from '../utility/data';
import { useRightContext } from '../context/rightMenuContext'
import { allHardwareCollection } from '../services/productHardwareServices';
import NumberInputDecimal from './NumberInputDecimal';

const SystemHardware = ({ isEditable, activeTab, accessToken, selectItemDetails, updateItemDetailsServices, handleFormReset }) => {

    const { timestamp } = useRightContext()

    const [hardwareCollection, SetHardwareCollection] = useState([]);

    const [itemId, setItemId] = useState('')
    const [hardwareId, setHardwareId] = useState("")
    const [handleOffset, setHandleOffset] = useState("0")

    useEffect(() => {
        if (activeTab === "Hardware") {
            getHardwareCollections()
        }
    }, [activeTab])

    // setting all the value for prefilled inputs
    useEffect(() => {
        // console.log(selectItemDetails, "data left")

        setItemId(selectItemDetails?.id || '')

        setHandleOffset(selectItemDetails?.handleOffset || 0)
    }, [selectItemDetails])

    useEffect(() => {
        if (hardwareCollection && hardwareCollection.length > 0) {
            setHardwareId(selectItemDetails?.hardwareCollectionId || hardwareCollection[0]?.id)
        }
    }, [selectItemDetails, hardwareCollection])

    const getHardwareCollections = async () => {
        const res = await allHardwareCollection(accessToken)

        if (res && res && res.status === 200) {
            if (res?.data?.statusCode === 200) {
                if (res?.data?.entity !== null) {
                    if (res?.data?.entity?.length > 0) {
                        SetHardwareCollection(res?.data?.entity)
                    } else {
                        SetHardwareCollection(noSelectOptions)
                    }
                }
            }
        }
    }

    const handleFormSubmit = () => {

        var formDataValues = {
            'id': selectItemDetails?.id,
            'hardwareId': hardwareId,
            'handleOffset': handleOffset,
        }

        updateItemDetailsServices(formDataValues, "Hardware")
    }

    return (
        <Form className='h-100 d-flex flex-column flex-grow-1 justify-content-between'>
            <div className='inside_scroll has_tab has_btns has_head'>
                <Row>
                    <Col xxl="12">
                        <Row>
                            <Col lg="6">
                                <FormGroup className="form-group">
                                    <Label for="collectionSelect">
                                        Collection
                                    </Label>

                                    <Select
                                        labelId="collectionSelect"
                                        id="collectionSelect"
                                        value={hardwareId || ''}
                                        label="collectionSelect"
                                        onChange={(e) => { setHardwareId(e.target.value) }}
                                        input={<OutlinedInput className='w-100' name='collectionSelect' />}

                                        MenuProps={MenuProps}

                                        IconComponent={() => (
                                            <MdOutlineKeyboardArrowDown size={20} className='me-1 MuiSvgIcon-root MuiSelect-icon' />
                                        )}
                                    >
                                        {hardwareCollection && hardwareCollection.map((o, index) => (
                                            <MenuItem key={index} value={o?.id}>
                                                {o?.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormGroup>
                            </Col>

                            <Col lg="6">
                                <FormGroup className="form-group">
                                    <Label for="handleOffset">Handle offset</Label>

                                    <NumberInputDecimal
                                        name="handleOffset"
                                        value={handleOffset}
                                        setValue={setHandleOffset}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>

            {isEditable && (
                <div className='d-flex gap-2 gap-xl-3 main_buttons'>
                    <Button
                        color="primary"
                        size="md"
                        className='px-4'
                        onClick={() => handleFormSubmit()}
                        disabled={!timestamp}
                    >
                        Save Changes
                    </Button>

                    <Button
                        color="primary"
                        size="md"
                        outline
                        className='px-4'
                        onClick={() => handleFormReset()}
                        disabled={!timestamp}
                    >
                        Reset
                    </Button>
                </div>
            )}
        </Form>
    )
}

export default SystemHardware