import React from 'react'
import { useState } from 'react';
import { useProductPaletteContext } from '../../context/productPaletteContext';
import {
    Row,
    Col,
    Button,
    Label,
    FormGroup,
    Input,
} from 'reactstrap';
import { Form } from 'react-bootstrap';
import { MenuItem, OutlinedInput, Select } from 'material-ui-core';
import { MdOutlineKeyboardArrowDown } from 'react-icons/md';
import { MenuProps } from '../../utility/data';
import { addUpdateGlazingPalette, textureImageMaster, uniqueGlazingPalette } from '../../services/glazingServices';
import { useMessage } from '../../context/messageContext';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRightContext } from '../../context/rightMenuContext';


const TexturePalette = ({ setLoading, setCommonHeading, allPalette, tabType, getAllPaletteServices }) => {

    const accessToken = localStorage.getItem('access_token')
    const { handleEdit, setTimestamp, timestamp, activeIndex, setActiveIndex, newItemList } = useProductPaletteContext();
    const { setMessage, setMessageType } = useMessage()
    const [name, setName] = useState('')
    const [imageId, setImageId] = useState(0)
    const [imageValues, setImageValues] = useState()
    const [id, setId] = useState()

    const firstEl = allPalette[0];

    const { setSelectedRightItem, selectedRightItem } = useRightContext()
    const navigate = useNavigate()

    useEffect(() => {
        setActiveIndex(0)
    }, [tabType])

    useEffect(() => {
        if (firstEl && tabType === 'palette') {
            if (allPalette?.length && activeIndex !== 0 && tabType === 'palette') {
                setTimestamp(timestamp)
            } else {
                setTimestamp(firstEl?.id)
            }
        }
    }, [allPalette, timestamp])

    useEffect(() => {
        if (timestamp !== ' ' && timestamp && tabType === 'palette' && newItemList?.length > 0) {
            getUniquePaletteServices()
        }
    }, [timestamp])

    const reset = () => {
        getUniquePaletteServices()
        getAllPaletteServices()
    }

    useEffect(() => {
        textureImageMasterService()
    }, [])

    useEffect(() => {
        if (newItemList?.length === 0) {
            setTimestamp('')
            setName('')
            setCommonHeading('')
            setImageId(0)
        }
    }, [newItemList])

    const getUniquePaletteServices = async () => {

        const res = await uniqueGlazingPalette(timestamp, accessToken)
        if (res && res.status === 200) {
            if (res?.data?.statusCode === 200) {
                const selectedRight = allPalette?.find((p) => p.id == timestamp)
                setSelectedRightItem(selectedRight)
                setName(res?.data.entity?.name)
                setCommonHeading(res?.data.entity?.name)
                setImageId(res?.data.entity?.imageId)

                // setTimestamp(timestamp)
                setId(res?.data.entity?.id)
            } else {
                setMessage(res?.data?.message ? res.data.message : 'Something went wrong');
                setMessageType('error')
            }

            setLoading(false)
        } else {
            setLoading(false)
            setMessage(res?.message ? res.message : 'Something went wrong');
            setMessageType('error')
            if (res?.message === "Network Error") {
                navigate('/error/network')
                setLoading(false)
            }

        }
    }

    const updateGlazingPaletteServices = async () => {
        setLoading(true)
        const data = {
            id: timestamp,
            name: name,
            desc: "",
            specification: "",
            colourId: 1,
            thickness: 0,
            noOfPanes: 0,
            textureSide: 0,
            maximumWidth: 0,
            maximumHeight: 0,
            maximumsquare: 0,
            imageId: imageId,
            typeId: 3,
            hardwareCollectionId: 0,
            sequenceNo: 0
        }

        const res = await addUpdateGlazingPalette(data, accessToken)
        if (res && res.status === 200) {
            if (res?.data?.statusCode === 200) {
                setMessageType('success')
                setMessage(res?.data?.message ? res.data.message : 'Added new palette')
                getAllPaletteServices()
                setLoading(false)
            } else {
                setMessage(res?.data?.message ? res.data.message : 'Something went wrong')
                setMessageType('error')
                getAllPaletteServices()
                setLoading(false)

            }
        } else {
            setLoading(false)
            setMessage(res?.message ? res.message : 'Something went wrong');
            setMessageType('error')
            getAllPaletteServices()
            if (res?.message === "Network Error") {
                navigate('/error/network')
            }
        }
    }


    const textureImageMasterService = async () => {

        const res = await textureImageMaster(accessToken)
        if (res?.data?.statusCode === 200) {
            setMessageType('success')
            setMessage(res.message)
            setImageValues(res?.data?.entity)
        } else {
            setMessageType('error')
            setMessage(res.message)
        }
    }

    const handleNameChange = (e) => {
        const value = e.target.value;
        if (e.target.value.length <= 50) {
            setName(value)
            handleEdit(value)
        }
    }

    const handleImageId = (e) => {
        const value = e.target.value
        setImageId(typeof value === 'string' ? value.split(',') : value)
    }

    return (
        <>
            <Form className='h-100 d-flex flex-column flex-grow-1 justify-content-between'>
                <div className='inside_scroll has_btns has_head'>
                    <Row>
                        <Col lg="6">
                            <FormGroup className="form-group">
                                <Label for="name">
                                    Name
                                </Label>
                                <Input disabled={!selectedRightItem?.isSelf} className='mb-1' name='name' value={name} onChange={(e) => handleNameChange(e)} />
                                {name && name.length > 49 && (
                                    <div className='mt-1 text-muted text-small'>Max 50 characters are allowed</div>
                                )}

                                {(!name && id) && (
                                    <div className='mt-1 text-danger text-small'>Name is required</div>
                                )}
                            </FormGroup>
                        </Col>
                        <Col lg="6">
                            <FormGroup className="form-group">
                                <Label for="image">
                                    Image
                                </Label>
                                <Select
                                    labelId="printdoc-label"
                                    id="image"
                                    value={imageId}
                                    disabled={!selectedRightItem?.isSelf}
                                    label="image"
                                    onChange={handleImageId}
                                    input={<OutlinedInput className='w-100' name='colours' />}

                                    MenuProps={MenuProps}
                                    IconComponent={() => (
                                        <MdOutlineKeyboardArrowDown size={20} className='me-1 MuiSvgIcon-root MuiSelect-icon' />
                                    )}
                                >
                                    {imageValues?.map((item, index) => {
                                        return (
                                            <MenuItem value={item?.id} key={index} >{item?.name}</MenuItem>

                                        )
                                    })}
                                </Select>
                            </FormGroup>
                        </Col>
                    </Row>
                </div>
                {selectedRightItem?.isSelf && <div className='d-flex gap-2 gap-xl-3 main_buttons mt-4'>
                    <Button
                        color="primary"
                        size="md"
                        className='px-4'
                        disabled={newItemList?.length === 0 || !name || name.length > 49}
                        onClick={() => updateGlazingPaletteServices()}
                    >
                        Save Changes
                    </Button>

                    <Button
                        color="primary"
                        size="md"
                        outline
                        className='px-4'
                        disabled={newItemList?.length === 0}

                        onClick={() => reset()}
                    >
                        Reset
                    </Button>
                </div>}
            </Form>
        </>
    )
}

export default TexturePalette