
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import { useMessage } from '../context/messageContext';

// const usePlatformService = () => {

//     const navigate = useNavigate()

//     const { setMessage, setMessageType } = useMessage();

//     return { getAllQuotations }

// }

// export default usePlatformService


export const getAllQuotations = async (fetch_url, userId, filters) => {

    const formData = new FormData();
    formData.append("user_id", userId);
    // formData.append("status", 10);

    if (filters && filters.filter) {
        formData.append("status", filters.status);
        formData.append("date", filters.date);

        if (filters.date == 3) {
            formData.append("start_date", filters.start_date);
            formData.append("end_date", filters.end_date);
        }
    }

    return axios
        .post(`https://${fetch_url}/api/orbit/quotations/orbit_quote_list`, formData)
        .then((res) => {
            return res;
        })
        .catch((error) => {
            // console.log(error);
            return error;
        });
}

export const GetQuoteHostUrl = async (fetch_url, quoteId) => {

    const formData = new FormData();
    formData.append("quotation_id", quoteId);

    return axios
        .get(`https://${fetch_url}/api/orbit/getHostUrl`)
        .then((res) => {
            return res;
        })
        .catch((error) => {
            return error;
        });
}

export const getAllQuotationsSuper = async (fetch_url) => {

    return axios
        .get(`https://${fetch_url}/api/orbit/allQuotations`)
        .then((res) => {
            return res;
        })
        .catch((error) => {
            // console.log(error);
            return error;
        });
}

export const getPlatformMenus = async (fetch_url, userId) => {

    // console.log("userId", userId)

    const formData = new FormData();
    formData.append("user_id", userId);

    return axios
        .post(`https://${fetch_url}/api/orbit/getMenus`, formData)
        .then((res) => {
            return res;
        })
        .catch((error) => {
            // console.log(error);
            return error;
        });
}

export const getQuotationDetails = async (accessToken, fetch_url, quoteId) => {

    let url = ""

    if (fetch_url?.includes("https")) {
        url = `${fetch_url}/api/orbit/quotations/details`
    } else {
        url = `https://${fetch_url}/api/orbit/quotations/details`
    }

    const formData = new FormData();
    formData.append("quotation_id", quoteId);

    return axios
        .post(url, formData)
        .then((res) => {
            return res;
        })
        .catch((error) => {
            // console.log(error);
            return error;
        });
}