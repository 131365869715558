import React from 'react'
import ErrorLayout from './error-layout'
import maintenanceImg from '../../../../assets/img/login-1.svg'

const LogoutSuccess = () => {
    return (
        <>
            <ErrorLayout 
                code={maintenanceImg} 
                text="Logged Out"
                subText="You have successfully logged out from Orbit! Please go back to glazingo to login again."
                layout="layout-2"
                pageType="full_cover"
            />
        </>
    )
}

export default LogoutSuccess