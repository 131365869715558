import React, { useEffect, useRef } from 'react'
import { useState } from 'react';
import {
    Button,
    Label,
    FormGroup,
    Input,
    Accordion,
    AccordionBody,
    AccordionHeader,
    AccordionItem,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Form,
    NavLink
} from 'reactstrap';
import { GoCircleSlash, GoPlus } from "react-icons/go";
import { designerFrameOption, designerGlazingOption } from '../../utility/data';
import { MdKeyboardArrowDown, MdOutlineCreate, MdOutlineSell } from 'react-icons/md';

import { useNavigate } from 'react-router-dom';
import FrameColor from './FrameColor';
import FrameObject from './FrameObject';
import GlazingConfig from './GlazingConfig';
import HardwareConfig from './HardwareConfig';
import { useDesigner } from '../../context/designerContext';
import { Image } from 'react-bootstrap';
import LayoutSizing from './LayoutSizing';
import { getFrameStyles, getSelectedFrameProduct, getModelHardware, externalInternalColor, getSashProductHardware, updateFrameSvg, getHardwareDataService, getCuttingFormulaService, getSashhangingForQuote, getProductDataService } from '../../services/3dModelServices';
import SidePanelBar from '../SidePanelBar';
import QuotationPricingPosition from '../QuotationPricingPosition';
import FrameDesign from './FrameDesign';
import { capitalizeFirstLetter, getAlertForOverWeight, getCanvasAsBase64, handleQuantity, updateArrayWithArea, updateArrayWithCount, updateArrayWithCountSumTotal, updateArrayWithNameCount, updatePricingData, extractPerMMLength } from '../../utility/helper';
import { IoWarningOutline } from "react-icons/io5";
import StyledTooltip from '../StyledTooltip';
import Vector from '../3DComponents/Vector';
import { getGlassSizeArea } from '../../utility/pricingHelper';
import { renderToString } from 'react-dom/server';
import ManufacturingComp from '../ManufacturingComp';
import HardwareListItem from './HardwareListItem';
import grillIcon from '../../assets/img/grill_icon.png';
import alphabetIcon from '../../assets/img/alphabet.svg';
import { updateFrameDetails } from '../../services/commonServices';
import { BiArrowBack } from 'react-icons/bi';
import { getProfileJointDetails } from '../../services/sysProfileJointsService';
import { getSizeTagsService } from '../../services/frameStylesService';
import { updateSashSize } from '../../utility/threeJsHelpers/uiLinesHelper';
import { getQuoteAdditionalHardware, getQuoteAdditionalProfile } from '../../services/ManufacturingServices';
import { useRightContext } from '../../context/rightMenuContext';
import PanelDesign from '../3DComponents/paneldesign';
import { handleError } from '../../utility/commonHelper';

const PanelDesigner = ({ initialLoad, setModelVisible, modelVisible, receivedData, quotationId, receivedProductId, setInitialLoad, accessToken, styleId, gotData, setGotData, collectionDetails, allFrameCollection, currentModel, loading, setLoading, frameStyles, modelId, setMessage, setMessageType, frameStyleService, handleAddFramePanel, showSillOptions, captureImages, setCaptureImages, isNewFrame, setIsNewFrame }) => {

    const { projectDefault } = useRightContext();

    const cameraRef = useRef(null);
    const hasRendered = useRef(false)

    const [headerDropdown, setHeaderDropdown] = useState(false);
    const [imageSrc, setImageSrc] = useState()
    const [open, setOpen] = useState('0');
    const [sidePanel, setSidePanel] = useState(false)
    const [dropdownOpen, setDropdownOpen] = useState(false)
    const [frameData, setFrameData] = useState('')
    const [glazingData, setGlazingData] = useState('')
    const [glazingDrop, setglazingDrop] = useState(false)
    const [hardwareDrop, setHardwareDrop] = useState(false)
    const [hardwareData, setHardwareData] = useState('')
    const [headerSelectedItem, setHeaderSelectedItem] = useState('')
    const [animationDrop, setanimationDrop] = useState(false)
    const [selectedAnimation, setSelectedAnimation] = useState()
    const [layoutSizing, setLayoutSizing] = useState(false)
    const [hardwareType, setHardwareType] = useState()
    const [modelTypeToggle, setModelTypeToggle] = useState(false)
    const [glazingDesignOption, setGlazingDesignOptions] = useState('')
    const [allHardware, setAllHardware] = useState([])
    const [newAllHardware, setNewAllHardware] = useState([])
    const [pricingLeftOpen, setPricingLeftOpen] = useState(false)
    const [isModelChange, setIsModelChange] = useState(false);
    const [sashProductId, setSashProductId] = useState(null)
    const [numeralsText, setNumeralsText] = useState('');
    const [numeralsFont, setNumeralsFont] = useState('36');
    const [isThreshold, setIsThreshold] = useState(false)
    const [manufacturingDrop, setManufacturingDrop] = useState(false)
    const [manufacturingToggle, setManufacturingToggle] = useState(false)
    const [gotGlazingData, setGotGlazingData] = useState(false);
    const [quoteFrameDataOld, setQuoteFrameDataOld] = useState('');
    const [quoteFrameData, setQuoteFrameData] = useState('');
    const [saveFrameDetails, setSaveFrameDetails] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false)
    const [saveAllImages, setSaveAllImages] = useState(false)
    const [cuttingFormulaDetails, setCuttingFormulaDetails] = useState(null)
    const [allTagData, setAllTagData] = useState([])
    const [sizeTagsInit, setSizeTagsInit] = useState(false)

    const [cuttingLength, setCuttingLength] = useState({
        frameLength: null,
        sashLength: null
    })

    const [hangingQuote, setHangingQuote] = useState([])

    const [allProfileRefs, setAllProfileRefs] = useState([])

    const toggleTabDropdown = () => setHeaderDropdown((prevState) => !prevState);

    const { customModelData, setHardwareReferencePoint, setFrameObject, setTextureObject, setSpecObject, setHardwareObject,
        setPanelObject, setPricingData, pricingData, setVisibleExternalColors, visibleExternalColors, setExternalColor,
        setExternalRAL, externalColor, setCustomModelData, hardwareReferencePoint, setProfileJointDetails, modelWidth,
        modelHeight, setUiLinesLocation, setModelWidth, setModelHeight, setSashHangingNo, validHeights,
        validWidths, modelImages, lockRefIndex } = useDesigner()

    const [frameStyleSection, setFrameStyleSection] = useState(false)
    const [frameProductDetails, setFrameProductDetails] = useState('')
    const [additionalProfileData, setAdditionalProfileData] = useState([])
    const [additionalFrameProfile, setAdditionalFrameProfile] = useState([])
    const [updatedAlertSashData, setUpdatedAlertSashData] = useState([])
    const [additionalHardware, setAdditionalHardware] = useState([])

    const navigate = useNavigate()


    useEffect(() => {
        // if (customModelData) {
        //     setSaveAllImages(false)
        // }
        console.log("DATA CHANGED", customModelData)

        if (customModelData && customModelData?.sashData?.length > 0) {
            setAlertOverweightSashProfile(customModelData);
        }
    }, [customModelData])

    useEffect(() => {
        if (quotationId && selectedAnimation && modelVisible) {
            getAdditionalHardwareService()

            // get enabled hangings
            getSashhangingForQuoteService()
        }
    }, [selectedAnimation, modelVisible])

    useEffect(() => {
        if (!hasRendered.current && quotationId && selectedAnimation) {
            getAdditionalProfilesServices(2, "sash")
            getAdditionalProfilesServices(1, "frame")
            hasRendered.current = true
        }
    }, [quotationId, selectedAnimation])


    useEffect(() => {
        if (frameProductDetails.jointsId) {
            profileJointsService(frameProductDetails.jointsId)
        }
    }, [frameProductDetails.jointsId])

    useEffect(() => {
        if (modelId && !isModelChange) {
            frameStyleService()
            setIsModelChange(false)
        }

        if (modelId) {
            setHardwareObject()
            getFrameProductDetails(modelId)
        }
        if (modelId) {
            getModelHardwareOptions(modelId)
            setUiLinesLocation('')
        }
    }, [modelId, initialLoad])

    useEffect(() => {
        if (hardwareReferencePoint !== null && modelId && selectedAnimation?.id) {
            getHardwareListingData(modelId, hardwareReferencePoint)
        }
    }, [hardwareReferencePoint])

    useEffect(() => {
        if (imageSrc && selectedAnimation) {
            if ((quotationId == "" || quotationId == null) && (receivedData == undefined || receivedData == "")) {
                frameSvgServices()
            }
        }

        if (imageSrc && selectedAnimation && quotationId && quoteFrameData != undefined && quoteFrameData.id) {
            updateFrameDetailService(quoteFrameData, imageSrc)
        }
    }, [imageSrc, selectedAnimation])

    useEffect(() => {
        if (selectedAnimation) {
            setHardwareReferencePoint(null)
            setSidePanel(false)
            setLayoutSizing(false)
            setFrameStyleSection(false)
            setUiLinesLocation('')
            if (manufacturingToggle) {
                setManufacturingToggle(false)
            }
        }
    }, [selectedAnimation])

    useEffect(() => {
        if (frameProductDetails?.id) {
            getTagData(frameProductDetails?.id)
            getProductsData()
        }
    }, [frameProductDetails?.id])

    useEffect(() => {
        if (!hardwareDrop) {
            setHardwareReferencePoint(null)
        }
    }, [hardwareDrop])

    // Function to generate  image for 3D model layout
    const generateImage = () => {
        // renderToString is a higher order function takes Vector component and returns the string of the component
        const svgString = renderToString(<Vector data={customModelData} />);
        const svgBlob = new Blob([svgString], { type: 'image/svg+xml;charset=utf-8' });
        const reader = new FileReader();

        reader.onload = function (event) {
            const dataUrl = event.target.result;
            const base64URL = dataUrl.substring(dataUrl.indexOf(",") + 1)
            setImageSrc(base64URL);
        };

        reader.readAsDataURL(svgBlob);
    };

    useEffect(() => {
        if (selectedAnimation?.id) {
            generateImage();
        }
    }, [customModelData]);

    const frameSvgServices = async () => {
        const res = await updateFrameSvg(accessToken, selectedAnimation?.id, imageSrc)
    }

    useEffect(() => {
        if (customModelData?.sashProd) {
            setSashProductId(customModelData?.sashProd)
        }
    }, [customModelData.sashProd])

    useEffect(() => {
        if (sashProductId) {
            sashProductHardwareService()
        }
    }, [sashProductId])

    useEffect(() => {
        if (receivedData != undefined && receivedData.id) {
            setQuoteFrameData(receivedData)
        }
    }, [receivedData])

    useEffect(() => {
        if (saveFrameDetails, quoteFrameData && quoteFrameData.id) {
            updateFrameDetailService(quoteFrameData, '')
        }
    }, [saveFrameDetails])

    useEffect(() => {
        if (saveAllImages && quoteFrameData && quoteFrameData.id) {
            updateFrameDetailService(quoteFrameData, '')
        }
    }, [saveAllImages])

    useEffect(() => {
        if (selectedAnimation?.id && quotationId && sizeTagsInit) {
            getCuttingFormulaData(selectedAnimation?.id);
        }
    }, [selectedAnimation, quotationId, sizeTagsInit]);

    useEffect(() => {
        if (frameStyles && frameStyles.length > 0) {

            const styleItem = frameStyles?.find((p) => p.id == styleId)

            if (styleItem && styleItem.id) {
                setSelectedAnimation(styleItem)
                let sashSize = []

                let sashValue = (parseFloat(customModelData?.layoutFrame?.width) / (customModelData?.numberOfSash?.number ? parseInt(customModelData?.numberOfSash?.number) : 1))
                // let sashValue = (parseFloat(isNewFrame ? receivedData?.width : customModelData?.layoutFrame?.width) / (customModelData?.numberOfSash?.number ? parseInt(customModelData?.numberOfSash?.number) : 1))

                if (customModelData?.numberOfSash?.number && customModelData?.numberOfSash?.number > 0) {
                    for (let i = 0; i < customModelData?.numberOfSash?.number; i++) {
                        sashSize.push(parseInt(sashValue))
                    }
                }

                // if(customModelData?.numberOfSash?.number && customModelData?.numberOfSash?.number > 0) {
                //     setSashHangingNo(customModelData?.numberOfSash?.number)
                // } else {
                //     setSashHangingNo(0)
                // }

                let newSashSize = []

                if (customModelData?.layoutFrame?.width && styleItem.id) {
                    if (isNewFrame && receivedData && receivedData.id && customModelData?.sashSize && customModelData?.sashSize.length > 0) {

                        if (customModelData && customModelData?.numberOfSash?.number > 0) {
                            newSashSize = updateSashSize(customModelData?.sashSize, customModelData?.layoutFrame?.width, receivedData?.width, customModelData?.numberOfSash?.number ? customModelData?.numberOfSash?.number : 1, lockRefIndex)
                        } else {
                            newSashSize = [modelWidth]
                        }

                        if (customModelData?.numberOfSash?.number && customModelData?.numberOfSash?.number > 0) {
                            setCustomModelData((prevData) => ({
                                ...prevData,
                                frameStyle: styleItem,
                                collectionId: modelId,
                                sashSize: newSashSize,

                                layoutFrame: {
                                    width: receivedData?.width ? receivedData?.width : 2500,
                                    height: receivedData?.height ? receivedData?.height : 2100,
                                },
                            }))
                        }

                        setIsNewFrame(false)
                    } else {

                        if (!customModelData.hasOwnProperty("sashSize") && customModelData?.numberOfSash?.number && customModelData?.numberOfSash?.number > 0) {
                            setCustomModelData((prevData) => ({
                                ...prevData,
                                frameStyle: styleItem,
                                collectionId: modelId,
                                sashSize: sashSize,
                            }))
                        } else {
                            setCustomModelData((prevData) => ({
                                ...prevData,
                                frameStyle: styleItem,
                                collectionId: modelId,
                            }))
                        }
                    }
                }
            }
        }
    }, [frameStyles])

    const sashProductHardwareService = async () => {
        const res = await getSashProductHardware(accessToken, sashProductId?.id)
        if (res && res.status === 200) {
            if (res?.data?.statusCode === 200) {
                setAllHardware(res.data.entity)
            }
        }
    }

    const handleThreshold = () => {
        setLayoutSizing(true)
        setIsThreshold(true)
        // setFrameStyleSection(true)
    }

    useEffect(() => {
        if (customModelData && customModelData?.frame?.sill && customModelData?.frame?.sill?.sillData) {
            setFrameObject(customModelData.frame.sill.sillData)
        }
    }, [customModelData])

    useEffect(() => {
        if (customModelData?.frame?.externalColor) {
            externalColorService(modelId)
        }
    }, [customModelData?.frame?.externalColor])

    const externalColorService = async (fetchId) => {
        const res = await externalInternalColor(accessToken, fetchId)

        if (res && res.status === 200) {
            if (res?.data?.statusCode === 200) {
                setVisibleExternalColors(res.data.entity.externalColorVisibleList)
            }
        }
    }

    const getAdditionalProfilesServices = async (type, profile) => {
        const data = {
            frameStyleId: selectedAnimation?.id,
            type
        }
        const res = await getQuoteAdditionalProfile(accessToken, data)

        if (res && res?.data?.statusCode === 200) {
            if (profile === "sash") {
                setAdditionalProfileData(res?.data?.entity)
            } else if (profile === "frame") {
                setAdditionalFrameProfile(res?.data?.entity)
            }
        }
    }

    const getSashhangingForQuoteService = async () => {

        const res = await getSashhangingForQuote(accessToken, selectedAnimation?.id)

        if (res && res?.data?.statusCode === 200) {
            setHangingQuote(res?.data?.entity)
        } else {
            setHangingQuote([])
        }
    }


    const getAdditionalHardwareService = async () => {
        const res = await getQuoteAdditionalHardware(accessToken, selectedAnimation?.id, modelId)
        if (res && res?.data?.statusCode === 200) {
            setAdditionalHardware(res?.data?.entity)
        }
    }

    useEffect(() => {
        if (visibleExternalColors && visibleExternalColors.length > 0 && customModelData?.frame?.externalColor) {

            var getSavedColor = customModelData?.frame?.externalColor

            if (customModelData?.frame?.externalColor.itemId !== 0) {
                if (customModelData?.frame?.externalColor?.name === 'Custom RAL') {
                    var jsonColor = {
                        id: getSavedColor?.itemId,
                        name: getSavedColor?.name,
                        colour: getSavedColor?.hex,
                        primaryId: getSavedColor?.primaryId,
                        price: getSavedColor?.price,
                        priceInpercentage: getSavedColor?.priceInpercentage,
                        barLengthPrice: getSavedColor?.barLengthPrice,
                        priceSquareMm: getSavedColor?.mmSquarePrice
                    }

                    var json = {
                        id: getSavedColor?.custom_Id,
                        name: getSavedColor?.customRALName,
                        rgbValue: getSavedColor?.rgbValue,
                        number: getSavedColor?.customRALCode,
                    }

                    setExternalColor(jsonColor)
                    setExternalRAL(json)
                } else {
                    var json = {
                        id: getSavedColor?.itemId,
                        name: getSavedColor?.name,
                        colour: getSavedColor?.hex,
                        primaryId: getSavedColor?.primaryId,
                        price: getSavedColor?.price,
                        priceInpercentage: getSavedColor?.priceInpercentage,
                        barLengthPrice: getSavedColor?.barLengthPrice,
                        priceSquareMm: getSavedColor?.mmSquarePrice
                    }

                    setExternalRAL()
                    setExternalColor(json)
                }
            } else {
                if (!quotationId && externalColor === undefined) {
                    const defaultExtColor = visibleExternalColors?.find((p) => p.default === true)

                    var testKey = customModelData?.frame?.externalColor?.hasOwnProperty("custom_Id")

                    if (!testKey) {
                        if (defaultExtColor === undefined) {
                            setExternalColor(visibleExternalColors[0])
                        } else {
                            setExternalColor(defaultExtColor)
                        }
                    }
                }
            }
        }
    }, [visibleExternalColors]);

    useEffect(() => {
        if (quotationId && modelVisible && sizeTagsInit && customModelData) {
            let tempGlazingArray = [];
            let tempTextureArray = [];
            let tempAddedHandleArray = [];
            let tempKnockerArray = [];
            let tempSpyholeArray = [];
            let tempEstucheonArray = [];
            let tempNumeralsArray = [];
            let tempLetterPlateArray = [];
            let tempHingeArray = [];
            let sillObj = "";
            let thresholdObj = null;
            let tempCouplerArray = [];
            let tempBayPostArray = [];
            let tempFloatingMullionArray = [];
            let tempBarHandleOffsetArray = [];
            let tempSlideAndTurnArray = [];
            let tempTrickleVentArray = [];
            let tempDesignArray = [];
            let tempPanelArray = [];
            let tempCylinderArray = []
            const tempSandblastedArray = []
            const tempAddedFrameArray = []
            const tempInstallationArray = []

            // For storing data for the glazing spec in the pricing JSON
            if (customModelData && customModelData?.glazing && customModelData?.glazing?.spec?.length > 0 && customModelData?.glassSizeData?.length > 0) {
                customModelData?.glazing?.spec?.forEach((item) => {
                    if (item?.name !== "None") {
                        let obj = {
                            id: item?.id,
                            name: item?.name,
                            price: item?.price,
                            orientation: "",
                            index: item?.index,
                            frameType: "Glazing specification",
                            imagePath: item?.imagePath,
                            oversizeArea: item?.oversizeArea,
                            priceableArea: getGlassSizeArea(item, hangingQuote, allTagData, customModelData?.sashSize, customModelData?.layoutFrame?.height),
                            // priceableArea: getPriceableArea(customModelData?.glassSizeData, item?.index, ""),
                            count: 1,
                        };
                        tempGlazingArray.push(obj)
                    }
                })
            }

            // For storing the added frame data
            if (customModelData && customModelData?.addedFrames && customModelData?.addedFrames?.length > 0) {
                customModelData?.addedFrames?.forEach((item => {
                    let obj = {
                        id: item?.id,
                        name: item?.name,
                        height: item?.height,
                        width: item?.width,
                        price: item?.price ? item?.price : 0,
                        count: 1,
                        orientation: "",
                        frameType: "Added frame",
                        collectionId: item.collectionId,
                        collectionName: item.collectionName,
                        imagePath: item.imagePath
                    }

                    let installationObj = {
                        id: item?.id,
                        price: item?.installationPrice ? item?.installationPrice : 0,
                        frameType: item?.name,
                        orientation: `${item?.width} x ${item?.height}`
                    }

                    tempInstallationArray.push(installationObj)
                    tempAddedFrameArray.push(obj)
                }))
            }

            // For storing data for the glazing texture in the pricing JSON
            if (customModelData && customModelData?.glazing && customModelData?.glazing?.texture?.length > 0 && customModelData?.glassSizeData?.length > 0) {
                customModelData?.glazing?.texture?.forEach((item) => {
                    if (item?.id !== null && item?.name !== "None") {
                        let obj = {
                            id: item?.id,
                            name: item?.name,
                            price: item?.price,
                            orientation: "",
                            index: item?.index,
                            frameType: "Glazing texture",
                            imagePath: item?.modelImage,
                            priceableArea: getGlassSizeArea(item, hangingQuote, allTagData, customModelData?.sashSize, customModelData?.layoutFrame?.height),
                            count: 1,
                        };
                        tempTextureArray.push(obj)
                    }
                })
            }

            // For storing data for the glazing design in the pricing JSON
            if (customModelData && customModelData?.glazing && customModelData?.glazing?.design?.length > 0 && customModelData?.glassSizeData?.length > 0) {
                customModelData?.glazing?.design?.forEach((item) => {
                    if (item?.id !== null && item?.name !== "None") {
                        let obj = {
                            id: item?.id,
                            name: item?.name,
                            price: item?.price,
                            orientation: "",
                            index: item?.index,
                            frameType: "Glazing design",
                            imagePath: item?.modelUrl,
                            priceableArea: getGlassSizeArea(item, hangingQuote, allTagData, customModelData?.sashSize, customModelData?.layoutFrame?.height),
                            count: 1,
                        };
                        tempDesignArray.push(obj)
                    }
                })
            }

            // For storing data for the panel in the pricing JSON
            if (customModelData && customModelData?.glazing && customModelData?.glazing?.panel?.length > 0 && customModelData?.glassSizeData?.length > 0) {
                customModelData?.glazing?.panel?.forEach((item) => {
                    if (item?.id !== null && item?.name !== "None") {
                        let obj = {
                            id: item?.id,
                            name: item?.name,
                            price: item?.price,
                            orientation: "",
                            index: item?.glassIndex,
                            frameType: "Panel",
                            imagePath: item?.profilePathUrl,
                            priceableArea: getGlassSizeArea(item, hangingQuote, allTagData, customModelData?.sashSize, customModelData?.layoutFrame?.height),
                            items: item?.items,
                            count: 1,
                        };
                        tempPanelArray.push(obj)
                    }
                })
            }

            // For storing price for the sanblasted numerals
            // For storing data for the glazing design in the pricing JSON
            if (customModelData && customModelData?.glazing && customModelData?.glazing?.sandblasted?.length > 0 && customModelData?.glassSizeData?.length > 0) {
                customModelData?.glazing?.sandblasted?.forEach((item) => {
                    let obj = {
                        id: 1,
                        name: item?.name,
                        price: item?.price,
                        orientation: "",
                        index: item?.index,
                        text: item?.text,
                        frameType: "Glazing design",
                        priceableArea: getGlassSizeArea(item, hangingQuote, allTagData, customModelData?.sashSize, customModelData?.layoutFrame?.height),
                        count: 1,
                    };
                    tempSandblastedArray.push(obj)
                })
            }

            // For storing hardwares
            if (customModelData && customModelData?.hardware) {
                Object.values(customModelData?.hardware)?.forEach((item) => {
                    item && item.length > 0 && item?.forEach((ele) => {
                        if (ele?.id !== null) {
                            let obj = {
                                id: ele?.id,
                                name: ele?.name,
                                price: ele?.price,
                                orientation: "",
                                index: ele?.index,
                                frameType: "Hardware",
                                type: ele?.type,
                                count: 1,
                                colorPrice: ele?.colorPrice,
                                imagePath: ele?.imagePath,
                            };
                            if (ele?.type === "knocker") {
                                tempKnockerArray.push(obj)
                            }
                            if (ele?.type === "spyhole")
                                tempSpyholeArray.push(obj)
                            if (ele?.type === "escutcheon")
                                tempEstucheonArray.push(obj)
                            if (ele?.type === "handle")
                                tempAddedHandleArray.push(obj)
                            if (ele?.type === "numeral") {
                                obj.numbers = ele?.numbers?.length - 1;
                                tempNumeralsArray.push(obj);
                            }
                            if (ele?.type === "letterplate") {
                                tempLetterPlateArray.push(obj)
                            }
                            if (ele?.type === "hinge") {
                                tempHingeArray.push(obj)
                            }
                            if (ele?.type === "bar handles offset") {
                                tempBarHandleOffsetArray.push(obj)
                            }
                            if (ele?.type === "trickle") {
                                tempTrickleVentArray.push(obj)
                            }
                            if (ele?.type === "cylinder") {
                                tempCylinderArray.push(obj)
                            }
                        }
                    })
                })
            }

            // For storing slide and turn hardware
            if (customModelData && customModelData?.doorTopHardware && customModelData
                ?.doorTopHardware?.length > 0
            ) {
                customModelData?.doorTopHardware?.forEach((item => {
                    let obj = {
                        id: item?.id,
                        name: item?.name,
                        price: item?.price,
                        orientation: "",
                        index: item?.index,
                        frameType: "Hardware",
                        type: "Slide and Turn",
                        count: 1,
                        colorPrice: item?.colorPrice,
                        imagePath: item?.imagePath,
                    };
                    tempSlideAndTurnArray.push(obj)
                }))
            }

            // for storing sill data
            if (customModelData && customModelData?.frame && customModelData?.frame?.sill && customModelData?.frame?.sill?.sillData) {
                let item = customModelData?.frame?.sill?.sillData
                sillObj = {
                    id: item?.id,
                    name: item?.name,
                    price: item?.price,
                    totalPrice: item?.price,
                    orientation: "",
                    frameType: "Sill",
                    imagePath: item?.imagePath,
                    externalPaintSurfaceArea: item?.externalPaintSurfaceArea,
                    internalPaintSurfaceArea: item?.internalPaintSurfaceArea,
                    profileTypePrice: item?.profileTypePrice
                };
            }

            // For storing threshold
            if (customModelData && customModelData?.frame && customModelData?.frame?.threshold && customModelData?.frame?.threshold?.isThresholdAdded) {
                let item = customModelData?.frame?.threshold
                thresholdObj = {
                    id: item?.id,
                    name: item?.name,
                    price: item?.price,
                    orientation: "",
                    frameType: "Threshold",
                    imagePath: item?.imagePath,
                    externalPaintSurfaceArea: item?.externalPaintSurfaceArea,
                    internalPaintSurfaceArea: item?.internalPaintSurfaceArea,
                    profileTypePrice: item?.profileTypePrice,
                };
            }

            // for storing the installationPrice for the base frame
            if (customModelData && customModelData?.frameStyle) {
                let item = frameStyles?.find((item => item?.id === customModelData?.frameStyle?.id))
                let obj = {
                    id: customModelData?.frameStyle?.id,
                    price: item?.installationPrice ? item?.installationPrice : 0,
                    frameType: customModelData?.frameStyle?.name,
                    orientation: `${customModelData?.layoutFrame?.width} x ${customModelData?.layoutFrame?.height}`
                }
                tempInstallationArray.push(obj)
            }

            // For storing transomsData
            if (customModelData && customModelData?.storedTransoms && customModelData?.storedTransoms?.length > 0) {
                setPricingData((prevData) => ({
                    ...prevData,
                    transoms: customModelData?.storedTransoms
                }))
            }

            // For storing bead data
            if (customModelData && customModelData?.bead && customModelData?.storedTransoms?.length > 0 && customModelData?.bead?.length > 0) {
                setPricingData((prevData) => ({
                    ...prevData,
                    bead: [customModelData?.bead[0]],
                    beadLength: customModelData?.beadLength
                }))
            }

            // for storing data for coupler
            if (customModelData && customModelData?.frame && customModelData?.frame?.framesAndCoupler && customModelData?.frame?.framesAndCoupler?.length > 0) {
                customModelData?.frame?.framesAndCoupler.forEach((item) => {
                    if (item?.coupler?.frameType == "Coupler") {
                        tempCouplerArray.push((item?.coupler))
                    }
                })
            }

            // for storing data for baypost
            if (customModelData && customModelData?.frame?.bayPost && customModelData?.frame?.bayPost?.length > 0) {
                customModelData?.frame?.bayPost.forEach((item) => {
                    tempBayPostArray.push((item))
                })
            }



            // For storing mullion data
            if (customModelData && customModelData?.hasOwnProperty("floatingMullion")) {
                let obj = customModelData?.floatingMullion
                tempFloatingMullionArray.push(obj)
            }

            if (tempGlazingArray?.length > 0) {
                setPricingData((prevData) => ({
                    ...prevData,
                    glazing: tempGlazingArray.length > 1 ? updateArrayWithArea(tempGlazingArray.filter(item => item?.index !== 0)) : updateArrayWithArea(tempGlazingArray),
                }))
            } else {
                setPricingData((prevData) => ({
                    ...prevData,
                    glazing: [],
                }))
            }

            if (tempAddedFrameArray?.length > 0) {
                setPricingData((prevData) => ({
                    ...prevData,
                    addedFrames: tempAddedFrameArray.length > 1 ? updateArrayWithCount(tempAddedFrameArray.filter(item => item?.index !== 0)) : updateArrayWithCount(tempAddedFrameArray),
                }))
            } else {
                setPricingData((prevData) => ({
                    ...prevData,
                    addedFrames: [],
                }))
            }

            if (tempInstallationArray?.length > 0) {
                setPricingData((prevData) => ({
                    ...prevData,
                    installationPrices: tempInstallationArray,
                }))
            } else {
                setPricingData((prevData) => ({
                    ...prevData,
                    installationPrices: [],
                }))
            }

            if (tempTextureArray?.length > 0) {
                setPricingData((prevData) => ({
                    ...prevData, texture: tempTextureArray.length > 1 ? updateArrayWithArea(tempTextureArray.filter(item => item?.index !== 0)) : updateArrayWithArea(tempTextureArray),
                }))
            } else {
                setPricingData((prevData) => ({
                    ...prevData,
                    texture: [],
                }))
            }

            if (tempDesignArray?.length > 0) {
                setPricingData((prevData) => ({
                    ...prevData,
                    design: tempDesignArray.length > 1 ? updateArrayWithArea(tempDesignArray.filter(item => item?.index !== 0)) : updateArrayWithArea(tempDesignArray),
                }))
            } else {
                setPricingData((prevData) => ({
                    ...prevData,
                    design: [],
                }))
            }

            if (tempPanelArray?.length > 0) {
                setPricingData((prevData) => ({
                    ...prevData,
                    panel: tempPanelArray.length > 1 ? updateArrayWithArea(tempPanelArray.filter(item => item?.index !== 0)) : updateArrayWithArea(tempPanelArray),
                }))
            } else {
                setPricingData((prevData) => ({
                    ...prevData,
                    panel: [],
                }))
            }

            if (tempSandblastedArray?.length > 0) {
                setPricingData((prevData) => ({
                    ...prevData,
                    sandblasted: tempSandblastedArray.length > 1 ? updateArrayWithArea(tempSandblastedArray.filter(item => item?.index !== 0)) : updateArrayWithArea(tempSandblastedArray),
                }))
            } else {
                setPricingData((prevData) => ({
                    ...prevData,
                    sandblasted: [],
                }))
            }

            if (tempHingeArray?.length > 0) {
                setPricingData((prevData) => ({
                    ...prevData,
                    hinge: tempHingeArray.length > 1 ? updateArrayWithCount(tempHingeArray.filter(item => item?.index !== 0)) : updateArrayWithCount(tempHingeArray),
                }))
            } else {
                setPricingData((prevData) => ({
                    ...prevData,
                    hinge: [],
                }))
            }

            setPricingData((prevData) => ({
                ...prevData,
                frameStyle: customModelData?.frameStyle,
                frameProduct: customModelData?.frameProduct,
                floatingMullion: tempFloatingMullionArray,
                glazingData: customModelData?.glazingData,
                sashSize: customModelData?.sashSize
            }))

            if (tempAddedHandleArray?.length > 0) {
                setPricingData((prevData) => ({
                    ...prevData,
                    handles: updateArrayWithNameCount(tempAddedHandleArray),
                }))
            } else {
                setPricingData((prevData) => ({
                    ...prevData,
                    handles: [],
                }))
            }

            if (tempKnockerArray?.length > 0) {
                setPricingData((prevData) => ({
                    ...prevData,
                    knocker: updateArrayWithNameCount(tempKnockerArray),
                }))
            } else {
                setPricingData((prevData) => ({
                    ...prevData,
                    knocker: [],
                }))
            }

            if (tempCylinderArray?.length > 0) {
                setPricingData((prevData) => ({
                    ...prevData,
                    cylinder: updateArrayWithNameCount(tempCylinderArray),
                }))
            } else {
                setPricingData((prevData) => ({
                    ...prevData,
                    cylinder: [],
                }))
            }

            if (tempSlideAndTurnArray?.length > 0) {
                setPricingData((prevData) => ({
                    ...prevData,
                    slideAndTurnHardware: updateArrayWithNameCount(tempSlideAndTurnArray),
                }))
            } else {
                setPricingData((prevData) => ({
                    ...prevData,
                    slideAndTurnHardware: [],
                }))
            }

            if (tempTrickleVentArray?.length > 0) {
                setPricingData((prevData) => ({
                    ...prevData,
                    trickleVent: updateArrayWithNameCount(tempTrickleVentArray),
                }))
            } else {
                setPricingData((prevData) => ({
                    ...prevData,
                    trickleVent: [],
                }))
            }

            if (tempSpyholeArray?.length > 0) {
                setPricingData((prevData) => ({
                    ...prevData,
                    spyhole: updateArrayWithNameCount(tempSpyholeArray),
                }))
            } else {
                setPricingData((prevData) => ({
                    ...prevData,
                    spyhole: [],
                }))
            }

            if (tempBarHandleOffsetArray?.length > 0) {
                setPricingData((prevData) => ({
                    ...prevData,
                    barHandleOffset: updateArrayWithNameCount(tempBarHandleOffsetArray),
                }))
            } else {
                setPricingData((prevData) => ({
                    ...prevData,
                    barHandleOffset: [],
                }))
            }

            if (tempEstucheonArray?.length > 0) {
                setPricingData((prevData) => ({
                    ...prevData,
                    escutcheon: updateArrayWithNameCount(tempEstucheonArray),
                }))
            } else {
                setPricingData((prevData) => ({
                    ...prevData,
                    escutcheon: [],
                }))
            }

            if (tempLetterPlateArray?.length > 0) {
                setPricingData((prevData) => ({
                    ...prevData,
                    letterPlate: updateArrayWithNameCount(tempLetterPlateArray),
                }))
            } else {
                setPricingData((prevData) => ({
                    ...prevData,
                    letterPlate: [],
                }))
            }

            if (tempNumeralsArray?.length > 0) {
                setPricingData((prevData) => ({
                    ...prevData,
                    numeral: updateArrayWithNameCount(tempNumeralsArray),
                }))
            } else {
                setPricingData((prevData) => ({
                    ...prevData,
                    numeral: [],
                }))
            }

            if (sillObj?.id !== undefined) {
                setPricingData((prevData) => ({
                    ...prevData,
                    sill: [sillObj]
                }))
            } else {
                setPricingData((prevData) => ({
                    ...prevData,
                    sill: []
                }))
            }

            // Storing threshold data
            if (thresholdObj !== null && thresholdObj?.id !== undefined) {
                setPricingData((prevData) => ({
                    ...prevData,
                    threshold: thresholdObj
                }))
            } else {
                setPricingData((prevData) => ({
                    ...prevData,
                    threshold: null
                }))
            }

            if (customModelData && customModelData?.sashData?.length > 0) {

                let sashArray = updatePricingData(customModelData?.sashData)

                sashArray?.forEach((item) => {
                    let cuttingLength = 0;
                    let array = item?.orientation.split(",");
                    array.forEach((ele) => {
                        cuttingLength = calculateHeightAndWidth(
                            item?.id,
                            allTagData,
                            cuttingFormulaDetails?.sash,
                            false,
                            "sash"
                        );
                    });
                    item.cuttingLength = cuttingLength?.length;
                    item.cuttingHeight = cuttingLength?.cuttingHeight;
                    item.cuttingWidth = cuttingLength?.cuttingWidth
                });


                setPricingData((prevData) => ({
                    ...prevData,
                    sash: sashArray
                }))
            } else {
                setPricingData((prevData) => ({
                    ...prevData,
                    sash: []
                }))
            }


            if (additionalProfileData && additionalProfileData?.length) {

                const additionalSashPricing = additionalProfileData?.map((item) => {
                    let length = extractPerMMLength(customModelData?.sashSize, item, customModelData?.layoutFrame?.height)
                    const price = item.price ? item.price : 0
                    const finalPrice = length > 0 ? (parseFloat(price) * parseFloat(length)) : price
                    length = parseFloat(length)
                    return {
                        ...item,
                        totalPrice: finalPrice,
                        length
                    }
                })


                setPricingData((prevData) => ({
                    ...prevData,
                    additionalSashPricing
                }))

            }

            if (additionalFrameProfile && additionalFrameProfile?.length) {
                const additionalFramePricing = additionalFrameProfile?.map((item) => {
                    let length = extractPerMMLength(customModelData?.layoutFrame?.width, item, customModelData?.layoutFrame?.height)
                    const price = item.price ? item.price : 0
                    const finalPrice = length > 0 ? (parseFloat(price) * parseFloat(length)) : price
                    return {
                        ...item,
                        totalPrice: finalPrice,
                        length
                    }
                })


                setPricingData((prevData) => ({
                    ...prevData,
                    additionalFramePricing
                }))

            }

            if (customModelData && customModelData?.frame?.framesAndCoupler && customModelData?.frame?.framesAndCoupler?.length > 0) {
                setPricingData((prevData) => ({
                    ...prevData,
                    coupler: updateArrayWithCount(tempCouplerArray)
                }))
            } else {
                setPricingData((prevData) => ({
                    ...prevData,
                    coupler: []
                }))
            }

            if (customModelData && customModelData?.frame?.bayPost && customModelData?.frame?.bayPost?.length > 0) {
                setPricingData((prevData) => ({
                    ...prevData,
                    bayPost: updateArrayWithCount(tempBayPostArray)
                }))
            } else {
                setPricingData((prevData) => ({
                    ...prevData,
                    bayPost: []
                }))
            }

            if (customModelData?.frameProfileData?.length > 0) {
                const processFrameProfileData = (useNullFrames = false) => {
                    const newArr = updatePricingData(customModelData.frameProfileData);
                    
                    newArr.forEach((item) => {
                        const orientations = item?.orientation.split(",");
                        let cuttingLength = 0;
                        
                        orientations.forEach(() => {
                            cuttingLength = calculateHeightAndWidth(
                                item.id,
                                allTagData,
                                useNullFrames ? null : cuttingFormulaDetails?.frames,
                                useNullFrames,
                                "frame"
                            );
                        });
                        
                        item.cuttingLength = cuttingLength?.length;
                        item.cuttingHeight = cuttingLength?.cuttingHeight;
                        item.cuttingWidth = cuttingLength?.cuttingWidth;
                    });
            
                    setPricingData((prevData) => ({
                        ...prevData,
                        frameProfile: newArr
                    }));
                };
            
                if (cuttingFormulaDetails && cuttingFormulaDetails?.frames?.length > 0 && sizeTagsInit) {
                    processFrameProfileData(false);
                } else if (cuttingFormulaDetails?.frames == null) {
                    processFrameProfileData(true);
                }
            }            


            if (additionalHardware?.length) {

                const additionalHardwarePricing = additionalHardware.map((item) => {
                    const formula = item?.widthFormula;
                    const parts = formula && formula?.split('-');
                    let pMatch = null
                    if (parts?.length) {
                        pMatch = parts[0]?.match(/P(\d+)/);
                    }

                    let width = pMatch ? customModelData?.sashSize : customModelData?.layoutFrame?.width
                    let length = extractPerMMLength(width, item, customModelData?.layoutFrame?.height)
                    let price = item?.price ? item?.price : 0

                    let newPrice = 0

                    if (item.priceType === 2) {
                        newPrice = item.quantity * parseFloat(price)
                    } else {
                        newPrice = length > 0 ? (parseFloat(price) * parseFloat(length)) : parseFloat(price)
                    }

                    return {
                        ...item,
                        totalPrice: newPrice
                    }
                })

                setPricingData((prevData) => ({
                    ...prevData,
                    additionHardwares: additionalHardwarePricing

                }))
            }


            if (customModelData?.frame?.externalColor?.itemId !== 0 && customModelData?.frame?.internalColor?.itemId !== 0) {
                setTimeout(() => {
                    const parentDiv = document.getElementById('ball_id_external');

                    if (parentDiv) {
                        const canvasElement = parentDiv.querySelector('canvas');
                        if (canvasElement) {
                            var extImg = getCanvasAsBase64(canvasElement)
                        }
                    }
                    const parentDivInternal = document.getElementById('ball_id_internal');

                    if (parentDivInternal) {
                        const canvasElements = parentDivInternal.querySelector('canvas');
                        if (canvasElements) {
                            var intImg = getCanvasAsBase64(canvasElements)
                        }
                    }
                    let color = {
                        extColorName: customModelData?.frame?.externalColor?.name === "Custom RAL" ? `${customModelData?.frame?.externalColor?.customRALName} ${customModelData?.frame?.externalColor?.customRALCode}` : customModelData?.frame?.externalColor?.name,
                        intColorName: customModelData?.frame?.internalColor?.name === "Custom RAL" ? `${customModelData?.frame?.internalColor?.customRALName} ${customModelData?.frame?.internalColor?.customRALCode}` : customModelData?.frame?.internalColor?.name
                    }
                    setPricingData((prevPricing) => ({
                        ...prevPricing,
                        frameColorExt: {
                            ...customModelData?.frame?.externalColor,
                            imagePath: extImg
                        },
                        frameColorInt: {
                            ...customModelData?.frame?.internalColor,
                            imagePath: intImg
                        },
                        color: color
                    }))
                }, [1000])
            }

            setPricingData((prevData) => ({
                ...prevData,
                ancillaryData: customModelData?.ancillaryData
            }));

            // if (cuttingLength?.frameLength) {
            //     setPricingData((prevData => ({
            //         ...prevData,
            //         frameCuttingLength: cuttingLength?.frameLength
            //     })))
            // }
        }
    }, [customModelData, modelVisible, sizeTagsInit, cuttingFormulaDetails])

    useEffect(() => {
        if (gotData) {
            // if(customModelData) {
            //     setSashHangingNo(customModelData?.numberOfSash?.number || 0);
            // }

            if (customModelData && customModelData?.glazing?.texture && customModelData?.glazing?.texture?.effect) {
                setTextureObject(customModelData.glazing.texture.effect)
            }
            if (customModelData && customModelData?.glazing?.spec && customModelData?.glazing?.spec?.specData) {
                setSpecObject(customModelData.glazing.spec.specData)
            }
            // if (customModelData && customModelData?.hardware?.handle?.length > 0) {
            //     setHandleObject(customModelData?.hardware?.handle)
            // }
            if (customModelData && customModelData?.hardware?.escutcheon?.length > 0) {
                setHardwareObject((prevData) => ({
                    ...prevData,
                    escutcheon: customModelData?.hardware?.escutcheon
                }))
            }
            if (customModelData && customModelData?.hardware?.knocker?.length > 0) {
                setHardwareObject((prevData) => ({
                    ...prevData,
                    knocker: customModelData?.hardware?.knocker
                }))
            }
            if (customModelData && customModelData?.hardware?.handle?.length > 0) {
                setHardwareObject((prevData) => ({
                    ...prevData,
                    handle: customModelData?.hardware?.handle
                }))
            }
            if (customModelData && customModelData?.hardware?.trickleVent?.length > 0) {
                setHardwareObject((prevData) => ({
                    ...prevData,
                    trickleVent: customModelData?.hardware?.trickleVent
                }))
            }
            if (customModelData && customModelData?.hardware?.spyhole?.length > 0) {
                setHardwareObject((prevData) => ({
                    ...prevData,
                    spyhole: customModelData?.hardware?.spyhole
                }))
            }
            if (customModelData && customModelData?.hardware?.numeral?.length > 0) {
                setHardwareObject((prevData) => ({
                    ...prevData,
                    numeral: customModelData?.hardware?.numeral
                }))
            }

            if (customModelData && customModelData?.glazing?.panel != undefined) {
                setPanelObject(customModelData?.glazing?.panel)
            }

            // if(customModelData && customModelData?.hardware?.storedHandles?.current.length > 0){
            //     setHardwareObject((prevData) => ({
            //         ...prevData,
            //         storedHandles: customModelData?.hardware?.storedHandles
            //     }))
            // }
            setGotData(false)
        }
    }, [gotData, customModelData])

    useEffect(() => {
        if (currentModel) {
            setHeaderSelectedItem(currentModel)
        }
    }, [currentModel])

    useEffect(() => {
        if (!sidePanel) {
            setHardwareType()
            setOpen(0)
        }
    }, [sidePanel])

    useEffect(() => {
        if (layoutSizing && open == 4) {
            setOpen(0)
            setManufacturingToggle(false)
        }
    }, [layoutSizing])

    useEffect(() => {
        if (isModelChange) {
            frameStyleNavigationService(headerSelectedItem)
        }
    }, [isModelChange, headerSelectedItem]);

    useEffect(() => {
        if (
            customModelData?.glazing?.spec?.length > 0 ||
            customModelData?.glazing?.texture?.length > 0 ||
            customModelData?.glazing?.panel?.length > 0 ||
            customModelData?.glazing?.design?.length > 0 ||
            parseInt(customModelData?.numberOfSash?.number) >= 0
        ) {
            setGotGlazingData(true);
        }
        setTimeout(() => {
            setGotGlazingData(false);
        }, [100]);
    }, [customModelData?.glazing]);

    // This is for filtering the spec and texture data if panel is applied on the model
    useEffect(() => {
        if (
            gotGlazingData &&
            customModelData?.glazing &&
            customModelData?.glazing?.panel &&
            customModelData?.glazing?.panel?.length > 0 &&
            customModelData?.glazing?.spec?.length > 0
        ) {
            let newArray = customModelData?.glazing?.spec.filter((obj1) => {
                return (
                    obj1.index !== 0 &&
                    !customModelData?.glazing?.panel?.some(
                        (item) => item.glassIndex === obj1.index
                    )
                );
            });
            if (newArray?.length > 0) {
                setCustomModelData((prevData) => ({
                    ...prevData,
                    glazing: {
                        ...prevData?.glazing,
                        spec: newArray,
                        isSpecModified: true
                    }
                }))
            }
        }

        if (
            gotGlazingData &&
            customModelData?.glazing &&
            customModelData?.glazing?.panel &&
            customModelData?.glazing?.panel?.length > 0 &&
            customModelData?.glazing?.texture?.length > 0
        ) {
            let newArray = customModelData?.glazing?.texture.filter((obj1) => {
                return (
                    obj1.index !== 0 &&
                    !customModelData?.glazing?.panel?.some(
                        (item) => item.glassIndex === obj1.index
                    )
                );
            });
            if (newArray?.length > 0) {
                setCustomModelData((prevData) => ({
                    ...prevData,
                    glazing: {
                        ...prevData?.glazing,
                        texture: newArray,
                        isTextureModified: true
                    }
                }))
            }
        }
    }, [gotGlazingData]);

    // For setting the glazing data for shwowing under design menu
    useEffect(() => {
        if (gotGlazingData) {
            let temp = [];
            let glazingSpec = customModelData?.glazing?.spec || [];
            if (glazingSpec.length > 0) {
                const glazingTexture = customModelData?.glazing?.texture || [];
                const glazingPanel = customModelData?.glazing?.panel || [];
                const glazingDesign = customModelData?.glazing?.design || [];
                const glazingSandBlasted = customModelData?.glazing?.sandblasted || [];

                glazingSpec.forEach((item, index) => {
                    let texture = null;
                    let panel = null;
                    let design = null;
                    let sandblasted = null;
                    let gotData = false;
                    if (
                        customModelData?.hasOwnProperty("numberOfSash") &&
                        customModelData?.numberOfSash?.number != "0" &&
                        item?.index !== 0
                    ) {
                        texture =
                            glazingTexture && glazingTexture.length > 0
                                ? glazingTexture?.find(
                                    (ele) => item.index === ele.index && ele.id !== null
                                )
                                : "";
                        panel =
                            glazingPanel && glazingPanel.length > 0
                                ? glazingPanel?.find(
                                    (ele) => item.index === ele.glassIndex && ele.id !== null
                                )
                                : "";
                        design =
                            glazingDesign && glazingDesign.length > 0
                                ? glazingDesign.find(
                                    (ele) => item.index === ele.index && ele.id !== null
                                )
                                : "";
                        sandblasted = glazingSandBlasted && glazingSandBlasted.length > 0
                            ? glazingSandBlasted.find(
                                (ele) => item.index === ele.index && ele.id !== null
                            )
                            : "";
                        gotData = true;
                    } else if (
                        (!customModelData?.hasOwnProperty("numberOfSash") ||
                            customModelData?.numberOfSash?.number == "0") &&
                        item?.index >= 0
                    ) {
                        texture =
                            glazingTexture && glazingTexture.length > 0
                                ? glazingTexture?.find(
                                    (ele) => item.index === ele.index && ele.id !== null
                                )
                                : "";
                        panel =
                            glazingPanel && glazingPanel.length > 0
                                ? glazingPanel?.find(
                                    (ele) => item.index === ele.glassIndex && ele.id !== null
                                )
                                : "";
                        design =
                            glazingDesign && glazingDesign.length > 0
                                ? glazingDesign.find(
                                    (ele) => ele?.index === null ? ele : item.index === ele.index && ele.id !== null
                                )
                                : "";
                        sandblasted = glazingSandBlasted && glazingSandBlasted.length > 0
                            ? glazingSandBlasted.find(
                                (ele) => item.index === ele.index && ele.id !== null
                            )
                            : "";
                        gotData = true;
                    }
                    if (gotData) {
                        let json = {
                            frameType: "Glazing",
                            glassIndex: item?.index,
                            spec: item,
                            panel: panel ? panel : null,
                            design: design ? design : null,
                            texture: texture ? texture : null,
                            sandblasted: sandblasted ? sandblasted : null,
                            imagePath: item?.imagePath,
                            height: customModelData?.layoutFrame?.height,
                            width: customModelData?.layoutFrame?.width,
                        };
                        temp.push(json);
                    }
                });
            }
            if (temp?.length > 0) {
                setCustomModelData((prevModelData) => ({
                    ...prevModelData,
                    // glazingData: filterUniqueSpecs(temp),
                    glazingData: removeGlazingIfPanel(temp, customModelData?.glazing?.panel),
                }));
            }
        }
    }, [gotGlazingData]);

    const removeGlazingIfPanel = (glazingData, panelData) => {
        if (panelData && panelData?.length > 0) {
            // Create a set of glassIndex values from array2 for quick lookup
            const glassIndexSet = new Set(panelData?.map(item => item.glassIndex));

            // Filter out objects from array1 that have matching glassIndex in array2
            const filteredArray1 = glazingData?.filter(item => !glassIndexSet.has(item.glassIndex));
            return filteredArray1
        }
        else {
            return glazingData
        }
    }

    // This is for setting the data for alerting the overweight for the sash
    const setAlertOverweightSashProfile = (data) => {

        if (data?.sashData && data?.sashData?.length > 0) {
            const updatedSashData = [];
            data?.sashData?.map((sashitem) => {
                var sashLength = 0;
                if (sashitem?.orientation == "Top" || sashitem?.orientation == "Bottom") {
                    sashLength = data?.layoutFrame?.width / parseInt(data?.numberOfSash?.number);
                }
                if (sashitem?.orientation == "Left" || sashitem?.orientation == "Right") {
                    sashLength = data?.layoutFrame?.height;
                }

                if (sashLength > 0) {
                    var totalWeightOfSash = (sashLength / 1000) * sashitem?.weight;
                    if (totalWeightOfSash != null) {
                        if (totalWeightOfSash > sashitem.maxWeight) {
                            sashitem.alert = true;
                        }
                        else {
                            sashitem.alert = false;
                        }
                    }
                }
                updatedSashData.push(sashitem);
            })
            if (updatedSashData.length > 0) {
                setUpdatedAlertSashData(updatedSashData)
            }
            else {
                setUpdatedAlertSashData([])
            }
        }
    }

    console.log("formula", cuttingFormulaDetails)

    const getCuttingFormulaData = async (frameStyleId) => {
        const res = await getCuttingFormulaService(accessToken, frameStyleId)
        if (res?.data?.statusCode == 200) {
            setCuttingFormulaDetails(res?.data?.entity)
        }
        else {
            setCuttingFormulaDetails(null)

            handleError(res, false, navigate)
        }
    }

    const getProductsData = async () => {
        const res = await getProductDataService(accessToken, modelId);
        if (res && res.status === 200) {
            if (res?.data?.statusCode === 200) {
                if (!customModelData?.sashProd) {
                    setSashProductId(res?.data?.entity[0]);

                    setCustomModelData((prevData) => ({
                        ...prevData,
                        sashProd: res?.data?.entity[0],
                    }));
                }
            }
        } else {
            handleError(res, false, navigate)
        }
    };

    const getTagData = async (systemId) => {
        const res = await getSizeTagsService(
            accessToken,
            systemId
        );

        if (res?.data?.statusCode === 200) {
            if (res?.data?.entity?.length > 0) {
                setAllTagData(res?.data?.entity);
            } else {
                setAllTagData([]);
            }

            setSizeTagsInit(true)
        } else {
            setAllTagData([])
            setSizeTagsInit(true)

            handleError(res, false, navigate)
        }
    };

    const calculateHeightAndWidth = (id, sizetags, array2, isEmpty, type) => {
        let length = 0;

        let cuttingHeight = 0;
        let cuttingWidth = 0;

        if (!isEmpty) {
            array2?.forEach((obj2) => {
                if (obj2?.frameProfileId === id) {
                    obj2.tagsIds.forEach((tagId) => {
                        const matchingObj = sizetags.find((obj1) => obj1.id === tagId);
                        if (matchingObj) {
                            length += matchingObj?.length;
                            if (obj2?.widthFormula?.startsWith("H")) {
                                cuttingHeight += matchingObj?.length
                            }
                            else {
                                cuttingWidth += matchingObj?.length
                            }
                        }
                    });
                }
            });
        }
        else {
            if (type === "frame") {
                length = parseFloat(customModelData?.layoutFrame?.height + customModelData?.layoutFrame.width)
                cuttingHeight = parseFloat(customModelData?.layoutFrame?.height)
                cuttingWidth = parseFloat(customModelData?.layoutFrame?.width)
            }

        }

        return {
            length: length,
            cuttingHeight: cuttingHeight,
            cuttingWidth: cuttingWidth
        };
    };

    const featureList = [
        {
            id: 1,
            name: "Path Feature 1"
        }
    ]

    const toggleAnimationDrop = () => {
        setanimationDrop(!animationDrop)
    }

    const toggleModelType = () => {
        setModelTypeToggle(!modelTypeToggle)
    }

    const handleAnimationSelected = (value) => {
        setLoading(true)
        setSelectedAnimation(value)
        if (manufacturingToggle) {
            setManufacturingToggle(false)
        }

        if (!quotationId && headerSelectedItem) {
            navigate(`/product/frame/designer/${headerSelectedItem?.id}/${value?.id}`)
        }
    }

    const handleFrameOptions = (data, id) => {
        setFrameData(data)
        setOpen(id)
    }

    const handleGlazingOptions = (data, id) => {
        setGlazingData(data)
        setGlazingDesignOptions(data)
        setOpen(id)
        setHardwareType(data?.name)
    }

    const handleHardwareOption = (data) => {
        setHardwareData(data)
        setHardwareType(data)
    }

    const handleItemSelect = (value) => {
        setLoading(true)
        setSelectedAnimation()
        setHeaderSelectedItem(value)
        setIsModelChange(true);
        if (manufacturingToggle) {
            setManufacturingToggle(false)
        }
    }

    const getModelHardwareOptions = async (modelId) => {
        const res = await getModelHardware(accessToken, modelId, 1);
        if (res?.data?.statusCode === 200) {
            setAllHardware(res?.data?.entity);
        } else {
            setAllHardware([]);
        }
    };

    const getHardwareListingData = async (modelId, referencePoint) => {

        const data = {
            frameCollectionId: modelId,
            type: 1,
            referencePoint: referencePoint,
            frameStyleId: selectedAnimation?.id,
        };

        const res = await getHardwareDataService(accessToken, data);

        if (res?.data?.statusCode === 200) {
            setNewAllHardware(res?.data?.entity);
        } else {
            setNewAllHardware([]);
        }
    };

    const profileJointsService = async (id) => {

        const res = await getProfileJointDetails(accessToken, id);

        if (res?.data?.statusCode === 200) {
            setProfileJointDetails(res?.data?.entity);
        } else {
            setProfileJointDetails('');
        }
    };

    const updateFrameDetailService = async (frameData, filePath) => {

        const data = {
            id: frameData.id,

            frameCollectionId: frameData.frameCollectionId,
            frameProductId: frameData.frameProductId,
            frameStyleId: frameData.frameStyleId,
            frameStyleCollectionId: frameData.frameStyleCollectionId,

            quantity: frameData.quantity,
            name: frameData.name,
            reference: frameData.reference,
            comment: frameData.comment,
            quoteId: frameData.comment,
            profilePath: filePath,
            modelImages: modelImages
        };

        const res = await updateFrameDetails(accessToken, data);

        if (res?.data?.statusCode === 200) {
            setQuoteFrameDataOld(quoteFrameData)
            setSaveFrameDetails(false)

            setTimeout(() => {
                setSaveAllImages(false)
            }, 100);

        } else {
            setSaveFrameDetails(false)
            setQuoteFrameDataOld(receivedData)

            setTimeout(() => {
                setSaveAllImages(false)
            }, 100);

            setMessageType("error")
            setMessage(res?.data?.message ? res?.data?.message : "Something went wrong !")

            handleError(res, false, navigate)
        }
    };

    const frameStyleNavigationService = async (item) => {
        const res = await getFrameStyles(accessToken, item.id)
        if (res && res.status === 200) {
            if (res?.data?.statusCode === 200) {
                // setFrameStyles(res?.data?.entity)
                // setLoading(false)
                // navigate(`/product/frame/designer/${item?.id}/${res?.data?.entity[0].id}`)


                // setIsModelChange(false)
                setSelectedAnimation(res?.data?.entity[0])

                setTimeout(() => {
                    if (!quotationId) {
                        navigate(`/product/frame/designer/${item?.id}/${res?.data?.entity[0].id}`)
                    }
                }, 500);
            } else {
                // setLoading(false)
                setMessage(res?.data?.message);
                setMessageType('error')
            }
        } else {
            // setLoading(false)
            setMessage(res?.message ? res.message : 'Something went wrong');
            setMessageType('error')
            if (res?.message === "Network Error") {
                navigate('/error/network')
                // setLoading(false)
            }
        }
    }

    const toggleAccordion = (id) => {
        if (open === id) {
            setOpen("0");
        } else {
            setOpen(id);
        }
    };

    const getFrameProductDetails = async (fetchId) => {
        const res = await getSelectedFrameProduct(accessToken, fetchId);
        if (res && res.status === 200) {
            if (res?.data?.statusCode === 200) {

                setFrameProductDetails(res?.data?.entity)
            }
        } else {
            setFrameProductDetails('')
        }
    };

    // const handleFeature = (e) => {
    //     e.stopPropagation()
    //     const value = e.target.value;
    //     setFeatureId(typeof value === 'string' ? value.split(',') : value)
    // }

    const toggleDropDown = (e) => {
        setDropdownOpen(!dropdownOpen)
        e.stopPropagation()
    }

    const toggleManufacturingDropdown = (e) => {
        setManufacturingDrop(!manufacturingDrop)
        e.stopPropagation()
    }

    const toggleSidePanel = (e) => {
        e.stopPropagation()
        setSidePanel(!sidePanel)
        setLayoutSizing(false)
        setFrameStyleSection(false)
        setUiLinesLocation("All") //
        if (manufacturingToggle) {
            setManufacturingToggle(false)
        }
    }

    const handleAccordionClick = (e) => {
        e.stopPropagation(); // Prevent the body click event from being triggered
    };

    const toggleGlazingDrop = (e) => {
        setglazingDrop(!glazingDrop)
        e.stopPropagation();
    }

    const toggleHardwareDrop = (e) => {
        setHardwareDrop(!hardwareDrop)
        e.stopPropagation()
        // setOpen('3');
    }

    const handlePricingPanel = () => {
        setPricingLeftOpen(!pricingLeftOpen)
    };

    const isEmpty = (obj) => {
        return obj && Object.keys(obj).length === 0;
    };


    const getLength = (arr) => {
        if (arr.length > 1) {
            return `X${arr.length}`
        }
    }

    const handleFrameType = (item, id) => {
        if (item.name === "Frame") {
            setHardwareType(item);
        } else {
            setFrameData(item);
        }
        setOpen(id)
    };

    const handleBackNav = () => {
        var quotationId = sessionStorage.getItem("quotationId")
        var collectionId = sessionStorage.getItem("collectionId")

        if (quotationId) {
            navigate(`/quotation/${quotationId}`)
        } else if (collectionId) {
            navigate(
                `/product/systems/styles/list`,
                { state: { data: collectionId, styleId: selectedAnimation?.id } }
            );
        } else {
            navigate('/')
        }
    }

    return (
        <>
            <div className="designer_wrap has_header new_design">
                <div className="d-flex justify-content-between border-botto designer_header">
                    <div className="d-flex align-items-center gap-3">

                        <NavLink className='btn btn-primary d-flex align-items-center gap-1 fw-bold back_btn' onClick={(e) => handleBackNav(e)}>
                            <BiArrowBack size={22} />
                            Back
                        </NavLink>

                        <Dropdown
                            isOpen={headerDropdown}
                            toggle={toggleTabDropdown}
                        >
                            <DropdownToggle color="none" className="border-0 p-0">
                                <div className="d-flex align-items-center">
                                    <span className="fw-semibold">
                                        {headerSelectedItem
                                            ? headerSelectedItem?.name
                                            : currentModel?.name}
                                        <MdKeyboardArrowDown className="ms-1" size={20} />
                                    </span>
                                </div>
                            </DropdownToggle>

                            <DropdownMenu className='full_height'>
                                {!quotationId ? (
                                    <>
                                        {allFrameCollection?.map((item, index) => {
                                            return (
                                                <DropdownItem
                                                    onClick={() => handleItemSelect(item)}
                                                    key={index}
                                                    disabled={item?.id == modelId}
                                                    className={`${item?.id == modelId ? 'text-primary fw-medium' : ''}`}
                                                >
                                                    {item?.name}
                                                </DropdownItem>
                                            );
                                        })}
                                    </>
                                ) : (
                                    <Form className="w-100 ps-2 pe-2">
                                        <FormGroup className="mb-3">
                                            <Label for="name">Name</Label>
                                            <Input name="name"
                                                value={quoteFrameData?.name}
                                                onChange={(e) => {
                                                    setQuoteFrameData((prevData) => ({
                                                        ...prevData,
                                                        name: e.target.value
                                                    }));
                                                }}
                                            />
                                            {quoteFrameData?.name === '' && <div className='text-danger mt-1'>Name is required</div>}
                                        </FormGroup>
                                        <FormGroup className="mb-1">
                                            <Label for="quantity" className="text-dark">
                                                Quantity
                                            </Label>

                                            <Input
                                                type='number'
                                                name="quantity"
                                                min="1" step="1" max="999"
                                                value={quoteFrameData?.quantity}
                                                onInput={(e) => handleQuantity(e)}

                                                onChange={(e) => {
                                                    setQuoteFrameData((prevData) => ({
                                                        ...prevData,
                                                        quantity: e.target.value
                                                    }));
                                                }}
                                            />

                                            {quoteFrameData?.quantity === '' && <div className='text-danger mt-1'>Quantity is required</div>}
                                        </FormGroup>
                                        <FormGroup className="mb-3">
                                            <Label for="reference">Reference</Label>
                                            <Input name="reference"
                                                value={quoteFrameData?.reference ? quoteFrameData?.reference : ''}
                                                onChange={(e) => {
                                                    setQuoteFrameData((prevData) => ({
                                                        ...prevData,
                                                        reference: e.target.value
                                                    }));
                                                }}
                                            />
                                        </FormGroup>

                                        <FormGroup className="mb-3">
                                            <Label for="comments">Comments</Label>
                                            <Input
                                                type="textarea"
                                                name="comments"
                                                value={quoteFrameData?.comment ? quoteFrameData?.comment : ''}
                                                onChange={(e) => {
                                                    setQuoteFrameData((prevData) => ({
                                                        ...prevData,
                                                        comment: e.target.value
                                                    }));
                                                }}
                                            />
                                        </FormGroup>
                                        <div className="d-flex gap-2 gap-xl-3 main_buttons mt-4">
                                            <Button
                                                type='button'
                                                color="primary"
                                                size="md"
                                                className="px-4"
                                                disabled={!quoteFrameData?.name || !quoteFrameData?.quantity}
                                                onClick={() => { setSaveFrameDetails(true); toggleTabDropdown() }}
                                            >
                                                Save
                                            </Button>

                                            <Button
                                                type='reset'
                                                color="primary"
                                                size="md"
                                                outline
                                                className="px-4"
                                                onClick={() => { toggleTabDropdown(); setQuoteFrameData(quoteFrameDataOld) }}
                                            >
                                                Cancel
                                            </Button>
                                        </div>
                                    </Form>
                                )}
                            </DropdownMenu>
                        </Dropdown>

                        {!quotationId && (
                            <Dropdown
                                isOpen={animationDrop}
                                toggle={toggleAnimationDrop}
                            >
                                <DropdownToggle color="none" className="border-0 p-0">
                                    <div className="d-flex align-items-center">
                                        <span className="fw-semibold">
                                            {selectedAnimation
                                                ? selectedAnimation?.name
                                                : selectedAnimation?.name}
                                            <MdKeyboardArrowDown className="ms-1" size={20} />
                                        </span>
                                    </div>
                                </DropdownToggle>
                                <DropdownMenu className='full_height'>
                                    {frameStyles?.map((item, index) => {
                                        return (
                                            <DropdownItem
                                                onClick={() => handleAnimationSelected(item)}
                                                key={index}
                                                disabled={item?.id == selectedAnimation?.id}
                                                className={`${item?.id == selectedAnimation?.id ? 'text-primary fw-medium' : ''}`}
                                            >
                                                {item?.name}
                                            </DropdownItem>
                                        );
                                    })}
                                </DropdownMenu>
                            </Dropdown>
                        )}

                        {/* {!quotationId && (
                            <Dropdown
                                isOpen={modelTypeToggle}
                                toggle={toggleModelType}
                            >
                                <DropdownToggle color="none" className="border-0 p-0">
                                    <div className="d-flex align-items-center">
                                        <span className="fw-semibold">
                                            {selectedAnimation
                                                ? selectedAnimation?.name
                                                : selectedAnimation?.name}
                                            <MdKeyboardArrowDown className="ms-1" size={20} />
                                        </span>
                                    </div>
                                </DropdownToggle>
                                <DropdownMenu>
                                    {modelTypeOptions && modelTypeOptions?.map((item, index) => {
                                        return (
                                            <DropdownItem
                                                onClick={() => handleModelTypeSelect(item)}
                                                key={index}
                                            >
                                                {item?.name}
                                            </DropdownItem>
                                        );
                                    })}
                                </DropdownMenu>
                            </Dropdown>
                        )} */}

                        <div className="d-flex gap-3">
                            {quotationId && (
                                <>
                                    <div className="cursor-pointer">
                                        <div onClick={() => handleAddFramePanel()}>
                                            <GoPlus className="text-primary me-1" size={18} />
                                            <span className="text-primary lh-1">New Frame</span>
                                        </div>
                                    </div>

                                    {pricingData?.totalPrice > 0 && (
                                        <div className="cursor-pointer" onClick={handlePricingPanel}>
                                            <div>
                                                <MdOutlineSell
                                                    className="text-primary me-1"
                                                    size={18}
                                                />
                                                <span className="text-primary lh-1">
                                                    {`${(projectDefault && projectDefault?.currency) ? projectDefault?.currency : '£'}${isNaN(pricingData?.totalPrice) ? 0 : pricingData?.totalPrice}`}</span></div>
                                        </div>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                </div>

                <Button
                    color="primary"
                    size="lg"
                    className="br-4 lh-1 py-2 px-3 px-lg-4 h-auto designer_btn"
                    onClick={(e) => toggleSidePanel(e)}
                >
                    {sidePanel ? (
                        "Close"
                    ) : (
                        <>
                            <MdOutlineCreate className="me-1" size={18} />
                            Design
                        </>
                    )}
                </Button>

                <div className={`position-relative design_modal ${captureImages ? "saving_images hide_lock" : 'saving_image'}`}>

                    {/* Start: 3D model */}
                    <div className={`canvas_wrap ${sidePanel ? "side_panel_active" : ""} ${modelVisible ? "model_visible" : 'model_hidden'}`}
                    >
                        <PanelDesign
                            isPanelOpen={sidePanel}
                            cameraRef={cameraRef}
                            allFrameCollection={allFrameCollection}
                            currentModel={selectedAnimation}
                            loading={loading}
                            setLoading={setLoading}
                            layoutSizing={layoutSizing}
                            hardwareType={hardwareType}
                            setHardwareType={setHardwareType}
                            setLayoutSizing={setLayoutSizing}
                            selectedAnimation={selectedAnimation}
                            modelId={modelId}
                            quotationId={quotationId}
                            receivedProductId={receivedProductId}
                            headerSelectedItem={headerSelectedItem}
                            collectionDetails={collectionDetails}
                            allHardware={allHardware}
                            setHardwareData={setHardwareData}
                            numeralsText={numeralsText}
                            numeralsFont={numeralsFont}
                            modelVisible={modelVisible}
                            setModelVisible={setModelVisible}
                            initialLoad={initialLoad}
                            setInitialLoad={setInitialLoad}
                            setSashProductId={setSashProductId}
                            sashProductId={sashProductId}
                            manufacturingToggle={manufacturingToggle}
                            newAllHardware={newAllHardware}
                            frameStyleSection={frameStyleSection}
                            isThreshold={isThreshold}
                            frameStyles={frameStyles}
                            maxZoom={false}
                            setSaveAllImages={setSaveAllImages}
                            captureImages={captureImages}
                            setCaptureImages={setCaptureImages}
                            setAllProfileRefs={setAllProfileRefs}
                        />
                    </div>

                    {/* End: 3D model */}

                    {/* Start: Right menu form */}
                    <div className={`designer-right-menu main_design ${sidePanel ? "open_menu" : ""}`}>
                        {!layoutSizing && !hardwareData && !frameData && !glazingData && !manufacturingToggle && (
                            <div
                                className={`d-flex gap-3 align-items-center justify-content-between ${layoutSizing && "mb-3"
                                    } cursor-pointer`}
                                onClick={() => {
                                    setLayoutSizing(!layoutSizing);
                                    setFrameStyleSection(false)
                                    // setUiLinesLocation('') //
                                    // if (manufacturingToggle) {
                                    //     setManufacturingToggle(false)
                                    //     setManufacturingDrop(false)
                                    // }
                                    // setHardwareType("")
                                }}
                            >
                                {/* <div>
                                    <h4 className='mb-1 fs-18 fw-500'>Layout & Sizing</h4>

                                    <p className="text-small mb-0">
                                        {!modelWidth ? customModelData?.layoutFrame?.width : modelWidth} x{" "}
                                        {!modelHeight ? customModelData?.layoutFrame?.height : modelHeight}
                                    </p>
                                </div>

                                <div className="border p-2 br-4 bg-light-gray">
                                    <Image src={ModelLayout} width={50} height={50} />
                                </div> */}

                                <button
                                    type="button"
                                    className={`accordion_btn_custom accordion-button ${layoutSizing ? '' : 'collapsed'}`}
                                >
                                    Size
                                </button>
                            </div>
                        )}

                        {layoutSizing && (
                            <LayoutSizing
                                setHardwareType={setHardwareType}
                                setLayoutSizing={setLayoutSizing}
                                collectionId={modelId}
                                frameStyleProdId={selectedAnimation}
                                setSelectedAnimation={setSelectedAnimation}
                                setLoading={setLoading}
                                quotationId={quotationId}
                                hardwareType={hardwareType}
                                frameStyleSection={frameStyleSection}
                                setFrameStyleSection={setFrameStyleSection}
                                collectionDetails={collectionDetails}
                                receivedProductId={receivedProductId}
                                headerSelectedItem={headerSelectedItem}
                                setSashProductId={setSashProductId}
                                sashProductId={sashProductId}
                                isThreshold={isThreshold}
                                setIsThreshold={setIsThreshold}
                                isDisabled={isDisabled}
                                setIsDisabled={setIsDisabled}
                                frameStyles={frameStyles}
                            />
                        )}

                        <Accordion
                            open={open}
                            toggle={toggleAccordion}
                            onClick={(e) => handleAccordionClick(e)}
                            tag="div"
                        >

                            {!layoutSizing && !glazingData && !hardwareData && !manufacturingToggle && (
                                <AccordionItem tag="div">
                                    <AccordionHeader
                                        targetId="1"
                                        onClick={() => {
                                            if (manufacturingToggle) {
                                                setManufacturingToggle(false)
                                            }
                                            setHardwareType("")
                                        }}
                                    >
                                        Style
                                        <Dropdown
                                            isOpen={dropdownOpen}
                                            toggle={toggleDropDown}
                                            className="ms-auto"
                                        >
                                            <DropdownToggle
                                                color="none"
                                                className="border-0 p-0 py-1 d-block text-muted"
                                                tag="span"
                                            >
                                                <GoPlus size={20} />
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                {designerFrameOption.map((item, index) => {
                                                    if (item?.name === "Sill" && !showSillOptions) {
                                                        return null; // Don't render anything if the condition is not met
                                                    }

                                                    return (
                                                        <DropdownItem
                                                            key={index}
                                                            onClick={() => handleFrameType(item, "1")}
                                                            tag="div"
                                                        >
                                                            {item?.name}
                                                        </DropdownItem>
                                                    );
                                                })}
                                            </DropdownMenu>
                                        </Dropdown>
                                    </AccordionHeader>

                                    <AccordionBody accordionId="1">

                                        {!frameData && (
                                            <div className="panel_object_list">

                                                <div
                                                    className="position-relative d-flex align-items-center"
                                                    onClick={() => { setLayoutSizing(true); setFrameStyleSection(true); setUiLinesLocation('All') }} style={{
                                                        pointerEvents: isDisabled ? 'none' : 'auto',
                                                        opacity: isDisabled ? 0.5 : 1,
                                                    }}
                                                    aria-disabled={isDisabled}
                                                >
                                                    <div className="right_item w-100 cursor-pointer flex-row-reverse justify-content-between">
                                                        <span className="me-2">
                                                            {!quotationId && !receivedProductId ? (
                                                                <div className="border p-2 br-4 bg-light-gray">
                                                                    <Vector data={customModelData} />
                                                                </div>
                                                            ) : (
                                                                <div className="border p-2 br-4 bg-light-gray">
                                                                    {customModelData?.frameStyle?.profilePathUrl?.includes("https") ? (
                                                                        <Image src={customModelData?.frameStyle?.profilePathUrl} className="img-fluid" alt={frameProductDetails?.name} />
                                                                    ) : (
                                                                        <Image src={`data:image/svg+xml;base64,${customModelData?.frameStyle?.profilePathUrl}`} className="img-fluid" alt={frameProductDetails?.name} />
                                                                    )}
                                                                </div>
                                                            )}
                                                        </span>

                                                        <div className='d-flex align-items-center gap-2 flex-row-reverse'>
                                                            <div className="right_title text-dark">
                                                                {frameProductDetails && frameProductDetails?.name && (
                                                                    <span className="text-truncate">
                                                                        {frameProductDetails?.name}
                                                                    </span>
                                                                )}
                                                                <p className="text-truncate text-small mb-0">
                                                                    {selectedAnimation && selectedAnimation.name}
                                                                </p>
                                                                {/* <p className="text-truncate text-small mb-0">
                                                                    Slave/Master
                                                                </p> */}
                                                            </div>
                                                            <div className='d-flex'>
                                                                <div>
                                                                    {updatedAlertSashData?.length > 0 && getAlertForOverWeight(updatedAlertSashData)?.length > 0 &&
                                                                        <StyledTooltip
                                                                            title={getAlertForOverWeight(updatedAlertSashData)?.length === 1 ? `${getAlertForOverWeight(updatedAlertSashData)[0]?.name} is overweight` : "Sashes are overweight"}
                                                                            placement="top"
                                                                            arrow
                                                                        >
                                                                            <span>
                                                                                <IoWarningOutline size={25} className='text-warning' />
                                                                            </span>
                                                                        </StyledTooltip>
                                                                    }
                                                                </div>

                                                                <div className='ms-2'>
                                                                    {validHeights?.min && validHeights?.max && validWidths?.min && validWidths?.max &&
                                                                        <StyledTooltip
                                                                            title={<div className='container'>
                                                                                <span className='d-block'>
                                                                                    Minimum width allowed : {validWidths?.min}
                                                                                </span>
                                                                                <span className='d-block'>
                                                                                    Maximum width allowed : {validWidths?.max}
                                                                                </span>
                                                                                <span className='d=block'>
                                                                                    Minimum height allowed : {validHeights?.min}
                                                                                </span>
                                                                                <span className='d-block'>
                                                                                    Maximum height allowed : {validHeights?.max}
                                                                                </span>
                                                                            </div>}
                                                                            placement="top"
                                                                            arrow
                                                                        >
                                                                            <span>
                                                                                <IoWarningOutline size={25} className='text-warning' />
                                                                            </span>
                                                                        </StyledTooltip>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <FrameDesign
                                                    item={customModelData?.frame?.externalColor}
                                                    handleFrameOptions={handleFrameOptions}
                                                    type="External"
                                                />

                                                <FrameDesign
                                                    item={customModelData?.frame?.internalColor}
                                                    handleFrameOptions={handleFrameOptions}
                                                    type="Internal"
                                                />

                                                {!isEmpty(customModelData?.frame?.sill?.sillData) && (
                                                    <div
                                                        className="position-relative d-flex align-items-center panel_object_list"
                                                        onClick={() =>
                                                            handleFrameOptions(
                                                                customModelData?.frame?.sill?.sillData, "1"
                                                            )
                                                        }
                                                    >
                                                        <div className='right_item w-100 cursor-pointer'>
                                                            <span className="me-2 image_icon">
                                                                {customModelData?.frame?.sill?.sillData?.width > 0 && customModelData?.frame?.sill?.sillData?.height > 0 ? (
                                                                    <Image
                                                                        className="me-icon"
                                                                        src={
                                                                            customModelData?.frame?.sill?.sillData
                                                                                ?.imagePath
                                                                        }
                                                                    ></Image>
                                                                ) : (
                                                                    <GoCircleSlash size={40} color="#989898" />
                                                                )}
                                                            </span>
                                                            <div className="right_title">
                                                                <span className="text-truncate text-dark">
                                                                    Sill
                                                                </span>
                                                                <span className="text-truncate d-block text-small text-dark">
                                                                    {customModelData?.frame?.sill?.sillData.name}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}

                                                {customModelData?.frame?.threshold?.name && (
                                                    <div
                                                        className="position-relative d-flex align-items-center panel_object_list"
                                                        onClick={() => handleThreshold()}
                                                    >
                                                        <div className='right_item w-100 cursor-pointer'>
                                                            <span className="me-2 image_icon">
                                                                {customModelData?.frame?.threshold?.width > 0 && customModelData?.frame?.threshold?.height > 0 ? (
                                                                    <Image
                                                                        className="me-icon"
                                                                        src={customModelData?.frame?.threshold?.imagePath}
                                                                    ></Image>
                                                                ) : (
                                                                    <GoCircleSlash size={40} color="#989898" />
                                                                )}
                                                            </span>

                                                            <div className="right_title">
                                                                <span className="text-truncate text-dark">
                                                                    Threshold
                                                                </span>
                                                                <span className="text-truncate d-block text-small text-dark">
                                                                    {customModelData?.frame?.threshold?.name}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        )}

                                        {(frameData?.id === 0 || frameData?.id === 1) && (
                                            <FrameColor
                                                frameData={frameData}
                                                setFrameData={setFrameData}
                                                modelId={modelId}
                                                frameStyleProdId={selectedAnimation}
                                                setLoading={setLoading}
                                                quotationId={quotationId}
                                                receivedProductId={receivedProductId}
                                            />
                                        )}

                                        {frameData?.id >= 2 && (
                                            <FrameObject
                                                frameData={frameData}
                                                setFrameData={setFrameData}
                                                modelId={modelId}
                                                frameStyleProdId={selectedAnimation}
                                                setLoading={setLoading}
                                                quotationId={quotationId}
                                                receivedProductId={receivedProductId}
                                            />
                                        )}
                                    </AccordionBody>
                                </AccordionItem>
                            )}

                            {!layoutSizing && !hardwareData && !frameData && !manufacturingToggle && (
                                <AccordionItem>
                                    <AccordionHeader targetId="2" color="none" onClick={() => {
                                        if (manufacturingToggle) {
                                            setManufacturingToggle(false)
                                        }
                                        setHardwareType("")
                                    }}>
                                        Glazing
                                        <Dropdown
                                            isOpen={glazingDrop}
                                            toggle={toggleGlazingDrop}
                                            className="ms-auto"
                                        >
                                            <DropdownToggle
                                                color="none"
                                                className="border-0 p-0 py-1 d-block text-muted"
                                                tag="span"
                                            >
                                                <GoPlus size={20} />
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                {designerGlazingOption.map((item, index) => {
                                                    return (
                                                        <DropdownItem
                                                            key={index}
                                                            onClick={() => handleGlazingOptions(item, "2")}
                                                            tag="div"
                                                        >
                                                            {item?.name}
                                                        </DropdownItem>
                                                    );
                                                })}
                                            </DropdownMenu>
                                        </Dropdown>
                                    </AccordionHeader>

                                    <AccordionBody accordionId="2">
                                        {!glazingData && (
                                            <div className="panel_object_list">
                                                {customModelData?.glazingData && customModelData?.glazingData?.length > 0 && customModelData?.glazingData?.map((item, index) => <div key={index} className="position-relative d-flex align-items-center"
                                                    onClick={() =>
                                                        handleGlazingOptions(
                                                            item, `2`
                                                        )
                                                    }>
                                                    {item?.spec?.name !== "None" && <div
                                                        className={`right_item w-100 cursor-pointer`}>
                                                        <span className="me-2 image_icon ball_shape ball_shadow">
                                                            <Image src={item?.imagePath} className='rounded-circle'></Image>

                                                            {(item?.design?.name === 'Astragal rectangular' || item?.design?.name === 'Georgian rectangular') && (
                                                                <Image src={grillIcon} className='glaze_img grill_bar'></Image>
                                                            )}

                                                            {item?.design?.name === 'Sandblasted numerals' && (
                                                                <Image src={alphabetIcon} className='glaze_img name_icon'></Image>
                                                            )}
                                                        </span>
                                                        <div className="right_title">
                                                            <span className='text-bold'>
                                                                {item?.spec?.name}
                                                            </span>

                                                            <span>
                                                                {item?.texture !== null && item?.texture?.name !== "None" ? item?.texture?.name : ''}
                                                            </span>

                                                            {item?.design !== null && <span>
                                                                {item?.design !== null && item?.design?.name}
                                                            </span>}
                                                            {item?.sandblasted !== null && <span >
                                                                {item?.sandblasted !== null && item?.sandblasted?.name}
                                                            </span>}
                                                            {item?.sandblasted !== null && <span className='text-muted mini_text'>
                                                                {item?.sandblasted !== null && item?.sandblasted?.text}
                                                            </span>}
                                                        </div>
                                                    </div>}
                                                </div>)}

                                                {customModelData?.glazing.panel && customModelData?.glazing.panel?.length > 0 && customModelData?.glazing.panel?.map((item, index) => <div key={index} className="position-relative d-flex align-items-center"
                                                    onClick={() =>
                                                        handleGlazingOptions(
                                                            item, `2`
                                                        )
                                                    }>
                                                    <div
                                                        className={`right_item w-100 cursor-pointer`}>
                                                        <span className="me-2 image_icon">
                                                            {item.profilePath ? (
                                                                <Image src={item?.profilePath}></Image>
                                                            ) : (
                                                                <svg viewBox="0 0 50 50" width="100" height="100"><path className="stroke" stroke="#989898" fill="#fff" stroke-width="2" d="M 0,50 0,0 50,0 50,50 Z"></path></svg>
                                                            )}
                                                        </span>
                                                        <div className="right_title">
                                                            <span className='text-bold'>
                                                                {item?.frameType}
                                                            </span>

                                                            <span>
                                                                {item?.name}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>)}
                                            </div>
                                        )}
                                        {glazingData && (
                                            <GlazingConfig
                                                setGlazingData={setGlazingData}
                                                collectionId={modelId}
                                                frameStyleProdId={selectedAnimation}
                                                setLoading={setLoading}
                                                quotationId={quotationId}
                                                glazingDesignOption={glazingDesignOption}
                                                setHardwareType={setHardwareType}
                                                receivedProductId={receivedProductId}
                                                setNumeralsText={setNumeralsText}
                                                setNumeralsFont={setNumeralsFont}
                                                numeralsText={numeralsText}
                                                numeralsFont={numeralsFont}
                                            />
                                        )}
                                    </AccordionBody>
                                </AccordionItem>
                            )}

                            {!layoutSizing && !glazingData && !frameData && !manufacturingToggle && (
                                <AccordionItem className='hardware_acc'>
                                    <AccordionHeader targetId="3" color="none"
                                        onClick={() => {
                                            if (manufacturingToggle) {
                                                setManufacturingToggle(false)
                                            }
                                            setHardwareType("")
                                        }}
                                    >
                                        {hardwareData
                                            ? hardwareData?.type === "trickle"
                                                ? "Trickle vent"
                                                : capitalizeFirstLetter(hardwareData?.type)
                                            : "Hardware"}

                                        <div className='ms-auto'>
                                            {allHardware && allHardware?.length > 0 && (
                                                <Dropdown
                                                    isOpen={hardwareDrop}
                                                    toggle={toggleHardwareDrop}
                                                    className="ms-auto"
                                                >
                                                    <DropdownToggle
                                                        color="none"
                                                        className="border-0 p-0 py-1 d-block text-muted"
                                                        tag="span"
                                                    >
                                                        <GoPlus size={20} />
                                                    </DropdownToggle>

                                                    <DropdownMenu className={`${window?.innerHeight < 900 ? 'has_scroll' : ''} body_dropdown hardware_list`} container="body">
                                                        {allHardware.map((item, index) => {
                                                            return (
                                                                <DropdownItem
                                                                    key={index}
                                                                    onClick={() => setHardwareType(item)}
                                                                    tag="div"
                                                                >
                                                                    {item?.name}
                                                                </DropdownItem>
                                                            );
                                                        })}
                                                    </DropdownMenu>
                                                </Dropdown>
                                            )}
                                        </div>
                                    </AccordionHeader>

                                    <AccordionBody accordionId="3">
                                        {!hardwareData && (
                                            <div className="panel_object_list">
                                                {customModelData && customModelData.hardware && Object.values(customModelData.hardware).map((item, index) => (
                                                    <>
                                                        {item && item.length > 0 && (
                                                            <HardwareListItem
                                                                key={item.id || index} // Use a unique key, preferably an id
                                                                data={item}
                                                                handleHardwareOption={handleHardwareOption}
                                                                getLength={getLength}
                                                            />
                                                        )}
                                                    </>
                                                ))}
                                            </div>
                                        )}

                                        {hardwareData && (
                                            <HardwareConfig
                                                selectedHardware={hardwareData}
                                                setHardwareData={setHardwareData}
                                                collectionId={modelId}
                                                frameStyleProdId={selectedAnimation}
                                                setLoading={setLoading}
                                                quotationId={quotationId}
                                                receivedProductId={receivedProductId}
                                            />
                                        )}
                                    </AccordionBody>
                                </AccordionItem>
                            )}

                            {!layoutSizing && !glazingData && !frameData && !hardwareData && !quotationId && !receivedProductId &&
                                <AccordionItem tag="div">
                                    <AccordionHeader targetId='4' color='none' onClick={() => { setManufacturingToggle(!manufacturingToggle); setUiLinesLocation('All') }}>
                                        Manufacturing
                                        <Dropdown
                                            isOpen={manufacturingDrop}
                                            toggle={toggleManufacturingDropdown}
                                            className="ms-auto"
                                        >
                                            <DropdownToggle
                                                color="none"
                                                className="border-0 p-0 py-1 d-block text-muted"
                                                tag="span"
                                            >
                                            </DropdownToggle>
                                            <DropdownMenu>
                                            </DropdownMenu>
                                        </Dropdown>
                                    </AccordionHeader>
                                    <AccordionBody accordionId='4'>
                                        <ManufacturingComp
                                            accessToken={accessToken}
                                            collectionId={modelId}
                                            sashProductId={sashProductId}
                                            manufacturingToggle={manufacturingToggle}
                                            setHardwareType={setHardwareType}
                                            frameStyleProdId={selectedAnimation}
                                            setLoading={setLoading}
                                            allHardware={allHardware}
                                            setAllHardware={setAllHardware}
                                            frameProductDetails={frameProductDetails}
                                            allTagData={allTagData}
                                        />
                                    </AccordionBody>
                                </AccordionItem>
                            }
                        </Accordion>
                    </div>
                    {/* End: Right menu form */}
                </div>
            </div>

            {quotationId && (
                <SidePanelBar
                    title={quoteFrameData?.name}
                    type="price"
                    setLeftPanelOpen={setPricingLeftOpen}
                    leftPanelOpen={pricingLeftOpen}
                    content={
                        <QuotationPricingPosition
                            accessToken={accessToken}
                            leftPanelOpen={pricingLeftOpen}
                            quotationId={quotationId}
                            collectionId={modelId}
                            frameStyleProdId={selectedAnimation}
                            receivedProductId={receivedProductId}
                            modelVisible={modelVisible}
                        />
                    }
                />
            )}
        </>
    );
}
export default PanelDesigner