import barHandleInline from "../assets/models/rectangular_pull_bar_inline_1200mm.glb";
import barHandleOffset from "../assets/models/rectangular_pull_bar_offset_1200mm.glb";
import panelKnocker from "../assets/models/PanelKnocker2.glb"
import panelSpyhole from "../assets/models/PanelSpyhole2.glb"
import escutcheon from "../assets/models/sweet_escutcheon_1.glb";
import doorKnob from "../assets/models/new_panel_door_knob_1.glb"
import dummyCuttingListImage from "../assets/img/dummyCuttingListImg.png"
import moment from "moment";
export const baseURL = `${process.env.REACT_APP_HOST}`;
export const servicePath = `${process.env.REACT_APP_API_ENDPOINT}/api`;
export const baseServicePath = `${process.env.REACT_APP_API_ENDPOINT}`;

const ITEM_HEIGHT = 43;
const ITEM_PADDING_TOP = 4;

export const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },

    anchorOrigin: {
        vertical: "bottom",
        horizontal: "left"
    },

    getContentAnchorEl: null,
};

export const ProfileLinkType = [
    {
        id: 1,
        name: "Adjacent"
    },
    {
        id: 2,
        name: "Corner"
    },
]

export const ProfileLinkCutType = [
    {
        id: 1,
        name: "Mitre"
    },
    {
        id: 2,
        name: "Butt"
    },
]

export const AccessoriesPricePer = [
    {
        id: 1,
        name: "Unit"
    },
    {
        id: 2,
        name: "Meter"
    },
]

export const printDocument = [
    {
        id: 1,
        name: "Supply and Install Quotation",
    },
    {
        id: 2,
        name: "Supply Only Quotation",
    },
    {
        id: 3,
        name: "Summary"
    }
]

export const subAccountCostPrice = [
    {
        value: "Standard Pricing",
        id: 1
    },
    {
        value: "Standard Pricing - Copy",
        id: 2
    }
]

export const hardwareCollectionsDrop = [
    {
        id: 1,
        name: "Bar Handle Inline"
    },
    {
        id: 2,
        name: "Bar Handle Offset"
    },
    {
        id: 3,
        name: "Cylinder"
    },
    {
        id: 4,
        name: "Door Handle"
    },
    {
        id: 5,
        name: "Door Knob"
    },
    {
        id: 6,
        name: "Escutcheon"
    },
    {
        id: 7,
        name: "Hinge"
    },
    {
        id: 8,
        name: "Knocker"
    },
    {
        id: 9,
        name: "Letterplate"
    },
    {
        id: 10,
        name: "Numeral"
    },
    {
        id: 11,
        name: "Spyhole"
    },
    {
        id: 12,
        name: "Trickle Vent"
    },
    {
        id: 13,
        name: "Window Handle"
    },
    {
        id: 16,
        name: "Slide and Turn Hardwares"
    },
    {
        id: 17,
        name: "Additional Hardware"
    }
]

export const glazignDesignLoc = [
    {
        id: 1,
        name: "Outer Panes",
    },
    {
        id: 2,
        name: "Both Panes"
    },
    {
        id: 3,
        name: "Between Panes"
    },
]

export const glazignDesignPatter = [
    {
        id: 1,
        name: "Border",
    },
    {
        id: 2,
        name: "Rectangle"
    },
]

export const glazignDesignColours = [
    {
        id: 1,
        name: "Glideline",
    },
    {
        id: 2,
        name: "Black"
    },
]

export const glazignSpecifcationSide = [
    {
        id: 1,
        name: "Outside",
    },
    {
        id: 2,
        name: "Inside"
    },
    {
        id: 3,
        name: "Both Sides"
    }
]

export const glazignDesignShape = [
    {
        id: 1,
        name: "Chamfer",
    },
    {
        id: 2,
        name: "Ovolo"
    },
    {
        id: 3,
        name: "Rebate"
    },
    {
        id: 4,
        name: "Round"
    }
]

export const noSelectOptions = [{
    "id": '',
    "name": 'No options',
    "imageUrl": '',
}]

export const hardwareColors = [
    {
        id: 1,
        name: "Black",
        color: "#000000",

    },
    {
        id: 2,
        name: "White",
        color: "#FFFFFF"
    }
]

export const designerShape = [
    {
        name: "Custom",
        id: 1,
    },
    {
        name: "Cladding",
        id: 2,
    },
    {
        name: "PVCu Square",
        id: 3,
    },
    {
        name: "PVCu groove",
        id: 4,
    },
]

export const HornOptions = [
    {
        id: 1,
        name: "None",
    },
    {
        id: 2,
        name: "Run through"
    },
    {
        id: 3,
        name: "Plant on"
    }
]

export const designerFrameOption = [
    {
        id: 1,
        name: "Frame"
    },
    {
        id: 2,
        name: "Sill"
    },
]

export const designerGlazingOption = [
    {
        id: 1,
        name: "Design",

    },
    {
        id: 2,
        name: "Texture",

    },
    {
        id: 3,
        name: "Panel",

    }
]

export const sashProductsSidebarMenu = [
    {
        id: 1,
        title: "Casement sash",
        parent: 'Products',
        submenu: 'Systems',
        submenu2: 'Sash',
        level: 4,
        to: "/product/systems/sash-products/1",
        items: [],
    },
    {
        id: 2,
        title: "Tilt and turn sash",
        parent: 'Products',
        submenu: 'Systems',
        submenu2: 'Sash',
        level: 4,
        to: "/product/systems/sash-products/2",
        items: [],
    },
    {
        id: 3,
        title: "Sliding window sash",
        parent: 'Products',
        submenu: 'Systems',
        submenu2: 'Sash',
        level: 4,
        to: "/product/systems/sash-products/3",
        items: [],
    },
    {
        id: 4,
        title: "Door sash",
        parent: 'Products',
        submenu: 'Systems',
        submenu2: 'Sash',
        level: 4,
        to: "/product/systems/sash-products/4",
        items: [],
    },
    {
        id: 5,
        title: "Sliding door sash",
        parent: 'Products',
        submenu: 'Systems',
        submenu2: 'Sash',
        level: 4,
        to: "/product/systems/sash-products/5",
        items: [],
    },
    {
        id: 6,
        title: "Bifolding door sash",
        parent: 'Products',
        submenu: 'Systems',
        submenu2: 'Sash',
        level: 4,
        to: "/product/systems/sash-products/6",
        items: [],
    },
]

// export const pricingProfileMenu = [
//     {
//         id: "1",
//         title: "Add-on",
//         parent: "Pricing",
//         submenu: "Profile",
//         level: 3,
//         to: "/pricing/profiles/1"
//     },
//     {
//         id: "2",
//         title: "Bay post",
//         parent: "Pricing",
//         submenu: "Profile",
//         level: 3,
//         to: "/pricing/profiles/2"
//     },
//     {
//         id: "3",
//         title: "Bead",
//         parent: "Pricing",
//         submenu: "Profile",
//         level: 3,
//         to: "/pricing/profiles/3"
//     },
//     {
//         id: "4",
//         title: "Coupler",
//         parent: "Pricing",
//         submenu: "Profile",
//         level: 3,
//         to: "/pricing/profiles/4"
//     },
//     {
//         id: "5",
//         title: "Floating mullion",
//         parent: "Pricing",
//         submenu: "Profile",
//         level: 3,
//         to: "/pricing/profiles/5"
//     },
//     {
//         id: "6",
//         title: "Frame",
//         parent: "Pricing",
//         submenu: "Profile",
//         level: 3,
//         to: "/pricing/profiles/6"
//     },
//     {
//         id: "7",
//         title: "Lock housing",
//         parent: "Pricing",
//         submenu: "Profile",
//         level: 3,
//         to: "/pricing/profiles/7"
//     },
//     {
//         id: "8",
//         title: "Sash",
//         parent: "Pricing",
//         submenu: "Profile",
//         level: 3,
//         to: "/pricing/profiles/8"
//     },
//     {
//         id: "9",
//         title: "Sill",
//         parent: "Pricing",
//         submenu: "Profile",
//         level: 3,
//         to: "/pricing/profiles/9"
//     },
//     {
//         id: "10",
//         title: "Threshold",
//         parent: "Pricing",
//         submenu: "Profile",
//         level: 3,
//         to: "/pricing/profiles/10"
//     },
//     {
//         id: "11",
//         title: "Transom",
//         parent: "Pricing",
//         submenu: "Profile",
//         level: 3,
//         to: "/pricing/profiles/11"
//     },
//     // {
//     //     id: "12",
//     //     title: "Additional Profile",
//     //     parent: "Pricing",
//     //     submenu: "Profile",
//     //     level: 3,
//     //     to: "/pricing/additional-profile"
//     // },
// ]

export const pricingHardwareMenu = [
    {
        id: "14",
        title: "Accessories",
        parent: "Pricing",
        submenu: "Hardware",
        level: 3,
        to: "/pricing/hardware/14"
    },
    {
        id: "15",
        title: "Additional Hardware",
        parent: "Pricing",
        submenu: "Hardware",
        level: 3,
        to: "/pricing/additional-hardware"
    },
    {
        id: "1",
        title: "Bar handle inline",
        parent: "Pricing",
        submenu: "Hardware",
        level: 3,
        to: "/pricing/hardware/1"
    },
    {
        id: "2",
        title: "Bar Handles Offset",
        parent: "Pricing",
        submenu: "Hardware",
        level: 3,
        to: "/pricing/hardware/2"
    },
    {
        id: "3",
        title: "Cylinder",
        parent: "Pricing",
        submenu: "Hardware",
        level: 3,
        to: "/pricing/hardware/3"
    },
    {
        id: "3",
        title: "Door Handle",
        parent: "Pricing",
        submenu: "Hardware",
        level: 3,
        to: "/pricing/hardware/4"
    },
    {
        id: "5",
        title: "Door Knob",
        parent: "Pricing",
        submenu: "Hardware",
        level: 3,
        to: "/pricing/hardware/5"
    },
    {
        id: "6",
        title: "Escutcheon",
        parent: "Pricing",
        submenu: "Hardware",
        level: 3,
        to: "/pricing/hardware/6"
    },
    {
        id: "7",
        title: "Hinge",
        parent: "Pricing",
        submenu: "Hardware",
        level: 3,
        to: "/pricing/hardware/7"
    },
    {
        id: "8",
        title: "Knocker",
        parent: "Pricing",
        submenu: "Hardware",
        level: 3,
        to: "/pricing/hardware/8"
    },
    {
        id: "9",
        title: "Letterplate",
        parent: "Pricing",
        submenu: "Hardware",
        level: 3,
        to: "/pricing/hardware/9"
    },
    {
        id: "10",
        title: "Numeral",
        parent: "Pricing",
        submenu: "Hardware",
        level: 3,
        to: "/pricing/hardware/10"
    },
    {
        id: "11",
        title: "Spyhole",
        parent: "Pricing",
        submenu: "Hardware",
        level: 3,
        to: "/pricing/hardware/11"
    },
    {
        id: "12",
        title: "Trickle Vent",
        parent: "Pricing",
        submenu: "Hardware",
        level: 3,
        to: "/pricing/hardware/12"
    },
    {
        id: "13",
        title: "Window Handle",
        parent: "Pricing",
        submenu: "Hardware",
        level: 3,
        to: "/pricing/hardware/13"
    },
    {
        id: "16",
        title: "Slide And Turn Hardwares",
        parent: "Pricing",
        submenu: "Hardware",
        level: 3,
        to: "/pricing/hardware/16"
    },
]

export const modelData = {
    modelImage: "",
    modelBalls: [],
    modelPrice: "120",
    layoutFrame: {
        width: 2500,
        height: 2100,
        depth: "",
    },
    layoutWalls: {
        sizes: "",
        externalThicknes: 0,
        internalThickness: 0,
        depth: 0,
        externalFinish: "",
        internalFinish: "",
        internalFinishRal: "",
        rotationAngle: 0,
    },

    frame: {
        externalColor: {
            "name": "Jet Black 9005 Matt",
            "hex": "#292c2f",
            "customRALName": "",
            "customRALCode": "",
            "rgbValue": "",
            "type": "external",
            "id": 1,
            "itemId": 373,
            "primaryId": 139,
            "custom_Id": "",
            "obj": "External",
            "model": "https://testorbitapp.augursapps.com/Content/VisualElement/White9017.svg",
            "bumpiness": 0,
            "roughness": 0,
            "metalness": 0,
            "price": 12,
            "priceInpercentage": 10,
            "barLengthPrice": 12,
            "stockColor": false
        },
        internalColor: {
            "name": "Jet Black 9005 Matt",
            "hex": "#292c2f",
            "customRALName": "",
            "customRALCode": "",
            "rgbValue": "",
            "type": "internal",
            "id": 1,
            "itemId": 373,
            "custom_Id": "",
            "obj": "Internal",
            "model": "https://testorbitapp.augursapps.com/Content/VisualElement/White9017.svg",
            "bumpiness": 0,
            "roughness": 0,
            "metalness": 0,
            "price": 10,
            "priceInpercentage": 10,
            "barLengthPrice": 10,
            "stockColor": false
        },
        externalColorPrice: "0",
        sill: {
            sillData: {},
            hornLength: 0,
            sillPrice: "0",
        },
    },

    frameProfileData: [],

    glazing: {
        panelIndex: -1,
        panel: [],
        design: [],
        spec: [],
        texture: [],
    },

    hardware: {
        handle: [],
        cylinder: [],
        barHandlesOffset: [],
        hingeData: [],
        numeral: [],
        spyhole: [],
        letterPlate: [],
        knocker: [],
        trickleVent: []
    },

    windowData: [],

    originalModel: {
        count: '',
        collectionId: '',
        styleId: '',
        typeId: '1',
    },

    appliedModel: {
        count: '',
        collectionId: '',
        styleId: '',
        typeId: '1',
    },

    beadLength: 0,

    numberOfSash: {
        number: 0
    },

    hideDimentions: false,
};

export const handleSideData = [
    {
        id: 1,
        name: "Outside"
    },
    {
        id: 2,
        name: "Inside"
    },
    {
        id: 3,
        name: "Both Side"
    }
]

export const productHardwareStyleMenu = [
    {
        id: 1,
        title: "Bar Handle Inline",
        parent: 'Products',
        submenu: 'Hardware',
        submenu2: 'Styles',
        level: 4,
        to: "/product/hardware/styles/1",
    },
    {
        id: 2,
        title: "Bar Handles Offset",
        parent: 'Products',
        submenu: 'Hardware',
        submenu2: 'Styles',
        level: 4,
        to: "/product/hardware/styles/2",
    },
    {
        id: 3,
        title: "Cylinder",
        parent: 'Products',
        submenu: 'Hardware',
        submenu2: 'Styles',
        level: 4,
        to: "/product/hardware/styles/3",
    },
    {
        id: 4,
        title: "Door Handle",
        parent: 'Products',
        submenu: 'Hardware',
        submenu2: 'Styles',
        level: 4,
        to: "/product/hardware/styles/4",
    },
    {
        id: 5,
        title: "Door Knob",
        parent: 'Products',
        submenu: 'Hardware',
        submenu2: 'Styles',
        level: 4,
        to: "/product/hardware/styles/5",
    },
    {
        id: 6,
        title: "Escutcheon",
        parent: 'Products',
        submenu: 'Hardware',
        submenu2: 'Styles',
        level: 4,
        to: "/product/hardware/styles/6",
    },
    {
        id: 7,
        title: "Hinge",
        parent: 'Products',
        submenu: 'Hardware',
        submenu2: 'Styles',
        level: 4,
        to: "/product/hardware/styles/7",
    },
    {
        id: 8,
        title: "Knocker",
        parent: 'Products',
        submenu: 'Hardware',
        submenu2: 'Styles',
        level: 4,
        to: "/product/hardware/styles/8",
    },
    {
        id: 9,
        title: "Letterplate",
        parent: 'Products',
        submenu: 'Hardware',
        submenu2: 'Styles',
        level: 4,
        to: "/product/hardware/styles/9",
    },
    {
        id: 10,
        title: "Numeral",
        parent: 'Products',
        submenu: 'Hardware',
        submenu2: 'Styles',
        level: 4,
        to: "/product/hardware/styles/10",
    },
    {
        id: 11,
        title: "Spyhole",
        parent: 'Products',
        submenu: 'Hardware',
        submenu2: 'Styles',
        level: 4,
        to: "/product/hardware/styles/11",
    },
    {
        id: 12,
        title: "Trickle Vent",
        parent: 'Products',
        submenu: 'Hardware',
        submenu2: 'Styles',
        level: 4,
        to: "/product/hardware/styles/12",
    },
    {
        id: 13,
        title: "Window Handle",
        parent: 'Products',
        submenu: 'Hardware',
        submenu2: 'Styles',
        level: 4,
        to: "/product/hardware/styles/13",
    },
    {
        id: 16,
        title: "Slide and Turn Hardwares",
        parent: 'Products',
        submenu: 'Hardware',
        submenu2: 'Styles',
        level: 4,
        to: "/product/hardware/styles/16",
    },
    {
        id: 17,
        title: "Additional Hardware",
        parent: 'Products',
        submenu: 'Hardware',
        submenu2: 'Styles',
        level: 4,
        to: "/product/hardware/styles/17",
    },
]

export const productProfileMenu = [
    {
        id: 1,
        title: "Add-on",
        parent: 'Products',
        submenu: 'Profiles',
        level: 3,
        to: "/product/profiles/1",
    },
    {
        id: 2,
        title: "Bay post",
        parent: 'Products',
        submenu: 'Profiles',
        level: 3,
        to: "/product/profiles/2",
    },
    {
        id: 3,
        title: "Bead",
        parent: 'Products',
        submenu: 'Profiles',
        level: 3,
        to: "/product/profiles/3",
    },
    {
        id: 4,
        title: "Coupler",
        parent: 'Products',
        submenu: 'Profiles',
        level: 3,
        to: "/product/profiles/4",
    },
    {
        id: 5,
        title: "Floating mullion",
        parent: 'Products',
        submenu: 'Profiles',
        level: 3,
        to: "/product/profiles/5",
    },
    {
        id: 6,
        title: "Frame",
        parent: 'Products',
        submenu: 'Profiles',
        level: 3,
        to: "/product/profiles/6",
    },
    {
        id: 7,
        title: "Lock housing",
        parent: 'Products',
        submenu: 'Profiles',
        level: 3,
        to: "/product/profiles/7",
    },
    {
        id: 8,
        title: "Sash",
        parent: 'Products',
        submenu: 'Profiles',
        level: 3,
        to: "/product/profiles/8",
    },
    {
        id: 9,
        title: "Sill",
        parent: 'Products',
        submenu: 'Profiles',
        level: 3,
        to: "/product/profiles/9",
    },
    {
        id: 10,
        title: "Threshold",
        parent: 'Products',
        submenu: 'Profiles',
        level: 3,
        to: "/product/profiles/10",
    },
    {
        id: 11,
        title: "Transom",
        parent: 'Products',
        submenu: 'Profiles',
        level: 3,
        to: "/product/profiles/11",
    },
    {
        id: 12,
        title: "Additional Profile",
        parent: 'Products',
        submenu: 'Profiles',
        level: 3,
        to: "/product/profiles/additional-profile",
    },
]

export const verticalAlignmentPanelOptions = [
    {
        id: 1,
        name: "Centre",
    },
    {
        id: 2,
        name: "Top",
    },
    {
        id: 3,
        name: "Bottom",
    },
];

export const horizontalAlignmentPanelOptions = [
    {
        id: 1,
        name: "Centre",
    },
    {
        id: 2,
        name: "Left",
    },
    {
        id: 3,
        name: "Right",
    },
    {
        id: 4,
        name: "Lock",
    },
    {
        id: 5,
        name: "Hinge",
    },
];

export const panelOriginData = [
    {
        id: 1,
        name: "Slab",
    },
    {
        id: 2,
        name: "Outside frame",
    },
    {
        id: 3,
        name: "Inside frame",
    },
];

export const panelData = {
    barHandle: {
        id: 1,
        name: "Bar Handle Inline",
        verticalPos: 0,
        horizontalPos: 400,
        scale: {
            posX: 1,
            posY: 1,
            posZ: 0.4,
        },
        file: barHandleInline,
        horizontalAlign: "Lock",
        horizontalOrigin: "Slab",
        verticalAlign: "Centre",
        verticalOrigin: "Slab",
    },
    barHandleOffset: {
        name: "Bar Handle Offset",
        id: 2,
        verticalPos: 0,
        horizontalPos: 400,
        scale: {
            posX: 1,
            posY: 1,
            posZ: 0.4,
        },
        file: barHandleOffset,
        horizontalAlign: "Lock",
        horizontalOrigin: "Slab",
        verticalAlign: "Centre",
        verticalOrigin: "Slab",
    },
    knocker: {
        id: 3,
        name: "Knocker",
        verticalPos: 0,
        horizontalPos: 0,
        scale: {
            posX: 5,
            posY: 5,
            posZ: 4.4,
        },
        file: panelKnocker,
        horizontalAlign: "Centre",
        horizontalOrigin: "Slab",
        verticalAlign: "Centre",
        verticalOrigin: "Slab",
    },
    spyHole: {
        id: 4,
        name: "Spyhole",
        verticalPos: 0,
        horizontalPos: 0,
        scale: {
            posX: 5,
            posY: 5,
            posZ: 4.75,
        },
        file: panelSpyhole,
        horizontalAlign: "Centre",
        horizontalOrigin: "Slab",
        verticalAlign: "Centre",
        verticalOrigin: "Slab",
    },
    escutcheon: {
        id: 5,
        name: "Escutcheon",
        verticalPos: 0,
        horizontalPos: 300,
        scale: {
            posX: 1,
            posY: 1,
            posZ: 0.61,
        },
        file: escutcheon,
        horizontalAlign: "Lock",
        horizontalOrigin: "Slab",
        verticalAlign: "Bottom",
        verticalOrigin: "Outside frame",
    },
    doorknob: {
        id: 6,
        name: "Door Knob",
        verticalPos: 0,
        horizontalPos: 0,
        scale: {
            posX: 2.5,
            posY: 2.5,
            posZ: 1.3,
        },
        file: doorKnob,
        horizontalAlign: "Centre",
        horizontalOrigin: "Slab",
        verticalAlign: "Centre",
        verticalOrigin: "Slab",
    },
    trickleVent: {
        id: 7,
        name: "Trickle vent",
        verticalPos: 0,
        horizontalPos: 0,
        scale: {
            posX: 1,
            posY: 1,
            posZ: 1
        },
        horizontalAlign: "Left",
        horizontalOrigin: "Slab",
        verticalAlign: "Top",
        verticalOrigin: "Outside frame",
    },
    hinge: {
        id: 8,
        name: "Hinge",
        verticalPos: 0,
        horizontalPos: 0,
        scale: {
            posX: 1,
            posY: 1,
            posZ: 1
        },
        horizontalAlign: "Left",
        horizontalOrigin: "Slab",
        verticalAlign: "Top",
        verticalOrigin: "Outside frame",
    },
    letterPlate: {
        id: 9,
        name: "Letterplate",
        verticalPos: -100,
        horizontalPos: 0,
        scale: {
            posX: 6.2,
            posY: 6.2,
            posZ: 6.2
        },
        horizontalAlign: "Centre",
        horizontalOrigin: "Slab",
        verticalAlign: "Centre",
        verticalOrigin: "Slab",
    }
};

export const panelFeatureList = [
    {
        id: 1,
        name: "Path feature 1"
    }
]

export const modelTypeOptions = [
    {
        id: 1,
        name: "Door",
        type: 'door',
    },
    {
        id: 2,
        name: "Window",
        type: 'window',
    },
];

export const positionsOpts = [
    {
        id: 0,
        name: "Internal",
    },
    {
        id: 1,
        name: "External",
    },
    {
        id: 2,
        name: "Central",
    },
];

export const customizeFrame = [
    {
        id: 1,
        name: "Transoms",
    },
    {
        id: 2,
        name: "Sash",
    },
    {
        id: 3,
        name: "Frame",
    },
];

export const projectTypeListOptions = [
    {
        id: 1,
        name: "Supply only"
    },
    {
        id: 2,
        name: "Supply and installation"
    }
]

export const statusFilterOptions2 = [
    {
        id: 1,
        name: "All"
    },
    {
        id: 2,
        color: "#E81123",
        name: "Required follow up"
    },
    {
        id: 3,
        color: "#e89911",
        name: "Check Quote"
    },
    {
        id: 4,
        color: "#FFB900",
        name: "Quote Checked"
    },
    {
        id: 5,
        color: "#107C10",
        name: "Quote Sent"
    },
    {
        id: 6,
        color: "#E3008C",
        name: "Need Survey"
    },
    {
        id: 7,
        color: "#00BCF2",
        name: "Ordered"
    },
]

export const statusFilterOptions = [
    {
        id: 1,
        name: "All"
    },
    {
        id: 10,
        color: "#FFB900",
        name: "In Quoting"
    },
    {
        id: 11,
        color: "#107C10",
        name: "Quote Approved"
    },
    {
        id: 58,
        color: "#E81123",
        name: "Quotation Expired"
    },
]

export const dateFilterOptions = [
    {
        id: 2,
        value: 1,
        name: "Today",
        startDate: moment().format('DD/MM/YYYY'),
        endDate: moment().format('DD/MM/YYYY')
    },
    {
        id: 3,
        value: 2,
        name: "Yesterday",
        startDate: moment().subtract(1, 'days').format('DD/MM/YYYY'),
        endDate: moment().subtract(1, 'days').format('DD/MM/YYYY')
    },
    {
        id: 4,
        value: 7,
        name: "Last 7 days",
        startDate: moment().subtract(6, 'days').format('DD/MM/YYYY'),
        endDate: moment().format('DD/MM/YYYY')
    },
    {
        id: 5,
        value: 30,
        name: "Last 30 days",
        startDate: moment().subtract(29, 'days').format('DD/MM/YYYY'),
        endDate: moment().format('DD/MM/YYYY')
    },
    {
        id: 6,
        value: 90,
        name: "Last 90 days",
        startDate: moment().subtract(89, 'days').format('DD/MM/YYYY'),
        endDate: moment().format('DD/MM/YYYY')
    },
    {
        id: 7,
        value: 3,
        name: "Custom",
        startDate: moment().format('DD/MM/YYYY'),
        endDate: moment().format('DD/MM/YYYY')
    }
];

export const dummyCuttingListData = [
    {
        imageUrl: dummyCuttingListImage,
        productName: "Cortizo 4304",
        productDescription:
            "Bottom frame sub sill three rail, Special 1 Powder Coating 9005CM",
        details: [
            {
                position: "001",
                seqNo: "3",
                quantity: "1",
                cuttingLength: "6,000.0 mm",
                orientation: "B / 3",
                cut: "I - I",
                status: "0 / 0",
            },
            {
                position: "001",
                seqNo: "4",
                quantity: "1",
                cuttingLength: "1,035.0 mm",
                orientation: "B / 4",
                cut: "I - I",
                status: "0 / 0",
            },
        ],
    },
    {
        imageUrl: dummyCuttingListImage,
        productName: "Cortizo 4304",
        productDescription:
            "Bottom frame sub sill three rail, Special 1 Powder Coating 9005CM",
        details: [
            {
                position: "001",
                seqNo: "3",
                quantity: "1",
                cuttingLength: "6,000.0 mm",
                orientation: "B / 3",
                cut: "I - I",
                status: "0 / 0",
            },
            {
                position: "001",
                seqNo: "4",
                quantity: "1",
                cuttingLength: "1,035.0 mm",
                orientation: "B / 4",
                cut: "I - I",
                status: "0 / 0",
            },
        ],
    },
    {
        imageUrl: dummyCuttingListImage,
        productName: "Cortizo 4304",
        productDescription:
            "Bottom frame sub sill three rail, Special 1 Powder Coating 9005CM",
        details: [
            {
                position: "001",
                seqNo: "3",
                quantity: "1",
                cuttingLength: "6,000.0 mm",
                orientation: "B / 3",
                cut: "I - I",
                status: "0 / 0",
            },
            {
                position: "001",
                seqNo: "4",
                quantity: "1",
                cuttingLength: "1,035.0 mm",
                orientation: "B / 4",
                cut: "I - I",
                status: "0 / 0",
            },
        ],
    },
    {
        imageUrl: dummyCuttingListImage,
        productName: "Cortizo 4304",
        productDescription:
            "Bottom frame sub sill three rail, Special 1 Powder Coating 9005CM",
        details: [
            {
                position: "001",
                seqNo: "3",
                quantity: "1",
                cuttingLength: "6,000.0 mm",
                orientation: "B / 3",
                cut: "I - I",
                status: "0 / 0",
            },
            {
                position: "001",
                seqNo: "4",
                quantity: "1",
                cuttingLength: "1,035.0 mm",
                orientation: "B / 4",
                cut: "I - I",
                status: "0 / 0",
            },
        ],
    },
    {
        imageUrl: dummyCuttingListImage,
        productName: "Cortizo 4304",
        productDescription:
            "Bottom frame sub sill three rail, Special 1 Powder Coating 9005CM",
        details: [
            {
                position: "001",
                seqNo: "3",
                quantity: "1",
                cuttingLength: "6,000.0 mm",
                orientation: "B / 3",
                cut: "I - I",
                status: "0 / 0",
            },
            {
                position: "001",
                seqNo: "4",
                quantity: "1",
                cuttingLength: "1,035.0 mm",
                orientation: "B / 4",
                cut: "I - I",
                status: "0 / 0",
            },
        ],
    },
    {
        imageUrl: dummyCuttingListImage,
        productName: "Cortizo 4304",
        productDescription:
            "Bottom frame sub sill three rail, Special 1 Powder Coating 9005CM",
        details: [
            {
                position: "001",
                seqNo: "3",
                quantity: "1",
                cuttingLength: "6,000.0 mm",
                orientation: "B / 3",
                cut: "I - I",
                status: "0 / 0",
            },
            {
                position: "001",
                seqNo: "4",
                quantity: "1",
                cuttingLength: "1,035.0 mm",
                orientation: "B / 4",
                cut: "I - I",
                status: "0 / 0",
            },
        ],
    },
    // More product objects...
];

export const dummyTagSizeData = [
    {
        name: "Frame to Sash",
        dimension: 12,
    },
    {
        name: "Frame to Bead",
        dimension: 12,
    },
    {
        name: "Sash to Bead",
        dimension: 12,
    },
    {
        name: "Bead Height",
        dimension: 12,
    },
    {
        name: " Sash to Transom",
        dimension: 12,
    },
    {
        name: "Transom to Bead",
        dimension: 12,
    },
];



// const transomData = {
//     type: "horizontal",
//     id: "xxxx",
//     child: [
//         {
//             type: "horizontal",
//             id: "xxxx11",
//             child: [
//                 {
//                     type: "horizontal",
//                     id: "xxxx11",
//                     child : [
//                         {
//                             id : "xxxx09",
//                             type : "vertical"
//                         }
//                     ]
//                 },
//                 {
//                     type : "",
//                     id : "xxxx14",
//                     child : []
//                 },
//             ]
//         },
//         {
//             type: "horizontal",
//             id: "xxxx12",
//             child: [
//                 {}
//             ]
//         }
//     ]
// }

export const accessQuote = ['Admin', 'Super Admin', 'Customer', 'Trade']
export const accessSettings = ['Admin', 'Super Admin']
export const accessProduct = ['Admin', 'Super Admin', "Supplier"]
export const accessPricing = ['Admin', 'Super Admin', "Supplier"]