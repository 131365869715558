import * as THREE from 'three';

const resizeAdditionalFrame = (additionalFrame, isPerpendicular = false, isTop = false, isBottom = false, overrideHeight, overrideWidth, headerSelectedItem, topLeftCorner , sceneRef , topRightCorner) => {
  let glass;
  let frameTop, frameRight, frameLeft, frameBottom, spaceBarTop, spaceBarBottom;
  //find glass and frames of additionalFrame
  console.log("traverse additional frame")
  const boundingBoxInitial = new THREE.Box3().setFromObject(additionalFrame);
  additionalFrame.traverse((child) => {
    console.log("frame part :", child.name)
    if (child.name.includes("Glass")) {
      glass = child;
      console.log("glass found :", child.name)
    }
    if (child.name.includes("Frame")) {
      if (child.name.includes("Top")) {
        frameTop = child;
      }

      if (child.name.includes("Right")) {
        frameRight = child;
      }

      if (child.name.includes("Left")) {
        frameLeft = child;
      }

      if (child.name.includes("Bottom")) {
        frameBottom = child;
      }

    }

    if (child.name.includes("SpaceBarTop")) {
      spaceBarTop = child;
    }

    if (child.name.includes("SpaceBarBottom")) {
      spaceBarBottom = child;
    }

  })

  //resize glass
  let width = overrideWidth
  let height = overrideHeight

  const scalex = width / 1250;
  const scaley = height / 2058;

  const boundingBoxGlassI = new THREE.Box3().setFromObject(glass);
  const glassWidthI = boundingBoxGlassI.max.x - boundingBoxGlassI.min.x;
  const glassHeightI = boundingBoxGlassI.max.y - boundingBoxGlassI.min.y;
  const glassDepthI = boundingBoxGlassI.max.z - boundingBoxGlassI.min.z;

  glass.scale.x = scalex;
  glass.scale.y = scaley;

  let boundingBoxGlass = new THREE.Box3().setFromObject(glass);
  let glassWidth = boundingBoxGlass.max.x - boundingBoxGlass.min.x;
  let glassHeight = boundingBoxGlass.max.y - boundingBoxGlass.min.y;
  let glassDepth = boundingBoxGlass.max.z - boundingBoxGlass.min.z;

  //place frames 
  if (isPerpendicular) {
    if (isTop || isBottom) {
      glass.position.y -= (glassDepthI - glassDepth) / 2

      const boundingBoxFrameTop = new THREE.Box3().setFromObject(frameTop);
      const widthFrameTop = boundingBoxFrameTop.max.x - boundingBoxFrameTop.min.x;

      if (headerSelectedItem.name === "Eaves Frame") {
        frameTop.scale.x *= (glassWidth / widthFrameTop) + 0.027
      } else if (headerSelectedItem.name === "Oriel Window") {
        frameTop.scale.x *= (glassWidth / widthFrameTop) + 0.027
      } else {
        frameTop.scale.x *= glassWidth / widthFrameTop
      }

      frameTop.position.x = glass.position.x

      spaceBarTop.scale.x *= glassWidth / widthFrameTop
      spaceBarTop.position.x = glass.position.x


      frameTop.position.y += (glassDepth - glassDepthI)
      spaceBarTop.position.y += (glassDepth - glassDepthI)

      if (headerSelectedItem.name === "Eaves Frame") {
        frameBottom.scale.x *= (glassWidth / widthFrameTop) + 0.15
      } else {
        frameBottom.scale.x *= (glassWidth / widthFrameTop)
      }

      frameBottom.position.x = glass.position.x


      spaceBarBottom.scale.x *= glassWidth / widthFrameTop
      spaceBarBottom.position.x = glass.position.x

      const boundingBoxFrameRight = new THREE.Box3().setFromObject(frameRight);
      const widthFrameRight = boundingBoxFrameRight.max.z - boundingBoxFrameRight.min.z;

      frameRight.position.y += (glassDepth - glassDepthI) / 2
      frameRight.scale.x *= glassDepth / widthFrameRight
      frameRight.position.x = boundingBoxGlass.min.x

      frameLeft.position.y += (glassDepth - glassDepthI) / 2
      frameLeft.scale.x *= glassDepth / widthFrameRight
      frameLeft.position.x = boundingBoxGlass.max.x

      const updatedBoundingBoxFrameTop = new THREE.Box3().setFromObject(frameTop);
      topLeftCorner.current = new THREE.Vector3(updatedBoundingBoxFrameTop.min.x, updatedBoundingBoxFrameTop.max.y, updatedBoundingBoxFrameTop.min.z)
      topRightCorner.current = new THREE.Vector3(updatedBoundingBoxFrameTop.max.x, updatedBoundingBoxFrameTop.max.y, updatedBoundingBoxFrameTop.min.z)
      // const geometry = new THREE.BoxGeometry(0.2, 0.2, 0.2);
      // const material = new THREE.MeshBasicMaterial({ color: 0xff0000  });
      // const cube = new THREE.Mesh(geometry, material);
      // cube.position.set(topRightCorner.current.x , topRightCorner.current.y , topRightCorner.current.z)
      // sceneRef.current.add(cube)
    }
    else {

      glass.position.x -= (glassDepthI - glassDepth) / 2
      const boundingBoxFrameTop = new THREE.Box3().setFromObject(frameTop);
      const widthFrameTop = boundingBoxFrameTop.max.z - boundingBoxFrameTop.min.z;

      frameTop.scale.x *= glassDepth / widthFrameTop
      frameTop.position.x = glass.position.x
      frameTop.position.y = boundingBoxGlass.max.y

      spaceBarTop.scale.x *= glassDepth / widthFrameTop
      spaceBarTop.position.x = glass.position.x
      spaceBarTop.position.y = boundingBoxGlass.max.y

      frameBottom.scale.x *= glassDepth / widthFrameTop
      frameBottom.position.x = glass.position.x
      frameBottom.position.y = boundingBoxGlass.min.y

      spaceBarBottom.scale.x *= glassDepth / widthFrameTop
      spaceBarBottom.position.x = glass.position.x
      spaceBarBottom.position.y = boundingBoxGlass.min.y

      const boundingBoxFrameRight = new THREE.Box3().setFromObject(frameRight);
      const widthFrameRight = boundingBoxFrameRight.max.y - boundingBoxFrameRight.min.y;

      if (headerSelectedItem.name === "Eaves Frame") {
        frameRight.scale.y *= (glassHeight / widthFrameRight) + 0.3
        frameLeft.scale.y *= (glassHeight / widthFrameRight) + 0.3
      } else {
        frameRight.scale.y *= glassHeight / widthFrameRight
        frameLeft.scale.y *= glassHeight / widthFrameRight
      }

      frameLeft.position.x -= (glassDepthI - glassDepth)
    }

  } else {

    const boundingBoxG = new THREE.Box3().setFromObject(glass);
    const glassW = boundingBoxG.max.x - boundingBoxG.min.x;
    const glassH = boundingBoxG.max.y - boundingBoxG.min.y;
    const glassD = boundingBoxG.max.z - boundingBoxG.min.z;

    if (isTop || isBottom) {
      console.log("height 01")
      if (isBottom) {
        console.log("height 02")
        glass.position.y += (glassHeightI - glassHeight) / 2
      }
      if (isTop) {
        console.log("height inc : ", glassH, " : ", glassHeightI)
        glass.position.y += (glassH - glassHeightI) / 2
      }

      let boundingBoxGlass = new THREE.Box3().setFromObject(glass);
      let glassWidth = boundingBoxGlass.max.x - boundingBoxGlass.min.x;
      let glassHeight = boundingBoxGlass.max.y - boundingBoxGlass.min.y;
      let glassDepth = boundingBoxGlass.max.z - boundingBoxGlass.min.z;

      const boundingBoxFrameTop = new THREE.Box3().setFromObject(frameTop);
      const widthFrameTop = boundingBoxFrameTop.max.x - boundingBoxFrameTop.min.x;

      frameTop.scale.x *= glassWidth / widthFrameTop
      frameTop.position.x = glass.position.x

      spaceBarTop.scale.x *= glassWidth / widthFrameTop
      spaceBarTop.position.x = glass.position.x

      if (isTop) {
        frameTop.position.y -= (glassHeightI - glassHeight)
        spaceBarTop.position.y -= (glassHeightI - glassHeight)
      }

      frameBottom.scale.x *= glassWidth / widthFrameTop
      frameBottom.position.x = glass.position.x

      spaceBarBottom.scale.x *= glassWidth / widthFrameTop
      spaceBarBottom.position.x = glass.position.x

      if (isBottom) {
        frameBottom.position.y += (glassHeightI - glassHeight) / 2
        spaceBarBottom.position.y += (glassHeightI - glassHeight) / 2
      }

      const boundingBoxFrameRight = new THREE.Box3().setFromObject(frameRight);
      const widthFrameRight = boundingBoxFrameRight.max.y - boundingBoxFrameRight.min.y;

      frameRight.scale.x *= glassHeight / widthFrameRight
      frameRight.position.x += (glassWidthI - glassWidth) / 2
      frameRight.position.y = glass.position.y

      frameLeft.scale.x *= glassHeight / widthFrameRight
      frameLeft.position.x -= (glassWidthI - glassWidth) / 2
      frameLeft.position.y = glass.position.y

    }
    else {
      if (additionalFrame.position.x < 0) {
        glass.position.x += (glassWidthI - glassWidth) / 2
      } else if (additionalFrame.position.x > 0) {
        glass.position.x -= (glassWidthI - glassWidth) / 2
      }

      boundingBoxGlass = new THREE.Box3().setFromObject(glass);
      glassWidth = boundingBoxGlass.max.x - boundingBoxGlass.min.x;
      glassHeight = boundingBoxGlass.max.y - boundingBoxGlass.min.y;
      let glassDepth = boundingBoxGlass.max.z - boundingBoxGlass.min.z;

      const boundingBoxFrameTop = new THREE.Box3().setFromObject(frameTop);
      const widthFrameTop = boundingBoxFrameTop.max.x - boundingBoxFrameTop.min.x;

      frameTop.scale.x *= glassWidth / widthFrameTop
      frameTop.position.x = glass.position.x
      frameTop.position.y = boundingBoxGlass.max.y

      spaceBarTop.scale.x *= glassWidth / widthFrameTop
      spaceBarTop.position.x = glass.position.x
      spaceBarTop.position.y = boundingBoxGlass.max.y

      frameBottom.scale.x *= glassWidth / widthFrameTop
      frameBottom.position.x = glass.position.x
      frameBottom.position.y = boundingBoxGlass.min.y

      spaceBarBottom.scale.x *= glassWidth / widthFrameTop
      spaceBarBottom.position.x = glass.position.x
      spaceBarBottom.position.y = boundingBoxGlass.min.y

      const boundingBoxFrameRight = new THREE.Box3().setFromObject(frameRight);
      const widthFrameRight = boundingBoxFrameRight.max.y - boundingBoxFrameRight.min.y;

      if (additionalFrame.position.x < 0) {
        frameRight.scale.x *= glassHeight / widthFrameRight
        frameRight.position.x += (glassWidthI - glassWidth)

        frameLeft.scale.x *= glassHeight / widthFrameRight
      } else if (additionalFrame.position.x > 0) {
        frameRight.scale.x *= glassHeight / widthFrameRight

        frameLeft.scale.x *= glassHeight / widthFrameRight
        frameLeft.position.x -= (glassWidthI - glassWidth)
      }

    }
  }

}

export { resizeAdditionalFrame };