import { Checkbox, FormControl, FormControlLabel, ListItemText, MenuItem, OutlinedInput, Radio, RadioGroup, Select } from 'material-ui-core';
import React, { useState, useEffect } from 'react'
import { Form } from 'react-bootstrap';
import { MdOutlineKeyboardArrowDown } from 'react-icons/md';
import { IoIosInformationCircleOutline } from 'react-icons/io';
import {
    Row,
    Col,
    Button,
    FormGroup,
    Label,
    Input,
    FormText,
} from 'reactstrap';
import { MenuProps, printDocument } from '../../../../../../utility/data';
import { addUpdateSettingDesigner } from '../../../../../../services/designerServices';
import { useMessage } from '../../../../../../context/messageContext';
import { useNavigate } from 'react-router-dom';
import { getAllFrameCollection } from '../../../../../../services/3dModelServices';

const TradeDesignerLeft = ({ accessToken, designerSetting, setLoading, getDesignerSettingServices }) => {

    const navigate = useNavigate();

    let designerInfoInitial = {
        designerTabIframeAddress: "",
        designerHomeBackground: "",
        designerIntroText: "",
        designerGetQuote: "",
        designerLastStep: "",
        agreementComment: "",
    }

    let afterSubmittingInitial = {
        acceptAggrement: false,
        thanks: "",
        emailWhenNewEnq: false,
        emailAddress: "",
        allowSavePdf: false
    }
    let customerInfoChecks = {
        name: false,
        nameCheck: false,
        reference: false,
        referenceReq: false,
        email: false,
        emailReq: false,
        phone: false,
        phoneReq: false,
        address: false,
        addressReq: false,
        postCode: false,
        postCodeReq: false,
        comments: false,
        commentsCheck: false
    }

    const [frameInfo, setFrameInfo] = useState({ name: true, reference: false, comment: false });
    const [websiteInfo, setWebsiteInfo] = useState({ webUrl: "", webTestUrl: "", webAnalyticJs: "" });
    const [customerInfo, setcustomerInfo] = useState(customerInfoChecks);
    const [designerInfo, setDesignerInfo] = useState(designerInfoInitial);
    const [allowedProductDrop, setAllowedProductDrop] = useState([]);
    const [specificProductCollection, setSpecificProductCollection] = useState([]);
    const [designerSpecificProduct, setDesignerSpecificProduct] = useState([])
    const [afterSubmitting, setAfterSubmitting] = useState(afterSubmittingInitial)
    const [printDoc, setPrintDoc] = useState([])
    const [customerInfoSelection, setCustomerInfoSelection] = useState('infousingForm')
    const [parentIfrme, setParentIfrme] = useState('')
    const [allFrames, setAllFrames] = useState([])

    const { setMessage, setMessageType } = useMessage()


    useEffect(() => {
        console.log(designerSetting)

        if (designerSetting) {
            setWebsiteInfo({ webUrl: designerSetting?.webUrl, webTestUrl: designerSetting?.webtestUrl, webAnalyticJs: designerSetting?.webAnalyticJs })
            setSpecificProductCollection(designerSetting?.designerProductCollection)
            setDesignerSpecificProduct(designerSetting?.designerSpecificProduct);
            setDesignerInfo({ designerTabIframeAddress: designerSetting?.designerTabIframeAddress, designerHomeBackground: designerSetting.designerHomeBackground, designerIntroText: designerSetting?.designerIntroText, designerGetQuote: designerSetting?.designerGetQuote, designerLastStep: designerSetting?.designerLastStep })
            setFrameInfo({ name: designerSetting?.frameName, reference: designerSetting?.frameRefrence, comment: designerSetting?.frameComment })
            setcustomerInfo({ name: designerSetting?.name, nameCheck: designerSetting?.nameRequired, reference: designerSetting?.refrence, referenceReq: designerSetting?.refrencerequired, email: designerSetting?.email, emailReq: designerSetting?.emailRequired, phone: designerSetting?.phone, phoneReq: designerSetting?.phoneRequired, address: designerSetting?.address, addressReq: designerSetting?.addressRequired, postCode: designerSetting?.postCode, postCodeReq: designerSetting?.postCodeRequired, comments: designerSetting?.comment, commentsCheck: designerSetting?.commentRequired })
            setAfterSubmitting({ acceptAggrement: designerSetting?.acceptAggrement, thanks: designerSetting?.thanks, emailWhenNewEnq: designerSetting?.emailWhenNewEnq, emailAddress: designerSetting?.emailAddress, allowSavePdf: designerSetting?.allowSavePdf })
            setPrintDoc(designerSetting?.printDocumentId)

            setCustomerInfoSelection(designerSetting?.formInfo ? "infousingForm" : "infousingIframe")
        }
    }, [designerSetting])

    useEffect(() => {
        getFrameStyleCollectionServices()
    }, [])



    const getFrameStyleCollectionServices = async () => {
        const res = await getAllFrameCollection(accessToken)
        setAllFrames(res.data.entity)
    }


    useEffect(() => {
        if (designerSetting?.allowedProduct?.length > 0) {
            const allowedProdList = designerSetting?.allowedProduct?.map((item) => {
                const prod = allFrames.find((p) => p?.id === item)
                return prod ? prod.name : null
            })?.filter(name => name !== null)
            setAllowedProductDrop(allowedProdList || [])
        }
    }, [designerSetting])


    // function to handle frameinfo checkboxes
    const handleFrameInfoChecks = (e) => {
        const { checked, name } = e.target;
        setFrameInfo({ ...frameInfo, [name]: checked })
    }

    // function to handle  after submit section checboxes
    const handleAfterSubChecks = (e) => {
        const { checked, name } = e.target;
        setAfterSubmitting({ ...afterSubmitting, [name]: checked })
    }

    // handle customer info checkboxes
    const handleCustomerInfoChecks = (e) => {
        const { checked, name } = e.target
        setcustomerInfo({ ...customerInfo, [name]: checked })
    }

    // handle web info input boxes
    const handleWebInfo = (e) => {
        const { value, name } = e.target;
        setWebsiteInfo({ ...websiteInfo, [name]: value })
    }

    // function to handle designer info inputs
    const handleDesignerInfo = (e) => {
        const { value, name } = e.target;
        setDesignerInfo({ ...designerInfo, [name]: value })
    }
    // function to handle aftersubmit inputs
    const handleAfterSubmitInput = (e) => {
        const { value, name } = e.target;
        setAfterSubmitting({ ...afterSubmitting, [name]: value })
    }

    // function to handle allowed products dropdown
    const handleAllowedProduct = (e) => {
        const value = e.target.value
        const selectedValues = typeof value === 'string' ? value.split(',') : value;
        const finalValues = selectedValues.filter((item) => item !== null)
        setAllowedProductDrop(finalValues)
    }

    // function to handle Specific product collection dropdown
    const handleSpecificProductCollection = (e) => {
        const value = e.target.value
        const selectedValues = typeof value === 'string' ? value.split(',') : value;
        setSpecificProductCollection(selectedValues)
    }

    // function to handle Specific product dropdown
    const handleSpecificProduct = (e) => {
        const value = e.target.value
        setDesignerSpecificProduct(typeof value === 'string' ? value.split(',') : value)
    }

    // function to handle print doc dropdown
    const handlePrintDoc = (e) => {
        const value = e.target.value
        setPrintDoc(typeof value === 'string' ? value.split(',') : value)
    }

    // function to get allowed product id based on the names allowed product dropdown
    const getAllowedProductsId = allowedProductDrop?.map((name) => {
        const getIds = allFrames?.find((item) => item.name === name)
        return getIds ? getIds?.id :null
    })?.filter(id => id !== null);


    // fetch the specific product based on the specific product collection
    const getSpecificProduct = allFrames?.find((item) => item?.id === specificProductCollection)


    // arrangement of the options for binding in api
    const options = {
        id: designerSetting?.id || '',
        webUrl: websiteInfo.webUrl,
        testUrl: websiteInfo.webTestUrl,
        webAnalytics: websiteInfo.webAnalyticJs,
        allowedProducts: getAllowedProductsId,
        specificProductCollection: specificProductCollection >= 1 ? specificProductCollection : 0,
        specificProduct: designerSpecificProduct >= 1 ? designerSpecificProduct : 0,
        desingTabOrIframe: designerInfo.designerTabIframeAddress,
        designerHomeBackground: designerInfo.designerHomeBackground,
        introText: designerInfo.designerIntroText,
        getQuote: designerInfo.designerGetQuote,
        lastStep: designerInfo.designerLastStep,
        frameInfoName: frameInfo.name,
        frameInfoRef: frameInfo.reference,
        frameInfoComments: frameInfo.comment,
        parentIframe: parentIfrme,
        customerInfoName: customerInfo.name,
        customerInfoNameReq: customerInfo.nameCheck,
        customerInfoRef: customerInfo.reference,
        customerInfoRefReq: customerInfo.referenceReq,
        customerInfoEmail: customerInfo.email,
        customerInfoEmailReq: customerInfo.emailReq,
        customerInfoPhone: customerInfo.phone,
        customerInfoPhoneReq: customerInfo.phoneReq,
        customerInfoAdd: customerInfo.address,
        customerInfoAddReq: customerInfo.addressReq,
        customerInfoPostCode: customerInfo.postCode,
        customerInfoPostCodeReq: customerInfo.postCodeReq,
        customerInfoComment: customerInfo.comments,
        customerInfoCommentReq: customerInfo.commentsCheck,
        acceptAggrement: afterSubmitting.acceptAggrement,
        afterSubmitThanks: afterSubmitting.thanks,
        afterSubmitEmailAdd: afterSubmitting.emailAddress,
        afterSubmitSavePdf: afterSubmitting.allowSavePdf,
        afterSubmitEmailEnq: afterSubmitting.emailWhenNewEnq,
        printDoc: printDoc,
        designerType: 2,
        formInfo: customerInfoSelection === "infousingForm" ? true : false,
        agreementComment: designerInfo.agreementComment
    };


    const addUpdateDesignerSettingService = async () => {

        setLoading(true)
        const res = await addUpdateSettingDesigner(options, accessToken)

        if (res && res && res.status === 200) {
            console.log(res, "sss")

            if (res?.data?.statusCode === 200) {
                setLoading(false)
                setMessage(res?.data?.message);
                setMessageType('success')

                getDesignerSettingServices();

                setLoading(false)
            } else {
                setLoading(false)
                setMessage(res?.data.message ? res.data.message : 'Something went wrong');
                setMessageType('error')
            }
        } else {
            setLoading(false)
            setMessage(res?.message ? res.message : 'Something went wrong');
            setMessageType('error')

            if (res?.message === "Network Error") {
                navigate('/error/network')
            }
        }
    }

    const handleFormReset = () => {
        getDesignerSettingServices()
    }

    return (
        <>
            <div className='d-flex flex-column h-100'>

                <h5 className='main_title sub_title mb-3'>Your website</h5>

                <Form className='h-100 d-flex flex-column flex-grow-1 justify-content-between'>

                    {/* has_tab has_btns has_head -> add respective class of included compoenent in div of class inside_scroll for proper scroll */}
                    <div className='inside_scroll has_btns has_head'>
                        <Row>
                            <Col xxl="12">
                                <Row>
                                    <Col lg="6">
                                        <FormGroup className="form-group">
                                            <Label for="webUrl">
                                                Website address
                                            </Label>
                                            <Input className='mb-1' name="webUrl" onChange={(e) => handleWebInfo(e)} value={websiteInfo.webUrl || ''} />
                                            <FormText>
                                                Stops others from using your trade designer.
                                            </FormText>
                                        </FormGroup>
                                    </Col>

                                    <Col lg="6">
                                        <FormGroup className="form-group">
                                            <Label for="webTestUrl">
                                                Website test address
                                            </Label>
                                            <Input className='mb-1' name="webTestUrl" onChange={(e) => handleWebInfo(e)} value={websiteInfo.webTestUrl || ''} />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Col>

                            <Col lg="6">
                                <FormGroup className="form-group">
                                    <Label for="webAnalyticJs">
                                        Analytics JavaScript
                                    </Label>
                                    <Input className='mb-1' name="webAnalyticJs" onChange={(e) => handleWebInfo(e)} value={websiteInfo.webAnalyticJs || ''} />
                                    <FormText>
                                        Track users on your trade designer. Exclude {'<script>'} tags.
                                    </FormText>
                                </FormGroup>
                            </Col>

                            <Col lg="12">
                                <div className='border-bottom form-group mb-3'></div>
                            </Col>

                            <Col lg="12">
                                <h5 className='main_title sub_title mb-3'>Designer</h5>
                            </Col>

                            <Col lg="6">
                                <FormGroup className="form-group">
                                    <FormControl fullWidth>
                                        <Label for="allowedproducts">
                                            Allowed products
                                        </Label>
                                        <Select
                                            labelId="allowedproducts-label"
                                            id="allowedproduct"
                                            multiple
                                            value={allowedProductDrop || []}
                                            onChange={handleAllowedProduct}
                                            input={<OutlinedInput name='allowedproduct' />}
                                            renderValue={(selected) => selected.join(',')}
                                            MenuProps={MenuProps}

                                            IconComponent={() => (
                                                <MdOutlineKeyboardArrowDown size={20} className='me-1 MuiSvgIcon-root MuiSelect-icon' />
                                            )}
                                        >
                                            {allFrames.map((item, index) => (
                                                <MenuItem key={index} value={item?.name} className='check_list'>
                                                    <Checkbox checked={allowedProductDrop?.indexOf(item?.name) > -1} />
                                                    <ListItemText primary={item?.name} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </FormGroup>
                            </Col>

                            <Col lg="6">
                                <FormGroup className="form-group">
                                    <Label for="specificProductC">
                                        Start with a specific product collection
                                    </Label>

                                    <Select
                                        labelId="specificProductC-label"
                                        id="specificProductC"
                                        value={specificProductCollection}
                                        label="specificProductC"
                                        onChange={handleSpecificProductCollection}
                                        input={<OutlinedInput className='w-100' name='specificProductC' value={specificProductCollection} />}

                                        MenuProps={MenuProps}

                                        IconComponent={() => (
                                            <MdOutlineKeyboardArrowDown size={20} className='me-1 MuiSvgIcon-root MuiSelect-icon' />
                                        )}
                                    >
                                        <MenuItem value="0">None</MenuItem>
                                        {allFrames.map((item, index) => {
                                            return (
                                                <MenuItem key={index} value={item?.id}>{item.name}</MenuItem>
                                            )
                                        })}

                                    </Select>
                                </FormGroup>
                            </Col>

                            <Col lg="6">
                                <FormGroup className="form-group">
                                    <Label for="designerTabIframeAddress">
                                        Window and door  designer addresses
                                    </Label><br />
                                    <FormText name="designerTabIframeAddress" onChange={(e) => handleDesignerInfo(e)} value={designerInfo.designerTabIframeAddress}>
                                        Open a new tab or use and iFrame on top of your website with one or more of the links below
                                    </FormText>
                                </FormGroup>
                            </Col>

                            <Col lg="6">
                                <FormGroup className={`form-group ${specificProductCollection < 1 && "opacity-75"}`}>
                                    <Label for="designerSpecific" className={`${specificProductCollection < 1 && "opacity-75"}`}>
                                        Start with a specific product
                                    </Label>

                                    <Select
                                        labelId="designerSpecific-label"
                                        id="designerSpecific"
                                        value={designerSpecificProduct}
                                        disabled={specificProductCollection < 1}
                                        label="designerSpecific"
                                        onChange={handleSpecificProduct}
                                        input={<OutlinedInput className='w-100' name='designerSpecific' value={designerSpecificProduct} />}

                                        MenuProps={MenuProps}

                                        IconComponent={() => (
                                            <MdOutlineKeyboardArrowDown size={20} className='me-1 MuiSvgIcon-root MuiSelect-icon' />
                                        )}
                                    >
                                        <MenuItem value={"0"}>None</MenuItem>
                                        <MenuItem value={getSpecificProduct?.id}>{getSpecificProduct?.name}</MenuItem>
                                    </Select>
                                </FormGroup>

                            </Col>

                            <Col lg="6">
                                <FormGroup className="form-group">
                                    <Label for="designerTabIframeAddress">
                                        Tab or iFrame address
                                    </Label>
                                    <Input
                                        type="textarea"
                                        className='mb-1'
                                        rows="5"
                                        name="designerTabIframeAddress"
                                        onChange={(e) => handleDesignerInfo(e)}
                                        value={designerInfo.designerTabIframeAddress || ''}
                                    />
                                </FormGroup>
                            </Col>

                            <Col lg="6">
                                <FormGroup className="form-group">
                                    <Label for="designerHomeBackground ">
                                        Home background
                                    </Label>
                                    <Input
                                        name="designerHomeBackground"
                                        onChange={(e) => handleDesignerInfo(e)}
                                        value={designerInfo.designerHomeBackground || ''}
                                    />
                                </FormGroup>
                                <FormGroup className="form-group">
                                    <Label for="designerIntroText">
                                        Intro text
                                    </Label>
                                    <Input
                                        name="designerIntroText"
                                        onChange={(e) => handleDesignerInfo(e)}
                                        value={designerInfo.designerIntroText || ''}
                                    />
                                </FormGroup>
                            </Col>
                            <Col lg="6">
                                <FormGroup className="form-group">
                                    <Label for="designerGetQuote">
                                        ‘Get a quote’ button
                                    </Label>
                                    <Input
                                        name="designerGetQuote"
                                        onChange={(e) => handleDesignerInfo(e)}
                                        value={designerInfo.designerGetQuote || ''}
                                    />
                                </FormGroup>
                            </Col>
                            <Col lg="6">
                                <FormGroup className="form-group">
                                    <Label for="designerLastStep">
                                        Last step
                                    </Label>
                                    <Input
                                        name="designerLastStep"
                                        onChange={(e) => handleDesignerInfo(e)}
                                        value={designerInfo.designerLastStep || ''}
                                    />
                                    <FormText>Text to display on the last text button.</FormText>
                                </FormGroup>
                            </Col>
                            <Col lg="12">
                                <div className='border-bottom form-group mb-3'></div>
                            </Col>
                            <Col lg="12">
                                <h5 className='main_title sub_title mb-3'>Frame info</h5>
                            </Col>

                            <Col lg="12">
                                <Row>
                                    <Col lg="3">

                                        <FormGroup className="form-group">
                                            <Label for="name" className={`opacity-75`}>
                                                Name
                                            </Label>
                                            <Form.Check // prettier-ignore
                                                type="switch"
                                                id="name"
                                                className='form-switch-lg'
                                                label='Visible'
                                                checked
                                                disabled
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col lg="3">
                                        <FormGroup className="form-group">
                                            <Label for="reference">
                                                Reference
                                            </Label>
                                            <Form.Check // prettier-ignore
                                                type="switch"
                                                id="reference"
                                                className={`form-switch-lg ${frameInfo.reference && "text-primary"}`}
                                                label={frameInfo.reference ? 'Visible' : 'Hidden'}
                                                name='reference'
                                                onChange={(e) => handleFrameInfoChecks(e)}
                                                checked={frameInfo.reference}

                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col lg="3">

                                        <FormGroup className="form-group">
                                            <Label for="comments" >
                                                Comments
                                            </Label>
                                            <Form.Check // prettier-ignore
                                                type="switch"
                                                id="comments"
                                                className={`form-switch-lg ${frameInfo.comment && "text-primary"}`}
                                                label={frameInfo.comment ? 'Visible' : 'Hidden'}
                                                name='comment'
                                                onChange={(e) => handleFrameInfoChecks(e)}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Col>
                            <Col lg="12">
                                <div className='border-bottom form-group mb-3'></div>
                            </Col>
                            <Col lg="12">
                                <h5 className='main_title sub_title mb-3'>Customer info</h5>
                            </Col>

                            <Col lg="12">
                                <FormControl className="mb-2 w-100">
                                    <RadioGroup
                                        aria-labelledby="pageSize-label"
                                        name="customerInfoSelection"
                                        className='flex-sm-row gap-2 gap-md-5'
                                        value={customerInfoSelection}
                                        onChange={(e) => setCustomerInfoSelection(e.target.value)}
                                    >
                                        <FormControlLabel value="infousingForm" control={<Radio />} label="Get the customers info using a form" />
                                        <FormControlLabel className='ps-xl-3 ps-xl-5' value="infousingIframe" control={<Radio />} label="Send the project to the parent iFrame" />
                                    </RadioGroup>
                                </FormControl>

                                {/* <Row>
                                    <Col lg="3">
                                        <FormGroup className="form-group">
                                            <Input
                                                type="radio" // Specify the input type as "radio"
                                                name="infousingform"
                                                id="useForm"
                                                value="infousingForm"
                                                checked={customerInfoSelection === 'infousingForm'}
                                                onChange={(e) => setCustomerInfoSelection(e.target.value)}
                                            />
                                            <span className={`ms-2 ${customerInfoSelection === 'infousingForm' && "text-primary"} `}>Get the customers info using a form</span>
                                        </FormGroup>
                                    </Col>
                                    <Col lg="3">
                                        <FormGroup className="form-group">
                                            <Input
                                                type="radio" // Specify the input type as "radio"
                                                name="infousingform"
                                                id="parentIfram"
                                                value="infousingIframe"
                                                checked={customerInfoSelection === 'infousingIframe'}
                                                onChange={(e) => setCustomerInfoSelection(e.target.value)}
                                            />
                                            <span className={`ms-2 ${customerInfoSelection === 'infousingIframe' && "text-primary"} `}>Send the project to the parent iFrame</span>
                                        </FormGroup>
                                    </Col>
                                </Row> */}
                            </Col>

                            <Col lg="12">
                                <div className='mb-4'>
                                    <span className='bg-light p-1 px-2'><IoIosInformationCircleOutline size={20} /><span className='ms-2'>A project will  {customerInfoSelection === 'infousingIframe' && "not"} be created in Orbit.</span></span>
                                </div>
                            </Col>

                            {customerInfoSelection === 'infousingIframe' ?
                                <>
                                    <Col lg="12">
                                        <Col lg="6">
                                            <FormGroup className="form-group">
                                                <Label for="iframParent">
                                                    iFrame Parent
                                                </Label>
                                                <Input
                                                    type="textarea"
                                                    className='mb-1'
                                                    rows="5"
                                                    name="iframParent"
                                                    value={parentIfrme || ''}
                                                    onChange={(e) => setParentIfrme(e.target.value)}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Col>
                                </>
                                :
                                <>
                                    <Col lg="4" className='mb-2'>
                                        <Label for="name1" >
                                            Name
                                        </Label>
                                        <Row className='align-items-center'>
                                            <Col lg="6">
                                                <FormGroup className="form-group">

                                                    <Form.Check // prettier-ignore
                                                        type="switch"
                                                        id="name"
                                                        name="name"
                                                        className={`form-switch-lg ${customerInfo.name && "text-primary"}`}
                                                        label={customerInfo.name ? "Visible" : "Hidden"}
                                                        onChange={(e) => handleCustomerInfoChecks(e)}
                                                        checked={customerInfo.name}
                                                    />
                                                </FormGroup>

                                            </Col>
                                            <Col lg="6">
                                                <FormGroup className="form-group" check>
                                                    <Input
                                                        type="checkbox" // Specify the input type as "radio"
                                                        name="nameCheck"
                                                        id="radioComments1"
                                                        onChange={(e) => handleCustomerInfoChecks(e)}
                                                        checked={customerInfo.nameCheck}
                                                    />
                                                    <Label check for="radioComments1" className={`${customerInfo.nameCheck && "text-primary"}`}>Required</Label>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col lg="4" className='mb-2'>
                                        <Label for="name1" >
                                            Reference
                                        </Label>
                                        <Row className='align-items-center'>
                                            <Col lg="6">
                                                <FormGroup className="form-group">
                                                    <Form.Check // prettier-ignore
                                                        type="switch"
                                                        id="reference"
                                                        name="reference"
                                                        className={`form-switch-lg ${customerInfo.reference && "text-primary"}`}
                                                        label={customerInfo.reference ? 'Visible' : 'Hidden'}
                                                        onChange={(e) => handleCustomerInfoChecks(e)}
                                                        checked={customerInfo.reference}
                                                    />
                                                </FormGroup>

                                            </Col>
                                            <Col lg="6">
                                                <FormGroup className="form-group" check>
                                                    <Input
                                                        type="checkbox" // Specify the input type as "radio"
                                                        name="referenceReq"
                                                        id="radioComments2"
                                                        onChange={(e) => handleCustomerInfoChecks(e)}
                                                        checked={customerInfo.referenceReq}
                                                    />
                                                    <Label check for="radioComments2" className={`${customerInfo.referenceReq && "text-primary"}`}>Required</Label>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col lg="4" className='mb-2'>
                                        <Label for="name1" >
                                            Email
                                        </Label>
                                        <Row className='align-items-center'>
                                            <Col lg="6">
                                                <FormGroup className="form-group">
                                                    <Form.Check // prettier-ignore
                                                        type="switch"
                                                        id="email"
                                                        name='email'
                                                        className={`form-switch-lg ${customerInfo.email && "text-primary"}`}
                                                        label={customerInfo.email ? 'Visible' : 'Hidden'}
                                                        onChange={(e) => handleCustomerInfoChecks(e)}
                                                        checked={customerInfo.email}
                                                    />
                                                </FormGroup>

                                            </Col>
                                            <Col lg="6">
                                                <FormGroup className="form-group" check>
                                                    <Input
                                                        type="checkbox" // Specify the input type as "radio"
                                                        name="emailReq"
                                                        id="radioComments3"
                                                        onChange={(e) => handleCustomerInfoChecks(e)}
                                                        checked={customerInfo.emailReq}
                                                    />
                                                    <Label check for="radioComments3" className={`${customerInfo.emailReq && "text-primary"}`}>Required</Label>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col lg="4" className='mb-2'>
                                        <Label for="name1" >
                                            Phone
                                        </Label>
                                        <Row className='align-items-center'>
                                            <Col lg="6">
                                                <FormGroup className="form-group">

                                                    <Form.Check // prettier-ignore
                                                        type="switch"
                                                        id="phone"
                                                        name="phone"
                                                        className={`form-switch-lg ${customerInfo.phone && "text-primary"}`}
                                                        label={customerInfo.phone ? 'Visible' : 'Hidden'}
                                                        onChange={(e) => handleCustomerInfoChecks(e)}
                                                        checked={customerInfo.phone}
                                                    />
                                                </FormGroup>

                                            </Col>
                                            <Col lg="6">
                                                <FormGroup className="form-group" check>
                                                    <Input
                                                        type="checkbox" // Specify the input type as "radio"
                                                        name="phoneReq"
                                                        id="radioComments4"
                                                        onChange={(e) => handleCustomerInfoChecks(e)}
                                                        checked={customerInfo.phoneReq}
                                                    />
                                                    <Label check for="radioComments4" className={`${customerInfo.phoneReq && "text-primary"}`}>Required</Label>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col lg="4" className='mb-2'>
                                        <Label for="name1" >
                                            Address
                                        </Label>
                                        <Row className='align-items-center'>
                                            <Col lg="6">
                                                <FormGroup className="form-group">
                                                    <Form.Check // prettier-ignore
                                                        type="switch"
                                                        id="address"
                                                        name="address"
                                                        className={`form-switch-lg ${customerInfo.address && "text-primary"}`}
                                                        label={customerInfo.address ? 'Visible' : 'Hidden'}
                                                        onChange={(e) => handleCustomerInfoChecks(e)}
                                                        checked={customerInfo.address}
                                                    />
                                                </FormGroup>

                                            </Col>
                                            <Col lg="6">
                                                <FormGroup className="form-group" check>
                                                    <Input
                                                        type="checkbox" // Specify the input type as "radio"
                                                        name="addressReq"
                                                        id="radioComments5"
                                                        onChange={(e) => handleCustomerInfoChecks(e)}
                                                        checked={customerInfo.addressReq}
                                                    />
                                                    <Label check for="radioComments5" className={`${customerInfo.addressReq && "text-primary"}`}>Required</Label>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col lg="4" className='mb-2'>
                                        <Label for="name1" >
                                            Post code
                                        </Label>
                                        <Row className='align-items-center'>
                                            <Col lg="6">
                                                <FormGroup className="form-group">

                                                    <Form.Check // prettier-ignore
                                                        type="switch"
                                                        id="postcode"
                                                        name='postCode'
                                                        className={`form-switch-lg ${customerInfo.postCode && "text-primary"}`}
                                                        label={customerInfo.postCode ? 'Visible' : 'Hidden'}
                                                        onChange={(e) => handleCustomerInfoChecks(e)}
                                                        checked={customerInfo.postCode}
                                                    />
                                                </FormGroup>

                                            </Col>
                                            <Col lg="6">
                                                <FormGroup className="form-group" check>
                                                    <Input
                                                        type="checkbox" // Specify the input type as "radio"
                                                        name="postCodeReq"
                                                        id="radioComments6"
                                                        onChange={(e) => handleCustomerInfoChecks(e)}
                                                        checked={customerInfo.postCodeReq}
                                                    />
                                                    <Label check for="radioComments6" className={`${customerInfo.postCodeReq && "text-primary"}`}>Required</Label>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col lg="4" className='mb-2'>
                                        <Label for="name1" >
                                            Comments
                                        </Label>
                                        <Row className='align-items-center'>
                                            <Col lg="6">
                                                <FormGroup className="form-group">
                                                    <Form.Check // prettier-ignore
                                                        type="switch"
                                                        id="comments"
                                                        name='comments'
                                                        className={`form-switch-lg ${customerInfo?.comments && "text-primary"}`}
                                                        label={customerInfo?.comments ? 'Visible' : 'Hidden'}
                                                        onChange={(e) => handleCustomerInfoChecks(e)}
                                                        checked={customerInfo?.comments}
                                                    />
                                                </FormGroup>

                                            </Col>
                                            <Col lg="6">
                                                <FormGroup className="form-group" check>
                                                    <Input
                                                        type="checkbox" // Specify the input type as "radio"
                                                        name="commentsCheck"
                                                        id="radioComments7"
                                                        onChange={(e) => handleCustomerInfoChecks(e)}
                                                        checked={customerInfo.commentsCheck}
                                                    />
                                                    <Label check for="radioComments7" className={`${customerInfo.commentsCheck && "text-primary"}`}>Required</Label>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col lg="12">
                                        <div className='border-bottom form-group mb-3'></div>
                                    </Col>
                                    <Col lg="12">
                                        <h5 className='main_title sub_title mb-3'>Accept an agreement</h5>
                                    </Col>

                                    <Col lg="6">
                                        <FormGroup className="form-group">
                                            <Form.Check
                                                type="switch"
                                                id="acceptAggrement"
                                                className='form-switch-lg'
                                                name='acceptAggrement'
                                                label={afterSubmitting.acceptAggrement ? "On" : "Off"}
                                                onChange={(e) => handleAfterSubChecks(e)}
                                                checked={afterSubmitting.acceptAggrement}
                                            />
                                        </FormGroup>
                                    </Col>
                                    {afterSubmitting.acceptAggrement &&
                                        <Col lg="12">
                                            <Col lg="6" className='pe-3'>
                                                <FormGroup className="form-group">
                                                    <Label for="agreementComment">
                                                        Agreement
                                                    </Label>
                                                    <Input
                                                        type="textarea"
                                                        className='mb-1'
                                                        rows="5"
                                                        name="agreementComment"
                                                        onChange={(e) => handleDesignerInfo(e)}
                                                        value={designerInfo.agreementComment || ''}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Col>
                                    }
                                    <Col lg="12">
                                        <div className='border-bottom form-group mb-3'></div>
                                    </Col>
                                    <Col lg="12">
                                        <h5 className='main_title sub_title mb-3'>After submitting</h5>
                                    </Col>

                                    <Col lg="12">
                                        <Col lg="6" className='pe-3'>
                                            <FormGroup className="form-group">
                                                <Label for="thanks">
                                                    Thanks
                                                </Label>
                                                <Input
                                                    name="thanks"
                                                    onChange={(e) => handleAfterSubmitInput(e)}
                                                    checked={afterSubmitting.thanks}

                                                />
                                            </FormGroup>
                                        </Col>
                                    </Col>
                                    <Col lg="6">
                                        <FormGroup className="form-group">
                                            <Label for="printdocpdf">
                                                Print document for summary and pdf
                                            </Label>
                                            <Select
                                                labelId="printdoc-label"
                                                id="printdoc"
                                                value={printDoc}
                                                label="printdoc"
                                                onChange={handlePrintDoc}
                                                input={<OutlinedInput className='w-100' name='printdoc' />}

                                                MenuProps={MenuProps}

                                                IconComponent={() => (
                                                    <MdOutlineKeyboardArrowDown size={20} className='me-1 MuiSvgIcon-root MuiSelect-icon' />
                                                )}
                                            >
                                                {printDocument.map((item, index) => {
                                                    return (
                                                        <MenuItem value={item?.id} key={index} >{item?.name}</MenuItem>
                                                    )
                                                })}
                                            </Select>


                                            {/* {formErrors.discountableItems && (
                                <div className='mt-1 text-danger'>{formErrors.discountableItems}</div>
                            )} */}
                                        </FormGroup>
                                    </Col>
                                    <Col lg="6">
                                        {afterSubmitting.emailWhenNewEnq &&
                                            <FormGroup className="form-group">
                                                <Label for="emailAddress">
                                                    Email address
                                                </Label>
                                                <Input
                                                    name="emailAddress"
                                                    onChange={(e) => handleAfterSubmitInput(e)}
                                                    value={afterSubmitting.emailAddress}
                                                    checked={afterSubmitting.emailAddress}
                                                />
                                            </FormGroup>
                                        }
                                    </Col>
                                    <Col lg="6">
                                        <Label for='allowSavePdf'>Allow save PDF</Label>
                                        <FormGroup className="form-group">
                                            <Form.Check // prettier-ignore
                                                type="switch"
                                                id="allowSavePdf"
                                                name='allowSavePdf'
                                                className='form-switch-lg'
                                                label={afterSubmitting.allowSavePdf ? 'On' : 'Off'}
                                                onChange={(e) => handleAfterSubChecks(e)}
                                                checked={afterSubmitting.allowSavePdf}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col lg="6">
                                        <Label>Email when a new enquiry is sent</Label>
                                        <FormGroup className="form-group">
                                            <Form.Check // prettier-ignore
                                                type="switch"
                                                id="emailWhenNewEnq"
                                                name='emailWhenNewEnq'
                                                onChange={(e) => handleAfterSubChecks(e)}
                                                className='form-switch-lg'
                                                label={afterSubmitting.emailWhenNewEnq ? 'On' : 'Off'}
                                            />
                                        </FormGroup>
                                    </Col>
                                </>
                            }
                        </Row>
                    </div>

                    {/* Start: space for buttons at the bottom */}
                    <div className='d-flex gap-2 gap-xl-3 main_buttons'>
                        <Button
                            color="primary"
                            size="md"
                            className='px-4'
                            onClick={() => addUpdateDesignerSettingService()}
                        >
                            Save Changes
                        </Button>

                        <Button
                            color="primary"
                            size="md"
                            outline
                            className='px-4'
                            onClick={handleFormReset}
                        >
                            Reset
                        </Button>
                    </div>
                    {/* End: space for buttons at the bottom */}
                </Form>
            </div>
        </>
    )
}

export default TradeDesignerLeft