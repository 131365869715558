import React from 'react'
import ErrorLayout from './error-layout'

const Error429 = () => {
    return (
        <>
            <ErrorLayout 
                code={<h1 className="display-1 fw-medium">429</h1>} 
                text="Too Many Requests" 
                subText="You've made too many requests recently. Please wait and try again later"
            />
        </>
    )
}

export default Error429