import React, { useRef, useEffect } from 'react';
import * as THREE from 'three';
// import WoodBump from "../../assets/img/woodBump.jpg"
// import { render } from '@testing-library/react';
// import gold from "../../assets/img/Gold.png"
import mountains from "../../assets/img/mountain.png"
// import { useLocation } from 'react-router';
import html2canvas from 'html2canvas';

function RotatingCube({ elements, roughnessVal, colorVal, metalNessVal, bumpiness, textureVal, bump  }) {

  const canvasRef = useRef();
  const renderer = useRef(null);
  const camera = useRef(null);
  const scene = useRef(null);
  const material = useRef(null);
  const sphere = useRef(null);
  const geometry = useRef(null);

  const metal = useRef(null);
  const rough = useRef(null);
  const bumpi = useRef(null);
  const color = useRef(null);

  const baseMap = useRef(null);
  const bumpMap = useRef(null);

  // const [imageUrls, setImageUrls] = useState([])

  useEffect(() => {
    setupSphere();
  }, [])

  useEffect(() => {
    setMaterialValues(metalNessVal, roughnessVal, colorVal, bumpiness);
  }, [metalNessVal, roughnessVal, colorVal, bumpiness]);

  useEffect(() => {

    const textureLoader = new THREE.TextureLoader();

    material.current = new THREE.MeshStandardMaterial();
    textureLoader.load(elements, function (bumpMapTexture) {
      material.current.bumpMap = bumpMapTexture;

      // material.current.bumpScale = 0.06;

      bumpMap.current = bumpMapTexture;

      if (baseMap.current) {
        material.current.map = baseMap.current;
      }

      sphere.current.material = material.current;
      setMaterialValues(metal.current, rough.current, color.current, bumpi.current);
      // Render the scene
      renderer.current.render(scene.current, camera.current);
    });

  }, [elements])

  useEffect(() => {

    const textureLoader = new THREE.TextureLoader();
    material.current = new THREE.MeshStandardMaterial();
    textureLoader.load(textureVal, function (mapTexture) {

      material.current.map = mapTexture;

      baseMap.current = mapTexture;

      if (bumpMap.current) {
        material.current.bumpMap = bumpMap.current;
        // material.current.bumpScale = 0.06;
      }

      sphere.current.material = material.current;

      setMaterialValues(metal.current, rough.current, color.current, bumpi.current);
      // Render the scene
      renderer.current.render(scene.current, camera.current);
      // setTimeout(() => {
      //   captureAndDownload()
      // }, 2000);

    });

  }, [textureVal])

  const setupSphere = () => {
    scene.current = new THREE.Scene();
    // // Create a camera
    camera.current = new THREE.PerspectiveCamera(75, 1, 0.1, 1000);
    camera.current.position.z = 1.7;

    renderer.current = new THREE.WebGLRenderer({ canvas: canvasRef?.current, antialias: true, preserveDrawingBuffer: true });
    renderer.current.setSize(45, 45);
    renderer.current.setClearColor(0xffffff);

    const directionalLight = new THREE.DirectionalLight(0xffffff, 3);
    directionalLight.position.set(1, 1, 1);
    scene.current.add(directionalLight);

    geometry.current = new THREE.SphereGeometry(1, 32, 32);

    material.current = new THREE.MeshStandardMaterial();
    sphere.current = new THREE.Mesh(geometry.current, material.current);
    scene.current.add(sphere.current);
    renderer.current.render(scene.current, camera.current);

    // return new Promise((resolve) => {
    //   // ... existing code ...

    //   // Load any textures or models asynchronously
    //   // For example, load textures like this:
    //   const textureLoader = new THREE.TextureLoader();
    //   textureLoader.load(elements, function (bumpMapTexture) {
    //     material.current.bumpMap = bumpMapTexture;
    //     // ... other texture loading code ...
    //     resolve(); // Resolve the promise when everything is loaded
    //   });

    //   // ... existing code ...
    // });

  }

  const captureBalls = async () => {
    const container = document.querySelector('#ballContainer');
    try {
      const canvas = await html2canvas(container, { useCORS: true });
      const base64String = canvas.toDataURL('image/png');
      // setImageUrls((prevImageUrls) => [...prevImageUrls, base64String]);
      // console.log(imageUrls, "199");
        // onImageCapture(base64String)
    } catch (error) {
      console.error('Error capturing image:', error);
    }
  };

  function setMaterialValues(metalNessVal, roughnessVal, colorVal, bumpiness) {
    metal.current = metalNessVal;
    rough.current = roughnessVal;
    bumpi.current = bumpiness;
    color.current = colorVal;

    const textureLoader = new THREE.TextureLoader();

    const image = mountains;
    var texture = textureLoader.load(image, function () {

      // Create a cube map from the 360 image
      var cubeRenderTarget = new THREE.WebGLCubeRenderTarget(texture.image.height);

      cubeRenderTarget.fromEquirectangularTexture(renderer.current, texture);
      material.current.envMap = cubeRenderTarget.texture; // Set the cube map as the environment map

      material.current.metalness = metalNessVal; // Increase metalness for a more reflective surface
      material.current.roughness = roughnessVal; // Decrease roughness for a smoother reflection
      material.current.color = new THREE.Color(colorVal);
      if (bumpiness < 1) {
        material.current.bumpScale = bumpiness;
      }

      sphere.current.material = material.current;

      renderer.current.render(scene.current, camera.current);

      // if (capturedAccordion === "Frame") {
      //   captureBalls()
      // }
    });

    renderer.current.render(scene.current, camera.current);
  }

  return <canvas ref={canvasRef} id='ballContainer' />;
}
export default RotatingCube;
