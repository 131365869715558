import React from "react";
import Left from "./left";
import InternalLayout from "../../../../../../../layouts/internalLayout";

const AddOn = () => {
  return (
    <div>
      <InternalLayout
        pageTitle="Profiles"
        layoutType="full"
        leftContent={<Left />}
        rightContent={''}
      />
    </div>
  );
};

export default AddOn;
