import * as THREE from 'three';
import { setInitialColors } from '../helper';
import { GLTFLoader } from 'three/addons/loaders/GLTFLoader.js';



export function addSill(element, data, sillRef, gltfModel, sceneRef, collectionDetails, frameStyleBottom, defaultFrameMaterial, setDefaultSillScale, setHardwareType, internalFrameRef, externalFrameRef, rgbStringToHex, internalRAL, internalColor, externalRAL, externalColor, customModelData, setInternal, setExternal) {
    // console.log(element, "add sill")

    let loader2 = new GLTFLoader();

    if (sillRef.current) {
        sillRef.current.visible = false;
        gltfModel.current.remove(sillRef.current);
        sceneRef.current.remove(sillRef.current);
    }

    if (sceneRef.current) {
        var sillLocal
        sceneRef.current.traverse(function (object) {
            if (object.name.includes("sill")) {
                object.visible = false;
                sillLocal = object
            }
        });

        sceneRef.current.remove(sillLocal);
    }

    if (element) {
        loader2.load(element, function (gltf) {
            if (collectionDetails && collectionDetails.typeId == 2) {
                gltf.scene.scale.z = data?.width * 0.0035;
                gltf.scene.scale.y = data?.height * 0.02;
            } else {
                gltf.scene.scale.z = data?.width * 0.0055;
                gltf.scene.scale.y = data?.height * 0.035;
            }

            const boundingBox = new THREE.Box3().setFromObject(frameStyleBottom.current);
            const width = boundingBox.max.x - boundingBox.min.x;

            const boundingBox2 = new THREE.Box3().setFromObject(gltf.scene);
            const width2 = boundingBox2.max.x - boundingBox2.min.x;
            const height2 = boundingBox2.max.y - boundingBox2.min.y;
            const zLength2 = boundingBox2.max.z - boundingBox2.min.z;

            gltf.scene.position.z = boundingBox.min.z + (gltf.scene.position.z - boundingBox2.min.z);
            gltf.scene.position.y = boundingBox.min.y - height2 / 2;
            gltf.scene.position.x = gltfModel.current.position.x;

            gltf.scene.scale.x = width / width2;
            setDefaultSillScale(gltf.scene.scale.x)
            gltf.scene.name = "sill";
            sillRef.current = gltf.scene;
            sceneRef.current.add(gltf.scene);

            gltf.scene.traverse((child) => {
                if (child.name.includes("Internal")) {
                    internalFrameRef.current.push(child);
                }

                if (child.name.includes("External")) {
                    externalFrameRef.current.push(child);
                    defaultFrameMaterial.current = child.material;
                }
            })

            setInitialColors(rgbStringToHex, internalRAL, internalColor, externalRAL, externalColor, customModelData, setInternal, setExternal)

            setHardwareType('')
        });
    }
}


export function removeSill(sillRef, gltfModel, sceneRef, setFrameObject, setPricingData) {
    if (!sillRef.current) {
        return;
    }
    if (sillRef.current) {
        // gltfModel.current.remove(sillRef.current);
        sceneRef.current.remove(sillRef.current);
        setFrameObject()
    }

    setPricingData((prevPricing) => ({
        ...prevPricing,
        sill: []
    }))
}

export function setHornLength(units, sillRef, defaultSillScale) {
    if (!sillRef.current) {
        return
    }
    sillRef.current.scale.x = defaultSillScale + (units * 0.01);
}