import React from "react";
import { Table, Form } from "react-bootstrap";
import { handleFocus } from "../utility/helper";

const PricingTableComp = ({
  userDataContext,
  data,
  setData,
  newHandleInput,
  handleInput,
}) => {
  return (
    <Table responsive className="w-100 discount_table">
      <thead className="border-bottom">
        <tr className="">
          <th style={{ width: "40%", minWidth: "40%" }}></th>
          <th className="ps-5">
            {userDataContext?.role === "Supplier" ? "Selling Price" : "Cost"}
          </th>
          {/* <th>
            {userDataContext?.role === "Supplier" ? "Selling Price" : "Cost"} +
            VAT
          </th> */}
          <th className="ps-5">Mark-up $</th>
          <th className="ps-5">Mark-up %</th>
          <th className="ps-5">Total</th>
          <th className="ps-5">Total + VAT</th>
        </tr>
      </thead>
      <tbody>
        {data && data.length > 0 ? (
          <>
            {data.map((item, index) => (
              <tr key={index}>
                <td>
                  {item.name ||
                    item?.description ||
                    item?.frameStyleName ||
                    item?.productName ||
                    item?.colourName}
                </td>
                <td>
                  <Form.Control
                    type="number"
                    value={item.cost}
                    readOnly={
                      userDataContext?.role !== "Supplier" && !item?.isSelf
                    }
                    onChange={(e) =>
                      newHandleInput(
                        data,
                        setData,
                        index,
                        "cost",
                        e.target.value
                      )
                    }
                    onFocus={handleFocus}
                  />
                </td>
                {/* <td>
                  <Form.Control
                    type="number"
                    value={item.costPlusVat}
                    readOnly
                    onChange={(e) =>
                      handleInput(setData, index, "costPlusVat", e.target.value)
                    }
                  />
                </td> */}
                <td>
                  <Form.Control
                    type="number"
                    value={item.markUp}
                    readOnly={userDataContext?.role === "Supplier"}
                    onChange={(e) =>
                      newHandleInput(
                        data,
                        setData,
                        index,
                        "markUp",
                        e.target.value
                      )
                    }
                    onFocus={handleFocus}
                  />
                </td>
                <td>
                  <Form.Control
                    type="number"
                    value={item.markUpPercentage}
                    readOnly={userDataContext?.role === "Supplier"}
                    onChange={(e) =>
                      newHandleInput(
                        data,
                        setData,
                        index,
                        "markUpPercentage",
                        e.target.value
                      )
                    }
                    onFocus={handleFocus}
                  />
                </td>
                <td>
                  <Form.Control
                    type="number"
                    value={item.total}
                    readOnly
                    onChange={(e) =>
                      handleInput(setData, index, "total", e.target.value)
                    }
                  />
                </td>
                <td className="text-end">
                  <Form.Control
                    type="number"
                    value={item.totalPlusVat}
                    readOnly
                    onChange={(e) =>
                      handleInput(
                        setData,
                        index,
                        "totalPlusVat",
                        e.target.value
                      )
                    }
                  />
                </td>
              </tr>
            ))}
          </>
        ) : (
          <tr>
            <td colSpan={7}>
              <h5 className="my-4 text-center text-muted w-100">
                No data found
              </h5>
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  );
};

export default PricingTableComp;
