import React, { useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import {
    Alert,
    Button,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    Accordion,
    AccordionBody,
    AccordionHeader,
    AccordionItem,
    DropdownToggle,
    Dropdown,
    Label,
    FormGroup
} from "reactstrap";
import { GoCircleSlash } from "react-icons/go";
import { BiSolidEditAlt, BiTrash } from "react-icons/bi";
import {
    MdInfoOutline,
    MdOutlineKeyboardArrowDown,
    MdOutlineVisibility,
    MdOutlineVisibilityOff,
} from "react-icons/md";
import { getAdditionalHardware, getAdditionalProfiles, setUpdateProfileJointsStatus, setUpdateProfileStatus, setUpdateSashHangingProfileStatus, updateAdditionalHardwareStatus, updateAdditionalProfileStatus } from "../../services/ManufacturingServices";
import { getFrameData, getSashProfileData } from "../../services/3dModelServices";
import ManufacturingEditComp from "../ManufacturingEditComp";
import ManufacturingAdditionalProfile from "../ManufacturingAdditionalProfile";
import { useDesigner } from "../../context/designerContext";
import { updateProfileJointsData } from "../../utility/helper";

const HandleCuttingItem = ({
    accessToken, allItems,
    frameStyleProdId, sashProductId,
    activeTab, referencePoint,
    setAdditionalAcc, additionalAcc, 
    setLoading, collectionId, 
    allTagData, manufacturingToggle, 
    getSashHangingDataService, getProfileJointsData,
    setIsSave, profileType
}) => {

    console.log(allItems, "allItems")

    const {
        multiSelectRefPoints,
        setMultiSelectRefPoints,
        setCheckSingleSide,
        delete3DItems,
        setCustomModelData,
        customModelData,
    } = useDesigner();
    
    const [isEdit, setIsEdit] = useState(false);
    const [additionalProfiles, setAdditionalProfiles] = useState([])
    const [allProfileLoading, setAllProfileLoading] = useState(false)
    const [additionalHardware, setAdditionalHardware] = useState([])
    const [frameData, setFrameData] = useState([]);
    const [sashData, setSashData] = useState([]);
    const [sashHangingData, setSashHangingData] = useState([]);
    const [isAdditionalProfileEdit, setIsAdditionalProfileEdit] = useState(false);
    const [currentFrameProfile, setCurrentFrameProfile] = useState(null);
    const [currentSashProfile, setCurrentSashProfile] = useState(null);

    const [dropdownOpen, setDropdownOpen] = useState(false);

    const AddiitionProfileAccordion = ({ additionalItems, getData, refetchData, updateStatusServices, additionalItemType, accordionType }) => {
        return (
            <Accordion
                open={additionalAcc}
                toggle={toggleAccordion}
                onClick={(e) => handleAccordionClick(e)}
                tag="div"
                className="mt-2"
            >
                <AccordionItem tag="div">
                    {!isAdditionalProfileEdit &&
                        referencePoint?.data?.name !== "" &&
                        referencePoint?.data?.name !== "GlassPanel034" && (
                            <AccordionHeader targetId={accordionType}>
                                {accordionType == 2 ? "Additional Hardware" : "Additional Profiles"}
                                <Dropdown
                                    isOpen={dropdownOpen}
                                    toggle={toggleDropDown}
                                    className="ms-auto"
                                >
                                    <DropdownToggle
                                        color="none"
                                        className="border-0 p-0 py-1 d-block text-muted"
                                        tag="span"
                                    ></DropdownToggle>
                                </Dropdown>
                            </AccordionHeader>
                        )}

                    <AccordionBody accordionId={accordionType}>
                        {referencePoint ?
                            <ManufacturingAdditionalProfile
                                additionalProfileList={currentSashProfile?.additionalArticles}
                                isAdditionalProfileEdit={isAdditionalProfileEdit}
                                setIsAdditionalProfileEdit={setIsAdditionalProfileEdit}
                                setIsEdit={setIsEdit}
                                isEdit={isEdit}
                                setAdditionalAcc={setAdditionalAcc}
                                referencePoint={referencePoint}
                                frameStyleId={frameStyleProdId?.id}
                                accessToken={accessToken}
                                currentItem={currentSashProfile}
                                getData={getData}
                                additionalItems={additionalItems}
                                updateStatusServices={updateStatusServices}
                                allProfileLoading={allProfileLoading}
                                getSashHangingData={getSashHangingDataService}
                                collectionId={collectionId}
                                frameStyleProdId={frameStyleProdId}
                                setLoading={setLoading}
                                activeTab={activeTab}
                                refetchData={refetchData}
                                allTagData={allTagData}
                                accordionType={accordionType}
                            /> :
                            <Alert color="secondary" className="d-flex gap-2 p-2 my-2">
                                <span>
                                    <MdInfoOutline size={18} />
                                </span>
                                <span>Select one or more Profile</span>
                            </Alert>
                        }
                    </AccordionBody>
                </AccordionItem>
            </Accordion>
        )
    }

    const toggleDropDown = (e) => {
        setDropdownOpen(!dropdownOpen);
        e.stopPropagation();
    }

    const toggleAccordion = (id) => {
        if (additionalAcc === id) {
            setAdditionalAcc("0");
        } else {
            setAdditionalAcc(id);
        }
    };

    const handleAccordionClick = (e) => {
        e.stopPropagation();
    };

    useEffect(() => {
        if (referencePoint?.index > -1 || referencePoint?.index !== undefined) {
            if (manufacturingToggle && activeTab === "Sash") {
                getAdditionalProfileServices(2, referencePoint?.index, frameStyleProdId?.id)
            } else if (manufacturingToggle && activeTab === "Frame") {
                getAdditionalProfileServices(1, referencePoint?.index, frameStyleProdId?.id)
            } else if (manufacturingToggle && activeTab === "Hardware") {
                getAdditionalHardwareService(referencePoint?.index)
            }
        }
    }, [activeTab, referencePoint?.index])

    const makeProfileEnable = async (item, type) => {
        let updatedStatus = item?.status;
        if (!item?.hasOwnProperty("hangingName")) {
            if (
                referencePoint !== null &&
                !referencePoint?.data?.name?.includes("Joints")
            ) {
                let data = {
                    id: 0,
                    frameProfileId: item?.id,
                    frameStyleId: frameStyleProdId?.id,
                    referencePoint: referencePoint?.index,
                    heightFormula: null,
                    widthFormula: null,
                    status: !updatedStatus,
                };
                const res = await setUpdateProfileStatus(accessToken, data);
                if (res && res?.statusCode == 200) {
                    if (type === "sash") {
                        getSashData(referencePoint?.index);
                    } else {
                        getFameData(referencePoint?.index);
                    }
                } else {
                    if (type === "sash") {
                        getSashData(referencePoint?.index);
                    } else {
                        getFameData(referencePoint?.index);
                    }
                }
            } else {
                let data = {
                    id: 0,
                    profileJointId: item?.id,
                    frameStyleId: frameStyleProdId?.id,
                    referencePoint: referencePoint?.index,
                    status: !updatedStatus,
                };
                const res = await setUpdateProfileJointsStatus(accessToken, data);
                if (res && res?.data?.statusCode === 200) {
                    getProfileJointsData(referencePoint?.index);
                    const referenceName = referencePoint?.data?.name;
                    const data = {
                        name: item?.name,
                        id: item?.id,
                        frame: item?.frame,
                        referencePoint: referenceName,
                    };

                    if (!updatedStatus) {
                        setCustomModelData((prevData) =>
                            updateProfileJointsData(prevData, data, referencePoint)
                        );
                    } else {
                        setCustomModelData((prevData) => ({
                            ...prevData,
                            profileJointsData: Array.isArray(prevData.profileJointsData)
                                ? prevData.profileJointsData.filter(
                                    (item) => item.referencePoint !== referenceName
                                )
                                : [],
                        }));
                    }
                    setIsSave(true);
                } else {
                    getProfileJointsData(referencePoint?.index);
                }
            }
        } else {
            let data = {
                id: 0,
                sashHangingId: item?.id,
                frameStyleId: frameStyleProdId?.id,
                referencePoint: referencePoint?.index,
                heightFormula: null,
                widthFormula: null,
                status: !updatedStatus,
            };
            const res = await setUpdateSashHangingProfileStatus(accessToken, data);
            if (res?.data?.statusCode === 200) {
                getSashHangingDataService(referencePoint?.index);
            } else {
                getSashHangingDataService(referencePoint?.index);
            }
        }
    };

    const getSashData = async (referencePoint) => {
        let data = {
            collectionId: collectionId,
            sashProductId: sashProductId?.id,
            frameStyleId: frameStyleProdId?.id,
            referencePoint: [referencePoint],
            type: 2,
        };
        const res = await getSashProfileData(accessToken, data);
        if (res && res.status === 200) {
            if (res?.data?.statusCode === 200) {
                setSashData(res?.data?.entity?.sash);
            }
        }
    };

    const getFameData = async (referencePoint) => {
        const res = await getFrameData(
            accessToken,
            collectionId,
            frameStyleProdId?.id,
            [referencePoint],
            2
        );
        if (res && res.status === 200) {
            if (res?.data?.statusCode === 200) {
                setFrameData(res?.data?.entity?.frame);
            }
        }
    };

    const getAdditionalProfileServices = async (type, index, frameStyleId) => {

        setAllProfileLoading(true)
        const data = {
            type,
            frameStyleId,
            index
        }
        const res = await getAdditionalProfiles(accessToken, data)
        if (res && res?.data?.statusCode) {
            setAdditionalProfiles(res.data.entity)
            setAllProfileLoading(false)
        }
    }

    const getAdditionalHardwareService = async (referencePoint) => {
        setAllProfileLoading(true)
        const res = await getAdditionalHardware(accessToken, frameStyleProdId?.id, collectionId, referencePoint)
        if (res.data.statusCode === 200) {
            setAdditionalHardware(res.data.entity)
            setAllProfileLoading(false)
        }
    }

    const additionalProfileStatusServices = async (data) => {
        let typeId;
        if (profileType === "Frame") {
            typeId = 1;
        } else if (profileType === "Sash") {
            typeId = 2
        }
        const res = await updateAdditionalProfileStatus(accessToken, referencePoint.index, data, frameStyleProdId?.id, typeId)
        if (res.data.statusCode === 200) {
            getAdditionalProfileServices(typeId, referencePoint?.index, frameStyleProdId?.id)
        }
    }

    const additionalHardwareStatusServices = async (data) => {

        const res = await updateAdditionalHardwareStatus(accessToken, referencePoint.index, data, frameStyleProdId?.id)
        if (res.data.statusCode === 200) {
            getAdditionalHardwareService(referencePoint?.index)
        }
    }

    return (
        <>
            {!isEdit ? (
                <>
                    {multiSelectRefPoints && multiSelectRefPoints?.length > 0 ? (
                        <div className="mt-1 right_list">
                            {allItems && allItems.length > 0 ? (
                                <>
                                    {allItems?.map((item, index) => (
                                        <div
                                            key={index}
                                            className={`position-relative d-flex align-items-center right_item_wrap has_delete has_copy ${item.status ? '' : 'd-none'}`}
                                        >
                                            <div className={`right_item w-100`}>
                                                <span
                                                    className={`me-2 image_icon ${item?.status || item.defaultProfile
                                                        ? ""
                                                        : "opacity-50"
                                                        }`}
                                                >
                                                    {item.imagePath ? (
                                                        <Image src={item.imagePath} />
                                                    ) : (
                                                        <svg viewBox="0 0 100 50" width="100" height="50">
                                                            <path
                                                                className="stroke"
                                                                stroke="#989898"
                                                                fill="#fff"
                                                                strokeWidth="2"
                                                                d={`M 0,50 0,0 100,0 100,50 Z`}
                                                            ></path>
                                                        </svg>
                                                    )}
                                                </span>

                                                <div className="right_title">
                                                    <span
                                                        className={`text-truncate ${item?.status || item.defaultProfile
                                                            ? "text-dark"
                                                            : "text-muted-light"
                                                            }`}
                                                    >
                                                        {item.name}
                                                    </span>
                                                    <span className="text-truncate d-block text-small">
                                                        {item.description}
                                                    </span>
                                                </div>

                                                <span className="position-absolute action_btns">
                                                    {/* {!item.defaultProfile && (
                                                        <NavLink
                                                            onClick={() => makeProfileEnable(item, "sash")}
                                                        >
                                                            {item?.status ? (
                                                                <MdOutlineVisibility
                                                                    className="text-muted"
                                                                    size={18}
                                                                />
                                                            ) : (
                                                                <MdOutlineVisibilityOff
                                                                    className="text-muted"
                                                                    size={18}
                                                                />
                                                            )}
                                                        </NavLink>
                                                    )} */}

                                                    {item?.status && (
                                                        <NavLink
                                                            onClick={() => {
                                                                setIsEdit(true);
                                                                setCurrentSashProfile(item);
                                                            }}
                                                        >
                                                            <BiSolidEditAlt
                                                                className="text-muted"
                                                                size={18}
                                                            />
                                                        </NavLink>
                                                    )}
                                                </span>
                                            </div>
                                        </div>
                                    ))}

                                    {additionalAcc != 2 && (
                                        <AddiitionProfileAccordion
                                            additionalItems={additionalProfiles}
                                            getData={getSashData}
                                            refetchData={getAdditionalProfileServices}
                                            updateStatusServices={additionalProfileStatusServices}
                                            additionalItemType={"sash"}
                                            accordionType="1"
                                        />
                                    )}


                                    {additionalAcc != 1 && (
                                        <AddiitionProfileAccordion
                                            additionalItems={additionalHardware}
                                            getData={getFameData}
                                            refetchData={getAdditionalHardwareService}
                                            updateStatusServices={additionalHardwareStatusServices}
                                            additionalItemType={"hardware"}
                                            accordionType="2"
                                        />
                                    )}
                                </>

                            ) : (
                                <Alert color="secondary" className="d-flex gap-2 p-2 my-2">
                                    <span>
                                        <MdInfoOutline size={18} />
                                    </span>
                                    <span>No profiles enabled</span>
                                </Alert>
                            )}
                        </div>
                    ) : (
                        <Alert color="secondary" className="d-flex gap-2 p-2 my-2">
                            <span>
                                <MdInfoOutline size={18} />
                            </span>
                            <span>Select one or more sash to edit</span>
                        </Alert>
                    )}
                </>
            ) : (
                <ManufacturingEditComp
                    setIsEdit={setIsEdit}
                    frameStyleProdId={frameStyleProdId}
                    referencePoint={referencePoint}
                    accessToken={accessToken}
                    currentItem={currentSashProfile}
                    setLoading={setLoading}
                    getData={getSashData}
                    getSashHangingData={getSashHangingDataService}
                    collectionId={collectionId}
                    isAdditionProfile={false}
                    allTagData={allTagData}
                />
            )}
        </>
    );
};

export default HandleCuttingItem;
