import axios from "axios";
import { servicePath } from "../utility/data";

// get all right menu item list based on page/type
export const getAllPrintTemplate = (accessToken, type) => {
    const formData = new FormData()

    formData.append('Type', type);
    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'multipart/form-data'
        }
    };

    return axios
        .post(`${servicePath}/PrintTemplate/getAllPrintTemplate`, formData, config)
        .then((res) => {
            return res;
        })
        .catch((error) => {
            return error;
        });
}

// get details of selected item in right menu
export const getItemDetails = (accessToken, id) => {
    const formData = new FormData();
    formData.append("Id", id);

    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'multipart/form-data'
        }
    };

    return axios
        .post(`${servicePath}/PrintTemplate/GetPrintTemplate`, formData, config)
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
};

// delete selected item
export const deleteRightItem = (accessToken, id) => {
    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    const formData = new FormData()
    formData.append("Id", id)
    return axios
        .post(`${servicePath}/PrintTemplate/DeletePrintTemplate`, formData, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
}

// update order of selected item
export const updateOrder = (accessToken, id, pageType, direction) => {

    // console.log(accessToken, id, pageType, direction, "update order foreword1")

    var requestBody = {
        "id": id,
        "sequenceNo": 0,
        "typeText": pageType,
        "type": direction
    };

    const headers = {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
    }

    return axios
        .post(`${servicePath}/PrintTemplate/UpdatePrintTemplateOrder`, requestBody, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
}

// update details of selected item
export const updateItemDetails = (accessToken, id, data, type) => {

    // console.log(id, data,type, "update item API")

    const headers = {
        'accept': 'text/plain',
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'multipart/form-data'
    }

    const formData = new FormData();
    formData.append("Id", id);
    formData.append("Templatetype", type);
    formData.append('Name', data?.name ? data.name : '');
    formData.append('TemplateText', data?.text ? data.text : '');

    return axios
        .post(`${servicePath}/PrintTemplate/UpdatePrintTemplate`, formData, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
};

// add new right menu item based on page/type
export const addNewRightItem = (accessToken, data, type, TemplateText) => {
    const formData = new FormData();

    formData.append("Id", '');
    formData.append('Templatetype', type);
    formData.append('Name', data);
    formData.append('TemplateText', TemplateText ? TemplateText : "text");

    const headers = {
        'accept': 'text/plain',
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'multipart/form-data'
    }
    return axios
        .post(`${servicePath}/PrintTemplate/AddPrintTemplate`, formData, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
};