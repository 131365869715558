import React, { useState, useEffect } from 'react'
import { BiPlus, BiTrashAlt } from 'react-icons/bi';
import { MdArrowDownward, MdArrowUpward, MdOutlineDashboardCustomize } from 'react-icons/md';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { useAncillary } from '../../../../../../context/ancillarycontext';
import { deleteFrameAncillary, updateOrder } from '../../../../../../services/ancillaryServices';
import { useMessage } from '../../../../../../context/messageContext';

const AncillaryRight = ({ accessToken, setLoading, allAncillaries, addAncillaryServices, frameAncillaryServices, uniqueAncillary }) => {

    const [previouseSelected, setPreviouseSelected] = useState({ prevId: 1, prevIndex: 1 })
    const [currentSelected, setCurrentSelected] = useState({ currId: 1, currIndex: 1 })
    const [orderUpdate, setOrderUpdate] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)

    const [isDeleting, setIsDeleting] = useState(false)
    const navigate = useNavigate()


    const { timestamp, newAncillary, handleActiveIndexUp, handleActiveIndexDown, activeIndex, setActiveIndex, fetchSelected, setNewAncillary } = useAncillary();
    const { setMessage, setMessageType } = useMessage()

    useEffect(() => {
        const ancillaryName = allAncillaries?.map((item) => {
            return {
                name: item.description,
                id: item.id
            }
        })

        setNewAncillary(ancillaryName)
    }, [allAncillaries])

    useEffect(() => {
        if (orderUpdate) {
            updateOrderServices()
        }

    }, [currentSelected, previouseSelected])

    useEffect(() => {
        setActiveIndex(0)
    }, [])

    // useEffect(() => {
    //     if(timestamp) {
    //         setCurrentSelected({ currId: timestamp});
    //     }
    // }, [timestamp])

    const handleItemClick = (currentIndex, id) => {
        setCurrentSelected({ currId: id, currIndex: currentIndex + 1 });
        if (currentSelected) {
            setPreviouseSelected({
                prevId: currentSelected.currId,
                prevIndex: currentSelected.currIndex,
            });
        }
        fetchSelected(currentIndex, id);
    };

    const deleteAncillaryServices = async () => {
        setIsDeleting(true)

        const res = await deleteFrameAncillary(accessToken, uniqueAncillary?.id)
        if (res?.statusCode === 200) {
            setMessage(res?.entity)
            setMessageType('success')
            setShowDeleteModal(false)
            setIsDeleting(false)

            frameAncillaryServices()

        } else if (res?.statusCode === 400) {
            navigate('/error/404')
            setOrderUpdate(false)
            setShowDeleteModal(false)
            setIsDeleting(false)
        } else {
            setMessage(res?.message)
            setMessageType('error')
            setShowDeleteModal(false)
            setIsDeleting(false)
        }
    }

    // api to update right side item
    const updateOrderServices = async (type, direction) => {
        setLoading(true)

        const res = await updateOrder(accessToken, timestamp, type, direction)

        if (res && res && res.status === 200) {

            if (res?.data?.statusCode === 200) {
                frameAncillaryServices()
                setOrderUpdate(false)
            } else {
                setLoading(false)
                setMessage(res?.data?.message ? res.data.message : 'Something went wrong');
                setMessageType('error')
            }
        } else {
            setLoading(false)
            setMessage(res?.message ? res.message : 'Something went wrong');
            setMessageType('error')

            if (res?.message === "Network Error") {
                navigate('/error/network')
            }
        }
    }

    const handleItemUp = () => {
        handleActiveIndexUp()
        updateOrderServices(1, "UP")
    }

    const handleItemDown = () => {
        handleActiveIndexDown()
        updateOrderServices(1, "DOWN")
    }

    return (
        <>
            <div className='h-100 d-flex flex-column flex-grow-1 justify-content-between'>
                <div className='inside_scroll has_btns'>
                    <div className='right_list'>
                        {newAncillary?.map((item, index) =>
                            <div key={index} className='position-relative d-flex align-items-center right_item_wrap has_delete'>
                                <Link to="#" className={`right_item w-100 ${activeIndex === index && "active"}`} onClick={() => { handleItemClick(index, item.id) }} >
                                    <span className='me-2'>
                                        <MdOutlineDashboardCustomize size={20} />
                                    </span>
                                    <span className='text-truncate'>
                                        {item.name}
                                    </span>
                                </Link>
                                <span className='position-absolute action_btns'>
                                    <Link to="#" onClick={() => { setShowDeleteModal(true); fetchSelected(index, item.id); }}> <BiTrashAlt className='text-muted' size={20} /> </Link>
                                </span>
                            </div>

                        )}
                    </div>
                </div>

                <div className='d-flex gap-2 gap-xl-3 main_buttons'>
                    <Button
                        color="primary"
                        size="md"
                        className='px-4'
                        onClick={() => addAncillaryServices(`Ancillary ${newAncillary && newAncillary.length ? (newAncillary.length + 1) : 1}`)}
                    >
                        <BiPlus size={20} className='me-1' />
                        New
                    </Button>

                    <Button
                        color=""
                        size="md"
                        className='border-0 text-primary px-0'
                        onClick={() => handleItemUp()}
                        disabled={activeIndex === 0 || !timestamp}
                    >
                        <MdArrowUpward size={22} className='me-1' />
                        Up
                    </Button>
                    <Button
                        color=""
                        size="md"
                        className='border-0 text-primary px-0'
                        onClick={() => handleItemDown()}
                        disabled={newAncillary.length > 0 ? ((newAncillary?.length - 1) === activeIndex) : true}

                    >
                        <MdArrowDownward size={22} className='me-1' />
                        Down
                    </Button>
                </div>
            </div>

            <Modal isOpen={showDeleteModal} toggle={() => setShowDeleteModal(false)}>
                <ModalHeader >
                    Delete Ancillary
                </ModalHeader>
                <ModalBody>
                    Are you sure you want delete <strong>{uniqueAncillary?.description}</strong> ?
                </ModalBody>
                <ModalFooter>
                    <Button color="outline-danger" size="md" disabled={isDeleting} onClick={() => deleteAncillaryServices()}>Delete</Button>
                    <Button onClick={() => setShowDeleteModal(false)} color="primary" size="md">Cancel</Button>
                </ModalFooter>
            </Modal>
        </>
    )
}

export default AncillaryRight