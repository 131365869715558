import React, { useState } from 'react'
import InternalLayout from '../../../../../../layouts/internalLayout'
import AncillaryLeft from './ancillaryleft'
import AncillaryRight from './ancillaryright'
import { addAncillary, getFrameAncillary, getUniqueFrameAncillary, updateAncillary } from '../../../../../../services/ancillaryServices'
import { useMessage } from '../../../../../../context/messageContext'
import { useEffect } from 'react'
import { useAncillary } from '../../../../../../context/ancillarycontext'
import { useNavigate } from 'react-router-dom'
import { Oval } from 'react-loader-spinner'

const Ancillaries = () => {

    const accessToken = localStorage.getItem('access_token')

    const [loading, setLoading] = useState(false)
    const [uniqueAncillary, setUniqueAncillary] = useState('')
    const [allAncillaries, setAllAncillaries] = useState([])
    const { setMessage, setMessageType } = useMessage()

    const { activeIndex, timestamp, fetchSelected, setTimestamp } = useAncillary()

    const navigate = useNavigate();

    var getFirstElId = allAncillaries[0]?.id

    var itemAddedTrue = ''

    useEffect(() => {
        setTimestamp('')
        
        setTimeout(() => {
            frameAncillaryServices()
        }, 400);
    }, [])

    useEffect(() => {
        if (allAncillaries?.length > 0 && timestamp) {
            getUniqueFrameAncillaryServices(timestamp)
        }
    }, [timestamp])

    useEffect(() => {
        if (allAncillaries?.length > 0) {
            if (getFirstElId) {
                if (allAncillaries?.length && activeIndex !== 0) {
                    getUniqueFrameAncillaryServices(timestamp)
                } else {
                    getUniqueFrameAncillaryServices(getFirstElId)
                }
            }
        } else {
            setUniqueAncillary([])
            setTimestamp('')
        }
    }, [allAncillaries])

    // api call to get all ancillaries
    const frameAncillaryServices = async () => {
        const res = await getFrameAncillary(accessToken)

        if (res?.statusCode === 200) {

            if (res?.entity !== null) {
                if (res?.entity?.length > 0) {
                    setAllAncillaries(res?.entity)

                    if (itemAddedTrue == 'added') {
                        const addedItemIndex = res.entity.length - 1

                        if (addedItemIndex) {
                            fetchSelected(addedItemIndex, res.entity[addedItemIndex]?.id)
                            itemAddedTrue = ''
                        }
                    }

                } else {
                    setAllAncillaries([])
                    setTimestamp('')

                    fetchSelected(0, '');
                }
            }

            setTimeout(() => {
                setLoading(false)
            }, 1200);

        } else if (res?.statusCode === 404) {
            setLoading(false)
            navigate('/error/404')
        } else {
            setLoading(false)
            setMessage(res.message)
            setMessageType('error')
        }
    }

    // api call to add all ancillaries
    const addAncillaryServices = async (data) => {
        setLoading(true)
        itemAddedTrue = "added"

        const res = await addAncillary(accessToken, data)

        if (res?.statusCode === 200) {
            setMessageType('success')
            setMessage(res.message)

            frameAncillaryServices()

            // fetchSelected(0, allAncillaries[0]?.id);

        } else if (res?.statusCode === 404) {
            setLoading(false)
            navigate('/error/404')
        } else {
            setLoading(false)
            setMessage(res.message)
            setMessageType('error')
        }
    }

    // api call to update all ancillaries
    const updateAncillaryServices = async (data) => {
        setLoading(true)
        const res = await updateAncillary(accessToken, timestamp, data)
        if (res?.statusCode === 200) {
            setLoading(false);
            setMessage(res.message)
            setMessageType('success')
            // frameAncillaryServices()
        } else if (res?.statusCode === 404) {
            setLoading(false)
            navigate('/error/404')
        } else {
            setLoading(false)
            setMessage(res.message);
            setMessageType('error')
        }
    }

    // api to feed form when ancillary is clicked
    const getUniqueFrameAncillaryServices = async (id) => {
        setLoading(true)
        const res = await getUniqueFrameAncillary(accessToken, id)
        if (res?.statusCode === 200) {
            setUniqueAncillary(res.entity)

            setTimestamp(id)

            setTimeout(() => {
                setLoading(false)
            }, 1200);
        } else if (res?.statusCode === 404) {
            navigate('/error/404')
            setLoading(false)
        } else {
            setMessage(res.message)
            setMessageType('error')
            setLoading(false)
        }
    }

    const handleFormReset = () => {
        if (timestamp) {

            frameAncillaryServices()

            getUniqueFrameAncillaryServices(timestamp)
        }
    }

    return (
        <>
            {loading &&
                <div className='loader_main full_loader'>
                    <Oval
                        height={50}
                        width={50}
                        color="#44C8F5"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                        ariaLabel='oval-loading'
                        secondaryColor="#44C8F5"
                        strokeWidth={2}
                        strokeWidthSecondary={2}
                    />
                </div>
            }

            <InternalLayout
                pageTitle="Frame Ancillaries"
                leftContent={<AncillaryLeft handleFormReset={handleFormReset} updateAncillaryServices={updateAncillaryServices} loading={loading} uniqueAncillary={uniqueAncillary} />}
                rightContent={<AncillaryRight accessToken={accessToken} setLoading={setLoading} allAncillaries={allAncillaries} addAncillaryServices={addAncillaryServices} loading={loading} frameAncillaryServices={frameAncillaryServices} uniqueAncillary={uniqueAncillary} />}
            />
        </>
    )
}

export default Ancillaries