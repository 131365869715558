import {
  getTrasomsFrameService,
  setModelData,
  setQuotationModelData,
  setQuotationPriceModelProperty,
} from "../services/3dModelServices";

export function storeExtColor(
  externalColor,
  externalRAL,
  internalColor,
  internalRAL,
  rgbStringToHex,
  setCustomModelData,
  setSaveColors
) {
  let externalColour = {
    name: externalColor?.name,
    hex:
      externalRAL !== undefined
        ? rgbStringToHex(externalRAL?.rgbValue)
        : externalColor?.colour,
    customRALName: externalRAL !== undefined ? externalRAL?.name : "",
    customRALCode: externalRAL !== undefined ? externalRAL?.number : "",
    rgbValue: externalRAL !== undefined ? externalRAL?.rgbValue : "",
    type: "external",
    id: 1,
    itemId: externalColor?.id,
    primaryId: externalColor?.primaryId,
    custom_Id: externalRAL !== undefined ? externalRAL?.id : "",
    obj: "External",
    model:
      "https://testorbitapp.augursapps.com/Content/VisualElement/White9017.svg",
    bumpiness: 0,
    roughness: 0,
    metalness: 0,
    price: externalColor?.price || 0,
    priceInpercentage: externalColor?.priceInpercentage || 0,
    barLengthPrice: externalColor?.barLengthPrice || 0,
    stockColor: externalColor?.stockColour,
    mmSquarePrice: externalColor?.mmSquarePrice,
  };

  let internalColour = {
    name: internalColor?.name,
    hex:
      internalRAL !== undefined
        ? rgbStringToHex(internalRAL?.rgbValue)
        : internalColor?.colour,
    customRALName: internalRAL !== undefined ? internalRAL?.name : "",
    customRALCode: internalRAL !== undefined ? internalRAL?.number : "",
    rgbValue: internalRAL !== undefined ? internalRAL?.rgbValue : "",
    type: "internal",
    id: 1,
    itemId: internalColor?.id,
    custom_Id: internalRAL !== undefined ? internalRAL?.id : "",
    obj: "Internal",
    model:
      "https://testorbitapp.augursapps.com/Content/VisualElement/White9017.svg",
    bumpiness: 0,
    roughness: 0,
    metalness: 0,
    price: internalColor?.price || 0,
    priceInpercentage: internalColor?.priceInpercentage || 0,
    barLengthPrice: internalColor?.barLengthPrice || 0,
    stockColor: internalColor?.stockColour,
    mmSquarePrice: externalColor?.mmSquarePrice,
  };

  setCustomModelData((prevModelData) => ({
    ...prevModelData,
    frame: {
      ...prevModelData.frame,
      externalColor: externalColour,
      internalColor: internalColour?.itemId
        ? internalColour
        : prevModelData?.frame?.internalColor,
    },
  }));

  setTimeout(() => {
    setSaveColors(true);
  }, 100);
}

export async function saveModelData(
  accessToken,
  modelId,
  styleId,
  customModelData,
  navigate,
  setMessage,
  setMessageType
) {
  const res = await setModelData(
    accessToken,
    modelId,
    styleId,
    customModelData
  );
  if (res?.status == 200) {
    if (res?.data?.statusCode === 200) {
      setMessageType("success");
      setMessage(res?.data?.message);
    }
  } else {
    setMessageType("error");
    setMessage(
      res?.data?.message ? res?.data?.message : "Something went wrong !"
    );
    if (res?.response?.status === 401) {
      navigate("/unauthorized");
      localStorage.removeItem("access_token");
    }
    if (res?.response?.status == 404) {
      navigate("/error/503");
    }
    if (res?.message === "Network Error") {
      navigate("/error/network");
    }
  }
}

export async function saveModelDataInit(
  accessToken,
  modelId,
  styleId,
  customModelData,
  setSaveData
) {
  const res = await setModelData(
    accessToken,
    modelId,
    styleId,
    customModelData
  );

  if (res && res.status === 200) {
    setSaveData(false);
  } else {
    setSaveData(false);
  }
}

export async function saveModelDataQuotation(
  accessToken,
  modelId,
  styleId,
  customModelData,
  quotationId,
  receivedProductId,
  navigate,
  setMessage,
  setMessageType
) {
  const res = await setQuotationModelData(
    accessToken,
    modelId,
    styleId,
    customModelData,
    quotationId,
    receivedProductId
  );
  if (res?.status == 200) {
    if (res?.data?.statusCode === 200) {
      setMessageType("success");
      setMessage(res?.data?.message);
    }
  } else {
    setMessageType("error");
    setMessage(
      res?.data?.message ? res?.data?.message : "Something went wrong !"
    );
    if (res?.response?.status === 401) {
      navigate("/unauthorized");
      localStorage.removeItem("access_token");
    }
    if (res?.response?.status == 404) {
      navigate("/error/503");
    }
    if (res?.message === "Network Error") {
      navigate("/error/network");
    }
  }
}

export async function saveQuotationPriceData(
  accessToken,
  receivedProductId,
  priceJson,
  navigate,
  setMessage,
  setMessageType
) {
  const res = await setQuotationPriceModelProperty(
    accessToken,
    receivedProductId,
    priceJson
  );
  if (res?.status == 200) {
    if (res?.data?.statusCode === 200) {
      setMessageType("success");
      setMessage(res?.data?.message);
    }
  } else {
    setMessageType("error");
    setMessage(
      res?.data?.message ? res?.data?.message : "Something went wrong !"
    );
    if (res?.response?.status === 401) {
      navigate("/unauthorized");
      localStorage.removeItem("access_token");
    }
    if (res?.response?.status == 404) {
      navigate("/error/503");
    }
    if (res?.message === "Network Error") {
      navigate("/error/network");
    }
  }
}

export async function getFramesProfilesDefault(
  accessToken,
  modelId,
  setFrameProfileDefault
) {
  const res = await getTrasomsFrameService(accessToken, modelId, 6);
  if (res && res.status === 200) {
    if (res?.data?.statusCode === 200) {
      if (res?.data?.entity && res?.data?.entity?.frame?.length > 0) {
        const frameDefault = res?.data?.entity?.frame?.find(
          (p) => p.defaultProfile === true
        );

        if (frameDefault) {
          setFrameProfileDefault(frameDefault);
        } else {
          setFrameProfileDefault(res?.data?.entity?.frame[0]);
        }
      }
    }
  }
}

const getCanvasAsBase64 = (canvas) => {
  return canvas?.toDataURL("image/png");
};

// This is for adding colorPrice key in the hardware object
export const addHardwareColorPricing = (color, type, name, setMethod) => {
  setMethod((prevData) => {
    // Create a copy of the hardware array inside customModelData
    const updatedHardware = prevData?.hardware?.[type]?.map((item) => {
      if (item?.name === name) {
        // Return a new object with the updated colorPrice
        return {
          ...item,
          colorPrice: color.price,
        };
      }
      return item;
    });

    // Return a new object with the updated hardware array and other properties spread
    return {
      ...prevData,
      hardware: {
        ...prevData.hardware,
        [type]: updatedHardware,
      },
    };
  });
};
