import React, { useEffect, useState } from 'react'
import InternalLayout from '../../../../../../layouts/internalLayout'
import AncillaryLeft from './ancillariesleft'
import AncillaryRight from './ancillariesright'
import { addAncillaryProject, getProjectAncillaries, getUniqueProjectAncillary, updateAncillaryProject } from '../../../../../../services/ancillaryServices'
import { useMessage } from '../../../../../../context/messageContext'
import { useNavigate } from 'react-router-dom'
import { useAncillary } from '../../../../../../context/ancillarycontext'
import { Oval } from 'react-loader-spinner'

const AncillaryMain = () => {
    const accessToken = localStorage.getItem('access_token')

    const [ancillaryList, setAncillaryList] = useState([])
    const [uniquePorjectAncillary, setuniquePorjectAncillary] = useState();
    const [loading, setLoading] = useState(false)

    const navigate = useNavigate();

    // custom hooks with the context
    const { setMessage, setMessageType } = useMessage()

    const { activeIndex, timestamp, fetchSelected, setTimestamp } = useAncillary()

    let getFirstElId = ancillaryList[0]?.id;
    var itemAddedTrue = ''

    useEffect(() => {
        setTimestamp('')
        
        setTimeout(() => {
            getAllProjectAncillaryServices()
        }, 400);
    }, [])

    useEffect(() => {
        if (ancillaryList?.length > 0 && timestamp) {
            getUniqueProjectAncillaryServices(timestamp)
        }
    }, [timestamp])

    useEffect(() => {
        if (ancillaryList.length > 0) {
            if (getFirstElId) {
                if (ancillaryList?.length && activeIndex !== 0) {
                    getUniqueProjectAncillaryServices(timestamp)
                } else {
                    getUniqueProjectAncillaryServices(getFirstElId)
                }
            }
        } else {
            setuniquePorjectAncillary([])
        }
    }, [ancillaryList])

    // Api call to update project ancillaries services
    const updateProjectAncillaryServices = async (data) => {
        setLoading(true)

        const res = await updateAncillaryProject(accessToken, uniquePorjectAncillary?.id, data)

        if (res?.statusCode === 200) {
            setMessage(res.message)
            setMessageType('success')
            setLoading(false)
        } else if (res?.statusCode === 404) {
            setLoading(false)
            navigate('/error/404')
        } else {
            setMessage(res.message)
            setMessageType('error')
            setLoading(false)
        }
    }

    // api call to add project ancillary
    const addProjectAncillary = async (data) => {
        setLoading(true)
        itemAddedTrue = "added"

        const res = await addAncillaryProject(accessToken, data)
        if (res?.statusCode === 200) {
            setLoading(false)
            setMessage(res.message)
            setMessageType('success')
            getAllProjectAncillaryServices()

        } else if (res?.statusCode === '404') {
            setLoading(false)
            navigate('/error/404')
            getAllProjectAncillaryServices()
        } else {
            setLoading(false)
            setMessage(res.message)
            setMessageType('error')
            getAllProjectAncillaryServices()
        }
    }

    // api call to get all project ancillaries
    const getAllProjectAncillaryServices = async () => {
        setLoading(true)

        const res = await getProjectAncillaries(accessToken)
        
        if (res?.statusCode === 200) {
            if (res?.entity !== null) {
                if (res?.entity?.length > 0) {
                    setAncillaryList(res?.entity)

                    if (itemAddedTrue == 'added') {
                        const addedItemIndex = res.entity.length - 1

                        if (addedItemIndex) {
                            fetchSelected(addedItemIndex, res.entity[addedItemIndex]?.id)
                            itemAddedTrue = ''
                        }
                    }

                } else {
                    setAncillaryList([])
                    setTimestamp('')

                    fetchSelected(0, '');
                }
            }

            setTimeout(() => {
                setLoading(false)
            }, 1200);

        } else if (res?.statusCode === 404) {
            setLoading(false)
            navigate('/error/404')
        } else {
            setMessage(res.message)
            setMessageType('error')
            setLoading(false)
        }
    }

    // api call to get single ancillary data to show description input prefilled
    const getUniqueProjectAncillaryServices = async (id) => {
        setLoading(true)

        const res = await getUniqueProjectAncillary(accessToken, id)
        if (res?.statusCode === 200) {
            setuniquePorjectAncillary(res?.entity)

            setTimestamp(id)

            setTimeout(() => {
                setLoading(false)
            }, 1200);
        } else if (res?.statusCode === 404) {
            setLoading(false)
            navigate('/error/404')
        } else {
            setMessage(res.message)
            setMessageType('error')
            setLoading(false)
        }
    }

    const handleFormReset = () => {
        if (timestamp) {
            getAllProjectAncillaryServices()
            getUniqueProjectAncillaryServices(timestamp)
        }
    }

    return (
        <div>
            {loading &&
                <div className='loader_main full_loader'>
                    <Oval
                        height={50}
                        width={50}
                        color="#44C8F5"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                        ariaLabel='oval-loading'
                        secondaryColor="#44C8F5"
                        strokeWidth={2}
                        strokeWidthSecondary={2}
                    />
                </div>
            }
            <InternalLayout
                pageTitle="Quotations Ancillaries"
                leftContent={<AncillaryLeft handleFormReset={handleFormReset} uniquePorjectAncillary={uniquePorjectAncillary} updateProjectAncillaryServices={updateProjectAncillaryServices} />}
                rightContent={<AncillaryRight accessToken={accessToken} setLoading={setLoading} addProjectAncillary={addProjectAncillary} loading={loading} ancillaryList={ancillaryList} getAllProjectAncillaryServices={getAllProjectAncillaryServices} uniquePorjectAncillary={uniquePorjectAncillary} />}
            />
        </div>
    )
}

export default AncillaryMain