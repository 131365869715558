import React, { useState } from "react";
import { Oval } from "react-loader-spinner";
import InternalLayout from "../../../../layouts/internalLayout";
import Left from "./left";
import { useMessage } from "../../../../context/messageContext";
import { Button } from "react-bootstrap";
import { saveCuttingListDataService } from "../../../../services/cuttingSheetServices";
import { NavLink } from "reactstrap";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router-dom";

const CuttingList = () => {
  const [loading, setLoading] = useState(false);
  const [cuttingListData, setCuttingListData] = useState([]);
  const access_token = localStorage.getItem("access_token");

  const { setMessage, setMessageType } = useMessage();

  const navigate = useNavigate();

  const saveDetails = async () => {
    setLoading(true);
    const res = await saveCuttingListDataService(
      access_token,
      cuttingListData?.profileDetails
    );
    if (res?.data?.statusCode === 200) {
      setLoading(false);

      setMessageType("success");
      setMessage(res?.data?.message);
    } else if (res?.data?.statusCode !== 200) {
      setMessageType("error");
      setLoading(false);
      setMessage(res?.data?.message);
    }
  };

  const handleBackNav = () => {
    let quoteId = sessionStorage.getItem("quotationId");
    if (quoteId) {
      navigate(`/quotation/${quoteId}`);
    } else {
      navigate("/");
    }
  };

  return (
    <>
      <>
        {loading && (
          <div className="loader_main full_loader">
            <Oval
              height={50}
              width={50}
              color="#44C8F5"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
              ariaLabel="oval-loading"
              secondaryColor="#44C8F5"
              strokeWidth={2}
              strokeWidthSecondary={2}
            />
          </div>
        )}
        <InternalLayout
          pageTitle={
            <div className="d-flex align-items-center gap-2">
              <NavLink
                className="btn btn-primary d-flex align-items-center gap-1 fw-bold back_btn"
                onClick={() => {
                  handleBackNav();
                }}
              >
                <BiArrowBack size={22} />
                Back
              </NavLink>
              <span>Cutting List</span>
            </div>
          }
          layoutType="full"
          headerButton={<Button onClick={saveDetails}>Save</Button>}
          leftContent={
            <Left
              token={access_token}
              setMessage={setMessage}
              setMessageType={setMessageType}
              cuttingListData={cuttingListData}
              setLoading={setLoading}
              setCuttingListData={setCuttingListData}
            />
          }
          rightContent={""}
        />
      </>
    </>
  );
};

export default CuttingList;
