import React, { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap';
import {
    Row,
    Col,
    Button,
    FormGroup,
    Label,
    Input,
} from 'reactstrap';
import NumberInput from '../../../../../../components/NumberInput';
import { useRightContext } from '../../../../../../context/rightMenuContext';
import PricingHeader from '../../../../../../components/Pricing/PricingHeader';


const PromotionsLeft = ({ setLoading, accessToken, selectItemDetails, updateItemDetailsServices, handleFormReset }) => {

    const { timestamp, selected, setSelected, handleEdit, handleDescEdit, setDescUpdate } = useRightContext()

    const [itemId, setItemId] = useState('')

    const [selectedPricing, setSelectedPricing] = useState()
    const [selectedSupplyOnly, setSelectedSupplyOnly] = useState('')

    const [name, setName] = useState('')
    const [description, setDescription] = useState('')
    const [percentage, setPercentage] = useState('0')
    const [defaultTax, setDefaultTax] = useState(false)

    const [nameError, setNameError] = useState(false)
    const [descriptionError, setDescriptionError] = useState(false)

    useEffect(() => {
        setLoading(false)

        setSelected(selectItemDetails?.name || '')
        setName(selectItemDetails?.name || '')
        setItemId(selectItemDetails?.id || '')

        setDescription(selectItemDetails?.description || '')
        setPercentage(selectItemDetails?.percentage || 0)

        setDefaultTax(selectItemDetails?.default || false)

        setNameError(false)
        setDescriptionError(false)
    }, [selectItemDetails])

    const handleNameChange = (e) => {
        const value = e.target.value

        if (e.target.value?.length <= 50) {
            setName(value)
            handleEdit(value)
            setSelected(value)
        }

        if (value.length > 50) {
            setNameError(true)
        } else {
            setNameError(false)
        }
    }

    const handleDescriptionChange = (e) => {
        const value = e.target.value

        if (e.target.value?.length <= 80) {
            setDescription(value)
            handleDescEdit(value)
            setDescUpdate(value)
        }

        if (value.length > 80) {
            setDescriptionError(true)
        } else {
            setDescriptionError(false)
        }
    }

    const handleFormSubmit = () => {

        const formDataValues = {
            'id': selectItemDetails?.id,
            "name": name,
            "percentage": percentage,
            "description": description,
            "default": defaultTax,
        }

        updateItemDetailsServices(formDataValues)
    }

    return (
        <div className='d-flex flex-column h-100'>

            <PricingHeader
                setSelectedSupplyOnly={setSelectedSupplyOnly}
                setSelectedPricing={setSelectedPricing}
                selectedPricing={selectedPricing}
                selectedSupplyOnly={selectedSupplyOnly}
            />

            <div className="d-flex align-items-center mb-3 flex-wrap gap-2 left_header">
                {selected && (
                    <h5 className="mb-0 text-wrap text-break main_title">{selected}</h5>
                )}
            </div>

            <Form className='h-100 d-flex flex-column flex-grow-1 justify-content-between'>
                <div className='inside_scroll has_btns'>
                    <Row>
                        <Col lg="6">
                            <FormGroup className="form-group mb-3">
                                <Label for="name">
                                    Name
                                </Label>
                                <Input className='mb-1' name="name" onChange={(e) => handleNameChange(e)} value={name} />
                                {nameError && timestamp && itemId && (
                                    <div className='mt-1 text-muted text-small'>Max 50 characters are allowed</div>
                                )}

                                {(!name && itemId) && (
                                    <div className='mt-1 text-danger text-small'>Name is required</div>
                                )}
                            </FormGroup>
                        </Col>

                        <Col lg="6">
                            <FormGroup className="form-group mb-3">
                                <Label for="desc">
                                    Description
                                </Label>
                                <Input className='mb-1' name='description' value={description || ''} onChange={(e) => handleDescriptionChange(e)} />

                                {(descriptionError && timestamp && itemId) && (
                                    <div className='mt-1 text-muted text-small'>Max 80 characters are allowed</div>
                                )}
                            </FormGroup>
                        </Col>

                        <Col lg="6">
                            <FormGroup className="form-group">
                                <Label for="percentage">%</Label>

                                <NumberInput
                                    name="percentage"
                                    value={percentage}
                                    setValue={setPercentage}
                                />
                            </FormGroup>
                        </Col>

                        <Col lg="6">
                            <FormGroup className="form-group">
                                <Label for="defaultTax">
                                    Default
                                </Label>

                                <Form.Check // prettier-ignore
                                    type="switch"
                                    id="defaultTax"
                                    className='form-switch-lg'
                                    onChange={() => { setDefaultTax(!defaultTax) }}
                                    label={defaultTax ? 'On' : 'Off'}
                                    disabled={defaultTax}
                                    checked={defaultTax}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </div>

                {/* Start: space for buttons at the bottom */}
                <div className="d-flex gap-2 gap-xl-3 main_buttons">
                    <Button color="primary" size="md" className="px-4"
                        onClick={() => handleFormSubmit()}
                        disabled={!timestamp || !name.trim()}
                    >
                        Save Changes
                    </Button>

                    <Button color="primary" size="md"
                        outline
                        className="px-4"
                        disabled={!timestamp}
                        onClick={() => handleFormReset()} >
                        Reset
                    </Button>
                </div>
                {/* End: space for buttons at the bottom */}
            </Form>
        </div>
    )
}

export default PromotionsLeft