import React, { useEffect, useState } from "react";
import { BiTrashAlt } from "react-icons/bi";
import {
  MdAdd,
  MdExpandMore,
  MdOutlineCreate,
  MdOutlineKeyboardArrowDown,
} from "react-icons/md";
import {
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  UncontrolledDropdown,
} from "reactstrap";
import { formatValue, handleQuantity } from "../utility/helper";
import { MenuItem, OutlinedInput, Select } from "material-ui-core";
import { Col, Form, Row, Table } from "react-bootstrap";
import NumberInputDecimal from "./NumberInputDecimal";
import { MenuProps } from "../utility/data";
import { GoUnlink } from "react-icons/go";

const AncillaryComponent = ({
  accessToken,
  leftPanelOpen,
  handleAncillaryModal,
  handleAncillary,
  addedAncillaries,
  handleAncillaryEdit,
  deleteAncillary,
  ancillaryModal,
  setAncillaryModal,
  modalData,
  setModalData,
  isUnlinked,
  discountSwitch,
  hidePrintSwitch,
  setIsEdit,
  isEdit,
  handlePresetModalValue,
  addNewFrameAncillary,
  setIsUnLinked,
  setHidePrintSwitch,
  setDiscountSwitch,
  ancillaryData,
  getAncillariesData,
  getAncillaryPrice,
  removeDiscountToggle,
  currency
}) => {
  const { description, lumpSum, percentage, preset, quantity } = modalData;

  useEffect(() => {
    if (leftPanelOpen) {
      getAncillariesData();
    }
  }, [leftPanelOpen]);

  return (
    <>
      <h5 className="mt-3">Ancillaries</h5>

      <FormGroup className="d-flex gap-2 my-3">
        <UncontrolledDropdown group className="add_ancillary_btn border br-4">
          <Button
            color="trans"
            onClick={() => {
              handleAncillaryModal()
            }}>
            <MdAdd size={22} className="me-1" />
            Ancillary
          </Button>

          <DropdownToggle color="trans" className="p-2">
            <MdExpandMore className="border-right" size={22} />
          </DropdownToggle>

          <DropdownMenu>
            {ancillaryData &&
              ancillaryData.map((item, index) => (
                <DropdownItem
                  key={index}
                  onClick={() => {
                    handleAncillary(item);
                  }}
                >
                  {item.description}
                </DropdownItem>
              ))}
          </DropdownMenu>
        </UncontrolledDropdown>
      </FormGroup>

      {addedAncillaries && addedAncillaries?.length > 0 ? (
        <>
          <Table className="td_p_2 no_td_bg">
            <tbody>
              {addedAncillaries &&
                addedAncillaries?.map((item, index) => (
                  <tr className="border-bottom" key={index}>
                    <td className="text-wrap w-100">
                      <div className="d-flex align-items-center gap-3">
                        {item.ancillariesName}
                        {item.quantity > 1 &&
                          <div className="d-flex gap-1">
                            <span className="fw-500 text-dark">X</span>
                            <b>{item.quantity}</b>
                          </div>
                        }
                      </div>
                    </td>
                    <td className="text-end">
                      <span
                        className="me-2 cursor-pointer"
                        onClick={() => handleAncillaryEdit(item)}
                      >
                        <MdOutlineCreate className="text-primary" size={20} />
                      </span>
                      <span
                        className="cursor-pointer"
                        onClick={() => {
                          deleteAncillary(item.id, index);
                        }}
                      >
                        <BiTrashAlt className="text-danger" size={20} />
                      </span>
                    </td>
                    <td className="text-wrap text-end">
                      {/* {currency}{formatValue((parseFloat(item?.ancillariesPrice ? item.ancillariesPrice : 0) * (item?.quantity > 0 ? item.quantity : 0)))} */}

                      {currency}{formatValue(getAncillaryPrice(item))}

                      {/* <div className="d-flex gap-1 justify-content-end">
                        {item.quantity > 1 &&
                          <div className="d-flex gap-1">
                            <b>{item.quantity}</b>
                            <span className="fw-500 text-dark">X</span>
                          </div>
                        }

                        {currency}{formatValue(parseFloat(item?.ancillariesPrice))}
                      </div> */}
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </>
      ) : null}

      <Modal
        wrapClassName="second-modal"
        className="ps-5"
        isOpen={ancillaryModal}
        zIndex={9999}
        toggle={() => setAncillaryModal(false)}
      >
        <ModalHeader>
          {!isEdit
            ? //   || !isUnlinked
            "Add ancillary"
            : "Edit ancillary"}
        </ModalHeader>
        <ModalBody>
          <FormGroup className="form-group">
            <Label for="preset">Preset</Label>

            <Select
              labelId="preset"
              id="preset"
              value={preset}
              label="preset"
              onChange={(e) => {
                handlePresetModalValue(e);
              }}
              input={<OutlinedInput className="w-100" name="preset" />}
              MenuProps={MenuProps}
              IconComponent={() => (
                <MdOutlineKeyboardArrowDown
                  size={20}
                  className="me-1 MuiSvgIcon-root MuiSelect-icon"
                />
              )}
            >
              {!isUnlinked && <MenuItem value="-1" seleced>Not linked</MenuItem>}

              {ancillaryData &&
                ancillaryData.map((item, index) => (
                  <MenuItem key={index} value={item.id}>
                    {item.description}
                  </MenuItem>
                ))}
            </Select>
          </FormGroup>
          <>
            {(addedAncillaries && addedAncillaries?.length === 0) ||
              !isUnlinked ? (
              <>
                <FormGroup className="form-group">
                  <Label for="desc">Description</Label>
                  <Input
                    type="text"
                    id="desc"
                    name="desc"
                    value={description}
                    onChange={(e) => {
                      setModalData((prevData) => ({
                        ...prevData,
                        description: e.target.value,
                      }));
                    }}
                  ></Input>
                </FormGroup>
                <Row>
                  <Col xs="6">
                    <FormGroup className="form-group">
                      <Label for="lumpSum">Lump sum</Label>
                      <NumberInputDecimal
                        name="lumpSum"
                        value={lumpSum}
                        setValue={(value) =>
                          setModalData((prevData) => ({
                            ...prevData,
                            lumpSum: value,
                          }))
                        }
                      ></NumberInputDecimal>
                    </FormGroup>
                  </Col>
                  <Col xs="6">
                    <FormGroup className="form-group">
                      <Label for="quantityAncillary">Quantity</Label>

                      <Input
                        type='number'
                        name="quantity"
                        min="1" step="1" max="999"
                        value={quantity}
                        onInput={(e) => handleQuantity(e)}

                        onChange={(e) => {
                          setModalData((prevData) => ({
                            ...prevData,
                            quantity: e.target.value,
                          }))
                        }}
                      />

                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs="6">
                    <FormGroup className="form-group">
                      <Label for="perc">Percentage</Label>
                      <NumberInputDecimal
                        name="perc"
                        value={percentage}
                        setValue={(value) =>
                          setModalData((prevData) => ({
                            ...prevData,
                            percentage: value,
                          }))
                        }
                      ></NumberInputDecimal>
                    </FormGroup>
                  </Col>
                  
                  {!removeDiscountToggle && (
                    <Col xs="6">
                      <FormGroup className="form-group">
                        <Label for="discountSwitch">Discountable</Label>
                        <Form.Check // prettier-ignore
                          type="switch"
                          id="discountSwitch"
                          className="form-switch-lg"
                          onChange={() => {
                            setDiscountSwitch(!discountSwitch);
                          }}
                          checked={discountSwitch}
                          label={discountSwitch ? "On" : "Off"}
                        />
                      </FormGroup>
                    </Col>
                  )}
                </Row>

                <FormGroup className="form-group">
                  <Label for="hidPrintSwitch">
                    Hide wihin frame when printing
                  </Label>
                  <Form.Check // prettier-ignore
                    type="switch"
                    id="hidPrintSwitch"
                    className="form-switch-lg"
                    onChange={() => {
                      setHidePrintSwitch(!hidePrintSwitch);
                    }}
                    checked={hidePrintSwitch}
                    label={hidePrintSwitch ? "On" : "Off"}
                  />
                </FormGroup>
              </>
            ) : (
              <>
                <Button
                  color="light"
                  size="md"
                  className="px-4 w-100 d-flex justify-content-between fw-medium mb-4"
                  onClick={() => { setIsUnLinked(!isUnlinked) }}
                >
                  <GoUnlink size={16} className="me-1" />
                  Unlink
                  <span></span>
                </Button>
              </>
            )}
          </>
        </ModalBody>
        <ModalFooter>
          <Button
            color="outline-primary"
            size="md"
            onClick={() => {
              addNewFrameAncillary(modalData)
            }}
            disabled={(description === "" ? true : false) || !preset}
          >
            {isEdit ? `Update` : `Save`}
          </Button>
          <Button
            onClick={() => {
              //   setIsUnLinked(true);
              setAncillaryModal(false);
              setIsEdit(false);
            }}
            color="secondary"
            outline
            size="md"
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default AncillaryComponent;
