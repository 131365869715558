import React, { useEffect, useState } from 'react'
import {
  Button,
  Label,
  FormGroup,
  Input,
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavLink,
} from 'reactstrap';
import { GoPlus } from "react-icons/go";
import { MdKeyboardArrowDown, MdOutlineCreate, MdOutlineKeyboardArrowDown } from 'react-icons/md';
import { useNavigate, useParams } from 'react-router-dom';
import { MenuProps, designerShape, panelFeatureList } from '../../../../../utility/data';
import { FormControl, FormControlLabel, MenuItem, OutlinedInput, Radio, RadioGroup, Select } from 'material-ui-core';
import PanelDesign2 from '../../../../../components/3DComponents/paneldesign2';
import Hardware from '../../../../../components/Designer2Comp/Hardware';
import { usePanelDesigner } from '../../../../../context/designer2Context';
import { useMessage } from '../../../../../context/messageContext';
import { getPanelModelProperty, setPanelModelProperty } from '../../../../../services/panelModelServices';
import { getModelHardware } from '../../../../../services/3dModelServices';
import { capitalizeFirstLetter, handlePositiveNumber } from '../../../../../utility/helper';
import { getPanelCollectionList, getPanelStyleList } from '../../../../../services/panelStylesService';
import { Image } from 'react-bootstrap';
import { BsTools } from 'react-icons/bs';
import { BiArrowBack } from 'react-icons/bi';

const Left = ({ loading, setLoading, modelVisible, setModelVisible }) => {

  const { panelMasterId, panelCollectionId, panelProductId } = useParams();
  const accessToken = localStorage.getItem('access_token')

  const navigate = useNavigate()

  const [panelCollectionsList, setPanelCollectionsList] = useState([])
  const [panelCollection, setPanelCollection] = useState('')

  const [panelStylesList, setPanelStylesList] = useState([])
  const [panelStyleSelected, setPanelStyleSelected] = useState('')

  const [collectionDropdown, setCollectionDropdown] = useState(false);
  const [stylesDropdown, setStylesDropdown] = useState(false);

  const [sidePanel, setSidePanel] = useState(false)
  const [open, setOpen] = useState('0');
  const [desc, setDesc] = useState('')
  const [featureId, setFeatureId] = useState(1)
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [hardwareTypeName, setHardwareTypeName] = useState("")
  const [hardwareType, setHardwareType] = useState("")
  const [isHardwareSelected, setIsHardwareSelected] = useState(false)
  const [data, setData] = useState();
  const [styleSelect, setStyleSelect] = useState("");
  const [methodSelect, setMethodSelect] = useState("Add");
  const [angleValue, setAngleValue] = useState(0);
  const [angle, setAngle] = useState(0);
  const [repeatValue, setRepeatValue] = useState(200);
  const [repeat, setRepeat] = useState(200);
  const [isSave, setIsSave] = useState(false);
  const [shape, setShape] = useState("")
  const [linesData, setLinesData] = useState()
  const [hardwareOptions, setHardwareOptions] = useState([])

  const { message, setMessage } = useMessage();

  const access_token = localStorage.getItem('access_token')

  useEffect(() => {
    getPanelCollectionListService(panelMasterId)
  }, [panelMasterId])

  useEffect(() => {
    if (panelCollectionsList && panelCollectionsList.length > 0) {
      const panelCollectionSelected = panelCollectionsList?.find((p) => p.id == panelCollectionId)
      setPanelCollection(panelCollectionSelected)
    }

    getModelHardwareOptions(panelCollectionId)
  }, [panelCollectionsList, panelCollectionId])

  useEffect(() => {
    if (panelCollection.id) {
      getPanelStylesListService(panelCollection?.id)
    }
  }, [panelCollection?.id])

  useEffect(() => {
    if (panelStylesList && panelStylesList.length > 0) {
      const panelStyleItem = panelStylesList?.find((p) => p.id == panelProductId)
      setPanelStyleSelected(panelStyleItem)
    }
  }, [panelStylesList, panelProductId])

  useEffect(() => {
    if (panelFeatureList) {
      setFeatureId(panelFeatureList[0]?.id)
      setDesc(panelFeatureList[0]?.name)
    }
  }, [panelFeatureList])

  useEffect(() => {
    setIsSave(false)
  }, [angle, repeat, styleSelect, shape]);

  const {
    handleHorizontalPos,
    handleVerticalPos,
    handleVerticalAlignment,
    handleHorizontalAlignment,
    handleHorizontalOrigin,
    handleVerticalOrigin,
    modelData,
    setModelData,
    setGotData,
    gotData,
  } = usePanelDesigner();

  useEffect(() => {
    if (panelStyleSelected?.id && panelCollection?.id) {
      setSidePanel(false)
      setOpen(0)
      setHardwareType('')
      setIsHardwareSelected(false)

      setModelVisible(false)
      getPanelModelDataService()
    }
  }, [panelStyleSelected?.id])

  const handlePanelCollectionSelect = (value) => {
    setPanelCollection(value)
  }

  const handlePanelStyleSelect = (value) => {
    // setPanelStyleSelected(value)

    navigate(`/product/panels/designer/${panelMasterId}/${panelCollection?.id}/${value.id}`)
  }

  const toggleDropDown = (e) => {
    setDropdownOpen(!dropdownOpen)
    e.stopPropagation()
  }

  const toggleTabDropdown = () => {
    setCollectionDropdown((prevState) => !prevState);
  }
  const toggleTabDropdownStyles = () => {
    setStylesDropdown((prevState) => !prevState);
  }

  const toggleSidePanel = (e) => {
    e.stopPropagation()
    setSidePanel(!sidePanel)
  }

  const toggleAccordion = (id) => {
    if (open === id) {
      setOpen("0");
    } else {
      setOpen(id);
    }
  };

  const handleFeature = (e) => {
    e.stopPropagation()
    const value = e.target.value;
    setFeatureId(typeof value === 'string' ? value.split(',') : value)
  }

  const handleHardwareType = (element) => {
    setHardwareType(element);
    setIsHardwareSelected(true);
    handleHorizontalPos(0);
    handleVerticalPos(0);
    handleVerticalAlignment("");
    handleHorizontalAlignment("");
    handleHorizontalOrigin("");
    handleVerticalOrigin("");
  };

  useEffect(() => {
    console.log("hardwareType", hardwareType, isHardwareSelected)
  }, [hardwareType, isHardwareSelected])

  const getPanelModelDataService = async () => {
    setLoading(true)
    const res = await (getPanelModelProperty(access_token, panelCollection?.id, panelStyleSelected?.id))
    if (res?.status === 200) {
      if (res?.data?.statusCode === 200) {
        console.log(res?.data?.entity, "res?.data?.entity?.jsonBlob")
        if (res?.data?.entity?.jsonBlob !== null && res?.data?.entity?.jsonBlob !== undefined) {
          setModelData(JSON.parse(res?.data?.entity?.jsonBlob));
          setGotData(true);
        } else {
          setModelData('')
          setGotData(true);
        }
      }

      setTimeout(() => {
        setModelVisible(true)
        setLoading(false)
      }, 1000);
    } else {
      setTimeout(() => {
        setModelVisible(true)
        setLoading(false)
      }, 1000);
    }
  }

  useEffect(() => {
    if (gotData) {
      if (modelData && modelData?.features) {
        setStyleSelect(modelData?.features?.type)
        setMethodSelect(modelData?.features?.method)
        setAngle(modelData?.features?.angle)
        setAngleValue(modelData?.features?.angle)
        setShape(modelData?.features?.shape)
        setRepeat(modelData?.features?.repeat)
        setRepeatValue(modelData?.features?.repeat)
      } else {
        setStyleSelect("")
        setMethodSelect('Add')
        setAngle(0)
        setAngleValue(0)
        setShape('4')
        setRepeat(200)
        setRepeatValue(200)
      }
    }
  }, [modelData, gotData])

  const getModelHardwareOptions = async (modelId) => {
    const res = await getModelHardware(access_token, modelId, 2);
    if (res?.data?.statusCode === 200) {
      setHardwareOptions(res?.data?.entity);
    } else {
      setHardwareOptions([]);
    }
  };

  const saveClick = () => {
    setModelData((prevData) => ({
      ...prevData,
      "features": {
        type: styleSelect,
        method: methodSelect,
        angle: angle,
        shape: shape,
        repeat: repeat,
      },
    }));
    setIsSave(true)
  }

  useEffect(() => {
    if (isSave) {
      savePanelDataService(modelData)
    }
  }, [isSave])

  const getPanelCollectionListService = async (fetchId) => {
    const res = await getPanelCollectionList(accessToken, fetchId);
    if (res && res && res.status === 200) {
      if (res?.data?.statusCode === 200) {
        if (res?.data?.entity?.length > 0) {
          setPanelCollectionsList(res.data.entity)
        } else {
          setPanelCollectionsList([])
        }
      }
    }
  }

  const getPanelStylesListService = async (fetchId) => {
    const res = await getPanelStyleList(accessToken, fetchId);
    if (res && res && res.status === 200) {
      if (res?.data?.statusCode === 200) {
        if (res?.data?.entity?.length > 0) {
          setPanelStylesList(res.data.entity)

          // setPanelStyleSelected(res?.data?.entity[0])

          if (panelStyleSelected?.id) {
            setTimeout(() => {
              navigate(`/product/panels/designer/${panelMasterId}/${panelCollection?.id}/${res?.data?.entity[0].id}`)
            }, 500);
          }
        } else {
          setPanelStylesList([])
        }
      }
    }
  }

  const savePanelDataService = async (jsonData) => {
    setLoading(true)
    const res = await (setPanelModelProperty(access_token, jsonData, panelCollection?.id, panelStyleSelected?.id))
    if (res?.status === 200) {
      if (res?.data?.statusCode === 200) {
        setLoading(false)
        setMessage(res?.data?.message);
      }
    }
    else {
      setLoading(false)
    }
  }

  const handleCancel = () => {
    setGotData(false)
    setIsHardwareSelected(false);

    if (open == 1) {
      setOpen("0");
    }

    if (panelStyleSelected?.id && panelCollection?.id) {
      // setModelVisible(false)
      getPanelModelDataService()
    }
  };

  const handleBackNav = () => {
    navigate(
      `/product/panels/panel-products/${panelMasterId}/collection/${panelCollectionId}`,
      { state: { styleId: panelProductId } }
    );
  }

  return (
    <>
      <div className='designer_wrap has_header new_design'>

        <div className='d-flex justify-content-between border-botto designer_header'>
          <div className='d-flex align-items-center gap-3'>

            <NavLink className='btn btn-primary d-flex align-items-center gap-1 fw-bold back_btn' onClick={(e) => handleBackNav(e)}>
              <BiArrowBack size={22} />
              Back
            </NavLink>

            <Dropdown isOpen={collectionDropdown} toggle={toggleTabDropdown}>
              <DropdownToggle color="none" className="border-0 p-0">
                <div className="d-flex align-items-center">
                  <span className="fw-semibold fs-5">
                    {panelCollection && panelCollection?.name}
                    <MdKeyboardArrowDown className="ms-1" size={20} />
                  </span>
                </div>
              </DropdownToggle>

              <DropdownMenu className='full_height'>
                {panelCollectionsList?.map((item, index) => {
                  return (
                    <DropdownItem
                      onClick={() => handlePanelCollectionSelect(item)}
                      key={index}
                      disabled={item?.id == panelCollection?.id}
                      className={`${item?.id == panelCollection?.id ? 'text-primary fw-medium' : ''}`}
                    >
                      {item?.name}
                    </DropdownItem>
                  );
                })}
              </DropdownMenu>
            </Dropdown>

            <Dropdown className='' isOpen={stylesDropdown} toggle={toggleTabDropdownStyles}>
              <DropdownToggle color="none" className="border-0 p-0">
                <div className="d-flex align-items-center">
                  <span className="fw-semibold fs-5">
                    {panelStyleSelected && panelStyleSelected?.name}
                    <MdKeyboardArrowDown className="ms-1" size={20} />
                  </span>
                </div>
              </DropdownToggle>

              <DropdownMenu className='full_height'>
                {panelStylesList?.map((item, index) => {
                  return (
                    <DropdownItem
                      onClick={() => handlePanelStyleSelect(item)}
                      key={index}
                      disabled={item?.id == panelStyleSelected?.id}
                      className={`${item?.id == panelStyleSelected?.id ? 'text-primary fw-medium' : ''}`}
                    >
                      {item?.name}
                    </DropdownItem>
                  );
                })}
              </DropdownMenu>
            </Dropdown>
          </div>
        </div>

        <Button color="primary" size="lg" className='br-4 lh-1 py-2 px-3 px-lg-4 h-auto designer_btn' onClick={(e) => toggleSidePanel(e)}>
          {sidePanel ? (
            "Close"
          ) : (
            <>
              <MdOutlineCreate className='me-1' size={18} />
              Design
            </>
          )}
        </Button>

        <div className='position-relative design_modal'>
          <div className={`canvas_wrap ${sidePanel ? "side_panel_active" : ""} ${modelVisible ? "model_visible" : 'model_hidden'}`}>
            {modelVisible && (
              <PanelDesign2 isSave={isSave} shape={shape} styleSelect={styleSelect} methodSelect={methodSelect} angle={angle} repeat={repeat} hardwareType={hardwareType} setHardwareType={setHardwareType} />
            )}
          </div>

          <div className={`designer-right-menu panel_design ${sidePanel ? "open_menu" : ""}`}>

            {isHardwareSelected ? (
              <Hardware hardwareTypeName={hardwareTypeName} setHardwareType={setHardwareType} hardwareType={hardwareType} access_token={access_token} setIsHardwareSelected={setIsHardwareSelected} setOpen={setOpen} setMessage={setMessage} loading={loading} setLoading={setLoading} panelProductId={panelStyleSelected?.id} panelCollectionId={panelCollection?.id} handleCancel={handleCancel} />
            ) : (
              <Accordion open={open} toggle={toggleAccordion} onClick={(e) => e.stopPropagation()} >
                <AccordionItem>
                  <AccordionHeader targetId="1">
                    Features
                    <div className='ms-auto'></div>
                  </AccordionHeader>
                  <AccordionBody accordionId="1" className='p-0'>
                    <div className='inside_scroll'>
                      <FormGroup className="form-group mb-2">
                        <Label for="feature" className='text-dark'>
                          Feature
                        </Label>
                        <Select
                          labelId="printdoc-label"
                          id="feature"
                          value={featureId}
                          label="feature"
                          onChange={handleFeature}
                          onMouseDown={(e) => e.stopPropagation()}
                          input={<OutlinedInput className='w-100' name='colours' />}
                          MenuProps={MenuProps}
                          IconComponent={() => (
                            <MdOutlineKeyboardArrowDown size={20} className='me-1 MuiSvgIcon-root MuiSelect-icon' />
                          )}
                        >
                          {panelFeatureList.map((item, index) => {
                            return (
                              <MenuItem value={item?.id} key={index} >{item.name}</MenuItem>
                            )
                          })}
                        </Select>
                      </FormGroup>

                      <FormGroup className="form-group mb-2">
                        <Label for="name" className='text-dark'>
                          Description
                        </Label>
                        <Input className='mb-1' name='desc' onChange={(e) => setDesc(e.target.value)} value={desc} />
                      </FormGroup>
                      <div>
                        <Label className='pb-1 text-dark' >
                          Style
                        </Label>
                        <FormControl className="mb-2 w-100">
                          <RadioGroup
                            aria-labelledby="dimensions-label"
                            name="dimensions"
                            className='flex-sm-row gap-2 gap-md-5 text-dark'
                            value={styleSelect}
                            onChange={(e) => { setStyleSelect(e.target.value) }}
                          >
                            <FormControlLabel value="Lines" control={<Radio name='Lines' />} label="Lines" />
                            <FormControlLabel value="Path" control={<Radio name='Path' />} label="Path" />
                          </RadioGroup>
                        </FormControl>
                      </div>
                      <div>
                        <Label className='pb-1 text-dark' >
                          Method
                        </Label>

                        <FormControl className="mb-2 w-100">
                          <RadioGroup
                            aria-labelledby="dimensions-label"
                            name="method"
                            className='flex-sm-row gap-2 gap-md-5 text-dark'
                            value={methodSelect}
                            onChange={(e) => setMethodSelect(e.target.value)}
                          >
                            <FormControlLabel value="Add" control={<Radio name='Add' />} label="Add" />
                            <FormControlLabel value="Subtract" control={<Radio name='Subtract' />} label="Subtract" />
                          </RadioGroup>
                        </FormControl>
                      </div>
                      <FormGroup className="form-group mb-2">
                        <Label for="shape" className='text-dark'>
                          Shape
                        </Label>
                        <Select
                          labelId="printdoc-label"
                          id="shape-id"
                          label="shape"
                          value={shape}
                          input={<OutlinedInput className='w-100' name='shape' />}
                          MenuProps={MenuProps}
                          onChange={(e) => setShape(e.target.value)}
                          IconComponent={() => (
                            <MdOutlineKeyboardArrowDown size={20} className='me-1 MuiSvgIcon-root MuiSelect-icon' />
                          )}
                        >
                          {designerShape?.map((item, index) => {
                            return (
                              <MenuItem value={item?.id} key={index} >{item?.name}</MenuItem>
                            )
                          })}
                        </Select>
                      </FormGroup>
                      {styleSelect === "Path" ? <FormGroup className="form-group mb-2">
                        <Label for="path" className='text-dark'>
                          Path
                        </Label>
                        <Input
                          type="textarea"
                          className='mb-1'
                          rows="5"
                          name="path"
                        />
                      </FormGroup> : <>
                        <FormGroup className="form-group mb-2">
                          <Label for="Angle" className='text-dark'>
                            Angle
                          </Label>
                          {/* <NumberInput
                          name="Angle"
                          value={angle}
                          setValue={setAngle}
                        /> */}
                          <Input type="number" className='mb-1' name="Repeat" value={angleValue} onChange={(e) => setAngleValue(e.target.value)} onBlur={(e) => setAngle(e.target.value)} onInput={(e) => handlePositiveNumber(e)} />
                        </FormGroup>
                        <FormGroup className="form-group mb-2">
                          <Label for="Repeat" className='text-dark'>
                            Repeat
                          </Label>

                          <Input type="number" className='mb-1' name="Repeat" value={repeatValue} onChange={(e) => setRepeatValue(e.target.value)} onBlur={(e) => setRepeat(e.target.value)} onInput={(e) => handlePositiveNumber(e)} />
                        </FormGroup>
                      </>}
                    </div>

                    <div className="d-flex gap-2 w-100 pb-3">
                      <Button
                        className="w-50"
                        color="outline-primary"
                        size="md"
                        onClick={() => saveClick()}
                        disabled={false}
                      >
                        Save
                      </Button>
                      <Button
                        className="w-50"
                        color="primary"
                        outline
                        size="md"
                        onClick={handleCancel}
                      >
                        Cancel
                      </Button>
                    </div>
                  </AccordionBody>
                </AccordionItem>

                <AccordionItem className='hardware_acc'>
                  <AccordionHeader targetId="2">
                    Hardware

                    <div className='ms-auto'>
                      {hardwareOptions && hardwareOptions?.length > 0 && (
                        <Dropdown
                          isOpen={dropdownOpen}
                          toggle={toggleDropDown}
                          className='ms-auto'
                        >

                          <DropdownToggle
                            color='none'
                            className="border-0 p-0 py-1 d-block text-muted"
                            tag="span"
                          >
                            {hardwareOptions && hardwareOptions.length > 0 ? <GoPlus size={20} /> : null}
                          </DropdownToggle>

                          <DropdownMenu className={`${window?.innerHeight < 900 ? 'has_scroll' : ''} body_dropdown panel_hardware_list`} container="body">
                            {hardwareOptions && hardwareOptions.map((item, index) => {
                              return (
                                <DropdownItem key={index} onClick={() => { setHardwareTypeName(item?.name); handleHardwareType(item?.hardwareStyles[0]) }} tag="div">
                                  {capitalizeFirstLetter(item?.name)}
                                </DropdownItem>
                              )
                            })}
                          </DropdownMenu>
                        </Dropdown>
                      )}
                    </div>
                  </AccordionHeader>

                  <AccordionBody accordionId="2">
                    {!isHardwareSelected && <div className="panel_object_list inside_scroll">
                      {modelData && Object.values(modelData)?.map((obj, index) => <div
                        key={index}
                        className={`position-relative align-items-center cursor-pointer ${obj.type !== "hardware" ? 'd-none' : 'd-flex'}`}
                        onClick={() => handleHardwareType(obj)}
                      >
                        <div
                          className={`right_item w-100 cursor-pointer`}
                        >
                          <span className="me-2">
                            {obj.imagePath ? (
                              <Image src={obj.imagePath} width={50} height={50} />
                            ) : (
                              <BsTools color='#989898' size={20} />
                            )}
                          </span>
                          <div className="right_title">
                            <span className='text-dark text-truncate'>
                              {obj?.name}
                            </span>
                          </div>
                        </div>
                      </div>)}
                    </div>}
                  </AccordionBody>
                </AccordionItem>
              </Accordion>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default Left