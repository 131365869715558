import React, { useState } from 'react'
import {
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
} from 'reactstrap';
import SystemGeneral from '../../../../../../components/SystemGeneral';
import { MdMoreHoriz, MdOutlineSell } from 'react-icons/md';
import SystemGlazing from '../../../../../../components/SystemGlazing';
import SystemProfile from '../../../../../../components/SystemProfile';
import SystemSubProducts from '../../../../../../components/SystemSubProducts';
import SystemHardware from '../../../../../../components/SystemHardware';
import SystemAccessories from '../../../../../../components/SystemAccessories';

import { useRightContext } from '../../../../../../context/rightMenuContext';
import { Link, useParams } from 'react-router-dom';
import Editprices from "../../../../../../components/Editprices";
import SizeTagsComp from '../../../../../../components/SizeTagsComp';
const FrameProductRight = ({ allFrameAccessories, getAllFrameAccessoriesServices, collectionId, navigate, setLoading, accessToken, activeTab, setActiveTab, selectItemDetails, updateItemDetailsServices, handleFormReset }) => {
    // let { collectionId } = useParams();
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggleTabDropdown = () => setDropdownOpen((prevState) => !prevState);

    const handleActiveTab = (tab) => {
        setActiveTab(tab)
    }

    const { selectedRightItem, selected } = useRightContext()

    // const editNavData = { url: `/pricing/systems/list/${collectionId}`}
    const editNavData = { url: `/pricing/systems/list`, stateValue: collectionId }


    return (
        <div className='d-flex flex-column h-100'>

            <div className='d-flex align-items-center mb-3 flex-wrap gap-2 left_header'>
                {selected && (
                    <h5 className="mb-0 text-wrap text-break main_title me-4">{selected}</h5>
                )}

                <Editprices data={editNavData} hasState={true} />
            </div>

            <Nav tabs className="details_header border-0 nav-tabs-custom">
                <NavItem>
                    <NavLink
                        className={`${activeTab === "General" && 'active'}`}
                        onClick={() => { handleActiveTab("General") }}
                    >
                        General
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        className={`${activeTab === "Glazing" && 'active'}`}
                        onClick={() => { handleActiveTab("Glazing") }}
                    >
                        Glazing
                    </NavLink>
                </NavItem>

                <NavItem>
                    <NavLink
                        className={`${activeTab === "Profiles" && 'active'}`}
                        onClick={() => { handleActiveTab("Profiles") }}
                    >
                        Profiles
                    </NavLink>
                </NavItem>

                <NavItem className='d-none d-sm-block d-lg-block'>
                    <NavLink
                        className={`${activeTab === "Sub products" && 'active'}`}
                        onClick={() => handleActiveTab("Sub products")}
                    >
                        Sub products
                    </NavLink>
                </NavItem>

                <NavItem className='d-none d-sm-block d-md-none d-lg-block'>
                    <NavLink
                        className={`${activeTab === "Hardware" && 'active'}`}
                        onClick={() => { handleActiveTab("Hardware") }}
                    >
                        Hardware
                    </NavLink>
                </NavItem>

                <NavItem className='d-none d-sm-block d-md-none d-lg-block'>
                    <NavLink
                        className={`${activeTab === "sizeTags" && 'active'}`}
                        onClick={() => { handleActiveTab("sizeTags") }}
                    >
                        Size tags
                    </NavLink>
                </NavItem>

                <Dropdown className='d-lg-none' isOpen={dropdownOpen} toggle={toggleTabDropdown}>
                    <DropdownToggle color='none' className='border-0 p-0'>
                        <MdMoreHoriz size={20} />
                    </DropdownToggle>
                    <DropdownMenu>
                        <DropdownItem className='d-sm-none' href="#Profiles" onClick={() => handleActiveTab("Sub products")}>
                            Sub products
                        </DropdownItem>
                        <DropdownItem className='d-sm-none d-md-block d-lg-none' href="#Hardware" onClick={() => handleActiveTab("Hardware")}>
                            Hardware
                        </DropdownItem>
                        <DropdownItem className='d-sm-none d-md-block d-lg-none' href="#sizeTags" onClick={() => handleActiveTab("sizeTags")}>
                            Size tags
                        </DropdownItem>
                    </DropdownMenu>
                </Dropdown>
            </Nav>

            <TabContent activeTab={activeTab} className={`flex-grow-1 d-flex`}>
                <TabPane tabId="General" className={`flex-grow-1 w-100 ${selectedRightItem?.isSelf ? "" : "not_allowed"}`}>
                    {activeTab === "General" && (
                        <SystemGeneral isEditable={selectedRightItem?.isSelf} activeTab={activeTab} handleFormReset={handleFormReset} accessToken={accessToken} selectItemDetails={selectItemDetails} updateItemDetailsServices={updateItemDetailsServices} />
                    )}
                </TabPane>
                <TabPane tabId="Glazing" className={`w-100 ${selectedRightItem?.isSelf ? "" : "not_allowed"}`}>
                    {activeTab === "Glazing" && (
                        <SystemGlazing isEditable={selectedRightItem?.isSelf} activeTab={activeTab} handleFormReset={handleFormReset} accessToken={accessToken} selectItemDetails={selectItemDetails} updateItemDetailsServices={updateItemDetailsServices} />
                    )}
                </TabPane>
                <TabPane tabId="Profiles" className='w-100'>
                    {activeTab === "Profiles" && (
                        <SystemProfile isEditable={selectedRightItem?.isSelf} activeTab={activeTab} handleFormReset={handleFormReset} accessToken={accessToken} selectItemDetails={selectItemDetails} updateItemDetailsServices={updateItemDetailsServices} navigate={navigate} setLoading={setLoading} />
                    )}
                </TabPane>
                <TabPane tabId="Sub products" className='w-100'>
                    {activeTab === "Sub products" && (
                        <SystemSubProducts isEditable={selectedRightItem?.isSelf} activeTab={activeTab} handleFormReset={handleFormReset} accessToken={accessToken} selectItemDetails={selectItemDetails} updateItemDetailsServices={updateItemDetailsServices} navigate={navigate} setLoading={setLoading} />
                    )}
                </TabPane>
                <TabPane tabId="Hardware" className={`w-100 ${selectedRightItem?.isSelf ? "" : "not_allowed"}`}>
                    {activeTab === "Hardware" && (
                        <SystemHardware isEditable={selectedRightItem?.isSelf} activeTab={activeTab} handleFormReset={handleFormReset} accessToken={accessToken} selectItemDetails={selectItemDetails} updateItemDetailsServices={updateItemDetailsServices} />
                    )}
                </TabPane>
                {/* <TabPane tabId="Accessories" className={`w-100 ${selectedRightItem?.isSelf ? "" : "not_allowed"}`}>
                    {activeTab === "Accessories" && (
                        <SystemAccessories isEditable={selectedRightItem?.isSelf} activeTab={activeTab} handleFormReset={handleFormReset} accessToken={accessToken} selectItemDetails={selectItemDetails} updateItemDetailsServices={updateItemDetailsServices} setLoading={setLoading} collectionId={collectionId} allFrameAccessories={allFrameAccessories} getAllFrameAccessoriesServices={getAllFrameAccessoriesServices} />
                    )}
                </TabPane> */}

                <TabPane tabId="sizeTags" className="flex-grow-1 w-100">
                    <SizeTagsComp
                        setLoading={setLoading}
                        frameProductId={selectItemDetails?.id}
                        activeTab={activeTab}
                        selectedRightItem={selectedRightItem}
                        accessToken={accessToken}
                    >
                    </SizeTagsComp>
                </TabPane>
            </TabContent>
        </div>
    )
}

export default FrameProductRight