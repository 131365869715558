import { useState } from "react";
import { useContext } from "react";
import { createContext } from "react";

// this ancillaryContext is common for ancillary and acocunt pages only
const ancillaryContext = createContext();

const useAncillary = () => {
    const value = useContext(ancillaryContext);
    return value;
}

const CustomContextAncillary = ({ children }) => {

    const [newAncillary, setNewAncillary] = useState([]);
    const [selected, setSelected] = useState();
    const [timestamp, setTimestamp] = useState('');
    const [activeIndex, setActiveIndex] = useState(0);
    const [fetchFlag, setFetchFlag] = useState(true);

    // Compute the last index of the ancillary data
    const lastIndex = newAncillary?.length - 1;

    // Function to add a new ancillary item
    const addNewAncillary = (currentData) => {
        setNewAncillary((prevAncillary) => [...prevAncillary, currentData]);
    };

    // Function to fetch and select a specific item
    const fetchSelected = (index, uniqueId) => {
        const data = newAncillary?.find((item) => item.id === uniqueId);
        setSelected(data?.name);
        setTimestamp(uniqueId);
        setFetchFlag(true);
        setActiveIndex(index);
    };

    // Function to edit and update an ancillary item
    const handleEdit = (value) => {
        const updateIndex = newAncillary?.findIndex((item) => item.id === timestamp);

        if (updateIndex !== -1) {
            let updatedAncillary = [...newAncillary];
            updatedAncillary[updateIndex] = {
                ...updatedAncillary[updateIndex],
                name: value,
            };
            setNewAncillary(updatedAncillary);
        }
    };

    // Function to handle navigating up the active index
    const handleActiveIndexUp = () => {
        if (activeIndex === 0) {
            setActiveIndex(lastIndex);
        } else {
            setActiveIndex(activeIndex - 1);
        }
    };

    // Function to handle navigating down the active index
    const handleActiveIndexDown = () => {
        if (activeIndex === lastIndex) {
            setActiveIndex(0);
        } else {
            setActiveIndex(activeIndex + 1);
        }
    };

    return (
        <ancillaryContext.Provider value={{
            newAncillary,
            selected,
            timestamp,
            activeIndex,
            fetchFlag,
            addNewAncillary,
            fetchSelected,
            handleEdit,
            handleActiveIndexUp,
            handleActiveIndexDown,
            setSelected,
            setFetchFlag,
            setActiveIndex,
            setNewAncillary,
            setTimestamp,
            // handeListClick,
            // previouseSelected,
            // currentSelected,
            // arrayOfObjects
        }}>
            {children}
        </ancillaryContext.Provider>
    )
}

export { useAncillary }
export default CustomContextAncillary;

