import React from 'react'
import InnerLayout from "../../../../../../layouts/internalLayout"
import { useState, useEffect } from 'react'
import { useProductPaletteContext } from '../../../../../../context/productPaletteContext'
import { useMessage } from '../../../../../../context/messageContext'
import { allGlazingCollection, allGlazingPalette } from '../../../../../../services/glazingServices'
import { Oval } from 'react-loader-spinner'
import DesignLeft from './design-left'
import DesignsRight from './design-right'
import NoDataCard from '../../../../../../components/NoDataCard'

export const Designs = () => {

    const [tabType, setTabType] = useState('palette')
    const [allPalette, setAllPalette] = useState([])
    const [allCollection, setAllCollection] = useState([])
    const [loading, setLoading] = useState(false)
    const [commonHeading, setCommonHeading] = useState('')

    const { setNewItemList, setTimestamp, setActiveIndex } = useProductPaletteContext();
    const { setMessage, setMessageType } = useMessage();
    const accessToken = localStorage.getItem('access_token')

    useEffect(() => {
        if (tabType === 'palette') {
            setTimestamp('')
            getAllPaletteServices()
        }

        if (tabType === 'collections') {
            setTimestamp('')
            getAllCollectionServices()
        }
    }, [tabType])

    const getAllPaletteServices = async (type) => {
        setLoading(true)

        const res = await allGlazingPalette(4, accessToken)
        if (res?.data?.statusCode === 200) {
            let data = res?.data?.entity;
            setNewItemList(res?.data.entity)
            setAllPalette(res?.data.entity)

            if (type === 'added') {
                setTimestamp(data[data.length - 1]?.id)
                setActiveIndex(data.length - 1)
            }

            if (res?.data?.entity < 1) {
                setLoading(false)
            }

            setMessageType('success')
            setMessage(res.message)
        } else {
            setLoading(false)
            setMessageType('error')
            setMessage(res.message)
        }
    }

    const getAllCollectionServices = async (type) => {
        setLoading(true)

        const res = await allGlazingCollection(4, accessToken)
        if (res?.data?.statusCode === 200) {
            let data = res?.data?.entity;
            setNewItemList(res?.data.entity)
            setAllCollection(res?.data.entity)
            if (type === 'added') {
                setTimestamp(data[data.length - 1]?.id)
                setActiveIndex(data.length - 1)
            }

            if (res?.data?.entity < 1) {
                setLoading(false)
            }
            setMessageType('success')
            setMessage(res.message)
        } else {
            setLoading(false)
            setMessageType('error')
            setMessage(res.message)
        }
    }

    return (
        <div>
            {loading && (
                <div className='loader_main full_loader'>
                    <Oval
                        height={50}
                        width={50}
                        color="#44C8F5"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                        ariaLabel='oval-loading'
                        secondaryColor="#44C8F5"
                        strokeWidth={2}
                        strokeWidthSecondary={2}
                    />
                </div>
            )}
            <InnerLayout
                pageTitle="Designs"
                layoutType=""
                leftContent={
                    <>
                        {allPalette && allPalette.length > 0 ? (
                            <DesignLeft setTabType={setTabType} setLoading={setLoading} commonHeading={commonHeading} setCommonHeading={setCommonHeading} getAllCollectionServices={getAllCollectionServices} allCollection={allCollection} allPalette={allPalette} tabType={tabType} getAllPaletteServices={getAllPaletteServices} />
                        ) : (
                            <div className='mt-5'>
                                <NoDataCard text="items" loading={loading} isText={true} />
                            </div>
                        )}
                    </>
                }
                rightContent={<DesignsRight getAllCollectionServices={getAllCollectionServices} getAllPaletteServices={getAllPaletteServices} tabType={tabType} setLoading={setLoading} allCollection={allCollection} allPalette={allPalette} />} />
        </div>
    )
}
