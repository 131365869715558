import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import {
	Row,
	Col,
	Button,
	FormGroup,
	Label,
	Input,
} from "reactstrap";
import NumberInputDecimal from "../../../../../../components/NumberInputDecimal";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { MenuItem, OutlinedInput, Select } from "material-ui-core";
import { MenuProps } from "../../../../../../utility/data";
import { useRightContext } from "../../../../../../context/rightMenuContext";
import NumberInputDecimalNegative from "../../../../../../components/NumberInputDecimalNegative";

const ProfileJointsLeft = ({ setLoading, pageType, selectItemDetails, updateItemDetailsServices, handleFormReset, frameMasterOptions, beadMasterOptions, transomMasterOptions }) => {

	const { timestamp, selectedRightItem, selected, setSelected, handleEdit } = useRightContext()

	const [name, setName] = useState('')
	const [itemId, setItemId] = useState('')

	const [frame, setFrame] = useState("")
	const [bead, setBead] = useState("")
	const [transomSelect, setTransomSelect] = useState('')

	const [frameValue1, setFrameValue1] = useState("0")
	const [frameValue2, setFrameValue2] = useState("0")
	const [frameValue3, setFrameValue3] = useState("0")
	const [beadValue, setBeadValue] = useState("0")

	const [transomValue1, setTransomValue1] = useState("0")
	const [transomValue2, setTransomValue2] = useState("0")
	const [transomValue3, setTransomValue3] = useState("0")

	const [allowCruciformSwitch, setAllowCruciformSwitch] = useState(false)
	const [cruficormValue, setCruficormValue] = useState("0")
	const [nameError, setNameError] = useState(false)

	useEffect(() => {

		setFrame(selectItemDetails?.frame || frameMasterOptions[0]?.id)
		setBead(selectItemDetails?.bead || beadMasterOptions[0]?.id)
		setTransomSelect(selectItemDetails?.transom || transomMasterOptions[0]?.id)

	}, [selectItemDetails, frameMasterOptions, beadMasterOptions, transomMasterOptions])

	useEffect(() => {
		setLoading(false)

		setSelected(selectItemDetails?.name || '')
		setName(selectItemDetails?.name || '')
		setItemId(selectItemDetails?.id || '')

		setBeadValue(selectItemDetails?.beadUnit || 0)

		setFrameValue1(selectItemDetails?.frameFirstUnit || 0)
		setFrameValue2(selectItemDetails?.frameFirstUnit || 0)
		setFrameValue3(selectItemDetails?.frameFirstUnit || 0)

		setTransomValue1(selectItemDetails?.transomFirstUnit || 0)
		setTransomValue2(selectItemDetails?.transomSecondUnit || 0)
		setTransomValue3(selectItemDetails?.transomThirdUnit || 0)

		setAllowCruciformSwitch(selectItemDetails?.allowCruciformWelds || false)
		setCruficormValue(selectItemDetails?.cruciformUnit || 0)

		setNameError(false)
	}, [selectItemDetails])


	const handleNameChange = (e) => {
		const value = e.target.value

		if (e.target.value?.length <= 50) {
			setName(value)
			handleEdit(value)
            setSelected(value)
		}

		if(value.length > 50) {
			setNameError(true)
		} else {
			setNameError(false)
		}
	}

	const handleCollectionChange = (e) => {
		const value = e.target.value
		setTransomSelect(value)
	}

	const handleFrameChange = (e) => {
		const value = e.target.value
		setFrame(value)
	}

	const handleBeadChange = (e) => {
		const value = e.target.value
		setBead(value)
	}

	const handleDefaultChange = (e) => {
		const value = e.target.checked

		setAllowCruciformSwitch(value)

		// if(!value) {
		// 	setCruficormValue(0)
		// } else {
		// 	setCruficormValue(selectItemDetails?.cruciformUnit || 0)
		// }
	}

	const handleFormSubmit = () => {

		const formDataValues = {
			'id': selectItemDetails?.id,
			"type": pageType,

			"name": name,
			'frame': frame,
			'bead': bead,
			"transom": transomSelect,

			"frameValue1": frameValue1,
			"frameValue2": frameValue2,
			"frameValue3": frameValue3,

			"beadValue": beadValue,

			'transomValue1': transomValue1,
			'transomValue2': transomValue2,
			'transomValue3': transomValue3,

			"allowCruciformWelds": allowCruciformSwitch,
			"cruciformUnit": cruficormValue,
		}

		updateItemDetailsServices(formDataValues)
	}

	return (
		<div className="d-flex flex-column h-100">
			<div className="d-flex align-items-center mb-3 flex-wrap gap-2 left_header">
				{selected && (
					<h5 className="mb-0 text-wrap text-break me-4 main_title">{selected}</h5>
				)}
			</div>

			<Form className={`h-100 d-flex flex-column flex-grow-1 justify-content-between ${selectedRightItem?.isSelf ? "" : "not_allowed"}`}>
				<div className="inside_scroll has_btns has_head">

					<Row>
						<Col xl="5" xxl="6">
							<FormGroup className="form-group">
								<Label for="name">
									Name
								</Label>
								<Input className='mb-1' name='name' value={name} onChange={(e) => handleNameChange(e)} />

								{nameError && timestamp && itemId && (
									<div className='mt-1 text-muted text-small'>Max 50 characters are allowed</div>
								)}

								{(!name && timestamp && itemId) && (
									<div className='mt-1 text-danger text-small'>Name is required</div>
								)}
							</FormGroup>
						</Col>
					</Row>

					<Row className="align-items-end mb-3">
						<Col xl="5" xxl="6">
							<FormGroup className="form-group">
								<Label for="FrameSelect">
									Frame
								</Label>

								<Select
									labelId="FrameSelect"
									id="FrameSelect"
									value={frame}
									label="FrameSelect"
									onChange={(e) => { handleFrameChange(e) }}
									input={<OutlinedInput className='w-100' name='FrameSelect' />}

									MenuProps={MenuProps}

									IconComponent={() => (
										<MdOutlineKeyboardArrowDown size={20} className='me-1 MuiSvgIcon-root MuiSelect-icon' />
									)}
								>
									{frameMasterOptions && frameMasterOptions.map((o, index) => (
										<MenuItem key={index} value={o?.id}>
											{o?.name}
										</MenuItem>
									))}
								</Select>
							</FormGroup>
						</Col>

						<Col xl="7" xxl="6" className="input_with_img ps-xl-0 ps-xxl-3">
							<div className="d-flex gap-3 flex-wrap flex-xl-nowrap">

								<div className="input_with_img_item">
									<div className="form_img">
										{frame === 1 && (
											<svg xmlns="http://www.w3.org/2000/svg" width="52" height="52" viewBox="0 0 52 52" fill="none">
												<path d="M0 26H26" stroke="#44C9F6" strokeWidth="2.6" />
												<path d="M0 0V21.84H26V0H0Z" fill="#E5E5E5" />
												<path d="M52 26V52H0V29.6768H28.6V26H52Z" fill="#E5E5E5" />
											</svg>
										)}

										{frame === 2 && (
											<svg xmlns="http://www.w3.org/2000/svg" width="52" height="53" viewBox="0 0 52 53" fill="none">
												<path d="M0 50.96H26" stroke="#44C9F6" strokeWidth="2.6" />
												<path d="M0 0V23.4H26V0H0Z" fill="#E5E5E5" />
												<path d="M52 52V26H0V46.8L28.6 46.8V52H52Z" fill="#E5E5E5" />
											</svg>
										)}

										{frame === 3 && (
											<svg xmlns="http://www.w3.org/2000/svg" width="53" height="53" viewBox="0 0 53 53" fill="none">
												<path d="M1 0V46.54V46.8L27 20.8V0H1Z" fill="#E5E5E5" />
												<path d="M53 52L6.46 52L6.2 52L32.2 26L53 26L53 52Z" fill="#E5E5E5" />
												<path d="M1 52L27 26" stroke="#44C9F6" strokeWidth="2.6" />
											</svg>
										)}

										{frame === 4 && (
											<svg xmlns="http://www.w3.org/2000/svg" width="52" height="53" viewBox="0 0 52 53" fill="none">
												<path d="M5.2002 50.96H20.8002" stroke="#44C9F6" strokeWidth="2.6" />
												<path d="M0 0V23.4H26V0H0Z" fill="#E5E5E5" />
												<path d="M52 52V26H0V46.8V52H2.6V46.8L23.4 46.8V52H52Z" fill="#E5E5E5" />
											</svg>
										)}
									</div>
									<NumberInputDecimal
										name="frameValue1"
										value={frameValue1}
										setValue={setFrameValue1}
									/>
								</div>

								{(frame === 3 && transomSelect === 2) && (
									<>
										<div className="input_with_img_item">
											<div className="form_img">
												<svg xmlns="http://www.w3.org/2000/svg" width="52" height="52" viewBox="0 0 52 52" fill="none">
													<path d="M14.3 22.36H0V0H26V10.66L14.3 22.36Z" fill="#E5E5E5" />
													<path d="M14.3 29.64H0V52H26V41.34L14.3 29.64Z" fill="#E5E5E5" />
													<path d="M51.9994 13H28.8594L15.8594 26L28.8594 39H51.9994V13Z" fill="#E5E5E5" />
													<path d="M0 26H13" stroke="#44C9F6" strokeWidth="2.6" />
												</svg>
											</div>
											<NumberInputDecimal
												name="frameValue2"
												value={frameValue2}
												setValue={setFrameValue2}
											/>
										</div>

										<div className="input_with_img_item">
											<div className="form_img">
												<svg xmlns="http://www.w3.org/2000/svg" width="52" height="52" viewBox="0 0 52 52" fill="none">
													<path d="M10.66 24.0597H0V0H26V8.53731L10.66 24.0597Z" fill="#E5E5E5" />
													<path d="M10.66 27.9403H0V52H26V43.4627L10.66 27.9403Z" fill="#E5E5E5" />
													<path d="M51.9992 12.9353H31.1992L18.1992 25.8706L31.1992 38.806H51.9992V12.9353Z" fill="#E5E5E5" />
													<path d="M26.26 12.74L13 26L26.26 39.26" stroke="#44C9F6" strokeWidth="2.6" />
												</svg>
											</div>
											<NumberInputDecimal
												name="frameValue3"
												value={frameValue3}
												setValue={setFrameValue3}
											/>
										</div>
									</>
								)}
							</div>
						</Col>
					</Row>

					<Row className="align-items-end mb-3">
						<Col xl="5" xxl="6">
							<FormGroup className="form-group">
								<Label for="BeadSelect">
									Bead
								</Label>

								<Select
									labelId="BeadSelect"
									id="BeadSelect"
									value={bead}
									label="BeadSelect"
									onChange={(e) => { handleBeadChange(e) }}
									input={<OutlinedInput className='w-100' name='BeadSelect' />}

									MenuProps={MenuProps}

									IconComponent={() => (
										<MdOutlineKeyboardArrowDown size={20} className='me-1 MuiSvgIcon-root MuiSelect-icon' />
									)}
								>
									{beadMasterOptions && beadMasterOptions.map((o, index) => (
										<MenuItem key={index} value={o?.id}>
											{o?.name}
										</MenuItem>
									))}
								</Select>
							</FormGroup>
						</Col>

						<Col xl="7" xxl="6" className="input_with_img ps-xl-0 ps-xxl-3">
							<div className="input_with_img_item">
								<div className="form_img">
									{bead === 1 && (
										<svg xmlns="http://www.w3.org/2000/svg" width="52" height="52" viewBox="0 0 52 52" fill="none">
											<path d="M26 10.399H22.1V41.599H26V51.999H0V-0.000976562H26V10.399Z" fill="#E5E5E5" />
											<path d="M25.7402 12.999V38.999" stroke="#44C9F6" strokeWidth="2.6" />
											<rect x="29.6406" y="12.999" width="22.36" height="26" fill="#E5E5E5" />
										</svg>
									)}

									{bead === 2 && (
										<svg xmlns="http://www.w3.org/2000/svg" width="53" height="53" viewBox="0 0 53 53" fill="none">
											<path d="M1 0V46.54V46.8L27 20.8V0H1Z" fill="#E5E5E5" />
											<path d="M53 52L6.46 52L6.2 52L32.2 26L53 26L53 52Z" fill="#E5E5E5" />
											<path d="M1 52L27 26" stroke="#44C9F6" strokeWidth="2.6" />
										</svg>
									)}
								</div>
								<NumberInputDecimalNegative
									name="beadValue"
									value={beadValue}
									setValue={setBeadValue}
								/>
							</div>
						</Col>
					</Row>

					<Row className="align-items-end mb-3">
						<Col xl="5" xxl="6">
							<FormGroup className="form-group">
								<Label for="TransomSelect">
									Transom
								</Label>

								<Select
									labelId="TransomSelect"
									id="TransomSelect"
									value={transomSelect}
									label="TransomSelect"
									onChange={(e) => { handleCollectionChange(e) }}
									input={<OutlinedInput className='w-100' name='TransomSelect' />}

									MenuProps={MenuProps}

									IconComponent={() => (
										<MdOutlineKeyboardArrowDown size={20} className='me-1 MuiSvgIcon-root MuiSelect-icon' />
									)}
								>
									{transomMasterOptions && transomMasterOptions.map((o, index) => (
										<MenuItem key={index} value={o?.id}>
											{o?.name}
										</MenuItem>
									))}
								</Select>
							</FormGroup>
						</Col>

						<Col xl="7" xxl="6" className="input_with_img ps-xl-0 ps-xxl-3">
							<div className="d-flex gap-3 flex-wrap flex-xl-nowrap">
								<div className="input_with_img_item">
									<div className="form_img">
										{transomSelect === 1 && (
											<svg xmlns="http://www.w3.org/2000/svg" width="52" height="52" viewBox="0 0 52 52" fill="none">
												<path d="M26 10.399H22.1V41.599H26V51.999H0V-0.000976562H26V10.399Z" fill="#E5E5E5" />
												<path d="M25.7402 12.999V38.999" stroke="#44C9F6" strokeWidth="2.6" />
												<rect x="29.6406" y="12.999" width="22.36" height="26" fill="#E5E5E5" />
											</svg>
										)}

										{transomSelect === 2 && (
											<svg xmlns="http://www.w3.org/2000/svg" width="52" height="52" viewBox="0 0 52 52" fill="none">
												<path d="M7.80045 26.9187H0L0.000454254 -0.000976562H26.0005V8.53634L7.80045 26.9187Z" fill="#E5E5E5" />
												<path d="M7.68068 24.959H0.000976562V51.999H26.001V43.4617L7.68068 24.959Z" fill="#E5E5E5" />
												<path d="M52.0002 12.9343H31.2002L18.2002 25.8697L31.2002 38.805H52.0002V12.9343Z" fill="#E5E5E5" />
												<path d="M26.261 12.739L13.001 25.999L26.261 39.259" stroke="#44C9F6" strokeWidth="2.6" />
											</svg>
										)}

										{transomSelect === 3 && (
											<svg xmlns="http://www.w3.org/2000/svg" width="52" height="52" viewBox="0 0 52 52" fill="none">
												<path d="M0.000431061 15.6H5.20051V36.4H0.000431061V51.9991H26V0H0.000431061V15.6Z" fill="#E5E5E5" />
												<path d="M1.56055 18.2L1.56055 33.8" stroke="#44C9F6" strokeWidth="2.59996" />
												<rect x="28.6006" y="13" width="23.4" height="26" fill="#E5E5E5" />
											</svg>
										)}
									</div>

									<NumberInputDecimal
										name="transomValue1"
										value={transomValue1}
										setValue={setTransomValue1}
									/>
								</div>

								{transomSelect === 2 && (
									<>
										<div className="input_with_img_item">
											<div className="form_img">
												<svg xmlns="http://www.w3.org/2000/svg" width="52" height="52" viewBox="0 0 52 52" fill="none">
													<path d="M14.3 22.359H0V-0.000976562H26V10.659L14.3 22.359Z" fill="#E5E5E5" />
													<path d="M14.3 29.639H0V51.999H26V41.339L14.3 29.639Z" fill="#E5E5E5" />
													<path d="M51.9994 12.999H28.8594L15.8594 25.999L28.8594 38.999H51.9994V12.999Z" fill="#E5E5E5" />
													<path d="M0 25.999H13" stroke="#44C9F6" strokeWidth="2.6" />
												</svg>
											</div>
											<NumberInputDecimal
												name="transomValue2"
												value={transomValue2}
												setValue={setTransomValue2}
											/>
										</div>

										<div className="input_with_img_item">
											<div className="form_img">
												<svg xmlns="http://www.w3.org/2000/svg" width="52" height="52" viewBox="0 0 52 52" fill="none">
													<path d="M10.66 24.0587H0V-0.000976562H26V8.53634L10.66 24.0587Z" fill="#E5E5E5" />
													<path d="M10.66 27.9393H0V51.999H26V43.4617L10.66 27.9393Z" fill="#E5E5E5" />
													<path d="M52.0002 12.9343H31.2002L18.2002 25.8697L31.2002 38.805H52.0002V12.9343Z" fill="#E5E5E5" />
													<path d="M26.26 12.739L13 25.999L26.26 39.259" stroke="#44C9F6" strokeWidth="2.6" />
												</svg>
											</div>
											<NumberInputDecimal
												name="transomValue3"
												value={transomValue3}
												setValue={setTransomValue3}
											/>
										</div>
									</>
								)}
							</div>
						</Col>
					</Row>

					{transomSelect === 2 && (
						<Row className="align-items-end mb-3">
							<Col lg="12" className="d-flex flex-wrap">
								<FormGroup className="me-3 me-sm-4">
									<Label for="allowSwitch">
										Allow cruciform welds
									</Label>
									<Form.Check // prettier-ignore
										type="switch"
										id="allowSwitch"
										className='form-switch-lg default_switch'
										onChange={(e) => handleDefaultChange(e)}
										label={allowCruciformSwitch ? 'On' : 'Off'}
										disabled={selectItemDetails?.allowCruciformSwitch}
										checked={allowCruciformSwitch}
									/>
								</FormGroup>

								<div className="input_with_img">
									<div className="input_with_img_item">
										<div className="form_img">
											<svg xmlns="http://www.w3.org/2000/svg" width="52" height="52" viewBox="0 0 52 52" fill="none">
												<path d="M39 7.8L26 20.8L13 7.8V0H39V7.8Z" fill="#E5E5E5" />
												<path d="M13 44.2L26 31.2L39 44.2V52H13V44.2Z" fill="#E5E5E5" />
												<path d="M44.2 39L31.2 26L44.2 13L52 13L52 39L44.2 39Z" fill="#E5E5E5" />
												<path d="M7.8 39L20.8 26L7.8 13L0 13L1.1365e-06 39L7.8 39Z" fill="#E5E5E5" />
												<path d="M13 13L39 39" stroke="#44C9F6" strokeWidth="2.6" />
												<path d="M39 13L13 39" stroke="#44C9F6" strokeWidth="2.6" />
											</svg>
										</div>

										<div className={allowCruciformSwitch ? '' : 'opacity-50'}>
											<NumberInputDecimal
												name="cruficormValue"
												value={cruficormValue}
												setValue={setCruficormValue}
												disabled={!allowCruciformSwitch}
											/>
										</div>
									</div>
								</div>
							</Col>
						</Row>
					)}
				</div>

				{/* Start: space for buttons at the bottom */}
				{selectedRightItem?.isSelf && (
					<div className="d-flex gap-2 gap-xl-3 main_buttons">
						<Button color="primary" size="md" className="px-4"
							onClick={() => handleFormSubmit()}
							disabled={!timestamp || !name.trim()}
						>
							Save Changes
						</Button>

						<Button color="primary" size="md"
							outline
							className="px-4"
							disabled={!timestamp}
							onClick={() => handleFormReset()} >
							Reset
						</Button>
					</div>
				)}
				{/* End: space for buttons at the bottom */}
			</Form>
		</div>
	);
};

export default ProfileJointsLeft;
