import { useState } from "react";
import { useContext } from "react";
import { createContext } from "react";


const MessageContext = createContext();

const useMessage = () => {
    const value = useContext(MessageContext);
    return value;
}

const CustomMessageContext = ({ children }) => {

//context for passing messages through any component
//make sure to pass message type in all small letter for eg : setMessageType('success')
    const [message, setMessage] = useState('');
    const [messageType, setMessageType] = useState('')

    return (
        // provider for context
        <MessageContext.Provider value={{ message, setMessage, messageType, setMessageType }}>
            {children}
        </MessageContext.Provider>
    )
}

export { useMessage }
export default CustomMessageContext;

