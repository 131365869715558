import React from 'react'
import ErrorLayout from './error-layout'
import wipImage from '../../../../assets/img/wip-1.svg'

const WorkInProgress = ({title}) => {
    return (
        <>  
            <ErrorLayout 
                code={wipImage} 
                text={` ${title ? title : 'Work in Progress'}`}
                subText="This is unfinished page, We are still working on it."
                layout="layout-2"
                pageType="inside_content"
            />
        </>
    )
}

export default WorkInProgress