import React, { useState, useEffect } from 'react'
import { Form } from 'react-bootstrap';
import { MdOutlineKeyboardArrowDown } from 'react-icons/md';
import { MenuItem, OutlinedInput, Select } from 'material-ui-core';
import {
    Row,
    Col,
    Button,
    FormGroup,
    Label,
} from 'reactstrap';
import { MenuProps, noSelectOptions } from '../utility/data';
import { useRightContext } from '../context/rightMenuContext'
import { allHardwareCollection } from '../services/productHardwareServices';

const SystemHardware = ({ isEditable, activeTab, accessToken, selectItemDetails, updateItemDetailsServices, handleFormReset }) => {

    const { timestamp } = useRightContext()

    const [hardwareCollection, SetHardwareCollection] = useState([]);

    const [itemId, setItemId] = useState('')
    const [hardwareId, setHardwareId] = useState("")

    useEffect(() => {
        if (activeTab === "Hardware") {
            getHardwareCollections()
        }
    }, [activeTab])

    // setting all the value for prefilled inputs
    useEffect(() => {
        // console.log(selectItemDetails, "data left")

        setItemId(selectItemDetails?.id || '')
    }, [selectItemDetails])

    useEffect(() => {
        if (hardwareCollection && hardwareCollection.length > 0) {
            setHardwareId(selectItemDetails?.hardwareId || hardwareCollection[0]?.id)
        }
    }, [selectItemDetails, hardwareCollection])

    const getHardwareCollections = async () => {
        const res = await allHardwareCollection(accessToken)

        if (res && res && res.status === 200) {
            if (res?.data?.statusCode === 200) {
                if (res?.data?.entity !== null) {
                    if (res?.data?.entity?.length > 0) {
                        SetHardwareCollection(res?.data?.entity)
                    } else {
                        SetHardwareCollection(noSelectOptions)
                    }
                }
            }
        }
    }

    const handleFormSubmit = () => {

        var formDataValues = {
            'hardwareId': hardwareId,

            'id': selectItemDetails?.id,
            'collectionId': selectItemDetails?.collectionId,
            'name': selectItemDetails?.name,
            'description': selectItemDetails?.description,
            'colourId': selectItemDetails?.colourId,
            'frameStyleId': selectItemDetails?.frameStyleId,
            'jointsId': selectItemDetails?.jointsId,
            'defaultTransomDrop': selectItemDetails?.defaultTransomDrop,
            'defaultSideHungWidth': selectItemDetails?.defaultSideHungWidth,
            'defaultSill': selectItemDetails?.defaultSill,
            'defaultWindow': selectItemDetails?.defaultWindow,

            'specifications': selectItemDetails?.specifications,
            'defaultSpecifications': selectItemDetails?.defaultSpecificationId,
            'frameClearance': selectItemDetails?.frameClearance,
            'gasketClearance': selectItemDetails?.gasketClearance,
            'beadRadio': selectItemDetails?.bead,

            'profileId': selectItemDetails?.profileId,
            'subProductId': selectItemDetails?.subProductId,
        }

        updateItemDetailsServices(formDataValues, "Hardware")
    }

    return (
        <Form className='h-100 d-flex flex-column flex-grow-1 justify-content-between'>
            <div className='inside_scroll has_tab has_btns has_head'>
                <Row>
                    <Col xxl="12">
                        <Row>
                            <Col lg="6">
                                <FormGroup className="form-group">
                                    <Label for="collectionSelect">
                                        Collection
                                    </Label>

                                    <Select
                                        labelId="collectionSelect"
                                        id="collectionSelect"
                                        value={hardwareId}
                                        label="collectionSelect"
                                        onChange={(e) => { setHardwareId(e.target.value) }}
                                        input={<OutlinedInput className='w-100' name='collectionSelect' />}

                                        MenuProps={MenuProps}

                                        IconComponent={() => (
                                            <MdOutlineKeyboardArrowDown size={20} className='me-1 MuiSvgIcon-root MuiSelect-icon' />
                                        )}
                                    >
                                        {hardwareCollection && hardwareCollection.map((o, index) => (
                                            <MenuItem key={index} value={o?.id}>
                                                {o?.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormGroup>
                            </Col>

                            {/* <Col lg="12" className='mb-5'>
                                <h4 className='main_title sub_title mb-3'>Cross section</h4>
                                <div className="d-flex gap-2 align-items-center flex-nowrap">
                                    <span className="css-502">Inside</span>
                                    <svg width="200" height="140"><g strokeWidth="1" transform="scale(2,2)"><g fill="rgba(73,72,71,0.2)" stroke="rgba(73,72,71,0.6)"><path d="M 0,70 0,30 7.5,30 7.5,50 92.5,50 92.5,30 100,30 100,70 Z "></path></g><path d="M 55,0 59,0 59,50 55,50 Z M 81,0 85,0 85,50 81,50 Z" fill="rgba(0,120,212,0.2)" stroke="rgba(0,120,212,0.6)"></path><path d="M 59,40 81,40 81,50 59,50 Z" fill="rgba(73,72,71,0.4)" stroke="rgba(73,72,71,0.8)"></path></g></svg>
                                    <span className="css-502">Outside</span>
                                </div>
                            </Col> */}
                        </Row>
                    </Col>
                </Row>
            </div>

            {isEditable && (
                <div className='d-flex gap-2 gap-xl-3 main_buttons'>
                    <Button
                        color="primary"
                        size="md"
                        className='px-4'
                        onClick={() => handleFormSubmit()}
                        disabled={!timestamp || !itemId}
                    >
                        Save Changes
                    </Button>

                    <Button
                        color="primary"
                        size="md"
                        outline
                        className='px-4'
                        onClick={() => handleFormReset()}
                        disabled={!timestamp}
                    >
                        Reset
                    </Button>
                </div>
            )}
        </Form>
    )
}

export default SystemHardware