import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { Form } from "react-bootstrap";
import { servicePath } from "../../../../../utility/data";
import PricingHeader from "../../../../../components/Pricing/PricingHeader";
import axios from "axios";
import { useEffect } from "react";
import { useRightContext } from "../../../../../context/rightMenuContext";
import {
  handleEmptyValues,
  newHandleInput,
} from "../../../../../utility/helper";
import PricingTableComp from "../../../../../components/PricingTableComp";

const Left = ({
  token,
  setMessage,
  setMessageType,
  loading,
  setLoading,
  sashId,
  pageName,
}) => {
  const navlinks = [
    {
      id: 1,
      name: "Lump sum",
    },
    {
      id: 2,
      name: "Square metre",
    },
  ];
  const [selectedPricing, setSelectedPricing] = useState();
  const [selectedSupplyOnly, setSelectedSupplyOnly] = useState("");
  const [costPrice, setCostPrice] = useState([]);
  const [activeTab, setActiveTab] = useState("Lump sum");
  const [lumpSumData, setLumpSumData] = useState([]);
  const [squareMetreData, setSquareMetreData] = useState([]);
  const { userDataContext, setUserDataContext } = useRightContext("");

  const { pricingSashProductsMenuList } = useRightContext();

  const navigate = useNavigate();

  const noRefCheck = (tab) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    if (pricingSashProductsMenuList && pricingSashProductsMenuList.length > 0) {
      const getPageName = pricingSashProductsMenuList.filter((item) => {
        return item.id == sashId;
      });

      // setPageName(getPageName[0]?.title);
    }
  }, [sashId, pricingSashProductsMenuList]);

  useEffect(() => {
    setLoading(true);
    if (selectedPricing && sashId && selectedSupplyOnly) {
      getSashProductsPricing();
    }
  }, [selectedPricing, sashId, selectedSupplyOnly]);

  const getSashProductsPricing = () => {
    const FormData = require("form-data");

    let data = new FormData();

    data.append("PriceMasterId", selectedPricing);
    data.append("SashProductMasterId", sashId);
    data.append("MarkupMasterId", selectedSupplyOnly);
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${servicePath}/General/GetSashProductPriceList`,
      headers: {
        accept: "text/plain",
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
      data: data,
    };

    axios
      .request(config)
      .then((res) => {
        if (res?.data?.statusCode === 200) {
          setLoading(false);
          setLumpSumData(res?.data?.entity["lumpSum"]);
          setSquareMetreData(res?.data?.entity["squareMetre"]);
          console.log(lumpSumData, squareMetreData, "sashPricing");
        } else if (res?.data?.statusCode === 404) {
          setLoading(false);
          navigate("/error/404");
        } else {
          setLoading(false);
          setMessage(
            res?.data?.message ? res.data.message : "Something went wrong"
          );
          setMessageType("error");
        }
      })
      .catch((error) => {
        console.log(error);

        setLoading(false);
        setMessage(error.message ? error.message : "Something went wrong");
        setMessageType("error");

        if (error?.message === "Network Error") {
          navigate("/error/network");
        }
      });
  };

  const handleInput = (setMethod, id, key, value) => {
    if (validate(value)) {
      setMethod((prevData) =>
        prevData.map((item, index) =>
          index === id ? { ...item, [key]: parseFloat(value) } : item
        )
      );
    }
  };

  const validate = (value) => {
    if (value < 0) return false;
    return true;
  };

  const handleUpdate = () => {
    setLoading(true);
    updateFrameStylePricing();
  };

  const updateFrameStylePricing = () => {
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${servicePath}/General/AddUpdateSashProductPriceList`,
      headers: {
        accept: "text/plain",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: handleEmptyValues([...lumpSumData, ...squareMetreData]),
    };

    axios
      .request(config)
      .then((res) => {
        console.log(res.data, "UpdatedSashProductsData");

        if (res?.data?.statusCode === 200) {
          setMessage(res.data.message);
          setMessageType("success");
          getSashProductsPricing();
        }
      })
      .catch((error) => {
        console.log(error);

        setLoading(false);
        setMessage(error.message ? error.message : "Something went wrong");
        setMessageType("error");

        if (error?.message === "Network Error") {
          navigate("/error/network");
        }
      });
  };

  const handleFormReset = () => {
    setLoading(true);
    getSashProductsPricing();
  };
  const data1 = { url: `/product/systems/sash-products/${sashId}` };
  return (
    <>
      <PricingHeader
        setSelectedSupplyOnly={setSelectedSupplyOnly}
        setSelectedPricing={setSelectedPricing}
        selectedPricing={selectedPricing}
        selectedSupplyOnly={selectedSupplyOnly}
        costPrice={costPrice}
      />
      <div className="d-flex align-items-center mb-4 flex-wrap gap-3 left_header">
        <h5 className="mb-0 text-wrap text-break me-4 main_title">
          {pageName}
        </h5>
        {/* <CustomizedButton data={data1} /> */}
      </div>
      <Nav tabs className="details_header border-0 nav-tabs-custom">
        {navlinks.map((item) => (
          <NavItem key={item.id}>
            <NavLink
              className={`${activeTab === item.name ? "active" : "text-muted"}`}
              onClick={() => noRefCheck(item.name)}
            >
              {item.name}
            </NavLink>
          </NavItem>
        ))}
      </Nav>
      <TabContent activeTab={activeTab} className="flex-grow-1 d-flex">
        <TabPane tabId="Lump sum" className="flex-grow-1 w-100">
          <Form className="h-100 d-flex flex-column flex-grow-1 justify-content-between">
            <div className="inside_scroll has_tab has_btns">
              <PricingTableComp
                data={lumpSumData}
                handleInput={handleInput}
                newHandleInput={newHandleInput}
                setData={setLumpSumData}
                userDataContext={userDataContext}
              ></PricingTableComp>
            </div>
            <div className="d-flex gap-2 gap-xl-3 main_buttons">
              <Button
                color="primary"
                size="md"
                className="px-4"
                onClick={handleUpdate}
              >
                Save Changes
              </Button>

              <Button
                color="primary"
                size="md"
                outline
                className="px-4"
                onClick={handleFormReset}
              >
                Reset
              </Button>
            </div>
          </Form>
        </TabPane>
        <TabPane tabId="Square metre" className="flex-grow-1 w-100">
          <Form className="h-100 d-flex flex-column flex-grow-1 justify-content-between">
            <div className="inside_scroll has_tab has_btns">
              <PricingTableComp
                data={squareMetreData}
                handleInput={handleInput}
                newHandleInput={newHandleInput}
                setData={setSquareMetreData}
                userDataContext={userDataContext}
              ></PricingTableComp>
            </div>
            <div className="d-flex gap-2 gap-xl-3 main_buttons">
              <Button
                color="primary"
                size="md"
                className="px-4"
                onClick={handleUpdate}
              >
                Save Changes
              </Button>

              <Button
                color="primary"
                size="md"
                outline
                className="px-4"
                onClick={handleFormReset}
              >
                Reset
              </Button>
            </div>
          </Form>
        </TabPane>
      </TabContent>
    </>
  );
};

export default Left;
