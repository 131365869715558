import React from "react";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import { Input } from "reactstrap";

const NumberInput = ({ name, value, setValue, disabled, className }) => {

    const handleValueUpdate = (e, type) => {
        e.preventDefault();
    
        if (!disabled) {
            let newVal = parseInt(value) || 0; // Default to 0 if value is empty or NaN
            
            if (type === 'minus') {
                newVal = newVal - 1;
            } else {
                newVal = newVal + 1;
            }
    
            // Ensure the value stays positive
            if (newVal < 0) {
                newVal = 0;
            }
    
            setValue(parseFloat(newVal));
        }
    };
    

    const handleonBlur = (e) => {
        // Allow only positive numbers
        e.target.value = e.target.value.replace(/[^0-9]/g, '');

        if (e.target.value === "" || parseInt(e.target.value, 10) < 0) {
            e.target.value = ""; // Clear if value is not a positive integer
        }
    }

    return (
        <>
            <div className='input_with_buttons'>
                <span className='up_down_btns'>
                    <span onClick={(e) => { handleValueUpdate(e, 'plus') }}>
                        <MdKeyboardArrowUp />
                    </span>
                    <span onClick={(e) => { handleValueUpdate(e, 'minus') }} className={(value === 0 || value === '') ? "opacity-50" : "''"}>
                        <MdKeyboardArrowDown />
                    </span>
                </span>
                <Input type="number" className={`mb-1 ${className}`} id={name} name={name} value={value} disabled={disabled} onChange={(e) => setValue(parseFloat(e.target.value))} onInput={(e) => { handleonBlur(e) }} />
            </div>
        </>
    );
}

export default NumberInput;