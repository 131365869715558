import React, { useEffect } from "react";
import { useState } from "react";
import {
  MdInfoOutline,
  MdOutlineKeyboardArrowDown,
  MdPersonOutline,
} from "react-icons/md";
import { useNavigate } from "react-router-dom";
import {
  Nav,
  NavLink,
  NavItem,
  TabContent,
  TabPane,
  Button,
  FormGroup,
  Label,
  Input,
  Alert,
} from "reactstrap";

import { Image } from "react-bootstrap";
import { MenuProps } from "../../utility/data";
import { colorList } from "../../services/productServices";
import { useDesigner } from "../../context/designerContext";
import {
  getGlazingProductsData,
  getPanelsWithPriceService,
  getVisiblePanelForFrameProductService,
  setModelData,
  setQuotationModelData,
} from "../../services/3dModelServices";
import { useMessage } from "../../context/messageContext";
import NumberInput from "../NumberInput";

import { MenuItem, OutlinedInput, Select } from "material-ui-core";
import { BiTrash } from "react-icons/bi";
import { PiProhibitBold } from "react-icons/pi";
import { IoWarningOutline } from "react-icons/io5";
import { getPanelModelProperty } from "../../services/panelModelServices";

const GlazingConfig = ({
  setGlazingData,
  collectionId,
  frameStyleProdId,
  setLoading,
  quotationId,
  glazingDesignOption,
  setHardwareType,
  receivedProductId,
  setNumeralsText,
  setNumeralsFont,
  numeralsText,
  numeralsFont,
}) => {
  const [activeTab, setActiveTab] = useState("");
  const [activePanelId, setActivePanelId] = useState("");
  const [colorListDrop, setColorListDrop] = useState([]);
  const [isPanel, setIsPanel] = useState("");

  const [activeSpacerBar, setActiveSpacerBar] = useState();
  const [activeSpacerBarIndex, setActiveSpacerBarIndex] = useState("");
  const [glazingProductData, setGlazingProductData] = useState([]);

  const [panelDropdownOptions, setPanelDropdownsOptions] = useState([]);
  const [selectedPanel, setSelectedPanel] = useState("");

  const [panelStyleList, setPanelStylesList] = useState([]);
  const [selectedPanelStyle, setSelectedPanelStyle] = useState("");
  const [specIndexArray, setSpecIndexArray] = useState([]);
  const [textureIndexArray, setTextureIndexArray] = useState([]);
  // const [showDesignConfig, setShowDesignConfig] = useState(false)

  const navigate = useNavigate();
  const { setMessage, setMessageType } = useMessage();
  const {
    activeIndex,
    handleGlazingSpec,
    handleTexture,
    customModelData,
    setCustomModelData,
    glazingSpec,
    texture,
    handleGlazingDesign,
    design,
    rows,
    setRows,
    cols,
    setCols,
    designHeight,
    designWidth,
    setdesignHeight,
    setdesignWidth,
    setSaveGlazingDesign,
    saveGlazingDesign,
    setDesign,
    setClearGlazingDesign,
    clearGlazingDesign,
    setApplyPanel,
    setDeletePanel,
    setAppliedPanelData,
    panelDataSave,
    setPanelDataSave,
    checkSingleSide,
    setCheckSingleSide,
    multiSelectRefPoints,
    setSpacerBar,
    setSanblastPricing
  } = useDesigner();

  const accessToken = localStorage.getItem("access_token");

  // let width = 2500;

  // const designElements = [
  //   {
  //     id: 1,
  //     name: "None",
  //     vertical: "None",
  //     row: "None",
  //   },
  //   {
  //     id: 2,
  //     name: "Astragal Rectangular",
  //     vertical: astragalVertical,
  //     row: astragalHorizontal,
  //   },
  //   {
  //     id: 3,
  //     name: "Georgian Rectangular",
  //     vertical: georgianVertical,
  //     row: georgianHorizontal,
  //   },
  // ];

  const [isSave, setIsSave] = useState(false);

  // useEffect(() => {
  //   if (customModelData?.glazing && customModelData?.glazing?.design && customModelData?.glazing?.design?.length > 0 && activeTab === "design") {
  //     setHardwareType("glazing")
  //     setDesign(glazingDesignOption?.design)
  //   }
  // }, [activeTab])


  
  useEffect(() => {
    if (glazingDesignOption) {
      if (
        glazingDesignOption?.frameType == "Panel" ||
        (glazingDesignOption?.name === "Panel" && glazingDesignOption?.id === 3)
      ) {
        setIsPanel(true);
        setHardwareType("glazingPanel");
      } else if (glazingDesignOption?.spec?.id > 3) {
        setActiveTab("spec");
        setIsPanel(false);
      } else if (glazingDesignOption === "None") {
        setActiveTab("spec");
        setIsPanel(false);
      } else {
        setActiveTab(glazingDesignOption?.name?.toLowerCase());
        setIsPanel(false);
      }
    } else {
      setActiveTab("spec");
      setIsPanel(false);
    }
  }, [glazingDesignOption]);

 

  useEffect(() => {
    if (collectionId) {
      getAllGlazingData(collectionId);
    }
  }, [collectionId]);

  useEffect(() => {
    if (
      glazingDesignOption?.frameType === "Panel" ||
      (glazingDesignOption?.name === "Panel" && glazingDesignOption?.id === 3)
    ) {
      getPanelDropdowns(collectionId);
    }
  }, [glazingDesignOption, collectionId]);

  useEffect(() => {
    if (selectedPanel) {
      getPanelStyles(selectedPanel);
    }
  }, [selectedPanel]);

  useEffect(() => {
    if (cols) {
      let glazingWidth = 2500 / cols;
      setdesignWidth(parseInt(glazingWidth));
    }
  }, [cols]);

  useEffect(() => {
    if (rows) {
      let glazingHeight = 2100 / rows;
      setdesignHeight(parseInt(glazingHeight));
    }
  }, [rows]);

  // This is for selecting the applied glazing from the list
  useEffect(() => {
    if (
      glazingProductData?.specification?.length > 0 &&
      customModelData &&
      customModelData?.glazing &&
      customModelData?.glazing?.spec?.specData
    ) {
      glazingProductData?.specification.forEach((item, index) => {
        if (item?.id === customModelData?.glazing?.spec?.specData?.id) {
          handleGlazingSpec(item, index);
        }
      });
    }
  }, [customModelData, glazingProductData]);

  // This is for activating/deactivating the selected glazing and texture on the basis of the selected reference point
  useEffect(() => {
    if (
      customModelData?.glazingData?.length > 0 &&
      multiSelectRefPoints?.length > 0 &&
      glazingProductData?.specification?.length > 0
    ) {
      const tempArray = customModelData?.glazing?.spec
        .filter((item) =>
          multiSelectRefPoints.some((ele) => item.index === ele.index)
        )
        .map((item) =>
          glazingProductData?.specification.findIndex(
            (el) => el?.id === item?.id
          )
        )
        .filter((index) => index !== -1);

      setSpecIndexArray(tempArray);
    }
    if (
      customModelData?.glazingData?.length > 0 &&
      multiSelectRefPoints?.length > 0 &&
      glazingProductData?.texture?.length > 0
    ) {
      const tempArray = customModelData?.glazing?.texture
        .filter((item) =>
          multiSelectRefPoints.some((ele) => item.index === ele.index)
        )
        .map((item) =>
          glazingProductData?.texture?.findIndex((el) => el?.id === item?.id)
        )
        .filter((index) => index !== -1);

      setTextureIndexArray(tempArray);
    }
  }, [
    multiSelectRefPoints,
    customModelData?.glazingData,
    glazingProductData?.specification,
  ]);

  useEffect(() => {
    if (saveGlazingDesign) {
      setTimeout(() => {
        setSaveGlazingDesign(false);
      }, 500);
    }
  }, [saveGlazingDesign]);

  useEffect(() => {
    if (clearGlazingDesign) {
      setTimeout(() => {
        setClearGlazingDesign(false);
      }, 100);
    }
  }, [clearGlazingDesign]);

  useEffect(() => {
    colorListServices();
    setCheckSingleSide("");
    // glazingTextureService()
  }, []);

  // showing active panel in list
  useEffect(() => {
    if (
      isPanel &&
      customModelData?.glazing?.panel?.length > 0 &&
      panelStyleList
    ) {
      const panelActiveId = customModelData?.glazing?.panel?.find(
        (p) => p.glassIndex == checkSingleSide
      );
      setActivePanelId(panelActiveId?.id);
    } else {
      setActivePanelId("");
    }
  }, [checkSingleSide]);

  // useEffect(() => {
  //   if (customModelData?.glazing?.spec?.length > 0 || customModelData?.glazing?.texture?.length > 0 || customModelData?.glazing?.panel?.length > 0 || customModelData?.glazing?.design?.effect || parseInt(customModelData?.numberOfSash?.number)>=0) {
  //     console.log("I AM HERE ",parseInt(customModelData?.numberOfSash?.number))
  //     setGotGlazingData(true)
  //   }
  //   setTimeout(() => {
  //     setGotGlazingData(false)
  //   }, [200])
  // }, [customModelData])


  const cancel = () => {
    setGlazingData();
    setDesign();
    setHardwareType("");
    handleGlazingSpec("", "");
  };

  useEffect(() => {
    if (!quotationId && isSave) {
      saveGlazingService(customModelData);
      setIsSave(false);
      setSaveGlazingDesign(true);
      setClearGlazingDesign(true);
      setCheckSingleSide("");
      setIsPanel("");
      // setHardwareType('')
      setGlazingData("");
    } else if (quotationId && isSave) {
      glazingQuotationService(customModelData);
      setIsSave(false);
      setSaveGlazingDesign(true);
      setClearGlazingDesign(true);
      setCheckSingleSide("");
      setIsPanel("");
      // setHardwareType('')
      setGlazingData("");
    }
  }, [customModelData, isSave]);

  // This is for activating the index of the selected glazing
  useEffect(() => {
    if (
      glazingProductData?.specification?.length > 0 &&
      customModelData?.glazingData?.length > 0 &&
      multiSelectRefPoints?.length == 0
    ) {
      let newArray = [];
      glazingProductData?.specification?.forEach((item, index) => {
        customModelData?.glazingData?.forEach((ele) => {
          if (ele?.spec?.id === item?.id) {
            newArray.push(index);
          }
        });
      });
      setSpecIndexArray(newArray);
    }
    if (
      glazingProductData?.texture?.length > 0 &&
      customModelData?.glazingData?.length > 0 &&
      multiSelectRefPoints?.length == 0
    ) {
      let newArray = [];
      glazingProductData?.texture?.forEach((item, index) => {
        customModelData?.glazingData?.forEach((ele) => {
          if (ele?.texture?.id === item?.id) {
            newArray.push(index);
          }
        });
      });
      setTextureIndexArray(newArray);
    }
  }, [glazingProductData, customModelData, multiSelectRefPoints]);

  // Code for getting data for the applied panel
  useEffect(() => {
    if (selectedPanelStyle !== "") {
      getAppliedPanelModelDataService(
        selectedPanelStyle?.panelProductCollectionId,
        selectedPanelStyle?.id
      );
    }
  }, [selectedPanelStyle]);

  const noRefCheck = (tab) => {
    setActiveTab(tab);
    setHardwareType(tab);
  };

  const colorListServices = async () => {
    const res = await colorList(accessToken);
    setColorListDrop(res?.data?.entity);
  };

  // const getAllPaletteServices = async () => {
  //   const res = await allGlazingPalette(1, accessToken)
  //   setGlazingList(res?.data?.entity)
  // }

  // const allGlazingCollectionPaletteServices = async () => {
  //   const res = await getGlazingCollectionPalette(collectionId, 2, accessToken)
  //   if (res && res.status === 200) {
  //     if (res?.data?.statusCode === 200) {
  //       setSpacerBar(res?.data?.entity?.visiblePalette)
  //     }
  //   }
  // }

  const getAllGlazingData = async (id) => {
    const res = await getGlazingProductsData(accessToken, id);
    if (res && res.status === 200) {
      if (res?.data?.statusCode === 200) {
        setGlazingProductData(res?.data?.entity);
      }
    }
  };

  const getPanelDropdowns = async (collectionId) => {
    const res = await getVisiblePanelForFrameProductService(
      accessToken,
      collectionId
    );
    if (res?.status === 200) {
      if (res?.data?.statusCode === 200) {
        setPanelDropdownsOptions(res?.data?.entity);
        setSelectedPanel(res?.data?.entity[0]?.id);
      }
    } else {
      setPanelDropdownsOptions([]);
    }
  };

  const getPanelStyles = async (panelId) => {
    const res = await getPanelsWithPriceService(accessToken, panelId);
    if (res?.status === 200) {
      if (res?.data?.statusCode === 200) {
        setPanelStylesList(res?.data?.entity);
      }
    } else {
      setPanelStylesList([]);
    }
  };

  const saveGlazing = () => {
    // setCustomModelData((prevModelData) => ({
    //   ...prevModelData,
    //   glazing: {
    //     ...prevModelData.glazing,
    //     spec: {
    //       ...prevModelData.glazing.spec,
    //       specData: glazingSpec
    //     },
    //     texture: {
    //       ...prevModelData.glazing.texture,
    //       effect: "None"
    //     },
    //   },
    // }));
    setIsSave(true);
  };

  const saveTexture = () => {
    // setCustomModelData((prevModelData) => ({
    //   ...prevModelData,
    //   glazing: {
    //     ...prevModelData.glazing,
    //     texture: {
    //       ...prevModelData.glazing.texture,
    //       effect: texture
    //     },
    //     spec: {
    //       ...prevModelData.glazing.spec,
    //       specData: "None"
    //     }
    //   }
    // }))
    setIsSave(true);
  };

  const saveDesign = () => {
    // setCustomModelData((prevModelData) => ({
    //   ...prevModelData,
    //   glazing: {
    //     ...prevModelData.glazing,
    //     design: {
    //       ...prevModelData.glazing.design,
    //       effect: design
    //     },
    //     spec: {
    //       ...prevModelData.glazing.spec,
    //       specData: glazingSpec ? glazingSpec : "None"
    //     }
    //   }
    // }))
    setIsSave(true);
  };

  // const savePanelData = () => {
  //   setCustomModelData((prevModelData) => ({
  //     ...prevModelData,
  //     glazing: {
  //       ...prevModelData.glazing,
  //       panel: panelDataSave,
  //     },
  //   }));

  //   setIsSave(true)
  // };

  useEffect(() => {
    if (panelDataSave) {
      setTimeout(() => {
        setCustomModelData((prevModelData) => ({
          ...prevModelData,
          glazing: {
            ...prevModelData.glazing,
            panel: panelDataSave,
          },
        }));
      }, 200);
    }
  }, [panelDataSave]);

  // This is for getting the pricing for the sanblasted numerals for the glazing design
  useEffect(() => {
    if (glazingProductData?.design?.length > 0) {
      glazingProductData?.design?.forEach((item) => {
        if (item?.name === "Sandblasted numerals") {
          setSanblastPricing(item?.price);
        }
      });
    }
  }, [glazingProductData?.design]);

  const saveGlazingService = async (data) => {
    setLoading(true);
    const res = await setModelData(
      accessToken,
      collectionId,
      frameStyleProdId?.id,
      data
    );
    if (res && res.status === 200) {
      if (res?.data?.statusCode === 200) {
        setLoading(false);
        setMessage(res?.data?.message);
        setMessageType("success");
        setHardwareType("");
      } else {
        setLoading(false);
        setMessage(res?.data?.message);
        setMessageType("error");
        setHardwareType("");
      }
    } else {
      setLoading(false)
      setMessageType("error")
      setMessage(res?.data?.message?res?.data?.message:"Something went wrong !")
      if (res?.response?.status === 401) {
          navigate('/unauthorized')
          localStorage.removeItem("access_token")
      }
      if (res?.response?.status == 404) {
          navigate('/error/503')
      }
      if (res?.message === "Network Error") {
          navigate('/error/network')
      }
  }
  };

  const glazingQuotationService = async (data) => {
    setLoading(true);
    const res = await setQuotationModelData(
      accessToken,
      collectionId,
      frameStyleProdId?.id,
      data,
      quotationId,
      receivedProductId
    );
    if (res && res.status === 200) {
      if (res?.data?.statusCode === 200) {
        setLoading(false);
        setMessage(res?.data?.message);
        setMessageType("success");
      }
    } else {
      setLoading(false)
      setMessageType("error")
      setMessage(res?.data?.message ? res?.data?.message : "Something went wrong !")
      if (res?.response?.status === 401) {
        navigate('/unauthorized')
        localStorage.removeItem("access_token")
      }
      if (res?.response?.status == 404) {
        navigate('/error/503')
      }
      if (res?.message === "Network Error") {
        navigate('/error/network')
      }
    }
  };

  const getAppliedPanelModelDataService = async (panelCollectionId, panelProductId) => {

    const res = await getPanelModelProperty(accessToken, panelCollectionId, panelProductId);

    if (res?.status === 200) {
      if (res?.data?.statusCode === 200) {

        if (res?.data?.entity?.jsonBlob !== null) {
          setAppliedPanelData(JSON.parse(res?.data?.entity?.jsonBlob));
          setTimeout(() => {
            var json = {
              ...selectedPanelStyle,
              frameType: "Panel",
              glassIndex: checkSingleSide,
              // count: 1,
              orientation: "",
              // priceableArea: priceAblearea ? priceAblearea : 0,
              items: JSON.parse(res?.data?.entity?.jsonBlob),
            };

            panelDataSave.push(json);
          }, 100);

          setTimeout(() => {
            setPanelDataSave([...panelDataSave]);
          }, 400);

        } else {
          setAppliedPanelData();

          setTimeout(() => {
            var json = {
              ...selectedPanelStyle,
              frameType: "Panel",
              glassIndex: checkSingleSide,
              count: 1,
              orientation: "",
              items: [],
            };

            panelDataSave.push(json);
          }, 100);

          setTimeout(() => {
            setPanelDataSave([...panelDataSave]);
          }, 400);
        }

        setLoading(false);
        setSelectedPanelStyle('')
      }
    } else {
      setAppliedPanelData();
      setLoading(false);
      setSelectedPanelStyle('')
    }
  };

  const saveData = () => {
    if (activeTab === "spec") {
      saveGlazing();
      if (quotationId) {
        // glazingQuotationService(customModelData);
        setIsSave(true);
      } else {
        saveGlazingService(customModelData);
      }
    } else if (activeTab === "texture") {
      saveTexture();
      if (quotationId) {
        glazingQuotationService(customModelData);
      } else {
        saveGlazingService(customModelData);
      }
    } else if (activeTab === "design") {
      saveDesign();
      if (quotationId) {
        glazingQuotationService(customModelData);
      } else {
        saveGlazingService(customModelData);
      }
      setSaveGlazingDesign(true);
      setClearGlazingDesign(true);
    } else if (isPanel) {
      // savePanelData()
      if (quotationId) {
        glazingQuotationService(customModelData);
      } else {
        saveGlazingService(customModelData);
      }
    }

    // setHardwareType('')
  };

  const handleSpacerBar = (item, index) => {
    setActiveSpacerBar(item);
    setActiveSpacerBarIndex(index);
    setSpacerBar(item);
  };

  const handlePanelStyle = (item) => {
    setSelectedPanelStyle(item);
    setActivePanelId(item?.id);

    var filterPanelJson = panelDataSave.filter((item) => {
      if (item.glassIndex == checkSingleSide) {
        return false;
      } else {
        return true;
      }
    });

    setPanelDataSave(filterPanelJson);

    setTimeout(() => {
      setApplyPanel(true);
    }, 500);
  };

  return (
    <>
      {!isPanel ? (
        <div className="d-flex flex-column h-100">
          <Nav tabs className="details_header border-0 nav-tabs-custom bg-none">
            <NavItem>
              <NavLink
                className={`${activeTab === "spec" && "active"}`}
                onClick={() => noRefCheck("spec")}
              >
                Spec
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={`${activeTab === "design" && "active"}`}
                onClick={() => noRefCheck("design")}
              >
                Design
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={`${activeTab === "texture" && "active"}`}
                onClick={() => noRefCheck("texture")}
              >
                Texture
              </NavLink>
            </NavItem>
          </Nav>

          <TabContent activeTab={activeTab} className="flex-grow-1 d-flex">
            <TabPane tabId="spec" className="flex-grow-1 w-100">
              <NavLink to={{}} className="text-primary mb-2 d-block">
                <span onClick={() => setHardwareType("glazing")}>
                  Select Glazing
                </span>
              </NavLink>
              <div
                className="panel_object_list"
              >
                <div
                  className="position-relative d-flex align-items-center right_item_wrap"
                  key={-1}
                  onClick={() =>
                    handleGlazingSpec(
                      {
                        id: 4,
                        name: "None",
                      },
                      -1
                    )
                  }
                >
                  {/* <div
                    className={`right_item w-100 ${glazingSpecSelect === -1 ? "active" : ""
                      } cursor-pointer`}
                  >
                    <span className="me-2 image_icon ball_shape">
                      <PiProhibitBold size={53} />
                    </span>
                    <div className="right_title">
                      <span className="text-truncate">None</span>
                    </div>
                  </div> */}
                </div>

                {glazingProductData?.specification?.length > 0 &&
                  glazingProductData?.specification?.map((item, index) => {
                    return (
                      <div
                        className="position-relative d-flex align-items-center right_item_wrap"
                        key={index}
                        onClick={() => handleGlazingSpec(item, index)}
                      >
                        <div
                          className={`right_item w-100 ${specIndexArray?.includes(index) ? "active" : ""
                            } cursor-pointer`}
                        >
                          <span className="me-2 image_icon ball_shape ball_shadow">
                            <Image
                              src={item?.capturedImage}
                              alt="ball-image.jpeg"
                              className="rounded-circle"
                            ></Image>
                          </span>
                          <div className="right_title">
                            <span className="text-truncate">{item.name}</span>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                <div className="d-flex align-items-center left_header mt-3 mb-1">
                  <h6 className="mb-0 text-wrap text-break me-4 main_title">
                    Spacer bar
                  </h6>{" "}
                  <div></div>
                </div>
                {glazingProductData?.spacerBar?.length > 0 &&
                  glazingProductData?.spacerBar?.map((item, index) => {
                    return (
                      <div
                        className="position-relative d-flex align-items-center right_item_wrap"
                        key={index}
                        onClick={() => handleSpacerBar(item, index)}
                      >
                        <div
                          className={`right_item w-100 ${activeSpacerBarIndex === index && "active"
                            } cursor-pointer`}
                        >
                          <span className="me-2 image_icon ball_shape ball_shadow">
                            <Image
                              src={item?.capturedImage}
                              alt="ball-image.jpeg"
                              className="rounded-circle"
                            ></Image>
                          </span>
                          <div className="right_title ms-2">
                            <span className="text-truncate">{item.name}</span>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </TabPane>

            <TabPane tabId="design" className="w-100">
              <NavLink
                to={{}}
                className="text-primary mb-2 d-block"
                onClick={() => setHardwareType("glazing")}
              >
                <span onClick={() => setDesign()}>Select Glazing</span>
              </NavLink>

              <div className="panel_object_list pb-3">
                {!design || design?.name === "None" ? (
                  <>

                    <div
                      className="position-relative d-flex align-items-center right_item_wrap"
                      key={-1}
                      onClick={() => handleGlazingDesign("None", -1)}
                    >
                      <div
                        className={`right_item w-100 ${activeIndex === -1 && "active"
                          } cursor-pointer`}
                      >
                        <span className="me-2">
                          <MdPersonOutline size={20} />
                        </span>
                        <div className="right_title">
                          <span className="text-truncate">
                            None
                          </span>
                        </div>
                      </div>
                    </div>
                    {glazingProductData?.design?.length > 0 &&
                      glazingProductData?.design?.map((item, index) => {
                        return (
                          <div
                            className="position-relative d-flex align-items-center right_item_wrap"
                            key={index}
                            onClick={() => handleGlazingDesign(item, index)}
                          >
                            <div
                              className={`right_item w-100 ${activeIndex === index && "active"
                                } cursor-pointer`}
                            >
                              <span className="me-2">
                                <MdPersonOutline size={20} />
                              </span>
                              <div className="right_title">
                                <span className="text-truncate">
                                  {item.name}
                                </span>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </>
                ) : (
                  <>
                    {design && design?.name !== "Sandblasted numerals" && (
                      <div>
                        <div className="d-flex gap-2">
                          <FormGroup className="mb-1">
                            <Label for="width" className="text-dark">
                              Width
                            </Label>
                            <NumberInput
                              name="width"
                              value={designWidth}
                              setValue={setdesignWidth}
                            />
                          </FormGroup>
                          <FormGroup className="mb-1">
                            <Label for="columns" className="text-dark">
                              Columns
                            </Label>
                            <NumberInput
                              name="columns"
                              value={cols}
                              setValue={setCols}
                            />
                          </FormGroup>
                        </div>
                        <div className="d-flex gap-2">
                          <FormGroup className="mb-1">
                            <Label for="height" className="text-dark">
                              Height
                            </Label>
                            <NumberInput
                              name="height"
                              value={designHeight}
                              setValue={setdesignHeight}
                            />
                          </FormGroup>
                          <FormGroup className="mb-1">
                            <Label for="height" className="text-dark">
                              Rows
                            </Label>
                            <NumberInput
                              name="height"
                              value={rows}
                              setValue={setRows}
                            />
                          </FormGroup>
                        </div>

                        {/* <div>
                          <FormGroup className="form-group">
                            <Label for="bump" className='text-dark'>Style</Label>
                            <Select
                              labelId="printdoc-label"
                              id="bump"
                              // value={desi}
                              label="bump"
                              // onChange={handleBump}
                              input={
                                <OutlinedInput className="w-100" name="bump" />
                              }
                              MenuProps={MenuProps}
                              IconComponent={() => (
                                <MdOutlineKeyboardArrowDown
                                  size={20}
                                  className="me-1 MuiSvgIcon-root MuiSelect-icon"
                                />
                              )}
                            >
                              <MenuItem value={0}>None</MenuItem>
                              {designElements?.map((item, index) => {
                                return (
                                  <MenuItem value={item?.id} key={index}>
                                    {item?.name}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormGroup>
                        </div> */}
                      </div>
                    )}
                  </>
                )}
              </div>

              {design && design?.name === "Sandblasted numerals" && (
                <>
                  <div className="mb-2">
                    <Label for="numeralsText" className="text-dark">
                      Numerals
                    </Label>

                    <Input
                      type="textarea"
                      className="mb-1"
                      rows="5"
                      name="numeralsText"
                      onChange={(e) => setNumeralsText(e.target.value)}
                      value={numeralsText}
                    />
                  </div>

                  <div className="mb-2">
                    <Label for="numeralsText" className="text-dark">
                      Numerals Size
                    </Label>

                    <Input
                      type="number"
                      className="mb-1"
                      name="numeralsFont"
                      onChange={(e) => setNumeralsFont(e.target.value)}
                      value={numeralsFont}
                    />
                  </div>
                </>
              )}
            </TabPane>

            <TabPane tabId="texture" className="w-100">
              <NavLink
                to={{}}
                className="text-primary mb-2 d-block"
                onClick={() => setHardwareType("glazing")}
              >
                <span>Select Glazing</span>
              </NavLink>
              <div
                className="panel_object_list"
              >
                <div
                  className="position-relative d-flex align-items-center right_item_wrap"
                  key={-1}
                  onClick={() => handleTexture("None", -1)}
                >
                  <div
                    className={`right_item w-100 ${activeIndex === -1 && "active"
                      } cursor-pointer`}
                  >
                    <span className="me-2 ball_shape">
                      <PiProhibitBold size={40} />
                    </span>
                    <div className="right_title">
                      <span className="text-truncate">None</span>
                    </div>
                  </div>
                </div>

                {glazingProductData?.texture?.length > 0 &&
                  glazingProductData?.texture?.map((item, index) => {
                    return (
                      <div
                        className={`position-relative align-items-center right_item_wrap ${item?.name == 'None' ? 'd-none' : 'd-flex'}`}
                        key={index}
                        onClick={() => handleTexture(item, index)}
                      >
                        <div
                          className={`right_item w-100 ${textureIndexArray?.includes(index) && "active"
                            } cursor-pointer`}
                        >
                          <span className="me-2 image_icon ball_shape">
                            {item.modelLinked ? (
                              <img
                                src={item?.modelLinked}
                                className="rounded-circle"
                              />
                            ) : (
                              <PiProhibitBold size={40} />
                            )}
                          </span>
                          <div className="right_title">
                            <span className="text-truncate">{item?.name}</span>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </TabPane>
          </TabContent>
          <div className="d-flex gap-2 gap-xl-3 main_buttons mt-4">
            <Button
              color="primary"
              size="md"
              className="px-4"
              onClick={() => saveData()}
            >
              Save
            </Button>

            <Button
              color="primary"
              size="md"
              outline
              className="px-4"
              onClick={() => cancel()}
            >
              Cancel
            </Button>
          </div>
        </div>
      ) : (
        <>
          <div className="panel_object_list">
            {panelDropdownOptions && panelDropdownOptions?.length > 0 ? (
              <>
                {checkSingleSide ? (
                  <>
                    <FormGroup className="form-group w-100">
                      <Select
                        className="w-100"
                        labelId="printdoc-label"
                        id="panel"
                        value={selectedPanel}
                        label="panel"
                        onChange={(e) => {
                          setSelectedPanel(e.target?.value);
                        }}
                        input={<OutlinedInput className="w-100" name="panel" />}
                        MenuProps={MenuProps}
                        IconComponent={() => (
                          <MdOutlineKeyboardArrowDown
                            size={20}
                            className="me-1 MuiSvgIcon-root MuiSelect-icon"
                          />
                        )}
                      >
                        {panelDropdownOptions &&
                          panelDropdownOptions?.map((item, index) => (
                            <MenuItem key={index} value={item?.id}>
                              {item?.name}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormGroup>

                    {panelStyleList &&
                      panelStyleList?.map((item, index) => (
                        <div
                          key={index}
                          className={`position-relative d-flex align-items-center right_item_wrap text-dark mb-2`}
                        >
                          <NavLink
                            to={{}}
                            className={`right_item w-100 d-flex ${activePanelId == item?.id ? "active" : ""
                              }`}
                            onClick={() => handlePanelStyle(item)}
                          >
                            <span className="me-2 image_icon">
                              {item?.profilePathUrl ? (
                                <Image src={item?.profilePathUrl} />
                              ) : (
                                <svg
                                  viewBox="0 0 50 50"
                                  width="100"
                                  height="100"
                                >
                                  <path
                                    className="stroke"
                                    stroke="#989898"
                                    fill="#fff"
                                    stroke-width="2"
                                    d="M 0,50 0,0 50,0 50,50 Z"
                                  ></path>
                                </svg>
                              )}
                            </span>
                            <div className="right_title">
                              <span className="text-truncate">{item.name}</span>
                            </div>
                          </NavLink>
                        </div>
                      ))}

                    <Button
                      color="light"
                      size="md"
                      className="px-4 w-100 d-flex justify-content-between fw-medium mb-2 mt-4"
                      onClick={() => setDeletePanel(true)}
                    >
                      <BiTrash size={20} />
                      Delete
                      <span></span>
                    </Button>
                  </>
                ) : (
                  <Alert color="secondary" className="d-flex gap-2 p-2 my-2">
                    <span>
                      <MdInfoOutline size={18} />
                    </span>
                    <span>Add or select panels to edit</span>
                  </Alert>
                )}
              </>
            ) : (
              <div
                className={`position-relative d-flex align-items-center right_item_wrap text-dark mb-2 h-100 w-100`}
              >
                <div className={`right_item w-100 d-flex h-100`}>
                  <span className="me-4">
                    <IoWarningOutline size={22} />
                  </span>
                  <div className="right_title">
                    <span>Panels are not configured for this model.</span>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="d-flex gap-2 gap-xl-3 main_buttons mt-4">
            <Button
              color="primary"
              size="md"
              className="px-4"
              onClick={() => {
                saveData();
                setIsSave(true);
              }}
            >
              Save
            </Button>

            <Button
              color="primary"
              size="md"
              outline
              className="px-4"
              onClick={() => cancel()}
            >
              Cancel
            </Button>
          </div>
        </>
      )}
    </>
  );
};

export default GlazingConfig;
