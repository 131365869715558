import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import {
	Row,
	Col,
	Button,
	FormGroup,
	Label,
	Input,
} from "reactstrap";
import { Link, useParams } from "react-router-dom";
import { MdOutlineSell } from "react-icons/md";
import { useRightContext } from "../../../../../../context/rightMenuContext";
import DropZone from "../../../../../../components/DropZone";
const PanelStylesLeft = ({ collectionId, panelMasterId, selectItemDetails, updateItemDetailsServices, handleFormReset }) => {

	var acceptFileType = {
		'image/png': ['.jpg', '.jpeg', '.png', '.svg', '.gif'],
	}
	const { timestamp, selectedRightItem, selected, setSelected, handleEdit, handleFileUpdate } = useRightContext()

	const [name, setName] = useState('')
	const [itemId, setItemId] = useState('')
	const [defaultStyle, setDefaultStyle] = useState(false)
	const [uploadedFile, setUploadedFile] = useState('')
	const [filePreview, setFilePreview] = useState('')
	const [nameError, setNameError] = useState(false)

	useEffect(() => {
		setSelected(selectItemDetails?.name || "")
		setName(selectItemDetails?.name || '')
		setItemId(selectItemDetails?.id || '')
		setDefaultStyle(selectItemDetails?.defaultPanelStyle || false)
		setUploadedFile('')
		setFilePreview(selectItemDetails?.imagePath || '')
		setNameError(false)
	}, [selectItemDetails])

	useEffect(() => {
		if (filePreview) {
			handleFileUpdate(filePreview)
		}
	}, [filePreview])

	const handleNameChange = (e) => {
		const value = e.target.value

		if (e.target.value?.length <= 50) {
			setName(value)
			handleEdit(value)
			setSelected(value)
		}

		if (value.length > 50) {
			setNameError(true)
		} else {
			setNameError(false)
		}
	}

	const handleDefaultChange = (e) => {
		const value = e.target.checked

		// console.log(value, "value")

		setDefaultStyle(value)
	}

	const handleFormSubmit = () => {

		const formDataValues = {
			'id': selectItemDetails?.id,
			"name": name,
			"defaultStyle": defaultStyle || false,
			"collectionId": collectionId,
			"panelProductMasterId": selectItemDetails?.panelProductMasterId,
			"filePath": uploadedFile || '',
			"imagePath": '',
		}

		updateItemDetailsServices(formDataValues)
	}

	return (
		<div className="d-flex flex-column h-100">
			<div className="d-flex align-items-center mb-3 flex-wrap gap-2 left_header">
				{selected && (
					<h5 className="mb-0 text-wrap text-break me-4 main_title">{selected}</h5>
				)}

				<div>
					<MdOutlineSell className="text-primary me-2" size={22} />
					<span className="text-primary">
						<Link to={`/pricing/panels/${collectionId}`}>Edit prices</Link>
					</span>
				</div>
			</div>

			<Form onSubmit={(e) => { e.preventDefault()}} className={`h-100 d-flex flex-column flex-grow-1 justify-content-between ${selectedRightItem?.isSelf ? "" : "not_allowed"}`}>
				{/* has_tab has_btns has_head -> add respective class of included compoenent in div of class inside_scroll for proper scroll */}

				<div className="inside_scroll has_btns has_head">
					<Row>
						<Col lg="8" xl="6">
							<FormGroup className="form-group">
								<Label for="name">
									Name
								</Label>
								<Input className='mb-1' name='name' value={name} onChange={(e) => handleNameChange(e)} />

								{nameError && timestamp && itemId && (
									<div className='mt-1 text-muted text-small'>Max 50 characters are allowed</div>
								)}

								{(!name && timestamp && itemId) && (
									<div className='mt-1 text-danger text-small'>Name is required</div>
								)}
							</FormGroup>
						</Col>

						<Col lg="4" xl="6">
							<FormGroup className="form-group">
								<Label for="defaultSwitch">
									Default panel style
								</Label>
								<Form.Check // prettier-ignore
									type="switch"
									id="defaultSwitch"
									className='form-switch-lg default_switch'
									onChange={(e) => handleDefaultChange(e)}
									label={defaultStyle ? 'On' : 'Off'}
									disabled={selectItemDetails?.defaultPanelStyle}
									checked={defaultStyle}
								/>
							</FormGroup>
						</Col>
						
						{selectedRightItem?.isSelf && (
							<Col lg="6">
								<FormGroup className="form-group">
									<Label for="uploadFile">Upload image</Label>

									<DropZone
										acceptFileType={acceptFileType}
										multiple={false}
										uploadedFile={uploadedFile}
										setUploadedFile={setUploadedFile}
										filePreview={filePreview}
										setFilePreview={setFilePreview}
									/>
								</FormGroup>
							</Col>
						)}
					</Row>

					{timestamp && selectedRightItem?.isSelf && <Row>
						<Col sm="6" lg="6" xl="4" xxl="3" className="mb-4">
							<Link to={`/product/panels/designer/${panelMasterId}/${collectionId}/${timestamp}`} className="btn btn-primary w-100" >Design</Link>
						</Col>
					</Row>}
				</div>

				{/* Start: space for buttons at the bottom */}
				{selectedRightItem?.isSelf && (
					<div className="d-flex gap-2 gap-xl-3 main_buttons">
						<Button color="primary" size="md" className="px-4"
							onClick={() => handleFormSubmit()}
							disabled={!timestamp || !name.trim()}
						>
							Save Changes
						</Button>

						<Button color="primary" size="md" outline className="px-4" disabled={!timestamp} onClick={() => handleFormReset()} >
							Reset
						</Button>
					</div>
				)}
				{/* End: space for buttons at the bottom */}
			</Form>
		</div>
	);
};

export default PanelStylesLeft;
