import React, { useState, useEffect } from 'react'
import { Form } from 'react-bootstrap';
import { MdOutlineKeyboardArrowDown } from 'react-icons/md';
import { Checkbox, FormControl, FormControlLabel, ListItemText, MenuItem, OutlinedInput, Radio, RadioGroup, Select } from 'material-ui-core';
import {
    Row,
    Col,
    Button,
    FormGroup,
    Label,
    Input,
} from 'reactstrap';
import { MenuProps, noSelectOptions } from '../utility/data';
import { useRightContext } from '../context/rightMenuContext';
import { GetProjectInfoMaster, getFontSizeMaster } from '../services/commonServices';

const DocsHeader = ({ fontOptions, accessToken, selectItemDetails, updateItemDetailsServices, handleFormReset }) => {
    const { timestamp } = useRightContext()
    useEffect(() => {
        getProjectDropOptions()
        getHeadFontSizeOptions(2)
    }, [])

    const [projectDropDown, setProjectDropDown] = useState([])
    const [headFontOptions, setHeadFontOptions] = useState([])

    const [title, setTitle] = useState('')
    const [titleFontSize, setTitleFontSize] = useState('')
    const [projectInfo, setProjectInfo] = useState([])

    const [projectInfoFontSize, setProjectInfoFontSize] = useState('')
    const [showHeader, setShowHeader] = useState('On first page only')

    // setting all the value for prefilled inputs
    useEffect(() => {
        setTitle(selectItemDetails?.title || '')

        if(headFontOptions && headFontOptions.length > 0) {
            setTitleFontSize(selectItemDetails?.titleFontSize ? selectItemDetails?.titleFontSize : headFontOptions[3]?.id)
        }

        if(fontOptions && fontOptions.length > 0) {
            setProjectInfoFontSize(selectItemDetails?.projectInfoFontSize ? selectItemDetails?.projectInfoFontSize : fontOptions[3]?.id)
        }
        
        setShowHeader(selectItemDetails?.showHeader ? selectItemDetails?.showHeader : 'On first page only')

        // Filtering and setting here the project items in dropdown
        if (selectItemDetails && selectItemDetails?.projectInfo?.length > 0) {

            const projectInfoNames = selectItemDetails?.projectInfo?.map((item) => {
                const proj = projectDropDown?.find((p) => p.id == item)
                return proj ? proj.name : null
            })?.filter(id => id !== null);
            
            setProjectInfo(projectInfoNames);
        } else {
            setProjectInfo([]);
        }
    }, [selectItemDetails])

    // get title font size dropdown from master
    const getHeadFontSizeOptions = async (typeID) => {
        const res = await getFontSizeMaster(accessToken, typeID);

        if (res && res && res.status === 200) {
            if (res?.data?.statusCode === 200) {
                if (res?.data?.entity !== null) {
                    if (res?.data?.entity?.length > 0) {
                        setHeadFontOptions(res?.data?.entity)
                    }
                }
            }
        }
    }

    const handleNameChange = (e) => {
        const value = e.target.value

        if (e.target.value?.length <= 50) {

            setTitle(value)
        }
    }

    const handleProjectInfoChange = (e) => {
        const value = e.target.value

        setProjectInfo(typeof value === 'string' ? value.split(',') : value)
    }

    const getProjectDropOptions = async () => {
        const res = await GetProjectInfoMaster(accessToken)

        if (res && res && res.status === 200) {
            if (res?.data?.statusCode === 200) {
                setProjectDropDown(res?.data?.entity)
            } else {
                setProjectDropDown(noSelectOptions)
            }
        }
    }

    const handleFormSubmit = () => {

        const getSelectedProjectId = projectInfo?.map((name) => {
            const project = projectDropDown.find((item) => item?.name === name)
            return project ? project?.id : null;
        })

        var formDataValues = {
            'id': selectItemDetails?.id,
            'title': title,
            'titleFontSize': titleFontSize || headFontOptions[3]?.value,
            'projectInfo': getSelectedProjectId,
            'projectInfoFontSize': projectInfoFontSize || fontOptions[3]?.value,
            'showHeader': showHeader,
        }

        updateItemDetailsServices(formDataValues, "Header")
    }

    return (
        <Form className='h-100 d-flex flex-column flex-grow-1 justify-content-between'>
            <div className='inside_scroll has_tab has_btns has_head'>
                <Row>
                    <Col xxl="12">
                        <Row>
                            <Col lg="6">
                                <FormGroup className="form-group">
                                    <Label for="title">
                                        Title Text
                                    </Label>
                                    <Input className='mb-1' name='title' value={title} onChange={(e) => handleNameChange(e)} />

                                    {title && title.length === 50 && (
                                        <div className='mt-1 text-muted text-small'>Max 50 characters are allowed</div>
                                    )}
                                </FormGroup>
                            </Col>

                            <Col lg="6">
                                <FormGroup className="form-group">
                                    <Label for="titleFontSize">
                                        Title font size
                                    </Label>

                                    <Select
                                        labelId="titleFontSize"
                                        id="titleFontSize"
                                        value={titleFontSize || ''}
                                        label="titleFontSize"
                                        onChange={(e) => { setTitleFontSize(e.target.value) }}
                                        input={<OutlinedInput className='w-100' name='titleFontSize' />}

                                        MenuProps={MenuProps}

                                        IconComponent={() => (
                                            <MdOutlineKeyboardArrowDown size={20} className='me-1 MuiSvgIcon-root MuiSelect-icon' />
                                        )}
                                    >
                                        {headFontOptions && headFontOptions.map((o, index) => (
                                            <MenuItem key={index} value={o?.id} style={{ fontSize: `${o.value}px` }}>
                                                {o.title}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormGroup>
                            </Col>

                            <Col lg="6">
                                <FormGroup className="form-group">
                                    <FormControl fullWidth>
                                        <Label for="projectInfo">
                                            Project info
                                        </Label>

                                        <Select
                                            labelId="projectInfo-label"
                                            id="projectInfo"
                                            multiple
                                            value={projectInfo || ''}
                                            onChange={(e) => handleProjectInfoChange(e)}
                                            input={<OutlinedInput name='projectInfo' />}
                                            renderValue={(selected) => selected.join(', ')}
                                            MenuProps={MenuProps}

                                            IconComponent={() => (
                                                <MdOutlineKeyboardArrowDown size={20} className='me-1 MuiSvgIcon-root MuiSelect-icon' />
                                            )}
                                        >
                                            {projectDropDown?.map((name, index) => (
                                                <MenuItem key={index} value={name.name} className='check_list'>
                                                    <Checkbox checked={projectInfo?.indexOf(name.name) > -1} />
                                                    <ListItemText primary={name.name} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </FormGroup>
                            </Col>

                            <Col lg="6">
                                <FormGroup className="form-group">
                                    <FormControl fullWidth>
                                        <Label for="projectInfoFontSize">
                                            Project info font size
                                        </Label>

                                        <Select
                                            labelId="projectInfoFontSize"
                                            id="projectInfoFontSize"
                                            value={projectInfoFontSize || ''}
                                            label="projectInfoFontSize"
                                            onChange={(e) => { setProjectInfoFontSize(e.target.value) }}
                                            input={<OutlinedInput className='w-100' name='projectInfoFontSize' />}

                                            MenuProps={MenuProps}

                                            IconComponent={() => (
                                                <MdOutlineKeyboardArrowDown size={20} className='me-1 MuiSvgIcon-root MuiSelect-icon' />
                                            )}
                                        >
                                            {fontOptions && fontOptions.map((o, index) => (
                                                <MenuItem key={index} value={o?.id} style={{ fontSize: `${o.value}px` }}>
                                                    {o.title}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </FormGroup>
                            </Col>

                            <Col lg="6">
                                <FormControl className="form-group">
                                    <Label for="showHeader">
                                        Show header
                                    </Label>
                                    <RadioGroup
                                        aria-labelledby="showHeader-label"
                                        name="showHeader"
                                        value={showHeader}
                                        onChange={(e) => setShowHeader(e.target.value)}
                                    >
                                        <FormControlLabel value="On first page only" control={<Radio />} label="On first page only" />
                                        <FormControlLabel value="On every page" control={<Radio />} label="On every page" />
                                    </RadioGroup>
                                </FormControl>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
            <div className='d-flex gap-2 gap-xl-3 main_buttons'>
                <Button
                    color="primary"
                    size="md"
                    className='px-4'

                    onClick={() => handleFormSubmit()}
                    disabled={!timestamp}
                >
                    Save Changes
                </Button>

                <Button
                    color="primary"
                    size="md"
                    outline
                    className='px-4'
                    onClick={() => handleFormReset()}
                    disabled={!timestamp || selectItemDetails?.initialValue}
                >
                    Reset
                </Button>
            </div>
        </Form>
    )
}

export default DocsHeader