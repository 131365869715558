import React, { useEffect, useState } from 'react'
import { Col, FormGroup, Image, Row } from 'react-bootstrap';
import Sqsm from "../assets/img/images/sq_sm.png"
import { Button, Input, Label } from 'reactstrap';
import NumberInput from './NumberInput';
import { servicePath } from '../utility/data';
import { MdAdd, MdClose, MdKeyboardBackspace, MdOutlineKeyboardArrowRight } from 'react-icons/md';
import { getAllSysFrameProductsList } from '../services/sysFrameProductsService';
import { useNavigate } from 'react-router';
import axios from 'axios';
import { getAllFrameStyle } from '../services/frameStylesService';
import { getFrameProductsCollection } from "../services/sysFrameCollectionService";
import { useDesigner } from '../context/designerContext';
import { handleQuantity } from '../utility/helper';

const AddNewFrame = ({ accessToken, sidebarOpen, setSidebarOpen, setMessage, setMessageType, setLoading, quoteId, frameCount }) => {

    const navigate = useNavigate();

    const [sidebarOpen2, setSidebarOpen2] = useState(false);
    const [frameCollectionOptions, setFrameCollectionOptions] = useState([]);
    const [frameProductOptions, setFrameProductOptions] = useState([]);
    const [frameStyleList, setFrameStyleList] = useState([]);

    const [frameProductCollectionId, setFrameProductCollectionId] = useState('');
    const [selectedProductId, setSelectedProductId] = useState('');
    const [selectedStyleId, setSelectedStyleId] = useState('0');
    const [styleCollectionId, setStyleCollectionId] = useState(0)

    const [width, setWidth] = useState('2500');
    const [height, setHeight] = useState('2100');
    const [quantity, setQuantity] = useState('1');

    const [name, setName] = useState('');
    const [reference, setReference] = useState('')
    const [comments, setComments] = useState('');

    const [minWidth, setMinWidth] = useState("");
    const [maxWidth, setMaxWidth] = useState("");
    const [minHeight, setMinHeight] = useState("");
    const [maxHeight, setMaxHeight] = useState("");
    const [selectedStyle, setSelectedStyle] = useState(null)

    // const [errorWidth, setErrorWidth] = useState('');
    // const [errorHeight, setErrorHeight] = useState('');
    const [debouncedWidth, setDebouncedWidth] = useState('');

    const { setModelHeight, setModelWidth, setCustomModelData } = useDesigner()

    useEffect(() => {
        const debounceTimeout = setTimeout(() => {
            handleInputChange('width', debouncedWidth);
        }, 500);

        return () => clearTimeout(debounceTimeout);
    }, [debouncedWidth]);

    useEffect(() => {
        if (sidebarOpen) {
            getFrameProductsCollectionService();
        }
    }, [sidebarOpen]);

    const handleDebouncedInputChange = (value) => {
        setDebouncedWidth(value);
    };

    useEffect(() => {
        if (sidebarOpen) {
            setFrameProductCollectionId('')
            setSelectedProductId('')

            setSelectedStyleId('')
            setStyleCollectionId('')
            setWidth(2500)
            setHeight(2100)
            setQuantity(1)

            setName('')
            setReference('')
            setComments('')

            // getFrameCollectionsList()
        }
    }, [sidebarOpen])

    useEffect(() => {
        if (sidebarOpen2) {
            setSelectedStyleId('')
        }
    }, [sidebarOpen2])

    useEffect(() => {
        // console.log(styleCollectionId, "styleCollectionId")

        if (styleCollectionId && sidebarOpen) {
            getFrameStyleList(styleCollectionId);
        }

        if (!styleCollectionId) {
            setFrameStyleList([]);
        }

    }, [styleCollectionId]);

    useEffect(() => {
        if (sidebarOpen && frameCollectionOptions && frameCollectionOptions.length > 0) {
            setFrameProductCollectionId(frameCollectionOptions[0].id)

            getAllSysFrameProductsData(frameCollectionOptions[0].id)
        }
    }, [frameCollectionOptions])

    const getFrameProductsCollectionService = async () => {

        const res = await getFrameProductsCollection(accessToken);

        if (res && res && res.status === 200) {
            if (res?.data?.statusCode === 200) {
                if (res?.data?.entity !== null) {
                    setFrameCollectionOptions(res.data.entity)
                } else {
                    setFrameCollectionOptions([])
                }
            }
        }
    }

    const getFrameStyleList = async (fetchId) => {
        setLoading(true)

        const res = await getAllFrameStyle(accessToken, fetchId);

        if (res && res && res.status === 200) {
            if (res?.data?.statusCode === 200) {
                if (res?.data?.entity !== null) {
                    if (res?.data?.entity?.length > 0) {
                        setFrameStyleList(res?.data?.entity);
                    } else {
                        setFrameStyleList([]);
                    }
                }

                setLoading(false)

            } else {
                setFrameStyleList([]);

                setLoading(false)
            }
        } else {
            setFrameStyleList([]);

            setLoading(false)
        }
    };

    const getAllSysFrameProductsData = async (fetchId) => {

        const res = await getAllSysFrameProductsList(accessToken, fetchId);

        if (res && res && res.status === 200) {

            if (res?.data?.statusCode === 200) {
                if (res?.data?.entity !== null) {

                    if (res?.data?.entity?.length > 0) {
                        setFrameProductOptions(res?.data?.entity)

                    } else {
                        setFrameProductOptions([])
                    }
                    setLoading(false)
                }
            } else {
                setFrameProductOptions([])
                setLoading(false)
            }

        } else {
            setFrameProductOptions([])
            setLoading(false)
        }
    }

    const handleCollectionClick = (itemId) => {
        setFrameProductCollectionId(itemId);

        getAllSysFrameProductsData(itemId)
    };

    const handleProductClick = (item) => {
        setSelectedProductId(item.id);

        var count = parseInt(frameCount) + 1

        // console.log(count)

        var frameName = `${item.name} ${count}`

        setName(frameName);

        setSidebarOpen2(true)
        setStyleCollectionId(item.frameStyleId)

        handleMinimumAllowedLayout(item.id)
    };

    const handleToggleSidebar = () => {
        setSidebarOpen(false);
        setSidebarOpen2(false);
    };

    const handleToggleSidebar2 = () => {
        setSidebarOpen2(!sidebarOpen2);
    };

    const handleFrameStyles = (item) => {
        setSelectedStyleId(item?.id);
        setSelectedStyle(item)
    };

    // This will add the new frame 
    const handleItemAdd = () => {

        const currentDate = new Date();

        const formattedDate = new Intl.DateTimeFormat('en-US', options).format(currentDate);
        const FormData = require("form-data");

        let data = new FormData();

        data.append("Id", 0); // frame id for update

        data.append("FrameCollectionId", frameProductCollectionId);
        data.append("FrameProductId", selectedProductId);
        data.append("FrameStyleId", selectedStyleId);
        data.append("FrameStyleCollectionId", styleCollectionId)

        data.append("Quantity", quantity);
        data.append("Name", name);
        data.append("Reference", reference);
        data.append("Comment", comments);
        data.append("QuotationId", quoteId);

        data.append("Width", width);
        data.append("Height", height);
        data.append("CreatedDate", formattedDate);

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${servicePath}/Quotation/AddUpdateNewFrame`,
            headers: {
                'accept': 'text/plain',
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'multipart/form-data'
            },
            data: data
        };

        axios
            .request(config)
            .then((res) => {
                // console.log(res.data, "FrameAdded");
                if (res?.data?.statusCode === 200) {
                    setMessage(res.data.message);
                    setMessageType("success");

                    setSidebarOpen(false)
                    setSidebarOpen2(false)

                    if (res?.data?.id) {
                        getQuotationFrameDetail(res?.data?.id)
                    }
                }
            })
            .catch((error) => {
                console.log(error);

                // setLoading(false);
                setMessage(error.message ? error.message : "Something went wrong");
                setMessageType("error");

                if (error?.message === "Network Error") {
                    navigate("/error/network");
                }
            });
    };

    const getQuotationFrameDetail = (fetchID) => {
        const FormData = require("form-data");

        let data = new FormData();

        data.append("Id", fetchID);

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${servicePath}/Quotation/GetQuotationFrameDetail`,
            headers: {
                'accept': 'text/plain',
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'multipart/form-data'
            },
            data: data
        };


        axios
            .request(config)
            .then((res) => {

                if (res?.data?.statusCode === 200) {
                    // console.log(res.data, "api response");

                    if (res?.data?.entity && res?.data?.entity.id) {
                        // setCustomModelData((prevModelData) => ({
                        //     ...prevModelData,
                        //     layoutFrame: {
                        //         width: res?.data?.entity?.width,
                        //         height: res?.data?.entity?.height,
                        //     },
                        // }));
                        // setModelHeight(res?.data?.entity?.height)
                        // setModelWidth(res?.data?.entity?.width)

                        navigate(`/product/frame/designer/${styleCollectionId}/${selectedStyleId}`,
                            { state: { data: res?.data?.entity, is_new: true } }
                        )
                    } else {
                        setMessage("Something went wrong");
                        setMessageType("error");
                    }

                }
            })
            .catch((error) => {
                setMessage(error.message ? error.message : "Something went wrong");
                setMessageType("error");

                if (error?.message === "Network Error") {
                    navigate("/error/network");
                }
            });
    };

    const handleMinimumAllowedLayout = (fetchID) => {
        const FormData = require("form-data");

        let data = new FormData();

        data.append("SysProductId", fetchID);

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${servicePath}/Master/GetMinimumAllowedLayout`,
            headers: {
                'accept': 'text/plain',
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'multipart/form-data'
            },
            data: data
        };


        axios
            .request(config)
            .then((res) => {

                if (res?.data?.statusCode === 200) {
                    // console.log(res.data, "api response");

                    var targetEntity = res?.data?.entity?.result[0]

                    setMessage(res.data.message);
                    setMessageType("success");

                    if (res?.data?.entity?.result?.length > 0) {
                        setWidth(targetEntity?.width || 2500);
                        setHeight(targetEntity?.height || 2100);

                        setMinWidth(targetEntity?.minWidth || 0);
                        setMaxWidth(targetEntity?.maxWidth || 9999);

                        setMinHeight(targetEntity?.minHeight || 0);
                        setMaxHeight(targetEntity?.maxHeight || 9999);

                        // if ((targetEntity?.width <= targetEntity?.minWidth) || (targetEntity?.width >= targetEntity?.maxWidth)) {
                        //     setErrorWidth(`Allowed range ${targetEntity?.minWidth} to ${targetEntity?.maxWidth}`);
                        // }

                        // if ((targetEntity?.height <= targetEntity?.minHeight) || (targetEntity?.height >= targetEntity?.maxHeight)) {
                        //     setErrorHeight(`Allowed range ${targetEntity?.minHeight} to ${targetEntity?.maxHeight}`);
                        // }

                    } else {
                        setWidth(2500);
                        setHeight(2100);

                        setMinWidth(0);
                        setMaxWidth(9999);

                        setMinHeight(0);
                        setMaxHeight(9999);

                        // setErrorWidth(`Allowed range 0 to 9999`);
                        // setErrorHeight(`Allowed range 0 to 9999`);
                    }

                }
            })
            .catch((error) => {
                setMessage(error.message ? error.message : "Something went wrong");
                setMessageType("error");

                if (error?.message === "Network Error") {
                    navigate("/error/network");
                }
            });
    };

    // This is for formatting the date in the required format
    const options = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "numeric",
        minute: "numeric",
        hour12: true,
    };

    // const validateInput = (name, value) => {
    //     const numericValue = parseFloat(value);
    //     let min, max, errorState;

    //     switch (name) {
    //         case 'width':
    //             min = minWidth;
    //             max = maxWidth;
    //             errorState = 'errorWidth';
    //             break;
    //         case 'height':
    //             min = minHeight;
    //             max = maxHeight;
    //             errorState = 'errorHeight';
    //             break;
    //         default:
    //             min = '';
    //             max = '';
    //             errorState = '';
    //     }

    //     if (isNaN(numericValue) || numericValue < min || numericValue > max) {
    //         if (name === 'width') {
    //             setErrorWidth(name === 'width' ? `Allowed range ${minWidth} to ${maxWidth}` : '');
    //         }

    //         if (name === 'height') {
    //             setErrorHeight(name === 'height' ? `Allowed range ${minHeight} to ${maxHeight}` : '');
    //         }
    //     } else {
    //         setErrorWidth(name === 'width' ? '' : errorWidth);
    //         setErrorHeight(name === 'height' ? '' : errorHeight);
    //     }
    // };

    const handleInputChange = (name, value) => {
        // console.log(`${name} changed. New value: ${value}`);

        // validateInput(name, value);

        if ((name === 'width' || name === 'height') && (value === null || value === '')) {
            return;
        }

        if (name === 'width' && (width > minWidth && width < maxWidth)) {
            const FormData = require("form-data");
            const data = new FormData();

            data.append("CollectionId", frameProductCollectionId);
            data.append("MaxWidth", value);

            const config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${servicePath}/Quotation/GetAllQuotationFrameStyleWidthSpecific`,
                headers: {
                    'accept': 'text/plain',
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'multipart/form-data'
                },
                data: data
            };

            axios
                .request(config)
                .then((res) => {
                    if (res?.data?.statusCode === 200) {
                        setMessage(res.data.message);
                        setMessageType("success");

                        if (styleCollectionId) {
                            getFrameStyleList(styleCollectionId);
                        } else {
                            setFrameStyleList([]);
                        }
                    }
                })
                .catch((error) => {
                    console.log(error);

                    setMessage(error.message ? error.message : "Something went wrong");
                    setMessageType("error");

                    if (error?.message === "Network Error") {
                        navigate("/error/network");
                    }
                });
        }
    };

    const validateValue = (value, minValue, maxValue) => {
        const numericValue = parseFloat(value);

        if (isNaN(numericValue)) {
            console.error(`Invalid numeric value: ${value}`);
            return false;
        }

        if (numericValue < minValue) {
            console.error(`Value (${value}) is below the valid minimum (${minValue})`);
            return false;
        }

        if (numericValue > maxValue) {
            console.error(`Value (${value}) is above the valid maximum (${maxValue})`);
            return false;
        }

        return true;
    };

    const handleRefChange = (e) => {
        setReference(e.target.value)
    }

    return (
        <>
            <div className={`custom_panel_wrap ${sidebarOpen ? 'sidebar-open' : ''}`}>
                <div className='custom_panel py-3 px-2'>
                    <div className="mb-4">
                        <div className='d-flex justify-content-between'>
                            <h5 className="mb-0 text-wrap text-break me-4 fs-4 fw-bold">
                                New frame
                            </h5>

                            <MdClose className="close-icon clickable" onClick={handleToggleSidebar} size={20} /></div>
                    </div>

                    <Row className={`m-0 ${sidebarOpen2 ? 'd-none' : 'd-flex'}`}>
                        <Col xs="6" className='px-0'>
                            <h5 className="mb-0 text-wrap text-break me-4 main_title mb-2">
                                Collection
                            </h5>

                            <div className='fixed_m_scroll no_head me-2'>
                                {frameCollectionOptions && frameCollectionOptions.map((item, index) => (
                                    <div key={index} className={`panel_item ${frameProductCollectionId === item.id ? 'selected' : ''}`} onClick={() => handleCollectionClick(item.id)}>
                                        <div className='d-flex align-items-center gap-2'>
                                            <div className='img_wrap'>
                                                {/* <Image src={item?.profilePathUrl || Sqsm} alt="icon" /> */}
                                                {item?.profilePathUrl?.includes("https") ? (
                                                    <Image src={item?.profilePathUrl} className="img-fluid" alt="icon" />
                                                ) : (
                                                    <Image src={`data:image/svg+xml;base64,${item?.profilePathUrl}`} className="img-fluid" alt="icon" />
                                                )}
                                            </div>
                                            <span className='title'>
                                                {item.name}
                                            </span>
                                        </div>
                                        <span className='mx-2'>
                                            {frameProductCollectionId === item.id && <MdOutlineKeyboardArrowRight size={24} />}
                                        </span>
                                    </div>
                                ))}
                            </div>
                        </Col>

                        <Col xs="6" className='px-0'>
                            <h5 className="mb-0 text-wrap text-break me-4 main_title mb-2">
                                Product
                            </h5>
                            <div className='fixed_m_scroll full_h'>

                                {frameProductOptions && frameProductOptions.map((item, index) => (
                                    <div key={index} className={`panel_item ${selectedProductId === item.id ? 'selected' : ''}`} onClick={() => handleProductClick(item)}>
                                        <div className='d-flex align-items-center gap-2'>
                                            <div className='img_wrap'>
                                                {/* <Image src={item?.profilePathUrl || Sqsm} alt="icon" /> */}
                                                {item?.profilePathUrl?.includes("https") ? (
                                                    <Image src={item?.profilePathUrl} className="img-fluid" alt="icon" />
                                                ) : (
                                                    <Image src={`data:image/svg+xml;base64,${item?.profilePathUrl}`} className="img-fluid" alt="icon" />
                                                )}
                                            </div>
                                            <span className='title'>
                                                {item.name}
                                            </span>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </Col>
                    </Row>

                    <Row className={`${sidebarOpen2 ? 'd-flex' : 'd-none'}`}>
                        <Col xs="12" className='fixed_m_scroll no_head add_frame_2'>
                            <Row>

                                <Col xs="6">
                                    <FormGroup className="mb-3">
                                        <Label for="name">Name</Label>
                                        <Input name="name" value={name || ''} onChange={(e) => setName(e.target.value)} />
                                        {name === '' && <div className='text-danger'>Please enter name</div>}
                                    </FormGroup>
                                </Col>

                                <Col xs="6">
                                    <FormGroup className="mb-3">
                                        <Label for="reference">Reference</Label>
                                        <Input name="reference" value={reference || ''} onChange={(e) => handleRefChange(e)} />
                                    </FormGroup>
                                </Col>

                                <Col xs="6">
                                    <FormGroup className="mb-3">
                                        <Label for="width1">Quantity</Label>

                                        <Input
                                            type='number'
                                            name="quantity"
                                            min="1" max="999" step="1"
                                            value={quantity}
                                            onInput={(e) => handleQuantity(e)}

                                            onChange={(e) => { setQuantity(e.target.value) }}
                                        />

                                        {parseInt(quantity) === 0
                                            ? <div className='text-danger'>Value must be at least 1</div>
                                            : quantity === '' || quantity === null
                                                ? <div className='text-danger'>Quantity is required</div>
                                                : null
                                        }
                                    </FormGroup>
                                </Col>

                                <Col xs="6">
                                    <FormGroup className="mb-3">
                                        <Label for="comments">Comments</Label>
                                        <Input type="textarea" rows={1} name="comments" value={comments || ''} onChange={(e) => setComments(e.target.value)} />
                                    </FormGroup>
                                </Col>

                                <Col xs="6">
                                    <FormGroup className="mb-3">
                                        <Label for="width">
                                            Width
                                        </Label>
                                        <NumberInput
                                            name="width"
                                            value={width}
                                            setValue={(value) => {
                                                setWidth(value);
                                                handleDebouncedInputChange(value);
                                            }}
                                        />
                                        {((width < minWidth) || (width > maxWidth) || (width > selectedStyle?.maximumWidth) || (width < selectedStyle?.minimumWidth)) && (
                                            <div className='text-danger'>{`Allowed range ${selectedStyle?.minimumWidth ? selectedStyle?.minimumWidth : minWidth} to ${selectedStyle?.maximumWidth ? selectedStyle?.maximumWidth : maxWidth}`}</div>
                                        )}
                                    </FormGroup>
                                </Col>

                                <Col xs="6">
                                    <FormGroup className="mb-3">
                                        <Label for="height">
                                            Height
                                        </Label>
                                        <NumberInput
                                            name="height"
                                            value={height}
                                            setValue={(value) => {
                                                setHeight(value);
                                                handleInputChange('height', value);
                                            }}
                                        />
                                        {((height < minHeight) || (height > maxHeight) || (height > selectedStyle?.maximumHeight) || (height < selectedStyle?.minimumHeight)) && (
                                            <div className='text-danger'>{`Allowed range ${selectedStyle?.minimumHeight ? selectedStyle?.minimumHeight : minHeight} to ${selectedStyle?.maximumHeight ? selectedStyle?.maximumHeight : maxHeight}`}</div>
                                        )}
                                    </FormGroup>
                                </Col>

                                <Col xs="12">
                                    {styleCollectionId !== null ? <FormGroup className="mb-3">
                                        <Label for="frameType">
                                            Select frame style
                                        </Label>

                                        <Row className='custom_row'>
                                            {frameStyleList && frameStyleList.length > 0 ? (
                                                <>
                                                    {frameStyleList && frameStyleList.map((item, index) => (
                                                        <Col xs="6" sm="4" key={index}>
                                                            <div className={`panel_item frame_type ${selectedStyleId === item.id ? 'selected' : ''}`} onClick={() => handleFrameStyles(item)}>
                                                                <div className='d-flex align-items-center gap-2'>
                                                                    <div className='img_wrap'>
                                                                        {item?.profilePathUrl?.includes("https") ? (
                                                                            <Image src={item?.profilePathUrl || Sqsm} className="img-fluid" alt={item?.name} />
                                                                        ) : (
                                                                            <Image src={item?.profilePathUrl ? `data:image/svg+xml;base64,${item?.profilePathUrl}` : Sqsm} className="img-fluid" alt={item?.name} />
                                                                        )}
                                                                    </div>
                                                                    <span className='title'>
                                                                        {item?.name}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    ))}
                                                </>
                                            ) : (
                                                <div className='d-flex justify-content-center my-4'>
                                                    <h4>No styles found</h4>
                                                </div>
                                            )}
                                        </Row>
                                    </FormGroup> : <></>}
                                </Col>
                            </Row>
                        </Col>

                        <Col xs="12">
                            <div className='d-flex justify-content-end gap-2'>
                                <Button
                                    color="primary"
                                    outline
                                    size="md"
                                    className='px-4'
                                    onClick={() => handleToggleSidebar2()}
                                >
                                    <MdKeyboardBackspace size={22} className='me-2' />
                                    Previous
                                </Button>

                                <Button
                                    color="primary"
                                    size="md"
                                    className='px-4'
                                    onClick={() => handleItemAdd()}
                                    disabled={((width < minWidth) || (width > maxWidth)) || ((height < minHeight) || (height > maxHeight)) || quantity == 0 || name === "" || !selectedStyleId || (width > selectedStyle?.maximumWidth) || (width < selectedStyle?.minimumWidth) || (height < selectedStyle?.minimumHeight) || (height > selectedStyle?.maximumHeight)}
                                >
                                    <MdAdd size={18} className='me-1' />
                                    Add
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div >

        </>
    )
}

export default AddNewFrame