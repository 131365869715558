import React, { useState } from 'react'
import { BiPlus, BiTrashAlt } from 'react-icons/bi';
import { MdArrowDownward, MdPersonOutline, MdArrowUpward, MdContentCopy, MdFolderOpen } from 'react-icons/md';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { useEffect } from 'react';
import { useProductPaletteContext } from '../../../../../../context/productPaletteContext';
import { useMessage } from '../../../../../../context/messageContext';
import { addUpdateGlazingCollection, addUpdateGlazingColours, addUpdateGlazingPalette, deleteGlazingCollection, deleteGlazingColours, deleteGlazingPalette, updateCollectionOrder, updateGlazingColourOrder, updatePaletteOrder } from '../../../../../../services/glazingServices';
import RotatingCube from '../../../../../../components/3DComponents/RotatingCube';
import { copyItem } from '../../../../../../services/commonServices';
import { Image } from 'react-bootstrap';
import { useRightContext } from '../../../../../../context/rightMenuContext';




const SpecificationsRight = ({ allColours, setLoading, tabType, getAllColoursServices, getAllPaletteServices, getAllCollectionServices,allPalette,allCollection }) => {

  const { newItemList, fetchSelected, activeIndex, handleActiveIndexDown, handleActiveIndexUp, setActiveIndex, timestamp } = useProductPaletteContext();
  const { setMessage, setMessageType } = useMessage()
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [isDeleted, setIsDeleted] = useState(true)

  const accessToken = localStorage.getItem('access_token')

  const navigate = useNavigate()

  const { selectedRightItem, setSelectedRightItem } = useRightContext()

  useEffect(() => {
    setActiveIndex(0)
  }, [])

  useEffect(() => {
    let selectedRight;
    if (tabType === "colours" && allColours?.length > 0) {
      selectedRight = allColours.find((p) => p.id === timestamp);
    } else if (tabType === "palette") {
      selectedRight = allPalette?.find((p) => p.id === timestamp);
    } else if (tabType === "collections") {
      selectedRight = allCollection?.find((p) => p.id === timestamp);
    }
    setSelectedRightItem(selectedRight);
  }, [tabType, timestamp, allColours, allPalette, allCollection]);

  const getSingleColor = newItemList?.find((item) => item.id === timestamp)




  const addGlazingColours = async (name) => {
    setLoading(true)
    const data = {
      id: "",
      name: name,
      desc: "",
      hex: 0,
      red: 0,
      green: 0,
      blue: 0,
      roughness: 0,
      metalness: 0,
      transparency: 0,
      typeId: 2,
    }

    const res = await addUpdateGlazingColours(data, accessToken)
    if (res && res.status === 200) {
      if (res?.data?.statusCode === 200) {
        setMessageType('success')
        setMessage(res?.data?.message ? res.data.message : 'Added new colour')
        getAllColoursServices('added')
        setLoading(false)
      } else {
        setMessage(res?.data?.message ? res.data.message : 'Something went wrong')
        setMessageType('error')
        getAllColoursServices()
        setLoading(false)

      }
    } else {
      setLoading(false)
      setMessage(res?.message ? res.message : 'Something went wrong');
      setMessageType('error')
      getAllColoursServices()
      if (res?.message === "Network Error") {
        navigate('/error/network')
      }
    }
  }

  const addGlazingPaletteServices = async (name) => {
    setLoading(true)
    const data = {
      id: "",
      name: name,
      desc: "",
      specification: "",
      colourId: 1,
      thickness: 0,
      noOfPanes: 0,
      textureSide: 0,
      maximumWidth: 0,
      maximumHeight: 0,
      maximumsquare: 0,
      imageId: 0,
      typeId: 2,
      hardwareCollectionId: 0,
      sequenceNo: 0
    }

    const res = await addUpdateGlazingPalette(data, accessToken)
    if (res && res.status === 200) {
      if (res?.data?.statusCode === 200) {
        setMessageType('success')
        setMessage(res?.data?.message ? res.data.message : 'Added new palette')
        getAllPaletteServices('added')
        setLoading(false)
      } else {
        setMessage(res?.data?.message ? res.data.message : 'Something went wrong')
        setMessageType('error')
        getAllPaletteServices()
        setLoading(false)

      }
    } else {
      setLoading(false)
      setMessage(res?.message ? res.message : 'Something went wrong');
      setMessageType('error')
      getAllColoursServices()
      if (res?.message === "Network Error") {
        navigate('/error/network')
      }
    }
  }

  const addGlazingCollectionServices = async (name) => {
    setLoading(true)
    const data = {
      id: "",
      name: name,
      typeId: 2,
      sequenceNo: "",
    }

    const res = await addUpdateGlazingCollection(data, accessToken)
    if (res && res.status === 200) {
      if (res?.data?.statusCode === 200) {
        setMessageType('success')
        setMessage(res?.data?.message ? res.data.message : 'Added new palette')
        getAllCollectionServices('added')
        setLoading(false)
      } else {
        setMessage(res?.data?.message ? res.data.message : 'Something went wrong')
        setMessageType('error')
        getAllCollectionServices()
        setLoading(false)

      }
    } else {
      setLoading(false)
      setMessage(res?.message ? res.message : 'Something went wrong');
      setMessageType('error')
      getAllCollectionServices()
      if (res?.message === "Network Error") {
        navigate('/error/network')
      }
    }
  }

  const handleAddServices = () => {
    if (tabType === "colours") {
      addGlazingColours(`Colour ${newItemList.length + 1}`)
    } else if (tabType === "palette") {
      addGlazingPaletteServices(`Palette ${newItemList.length + 1}`)
    } else {
      addGlazingCollectionServices(`Collections ${newItemList.length + 1}`)
    }
  }


  const deleteColourServices = async () => {
    setLoading(true)
    setIsDeleted(false)
    const res = await deleteGlazingColours(timestamp, accessToken)
    if (res && res.status === 200) {
      if (res?.data?.statusCode === 200) {
        setMessageType('success')
        setMessage(res?.data?.message ? res.data.message : 'Deleted Color Successfully')
        getAllColoursServices()
        setLoading(false)
        fetchSelected(0, newItemList[0]?.id)
        setShowDeleteModal(false)
        setIsDeleted(true)
      } else {
        setMessage(res?.data?.message ? res.data.message : 'Something went wrong')
        setMessageType('error')
        getAllColoursServices()
        setLoading(false)
        setIsDeleted(true)

      }
    } else {
      setLoading(false)
      setMessage(res?.message ? res.message : 'Something went wrong');
      setMessageType('error')
      getAllColoursServices()
      if (res?.message === "Network Error") {
        navigate('/error/network')
      }
    }

  }

  const deletePaletteServices = async () => {
    setLoading(true)
    setIsDeleted(false)
    const res = await deleteGlazingPalette(timestamp, accessToken)
    if (res && res.status === 200) {
      if (res?.data?.statusCode === 200) {
        setMessageType('success')
        setMessage(res?.data?.message ? res.data.message : 'Deleted Color Successfully')
        getAllPaletteServices()
        setLoading(false)
        fetchSelected(0, newItemList[0]?.id)
        setShowDeleteModal(false)
        setIsDeleted(true)
      } else {
        setMessage(res?.data?.message ? res.data.message : 'Something went wrong')
        setMessageType('error')
        getAllPaletteServices()
        setLoading(false)
        setIsDeleted(true)

      }
    } else {
      setLoading(false)
      setMessage(res?.message ? res.message : 'Something went wrong');
      setMessageType('error')
      getAllPaletteServices()
      if (res?.message === "Network Error") {
        navigate('/error/network')
      }
    }
  }

  const deleteCollectionServices = async () => {
    setLoading(true)
    setIsDeleted(false)
    const res = await deleteGlazingCollection(timestamp, accessToken)
    if (res && res.status === 200) {
      if (res?.data?.statusCode === 200) {
        setMessageType('success')
        setMessage(res?.data?.message ? res.data.message : 'Deleted Color Successfully')
        getAllCollectionServices()
        setLoading(false)
        fetchSelected(0, newItemList[0]?.id)
        setShowDeleteModal(false)
        setIsDeleted(true)
      } else {
        setMessage(res?.data?.message ? res.data.message : 'Something went wrong')
        setMessageType('error')
        getAllCollectionServices()
        setLoading(false)
        setIsDeleted(true)

      }
    } else {
      setLoading(false)
      setMessage(res?.message ? res.message : 'Something went wrong');
      setMessageType('error')
      getAllCollectionServices()
      if (res?.message === "Network Error") {
        navigate('/error/network')
      }
    }

  }

  const handleDelete = () => {
    if (tabType === "colours") {
      deleteColourServices()
    } else if (tabType === "palette") {
      deletePaletteServices()
    } else {
      deleteCollectionServices()
    }
  }


  const updateOrderServices = async (type, direction) => {
    const handleUpdateOrder = async (type, direction, serviceFunction, getAllServices) => {
      setLoading(true);

      const res = await serviceFunction(accessToken, timestamp, type, direction);

      if (res && res && res.status === 200) {
        if (res?.data?.statusCode === 200) {
          getAllServices();
        } else {
          setLoading(false);
          setMessage(res?.data?.message ? res.data.message : 'Something went wrong');
          setMessageType('error');
        }
      } else {
        setLoading(false);
        setMessage(res?.message ? res.message : 'Something went wrong');
        setMessageType('error');

        if (res?.message === 'Network Error') {
          navigate('/error/network');
        }
      }
    };

    if (tabType === 'colours') {
      handleUpdateOrder(type, direction, updateGlazingColourOrder, getAllColoursServices);
    } else if (tabType === 'palette') {
      handleUpdateOrder(type, direction, updatePaletteOrder, getAllPaletteServices);
    } else {
      handleUpdateOrder(type, direction, updateCollectionOrder, getAllCollectionServices);
    }
  }


  const handleItemUp = () => {
    handleActiveIndexUp()
    updateOrderServices(2, "UP")
  }

  const handleItemDown = () => {
    handleActiveIndexDown()
    updateOrderServices(2, "DOWN")
  }

  const copyPaletteServices = (id) => {
    const copyItemWithServiceCode = async (id, serviceCode, getAllServices) => {
      setLoading(true);
      const res = await copyItem(accessToken, id, serviceCode);

      if (res && res.status === 200) {
        if (res?.data?.statusCode === 200) {
          setMessageType('success');
          setMessage(res?.data?.message ? res.data.message : 'Item copied');
        } else {
          setMessageType('error');
          setMessage(res?.data?.message ? res.data.message : 'Something went wrong');
        }
      } else {
        setLoading(false);
        setMessageType('error');
        setMessage(res?.message ? res.message : 'Something went wrong');
        if (res?.message === 'Network Error') {
          navigate('/error/network');
        }
      }

      getAllServices();
      setLoading(false);
    };

    if (tabType === 'colours') {
      copyItemWithServiceCode(id, 17, getAllColoursServices);
    } else if (tabType === 'palette') {
      copyItemWithServiceCode(id, 16, getAllPaletteServices);
    } else {
      copyItemWithServiceCode(id, 18, getAllCollectionServices);
    }
  };

  return (
    <>
      <div className="h-100 d-flex flex-column flex-grow-1 justify-content-between">
        <div className="inside_scroll has_btns">
          <div className="right_list">
            {newItemList?.map((item, index) => (
              <div
                key={index}
                className="position-relative d-flex align-items-center right_item_wrap has_delete has_copy  threejs_ball"
              >
                <Link
                  to="#"
                  className={`right_item w-100 ${activeIndex === index && "active"
                    }`}
                  onClick={() => {
                    fetchSelected(index, item?.id);
                  }}
                >
                  <span className={`me-2 ${tabType !== "collections" ? "ball_shadow" : ""}`}>
                    {/* <MdPersonOutline size={20} /> */}
                    {tabType === "colours" && (
                      activeIndex === index ?
                        <RotatingCube
                          elements={item?.filePath}
                          roughnessVal={item?.roughness}
                          colorVal={item?.hex}
                          metalNessVal={item?.metalness}
                          bumpiness={item?.metalness}
                          textureVal={item?.filePath}
                          capturedAccordion={""}
                        /> : tabType === "colours" && (
                          <span className="me-2 image_icon ball_shape ball_shadow">
                            <Image src={item?.capturedImage} alt="icon" className='rounded-circle' />
                          </span>
                        )
                    )}
                    {tabType === "palette" && (
                      activeIndex === index ?
                        <RotatingCube
                          elements={item?.modelLinked}
                          roughnessVal={item?.roughness}
                          colorVal={item?.hex}
                          metalNessVal={item?.metalness}
                          bumpiness={item?.metalness}
                          textureVal={item?.modelLinked}
                          capturedAccordion={""}
                        /> : tabType === "palette" && (
                          <span className="me-2 image_icon ball_shape ball_shadow">
                            <Image src={item?.capturedImage} alt="icon" className='rounded-circle' />
                          </span>
                        )
                    )}
                    {tabType === "collections" && <MdFolderOpen size={22} />}
                  </span>
                  <div className="right_title">
                    <span className="text-truncate">{item.name}</span>
                    <span className="text-truncate d-block text-small">
                      {item.description}
                    </span>
                  </div>
                </Link>
                <span className="position-absolute action_btns">
                  {item?.isSelf && <>
                    <Link
                      to="#"
                      onClick={() => {
                        fetchSelected(index, item?.id);
                        setShowDeleteModal(true);
                      }}
                    >
                      {" "}
                      <BiTrashAlt className="text-muted" size={20} />{" "}
                    </Link>
                    <Link to="#" onClick={() => copyPaletteServices(item?.id)}>
                      {" "}
                      <MdContentCopy className="text-muted" size={18} />{" "}
                    </Link>
                  </>}
                </span>
              </div>
            ))}
          </div>
        </div>

        <Modal
          isOpen={showDeleteModal}
          toggle={() => setShowDeleteModal(false)}
        >
          <ModalHeader>Delete Ancillary</ModalHeader>
          <ModalBody>
            Are you sure you want delete <strong>{getSingleColor?.name}</strong>{" "}
            ?
          </ModalBody>
          <ModalFooter>
            <Button
              color="outline-danger"
              size="md"
              onClick={() => handleDelete()}
              disabled={!isDeleted}
            >
              Delete
            </Button>
            <Button
              onClick={() => setShowDeleteModal(false)}
              color="primary"
              size="md"
            >
              Cancel
            </Button>
          </ModalFooter>
        </Modal>

        <div className="d-flex gap-2 gap-xl-3 main_buttons">
          <Button
            color="primary"
            size="md"
            className="px-4"
            onClick={() => handleAddServices()}
          >
            <BiPlus size={20} className="me-1" />
            New
          </Button>

          {selectedRightItem?.isSelf && <>
            <Button
              color=""
              size="md"
              className="border-0 text-primary px-0"
              onClick={() => handleItemUp()}
              disabled={activeIndex === 0 || newItemList?.length === 0}
            >
              <MdArrowUpward size={22} className="me-1" />
              Up
            </Button>
            <Button
              color=""
              size="md"
              className="border-0 text-primary px-0"
              onClick={() => handleItemDown()}
              disabled={
                activeIndex === newItemList?.length - 1 ||
                newItemList?.length === 0 || (selectedRightItem?.isLast)
              }
            >
              <MdArrowDownward size={22} className="me-1" />
              Down
            </Button>
          </>}
        </div>
      </div>
    </>
  );
}

export default SpecificationsRight