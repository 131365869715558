import React, { useEffect, useState } from 'react'
import { AiOutlineArrowLeft, AiOutlineArrowRight } from 'react-icons/ai';
import { BsSquare } from 'react-icons/bs';
import { MdArrowDownward, MdArrowUpward } from 'react-icons/md';
import { Link } from 'react-router-dom';
import {
  Button,
  Label,
} from 'reactstrap';
import { Image } from 'react-bootstrap';
import { PiProhibitBold } from "react-icons/pi";

const CollectionColours = ({ hiddenColours, shownColours, moveToRight, moveToLeft, selectedHiddenIndex, setDefaultColour, setSelectedHiddenIndex, setVisibleData, selectedVisibleIndex, setSelectedVisibleIndex, updateOrder, visibleData, isImage, isHardwareCollection, istexture, isSelf }) => {

  const [showImage, setShowImage] = useState(false)
  const [isEditable, setIsEditable] = useState(false)

  useEffect(() => {
    if (isSelf === true || isSelf === undefined) {
      setIsEditable(true)
    }
    else {
      setIsEditable(false)
    }
  }, [isSelf])

  useEffect(() => {
    if (isImage) {
      setShowImage(true);
    }
  }, [isImage]);

  useEffect(() => {
    if (shownColours) {
      setVisibleData(shownColours[selectedVisibleIndex])
    }
  }, [shownColours, selectedVisibleIndex])

  const handleDown = () => {
    updateOrder('DOWN')
  }

  const handleUp = () => {
    updateOrder('UP')
  }

  return (
    <div>
      <div className="d-flex flex-wrap dual_box_design">
        <div className="dual_box_wrap">
          <Label for="name">Hidden</Label>
          <div className="dual_box">
            <div className='dual_box_list'>
              {hiddenColours &&
                hiddenColours?.map((item, index) => (
                  <div
                    key={index}
                    className="position-relative d-flex align-items-center"
                  >
                    <div
                      className={`list_item w-100 ${index === selectedHiddenIndex && "active"
                        }`}
                    >
                      {isHardwareCollection ? (
                        <span className="me-2 image_icon">
                          {item.imagePath ? (
                            <Image
                              src={item.imagePath}
                              // className="rounded-circle"
                              alt="icon"
                              style={{height:"40px",width:"55px"}}
                            />
                          ) : (
                            <PiProhibitBold size={40} />
                          )}
                        </span>
                      ) : (
                        <>
                          {showImage ? (
                            <span className={istexture ? "me-2 image_icon ball_shape" : "me-2 image_icon"}>
                              {item.modelLinked ? (
                                <Image
                                  src={item.modelLinked}
                                  className={istexture ? "rounded-circle" : ""}
                                  alt="icon"
                                  style={{width:"55px", height:"40px"}}
                                />
                              ) : (
                                <PiProhibitBold size={40} />
                              )}
                            </span>
                          ) : (
                            <span className="me-2 image_icon ball_shape ball_shadow">
                              <Image
                                src={item.capturedImage}
                                className={istexture ? "rounded-circle" : ""}
                                alt="icon"
                              />
                            </span>
                          )}
                        </>
                      )}

                      <span className="text-truncate ms-2">{item.name}</span>
                    </div>

                    {isEditable && <span
                      className="position-absolute p-3 "
                      style={{ right: "0" }}
                    >
                      <Link
                        to="#"
                        onClick={() => {
                          moveToRight(item?.id, item?.name);
                          setSelectedHiddenIndex(index);
                        }}
                      >
                        {" "}
                        <AiOutlineArrowRight
                          className="text-primary"
                          size={18}
                        />{" "}
                      </Link>
                    </span>}
                  </div>
                ))}
            </div>
          </div>
        </div>

        <div className="dual_box_wrap">
          <Label for="name">Visible</Label>
          <div className="dual_box has_btns">
            <div className="dual_box_list">
              {shownColours &&
                shownColours?.map((item, index) => (
                  <div
                    key={index}
                    className="position-relative d-flex align-items-center clickable"
                    onClick={() => {
                      setVisibleData(item);
                      setSelectedVisibleIndex(index);
                    }}
                  >
                    <div
                      className={`list_item w-100 ${index === selectedVisibleIndex && "active"
                        }`}
                    >
                      {isHardwareCollection ? (
                        <span className="me-2 image_icon">
                          {item.imagePath ? (
                            <Image
                              src={item.imagePath}
                              // className="rounded-circle"
                              alt="icon"
                            />
                          ) : (
                            <PiProhibitBold size={40} />
                          )}
                        </span>
                      ) : (
                        <>
                          {" "}
                          {showImage ? (
                            <span className={istexture ? "me-2 image_icon ball_shape" : "me-2 image_icon"}>
                              {item.modelLinked ? (
                                <Image
                                  src={item.modelLinked}
                                  className={istexture ? "rounded-circle" : ""}
                                  alt="icon"
                                />
                              ) : (
                                <PiProhibitBold size={40} />
                              )}
                            </span>
                          ) : (
                            <span className="me-2 image_icon ball_shape ball_shadow">
                              <Image
                                src={item.capturedImage}
                                className={istexture ? "rounded-circle" : ""}
                                alt="icon"
                              />
                            </span>
                          )}
                        </>
                      )}
                      <span className="text-truncate ms-2">{item.name}</span>
                    </div>
                    {isEditable && <span
                      className="position-absolute p-3 "
                      style={{ right: "0" }}
                    >

                      {(!item?.default) ? (
                        <Link
                          to="#"
                          onClick={() => {
                            {
                              moveToLeft(item?.primaryId || item?.id);
                              setSelectedVisibleIndex(index);
                            }
                          }}
                        >
                          <AiOutlineArrowLeft className='text-primary' size={18} />
                        </Link>
                      ) : (
                        <Link to="#" className='disabled opacity-50'
                        >
                          <AiOutlineArrowLeft className='text-primary' size={18} />
                        </Link>
                      )}
                    </span>}
                  </div>
                ))}
            </div>
            {isEditable && <div
              className={`d-flex justify-content-between gap-2 px-4 py-2 ${shownColours?.length < 1 && "opacity-75 pointer_none"
                }`}
            >
              <Button
                color=""
                size="md"
                className="border-0 text-primary px-0"
                onClick={() => handleUp()}
                disabled={
                  shownColours?.length < 1 ||
                  visibleData?.id === "" ||
                  selectedVisibleIndex === 0
                }
              >
                <MdArrowUpward size={22} className="me-1" />
                Up
              </Button>
              <Button
                color=""
                size="md"
                className="border-0 text-primary px-0"
                onClick={() => handleDown()}
                disabled={
                  selectedVisibleIndex === shownColours?.length - 1 ||
                  shownColours?.length < 1
                }
              >
                <MdArrowDownward size={22} className="me-1" />
                Down
              </Button>

              <Button
                color=""
                size="md"
                className="border-0 text-primary px-0"
                onClick={() => setDefaultColour()}
                disabled={shownColours?.length < 1 || visibleData?.default}
              >
                <BsSquare size={16} className="me-1" />
                Default
              </Button>
            </div>}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CollectionColours