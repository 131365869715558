import React, { useState } from 'react'
import {
    Nav,
    NavLink,
    NavItem,
    TabContent,
    TabPane
} from 'reactstrap';
import TexturePalette from '../../../../../../components/GlazingTextureComp/TexturePalette';
import TextureCollection from '../../../../../../components/GlazingTextureComp/TextureCollection';
import Editprices from '../../../../../../components/Editprices';


const TexturesLeft = ({ setLoading, commonHeading, setCommonHeading, setTabType, allPalette, allCollection, tabType, getAllPaletteServices, getAllCollectionServices }) => {

    const [activeTab, setActiveTab] = useState("palette")

    const noRefCheck = (tab) => {
        setActiveTab(tab)
        setTabType(tab)
    }

    const editNavData = { url: `/pricing/glazing/3` }

    return (
        <div className='d-flex flex-column h-100'>
            <div className='d-flex align-items-center mb-3 left_header'>
                {commonHeading && <h5 className="mb-0 text-wrap text-break me-4 main_title">{commonHeading}</h5>} <div></div>

                {activeTab !== 'collections' && <Editprices data={editNavData} />}
            </div>

            <Nav tabs className="details_header border-0 nav-tabs-custom">
                <NavItem>
                    <NavLink
                        className={`${activeTab === "palette" && 'active'}`}
                        onClick={() => noRefCheck("palette")}
                    >
                        Palette
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        className={`${activeTab === "collections" && 'active'}`}
                        onClick={() => noRefCheck("collections")}
                    >
                        Collections
                    </NavLink>
                </NavItem>
            </Nav>
            <TabContent activeTab={activeTab} className='flex-grow-1 d-flex'>
                <TabPane tabId="palette" className='w-100'>
                    <TexturePalette setLoading={setLoading} setCommonHeading={setCommonHeading} allPalette={allPalette} tabType={tabType} getAllPaletteServices={getAllPaletteServices} />
                </TabPane>
                <TabPane tabId="collections" className='w-100'>
                    <TextureCollection tabType={tabType} setCommonHeading={setCommonHeading} allCollection={allCollection} getAllCollectionServices={getAllCollectionServices} setLoading={setLoading} />
                </TabPane>
            </TabContent>
        </div>
    )
}

export default TexturesLeft