import { useState } from "react";
import { useContext } from "react";
import { createContext } from "react";

// this sashHangingContext is common for all pages having right side menu except ancillary and acocunt pages
const sashHangingContext = createContext();

const useSashHangingContext = () => {
    const value = useContext(sashHangingContext);
    return value;
}

const CustomContextRightMenu = ({ children }) => {
    const [panelMenuList, setPanelMenuList] = useState([{
        title: "Panel products",
        parent: 'Products',
        submenu: 'Panels',
        level: 3,
        to: "/product/panels/panel-products",
    }]);

    const [frameMenuList, setFrameMenuList] = useState([{
        id: 0,
        title: "Collections",
        parent: 'Products',
        submenu: 'Frame Styles',
        items: [],
        level: 3,
        to: "/product/systems/style-collections",
    }]);
    
    const [newItemList, setNewItemList] = useState([]);
    const [selected, setSelected] = useState();
    const [descUpdate, setDescUpdate] = useState();
    const [timestamp, setTimestamp] = useState('');
    const [activeIndex, setActiveIndex] = useState(0);
    const [fetchFlag, setFetchFlag] = useState(true);

    // Compute the last index of the item data
    const lastIndex = newItemList?.length - 1;

    // Function to add a new item item
    const addNewItem = (currentData) => {
        setNewItemList((prevItem) => [...prevItem, currentData]);
    };

    // Function to fetch and select a specific item
    const fetchSelected = (index, uniqueId) => {
        const data = newItemList?.find((item) => item.id === uniqueId);
        setSelected(data?.name);
        setDescUpdate(data?.description)
        setTimestamp(uniqueId);
        setFetchFlag(true);
        setActiveIndex(index);
    };

    // Function to edit and update an item name
    const handleEdit = (value) => {
        const updateIndex = newItemList?.findIndex((item) => item.id === timestamp);

        if (updateIndex !== -1) {
            let updatedItem = [...newItemList];
            updatedItem[updateIndex] = {
                ...updatedItem[updateIndex],
                name: value,
            };
            setNewItemList(updatedItem);
        }
    };

    // Function to edit and update an item description
    const handleDescEdit = (value) => {
        const updateIndex = newItemList?.findIndex((item) => item.id === timestamp);

        if (updateIndex !== -1) {
            let updatedItem = [...newItemList];
            updatedItem[updateIndex] = {
                ...updatedItem[updateIndex],
                description: value,
            };

            setNewItemList(updatedItem);
        }
    };

    // Function to edit and update an item description
    const handleFileUpdate = (value) => {
        const updateIndex = newItemList?.findIndex((item) => item.id === timestamp);

        if (updateIndex !== -1) {
            let updatedItem = [...newItemList];
            updatedItem[updateIndex] = {
                ...updatedItem[updateIndex],
                imageUrl: value,
            };

            setNewItemList(updatedItem);
        }
    };

    // Function to handle navigating up the active index
    const handleActiveIndexUp = () => {
        if (activeIndex === 0) {
            setActiveIndex(lastIndex);
        } else {
            setActiveIndex(activeIndex - 1);
        }
    };

    // Function to handle navigating down the active index
    const handleActiveIndexDown = () => {
        if (activeIndex === lastIndex) {
            setActiveIndex(0);
        } else {
            setActiveIndex(activeIndex + 1);
        }
    };

    return (
        <sashHangingContext.Provider value={{
            newItemList,
            selected,
            timestamp,
            activeIndex,
            fetchFlag,
            addNewItem,
            fetchSelected,
            handleEdit,
            handleActiveIndexUp,
            handleActiveIndexDown,
            setSelected,
            descUpdate,
            setDescUpdate,
            handleDescEdit,
            setFetchFlag,
            setActiveIndex,
            setNewItemList,
            setTimestamp,
            panelMenuList,
            setPanelMenuList,
            frameMenuList,
            setFrameMenuList,
            handleFileUpdate,
            // handeListClick,
            // previouseSelected,
            // currentSelected,
            // arrayOfObjects
        }}>
            {children}
        </sashHangingContext.Provider>
    )
}

export { useSashHangingContext }
export default CustomContextRightMenu;

