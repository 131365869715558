import React from 'react'
import { MdPersonOutline } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { useAncillary } from '../../../../../context/ancillarycontext';
import { useEffect } from 'react';

const CollectionsRight = ({ subAccounts, setSubAccountsId, setGetSubAccountName }) => {

  const { setActiveIndex, activeIndex } = useAncillary();

  useEffect(() => {
    setActiveIndex(0)
  }, [])

  const handeListItemClick = (item, index) => {
    setSubAccountsId(item.id);
    setActiveIndex(index)
    setGetSubAccountName(item.userName)
  }

  return (
    <>
      <div className='h-100 d-flex flex-column flex-grow-1 justify-content-between'>
        <div className='inside_scroll'>
          <div className='right_list'>
            {subAccounts.length > 0 && subAccounts?.map((item, index) =>
              <Link key={index} to="#" className={`right_item ${activeIndex === index && "active"}`} onClick={() => handeListItemClick(item, index)}>
                <span className='me-2'>
                  <MdPersonOutline size={22} />
                </span>
                <span>
                  {item.userName}
                </span>
              </Link>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default CollectionsRight