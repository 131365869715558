import React, { useEffect, useState } from 'react'
import ComingSoon from '../../../errors/coming-soon'
import InternalLayout from "../../../../../../layouts/internalLayout";
import CollectionsLeft from './collections-left';
import CollectionsRight from './collections-right';
import { Oval } from 'react-loader-spinner';
import { getAllColours, getAllColoursCollection } from '../../../../../../services/productServices';
import { useProductPaletteContext } from '../../../../../../context/productPaletteContext';
import { useMessage } from '../../../../../../context/messageContext';
import { useRightContext } from '../../../../../../context/rightMenuContext';
import { getColourCollectionNavigaton } from '../../../../../../services/SidebarServices';
import { useNavigate } from 'react-router-dom';

const ProductCollections = () => {

    const navigate = useNavigate()

    const { setNewItemList, setTimestamp, setActiveIndex } = useProductPaletteContext()
    const { setMainMenuActive, setSubMenuActive, setSubMenu2Active, setPricingColoursMenuList } = useRightContext()

    const [loading, setLoading] = useState(false)
    const [allCollection, setAllCollection] = useState([])

    const { setMessage, setMessageType } = useMessage()

    const accessToken = localStorage.getItem('access_token')

    useEffect(() => {
        setLoading(true)
        setTimestamp('')
        setNewItemList([])
        setAllCollection([])

        setMainMenuActive("Products")
        setSubMenuActive("Colours")
        setSubMenu2Active("")

        setTimeout(() => {
            getAllCollectionServices('')
        }, 400);
    }, [])

    const getAllCollectionServices = async (type) => {
        setLoading(true)

        const res = await getAllColoursCollection("1", accessToken)

        if (res.data?.statusCode === 200) {
            let data = res?.data?.entity
            setNewItemList(res?.data.entity)
            setAllCollection(res?.data.entity)

            if (type === 'added') {
                setTimestamp(data[data.length - 1]?.id)
                setActiveIndex(data.length - 1)
            }

            type = '';
            setLoading(false)
            setMessageType('success')
            setMessage(res.message)

            getColourCollectionNavigatonService()
        } else {
            setLoading(false)
            setMessageType('error')
            setMessage(res.message)
            getColourCollectionNavigatonService()
        }
    }

    const getColourCollectionNavigatonService = () => {
        getColourCollectionNavigaton(accessToken, setPricingColoursMenuList, navigate);
    }

    return (
        <>
            {loading && (
                <div className='loader_main full_loader'>
                    <Oval
                        height={50}
                        width={50}
                        color="#44C8F5"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                        ariaLabel='oval-loading'
                        secondaryColor="#44C8F5"
                        strokeWidth={2}
                        strokeWidthSecondary={2}
                    />
                </div>
            )}
            <InternalLayout
                pageTitle="Collection"
                layoutType=""
                leftContent={
                    <CollectionsLeft
                        setLoading={setLoading}
                        loading={loading}
                        getAllCollectionServices={getAllCollectionServices}
                        allCollection={allCollection}
                        getColourCollectionNavigatonService={getColourCollectionNavigatonService}
                    />
                }
                rightContent={
                    <CollectionsRight
                        setLoading={setLoading} loading={loading}
                        allCollection={allCollection}
                        getAllCollectionServices={getAllCollectionServices} />
                }
            />
        </>
    )
}

export default ProductCollections